export enum TipoUsuario {
    Vendedor = -1,
    Root = 0,
    Administrador = 1,
    Gerente = 2,
    Cajero = 3,
    Barman = 4,
    Mesero = 5,
    Cocinero = 6
}
