import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {InventoryService} from '../../../../../../core/services/inventory.service';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {Insumo} from '../../../../../../shared/models/insumo';
import {Magnitude} from '../../magnitudes';

@Component({
  selector: 'app-inventory-edit-input',
  templateUrl: './inventory-edit-input.component.html'
})
export class InventoryEditInputComponent implements OnInit, OnDestroy {

  disableBtn: boolean;
  form: FormGroup;
  requestState: number;
  dataSubscription: Subscription;
  formSubscription: Subscription;
  timeout: number;
  title = 'Editar Insumo';
  unidades = Magnitude;
  @ViewChild('dialogWarning', {static: true}) private dialogWarning: SwalComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<InventoryEditInputComponent>,
              private inventoryService: InventoryService) {
    this.form = new FormGroup(
      {
        nombre: new FormControl('', Validators.required),
        descripcion: new FormControl(''),
        unidadMedida: new FormControl('', Validators.required),
        proveedores: new FormControl('')
      }
    );
  }

  ngOnInit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.initial;
    this.dataSubscription = this.inventoryService.obtenerInsumo(this.data.id).subscribe(
      (data: Insumo) => this.form.patchValue(data[0])
    );
    this.formSubscription = this.form.valueChanges.subscribe(
      () => this.disableBtn = !this.form.valid
    );
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  edit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.inventoryService.editarInsumo(this.data.id, this.form.value).subscribe(
      () => {
        this.requestState = RequestState.success;
        this.dialogRef.close({ok: true, id: this.data.id, form: this.form.getRawValue()});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogWarning.show().then(
      data => {
        if (data.value) {
          this.dialogRef.close({ok: false});
        }
      }
    );
  }
}
