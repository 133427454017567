import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Licencia } from 'src/app/shared/class-models/licencia.model';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class LicenciasService {
  private rutaBaseLicencias = '/licencias';

  constructor(private httpClient: HttpClient) { }

  /* GET */
  public obtenerLicencia(_idLicencia: string) {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseLicencias}/${_idLicencia}`, AppSettings.Options);
  }

  public obtenerLicencias(): Observable<Licencia[]> {
    return this.httpClient.get<Licencia[]>(`${AppSettings.APIEndpoint}${this.rutaBaseLicencias}`, AppSettings.Options);
  }

  public obtenerLicenciasVigentes(): Observable<Licencia[]> {
    return this.httpClient.get<Licencia[]>(`${AppSettings.APIEndpoint}${this.rutaBaseLicencias}/vigentes`, AppSettings.Options);
  }

  /* POST */
  public agregarLicencia(licencia: Licencia): Observable<Licencia> {
    return this.httpClient.post<Licencia>(`${AppSettings.APIEndpoint}${this.rutaBaseLicencias}`, JSON.stringify(licencia), AppSettings.Options);
  }

  /* PUT */
  public actualizarLicencia(_idLicencia: string, licencia: Licencia): Observable<Licencia> {
    return this.httpClient.put<Licencia>(`${AppSettings.APIEndpoint}${this.rutaBaseLicencias}/${_idLicencia}`, JSON.stringify(licencia), AppSettings.Options);
  }

  /* PATCH */
  public eliminarLicencia(_idLicencia: string): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseLicencias}/${_idLicencia}`, AppSettings.Options);
  }
}
