import { Component, OnInit } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { Subscription } from 'rxjs';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Insumo } from 'src/app/shared/class-models/insumo.model';
import { ProveedoresService } from 'src/app/core/servicios/proveedores.service';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { InsumosService } from 'src/app/core/servicios/insumos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { NewProviderComponent } from 'src/app/modules/providers/modals/new-provider/new-provider.component';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { UnidadDeMedida } from 'src/app/shared/class-models/unidad-de-medida.model';
import { UnidadesDeMedidaService } from 'src/app/core/servicios/unidad-de-medida.service';
import { TipoDeInsumo } from 'src/app/shared/enums/tipo-de-insumo.enum';
import { AltaUnidadDeMedidaComponent } from '../../unidades-de-medida/alta-unidad-de-medida/alta-unidad-de-medida.component';

@Component({
  selector: 'app-alta-insumo',
  templateUrl: './alta-insumo.component.html',
  styleUrls: ['./alta-insumo.component.scss']
})
export class AltaInsumoComponent implements OnInit {
  /* VARIABLES GENERALES */
  titulo: string = 'Agregar insumo';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;

  /* VARIABLES PARA EL STEP DE INFORMACIÓN GENERAL */
  TipoDeInsumo = TipoDeInsumo;
  unidadesDeMedida: UnidadDeMedida[];
  estadoPeticionObtenerUnidadesDeMedida: number;
  obtenerUnidadesDeMedidaSubscripcion: Subscription;
  ingredientes: Ingrediente[];
  estadoPeticionObtenerIngredientesSinInsumoRegistrado: number;
  obtenerIngredientesSinInsumoRegistradoSubscripcion: Subscription;
  productos: Producto[];
  estadoPeticionObtenerProductosSinInsumoRegistrado: number;
  obtenerProductosSinInsumoRegistradoSubscripcion: Subscription;

  /* VARIABLES PARA EL STEP DE PROVEEDORES */
  proveedores: Proveedor[];
  estadoProveedores: boolean[];
  estadoPeticionObtenerProveedores: number;
  obtenerProveedoresSubscripcion: Subscription;

  /* VARIABLES PARA AGREGAR EL INSUMO */
  estadoPeticionAgregarInsumo: number;
  agregarInsumoSubscripcion: Subscription;
  insumo: Insumo = new Insumo();

  constructor(
    private _unidadesDeMedidaService: UnidadesDeMedidaService,
    private _proveedoresService: ProveedoresService,
    private _ingredientesService: IngredientesService,
    private _productosService: ProductosService,
    private _insumosService: InsumosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    public modal: MatDialogRef<AltaInsumoComponent>
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 4;
    this.estadoPeticionObtenerProveedores = RequestState.initial;
    this.estadoPeticionObtenerUnidadesDeMedida = RequestState.initial;
    this.estadoPeticionObtenerIngredientesSinInsumoRegistrado = RequestState.initial;
    this.estadoPeticionObtenerProductosSinInsumoRegistrado = RequestState.initial;
    this.estadoPeticionAgregarInsumo = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProveedores();
    this.obtenerUnidadesDeMedida();
    this.obtenerIngredientesSinInsumoRegistrado();
    this.obtenerProductosSinInsumoRegistrado();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerUnidadesDeMedida(): void {
    this.unidadesDeMedida = [];
    this.estadoPeticionObtenerUnidadesDeMedida = RequestState.loading;
    this.obtenerUnidadesDeMedidaSubscripcion = this._unidadesDeMedidaService.obtenerUnidadesDeMedida().subscribe(
      (unidadesDeMedida: UnidadDeMedida[]) => {
        this.unidadesDeMedida = unidadesDeMedida;
        this.estadoPeticionObtenerUnidadesDeMedida = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUnidadesDeMedida = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerProveedores(): void {
    this.proveedores = [];
    this.estadoPeticionObtenerProveedores = RequestState.loading;
    this.obtenerProveedoresSubscripcion = this._proveedoresService.obtenerProveedores().subscribe(
      (proveedores: Proveedor[]) => {
        this.proveedores = proveedores;
        this.estadoProveedores = proveedores.map(proveedores => { return false; });
        this.estadoPeticionObtenerProveedores = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProveedores = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerIngredientesSinInsumoRegistrado(): void {
    this.ingredientes = [];
    this.estadoPeticionObtenerIngredientesSinInsumoRegistrado = RequestState.loading;
    this.obtenerIngredientesSinInsumoRegistradoSubscripcion = this._ingredientesService.obtenerIngredientesSinInsumoRegistrado().subscribe(
      (ingredientes: Ingrediente[]) => {
        this.ingredientes = ingredientes;
        this.estadoPeticionObtenerIngredientesSinInsumoRegistrado = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngredientesSinInsumoRegistrado = RequestState.error;
      }
    );
  }

  obtenerProductosSinInsumoRegistrado(): void {
    this.productos = [];
    this.estadoPeticionObtenerProductosSinInsumoRegistrado = RequestState.loading;
    this.obtenerProductosSinInsumoRegistradoSubscripcion = this._productosService.obtenerProductosSinInsumoRegistrado().subscribe(
      (productos: Producto[]) => {
        this.productos = productos;
        this.estadoPeticionObtenerProductosSinInsumoRegistrado = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProductosSinInsumoRegistrado = RequestState.error;
      }
    );
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  /* MÉTODOS DEL STEP DE INFORMACIÓN GENERAL */
  obtenerTipoDeInsumo(tipoDeInsumo: number): string {
    switch(tipoDeInsumo) {
      case TipoDeInsumo.Ingrediente: return 'Ingrediente';
      case TipoDeInsumo.Producto: return 'Producto';
      case TipoDeInsumo.Otro: return 'Otro';
    }
  }

  agregarUnidadDeMedida(): void {
    const dialogRef = this.dialog.open(AltaUnidadDeMedidaComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (unidadDeMedida: UnidadDeMedida) => {
        if (unidadDeMedida != null) {
          this._alertasService.alertaExitoSinConfirmacion('Unidad de medida agregada exitosamente', 'La unidad de medida ' + unidadDeMedida.nombre + ' ha sido agregada con éxito');
          this.unidadesDeMedida.push(unidadDeMedida);
        }
      }
    );
  }

  /* MÉTODOS DEL STEP DE PROVEEDORES */
  cambioEstadoProveedor(posicionProveedor: number, proveedor: Proveedor): void {
    if (this.estadoProveedores[posicionProveedor]) (<Proveedor[]>this.insumo.proveedores).push(proveedor);
    else {
      const posicionProveedorSeleccionado = (<Proveedor[]>this.insumo.proveedores).indexOf(proveedor, 0);
      if (posicionProveedorSeleccionado > -1) {
        this.insumo.proveedores.splice(posicionProveedorSeleccionado, 1);
      }
    }
  }

  agregarProveedor(): void {
    const dialogRef = this.dialog.open(NewProviderComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (proveedor: Proveedor) => {
        if (proveedor != null) {
          this._alertasService.alertaExitoSinConfirmacion('Proveedor agregado exitosamente', 'El proveedor ' + this.obtenerRazonSocialProveedor(proveedor) + ' ha sido agregado con éxito');
          this.proveedores.push(proveedor);
          this.estadoProveedores.push(false);
        }
      }
    );
  }

  obtenerRazonSocialProveedor(proveedor: Proveedor): string {
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral: return proveedor.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return proveedor.nombres + ' ' + proveedor.primerApellido + (proveedor.segundoApellido ? (' ' + proveedor.segundoApellido) : '');
    }
  }

  /* MÉTODOS PARA AGREGAR EL INSUMO */
  agregarInsumo(): void {
    this.estadoPeticionAgregarInsumo = RequestState.loading;
    this.agregarInsumoSubscripcion = this._insumosService.agregarInsumo(this.prepararDatosInsumo()).subscribe(
      (insumo: Insumo) => {
        this.estadoPeticionAgregarInsumo = RequestState.success;
        this.modal.close(insumo);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarInsumo = RequestState.error;
      }
    );
  }

  prepararDatosInsumo(): Insumo {
    let insumo = new Insumo();
    insumo.tipo = this.insumo.tipo;
    switch (insumo.tipo) {
      case TipoDeInsumo.Ingrediente:
        insumo._idIngrediente = this.insumo._idIngrediente;
        insumo._idProducto = null;
        insumo.nombre = null;
        insumo.descripcion = null;
        break;
      case TipoDeInsumo.Producto:
        insumo._idProducto = this.insumo._idProducto;
        insumo._idIngrediente = null;
        insumo.nombre = null;
        insumo.descripcion = null;
        break;
      case TipoDeInsumo.Otro:
        insumo.nombre = this.insumo.nombre;
        insumo.descripcion = this.insumo.descripcion;
        insumo._idIngrediente = null;
        insumo._idProducto = null;
        break;
    }
    insumo._idUnidadDeMedida = this.insumo._idUnidadDeMedida;
    (<Proveedor[]>this.insumo.proveedores).forEach((proveedor)=> {
      (<string[]>insumo.proveedores).push(proveedor._id);
    })
    return insumo;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerUnidadesDeMedidaSubscripcion) this.obtenerUnidadesDeMedidaSubscripcion.unsubscribe();
    if (this.obtenerProveedoresSubscripcion) this.obtenerProveedoresSubscripcion.unsubscribe();
    if (this.obtenerIngredientesSinInsumoRegistradoSubscripcion) this.obtenerIngredientesSinInsumoRegistradoSubscripcion.unsubscribe();
    if (this.obtenerProductosSinInsumoRegistradoSubscripcion) this.obtenerProductosSinInsumoRegistradoSubscripcion.unsubscribe();
    if (this.agregarInsumoSubscripcion) this.agregarInsumoSubscripcion.unsubscribe();
  }
}
