import {AppSettings} from '../../../../configs/app-settings.config';
import {Component, Input, OnInit} from '@angular/core';
import {Paquete} from '../../../../shared/models/paquete';
import {MatDialog} from '@angular/material';
import { VistaPaqueteModalComponent } from 'src/app/modules/promo/vista-paquete-modal/vista-paquete-modal.component';

@Component({
  selector: 'app-menu-promo',
  templateUrl: './kiosk-promo.component.html'
})
export class KioskPromoComponent implements OnInit {

  @Input() promo: Paquete;

  appSettings = AppSettings;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  detalles() {
    const dialogRef = this.dialog.open(VistaPaqueteModalComponent,
      {
        disableClose: true,
        data: {
          id: this.promo.id,
          nombre: this.promo.nombre
        }
      });
  }

}
