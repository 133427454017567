import {Component, Input, OnInit} from '@angular/core';
import {Orden} from '../../../../../../shared/models/orden';
import {ActivatedRoute} from '@angular/router';
import {AppSettings} from '../../../../../../configs/app-settings.config';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html'
})
export class OrderCardComponent implements OnInit {

  title = 'Orden';
  @Input() orden: Orden;
  @Input() verTitulo: boolean;
  @Input() verVerMas: boolean;
  @Input() verConfiguracion: boolean;
  @Input() verCancelado: boolean;
  @Input() verCantidadPrecio: boolean;
  idOrdenPorTamano: string;
  AppSettings = AppSettings;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.idOrdenPorTamano = this.orden.datosProducto != null
      ? 'producto' + this.orden.datosProducto.producto.id
      : 'paquete' + this.orden.datosPaquete.paquete.id;
  }

  mostrarOpcionesConfiguracion() {
    if (!document.getElementById(`configurar${this.idOrdenPorTamano}`).classList.contains('rollIn')) {
      document.getElementById(`configurar${this.idOrdenPorTamano}`).classList.add('rollIn');
      document.getElementById(`configurar${this.idOrdenPorTamano}`).classList.remove('rollOut');
      document.getElementById(`editar${this.idOrdenPorTamano}`).classList.add('fadeInUp-2x');
      document.getElementById(`editar${this.idOrdenPorTamano}`).classList.remove('fadeOutDown-2x');
      document.getElementById(`eliminar${this.idOrdenPorTamano}`).classList.add('fadeInUp');
      document.getElementById(`eliminar${this.idOrdenPorTamano}`).classList.remove('fadeOutDown');
    } else {
      document.getElementById(`configurar${this.idOrdenPorTamano}`).classList.add('rollOut');
      document.getElementById(`configurar${this.idOrdenPorTamano}`).classList.remove('rollIn');
      document.getElementById(`editar${this.idOrdenPorTamano}`).classList.add('fadeOutDown-2x');
      document.getElementById(`editar${this.idOrdenPorTamano}`).classList.remove('fadeInUp-2x');
      document.getElementById(`eliminar${this.idOrdenPorTamano}`).classList.add('fadeOutDown');
      document.getElementById(`eliminar${this.idOrdenPorTamano}`).classList.remove('fadeInUp');
    }
  }

}
