export class CambioContrasena {
    contrasenaActual: string;
    contrasenaNueva: string;
    confirmacionContrasenaNueva: string;

    constructor() {
        this.contrasenaActual = '';
        this.contrasenaNueva = '';
        this.confirmacionContrasenaNueva = '';
    }
}