import { Component, OnInit, Inject } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Elemento } from 'src/app/shared/class-models/elemento.model';
import { Subscription } from 'rxjs';
import { ElementosService } from 'src/app/core/servicios/elementos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-editar-elemento-configuracion',
  templateUrl: './editar-elemento-configuracion.component.html',
  styleUrls: ['./editar-elemento-configuracion.component.scss']
})
export class EditarElementoConfiguracionComponent implements OnInit {
  titulo = 'Actualizar tipo de mesa';
  RequestState = RequestState;
  elemento: Elemento;

  /* VARIABLES PARA OBTENER EL ELEMENTO */
  obtenerElementoSubscription: Subscription;
  estadoPeticionObtenerElemento: number;

  /* VARIABLES PARA ACTUALIZAR EL ELEMENTO */
  actualizarElementoSubscription: Subscription;
  estadoPeticionActualizarElemento: number;

  constructor(
    private _elementosService: ElementosService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Elemento,
    public dialogRef: MatDialogRef<EditarElementoConfiguracionComponent>
    ) {
    this.estadoPeticionObtenerElemento = RequestState.initial;
    this.estadoPeticionActualizarElemento = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerElemento();
  }

  obtenerElemento(): void {
    this.estadoPeticionObtenerElemento = RequestState.loading;
    this.obtenerElementoSubscription = this._elementosService.obtenerElemento(this.data._id).subscribe(
      (elemento: Elemento) => {
        this.elemento = elemento;
        this.estadoPeticionObtenerElemento = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerElemento = RequestState.error;
        this.dialogRef.close();
      }
    );
  }

  transformarNumeroAsientos(numeroAsientos: string): void {
    if (numeroAsientos != null && numeroAsientos != undefined && !isNaN(Number(numeroAsientos))) {
      if (Number(numeroAsientos) < 1) {
        this.elemento.numeroAsientos = 1;
      } else {
        this.elemento.numeroAsientos = Number(numeroAsientos).toFixed(0);
      }
    } else {
      this.elemento.numeroAsientos = 1;
    }
  }

  actualizarElemento() {
    this.estadoPeticionActualizarElemento = RequestState.loading;
    this.actualizarElementoSubscription = this._elementosService.actualizarElemento(this.data._id, this.prepararDatosElemento()).subscribe(
      (elemento: Elemento) => {
        this.estadoPeticionActualizarElemento = RequestState.success;
        this.actualizarElementoOriginal(elemento)
        this.dialogRef.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarElemento = RequestState.error;
      }
    );
  }

  prepararDatosElemento(): Elemento {
    let elemento: Elemento = new Elemento();
    elemento.numeroAsientos = parseInt(this.elemento.numeroAsientos.toString());
    elemento.tipoDeArea = this.elemento.tipoDeArea;
    elemento.tipo = this.elemento.tipo;
    return elemento;
  }
  
  actualizarElementoOriginal(elemento: Elemento): void {
    this.data.numeroAsientos = elemento.numeroAsientos;
    this.data.tipo = elemento.tipo;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerElementoSubscription) this.obtenerElementoSubscription.unsubscribe();
    if(this.actualizarElementoSubscription) this.actualizarElementoSubscription.unsubscribe();
  }
}
