import { Area } from './area.model';
import { ElementoPorArea } from './elemento-por-area.model';
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';
import { GrupoDeOrdenes } from './grupo-de-ordenes.model';
import { Producto } from './producto.model';
import { Paquete } from './paquete.model';
import { Tamano } from './tamano.model';
import { Cuenta } from './cuenta.model';
import { Comanda } from './comanda.model';
import { Ingrediente } from './ingrediente.model';

export class AgregarIngrediente {
    _idIngrediente: string;
    ingrediente: Ingrediente;
    precio: number;

    constructor() {}
}

export class DatosProducto {
    _idProducto: string;
    producto: Producto;
    quitarIngredientes: Ingrediente[] | string[];
    agregarIngredientes: AgregarIngrediente[];
    notas: string[];

    constructor() {
        this.producto = new Producto();
        this.quitarIngredientes = [];
        this.agregarIngredientes = [];
        this.notas = [];
    }
}

export class AlternativaProductoPorTamano {
    _idTamano: string;
    tamano: Tamano;
    _idProducto: string;
    producto: Producto;
    quitarIngredientes: Ingrediente[] | string[];
    agregarIngredientes: AgregarIngrediente[];
    notas: string[];

    constructor() {
        this.quitarIngredientes = [];
        this.agregarIngredientes = [];
        this.notas = [];
    }
}

export class DatosPaquete {
    _idPaquete: string;
    paquete: Paquete;
    alternativasProductosPorTamano: AlternativaProductoPorTamano[];

    constructor() {
        this.paquete = new Paquete();
        this.alternativasProductosPorTamano = [];
    }
}

export class PrecioPorTamano {
    _idTamano: string;
    tamano: Tamano;
    precio: number;
}

export class Orden {
    _id?: string;
    id?: number;
    tipo: number;
    cantidad: number | string;
    precioUnitario: number;
    datosProducto: DatosProducto;
    datosPaquete: DatosPaquete;
    precioPorTamano: PrecioPorTamano;
    _idGrupoDeOrdenes: string;
    grupoDeOrdenes: GrupoDeOrdenes;
    _idCuenta: string;
    cuenta: Cuenta;
    _idComanda: string;
    comanda: Comanda;
    _idElementoPorArea: string;
    elementoPorArea: ElementoPorArea;
    _idArea: string;
    area: Area;
    _idRestaurante: string;
    restaurante: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.cantidad = 1;
        this.datosProducto = new DatosProducto();
        this.datosPaquete = new DatosPaquete();
        this.precioPorTamano = new PrecioPorTamano();
    }
}