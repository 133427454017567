import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { RecuperacionContrasena } from 'src/app/shared/class-models/recuperacion-contrasena.model';

@Injectable({
    providedIn: 'root'
})
export class PerfilService {
    private rutaBasePerfil = '/perfil';

    constructor(private httpClient: HttpClient) { }

    /* GET */
    obtenerPerfil(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBasePerfil}`, AppSettings.Options)
    }

    obtenerCajaPerfil(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBasePerfil}/caja`, AppSettings.Options)
    }

    /* POST */

    /* PUT */
    actualizarPerfil(usuario: Usuario): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBasePerfil}`, JSON.stringify(usuario), AppSettings.Options);
    }

    /* PATCH */
    actualizarContrasenaPerfil(contrasenaActual: string, contrasenaNueva: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBasePerfil}/contrasena`, JSON.stringify({ contrasenaActual, contrasenaNueva }), AppSettings.Options);
    }

    actualizarContrasenaConCodigoDeRecuperacionPerfil(recuperacionContrasena: RecuperacionContrasena): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBasePerfil}/contrasena-con-codigo-de-recuperacion`, JSON.stringify(recuperacionContrasena), AppSettings.Options);
    }

    actualizarCodigoDeRecuperacionContrasenaPerfil(nombreUsuario: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBasePerfil}/codigo-de-recuperacion`, JSON.stringify({nombreUsuario}), AppSettings.Options);
    }
}
