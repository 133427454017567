import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Producto} from '../../shared/models/producto';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppSettings} from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) {
  }

  obtenerProducto(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/producto.php?idProducto=` + id, AppSettings.Options);
  }

  obtenerProductos(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/productos/obtener.php`, AppSettings.Options);
  }

  obtenerProductosDeCocina(idSucursal: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/productos/cocina.php?id=` + idSucursal, AppSettings.Options);
  }

  agregarProductoACocina(idSucursal: string, idProducto: string): Observable<object> {
    const body = {
      idSucursal,
      idProducto
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/producto-cocina.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarProductoDeCocina(idSucursal: string, idProducto: string): Observable<object> {
    return this.httpClient.delete(
      `${AppSettings.APIEndpoint}/producto-cocina.php?idSucursal=${idSucursal}&idProducto=${idProducto}`,
      AppSettings.Options);
  }

  obtenerProductosDetallados(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/productos/obtener-detalles.php`, AppSettings.Options);
  }

  obtenerProductosSucursal(idSucursal: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/productos/obtener-sucursal.php?id=` + idSucursal, AppSettings.Options);
  }

  nuevoProducto(producto: Producto): Observable<any> {
    const body = {
      product: producto
    };
    return this.httpClient.post<any>(`${AppSettings.APIEndpoint}/producto.php`, JSON.stringify(body), AppSettings.Options);
  }

  actualizarProducto(id: string, producto: Producto) {
    producto.id = id;
    const body = {
      product: producto
    };
    return this.httpClient.put<any>(`${AppSettings.APIEndpoint}/producto.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarProducto(id: string) {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/producto.php?id=${id}`, AppSettings.Options);
  }
}
