import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { LicenciasService } from 'src/app/core/servicios/licencias.service';
import { Licencia } from 'src/app/shared/class-models/licencia.model';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { FormasDePago } from 'src/app/shared/constants/formas-de-pago';
import { RequestState } from '../../../../../shared/enums/request-state.enum';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { NgForm, NgModel } from '@angular/forms';
import { startWith, map, skip, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-license',
  templateUrl: './add-license.component.html'
})
export class AddLicenseComponent implements OnInit, OnDestroy {
  titulo: string = 'Agregar licencia';
  ExpresionesRegulares = ExpresionesRegulares;
  FormasDePago = FormasDePago;
  RequestState = RequestState;
  licencia: Licencia = new Licencia();
  usuariosAdministradores: Usuario[];
  estadoPeticionObtenerUsuariosAdministradores: number;
  obtenerUsuariosAdministradoresSubscripcion: Subscription;
  estadoPeticionAgregarLicencia: number;
  agregarLicenciaSubscripcion: Subscription;
  usuariosFiltrados: Observable<Usuario[]>;
  filtro: string = '';
  usuariosAdministradoresFiltrados: Usuario[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modal: MatDialogRef<AddLicenseComponent>,
    private _licenciasService: LicenciasService,
    private _usuariosService: UsuariosService,
    private _alertasService: AlertasService
  ) {
    this.estadoPeticionObtenerUsuariosAdministradores = RequestState.initial;
    this.estadoPeticionAgregarLicencia = RequestState.initial;
    this.licencia.esDemo = false;
    this.inicializarDatosLicencia()
  }

  ngOnInit(): void {
    this.obtenerUsuariosAdministradores();
  }

  obtenerUsuariosAdministradores() {
    this.estadoPeticionObtenerUsuariosAdministradores = RequestState.loading;
    this.obtenerUsuariosAdministradoresSubscripcion = this._usuariosService.obtenerUsuariosAdministradores().subscribe(
      (usuarios: Usuario[]) => {
        if (usuarios.length == 0) {
          this._alertasService.alertaErrorSinConfirmacion('No se tienen usuarios registrados', 'No hay usuarios registrados para asignarles una licencia, favor de intentar mas tarde');
          this.modal.close();
        } else {
          this.usuariosAdministradores = usuarios.sort((usuario1, usuario2) => {
            let nombreCompletoUsuario1: string = usuario1.nombres + ' ' + usuario1.primerApellido + (usuario1.segundoApellido ? (' ' + usuario1.segundoApellido) : '');
            let nombreCompletoUsuario2: string = usuario2.nombres + ' ' + usuario2.primerApellido + (usuario2.segundoApellido ? (' ' + usuario2.segundoApellido) : '');
            if (nombreCompletoUsuario1 > nombreCompletoUsuario2) return 1;
            if (nombreCompletoUsuario1 < nombreCompletoUsuario2) return -1;
            return 0;
          });
          this.usuariosAdministradoresFiltrados = usuarios;
        }
        this.estadoPeticionObtenerUsuariosAdministradores = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUsuariosAdministradores = RequestState.error;
        this.modal.close();
      }
    );
  }

  inicializarDatosLicencia() {
    if (this.licencia.esDemo) {
      this.licencia.meses = 1;
      this.licencia.fechaInicio = new Date();
      this.licencia.fechaFin = new Date(new Date().setMonth(new Date().getMonth() + this.licencia.meses));
      this.licencia.restaurantes = 1;
      this.licencia.usuarios = 8;
      this.licencia.monto = Number('0').toFixed(2);
      this.licencia.formaDePago = '15';
    } else {
      this.licencia.meses = 1;
      this.licencia.fechaInicio = new Date();
      this.licencia.fechaFin = new Date(new Date().setMonth(new Date().getMonth() + this.licencia.meses));
      this.licencia.restaurantes = 1;
      this.licencia.usuarios = 1;
      this.licencia.monto = Number('0').toFixed(2);
      this.licencia.formaDePago = null;
    }
  }

  transformarPrecio(monto: string) {
    if (monto != null && monto != undefined && !isNaN(Number(monto))) {
      this.licencia.monto = Number(monto).toFixed(2);
    } else {
      this.licencia.monto = Number('0').toFixed(2);
    }
  }

  calcularValidezLicencia(cantidadMeses: string) {
    if (cantidadMeses != null && cantidadMeses != undefined && !isNaN(Number(cantidadMeses))) {
      this.licencia.fechaInicio = new Date();
      this.licencia.fechaFin = new Date(new Date().setMonth(new Date().getMonth() + Number(this.licencia.meses)));
    }
  }

  transformarCantidadMeses(cantidadMeses: string) {
    this.licencia.meses = Number(this.transformarCantidadEntera(cantidadMeses));
    this.licencia.fechaInicio = new Date();
    this.licencia.fechaFin = new Date(new Date().setMonth(new Date().getMonth() + this.licencia.meses));
  }

  transformarCantidadRestaurantes(cantidadRestaurantes: string) {
    this.licencia.restaurantes = Number(this.transformarCantidadEntera(cantidadRestaurantes));
  }

  transformarCantidadUsuarios(cantidadUsuarios: string) {
    this.licencia.usuarios = Number(this.transformarCantidadEntera(cantidadUsuarios));
  }

  transformarCantidadEntera(cantidad: string): string {
    if (cantidad != null && cantidad != undefined && !isNaN(Number(cantidad))) {
      if (Number(cantidad) < 1) {
        return '1';
      } else {
        return Number(cantidad).toFixed(0);
      }
    } else {
      return '1';
    }
  }

  agregarLicencia(): void {
    this.estadoPeticionAgregarLicencia = RequestState.loading;
    this.agregarLicenciaSubscripcion = this._licenciasService.agregarLicencia(this.prepararDatosLicencia()).subscribe(
      (licencia: Licencia) => {
        this.estadoPeticionAgregarLicencia = RequestState.success;
        this.modal.close(licencia);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarLicencia = RequestState.error;
      }
    );
  }

  prepararDatosLicencia(): Licencia {
    let licencia = new Licencia();
    licencia._idAdministrador = this.licencia._idAdministrador;
    licencia.meses = parseInt(this.licencia.meses.toString());;
    licencia.fechaInicio = this.licencia.fechaInicio;
    licencia.fechaFin = this.licencia.fechaFin;
    licencia.restaurantes = parseInt(this.licencia.restaurantes.toString());
    licencia.usuarios = parseInt(this.licencia.usuarios.toString());
    licencia.formaDePago = this.licencia.formaDePago;
    licencia.monto = parseFloat(this.licencia.monto.toString());
    licencia.esDemo = this.licencia.esDemo;
    return licencia;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  buscarUsuarioAdministrador() {
    this.usuariosAdministradoresFiltrados = this.usuariosAdministradores.filter(usuarioAdministrador => {
      return (usuarioAdministrador.nombres + ' ' + usuarioAdministrador.primerApellido + ' ' + usuarioAdministrador.segundoApellido)
        .trim().toLowerCase().indexOf(this.filtro.trim().toLowerCase()) !== -1 ||
        usuarioAdministrador.nombreUsuario.trim().toLowerCase().indexOf(this.filtro.trim().toLowerCase()) != -1 ;
    });
    if (this.usuariosAdministradoresFiltrados.length > 0 && this.filtro !== '') {
      this.licencia._idAdministrador = this.usuariosAdministradoresFiltrados[0]._id;
    }
    if (this.filtro == '') {
      this.licencia._idAdministrador = '';
    }
  }

  ngOnDestroy(): void {
    if (this.obtenerUsuariosAdministradoresSubscripcion) this.obtenerUsuariosAdministradoresSubscripcion.unsubscribe();
    if (this.agregarLicenciaSubscripcion) this.agregarLicenciaSubscripcion.unsubscribe();
  }
}
