import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: []
})
export class AdminComponent implements OnInit, OnDestroy {
  @Input() tipoUsuario: number;
  @Input() _idRestaurante: string;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
