import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {HttpClient} from '@angular/common/http';
import {Cuenta} from '../../shared/models/cuenta';
import {map} from 'rxjs/operators';
import {Orden} from '../../shared/models/orden';
import { GrupoOrdenes } from 'src/app/shared/models/grupo-ordenes';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  public deteccionCambios: any;
  public deteccionCambiosEnOrdenes: any;
  public deteccionCambiosTipoImpresion: any;
  public deteccionCambiosGrupoOrdenes: any;
  public deteccionCambiosOrdenPrincipal: any;
  public deteccionCambiosOrdenesAgrupadas: any;
  private cuentaActual: Cuenta;
  private ordenesCuentaActual: Orden[];
  private tipoImpresion: number; //0 para imprimir cuenta y 1 para imprimir ordenes
  private grupoOrdenes: GrupoOrdenes;
  private ordenPrincipal: Orden[];
  private ordenesAgrupadas: Orden[][];

  constructor(private httpClient: HttpClient) {
    this.cuentaActual = null;
    this.ordenesCuentaActual = null;
    this.deteccionCambios = new BehaviorSubject(this.cuentaActual);
    this.deteccionCambiosEnOrdenes = new BehaviorSubject(this.ordenesCuentaActual);
    this.deteccionCambiosTipoImpresion = new BehaviorSubject(this.tipoImpresion);
    this.deteccionCambiosGrupoOrdenes = new BehaviorSubject(this.grupoOrdenes);
    this.deteccionCambiosOrdenPrincipal = new BehaviorSubject(this.ordenPrincipal);
    this.deteccionCambiosOrdenesAgrupadas = new BehaviorSubject(this.ordenesAgrupadas);
  }

  setTipoImpresion(valor: number) {
    this.tipoImpresion = valor;
    this.deteccionCambiosTipoImpresion.next(this.tipoImpresion);
  }

  setGrupoOrdenes(grupoOrdenes: GrupoOrdenes) {
    this.grupoOrdenes = grupoOrdenes;
    this.deteccionCambiosGrupoOrdenes.next(this.grupoOrdenes);
  }

  setOrdenPrincipal(ordenPrincipal: Orden[]) {
    this.ordenPrincipal = ordenPrincipal;
    this.deteccionCambiosOrdenPrincipal.next(this.ordenPrincipal);
  }

  setOrdenesAgrupadas(ordenesAgrupadas: Orden[][]) {
    this.ordenesAgrupadas = ordenesAgrupadas;
    this.deteccionCambiosOrdenesAgrupadas.next(this.ordenesAgrupadas);
  }

  getCuentaActual() {
    return this.cuentaActual;
  }

  setCuentaActual(cuenta: Cuenta) {
    this.cuentaActual = cuenta;
    this.deteccionCambios.next(this.cuentaActual);
  }

  getOrdenesCuentaActual() {
    return this.ordenesCuentaActual;
  }

  abrirCaja(): Observable<boolean> {
    return new Observable<boolean>( // Simulación de petición
      observer => {
        setTimeout(
          () => {
            observer.next(true);
          },
          1000
        );
      }
    );
  }

  cerrarCaja(): Observable<boolean> {
    return this.abrirCaja(); // Simulación de petición
  }

  obtenerCuenta(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cuenta.php?id=` + id, AppSettings.Options)
      .pipe(map((res: Cuenta[]) => {
        const fl = res.filter(cuenta => cuenta.id === id);
        this.cuentaActual = (fl.length > 0) ? fl[0] : null;
        this.deteccionCambios.next(this.cuentaActual);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  nuevaCuenta(cuenta: Cuenta, idOrdenes: string[]): Observable<any> {
    const body = {
      cuenta,
      idOrdenes
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/cuenta.php`, JSON.stringify(body), AppSettings.Options);
  }

  obtenerOrdenesDeCuenta(idCuenta: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cuentas/ordenes-de-cuenta.php?idCuenta=` + idCuenta, AppSettings.Options);
  }

  obtenerOrdenesDeCuentaParaTicket(idCuenta: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cuentas/ordenes-de-cuenta-para-ticket.php?idCuenta=` + idCuenta, AppSettings.Options)
      .pipe(map((res: Orden[]) => {
        this.ordenesCuentaActual = res;
        this.deteccionCambiosEnOrdenes.next(this.ordenesCuentaActual);
        return res;
      }));
  }
}
