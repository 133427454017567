import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppSettings} from 'src/app/configs/app-settings.config';
import { Paquete } from 'src/app/shared/class-models/paquete.model';

@Injectable({
  providedIn: 'root'
})
export class PaquetesService {
    private rutaBasePaquetes = '/paquetes';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerPaquetes(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBasePaquetes}`, AppSettings.Options);
    }

    obtenerPaquetesVigentes(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBasePaquetes}/vigentes`, AppSettings.Options);
    }

    obtenerPaquete(_idPaquete: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBasePaquetes}/${_idPaquete}`, AppSettings.Options);
    }

    /* POST */
    agregarPaquete(paquete: Paquete) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBasePaquetes}`, JSON.stringify(paquete), AppSettings.Options);
    }

    /* PUT */
    actualizarPaquete(_idPaquete: string, paquete: Paquete) {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBasePaquetes}/${_idPaquete}`, JSON.stringify(paquete), AppSettings.Options);
    }

    /* PATCH */
    eliminarPaquete(_idPaquete: string) {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBasePaquetes}/${_idPaquete}`, AppSettings.Options);
    }

  /*

  obtenerPaqueteSucursal(id: string, idSucursal: String): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/paquete.php?id=` + id + `&idSucursal=` + idSucursal, AppSettings.Options)
      .pipe(map((res: Paquete[]) => {
        const fl = res.filter(paquete => paquete.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }*/
}
