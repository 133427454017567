import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CajasDeUsuarioService } from 'src/app/core/servicios/cajas-de-usuario.services';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Meses } from 'src/app/shared/enums/meses.enum';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { TipoDeBusqueda } from 'src/app/shared/enums/tipo-de-busqueda.enum';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { FechaConHoraPipe } from 'src/app/shared/pipes/fecha-con-hora.pipe';

@Component({
  selector: 'app-historial-cajas',
  templateUrl: './historial-cajas.component.html',
  styleUrls: ['./historial-cajas.component.scss']
})
export class HistorialCajasComponent implements OnInit, OnDestroy {
  @Input() tipoDeUsuario: number;
  @Output() cajaDeUsuario = new EventEmitter<CajaDeUsuario>();
  RequestState = RequestState;
  ExpresionesRegulares = ExpresionesRegulares;
  estadoPeticionObtenerRestaurantes: number;
  obtenerRestaurantesSubscripcion: Subscription;
  restaurantes: Restaurante[];
  _idRestaurante: string;
  TipoUsuario = TipoUsuario;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<CajaDeUsuario>;
  filtro: string = '';
  estadoPeticionObtenerCajasDeUsuario: number;
  obtenerCajasDeUsuarioPorFechaSubscripcion: Subscription;
  columnas: string[] = [];
  TipoDeBusqueda = TipoDeBusqueda;
  tipoDeBusqueda: number;
  anios: number[];
  anio: number;
  Meses = Meses;
  mes: number;
  dias: number[];
  dia: number;

  constructor(
    private _authService: AuthService,
    private _restaurantesService: RestaurantesService,
    private _cajasDeUsuarioService: CajasDeUsuarioService,
    private fechaConHora: FechaConHoraPipe,
    private _alertasService: AlertasService,
  ) {
    this.estadoPeticionObtenerRestaurantes = RequestState.initial;
    this.inicializarReporte();
  }

  ngOnInit() {
    this.inicializarColumnasDeLaTabla();
    if (this.tipoDeUsuario == TipoUsuario.Administrador) {
      this.obtenerRestaurantes();
    } else {
      this._idRestaurante = this._authService.obtenerUsuario()._idRestaurante;
      this.obtenerCajasDeUsuarioPorFecha();
    }
  }

  inicializarReporte(): void {
    this.tipoDeBusqueda = TipoDeBusqueda.Diaria;
    const fechaActual: Date = new Date(Date.now());
    this.anio = fechaActual.getFullYear();
    this.mes = fechaActual.getMonth() + 1;
    this.inicializarAnios();
    this.inicializarDias(fechaActual.getDate());
  }
  
  inicializarAnios(): void {
    this.anios = [];
    for (let anio = 2020; anio <= this.anio; anio++) {
      this.anios.push(anio);
    }
  }

  inicializarDias(diaInicializar: number): void {
    this.dias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
    const maximoNumeroDeDias = new Date(this.anio, this.mes, 0).getDate();
    for (let dia = 29; dia <= maximoNumeroDeDias; dia++) {
      this.dias.push(dia);
    }
    this.dia = diaInicializar;
  }

  /* MÉTODO PARA INICIALIZAR LAS COLUMNAS DE LA TABLA */
  inicializarColumnasDeLaTabla() {
    switch (this.tipoDeUsuario) {
      case TipoUsuario.Administrador:
      case TipoUsuario.Gerente:
        this.columnas = ['usuario', 'fechaApertura', 'fechaCierre', 'montoApertura', 'montoVentaTotal', 'montoCierre', 'opciones'];
        break;
      case TipoUsuario.Cajero:
      case TipoUsuario.Barman:
        this.columnas = ['fechaApertura', 'fechaCierre', 'montoApertura', 'montoVentaTotal', 'montoCierre', 'opciones'];
        break;
    }
  }

  /* MÉTODO PARA OBTENER LOS RESTAURANTES */
  obtenerRestaurantes(): void {
    this.restaurantes = [];
    this.estadoPeticionObtenerRestaurantes = RequestState.loading;
    this.obtenerRestaurantesSubscripcion = this._restaurantesService.obtenerRestaurantes().subscribe(
      (restaurantes: Restaurante[]) => {
        this.restaurantes = restaurantes;
        this._idRestaurante = this.restaurantes.length > 0 ? this.restaurantes[0]._id : null;
        this.obtenerCajasDeUsuarioPorFecha();
        this.estadoPeticionObtenerRestaurantes = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerRestaurantes = RequestState.error;
      }
    );
  }

  /* MÉTODO PARA OBTENER LAS CAJAS DE USUARIO DE UN RESTAURANTE */
  obtenerCajasDeUsuarioPorFecha(): void {
    this.estadoPeticionObtenerCajasDeUsuario = RequestState.loading;
    this.obtenerCajasDeUsuarioPorFechaSubscripcion = this._cajasDeUsuarioService.obtenerCajasDeUsuarioPorFecha(this._idRestaurante, this.tipoDeBusqueda, this.anio, this.mes, this.dia).subscribe(
      (cajasDeUsuario: CajaDeUsuario[]) => {
        this.inicializarCajasDeUsuario(cajasDeUsuario)
        this.estadoPeticionObtenerCajasDeUsuario = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCajasDeUsuario = RequestState.error;
      }
    );
  }

  inicializarCajasDeUsuario(cajasDeUsuario: CajaDeUsuario[]): void {
    this.dataSource = new MatTableDataSource<CajaDeUsuario>(cajasDeUsuario);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (cajaDeUsuario: CajaDeUsuario, filtro: string) => {
      return ((this.tipoDeUsuario == TipoUsuario.Administrador || this.tipoDeUsuario == TipoUsuario.Gerente) ? (this.obtenerNombreCompletoUsuario(cajaDeUsuario.usuario).trim().toLowerCase().indexOf(filtro) !== -1) : false) ||
        ('$' + Number(cajaDeUsuario.montoApertura).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1 ||
        (cajaDeUsuario.montoCierreReal ? (('$' + Number(cajaDeUsuario.montoCierreReal).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1) : false) ||
        (cajaDeUsuario.montoCierreEsperado ? (('$' + Number(cajaDeUsuario.montoCierreEsperado).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1) : false) ||
        this.fechaConHora.transform(cajaDeUsuario.fechaApertura).trim().toLowerCase().indexOf(filtro) !== -1 ||
        (cajaDeUsuario.fechaCierre ? (this.fechaConHora.transform(cajaDeUsuario.fechaCierre).trim().toLowerCase().indexOf(filtro) !== -1) : false);
    }
  }

  buscarCajaDeUsuario(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerNombreCompletoUsuario(usuario: Usuario): string {
    return usuario.nombres + ' ' + usuario.primerApellido + (usuario.segundoApellido ? (' ' + usuario.segundoApellido) : '');
  }

  obtenerTipoDeBusqueda(tipoDeBusqueda): string {
    switch (tipoDeBusqueda) {
      case TipoDeBusqueda.Anual: return 'Anual';
      case TipoDeBusqueda.Mensual: return 'Mensual';
      case TipoDeBusqueda.Diaria: return 'Diaria';
      default: return '';
    }
  }

  obtenerMes(mes): string {
    switch (mes) {
      case Meses.Enero: return 'Enero';
      case Meses.Febrero: return 'Febrero';
      case Meses.Marzo: return 'Marzo';
      case Meses.Abril: return 'Abril';
      case Meses.Mayo: return 'Mayo';
      case Meses.Junio: return 'Junio';
      case Meses.Julio: return 'Julio';
      case Meses.Agosto: return 'Agosto';
      case Meses.Septiembre: return 'Septiembre';
      case Meses.Octubre: return 'Octubre';
      case Meses.Noviembre: return 'Noviembre';
      case Meses.Diciembre: return 'Diciembre';
      default: return '';
    }
  }

  verCajaDeUsuario(cajaDeUsuario: CajaDeUsuario): void {
    this.cajaDeUsuario.emit(cajaDeUsuario);
  }

  ngOnDestroy(): void {
    if(this.obtenerRestaurantesSubscripcion) this.obtenerRestaurantesSubscripcion.unsubscribe();
    if(this.obtenerCajasDeUsuarioPorFechaSubscripcion) this.obtenerCajasDeUsuarioPorFechaSubscripcion.unsubscribe();
  }
}