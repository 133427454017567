import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { FormasDePago } from 'src/app/shared/constants/formas-de-pago';
import { RequestState } from '../../../../../shared/enums/request-state.enum';
import { Usuario } from '../../../../../shared/models/usuario';
import { Licencia } from 'src/app/shared/class-models/licencia.model';
import { LicenciasService } from 'src/app/core/servicios/licencias.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html'
})
export class EditLicenseComponent implements OnInit, OnDestroy {
  titulo: string = 'Renovar licencia';
  ExpresionesRegulares = ExpresionesRegulares;
  FormasDePago = FormasDePago;
  RequestState = RequestState;
  licencia: Licencia = new Licencia();
  estadoPeticionObtenerLicencia: number;
  obtenerLicenciaSubscripcion: Subscription;
  usuariosAdministradores: Usuario[];
  estadoPeticionObtenerUsuariosAdministradores: number;
  obtenerUsuariosAdministradoresSubscripcion: Subscription;
  estadoPeticionActualizarLicencia: number;
  actualizarLicenciaSubscripcion: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Licencia,
    private modal: MatDialogRef<EditLicenseComponent>,
    private _licenciasService: LicenciasService,
    private _usuariosService: UsuariosService,
    private _alertasService: AlertasService) {
    this.estadoPeticionObtenerLicencia = RequestState.initial;
    this.estadoPeticionObtenerUsuariosAdministradores = RequestState.initial;
    this.estadoPeticionActualizarLicencia = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerLicencia();
    this.obtenerUsuariosAdministradores();
  }

  obtenerLicencia() {
    this.estadoPeticionObtenerLicencia = RequestState.loading;
    this.obtenerLicenciaSubscripcion = this._licenciasService.obtenerLicencia(this.data._id).subscribe(
      (licencia: Licencia) => {
        this.licencia = licencia;
        this.licencia.meses = licencia.meses;
        this.licencia.fechaInicio = new Date();
        this.licencia.fechaFin = new Date(new Date().setMonth(new Date().getMonth() + Number(this.licencia.meses)));
        this.licencia.restaurantes = licencia.restaurantes;
        this.licencia.usuarios = licencia.usuarios;
        this.licencia.formaDePago = licencia.formaDePago;
        this.licencia.monto = Number(licencia.monto).toFixed(2);
        this.estadoPeticionObtenerLicencia = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerLicencia = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerUsuariosAdministradores() {
    this.estadoPeticionObtenerUsuariosAdministradores = RequestState.loading;
    this.obtenerUsuariosAdministradoresSubscripcion = this._usuariosService.obtenerUsuariosAdministradores().subscribe(
      (usuarios: Usuario[]) => {
        this.usuariosAdministradores = usuarios;
        this.estadoPeticionObtenerUsuariosAdministradores = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUsuariosAdministradores = RequestState.error;
        this.modal.close();
      }
    );
  }

  transformarPrecio(monto: string) {
    if (monto != null && monto != undefined && !isNaN(Number(monto))) {
      this.licencia.monto = Number(monto).toFixed(2);
    } else {
      this.licencia.monto = Number('0').toFixed(2);
    }
  }

  calcularValidezLicencia(cantidadMeses: string) {
    if (cantidadMeses != null && cantidadMeses != undefined && !isNaN(Number(cantidadMeses))) {
      this.licencia.fechaInicio = new Date();
      this.licencia.fechaFin = new Date(new Date().setMonth(new Date().getMonth() + Number(this.licencia.meses)));
    }
  }

  transformarCantidadMeses(cantidadMeses: string) {
    this.licencia.meses = Number(this.transformarCantidadEntera(cantidadMeses));
    this.licencia.fechaInicio = new Date();
    this.licencia.fechaFin = new Date(new Date().setMonth(new Date().getMonth() + this.licencia.meses));
  }

  transformarCantidadRestaurantes(cantidadRestaurantes: string) {
    this.licencia.restaurantes = Number(this.transformarCantidadEntera(cantidadRestaurantes));
  }

  transformarCantidadUsuarios(cantidadUsuarios: string) {
    this.licencia.usuarios = Number(this.transformarCantidadEntera(cantidadUsuarios));
  }

  transformarCantidadEntera(cantidad: string): string {
    if (cantidad != null && cantidad != undefined && !isNaN(Number(cantidad))) {
      if (Number(cantidad) < 1) {
        return '1';
      } else {
        return Number(cantidad).toFixed(0);
      }
    } else {
      return '1';
    }
  }

  actualizarLicencia(): void {
    this.estadoPeticionActualizarLicencia = RequestState.loading;
    this.actualizarLicenciaSubscripcion = this._licenciasService.actualizarLicencia(this.data._id, this.prepararDatosLicencia()).subscribe(
      (licencia: Licencia) => {
        this.actualizarLicenciaOriginal(licencia);
        this.estadoPeticionActualizarLicencia = RequestState.success;
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarLicencia = RequestState.error;
      }
    );
  }

  prepararDatosLicencia(): Licencia {
    let licencia = new Licencia();
    licencia._idAdministrador = this.licencia._idAdministrador;
    licencia.meses = this.licencia.meses;
    licencia.fechaInicio = this.licencia.fechaInicio;
    licencia.fechaFin = this.licencia.fechaFin;
    licencia.restaurantes = parseInt(this.licencia.restaurantes.toString());
    licencia.usuarios = parseInt(this.licencia.usuarios.toString());
    licencia.formaDePago = this.licencia.formaDePago;
    licencia.monto = parseFloat(this.licencia.monto.toString());
    licencia.esDemo = false;
    return licencia;
  }

  actualizarLicenciaOriginal(licencia: Licencia): void {
    this.data._id = licencia._id;
    this.data.id = licencia.id;
    this.data._idAdministrador = licencia._idAdministrador;
    this.data.administrador = licencia.administrador;
    this.data.meses = licencia.meses;
    this.data.fechaInicio = licencia.fechaInicio;
    this.data.fechaFin = licencia.fechaFin;
    this.data.restaurantes = licencia.restaurantes;
    this.data.usuarios = licencia.usuarios;
    this.data.formaDePago = licencia.formaDePago;
    this.data.monto = licencia.monto;
    this.data.esDemo = licencia.esDemo;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerLicenciaSubscripcion) this.obtenerLicenciaSubscripcion.unsubscribe();
    if (this.obtenerUsuariosAdministradoresSubscripcion) this.obtenerUsuariosAdministradoresSubscripcion.unsubscribe();
    if (this.actualizarLicenciaSubscripcion) this.actualizarLicenciaSubscripcion.unsubscribe();
  }
}
