import { Ciudad } from './ciudad.model';
import { CodigoPostal } from './codigo-postal.model';
import { Estado } from './estado.model';
import { Usuario } from './usuario.model';

export class Proveedor {
    _id: string;
    id: number;
    tipoDePersona: number;
    nombres: string;
    primerApellido: string;
    segundoApellido?: string;
    razonSocial: string;
    tieneRepresentante?: boolean;
    nombresRepresentante?: string;
    primerApellidoRepresentante?: string;
    segundoApellidoRepresentante?: string;
    rfc: string;
    estado?: Estado;
    idEstado?: number;
    ciudad?: Ciudad;
    idCiudad?: number;
    calle?: string;
    colonia?: string;
    numeroExterior: string;
    numeroInterior?: string;
    codigoPostal: CodigoPostal;
    idCodigoPostal?: number;
    telefono?: string;
    correo?: string;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.tieneRepresentante = false;
    }
}