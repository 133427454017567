import {CommonModule} from '@angular/common';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatTabsModule} from '@angular/material/tabs';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTableModule,
  MatTooltipModule,
  MatProgressBarModule
} from '@angular/material';
import {RestaurantsModule} from '../restaurants/restaurants.module';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {RestaurantComponent} from './restaurant.component';
// Pages.
import {AreaModule} from './pages/area/area.module';
import {AreasModule} from './pages/areas/areas.module';
import {CashRegisterModule} from './pages/cash-register/cash-register.module';
import {InventoryModule} from './pages/inventory/inventory.module';
import {StockModule} from './pages/stock/stock.module';
// Components.
import {EditRestaurantComponent} from './pages/edit-restaurant/edit-restaurant.component';
import {LicenceInfoComponent} from './pages/dialogs/licence-info/licence-info.component';
import {NewLicenceComponent} from './pages/dialogs/new-licence/new-licence.component';
// Routes.
import {RestaurantRoutes} from './restaurant.routes';
// Pipes.
import {PipesModule} from '../../shared/pipes/pipes.module';
import {KdsConfComponent} from './pages/kds-conf/kds-conf.component';
import { AdministracionGastosModule } from './pages/administracion-gastos/administracion-gastos.module';
import { AdministracionInsumosModule } from './pages/administracion-insumos/administracion-insumos.module';
import { AdministracionExistenciasModule } from './pages/administracion-existencias/administracion-existencias.module';

@NgModule({
  declarations: [
    RestaurantComponent,
    EditRestaurantComponent,
    NewLicenceComponent,
    LicenceInfoComponent,
    KdsConfComponent,
  ],
  imports: [
    RouterModule.forRoot(RestaurantRoutes),
    AreaModule,
    AreasModule,
    CashRegisterModule,
    AdministracionGastosModule,
    AdministracionInsumosModule,
    AdministracionExistenciasModule,
    InventoryModule,
    StockModule,
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatStepperModule,
    RestaurantsModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    ImageCropperModule,
    MatSelectModule,
    PipesModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule
  ],
  exports: [
    RestaurantComponent,
    AreaModule,
    AreasModule,
    CashRegisterModule,
    InventoryModule,
    StockModule
  ]
})
export class RestaurantModule {
}
