import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class UsuarioNoAutenticadoGuard implements CanActivate {
    constructor(private authService: AuthService,
        private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authService.obtenerToken()) return true;
        else {
            this.router.navigate(['inicio']);
            return false;
        }
    }
}
