import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Paquete } from 'src/app/shared/class-models/paquete.model';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { MatDialog } from '@angular/material';
import { VistaPaqueteModalComponent } from 'src/app/modules/promo/vista-paquete-modal/vista-paquete-modal.component';
import { NuevaOrdenPaqueteModalComponent } from '../modales/nueva-orden-paquete-modal/nueva-orden-paquete-modal.component';
import { ControlInternoExistenciaDeProductoPorTamano } from 'src/app/shared/class-models/control-interno-existencia-de-producto-por-tamano.enum';

@Component({
  selector: 'app-tarjeta-orden-paquete',
  templateUrl: './tarjeta-orden-paquete.component.html',
  styleUrls: ['./tarjeta-orden-paquete.component.scss']
})
export class TarjetaOrdenPaqueteComponent implements OnInit {
  @Input() paquete: Paquete;
  @Input() _idRestaurante: string;
  @Output() orden = new EventEmitter<Orden>();
  @Input() controlInternoExistenciasDeProductosPorTamano: ControlInternoExistenciaDeProductoPorTamano[];
  AppSettings = AppSettings;

  constructor(public modal: MatDialog) {}

  ngOnInit(): void {}

  verPaquete() {
    const dialogRef = this.modal.open(VistaPaqueteModalComponent,
      {
        disableClose: true,
        data: this.paquete
      });
  }

  agregarOrden(): void {
    const dialogRef = this.modal.open(NuevaOrdenPaqueteModalComponent,
      {
        disableClose: true,
        data: {
          _idRestaurante: this._idRestaurante,
          paquete: this.paquete,
          controlInternoExistenciasDeProductosPorTamano: this.controlInternoExistenciasDeProductosPorTamano
        }
      });
    dialogRef.afterClosed().subscribe(
      (orden: Orden) => {
        if (orden) {
          this.orden.emit(orden);
          /*if (this.product.existencia != 'NA') {
            (this.product.existencia as number) -= res.orden.cantidad;
          }
          this.orden.emit(res.orden);*/
          /*this.control++;
          this.quantity.emit(this.control);*/
        }
      }
    );
  }
  /*add(): void {
    const dialogRef = this.dialog.open(PromoSizeComponent,
      {
        disableClose: true,
        data: {
          id: this.promo.id,
          nombre: this.promo.nombre,
          idSucursal: this.idSucursal,
        }
      });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res.ok) {
          this.orden.emit(res.orden);
        }
      }
    );
  }*/
}
