import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {BaseChartDirective, Color, Label} from 'ng2-charts';
import {formatCurrency} from '@angular/common';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import { ReportesService } from 'src/app/core/servicios/reportes.service';
import { TipoDeReporte } from 'src/app/shared/enums/tipo-de-reporte.enum';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';

@Component({
  selector: 'app-restaurant-report',
  templateUrl: './restaurant-report.component.html',
  styleUrls: []
})
export class RestaurantReportComponent implements OnInit, OnDestroy {
  @Input() tipoUsuario: number;
  @Input() _idRestaurante: string;
  public lineChartData: ChartDataSets[] = [
    {data: [], label: 'Ventas'},
    {data: [], label: 'Propinas'},
    {data: [], label: 'Gastos de insumos'},
    {data: [], label: 'Gastos generales'}
  ];
  public lineChartLabels: Label[];
  public monthsLabels: Label[] =
    ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            // Include a dollar sign in the ticks
            callback(value, index, values) {
              return formatCurrency(parseFloat(value.toString()),
                'en',
                '$',
                'MXN',
                '.2-2');
            }
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter(value) {
          const amount = formatCurrency(parseFloat(value.toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
          return amount === '$0.00' ? '' : amount;
        },
      },
    },
    tooltips: {
      callbacks: {
        label(tooltipItems, data) {
          return formatCurrency(parseFloat(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
        }
      }
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          borderWidth: 2,
          label: {
            enabled: true,
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // green
      backgroundColor: 'rgba(7,111,0,0.3)',
      borderColor: 'rgba(7,111,0,1)',
      pointBackgroundColor: 'rgba(7,111,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(7,111,0,0.7)'
    },
    { // light green
      backgroundColor: 'rgba(127,208,67,0.3)',
      borderColor: 'rgba(127,208,67,1)',
      pointBackgroundColor: 'rgba(127,208,67,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(127,208,67,0.7)'
    },
    { // red
      backgroundColor: 'rgba(244,3,71,0.3)',
      borderColor: 'rgba(244,3,71,1)',
      pointBackgroundColor: 'rgba(244,3,71,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(244,3,71,0.7)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.3)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.7)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  requestState: number;
  restaurantsRequestState: number;
  restaurantsSubscription: Subscription;
  salesSubscription: Subscription;
  generalExpensesSubscription: Subscription;
  inputExpensesSubscription: Subscription;
  form: FormGroup;
  restaurants: Restaurante[];
  emptyRequest: boolean;
  totalDeVentas: number;
  totalDePropinas: number;
  totalDeGastosInsumos: number;
  totalDeGastosGenerales: number;
  TipoDeReporte = TipoDeReporte;
  TipoUsuario = TipoUsuario;

  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

  constructor(private _restaurantesService: RestaurantesService, private _reportesService: ReportesService) {
    this.requestState = RequestState.initial;
  }

  ngOnInit() {
    this.form = new FormGroup(
      {
        reporte: new FormControl(TipoDeReporte.Anual, [Validators.required]),
        anio: new FormControl(2020, [Validators.required]),
        mes: new FormControl(1, [Validators.required]),
        _idRestaurante: new FormControl(this.tipoUsuario === TipoUsuario.Administrador ? '0' : this._idRestaurante, [
            Validators.required
          ]
        )
      });
    this.emptyRequest = true;
    this.lineChartLabels = this.monthsLabels;
    if (this.tipoUsuario == TipoUsuario.Administrador) {
      this.restaurantsRequestState = RequestState.loading;
      this.restaurantsSubscription = this._restaurantesService.obtenerRestaurantes().subscribe(
        (res: Restaurante[]) => {
          this.restaurants = res;
          this.restaurantsRequestState = RequestState.success;
          this.generarReporte();
        }, error => {
          this.restaurantsRequestState = RequestState.error;
        }
      );
    } else {
      this.restaurantsRequestState = RequestState.success;
      this.generarReporte();
    }
  }

  generarReporte() {
    this.requestState = RequestState.loading;
    let _idRestaurante: string;
    if (this.form.get('_idRestaurante').value === '0') {
      _idRestaurante = null;
    } else {
      _idRestaurante = this.form.get('_idRestaurante').value
    }
    const reporte: number = this.form.get('reporte').value;
    const anio: number = this.form.get('anio').value;
    const mes: number = this.form.get('mes').value;
    this.salesSubscription = this._reportesService.obtenerVentas(_idRestaurante, reporte, anio, mes).subscribe(
      (sales: any[]) => {
        this.inputExpensesSubscription = this._reportesService.obtenerGastosDeInsumos(_idRestaurante, reporte, anio, mes).subscribe(
          (inputExpenses: any[]) => {
            this.generalExpensesSubscription = this._reportesService.obtenerGastosGenerales(_idRestaurante, reporte, anio, mes).subscribe(
              (generalExpenses: any[]) => {
                
                if (sales.length > 0 || inputExpenses.length > 0 || generalExpenses.length > 0) {
                  this.emptyRequest = false;
                } else {
                  this.emptyRequest = true;
                }
                if (reporte == TipoDeReporte.Anual) {
                  this.lineChartLabels = this.monthsLabels;
                  this.totalDeVentas = this.acomodarDatosAnualesEnLaGrafica(sales, 0);
                  this.lineChartData[0].label = 'Ventas (' + formatCurrency(parseFloat(this.totalDeVentas.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                  sales.map(sale => {
                    sale.total = sale.totalPropinas;
                  });
                  
                  this.totalDePropinas = this.acomodarDatosAnualesEnLaGrafica(sales, 1);
                  this.lineChartData[1].label = 'Propinas (' + formatCurrency(parseFloat(this.totalDePropinas.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                  this.totalDeGastosInsumos = this.acomodarDatosAnualesEnLaGrafica(inputExpenses, 2);
                  this.lineChartData[2].label = 'Gastos de insumos (' + formatCurrency(parseFloat(this.totalDeGastosInsumos.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                  this.totalDeGastosGenerales = this.acomodarDatosAnualesEnLaGrafica(generalExpenses, 3);
                  this.lineChartData[3].label = 'Gastos de generales (' + formatCurrency(parseFloat(this.totalDeGastosGenerales.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                } else {
                  const diasDelMes: number = (new Date(anio, mes, 0)).getDate();
                  this.lineChartLabels = [];
                  for (let i = 1; i <= diasDelMes; i++) {
                    this.lineChartLabels.push(i.toString());
                  }
                  this.totalDeVentas = this.acomodarDatosMensualesEnLaGrafica(sales, 0, anio, mes, diasDelMes);
                  this.lineChartData[0].label = 'Ventas (' + formatCurrency(parseFloat(this.totalDeVentas.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                  sales.map(sale => {
                    sale.total = sale.totalPropinas;
                  });
                  this.totalDePropinas = this.acomodarDatosMensualesEnLaGrafica(sales, 1, anio, mes, diasDelMes);
                  this.lineChartData[1].label = 'Propinas (' + formatCurrency(parseFloat(this.totalDePropinas.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                  this.totalDeGastosInsumos = this.acomodarDatosMensualesEnLaGrafica(inputExpenses, 2, anio, mes, diasDelMes);
                  this.lineChartData[2].label = 'Gastos de insumos (' + formatCurrency(parseFloat(this.totalDeGastosInsumos.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                  this.totalDeGastosGenerales = this.acomodarDatosMensualesEnLaGrafica(generalExpenses, 3, anio, mes, diasDelMes);
                  this.lineChartData[3].label = 'Gastos de generales (' + formatCurrency(parseFloat(this.totalDeGastosGenerales.toString()),
                    'en',
                    '$',
                    'MXN',
                    '.2-2') + ')';
                }
                this.requestState = RequestState.success;
              }, error => {
                this.requestState = RequestState.error;
              }
            );
          }, error => {
            this.requestState = RequestState.error;
          }
        );
      }, error => {
        this.requestState = RequestState.error;
      }
    );
  }

  acomodarDatosAnualesEnLaGrafica(datos: any[], posicionGrafica: number): number {
    let acomulador = 0;
    this.lineChartData[posicionGrafica].data = [];
    for (let i = 1; i <= 12; i++) {
      const posicionDatoMensual = datos.findIndex((datoMensual) => {
        return datoMensual.mes === i;
      });
      if (posicionDatoMensual === -1) {
        this.lineChartData[posicionGrafica].data.push(0);
      } else {
        acomulador += datos[posicionDatoMensual].total;
        this.lineChartData[posicionGrafica].data.push(datos[posicionDatoMensual].total);
      }
    }
    return acomulador;
  }

  acomodarDatosMensualesEnLaGrafica(datos: any[], posicionGrafica: number, anio: number, mes: number, diasDelMes: number): number {
    let acomulador = 0;
    this.lineChartData[posicionGrafica].data = [];
    for (let i = 1; i <= diasDelMes; i++) {
      const posicionDatoDiario = datos.findIndex((datoDiario) => {
        return datoDiario.dia === i;
      });
      if (posicionDatoDiario === -1) {
        this.lineChartData[posicionGrafica].data.push(0);
      } else {
        acomulador += datos[posicionDatoDiario].total;
        this.lineChartData[posicionGrafica].data.push(datos[posicionDatoDiario].total);
      }
    }
    return acomulador;
  }

  public chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
    
  }

  public chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {
    
  }

  ngOnDestroy(): void {
    if (this.salesSubscription !== undefined) {
      this.salesSubscription.unsubscribe();
    }
  }
}
