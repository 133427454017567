import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html'
})
export class TableCardComponent implements OnInit {
  title = 'Mesa';
  @Input() mesa: ElementoPorArea;
  @Input() verTitulo: boolean;
  @Input() verVerMas: boolean;
  @Input() verConfiguracion: boolean;
  @Input() verActivo: boolean;
  @Input() verPagar: boolean;

  @Output() agregar = new EventEmitter<ElementoPorArea>();
  @Output() delete = new EventEmitter<ElementoPorArea>();
  @Output() edit = new EventEmitter<ElementoPorArea>();

  AppSettings = AppSettings;

  constructor() {
  }

  ngOnInit() {
  }

  mostrarOpcionesConfiguracion() {
    if (!document.getElementById(`configurar${this.mesa.id}`).classList.contains('rollIn')) {
      document.getElementById(`configurar${this.mesa.id}`).classList.add('rollIn');
      document.getElementById(`configurar${this.mesa.id}`).classList.remove('rollOut');
      document.getElementById(`editar${this.mesa.id}`).classList.add('fadeInUp-2x');
      document.getElementById(`editar${this.mesa.id}`).classList.remove('fadeOutDown-2x');
      document.getElementById(`eliminar${this.mesa.id}`).classList.add('fadeInUp');
      document.getElementById(`eliminar${this.mesa.id}`).classList.remove('fadeOutDown');
    } else {
      document.getElementById(`configurar${this.mesa.id}`).classList.add('rollOut');
      document.getElementById(`configurar${this.mesa.id}`).classList.remove('rollIn');
      document.getElementById(`editar${this.mesa.id}`).classList.add('fadeOutDown-2x');
      document.getElementById(`editar${this.mesa.id}`).classList.remove('fadeInUp-2x');
      document.getElementById(`eliminar${this.mesa.id}`).classList.add('fadeOutDown');
      document.getElementById(`eliminar${this.mesa.id}`).classList.remove('fadeInUp');
    }
  }

  agregarComanda() {
    this.agregar.emit(this.mesa);
  }

  deleteTable() {
    this.delete.emit(this.mesa);
  }

  editTable() {
    this.edit.emit(this.mesa);
  }
}
