import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {
  private rutaBaseProveedores = '/proveedores';

  constructor(private httpClient: HttpClient) { }

  /* GET */
  obtenerProveedor(_idProveedor: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseProveedores}/${_idProveedor}`, AppSettings.Options);
  }

  obtenerInsumosProveedor(_idProveedor: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseProveedores}/${_idProveedor}/insumos`, AppSettings.Options);
  }

  obtenerProveedores(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseProveedores}`, AppSettings.Options);
  }

  /* POST */
  agregarProveedor(proveedor: Proveedor): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseProveedores}`, JSON.stringify(proveedor), AppSettings.Options);
  }

  /* PUT */
  actualizarProveedor(_idProveedor: string, proveedor: Proveedor): Observable<any> {
    return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseProveedores}/${_idProveedor}`, JSON.stringify(proveedor), AppSettings.Options);
  }

  /* PATCH */
  eliminarProveedor(_idProveedor: string): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseProveedores}/${_idProveedor}`, AppSettings.Options);
  }
}
