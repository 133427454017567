export default class UsuarioSocket {
    id: string;
    _id: string;
    conectado: boolean;
    tipo: number;
    _idRestaurante: string;
    _idAccesoUsuario: string;

    constructor() {
        this.id = '';
        this._id = '';
        this.tipo = -1;
        this.conectado = false;
        this._idRestaurante = '';
        this._idAccesoUsuario = '';
    }

    public nuevoUsuario(_id: string, conectado: boolean, tipo: number, _idRestaurante: string, _idAccesoUsuario: string): UsuarioSocket {
        let usuario = new UsuarioSocket();
        usuario.id = '';
        usuario._id = _id;
        usuario.conectado = conectado;
        usuario.tipo = tipo;
        usuario._idRestaurante = _idRestaurante;
        usuario._idAccesoUsuario = _idAccesoUsuario;
        return usuario;
    }
}