import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { RequestState } from '../../shared/enums/request-state.enum';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { CambiarContrasenaConfiguracionComponent } from './cambiar-contrasena-configuracion/cambiar-contrasena-configuracion.component';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html'
})

export class ConfigurationComponent implements OnInit {
  TipoUsuario = TipoUsuario;
  tipoUsuario: number;
  RequestState = RequestState;
  estadoPeticiones: number;
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;

  constructor(private dialog: MatDialog,
    private _authService: AuthService,
    private _alertasService: AlertasService) {
    this.estadoPeticiones = RequestState.initial;
    this.contadorPeticionesFinalizadas = 0;
    this.tipoUsuario = this._authService.obtenerTipoUsuario();
    switch (this.tipoUsuario) {
      case TipoUsuario.Administrador: this.cantidadPeticionesRequeridas = 5;
        break;
      case TipoUsuario.Gerente: this.cantidadPeticionesRequeridas = 4;
        break;
      default: this.cantidadPeticionesRequeridas = 0;
        break;
    }
  }

  ngOnInit() {
    if (this.cantidadPeticionesRequeridas > 0) this.estadoPeticiones = RequestState.loading;
    else this.estadoPeticiones = RequestState.success;
  }

  aumentarContadorPeticionesFinalizadas() {
    this.contadorPeticionesFinalizadas++;
    if (this.contadorPeticionesFinalizadas == this.cantidadPeticionesRequeridas) {
      this.estadoPeticiones = RequestState.success;
    }
  }

  cambiarContrasena(): void {
    const referenciaModal = this.dialog.open(CambiarContrasenaConfiguracionComponent, { disableClose: true });
    referenciaModal.afterClosed().subscribe(
      resultado => {
        if (resultado) {
          this._alertasService.alertaExitoSinConfirmacion('Contraseña actualizada exitosamente', '');
        }
      }
    );
  }
}
