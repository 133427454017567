import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';

@Injectable({
    providedIn: 'root'
})
export class GruposDeOrdenesService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseAreas = '/areas';
    private rutaBaseElementosPorArea = '/elementos-por-area';
    private rutaBaseComandas = '/comandas';
    private rutaBaseGruposDeOrdenes = '/grupos-de-ordenes';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerGruposDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}`, AppSettings.Options);
    }

    obtenerGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}/${_idGrupoDeOrdenes}`, AppSettings.Options);
    }

    /* POST */
    agregarGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}`, AppSettings.Options);
    }

    /* PUT */

    /* PATCH */
    eliminarGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}/${_idGrupoDeOrdenes}`, AppSettings.Options);
    }
    actualizarEstadoEnCocinaGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}/areas/${_idArea}/elementos-por-area/${_idElementoPorArea}/comandas/${_idComanda}/grupos-de-ordenes/${_idGrupoDeOrdenes}/estado-en-cocina`, null, AppSettings.Options);
    }
}
