import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private httpClient: HttpClient) {
  }

  arrayToString(idSucursales: string[]) {
    let convertion = '';
    idSucursales.forEach((idSucursal) => {
      convertion += 'idSucursales[]=' + idSucursal + '&';
    });
    return convertion;
  }

  obtenerVentas(idSucursales: string[], reporte: string, anio: number, mes: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/ventas.php?${this.arrayToString(idSucursales)}reporte=${reporte}&anio=${anio}&mes=${mes}`,
      AppSettings.Options);
  }

  obtenerGastosGenerales(idSucursales: string[], reporte: string, anio: number, mes: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/gastos-generales.php?${this.arrayToString(idSucursales)}reporte=${reporte}&anio=${anio}&mes=${mes}`,
      AppSettings.Options);
  }

  obtenerGastosInsumos(idSucursales: string[], reporte: string, anio: number, mes: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/gastos-insumos.php?${this.arrayToString(idSucursales)}reporte=${reporte}&anio=${anio}&mes=${mes}`,
      AppSettings.Options);
  }

  obtenerVentasCajeros(idSucursales: string[], reporte: string, anio: number, mes: number, dia: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/ventas-cajeros.php?${this.arrayToString(idSucursales)}reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`,
      AppSettings.Options);
  }

  obtenerVentasMeseros(idSucursales: string[], reporte: string, anio: number, mes: number, dia: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/ventas-meseros.php?${this.arrayToString(idSucursales)}reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`,
      AppSettings.Options);
  }

  obtenerVentasLicencias(reporte: string, anio: number, mes: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/ventas-licencias.php?reporte=${reporte}&anio=${anio}&mes=${mes}`, AppSettings.Options);
  }

  obtenerVentasLicenciasPersonal(reporte: string, anio: number, mes: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/ventas-licencias-personal.php?reporte=${reporte}&anio=${anio}&mes=${mes}`, AppSettings.Options);
  }

  obtenerVentasLicenciasPorVendedor(reporte: string, anio: number, mes: number, dia: number): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/reportes/ventas-licencias-por-vendedor.php?reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`, AppSettings.Options);
  }
}
