import { FormaDePago } from '../enums/forma-de-pago.enum';
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';
import { Insumo } from './insumo.model';
import { Proveedor } from './proveedor.model';
import { Tamano } from './tamano.model';
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/*                         Clase utilizada con el fin de que se puedan registrar varios insumos en un gasto                      */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export class RegistroDeInsumo {
    _idInsumo: string;
    insumo: Insumo;
    _idTamano: string;
    tamano: Tamano;
    cantidadDeInsumo: number | string;
    total: number | string;

    constructor() {
        this.total = '0.00';
    }
}

export class GastoDeInsumo {
    _id?: string;
    id?: number;
    _idProveedor: string;
    proveedor: Proveedor;
    descripcion?: string;
    insumos: RegistroDeInsumo[];
    formaDePago: string;
    /*subtotal: number;
    porcentajeIva: number;
    iva: number;
    ieps: number;*/
    total: number | string;
    _idRestaurante?: string;
    restaurante?: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.insumos = [];
        this.formaDePago = FormaDePago.Efectivo;
        this.total = '0.00';
    }
}