import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { WebSocketsService } from './web-sockets.service';

@Injectable({
    providedIn: 'root'
})
export class OrdenesService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseAreas = '/areas';
    private rutaBaseElementosPorArea = '/elementos-por-area';
    private rutaBaseComandas = '/comandas';
    private rutaBaseGruposDeOrdenes = '/grupos-de-ordenes';
    private rutaBaseOrdenes = '/ordenes';

    constructor(private httpClient: HttpClient, private _webSocketsService: WebSocketsService) {
    }

    /* GET */
    obtenerOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}/${_idGrupoDeOrdenes}${this.rutaBaseOrdenes}`, AppSettings.Options);
    }

    obtenerOrden(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string, _idOrden: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}/${_idGrupoDeOrdenes}${this.rutaBaseOrdenes}/${_idOrden}`, AppSettings.Options);
    }

    obtenerOrdenesComandaSinPagar(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseOrdenes}/sin-pagar`, AppSettings.Options);
    }

    obtenerTotalDeOrdenesComanda(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseOrdenes}/total`, AppSettings.Options);
    }

    obtenerTotalSinPagarDeOrdenesComanda(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseOrdenes}/total-sin-pagar`, AppSettings.Options);
    }
    
    /* POST */
    agregarOrden(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string, orden: Orden): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}/${_idGrupoDeOrdenes}${this.rutaBaseOrdenes}`, JSON.stringify(orden), AppSettings.Options);
    }

    /* PUT */

    /* PATCH */
    eliminarOrden(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string, _idOrden: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseGruposDeOrdenes}/${_idGrupoDeOrdenes}${this.rutaBaseOrdenes}/${_idOrden}`, AppSettings.Options);
    }

    /* SOCKETS */
    public disminucionExistenciasOrdenes() {
        return this._webSocketsService.escuchar('disminucion-existencias-ordenes');
    }
}