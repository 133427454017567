import { Component, OnInit, Inject } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { UnidadDeMedida } from 'src/app/shared/class-models/unidad-de-medida.model';
import { Subscription } from 'rxjs';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { UnidadesDeMedidaService } from 'src/app/core/servicios/unidad-de-medida.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { CantidadDeDecimalesParaUnidadesDeMedida } from 'src/app/shared/enums/cantidad-de-decimales-para-unidades-de-medida.enum';

@Component({
  selector: 'app-actualizacion-unidad-de-medida',
  templateUrl: './actualizacion-unidad-de-medida.component.html',
  styleUrls: ['./actualizacion-unidad-de-medida.component.scss']
})
export class ActualizacionUnidadDeMedidaComponent implements OnInit {
  titulo = 'Actualizar unidad de medida';
  RequestState = RequestState;
  unidadDeMedida: UnidadDeMedida;
  CantidadDeDecimalesParaUnidadesDeMedida = CantidadDeDecimalesParaUnidadesDeMedida;

  /* VARIABLES PARA OBTENER LA UNIDAD DE MEDIDA */
  obtenerUnidadDeMedidaSubscription: Subscription;
  estadoPeticionObtenerUnidadDeMedida: number;

  /* VARIABLES PARA ACTUALIZAR LA UNIDAD DE MEDIDA */
  ExpresionesRegulares = ExpresionesRegulares;
  actualizarUnidadDeMedidaSubscription: Subscription;
  estadoPeticionActualizarUnidadDeMedida: number;

  constructor(
    private _unidadesDeMedidaService: UnidadesDeMedidaService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<ActualizacionUnidadDeMedidaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UnidadDeMedida,
  ) {
    this.estadoPeticionObtenerUnidadDeMedida = RequestState.initial;
    this.estadoPeticionActualizarUnidadDeMedida = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerUnidadDeMedida();
  }

  obtenerUnidadDeMedida(): void {
    this.estadoPeticionObtenerUnidadDeMedida = RequestState.loading;
    this.obtenerUnidadDeMedidaSubscription = this._unidadesDeMedidaService.obtenerUnidadDeMedida(this.data._id).subscribe(
      (unidadDeMedida: UnidadDeMedida) => {
        this.unidadDeMedida = unidadDeMedida;
        this.estadoPeticionObtenerUnidadDeMedida = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUnidadDeMedida = RequestState.error;
        this.modal.close();
      }
    );
  }

  actualizarUnidadDeMedida(): void {
    this.estadoPeticionActualizarUnidadDeMedida = RequestState.loading;
    this.actualizarUnidadDeMedidaSubscription = this._unidadesDeMedidaService.actualizarUnidadDeMedida(this.data._id, this.unidadDeMedida).subscribe(
      (unidadDeMedida: UnidadDeMedida) => {
        this.estadoPeticionActualizarUnidadDeMedida = RequestState.success;
        this.actualizarUnidadDeMedidaOriginal(unidadDeMedida);
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarUnidadDeMedida = RequestState.error;
      }
    );
  }

  actualizarUnidadDeMedidaOriginal(unidadDeMedida: UnidadDeMedida): void {
    this.data.nombre = unidadDeMedida.nombre;
    this.data.abreviacion = unidadDeMedida.abreviacion;
    this.data.cantidadDeDecimales = unidadDeMedida.cantidadDeDecimales;
    this.data.descripcion = unidadDeMedida.descripcion;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerUnidadDeMedidaSubscription) this.obtenerUnidadDeMedidaSubscription.unsubscribe();
    if(this.actualizarUnidadDeMedidaSubscription) this.actualizarUnidadDeMedidaSubscription.unsubscribe();
  }
}
