import {Component, OnDestroy, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../shared/enums/request-state.enum';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CajasDeUsuarioService } from 'src/app/core/servicios/cajas-de-usuario.services';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';

@Component({
  selector: 'app-open-cash-register',
  templateUrl: './open-cash-register.component.html',
  styleUrls: []
})
export class OpenCashRegisterComponent implements OnInit, OnDestroy {
  titulo: string = 'Abrir caja';
  RequestState = RequestState;
  estadoPeticionAgregarCajaDeUsuario: number;
  agregarCajaDeUsuarioSubscription: Subscription;
  cajaDeUsuario: CajaDeUsuario = new CajaDeUsuario();
  ExpresionesRegulares = ExpresionesRegulares;
  estadoPeticionObtenerRestaurantes: number;
  obtenerRestaurantesSubscription: Subscription;
  restaurantes: Restaurante[];
  _idRestaurante: string;
  TipoUsuario = TipoUsuario;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public tipoDeUsuario: number,
    public modal: MatDialogRef<OpenCashRegisterComponent>,
    private _authService: AuthService,
    private _restaurantesService: RestaurantesService,
    private _alertasService: AlertasService,
    private _cajasService: CajasDeUsuarioService) {
    this.estadoPeticionObtenerRestaurantes = RequestState.initial;
    this.estadoPeticionAgregarCajaDeUsuario = RequestState.initial;
  }

  ngOnInit() {
    if (this.tipoDeUsuario == TipoUsuario.Administrador) {
      this.obtenerRestaurantes();
    } else {
      this._idRestaurante = this._authService.obtenerUsuario()._idRestaurante;
    }
  }

  obtenerRestaurantes(): void {
    this.restaurantes = [];
    this.estadoPeticionObtenerRestaurantes = RequestState.loading;
    this.obtenerRestaurantesSubscription = this._restaurantesService.obtenerRestaurantes().subscribe(
      (restaurantes: Restaurante[]) => {
        this.restaurantes = restaurantes;
        this.estadoPeticionObtenerRestaurantes = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerRestaurantes = RequestState.error;
      }
    );
  }

  transformarMontoApertura(montoApertura: string): void {
    if (montoApertura != null && montoApertura != undefined && !isNaN(Number(montoApertura))) {
      this.cajaDeUsuario.montoApertura = Number(montoApertura).toFixed(2);
    } else {
      this.cajaDeUsuario.montoApertura = Number('0').toFixed(2);
    }
  }

  agregarCajaDeUsuario() {
    this.estadoPeticionAgregarCajaDeUsuario = RequestState.loading;
    this.agregarCajaDeUsuarioSubscription = this._cajasService.agregarCajaDeUsuario(this._idRestaurante, this.prepararDatosCajaDeUsuario()).subscribe(
      (cajaDeUsuario: CajaDeUsuario) => {
        this.estadoPeticionAgregarCajaDeUsuario = RequestState.success;
        this.modal.close(cajaDeUsuario);
      },
      (err: HttpErrorResponse) => {
        this.estadoPeticionAgregarCajaDeUsuario = RequestState.error;
        this._alertasService.alertaErrorSinConfirmacion(err.error.titulo, err.error.detalles);
      }
    );
  }

  prepararDatosCajaDeUsuario(): CajaDeUsuario {
    let cajaDeUsuario = new CajaDeUsuario();
    cajaDeUsuario.montoApertura = parseFloat(this.cajaDeUsuario.montoApertura.toString());
    return cajaDeUsuario
  }

  cerrar(){
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy() {
    if (this.obtenerRestaurantesSubscription) this.obtenerRestaurantesSubscription.unsubscribe();
    if (this.agregarCajaDeUsuarioSubscription) this.agregarCajaDeUsuarioSubscription.unsubscribe();
  }
}
