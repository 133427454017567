import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Existencia } from 'src/app/shared/class-models/existencia.model';

@Injectable({
    providedIn: 'root'
})
export class ExistenciasService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseExistenciasDeProductos = '/existencias-de-productos';
    private rutaBaseExistencias = '/existencias';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerExistenciasDeProductos(_idRestaurante: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistenciasDeProductos}`, AppSettings.Options);
    }

    obtenerExistenciaDeProducto(_idRestaurante: string, _idProducto: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistenciasDeProductos}/${_idProducto}`, AppSettings.Options);
    }

    /* POST */
    agregarExistencia(_idRestaurante: string, existencia: Existencia): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistencias}`, JSON.stringify(existencia), AppSettings.Options);
    }

    /* PUT */

    /* PATCH */
    actualizarEstadoDeExistencia(_idRestaurante: string, _idExistencia: string, activo: boolean): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistencias}/${_idExistencia}`, JSON.stringify({activo}), AppSettings.Options);
    }
}