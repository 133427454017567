import { Component, OnInit, OnDestroy } from '@angular/core';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { TipoDeGastoGeneral } from 'src/app/shared/class-models/tipo-de-gasto-general.model';
import { Subscription } from 'rxjs';
import { TiposDeGastosGeneralesService } from 'src/app/core/servicios/tipos-de-gastos-generales.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef } from '@angular/material';
import { RFC_GENERICO } from 'src/app/shared/constants/rfc-generico.constant';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-alta-tipo-de-gasto',
  templateUrl: './alta-tipo-de-gasto.component.html',
  styleUrls: ['./alta-tipo-de-gasto.component.scss']
})
export class AltaTipoDeGastoComponent implements OnInit, OnDestroy {
  titulo: string = 'Agregar tipo de gasto general';
  TipoDePersona = TipoDePersona;
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  tipoDeGastoGeneral: TipoDeGastoGeneral = new TipoDeGastoGeneral();
  estadoPeticionAgregarTipoDeGastoGeneral: number;
  agregarTipoDeGastoGeneralSubscripcion: Subscription;

  constructor(
    private _tiposDeGastosGeneralesService: TiposDeGastosGeneralesService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<AltaTipoDeGastoComponent>
  ) {
    this.estadoPeticionAgregarTipoDeGastoGeneral = RequestState.initial;
  }

  ngOnInit(): void { }

  obtenerTipoDePersona(tipo: number): string {
    switch (tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  actualizacionTipoDePersona(): void {
    switch (this.tipoDeGastoGeneral.tipoDePersona) {
      case TipoDePersona.Moral:
      case TipoDePersona.Fisica:
        if (this.tipoDeGastoGeneral.rfc == RFC_GENERICO) this.tipoDeGastoGeneral.rfc = '';
        break;
      case TipoDePersona.Ninguna:
        this.tipoDeGastoGeneral.rfc = RFC_GENERICO;
        break;
    }
  }

  convertirRfcMayusculas(): void {
    if (this.tipoDeGastoGeneral.rfc) {
      this.tipoDeGastoGeneral.rfc = this.tipoDeGastoGeneral.rfc.toUpperCase();
    }
  }

  agregarTipoDeGastoGeneral(): void {
    this.estadoPeticionAgregarTipoDeGastoGeneral = RequestState.loading;
    this.agregarTipoDeGastoGeneralSubscripcion = this._tiposDeGastosGeneralesService.agregarTipoDeGastoGeneral(this.prepararDatosTipoDeGastoGeneral()).subscribe(
      (tipoDeGastoGeneral: TipoDeGastoGeneral) => {
        this.estadoPeticionAgregarTipoDeGastoGeneral = RequestState.success;
        this.modal.close(tipoDeGastoGeneral);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarTipoDeGastoGeneral = RequestState.error;
      }
    );
  }

  prepararDatosTipoDeGastoGeneral(): TipoDeGastoGeneral {
    let tipoDeGastoGeneral = new TipoDeGastoGeneral();
    tipoDeGastoGeneral.concepto = this.tipoDeGastoGeneral.concepto;
    tipoDeGastoGeneral.descripcion = this.tipoDeGastoGeneral.descripcion;
    tipoDeGastoGeneral.tieneReceptor = this.tipoDeGastoGeneral.tieneReceptor;
    if (tipoDeGastoGeneral.tieneReceptor) {
      tipoDeGastoGeneral.tipoDePersona = this.tipoDeGastoGeneral.tipoDePersona;
      tipoDeGastoGeneral.rfc = this.tipoDeGastoGeneral.rfc;
      tipoDeGastoGeneral.telefono = this.tipoDeGastoGeneral.telefono;
      tipoDeGastoGeneral.correo = this.tipoDeGastoGeneral.correo;
      switch (tipoDeGastoGeneral.tipoDePersona) {
        case TipoDePersona.Moral:
          tipoDeGastoGeneral.razonSocial = this.tipoDeGastoGeneral.razonSocial;
          tipoDeGastoGeneral.tieneRepresentante = this.tipoDeGastoGeneral.tieneRepresentante;
          if (tipoDeGastoGeneral.tieneRepresentante) {
            tipoDeGastoGeneral.nombresRepresentante = this.tipoDeGastoGeneral.nombresRepresentante;
            tipoDeGastoGeneral.primerApellidoRepresentante = this.tipoDeGastoGeneral.primerApellidoRepresentante;
            tipoDeGastoGeneral.segundoApellidoRepresentante = this.tipoDeGastoGeneral.segundoApellidoRepresentante;
          } else {
            tipoDeGastoGeneral.nombresRepresentante = null;
            tipoDeGastoGeneral.primerApellidoRepresentante = null;
            tipoDeGastoGeneral.segundoApellidoRepresentante = null;
          }
          break;
        case TipoDePersona.Fisica:
        case TipoDePersona.Ninguna:
          tipoDeGastoGeneral.nombres = this.tipoDeGastoGeneral.nombres;
          tipoDeGastoGeneral.primerApellido = this.tipoDeGastoGeneral.primerApellido;
          tipoDeGastoGeneral.segundoApellido = this.tipoDeGastoGeneral.segundoApellido;
          tipoDeGastoGeneral.tieneRepresentante = false;
          tipoDeGastoGeneral.nombresRepresentante = null;
          tipoDeGastoGeneral.primerApellidoRepresentante = null;
          tipoDeGastoGeneral.segundoApellidoRepresentante = null;
          break;
      }
    } else {
      tipoDeGastoGeneral.tipoDePersona = null;
      tipoDeGastoGeneral.rfc = null;
      tipoDeGastoGeneral.nombres = null;
      tipoDeGastoGeneral.primerApellido = null;
      tipoDeGastoGeneral.segundoApellido = null;
      tipoDeGastoGeneral.razonSocial = null;
      tipoDeGastoGeneral.tieneRepresentante = false;
      tipoDeGastoGeneral.nombresRepresentante = null;
      tipoDeGastoGeneral.primerApellidoRepresentante = null;
      tipoDeGastoGeneral.segundoApellidoRepresentante = null;
      tipoDeGastoGeneral.telefono = null;
      tipoDeGastoGeneral.correo = null;
    }
    return tipoDeGastoGeneral;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.agregarTipoDeGastoGeneralSubscripcion) this.agregarTipoDeGastoGeneralSubscripcion.unsubscribe();
  }
}
