import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
    providedIn: 'root'
})
export class AlertasService {

    constructor() { }

    alertaExitoSinConfirmacion(titulo: string, texto: string) {
        this.alertaSinConfirmacion(titulo, texto, 'success');
    }

    alertaErrorSinConfirmacion(titulo: string, texto: string) {
        this.alertaSinConfirmacion(titulo, texto, 'error');
    }

    alertaInfoSinConfirmacion(titulo: string, texto: string) {
        this.alertaSinConfirmacion(titulo, texto, 'info');
    }

    alertaAdvertenciaSinConfirmacion(titulo: string, texto: string) {
        this.alertaSinConfirmacion(titulo, texto, 'warning');
    }

    alertaExitoConConfirmacion(titulo: string, texto: string) {
        return this.alertaConConfirmacion(titulo, texto, 'success');
    }

    alertaExitoConConfirmacionImpresionTicket(titulo: string, texto: string, textoBotonConfirmacion: string) {
        return Swal.fire({
            title: titulo,
            text: texto,
            type: 'success',
            showCancelButton: true,
            showConfirmButton: true,
            heightAuto: false,
            buttonsStyling: false,
            cancelButtonText: 'Cerrar',
            cancelButtonClass: 'btn btn-outline-danger mr-1',
            confirmButtonClass: 'btn btn-primary ml-1',
            confirmButtonText: textoBotonConfirmacion,
            customClass: 'modal-content',
            reverseButtons: true
        });
    }

    alertaAdvertenciaConConfirmacionImpresionTicket(titulo: string, texto: string, textoBotonConfirmacion: string) {
        return Swal.fire({
            title: titulo,
            text: texto,
            type: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            heightAuto: false,
            buttonsStyling: false,
            cancelButtonText: 'Cerrar',
            cancelButtonClass: 'btn btn-outline-danger mr-1',
            confirmButtonClass: 'btn btn-primary ml-1',
            confirmButtonText: textoBotonConfirmacion,
            customClass: 'modal-content',
            reverseButtons: true
        });
    }

    alertaErrorConConfirmacion(titulo: string, texto: string) {
        return this.alertaConConfirmacion(titulo, texto, 'error');
    }

    alertaInfoConConfirmacion(titulo: string, texto: string) {
        return this.alertaConConfirmacion(titulo, texto, 'info');
    }

    alertaAdvertenciaConConfirmacion(titulo: string, texto: string) {
        return this.alertaConConfirmacion(titulo, texto, 'warning');
    }

    private alertaSinConfirmacion(titulo: string, texto: string, tipo: any) {
        Swal.fire({
            title: titulo,
            text: texto,
            type: tipo,
            showConfirmButton: true,
            heightAuto: false,
            buttonsStyling: false,
            cancelButtonText: 'Cancelar',
            cancelButtonClass: 'btn btn-outline-danger',
            confirmButtonClass: 'btn btn-primary',
            confirmButtonText: 'Aceptar',
            customClass: 'modal-content'
        });
    }

    private alertaConConfirmacion(titulo: string, texto: string, tipo: any) {
        return Swal.fire({
            title: titulo,
            text: texto,
            type: tipo,
            showCancelButton: true,
            showConfirmButton: true,
            heightAuto: false,
            buttonsStyling: false,
            cancelButtonText: 'Cancelar',
            cancelButtonClass: 'btn btn-outline-danger mr-1',
            confirmButtonClass: 'btn btn-primary ml-1',
            confirmButtonText: 'Aceptar',
            customClass: 'modal-content',
            reverseButtons: true
        });
    }
}
