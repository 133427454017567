import {MatButtonModule} from '@angular/material/button';
import {
  MatBadgeModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatInputModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTooltipModule,
  MatProgressBarModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {PromosComponent} from './promos.component';
// Components.
import {PromoCardComponent} from './components/promo-card/promo-card.component';
// Pages.
import {NewPromoComponent} from './pages/new-promo/new-promo.component';
// Routes.
import {PromosRoutes} from './promos.routes';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageCropperModule} from 'ngx-image-cropper';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
  declarations: [
    PromosComponent,
    NewPromoComponent,
    PromoCardComponent
  ],
  imports: [
    RouterModule.forRoot(PromosRoutes),
    ImageCropperModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    MatTooltipModule
  ],
  exports: [
    PromosComponent,
    NewPromoComponent,
    PromoCardComponent
  ],
  providers: [
    MatDatepickerModule
  ]
})
export class PromosModule {
}
