import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {InventoryService} from '../../../../../../core/services/inventory.service';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {Insumo} from '../../../../../../shared/models/insumo';
import {InsumoOrdenado} from 'src/app/shared/models/insumo-ordenado';
import {ProviderService} from 'src/app/core/services/provider.service';
import {Proveedor} from 'src/app/shared/models/proveedor';

@Component({
  selector: 'app-inventory-edit-order',
  templateUrl: './inventory-edit-order.component.html'
})
export class InventoryEditOrderComponent implements OnInit, OnDestroy {

  disableBtn: boolean;
  form: FormGroup;
  requestState: number;
  requestInputsState: number;
  requestProvidersState: number;
  dataSubscription: Subscription;
  formSubscription: Subscription;
  timeout: number;
  title = 'Editar Orden';
  insumos: Insumo[];
  insumoSeleccionado: Insumo;
  proveedores: Proveedor[];
  @ViewChild('dialogWarning', {static: true}) private dialogWarning: SwalComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<InventoryEditOrderComponent>,
              private inventoryService: InventoryService,
              private providerService: ProviderService) {
    this.form = new FormGroup(
      {
        idInsumo: new FormControl(''),
        idProveedor: new FormControl(''),
        precio: new FormControl('0.00', [Validators.required, Validators.pattern('^[0-9]+[.][0-9][0-9]$')]),
        cantidad: new FormControl('')
      }
    );
  }

  ngOnInit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.initial;
    this.dataSubscription = this.inventoryService.obtenerOrden(this.data.id).subscribe(
      (data: InsumoOrdenado) => {
        this.form.patchValue(data[0]);
        this.form.get('precio').setValue(data[0].precio.toFixed(2));
        this.requestInputsState = RequestState.loading;
        this.inventoryService.obtenerInsumos().subscribe(
          (res) => {
            this.insumos = res;
            this.requestInputsState = RequestState.success;
            this.insumoSeleccionado = this.insumos.find(element => {
              return (element.id === this.form.get('idInsumo').value);
            });
          }
        );
        this.requestProvidersState = RequestState.loading;
        this.providerService.getProviders().subscribe(
          (res) => {
            this.proveedores = res;
            this.requestProvidersState = RequestState.success;
          }
        );
      }
    );
    this.formSubscription = this.form.valueChanges.subscribe(
      () => {
        this.disableBtn = !this.form.valid;
        if (this.insumos) {
          this.insumoSeleccionado = this.insumos.find(element => {
            return (element.id === this.form.get('idInsumo').value);
          });
        }
      }
    );

  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  edit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.inventoryService.editarOrden(this.data.id, this.form.value).subscribe(
      () => {
        this.requestState = RequestState.success;
        this.dialogRef.close({ok: true, id: this.data.id, form: this.form.getRawValue()});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogWarning.show().then(
      data => {
        if (data.value) {
          this.dialogRef.close({ok: false});
        }
      }
    );
  }
}
