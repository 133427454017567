import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Producto, ProductoPorTamano } from 'src/app/shared/class-models/producto.model';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { Categoria } from 'src/app/shared/class-models/categoria.model';
import { CategoriasService } from 'src/app/core/servicios/categorias.service';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { AgregarTamanoConfiguracionComponent } from 'src/app/modules/configuration/tamanos-configuracion/agregar-tamano-configuracion/agregar-tamano-configuracion.component';
import { NewIngredientComponent } from 'src/app/modules/ingredients/pages/new-ingredient/new-ingredient.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss'],
})
export class NewProductComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  titulo = 'Agregar producto';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;

  /* VARIABLES DEL STEP DE INFORMACIÓN GENERAL */
  eventoCambioImagenFotografia: any = '';
  categorias: Categoria[];
  estadoPeticionObtenerCategorias: number;
  obtenerCategoriasSubscription: Subscription;

  /* VARIABLES DEL STEP DE TAMAÑOS */
  productoPorTamano: ProductoPorTamano[];
  estadoProductoPorTamano: boolean[];
  estadoPeticionObtenerTamanos: number;
  obtenerTamanosSubscription: Subscription;

  /* VARIABLES DEL STEP DE INGREDIENTES */
  ingredientes: Ingrediente[];
  estadoIngredientes: boolean[];
  estadoPeticionObtenerIngredientes: number;
  obtenerIngredientesSubscription: Subscription;

  /* VARIABLES PARA AGREGAR EL PRODUCTO */
  estadoPeticionAgregarProducto: number;
  agregarProductoSubscription: Subscription;
  producto: Producto = new Producto();

  constructor(
    private _productosService: ProductosService,
    private _ingredientesService: IngredientesService,
    private _tamanosService: TamanosService,
    private _categoriasService: CategoriasService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NewProductComponent>,
    public dialog: MatDialog
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 3;
    this.estadoPeticionAgregarProducto = RequestState.initial;
    this.estadoPeticionObtenerCategorias = RequestState.initial;
    this.estadoPeticionObtenerTamanos = RequestState.initial;
    this.estadoPeticionObtenerIngredientes = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerIngredientes();
    this.obtenerCategorias();
    this.obtenerTamanos();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerIngredientes(): void {
    this.estadoPeticionObtenerIngredientes = RequestState.loading;
    this.obtenerIngredientesSubscription = this._ingredientesService.obtenerIngredientes().subscribe(
      (ingredientes: Ingrediente[]) => {
        this.ingredientes = ingredientes;
        this.estadoIngredientes = ingredientes.map(ingrediente => { return false; });
        this.estadoPeticionObtenerIngredientes = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas()
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngredientes = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas()
      }
    );
  }

  obtenerCategorias(): void {
    this.estadoPeticionObtenerCategorias = RequestState.loading;
    this.obtenerCategoriasSubscription = this._categoriasService.obtenerCategorias().subscribe(
      (categorias: Categoria[]) => {
        this.categorias = categorias;
        this.estadoPeticionObtenerCategorias = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas()
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCategorias = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas()
        this.modal.close();
      }
    );
  }

  obtenerTamanos(): void {
    this.estadoPeticionObtenerTamanos = RequestState.loading;
    this.obtenerTamanosSubscription = this._tamanosService.obtenerTamanos().subscribe(
      (tamanos: Tamano[]) => {
        this.estadoProductoPorTamano = tamanos.map(tamano => { return false; });
        this.productoPorTamano = tamanos.map((tamano): ProductoPorTamano => {
          let productoPorTamano = new ProductoPorTamano();
          productoPorTamano.tamano = tamano;
          return productoPorTamano;
        });
        this.estadoPeticionObtenerTamanos = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas()
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerTamanos = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas()
        this.modal.close();
      }
    );
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  /* MÉTODOS DEL STEP DE INFORMACIÓN GENERAL */
  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(evento: ImageCroppedEvent): void {
    this.producto.foto = evento.base64;
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
  //this.imagenFotoRecortada = '';
  //this.eventoCambioImagenFotografia = '';
  //}

  /* MÉTODOS DEL STEP DE TAMAÑOS */
  cambioEstadoProductoPorTamano(posicionProductoPorTamano: number, productoPorTamano: ProductoPorTamano): void {
    if (this.estadoProductoPorTamano[posicionProductoPorTamano]) this.producto.productoPorTamano.push(productoPorTamano);
    else {
      const posicionProductoPorTamanoSeleccionado = this.producto.productoPorTamano.indexOf(productoPorTamano, 0);
      if (posicionProductoPorTamanoSeleccionado > -1) {
        this.producto.productoPorTamano.splice(posicionProductoPorTamanoSeleccionado, 1);
      }
    }
  }

  transformarPrecio(posicionProductoPorTamano: number, precio: string): void {
    if (precio != null && precio != undefined && !isNaN(Number(precio))) {
      this.productoPorTamano[posicionProductoPorTamano].precio = Number(precio).toFixed(2);
    } else {
      this.productoPorTamano[posicionProductoPorTamano].precio = Number('0').toFixed(2);
    }
  }

  agregarTamano(): void {
    const modal = this.dialog.open(AgregarTamanoConfiguracionComponent, { disableClose: true });
    modal.afterClosed().subscribe(
      (tamano: Tamano) => {
        if (tamano != null) {
          this._alertasService.alertaExitoSinConfirmacion('Tamaño agregado exitosamente', 'El tamaño ' + tamano.nombre + ' ha sido agregado con éxito.');
          let productoPorTamano = new ProductoPorTamano();
          productoPorTamano.tamano = tamano;
          this.productoPorTamano.push(productoPorTamano);
          this.estadoProductoPorTamano.push(false);
        }
      }
    );
  }

  /* MÉTODOS DEL STEP DE INGREDIENTES */
  cambioEstadoIngrediente(posicionIngrediente: number, ingrediente: Ingrediente): void {
    if (this.estadoIngredientes[posicionIngrediente]) (<Ingrediente[]>this.producto.ingredientes).push(ingrediente);
    else {
      const posicionIngredienteSeleccionado = (<Ingrediente[]>this.producto.ingredientes).indexOf(ingrediente, 0);
      if (posicionIngredienteSeleccionado > -1) {
        this.producto.ingredientes.splice(posicionIngredienteSeleccionado, 1);
      }
    }
  }

  agregarIngrediente(): void {
    const modal = this.dialog.open(NewIngredientComponent, { disableClose: true });
    modal.afterClosed().subscribe(
      (ingrediente: Ingrediente) => {
        if (ingrediente != null) {
          this._alertasService.alertaExitoSinConfirmacion('Ingrediente agregado exitosamente', 'El ingrediente ' + ingrediente.nombre + ' ha sido agregado con éxito.');
          this.ingredientes.push(ingrediente);
          this.estadoIngredientes.push(false);
        }
      }
    );
  }

  /* MÉTODOS PARA AGREGAR EL PRODUCTO */
  agregarProducto(): void {
    this.estadoPeticionAgregarProducto = RequestState.loading;
    this.agregarProductoSubscription = this._productosService.agregarProducto(this.prepararDatosProducto()).subscribe(
      (producto: Producto) => {
        this.estadoPeticionAgregarProducto = RequestState.success;
        this.modal.close(producto);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarProducto = RequestState.error;
      }
    );
  }

  prepararDatosProducto(): Producto {
    let producto = new Producto;
    producto.nombre = this.producto.nombre;
    producto._idCategoria = this.producto._idCategoria;
    producto.descripcion = this.producto.descripcion;
    producto.foto = this.producto.foto;
    this.producto.productoPorTamano.forEach((productoPorTamano) => {
      let copiaProductoPorTamano = new ProductoPorTamano();
      copiaProductoPorTamano._idTamano = productoPorTamano.tamano._id;
      copiaProductoPorTamano.precio = parseFloat(productoPorTamano.precio.toString());
      producto.productoPorTamano.push(copiaProductoPorTamano);
    });
    (<Ingrediente[]>this.producto.ingredientes).forEach((ingrediente)=> {
      (<string[]>producto.ingredientes).push(ingrediente._id);
    })
    return producto;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerIngredientesSubscription) this.obtenerIngredientesSubscription.unsubscribe();
    if (this.obtenerCategoriasSubscription) this.obtenerCategoriasSubscription.unsubscribe();
    if (this.obtenerTamanosSubscription) this.obtenerTamanosSubscription.unsubscribe();
    if (this.agregarProductoSubscription) this.agregarProductoSubscription.unsubscribe();
  }
}
