import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppSettings} from 'src/app/configs/app-settings.config';
import {Insumo} from '../../shared/models/insumo';
import {InsumoOrdenado} from '../../shared/models/insumo-ordenado';
import {OtroGasto} from '../../shared/models/otro-gasto';
import {GastoGenerado} from 'src/app/shared/models/gasto-generado';

@Injectable({
  providedIn: 'root'
})

export class InventoryService {

  timeout = 2000;
  insumos: Insumo[];
  otrosGastos: OtroGasto[];

  constructor(private httpClient: HttpClient) {
  }

  /*
  Insumos
  */

  obtenerInsumos(): Observable<Insumo[]> {
    return this.httpClient.get<Insumo[]>(`${AppSettings.APIEndpoint}/insumos.php`, AppSettings.Options);
  }

  obtenerInsumo(id: string): Observable<Insumo> {
    return this.httpClient.get<Insumo>(`${AppSettings.APIEndpoint}/insumo.php?id=${id}`, AppSettings.Options);
  }

  agregarInsumo(insumo: Insumo): Observable<Insumo> {
    const body = {
      supply: insumo
    };
    return this.httpClient.post<Insumo>(`${AppSettings.APIEndpoint}/insumo.php`, JSON.stringify(body), AppSettings.Options);
  }

  editarInsumo(id: string, insumo: Insumo): Observable<Insumo> {
    insumo.id = id;
    const body = {
      supply: insumo
    };
    return this.httpClient.put<Insumo>(`${AppSettings.APIEndpoint}/insumo.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarInsumo(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/insumo.php?id=${id}`, AppSettings.Options);
  }

  /*
  Ordenes Insumos
  */

  obtenerInventario(idSucursal: string): Observable<InsumoOrdenado[]> {
    return this.httpClient.get<InsumoOrdenado[]>(`${AppSettings.APIEndpoint}/ordenesInsumo.php?idSucursal=${idSucursal}`, AppSettings.Options);
  }

  obtenerOrden(id: string): Observable<InsumoOrdenado> {
    return this.httpClient.get<InsumoOrdenado>(`${AppSettings.APIEndpoint}/ordenInsumo.php?id=${id}`, AppSettings.Options);
  }

  agregarOrden(orden: InsumoOrdenado, idSucursal: string): Observable<InsumoOrdenado> {
    orden.restaurante = {
      id: idSucursal,
      nombre: null,
      estado: null,
      ciudad: null,
      IVA: null,
      rfc: null,
    };
    orden.precio = parseFloat(orden.precio.toString());
    const body = {
      order: orden
    };
    return this.httpClient.post<InsumoOrdenado>(`${AppSettings.APIEndpoint}/ordenInsumo.php`, JSON.stringify(body), AppSettings.Options);
  }

  editarOrden(id: string, orden: InsumoOrdenado): Observable<InsumoOrdenado> {
    orden.id = id;
    orden.precio = parseFloat(orden.precio.toString());
    const body = {
      order: orden
    };
    return this.httpClient.put<InsumoOrdenado>(`${AppSettings.APIEndpoint}/ordenInsumo.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarOrden(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/ordenInsumo.php?id=${id}`, AppSettings.Options);
  }

  /*
  Otro Gasto
  */

  obtenerOtrosGastos(): Observable<OtroGasto[]> {
    return this.httpClient.get<OtroGasto[]>(`${AppSettings.APIEndpoint}/gastos.php`, AppSettings.Options);
  }

  obtenerOtroGasto(id: string): Observable<OtroGasto> {
    return this.httpClient.get<OtroGasto>(`${AppSettings.APIEndpoint}/gasto.php?id=${id}`, AppSettings.Options);
  }

  agregarOtroGasto(gasto: OtroGasto): Observable<OtroGasto> {
    const body = {
      expense: gasto
    };
    return this.httpClient.post<OtroGasto>(`${AppSettings.APIEndpoint}/gasto.php`, JSON.stringify(body), AppSettings.Options);
  }

  editarOtroGasto(id: string, gasto: OtroGasto): Observable<OtroGasto> {
    gasto.id = id;
    const body = {
      expense: gasto
    };
    return this.httpClient.put<OtroGasto>(`${AppSettings.APIEndpoint}/gasto.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarOtroGasto(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/gasto.php?id=${id}`, AppSettings.Options);
  }

  /*
  Gasto generado
  */

  obtenerGastosGenerados(idSucursal: string): Observable<GastoGenerado[]> {
    return this.httpClient.get<GastoGenerado[]>(
      `${AppSettings.APIEndpoint}/gastosGenerados.php?idSucursal=${idSucursal}`, AppSettings.Options);
  }

  obtenerGastoGenerado(id: string): Observable<GastoGenerado> {
    return this.httpClient.get<GastoGenerado>(`${AppSettings.APIEndpoint}/gastoGenerado.php?id=${id}`, AppSettings.Options);
  }

  agregarGastoGenerado(gasto: GastoGenerado, idSucursal: string): Observable<GastoGenerado> {
    gasto.restaurante = {
      id: idSucursal,
      nombre: null,
      estado: null,
      ciudad: null,
      IVA: null,
      rfc: null,
    };
    gasto.precio = parseFloat(gasto.precio.toString());
    const body = {
      expense: gasto
    };
    return this.httpClient.post<GastoGenerado>(`${AppSettings.APIEndpoint}/gastoGenerado.php`, JSON.stringify(body), AppSettings.Options);
  }

  editarGastoGenerado(id: string, gasto: GastoGenerado): Observable<GastoGenerado> {
    gasto.id = id;
    gasto.precio = parseFloat(gasto.precio.toString());
    const body = {
      expense: gasto
    };
    return this.httpClient.put<GastoGenerado>(`${AppSettings.APIEndpoint}/gastoGenerado.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarGastoGenerado(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/gastoGenerado.php?id=${id}`, AppSettings.Options);
  }
}
