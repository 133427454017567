import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { GruposDeOrdenesService } from 'src/app/core/servicios/grupos-de-ordenes.service';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { Comanda } from 'src/app/shared/class-models/comanda.model';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Orden, AgregarIngrediente, AlternativaProductoPorTamano } from 'src/app/shared/class-models/orden.model';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';

@Component({
  selector: 'app-resumen-grupo-de-ordenes',
  templateUrl: './resumen-grupo-de-ordenes.component.html',
  styleUrls: ['./resumen-grupo-de-ordenes.component.scss']
})
export class ResumenGrupoDeOrdenesComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  estadoPeticionObtenerGruposDeOrdenes: number;
  obtenerGruposDeOrdenesSubscription: Subscription;
  gruposDeOrdenes: GrupoDeOrdenes[];
  ordenes: Orden[][];//Usado para almacenar las ordenes de cada grupo de ordenes.
  ordenesAgrupadas: Orden[][];//Usado para agrupar de forma simple (producto, paquete y tamaño) las ordenes de cada grupo de ordenes.
  ordenesSinAgruparoPorOrdenesAgrupadas: Orden[][][];//Usado para almacenar las ordenes incluidas en cada grupo de ordenes.
  ordenesPorOrdenesAgrupadas: Orden[][][];//Usado para agrupar ordenes identicas de las ordenes incluidas en cada grupo de ordenes almacenadas en ordenesSinAgruparoPorOrdenesAgrupadas.
  TipoOrden = TipoOrden;

  constructor(
    public modal: MatDialogRef<ResumenGrupoDeOrdenesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      _idRestaurante: string,
      _idArea: string,
      _idElementoPorArea: string,
      comanda: Comanda
    },
    private _gruposDeOrdenesService: GruposDeOrdenesService,
    private _ordenesService: OrdenesService,
    private _alertasService: AlertasService
  ) {
    this.estadoPeticionObtenerGruposDeOrdenes = RequestState.initial;
  }

  ngOnInit() {
    this.obtenerGruposDeOrdenes();
  }

  obtenerGruposDeOrdenes(): void {
    this.gruposDeOrdenes = [];
    this.ordenes = [];
    this.ordenesAgrupadas = [];
    this.ordenesSinAgruparoPorOrdenesAgrupadas = [];
    this.ordenesPorOrdenesAgrupadas = [];
    this.estadoPeticionObtenerGruposDeOrdenes = RequestState.loading;
    this.obtenerGruposDeOrdenesSubscription = this._gruposDeOrdenesService.obtenerGruposDeOrdenes(this.data._idRestaurante, this.data._idArea, this.data._idElementoPorArea, this.data.comanda._id).subscribe(
      async (gruposDeOrdenes: GrupoDeOrdenes[]) => {
        this.gruposDeOrdenes = gruposDeOrdenes;
        for (let posicionGrupoDeOrdenes = 0; posicionGrupoDeOrdenes < this.gruposDeOrdenes.length; posicionGrupoDeOrdenes++) {
          this.ordenes.push(await this.obtenerOrdenes(this.gruposDeOrdenes[posicionGrupoDeOrdenes]._id));
          this.inicializarOrdenesAgrupadas(posicionGrupoDeOrdenes);
        }
        this.estadoPeticionObtenerGruposDeOrdenes = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerGruposDeOrdenes = RequestState.error;
        this.modal.close();
      }
    );
  }

  async obtenerOrdenes(_idGrupoDeOrdenes: string): Promise<Orden[]> {
    return new Promise<Orden[]>((resolve, reject) => {
      this.obtenerGruposDeOrdenesSubscription = this._ordenesService.obtenerOrdenes(this.data._idRestaurante, this.data._idArea, this.data._idElementoPorArea, this.data.comanda._id, _idGrupoDeOrdenes).subscribe(
        (ordenes: Orden[]) => {
          resolve(ordenes)
        },
        (error: HttpErrorResponse) => {
          resolve(null)
        }
      );
    });
  }

  inicializarOrdenesAgrupadas(posicionGrupoDeOrdenes: number): void {
    this.ordenesAgrupadas[posicionGrupoDeOrdenes] = [];
    this.ordenesSinAgruparoPorOrdenesAgrupadas[posicionGrupoDeOrdenes] = [];
    this.ordenesPorOrdenesAgrupadas[posicionGrupoDeOrdenes] = [];
    this.ordenes[posicionGrupoDeOrdenes].forEach(orden => {
      const posicionOrdenAgrupada = this.ordenesAgrupadas[posicionGrupoDeOrdenes].findIndex(ordenAgrupada => {
        if (ordenAgrupada.tipo == orden.tipo && ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano) {
          switch (ordenAgrupada.tipo) {
            case TipoOrden.Producto:
              return ordenAgrupada.datosProducto._idProducto == orden.datosProducto._idProducto;
            case TipoOrden.Paquete:
              return ordenAgrupada.datosPaquete._idPaquete == orden.datosPaquete._idPaquete;
          }
        } else {
          return false;
        }
      })
      if (posicionOrdenAgrupada != -1) {
        (<number>this.ordenesAgrupadas[posicionGrupoDeOrdenes][posicionOrdenAgrupada].cantidad) += <number>orden.cantidad;
        this.ordenesSinAgruparoPorOrdenesAgrupadas[posicionGrupoDeOrdenes][posicionOrdenAgrupada].push(orden);
      } else {
        let ordenAgrupada = new Orden();
        ordenAgrupada.tipo = orden.tipo;
        ordenAgrupada.cantidad = orden.cantidad;
        ordenAgrupada.precioPorTamano._idTamano = orden.precioPorTamano._idTamano;
        ordenAgrupada.precioPorTamano.tamano = orden.precioPorTamano.tamano;
        switch (ordenAgrupada.tipo) {
          case TipoOrden.Producto:
            ordenAgrupada.datosProducto._idProducto = orden.datosProducto._idProducto;
            ordenAgrupada.datosProducto.producto = orden.datosProducto.producto;
            break;
          case TipoOrden.Paquete:
            ordenAgrupada.datosPaquete._idPaquete = orden.datosPaquete._idPaquete;
            ordenAgrupada.datosPaquete.paquete = orden.datosPaquete.paquete;
            break;
        }
        this.ordenesAgrupadas[posicionGrupoDeOrdenes].push(ordenAgrupada);
        this.ordenesSinAgruparoPorOrdenesAgrupadas[posicionGrupoDeOrdenes].push([]);
        this.ordenesSinAgruparoPorOrdenesAgrupadas[posicionGrupoDeOrdenes][this.ordenesSinAgruparoPorOrdenesAgrupadas[posicionGrupoDeOrdenes].length - 1].push(orden);
      }
    })
    for (let posicionOrdenesAgrupadas = 0; posicionOrdenesAgrupadas < this.ordenesAgrupadas[posicionGrupoDeOrdenes].length; posicionOrdenesAgrupadas++) {
      this.inicializarOrdenesPorOrdenAgrupada(posicionGrupoDeOrdenes, posicionOrdenesAgrupadas);
    }
  }

  inicializarOrdenesPorOrdenAgrupada(posicionGrupoDeOrdenes: number, posicionOrdenAgrupada: number): void {
    this.ordenesPorOrdenesAgrupadas[posicionGrupoDeOrdenes][posicionOrdenAgrupada] = [];
    this.ordenesSinAgruparoPorOrdenesAgrupadas[posicionGrupoDeOrdenes][posicionOrdenAgrupada].forEach(orden => {
      const posicionOrden = this.obtenerPosicionOrden(posicionGrupoDeOrdenes, posicionOrdenAgrupada, orden);
      if (posicionOrden == -1) {
        this.ordenesPorOrdenesAgrupadas[posicionGrupoDeOrdenes][posicionOrdenAgrupada].push(orden);
      } else {
        (<number>this.ordenesPorOrdenesAgrupadas[posicionGrupoDeOrdenes][posicionOrdenAgrupada][posicionOrden].cantidad) += <number>orden.cantidad;
      }
    });
  }

  obtenerPosicionOrden(posicionGrupoDeOrdenes: number, posicionOrdenAgrupada: number, nuevaOrden: Orden): number {
    return this.ordenesPorOrdenesAgrupadas[posicionGrupoDeOrdenes][posicionOrdenAgrupada].findIndex(orden => {
      let esOrdenIdentica: boolean = true;
      if (orden.tipo == nuevaOrden.tipo && orden.precioPorTamano._idTamano == nuevaOrden.precioPorTamano._idTamano && orden.precioPorTamano.precio == nuevaOrden.precioPorTamano.precio) {
        switch (nuevaOrden.tipo) {
          case TipoOrden.Producto:
            if (
              nuevaOrden.datosProducto._idProducto != orden.datosProducto._idProducto ||
              !this.quitarIngredientesIdentico(<Ingrediente[]>orden.datosProducto.quitarIngredientes, <Ingrediente[]>nuevaOrden.datosProducto.quitarIngredientes) ||
              !this.agregarIngredientesIdentico(orden.datosProducto.agregarIngredientes, nuevaOrden.datosProducto.agregarIngredientes) ||
              !this.notasIdentico(orden.datosProducto.notas, nuevaOrden.datosProducto.notas)) {
              esOrdenIdentica = false;
            }
            break;
          case TipoOrden.Paquete:
            if (nuevaOrden.datosPaquete._idPaquete != orden.datosPaquete._idPaquete ||
              !this.alternativasProductosPorTamanoIdentico(orden.datosPaquete.alternativasProductosPorTamano, nuevaOrden.datosPaquete.alternativasProductosPorTamano)
            ) {
              esOrdenIdentica = false;
            }
            break;
        }
      } else {
        esOrdenIdentica = false;
      }
      return esOrdenIdentica;
    });
  }


  quitarIngredientesIdentico(quitarIngredientesOrden: Ingrediente[], quitarIngredientesNuevaOrden: Ingrediente[]): boolean {
    let esIdentico: boolean = true;
    if (quitarIngredientesOrden.length == quitarIngredientesNuevaOrden.length) {
      for (let posicionIngrediente = 0; posicionIngrediente < quitarIngredientesOrden.length; posicionIngrediente++) {
        if (quitarIngredientesOrden[posicionIngrediente]._id != quitarIngredientesNuevaOrden[posicionIngrediente]._id) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  agregarIngredientesIdentico(agregarIngredientesOrden: AgregarIngrediente[], agregarIngredientesNuevaOrden: AgregarIngrediente[]): boolean {
    let esIdentico: boolean = true;
    if (agregarIngredientesOrden.length == agregarIngredientesNuevaOrden.length) {
      for (let posicionIngrediente = 0; posicionIngrediente < agregarIngredientesOrden.length; posicionIngrediente++) {
        if (agregarIngredientesOrden[posicionIngrediente]._idIngrediente != agregarIngredientesNuevaOrden[posicionIngrediente]._idIngrediente ||
          agregarIngredientesOrden[posicionIngrediente].precio != agregarIngredientesNuevaOrden[posicionIngrediente].precio) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  notasIdentico(notasOrden: string[], notasNuevaOrden: string[]): boolean {
    let esIdentico: boolean = true;
    if (notasOrden.length == notasNuevaOrden.length) {
      for (let posicionNota = 0; posicionNota < notasOrden.length; posicionNota++) {
        if (notasOrden[posicionNota] != notasNuevaOrden[posicionNota]) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  alternativasProductosPorTamanoIdentico(alternativasProductosPorTamanoOrden: AlternativaProductoPorTamano[], alternativasProductosPorTamanoNuevaOrden: AlternativaProductoPorTamano[]): boolean {
    let esIdentico: boolean = true;
    if (alternativasProductosPorTamanoOrden.length == alternativasProductosPorTamanoNuevaOrden.length) {
      for (let posicionAlternativaProductoPorTamano = 0; posicionAlternativaProductoPorTamano < alternativasProductosPorTamanoOrden.length; posicionAlternativaProductoPorTamano++) {
        if (alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano]._idProducto != alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano]._idProducto ||
          alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano]._idTamano != alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano]._idTamano ||
          !this.quitarIngredientesIdentico(<Ingrediente[]>alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].quitarIngredientes, <Ingrediente[]>alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].quitarIngredientes) ||
          !this.agregarIngredientesIdentico(alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].agregarIngredientes, alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].agregarIngredientes) ||
          !this.notasIdentico(alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].notas, alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].notas)
        ) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  /* MÉTODOS PARA IMPRIMIR EL GRUPO DE ORDENES */
  imprimirGrupoDeOrdenes(grupoDeOrdenes: GrupoDeOrdenes) {
    this.modal.close(grupoDeOrdenes);
  }

  cerrar(): void {
    this.modal.close();
  }

  ngOnDestroy(): void {
    if (this.obtenerGruposDeOrdenesSubscription) this.obtenerGruposDeOrdenesSubscription.unsubscribe();
  }
}
