import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Categoria} from '../../../../shared/models/categoria';
import {Tamano} from '../../../../shared/models/tamano';
import {SizeService} from '../../../../core/services/size.service';
import {CategoriesService} from '../../../../core/services/categories.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-menu-selector',
  templateUrl: './kiosk-selector.component.html'
})
export class KioskSelectorComponent implements OnInit, OnDestroy {

  @Output() categoryID = new EventEmitter<string>();
  categoriesSubscription: Subscription;
  categories: Categoria[];
  selected: string;

  sizes: Tamano[];
  sizesSubscription: Subscription;

  constructor(private categoriesService: CategoriesService,
              private sizeService: SizeService) {
    this.selected = '';
  }

  ngOnInit(): void {
    this.categoriesSubscription = this.categoriesService.getCategories().subscribe(data => this.categories = data );
    this.sizesSubscription = this.sizeService.getSizes().subscribe(data => this.sizes = data);
  }

  ngOnDestroy(): void {
    this.categoriesSubscription.unsubscribe();
    this.sizesSubscription.unsubscribe();
  }

  setCategory(): void {
    this.categoryID.emit(this.selected);
  }

}
