import {Routes} from '@angular/router';

import {AreaComponent} from './area.component';
import {EditAreaComponent} from './pages/edit-area/edit-area.component';
import {EditTableComponent} from './pages/edit-table/edit-table.component';
import {TableComponent} from './pages/table/table.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {OrderComponent} from './pages/order/order.component';
import {AtLeastBarGuard} from '../../../../core/guards/at-least-bar';
import { PagoComponent } from './pages/pago/pago.component';

export const AreaRoutes: Routes = [
  {
    path: '',
    component: AreaComponent
  },
  {
    path: 'editar',
    component: EditAreaComponent
  },
  {
    path: 'mesa/:idMesa',
    data: {
      breadcrumb: 'Mesa'
    },
    children: [
      {
        path: '',
        component: TableComponent
      },
      {
        path: 'editar',
        data: {
          breadcrumb: 'Editar'
        },
        component: EditTableComponent
      },
      {
        path: 'pago',
        data: {
          breadcrumb: 'Pago'
        },
        component: PagoComponent,
        canActivate: [AtLeastBarGuard]
      },
      {
        path: 'orden/:idComanda/:tipo/:idTipo/:idTamano',
        data: {
          breadcrumb: 'Orden'
        },
        component: OrderComponent
      }
    ]
  }
];
