import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Paquete} from '../../shared/models/paquete';
import {AppSettings} from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  constructor(private httpClient: HttpClient) {
  }

  obtenerPaquete(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/paquete.php?id=` + id, AppSettings.Options)
      .pipe(map((res: Paquete[]) => {
        const fl = res.filter(paquete => paquete.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  obtenerPaqueteSucursal(id: string, idSucursal: String): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/paquete.php?id=` + id + `&idSucursal=` + idSucursal, AppSettings.Options)
      .pipe(map((res: Paquete[]) => {
        const fl = res.filter(paquete => paquete.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  obtenerPaquetes(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/paquetes/paquetes.php`, AppSettings.Options);
  }

  obtenerPaquetesValidos(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/paquetes/paquetes-validos.php`, AppSettings.Options);
  }

  nuevoPaquete(paquete: any): Observable<any> {
    const body = {
      paquete
    };
    return this.httpClient.post<any>(`${AppSettings.APIEndpoint}/paquete.php`, JSON.stringify(body), AppSettings.Options);
  }

  actualizarPaquete(id: string) {
    // Pendiente
  }

  eliminarPaquete(id: string) {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/paquete.php?id=${id}`, AppSettings.Options);
  }
}
