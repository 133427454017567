import {CommonModule} from '@angular/common';
import {
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTabsModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {KioskComponent} from './kiosk.component';
import {KioskProductComponent} from './components/menu-products/kiosk-product.component';
import {KioskPromoComponent} from './components/menu-promos/kiosk-promo.component';
import {KioskSelectorComponent} from './components/selector/kiosk-selector.component';
import {MenuModule} from '../restaurant/pages/area/pages/menu/menu.module';
import { MenuProductosComponent } from './components/menu-productos/menu-productos.component';
import { MenuPaquetesComponent } from './components/menu-paquetes/menu-paquetes.component';

// Components.

@NgModule({
  declarations: [
    KioskComponent,
    KioskSelectorComponent,
    KioskProductComponent,
    KioskPromoComponent,
    MenuProductosComponent,
    MenuPaquetesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MenuModule,
    MatIconModule
  ],
  exports: [
    KioskComponent
  ]
})
export class KioskModule {
}
