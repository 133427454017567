import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {formatCurrency} from '@angular/common';
import {Color, Label} from 'ng2-charts';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { TipoDeReporte } from 'src/app/shared/enums/tipo-de-reporte.enum';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { ReportesService } from 'src/app/core/servicios/reportes.service';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';

@Component({
  selector: 'app-cashier-report',
  templateUrl: './cashier-report.component.html',
  styleUrls: ['./cashier-report.component.scss']
})
export class CashierReportComponent implements OnInit {
  @Input() tipoUsuario: number;
  @Input() _idRestaurante: string;

  /* ------------------------------- START OF PIE CHART VARIABLES ------------------------------- */
  colors = [
    { // green
      backgroundColor: 'rgba(7,111,0,0.3)',
      borderColor: 'rgba(7,111,07,1)'
    },
    { // red
      backgroundColor: 'rgba(185,0,32,0.3)',
      borderColor: 'rgba(185,0,32,1)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.3)',
      borderColor: 'rgba(77,83,96,1)'
    },
    { // green
      backgroundColor: 'rgba(127,208,67,0.3)',
      borderColor: 'rgba(127,208,67)'
    },
    { // red
      backgroundColor: 'rgba(255,89,114,0.3)',
      borderColor: 'rgba(255,89,114,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.3)',
      borderColor: 'rgba(148,159,177,1)'
    }
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return formatCurrency(parseFloat(value.toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
        },
      },
    },
    tooltips: {
      callbacks: {
        label(tooltipItems, data) {
          /*const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;*/
          return data.labels[tooltipItems.index] + ': '
            + formatCurrency(parseFloat(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toString()),
              'en',
              '$',
              'MXN',
              '.2-2');
        }
      }
    }
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [],
      borderColor: []
    }
  ];
  totalDeCobros: number;
  totalDePropinas: number;
  /* ------------------------------- END OF PIE CHART VARIABLES ------------------------------- */

  /* ------------------------------- START OF BAR CHART VARIABLES ------------------------------- */
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            min: 0,
            // Include a dollar sign in the ticks
            callback(value, index, values) {
              return formatCurrency(parseFloat(value.toString()),
                'en',
                '$',
                'MXN',
                '.2-2');
            }
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return formatCurrency(parseFloat(value.toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
        },
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartColors: Color[] = [
    { // green
      backgroundColor: 'rgba(7,111,0,0.3)',
      borderColor: 'rgba(7,111,0,1)',
      pointBackgroundColor: 'rgba(7,111,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(7,111,0,0.7)'
    },
    { // light green
      backgroundColor: 'rgba(127,208,67,0.3)',
      borderColor: 'rgba(127,208,67,1)',
      pointBackgroundColor: 'rgba(127,208,67,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(127,208,67,0.7)'
    },
    { // dark gray
      backgroundColor: 'rgba(77,83,96,0.3)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,0.7)'
    },
    { // gray
      backgroundColor: 'rgba(148,159,177,0.3)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.7)'
    },
  ];
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    {data: [], label: 'Cobros de ventas en efectivo (excluyendo propinas)', borderWidth: 2},
    {data: [], label: 'Cobros de propinas en efectivo', borderWidth: 2},
    {data: [], label: 'Cobros de ventas con tarjeta (excluyendo propinas)', borderWidth: 2},
    {data: [], label: 'Cobros de propinas con tarjeta', borderWidth: 2}
  ];
  totalVendidoEfectivo: number;
  totalPropinasEfectivo: number;
  totalVendidoOtro: number;
  totalPropinasOtro: number;
  /* ------------------------------- END OF BAR CHART VARIABLES ------------------------------- */


  form: FormGroup;
  requestState: number;
  restaurantsRequestState: number;
  restaurantsSubscription: Subscription;
  reportSubscription: Subscription;
  restaurants: Restaurante[];
  dias: number[];
  TipoUsuario = TipoUsuario;
  TipoDeReporte = TipoDeReporte;

  constructor(private _restaurantesService: RestaurantesService, private _reportesService: ReportesService) {
    this.requestState = RequestState.initial;
    this.totalDeCobros = 0;
  }

  ngOnInit() {
    this.form = new FormGroup(
      {
        reporte: new FormControl(TipoDeReporte.Anual, [Validators.required]),
        anio: new FormControl(2020, [Validators.required]),
        mes: new FormControl(1, [Validators.required]),
        dia: new FormControl(1, [Validators.required]),
        _idRestaurante: new FormControl(this.tipoUsuario === TipoUsuario.Administrador ? '0' : this._idRestaurante, [
            Validators.required
          ]
        )
      });
    this.calcularDias();
    this.restaurantsRequestState = RequestState.loading;
    if (this.tipoUsuario === TipoUsuario.Administrador) {
      this.requestState = RequestState.loading;
      this.restaurantsSubscription = this._restaurantesService.obtenerRestaurantes().subscribe(
        (res: Restaurante[]) => {
          this.restaurants = res;
          this.restaurantsRequestState = RequestState.success;
          this.generarReporte();
        }, error => {
          this.restaurantsRequestState = RequestState.error;
        }
      );
    } else {
      this.restaurantsRequestState = RequestState.success;
      this.generarReporte();
    }
  }

  calcularDias() {
    if (this.form.get('reporte').value === TipoDeReporte.Diario) {
      this.dias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
      const maximoNumeroDeDias = new Date(this.form.get('anio').value, this.form.get('mes').value, 0).getDate();
      for (let i = 29; i <= maximoNumeroDeDias; i++) {
        this.dias.push(i);
      }
      this.form.get('dia').setValue(1);
    }
  }

  generarReporte() {
    this.requestState = RequestState.loading;
    let _idRestaurante: string;
    if (this.form.get('_idRestaurante').value === '0') {
      _idRestaurante = null;
    } else {
      _idRestaurante = this.form.get('_idRestaurante').value
    }
    const reporte: number = this.form.get('reporte').value;
    const anio: number = this.form.get('anio').value;
    const mes: number = this.form.get('mes').value;
    const dia: number = this.form.get('dia').value;
    this.reportSubscription = this._reportesService.obtenerVentasCajeros(_idRestaurante, reporte, anio, mes, dia).subscribe(
      (sales: any[]) => {
        this.inicializarGrafico();
        let posicionColor = 0;
        this.totalDeCobros = 0;
        this.totalDePropinas = 0;
        this.totalVendidoEfectivo = 0;
        this.totalPropinasEfectivo = 0;
        this.totalVendidoOtro = 0;
        this.totalVendidoOtro = 0;
        sales.forEach((sale) => {
          // Pie chart generation
          this.pieChartColors[0].backgroundColor.push(this.colors[posicionColor].backgroundColor);
          this.pieChartColors[0].borderColor.push(this.colors[posicionColor].borderColor);
          posicionColor++;
          if (posicionColor === this.colors.length) {
            posicionColor = 0;
          }
          this.pieChartLabels.push(sale.cajero.nombres + ' ' + sale.cajero.primerApellido);
          this.pieChartData.push(sale.total);
          this.totalDeCobros += sale.total;
          this.totalDePropinas += sale.totalPropinas;
          // Bar chart generation
          this.barChartLabels.push(sale.cajero.nombres + ' ' + sale.cajero.primerApellido);
          this.barChartData[0].data.push(sale.totalVendidoEfectivo);
          this.totalVendidoEfectivo = sale.totalVendidoEfectivo;
          this.barChartData[1].data.push(sale.totalPropinasEfectivo);
          this.totalPropinasEfectivo = sale.totalPropinasEfectivo;
          this.barChartData[2].data.push(sale.totalVendidoOtro);
          this.totalVendidoOtro = sale.totalVendidoOtro;
          this.barChartData[3].data.push(sale.totalPropinasOtro);
          this.totalPropinasOtro = sale.totalPropinasOtro;
        });
        this.requestState = RequestState.success;
      }, error => {
        this.requestState = RequestState.error;
      }
    );
  }

  inicializarGrafico() {
    // Pie chart initialization
    this.pieChartLabels = [];
    this.pieChartData = [];
    this.pieChartColors = [
      {
        backgroundColor: [],
        borderColor: []
      }
    ];
    // Bar chart initialization
    this.barChartLabels = [];
    this.barChartData.forEach(barData => barData.data = []);
  }
}
