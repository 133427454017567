import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {BaseChartDirective, Color, Label} from 'ng2-charts';
import {formatCurrency} from '@angular/common';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestState} from '../../../../shared/enums/request-state.enum';
import { ReportesService } from 'src/app/core/servicios/reportes.service';
import { TipoDeReporte } from 'src/app/shared/enums/tipo-de-reporte.enum';

@Component({
  selector: 'app-license-seller',
  templateUrl: './license-seller.component.html',
  styleUrls: []
})
export class LicenseSellerComponent implements OnInit, OnDestroy {
  public lineChartData: ChartDataSets[] = [
    {data: [], label: 'Venta total'},
  ];
  public lineChartLabels: Label[];
  public monthsLabels: Label[] =
    ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            // Include a dollar sign in the ticks
            callback(value, index, values) {
              return formatCurrency(parseFloat(value.toString()),
                'en',
                '$',
                'MXN',
                '.2-2');
            }
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter(value) {
          const amount = formatCurrency(parseFloat(value.toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
          return amount === '$0.00' ? '' : amount;
        },
      },
    },
    tooltips: {
      callbacks: {
        label(tooltipItems, data) {
          return formatCurrency(parseFloat(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
        }
      }
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          borderWidth: 2,
          label: {
            enabled: true,
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // green
      backgroundColor: 'rgba(7,111,0,0.3)',
      borderColor: 'rgba(7,111,0,1)',
      pointBackgroundColor: 'rgba(7,111,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(7,111,0,0.7)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  requestState: number;
  salesSubscription: Subscription;
  form: FormGroup;
  emptyRequest: boolean;
  totalDeVentas: number;
  TipoDeReporte = TipoDeReporte;

  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

  constructor(private _reportesService: ReportesService) {
    this.requestState = RequestState.initial;
  }

  ngOnInit() {
    this.form = new FormGroup(
      {
        reporte: new FormControl(TipoDeReporte.Anual, [Validators.required]),
        anio: new FormControl(2020, [Validators.required]),
        mes: new FormControl(1, [Validators.required]),
      });
    this.emptyRequest = true;
    this.lineChartLabels = this.monthsLabels;
    this.generarReporte();
  }

  generarReporte() {
    this.requestState = RequestState.loading;
    const reporte: number = this.form.get('reporte').value;
    const anio: number = this.form.get('anio').value;
    const mes: number = this.form.get('mes').value;
    this.salesSubscription = this._reportesService.obtenerVentasLicenciasPersonal(reporte, anio, mes).subscribe(
      (sales: any[]) => {
        if (sales.length > 0) {
          this.emptyRequest = false;
        } else {
          this.emptyRequest = true;
        }
        if (reporte == TipoDeReporte.Anual) {
          this.totalDeVentas = this.acomodarDatosAnualesEnLaGrafica(sales, 0);
          this.lineChartData[0].label = 'Ventas total (' + formatCurrency(parseFloat(this.totalDeVentas.toString()),
            'en',
            '$',
            'MXN',
            '.2-2') + ')';
        } else {
          this.totalDeVentas = this.acomodarDatosMensualesEnLaGrafica(sales, 0, anio, mes);
          this.lineChartData[0].label = 'Venta total (' + formatCurrency(parseFloat(this.totalDeVentas.toString()),
            'en',
            '$',
            'MXN',
            '.2-2') + ')';
        }
        this.requestState = RequestState.success;
      }, error => {
        this.requestState = RequestState.error;
      }
    );
  }

  acomodarDatosAnualesEnLaGrafica(datos: any[], posicionGrafica: number): number {
    this.lineChartLabels = this.monthsLabels;
    let acomulador = 0;
    this.lineChartData[posicionGrafica].data = [];
    for (let i = 1; i <= 12; i++) {
      const posicionDatoMensual = datos.findIndex((datoMensual) => {
        return datoMensual.mes === i;
      });
      if (posicionDatoMensual === -1) {
        this.lineChartData[posicionGrafica].data.push(0);
      } else {
        acomulador += datos[posicionDatoMensual].total;
        this.lineChartData[posicionGrafica].data.push(datos[posicionDatoMensual].total);
      }
    }
    return acomulador;
  }

  acomodarDatosMensualesEnLaGrafica(datos: any[], posicionGrafica: number, anio: number, mes: number): number {
    const diasDelMes: number = (new Date(anio, mes, 0)).getDate();
    let acomulador = 0;
    
    this.lineChartLabels = [];
    this.lineChartData[posicionGrafica].data = [];
    for (let i = 1; i <= diasDelMes; i++) {
      this.lineChartLabels.push(i.toString());
      const posicionDatoDiario = datos.findIndex((datoDiario) => {
        return datoDiario.dia === i;
      });
      if (posicionDatoDiario === -1) {
        this.lineChartData[posicionGrafica].data.push(0);
      } else {
        acomulador += datos[posicionDatoDiario].total;
        this.lineChartData[posicionGrafica].data.push(datos[posicionDatoDiario].total);
      }
    }
    return acomulador;
  }

  public chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
    
  }

  public chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {
    
  }

  ngOnDestroy(): void {
    if (this.salesSubscription !== undefined) {
      this.salesSubscription.unsubscribe();
    }
  }
}
