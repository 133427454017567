import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-agregar-tamano-configuracion',
  templateUrl: './agregar-tamano-configuracion.component.html',
  styleUrls: ['./agregar-tamano-configuracion.component.scss']
})
export class AgregarTamanoConfiguracionComponent implements OnInit, OnDestroy {
  titulo = 'Agregar tamaño';
  ExpresionesRegulares = ExpresionesRegulares;
  tamano: Tamano = new Tamano();
  estadoPeticionAgregarTamano: number;
  RequestState = RequestState;
  agregarTamanoSubscription: Subscription;

  /*
    VALIDACIÓN DEL NOMBRE DEL TAMAÑO
    this.nombreTamano = new FormControl('', [Validators.pattern('^((?!default)(?!Default).)*$'), Validators.required]);
  */

  constructor(
    private _tamanosService: TamanosService,
    private _alertasService: AlertasService,
    public dialogRef: MatDialogRef<AgregarTamanoConfiguracionComponent>
  ) {
    this.estadoPeticionAgregarTamano = RequestState.initial;
  }

  ngOnInit(): void {}

  agregarTamano() {
    this.estadoPeticionAgregarTamano = RequestState.loading;
    this.agregarTamanoSubscription = this._tamanosService.agregarTamano(this.tamano).subscribe(
      (tamano: Tamano) => {
        this.estadoPeticionAgregarTamano = RequestState.success;
        this.dialogRef.close(tamano);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarTamano = RequestState.error;
      }
    );
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.agregarTamanoSubscription) this.agregarTamanoSubscription.unsubscribe();
  }
}
