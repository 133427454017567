import { Component, OnDestroy, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { LicenceInfoComponent } from 'src/app/modules/restaurant/pages/dialogs/licence-info/licence-info.component';
import { LicenciasService } from 'src/app/core/servicios/licencias.service';
import { Licencia } from 'src/app/shared/class-models/licencia.model';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FolioPipe } from 'src/app/shared/pipes/folio.pipe';
import { FolioSinEspaciosPipe } from 'src/app/shared/pipes/folio-sin-espacios.pipe';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html'
})
export class LicensesComponent implements OnInit, OnDestroy {
  @Output() peticionObtenerLicenciasFinalizada = new EventEmitter<boolean>();
  @Input() estadoPeticiones: number;
  @ViewChild('paginatorLicencias', {read: MatPaginator, static: false}) paginatorLicencias: MatPaginator;
  obtenerLicenciasSubscription: Subscription;
  columnasTabla: string[] = ['serial', 'validez', 'costo'];//, 'opciones'
  estadoPeticion: number;
  RequestState = RequestState;
  dataSource: MatTableDataSource<Licencia>;
  filtro: string = '';

  constructor(
    private _licenciasService: LicenciasService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    private folioPipe: FolioPipe,
    private folioSinEspaciosPipe: FolioSinEspaciosPipe,
    private fechaPipe: FechaPipe) {
  }

  ngOnInit() {
    this.obtenerLicencias();
  }

  obtenerLicencias() {
    this.estadoPeticion = RequestState.loading;
    this.obtenerLicenciasSubscription = this._licenciasService.obtenerLicencias().subscribe(
      (licencias: Licencia[]) => {
        this.inicializarLicencias(licencias);
        this.estadoPeticion = RequestState.success;
        this.peticionObtenerLicenciasFinalizada.emit(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticion = RequestState.error;
        this.peticionObtenerLicenciasFinalizada.emit(true);
      }
    );
  }

  inicializarLicencias(licencias: Licencia[]): void {
    this.dataSource = new MatTableDataSource<Licencia>(licencias);
    this.dataSource.paginator = this.paginatorLicencias;
    this.dataSource.filterPredicate = (licencia: Licencia, filtro: string) => {
      return licencia._id.trim().toLowerCase().indexOf(filtro) !== -1 ||
        ('$' + Number(licencia.monto).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.folioSinEspaciosPipe.transform(licencia._id, 'folio-sin-espacios').trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.folioPipe.transform(licencia._id, 'folio').trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerValidezLicencia(licencia).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerValidezLicencia(licencia).trim().replace(/\s/g, '').toLowerCase().indexOf(filtro) !== -1;
    }
  }

  obtenerValidezLicencia(licencia: Licencia): string {
    return this.fechaPipe.transform(licencia.fechaInicio as Date) + ' - ' + this.fechaPipe.transform(licencia.fechaFin as Date);
  }

  buscarLicencia(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  ngOnDestroy() {
    if (this.obtenerLicenciasSubscription) this.obtenerLicenciasSubscription.unsubscribe();
  }

  /*licenseInfo(index: number): void {
    this.dialog.open(LicenceInfoComponent, {
      width: '500px',
      data: this.licencias[index],
      disableClose: true
    });
  }*/
}
