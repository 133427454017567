import { Usuario } from './usuario.model';

export class TipoDeGastoGeneral {
    _id?: string;
    id?: number;
    concepto: string;
    descripcion?: string;
    tieneReceptor: boolean;
    tipoDePersona: number;
    nombres: string;
    primerApellido: string;
    segundoApellido?: string;
    razonSocial: string;
    tieneRepresentante?: boolean;
    nombresRepresentante?: string;
    primerApellidoRepresentante?: string;
    segundoApellidoRepresentante?: string;
    rfc: string;
    telefono?: string;
    correo?: string;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.tieneReceptor = false;
        this.tieneRepresentante = false;
    }
}