import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CuentasService } from 'src/app/core/servicios/cuentas.service';
import { ImpresionService } from 'src/app/core/servicios/impresion.service';
import { BillDetailsComponent } from 'src/app/modules/restaurant/pages/cash-register/dialogs/bill-details/bill-details.component';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { TipoDescuento } from 'src/app/shared/enums/tipo-descuento.enum';
import { TipoImpresion } from 'src/app/shared/enums/tipo-impresion.enum';
import { TipoPropina } from 'src/app/shared/enums/tipo-propina.enum';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { FechaConHoraPipe } from 'src/app/shared/pipes/fecha-con-hora.pipe';

@Component({
  selector: 'app-caja-consultada',
  templateUrl: './caja-consultada.component.html',
  styleUrls: ['./caja-consultada.component.scss']
})
export class CajaConsultadaComponent implements OnInit {
  @Input() tipoDeUsuario: number;
  @Input() cajaDeUsuario: CajaDeUsuario;
  TipoUsuario = TipoUsuario;
  RequestState = RequestState;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Cuenta>;
  filtro: string = '';
  estadoPeticionObtenerCuentasCaja: number;
  obtenerCuentasCajaSubscription: Subscription;
  columnas = ['fechaRegistro', /*'formaDePago',*/ 'subtotal', 'iva', 'cupon', 'descuento', 'propina', 'total', 'opciones'];
  TipoCupon = TipoCupon;
  TipoDescuento = TipoDescuento;
  TipoPropina = TipoPropina;

  constructor(
    private _cuentasService: CuentasService,
    private _impresionService: ImpresionService,
    private fechaConHora: FechaConHoraPipe,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerCuentasCaja = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCuentasCaja();
  }

  obtenerCuentasCaja(): void {
    this.estadoPeticionObtenerCuentasCaja = RequestState.loading;
    this.obtenerCuentasCajaSubscription = this._cuentasService.obtenerCuentasCaja(this.cajaDeUsuario._idRestaurante, this.cajaDeUsuario._id).subscribe(
      (cuentas: Cuenta[]) => {
        this.inicializarCuentas(cuentas);
        this.cajaDeUsuario.montoVentaTotal = this.calcularTotalCajaDeUsuario();
        this.estadoPeticionObtenerCuentasCaja = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCuentasCaja = RequestState.error;
      }
    );
  }

  inicializarCuentas(cuentas: Cuenta[]): void {
    this.dataSource = new MatTableDataSource<Cuenta>(cuentas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (cuenta: Cuenta, filtro: string) => {
      return this.fechaConHora.transform(cuenta.fechaRegistro).trim().toLowerCase().indexOf(filtro) !== -1 ||
        ('$' + Number(cuenta.subtotal).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1 ||
        ('$' + Number(cuenta.total).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  obtenerNombreCompleto(empleado: Usuario): string {
    return empleado.nombres + ' ' + empleado.primerApellido + (empleado.segundoApellido ? (' ' + empleado.segundoApellido) : '');
  }

  calcularTotalCajaDeUsuario(): number {
    return this.dataSource.data
      .map(cuenta => cuenta.total)
      .reduce((acomulador, total) => {return acomulador + total}, 0);
  }

  buscarCuenta(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  verCuenta(cuenta: Cuenta): void {
    const dialogRef = this.dialog.open(BillDetailsComponent,
      { 
        width: '80%', 
        disableClose: true, 
        data: { _idRestaurante: this.cajaDeUsuario._idRestaurante, cuenta: cuenta } 
      });
  }

  async imprimirCuenta(cuenta: Cuenta) {
    this._impresionService.actualizarTipoImpresion(TipoImpresion.Cuenta);
    await this._impresionService.actualizarCuenta(cuenta);
    await this._impresionService.actualizarOrdenesAgrupadasCuenta(cuenta);
    window.print();
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerCuentasCajaSubscription) this.obtenerCuentasCajaSubscription.unsubscribe();
  }
}
