import {MatButtonModule} from '@angular/material/button';
import {
  MatBadgeModule,
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatStepperModule,
  MatTooltipModule,
  MatProgressBarModule,
  MatSlideToggleModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {RestaurantsComponent} from './restaurants.component';
// Components.
import {RestaurantCardComponent} from './components/restaurant-card/restaurant-card.component';
// Pages.
import {NewRestaurantComponent} from './pages/new-restaurant/new-restaurant.component';
// Routes.
import {RestaurantsRoutes} from './restaurants.routes';
import {ImageCropperModule} from 'ngx-image-cropper';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../shared/pipes/pipes.module';

@NgModule({
  declarations: [
    RestaurantsComponent,
    NewRestaurantComponent,
    RestaurantCardComponent
  ],
  imports: [
    RouterModule.forRoot(RestaurantsRoutes),
    ImageCropperModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    RestaurantsComponent,
    NewRestaurantComponent,
    RestaurantCardComponent
  ]
})
export class RestaurantsModule {
}
