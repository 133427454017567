import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppSettings} from '../../../../configs/app-settings.config';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';

@Component({
  selector: 'app-restaurant-card',
  templateUrl: './restaurant-card.component.html'
})
export class RestaurantCardComponent implements OnInit {
  @Input() restaurante: Restaurante;
  @Input() verTitulo: boolean;
  @Input() verVerMas: boolean;
  @Input() verConfiguracion: boolean;
  @Input() verActivo: boolean;

  AppSettings = AppSettings;

  @Output() edit = new EventEmitter<Restaurante>();
  @Output() delete = new EventEmitter<Restaurante>();

  constructor() {
  }

  ngOnInit() {
  }

  mostrarOpcionesConfiguracion() {
    if (!document.getElementById(`configurar${this.restaurante.id}`).classList.contains('rollIn')) {
      document.getElementById(`configurar${this.restaurante.id}`).classList.add('rollIn');
      document.getElementById(`editar${this.restaurante.id}`).classList.add('fadeInUp-2x');
      document.getElementById(`editar${this.restaurante.id}`).classList.remove('fadeOutDown-2x');
      document.getElementById(`configurar${this.restaurante.id}`).classList.remove('rollOut');
      document.getElementById(`eliminar${this.restaurante.id}`).classList.add('fadeInUp');
      document.getElementById(`eliminar${this.restaurante.id}`).classList.remove('fadeOutDown');
    } else {
      document.getElementById(`configurar${this.restaurante.id}`).classList.add('rollOut');
      document.getElementById(`configurar${this.restaurante.id}`).classList.remove('rollIn');
      document.getElementById(`editar${this.restaurante.id}`).classList.add('fadeOutDown-2x');
      document.getElementById(`editar${this.restaurante.id}`).classList.remove('fadeInUp-2x');
      document.getElementById(`eliminar${this.restaurante.id}`).classList.add('fadeOutDown');
      document.getElementById(`eliminar${this.restaurante.id}`).classList.remove('fadeInUp');
    }
  }

  deleteRestaurant(): void {
    this.delete.emit(this.restaurante);
  }

  editRestaurant(): void {
    this.edit.emit(this.restaurante);
  }

}
