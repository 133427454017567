import { Usuario } from './usuario.model';


export class UnidadDeMedida {
    _id?: string;
    id?: number;
    nombre: string;
    abreviacion: string;
    cantidadDeDecimales: number;
    descripcion?: string;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor(){}
}