import { Area } from './area.model';
import { ElementoPorArea } from './elemento-por-area.model';
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';
import { Cupon } from './cupon.model';
import { Cliente } from './cliente.model';
import { CajaDeUsuario } from './caja-de-usuario.model';
import { Orden } from './orden.model';
import { FormaDePago } from '../enums/forma-de-pago.enum';
import { MetodoDePago } from '../enums/metodo-de-pago.enum';
import { Moneda } from '../enums/moneda.enum';
import { TipoPropina } from '../enums/tipo-propina.enum';
import { TipoDescuento } from '../enums/tipo-descuento.enum';
import { EstadoFacturacionCuenta } from '../enums/estado-facturacion-cuenta.enum';

export class DatosPropina {
    activo: boolean;
    tipo: string;
    porcentaje: number | string;
    valor: number | string;

    constructor() {
        this.activo = false;
        this.tipo = TipoPropina.Cantidad;
        this.porcentaje = 0;
        this.valor = '0.00';
    }
}

export class DatosCupon {
    noAplica: boolean;
    _idCupon: string;
    cupon: Cupon;
    nombre: string;
    tipo: string;
    porcentaje: number | string;
    valor: number | string;

    constructor() {
        this.noAplica = true;
    }
}

export class DatosDescuento {
    activo: boolean;
    tipo: string;
    porcentaje: number | string;
    valor: number | string;

    constructor() {
        this.activo = false;
        this.tipo = TipoDescuento.Cantidad;
        this.porcentaje = 0;
        this.valor = '0.00';
    }
}

export class DatosCliente {
    noAplica: boolean;
    _idCliente: string;
    cliente: Cliente;
    tipoDePersona: number;
    nombres: string;
    primerApellido: string;
    segundoApellido?: string;
    razonSocial: string;
    tieneRepresentante?: boolean;
    nombresRepresentante?: string;
    primerApellidoRepresentante?: string;
    segundoApellidoRepresentante?: string;
    rfc: string;

    constructor() {
        this.noAplica = true;
    }
}


export class Cuenta {
    _id?: string;
    id?: number;
    folio: number;
    cantidadProductosDiferentes: number;
    cantidadTotalProductos: number;
    ordenes: Orden[] | string[];
    datosPropina: DatosPropina;
    datosCupon: DatosCupon;
    datosDescuento: DatosDescuento;
    datosCliente: DatosCliente;
    subtotal: number;
    preciosIncluyenIva: boolean;
    porcentajeIva: number;
    iva: number;
    total: number;
    formaDePago: string;
    metodoDePago: string;
    moneda: string;
    cantidadPago: number | string;
    cantidadCambio: number;
    banco: string;
    numeroDeTarjeta: string;
    estadoDeFacturacion: number;
    _idCajaDeUsuario: string;
    cajaDeUsuario: CajaDeUsuario;
    _idElementoPorArea: string;
    elementoPorArea: ElementoPorArea;
    _idArea: string;
    area: Area;
    _idRestaurante: string;
    restaurante: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.formaDePago = FormaDePago.Efectivo;
        this.metodoDePago = MetodoDePago.PagoEnUnaSolaExhibicion;
        this.moneda = Moneda.PesoMexicano;
        this.ordenes = [];
        this.datosPropina = new DatosPropina();
        this.datosCupon = new DatosCupon();
        this.datosDescuento = new DatosDescuento();
        this.datosCliente = new DatosCliente();
        this.cantidadPago = '0.00';
        this.preciosIncluyenIva = true;
        this.estadoDeFacturacion = EstadoFacturacionCuenta.SinFacturar;
    }
}