import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { Paquete } from 'src/app/shared/class-models/paquete.model';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { EstadoDeGrupoDeOrdenesEnCocina } from 'src/app/shared/enums/estado-de-grupo-de-ordenes-en-cocina.enum';
import { GrupoOrdenes } from '../../../../shared/models/grupo-ordenes';

@Component({
  selector: 'app-kds-order',
  templateUrl: './k-d-s-order.component.html'
})
export class KDSOrderComponent implements OnInit, OnDestroy {

  @Input() grupoDeOrdenes: GrupoDeOrdenes;
  @Output() terminarOrdenEvento = new EventEmitter<{ _id: string, estadoEnCocina: number }>();
  productos: Producto[] = [];
  tamanos: Tamano[] = [];
  ingredientes: Ingrediente[] = [];
  paquetes: Paquete[] = [];
  disableBtn: boolean;

  constructor(
    private _ingredientesService: IngredientesService,
    private _productosService: ProductosService,
    private _paquetesService: PaquetesService,
    private _serTamanos: TamanosService) {
  }

  ngOnInit(): void {
    this.obtenerIngredientes();
    this.obtenerProductos();
    this.obtenerTamanos();
    this.obtenerPaquetes();
  }

  obtenerPaquetes() {
    this._paquetesService.obtenerPaquetes().subscribe(
      (paquetes: Paquete[]) => {
        this.paquetes = paquetes;
      },
      (err: HttpErrorResponse) => {
      }
    );
  }

  obtenerIngredientes() {
    this._ingredientesService.obtenerIngredientes().subscribe(
      (ingredientes: Ingrediente[]) => {
        this.ingredientes = ingredientes;
      },
      (err: HttpErrorResponse) => {
      }
    );
  }

  obtenerProductos() {
    this._productosService.obtenerProductos().subscribe(
      (productos: Producto[]) => {
        this.productos = productos;
      },
      (err: HttpErrorResponse) => {
      }
    );
  }

  obtenerTamanos() {
    this._serTamanos.obtenerTamanos().subscribe(
      (tamanos: Tamano[]) => {
        this.tamanos = tamanos;
      },
      (err: HttpErrorResponse) => {
      }
    );
  }

  obtenerNombreProducto(_idProducto: string): string {
    const producto = this.productos.find(productoBuscar => {
      return productoBuscar._id == _idProducto;
    })
    return producto.nombre;
  }

  obtenerNombreTamano(_idTamano: string) {
    const tamano = this.tamanos.find(tamanoBuscar => {
      return tamanoBuscar._id == _idTamano;
    })
    return tamano.nombre;
  }

  obtenerNombreIngrediente(_idIngrediente: string) {
    const ingrediente = this.ingredientes.find(ingredienteBuscar => {
      return ingredienteBuscar._id == _idIngrediente;
    })
    return ingrediente.nombre;
  }

  obtenerNombrePaquete(_idPaquete: string) {
    const paquete = this.paquetes.find(paqueteBuscar => {
      return paqueteBuscar._id == _idPaquete;
    })
    return paquete.nombre;
  }

  obtenerEstadoEnCocina(estadoEnCocina: number) {
    switch (estadoEnCocina) {
      case EstadoDeGrupoDeOrdenesEnCocina.Inicial: return 'Terminar';
    }
  }

  ngOnDestroy(): void {
  }

  terminarOrden(): void {
    this.disableBtn = true;
    this.terminarOrdenEvento.emit({ _id: this.grupoDeOrdenes._id, estadoEnCocina: this.grupoDeOrdenes.estadoEnCocina });
  }

}
