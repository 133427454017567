import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatButtonModule} from '@angular/material/button';
import {
  MatBadgeModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatStepperModule,
  MatProgressBarModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {ProductsComponent} from './products.component';
// Components.
import {ProductCardComponent} from './components/product-card/product-card.component';
// Modules.
import {EditProductComponent} from './modals/edit-product/edit-product.component';
// Pages.
import {NewProductComponent} from './pages/new-product/new-product.component';
// Routes.
import {ProductsRoutes} from './products.routes';
import {ScrollingModule} from '@angular/cdk/scrolling';

import {DirectivesModule} from 'src/app/core/directives/directives.module';

@NgModule({
  declarations: [
    EditProductComponent,
    ProductsComponent,
    NewProductComponent,
    ProductCardComponent
  ],
  imports: [
    RouterModule.forRoot(ProductsRoutes),
    BrowserModule,
    MatInputModule,
    FormsModule,
    ImageCropperModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatChipsModule,
    MatSelectModule,
    MatStepperModule,
    MatBadgeModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    SweetAlert2Module,
    ScrollingModule,
    DirectivesModule
  ],
  exports: [
    EditProductComponent,
    ProductsComponent,
    NewProductComponent,
    ProductCardComponent
  ]
})
export class ProductsModule {
}
