import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription, Subject } from 'rxjs';
import { ExistenciasPorTamanoService } from 'src/app/core/servicios/existencias-por-tamano.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { Orden, AgregarIngrediente } from 'src/app/shared/class-models/orden.model';
import { Producto, ProductoPorTamano } from 'src/app/shared/class-models/producto.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { ControlInternoExistenciaDeProductoPorTamano } from 'src/app/shared/class-models/control-interno-existencia-de-producto-por-tamano.enum';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { takeUntil } from 'rxjs/operators';
import { ExistenciaPorTamano } from 'src/app/shared/class-models/existencia-por-tamano.model';

@Component({
  selector: 'app-nueva-orden-producto-modal',
  templateUrl: './nueva-orden-producto-modal.component.html',
  styleUrls: ['./nueva-orden-producto-modal.component.scss']
})
export class NuevaOrdenProductoModalComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  titulo: string;
  RequestState = RequestState;
  ExpresionesRegulares = ExpresionesRegulares;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;
  producto: Producto;
  estadoPeticionObtenerExistenciasDeProductoPorTamano: number;
  obtenerExistenciasDeProductoPorTamanoSubscripcion: Subscription;
  ingredientesExtra: Ingrediente[];
  estadoPeticionObtenerIngredientesExtra: number;
  obtenerIngredientesExtraSubscripcion: Subscription;

  /* VARIABLES DEL STEP DE CANTIDAD Y TAMAÑO */
  @ViewChild('tamanoYCantidadOrdenForm') tamanoYCantidadOrdenForm: FormGroup;
  productoPorTamanoSeleccionado: ProductoPorTamano;

  /* VARIABLES DEL STEP DE QUITAR INGREDIENTES */
  estadoIngredientes: boolean[];

  /* VARIABLES DEL STEP DE INGREDIENTES EXTRA */
  estadoIngredientesExtra: boolean[];

  /* VARIABLES DEL STEP DE NOTAS */
  readonly codigosDeSeparadores: number[] = [ENTER, COMMA];

  /* VARIABLES PARA AGREGAR UNA ORDEN */
  orden: Orden = new Orden();

  /* VARIABLES CON SOCKETS PARA ACTUALIZAR LOS CAMBIOS EN LAS EXISTENCIAS  */
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private _existenciasPorTamanoService: ExistenciasPorTamanoService,
    private _ingredientesService: IngredientesService,
    private _ordenesService: OrdenesService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NuevaOrdenProductoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      _idRestaurante: string,
      producto: Producto,
      controlInternoExistenciasDeProductoPorTamano: ControlInternoExistenciaDeProductoPorTamano[]
    }
  ) {
    this.inicializarDatosOrden();
    this.titulo = 'Agregar orden del producto ' + this.data.producto.nombre;
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 2;
    this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.initial;
    this.estadoPeticionObtenerIngredientesExtra = RequestState.initial;
  }

  inicializarDatosOrden() {
    this.orden.tipo = TipoOrden.Producto;
    this.orden.datosProducto._idProducto = this.data.producto._id;
    this.orden.datosProducto.producto._id = this.data.producto._id;
    this.orden.datosProducto.producto.id = this.data.producto.id;
    this.orden.datosProducto.producto.nombre = this.data.producto.nombre;
  }

  ngOnInit(): void {
    this.disminucionExistenciasOrdenes();
    this.obtenerExistenciasDeProductoPorTamano();
    this.obtenerIngredientesExtra();
  }

  /* MÉTODO CON SOCKETS PARA ACTUALIZAR LOS CAMBIOS EN LAS EXISTENCIAS */
  disminucionExistenciasOrdenes() {
    this._ordenesService.disminucionExistenciasOrdenes()
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (existenciaPorTamano: ExistenciaPorTamano) => {
          if (
            this.data._idRestaurante == existenciaPorTamano._idRestaurante &&
            this.data.producto._id == existenciaPorTamano._idProducto &&
            this.administracionDeExistenciasHabilitado()
          ) {
            const posicionExistenciaPorTamano = this.obtenerPosicionExistenciaPorTamano(existenciaPorTamano._idTamano);
            if (posicionExistenciaPorTamano != -1) {
              this.producto.existenciasPorTamano[posicionExistenciaPorTamano].cantidad = existenciaPorTamano.cantidad;
              if (this.productoPorTamanoSeleccionado) {
                if (parseInt(this.orden.cantidad.toString()) > this.obtenerExistenciaProductoPorTamano(this.productoPorTamanoSeleccionado._idTamano)) {
                  this._alertasService.alertaErrorSinConfirmacion('Las existencias han disminuido','El tamaño seleccionado del producto ha bajado de existencias, favor de disminuir la cantidad seleccionada o cambiar del tamaño seleccionado');
                  this.tamanoYCantidadOrdenForm.controls['cantidad'].setErrors({ invalida: true });
                }
              }
            }
            /*if (this.obtenerExistenciaProductoPorTamano(existenciaPorTamano._idTamano) > 0) {//Falatría revisar que no se haga negativo
              this.producto.existenciasPorTamano[posicionExistenciaPorTamano].cantidad--;
              this.cambioProductoPorTamanoSeleccionado();
            } else {

            }*/
          }
        }
      );
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  obtenerExistenciasDeProductoPorTamano(): void {
    this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.loading;
    this.obtenerExistenciasDeProductoPorTamanoSubscripcion = this._existenciasPorTamanoService.obtenerExistenciasDeProductoPorTamano(this.data._idRestaurante, this.data.producto._id).subscribe(
      (producto: Producto) => {
        this.producto = producto;
        this.estadoIngredientes = (<Ingrediente[]>this.producto.ingredientes).map(ingrediente => false);
        this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerIngredientesExtra(): void {
    this.estadoPeticionObtenerIngredientesExtra = RequestState.loading;
    this.obtenerIngredientesExtraSubscripcion = this._ingredientesService.obtenerIngredientesExtra().subscribe(
      (ingredientesExtra: Ingrediente[]) => {
        this.ingredientesExtra = ingredientesExtra;
        this.estadoIngredientesExtra = this.ingredientesExtra.map(ingredienteExra => false);
        this.estadoPeticionObtenerIngredientesExtra = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngredientesExtra = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas();
      }
    );
  }

  /* MÉTODOS DEL STEP DE CANTIDAD Y TAMAÑO */
  administracionDeExistenciasHabilitado(): boolean {
    if (this.producto.existencia != null && this.producto.existencia.activo == true) {
      return true;
    } else {
      return false;
    }
  }

  obtenerPosicionExistenciaPorTamano(_idTamano: string): number {
    return this.producto.existenciasPorTamano.findIndex(existenciaPorTamano => {
      return existenciaPorTamano._idTamano == _idTamano;
    });
  }

  obtenerExistenciasUtilizadasPorTamano(_idTamano: string): number {
    const posicionControlInternoExistenciaDeProductoPorTamano = this.data.controlInternoExistenciasDeProductoPorTamano.findIndex(controlInternoExistenciaDeProductoPorTamano => {
      return controlInternoExistenciaDeProductoPorTamano._idTamano == _idTamano;
    });
    if (posicionControlInternoExistenciaDeProductoPorTamano != -1) {
      return this.data.controlInternoExistenciasDeProductoPorTamano[posicionControlInternoExistenciaDeProductoPorTamano].cantidad;
    } else {
      return 0;
    }
  }

  productoPorTamanoConExistencias(_idTamano: string): boolean {
    if (this.administracionDeExistenciasHabilitado()) {
      const posicionExistenciaPorTamano = this.obtenerPosicionExistenciaPorTamano(_idTamano);
      if (posicionExistenciaPorTamano != -1) {
        return (this.producto.existenciasPorTamano[posicionExistenciaPorTamano].cantidad - this.obtenerExistenciasUtilizadasPorTamano(_idTamano)) > 0;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  obtenerExistenciaProductoPorTamano(_idTamano: string): number {
    const posicionExistenciaPorTamano = this.obtenerPosicionExistenciaPorTamano(_idTamano);
    if (posicionExistenciaPorTamano != -1) {
      return this.producto.existenciasPorTamano[posicionExistenciaPorTamano].cantidad - this.obtenerExistenciasUtilizadasPorTamano(_idTamano);
    } else {
      return 0;
    }
  }

  cambioProductoPorTamanoSeleccionado(): void {
    this.orden.precioPorTamano.precio = <number>this.productoPorTamanoSeleccionado.precio;
    this.orden.precioPorTamano.tamano = this.productoPorTamanoSeleccionado.tamano;
    this.orden.precioPorTamano._idTamano = this.productoPorTamanoSeleccionado._idTamano;
    if (this.administracionDeExistenciasHabilitado() &&
      parseInt(this.orden.cantidad.toString()) > this.obtenerExistenciaProductoPorTamano(this.productoPorTamanoSeleccionado._idTamano)) {
      this.tamanoYCantidadOrdenForm.controls['cantidad'].setErrors({ invalida: true });
    }
  }

  transformarCantidad(cantidad: string): void {
    if (cantidad != null && cantidad != undefined && !isNaN(Number(cantidad))) {
      if (Number(cantidad) < 1) {
        this.orden.cantidad = 1;
      } else {
        if (this.administracionDeExistenciasHabilitado()) {
          const existenciaProductoPorTamano = this.obtenerExistenciaProductoPorTamano(this.productoPorTamanoSeleccionado._idTamano);
          if (Number(cantidad) >= existenciaProductoPorTamano) {
            this.orden.cantidad = existenciaProductoPorTamano;
          } else {
            this.orden.cantidad = parseInt(Number(cantidad).toFixed(0));
          }
        } else {
          this.orden.cantidad = parseInt(Number(cantidad).toFixed(0));
        }
      }
    } else {
      this.orden.cantidad = 1;
    }
  }

  aumentarCantidadOrden(): void {
    if (this.administracionDeExistenciasHabilitado()) {
      const existenciaProductoPorTamano = this.obtenerExistenciaProductoPorTamano(this.productoPorTamanoSeleccionado._idTamano)
      if (parseInt(this.orden.cantidad.toString()) >= existenciaProductoPorTamano) {
        this.orden.cantidad = existenciaProductoPorTamano;
      } else {
        this.orden.cantidad = parseInt(this.orden.cantidad.toString()) + 1;
      }
    } else {
      this.orden.cantidad = parseInt(this.orden.cantidad.toString()) + 1;
    }
  }

  desactivarAumentoCantidadOrden(): boolean {
    if (this.administracionDeExistenciasHabilitado() &&
      parseInt(this.orden.cantidad.toString()) >= this.obtenerExistenciaProductoPorTamano(this.productoPorTamanoSeleccionado._idTamano)
    ) {
      return true;
    } else {
      return false;
    }
  }

  disminuirCantidadOrden() {
    if (this.orden.cantidad > 1) {
      this.orden.cantidad = parseInt(this.orden.cantidad.toString()) - 1;
    } else {
      this.orden.cantidad = 1;
    }
  }

  desactivarDisminucionCantidadOrden() {
    if (this.orden.cantidad == null || this.orden.cantidad == undefined || isNaN(Number(this.orden.cantidad)) || parseInt(this.orden.cantidad.toString()) <= 1) {
      return true;
    } else {
      return false;
    }
  }

  /* MÉTODOS DEL STEP DE QUITAR INGREDIENTES */
  cambioEstadoIngrediente(posicionIngrediente: number, ingrediente: Ingrediente): void {
    if (this.estadoIngredientes[posicionIngrediente]) (<Ingrediente[]>this.orden.datosProducto.quitarIngredientes).push(ingrediente);
    else {
      const posicionIngredienteSeleccionado = (<Ingrediente[]>this.orden.datosProducto.quitarIngredientes).indexOf(ingrediente, 0);
      if (posicionIngredienteSeleccionado > -1) {
        this.orden.datosProducto.quitarIngredientes.splice(posicionIngredienteSeleccionado, 1);
      }
    }
  }

  /* MÉTODOS DEL STEP DE INGREDIENTES EXTRA */
  cambioEstadoIngredienteExtra(posicionIngredienteExtra: number, ingredienteExtra: Ingrediente): void {
    if (this.estadoIngredientesExtra[posicionIngredienteExtra]) {
      let agregarIngrediente: AgregarIngrediente = new AgregarIngrediente();
      agregarIngrediente.ingrediente = ingredienteExtra;
      agregarIngrediente._idIngrediente = ingredienteExtra._id;
      agregarIngrediente.precio = <number>ingredienteExtra.precio;
      this.orden.datosProducto.agregarIngredientes.push(agregarIngrediente);
    }
    else {
      const posicionIngredienteExtraSeleccionado = this.orden.datosProducto.agregarIngredientes.findIndex(agregarIngrediente => {
        return agregarIngrediente._idIngrediente == ingredienteExtra._id;
      });
      if (posicionIngredienteExtraSeleccionado > -1) {
        this.orden.datosProducto.agregarIngredientes.splice(posicionIngredienteExtraSeleccionado, 1);
      }
    }
  }

  /* MÉTODOS DEL STEP DE NOTAS */
  agregarNota(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (value != null && value != '') {
      this.orden.datosProducto.notas.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  eliminarNota(nota: string): void {
    const posicionNota = this.orden.datosProducto.notas.indexOf(nota);
    if (posicionNota != -1) {
      this.orden.datosProducto.notas.splice(posicionNota, 1);
    }
  }

  /* MÉTODOS PARA AGREGAR UNA ORDEN */
  agregarOrden(): void {
    this.modal.close(this.prepararDatosOrden());
  }

  prepararDatosOrden(): Orden {
    let orden: Orden = new Orden();
    orden.tipo = this.orden.tipo;
    orden.cantidad = parseInt(this.orden.cantidad.toString());
    orden.datosProducto._idProducto = this.orden.datosProducto._idProducto;
    orden.datosProducto.producto = this.orden.datosProducto.producto;
    orden.datosProducto.quitarIngredientes = (<Ingrediente[]>this.orden.datosProducto.quitarIngredientes).sort((ingrediente1, ingrediente2) => {
      if (ingrediente1._id > ingrediente2._id) return 1;
      if (ingrediente1._id < ingrediente2._id) return -1;
      return 0;
    });
    orden.datosProducto.agregarIngredientes = this.orden.datosProducto.agregarIngredientes.sort((agregarIngrediente1, agregarIngrediente2) => {
      if (agregarIngrediente1._idIngrediente > agregarIngrediente2._idIngrediente) return 1;
      if (agregarIngrediente1._idIngrediente < agregarIngrediente2._idIngrediente) return -1;
      return 0;
    });
    orden.datosProducto.notas = this.orden.datosProducto.notas.sort();
    orden.precioPorTamano = this.orden.precioPorTamano;
    orden.precioUnitario = this.obtenerPrecioUnitarioOrden();
    return orden;
  }

  obtenerPrecioUnitarioOrden(): number {
    let precioUnitario = this.orden.precioPorTamano.precio;
    this.orden.datosProducto.agregarIngredientes.forEach(agregarIngrediente => {
      precioUnitario += agregarIngrediente.precio;
    })
    return precioUnitario;
  }


  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (this.obtenerExistenciasDeProductoPorTamanoSubscripcion) this.obtenerExistenciasDeProductoPorTamanoSubscripcion.unsubscribe();
    if (this.obtenerIngredientesExtraSubscripcion) this.obtenerIngredientesExtraSubscripcion.unsubscribe();
  }
}
