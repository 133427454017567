import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Elemento } from 'src/app/shared/class-models/elemento.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { ElementosService } from 'src/app/core/servicios/elementos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AgregarElementoConfiguracionComponent } from './agregar-elemento-configuracion/agregar-elemento-configuracion.component';
import { EditarElementoConfiguracionComponent } from './editar-elemento-configuracion/editar-elemento-configuracion.component';
import { TipoArea } from 'src/app/shared/enums/tipo-area.enum';

@Component({
  selector: 'app-elementos-configuracion',
  templateUrl: './elementos-configuracion.component.html',
  styleUrls: ['./elementos-configuracion.component.scss']
})
export class ElementosConfiguracionComponent implements OnInit {
  @Output() peticionObtenerElementosFinalizada = new EventEmitter<boolean>();
  @Input() estadoPeticiones: number;
  @ViewChild('paginatorElementos', { read: MatPaginator, static: false }) paginatorElementos: MatPaginator;
  dataSource: MatTableDataSource<Elemento>;
  columnasTabla: string[] = ['numeroAsientos', 'opciones'];
  estadoPeticion: number;
  RequestState = RequestState;
  obtenerElementosSubscription: Subscription;
  eliminarElementoSubscription: Subscription;
  filtro: string = '';

  constructor(
    private _elementosService: ElementosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticion = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerElementos();
  }

  obtenerElementos(): void {
    this.estadoPeticion = RequestState.loading;
    this.obtenerElementosSubscription = this._elementosService.obtenerElementosPorTipoDeArea(TipoArea.Normal).subscribe(
      (elementos: Elemento[]) => {
        this.inicializarElementos(elementos);
        this.estadoPeticion = RequestState.success;
        this.peticionObtenerElementosFinalizada.emit(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticion = RequestState.error;
        this.peticionObtenerElementosFinalizada.emit(true);
      }
    );
  }

  inicializarElementos(elementos: Elemento[]): void {
    this.dataSource = new MatTableDataSource<Elemento>(elementos);
    this.dataSource.paginator = this.paginatorElementos;
    this.dataSource.filterPredicate = (elemento: Elemento, filtro: string) => {
      return elemento.numeroAsientos.toString().trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarElemento(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarElemento() {
    const dialogRef = this.dialog.open(AgregarElementoConfiguracionComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (elemento: Elemento) => {
        if (elemento != null) {
          this._alertasService.alertaExitoSinConfirmacion('Tipo de mesa agregado exitosamente', 'El tipo de mesa con ' + elemento.numeroAsientos + ' ' + (elemento.numeroAsientos == 1  ? 'asiento' : 'asientos') + ' ha sido agregado con éxito.');
          this.dataSource.data.push(elemento);
          const elementos: Elemento[] = this.dataSource.data;
          this.inicializarElementos(elementos);
          this.buscarElemento();
        }
      }
    );
  }

  actualizarElemento(elemento: Elemento) {
    const dialogRef = this.dialog.open(EditarElementoConfiguracionComponent, { disableClose: true, data: elemento });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Tipo de mesa actualizado exitosamente', 'El tipo de mesa con ' + elemento.numeroAsientos + ' ' + (elemento.numeroAsientos == 1  ? 'asiento' : 'asientos') + ' ha sido actualizado con éxito.');
          this.buscarElemento();
        }
      }
    );
  }

  eliminarElemento(elementoEliminar: Elemento) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el tipo de mesa con ' + elementoEliminar.numeroAsientos + ' ' + (elementoEliminar.numeroAsientos == 1  ? 'asiento' : 'asientos') + '?', '').then((result) => {
      if (result.value) {
        this.eliminarElementoSubscription = this._elementosService.eliminarElemento(elementoEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionElemento = this.dataSource.data.findIndex((elemento: Elemento) => {
              return elemento._id === elementoEliminar._id;
            });
            let elementosActualizados: Elemento[] = this.dataSource.data;
            if (posicionElemento != -1) {
              elementosActualizados.splice(posicionElemento, 1);
            }
            this.inicializarElementos(elementosActualizados);
            this.buscarElemento();
            this._alertasService.alertaExitoSinConfirmacion('Tipo de mesa eliminado exitosamente', 'El tipo de mesa con ' + elementoEliminar.numeroAsientos + ' ' + (elementoEliminar.numeroAsientos == 1  ? 'asiento' : 'asientos') + ' ha sido eliminado con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy() {
    if (this.obtenerElementosSubscription) this.obtenerElementosSubscription.unsubscribe();
    if (this.eliminarElementoSubscription) this.eliminarElementoSubscription.unsubscribe();
  }
}
