import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatProgressSpinnerModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {LicensesComponent} from './licenses.component';
import {LicensesRoutes} from './licenses.routes';
import {EditLicenseComponent} from './components/modals/edit-license/edit-license.component';
import {AddLicenseComponent} from './components/modals/add-license/add-license.component';
import {PipesModule} from '../../shared/pipes/pipes.module';
import { FolioPipe } from 'src/app/shared/pipes/folio.pipe';
import { FolioSinEspaciosPipe } from 'src/app/shared/pipes/folio-sin-espacios.pipe';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';


@NgModule({
  declarations: [LicensesComponent, EditLicenseComponent, AddLicenseComponent],
  providers: [
    FolioPipe,
    FolioSinEspaciosPipe
  ],
  imports: [
    RouterModule.forRoot(LicensesRoutes),
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    LicensesComponent,
    FechaPipe
  ]
})
export class LicensesModule {
}
