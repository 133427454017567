import { TipoCupon } from '../enums/tipo-cupon.enum';
import { Usuario } from './usuario.model';

export class Cupon {
    _id?: string;
    id?: number;
    nombre: string;
    tipo: string;
    descuentoCantidad?: number | string;
    descuentoPorcentaje?: number | string;
    fechaInicio?: string | Date;
    fechaFin?: string | Date;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.tipo = TipoCupon.Cantidad;
        this.descuentoCantidad = '0.00';
        this.descuentoPorcentaje = 0;
    }
}