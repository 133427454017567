import { Area } from './area.model';
import { ElementoPorArea } from './elemento-por-area.model';
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';
import { Comanda } from './comanda.model';

export class GrupoDeOrdenes {
    _id?: string;
    id?: number;
    contador: number;
    estadoEnCocina: number;
    _idComanda: string;
    comanda: Comanda;
    _idElementoPorArea: string;
    elementoPorArea: ElementoPorArea;
    _idArea: string;
    area: Area;
    _idRestaurante: string;
    restaurante: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {}
}