import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';

@Injectable({
    providedIn: 'root'
})
export class IngredientesService {
    private rutaBaseIngredientes = '/ingredientes';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerIngredientes(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseIngredientes}`, AppSettings.Options);
    }

    obtenerIngrediente(_idIngrediente: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseIngredientes}/${_idIngrediente}`, AppSettings.Options);
    }

    obtenerIngredientesExtra(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseIngredientes}/extra`, AppSettings.Options);
    }

    obtenerIngredientesSinInsumoRegistrado(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseIngredientes}/sin-insumo-registrado`, AppSettings.Options);
    }

    /* POST */
    agregarIngrediente(ingrediente: Ingrediente): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseIngredientes}`, JSON.stringify(ingrediente), AppSettings.Options);
    }

    /* PUT */
    actualizarIngrediente(_idIngrediente: string, ingrediente: Ingrediente): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseIngredientes}/${_idIngrediente}`, JSON.stringify(ingrediente), AppSettings.Options);
    }

    /* PATCH */
    eliminarIngrediente(_idIngrediente: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseIngredientes}/${_idIngrediente}`, AppSettings.Options);
    }
}
