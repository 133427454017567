import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministracionGastosRoutes } from './administracion-gastos.routes';
import { AdministracionGastosComponent } from './administracion-gastos.component';
import { RouterModule } from '@angular/router';
import { TiposDeGastosComponent } from './tipos-de-gastos/tipos-de-gastos.component';
import { GastosComponent } from './gastos/gastos.component';
import { AltaGastoComponent } from './gastos/alta-gasto/alta-gasto.component';
import { ActualizarGastoComponent } from './gastos/actualizar-gasto/actualizar-gasto.component';
import { AltaTipoDeGastoComponent } from './tipos-de-gastos/alta-tipo-de-gasto/alta-tipo-de-gasto.component';
import { ActualizarTipoDeGastoComponent } from './tipos-de-gastos/actualizar-tipo-de-gasto/actualizar-tipo-de-gasto.component';
import { MatTabsModule, MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatSelectModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatTableModule, MatCheckboxModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';


@NgModule({
  declarations: [
    AdministracionGastosComponent,
    TiposDeGastosComponent,
    GastosComponent,
    AltaGastoComponent,
    ActualizarGastoComponent,
    AltaTipoDeGastoComponent,
    ActualizarTipoDeGastoComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(AdministracionGastosRoutes),
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule
  ],
  providers: [
    PhonePipe,
    FechaPipe
  ],
  exports: []
})
export class AdministracionGastosModule { }
