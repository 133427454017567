import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable, Subscription } from 'rxjs';

import { RequestState } from '../../shared/enums/request-state.enum';
import { EditProductComponent } from './modals/edit-product/edit-product.component';
import { NewProductComponent } from './pages/new-product/new-product.component';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html'
})
export class ProductsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Producto>;
  filtro: string = '';
  RequestState = RequestState;
  estadoPeticionObtenerProductos: number;
  obtenerProductosSubscription: Subscription;
  observable: Observable<any>;
  eliminarProductoSubscription: Subscription;

  constructor(
    private _productosService: ProductosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerProductos = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProductos();
  }

  obtenerProductos(): void {
    this.estadoPeticionObtenerProductos = RequestState.loading;
    this.obtenerProductosSubscription = this._productosService.obtenerProductos().subscribe(
      (productos: Producto[]) => {
        this.inicializarProductos(productos);
        this.estadoPeticionObtenerProductos = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProductos = RequestState.error;
      }
    );
  }

  inicializarProductos(productos: Producto[]): void {
    this.dataSource = new MatTableDataSource<Producto>(productos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (producto: Producto, filtro: string) => {
      return producto.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
    this.observable = this.dataSource.connect();
  }

  buscarProducto(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarProducto(): void {
    const dialogRef = this.dialog.open(NewProductComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (producto: Producto) => {
        if (producto != null) {
          this._alertasService.alertaExitoSinConfirmacion('Producto agregado exitosamente', 'El producto ' + producto.nombre + ' ha sido agregado con éxito.');
          this.dataSource.data.push(producto);
          const productos: Producto[] = this.dataSource.data;
          this.inicializarProductos(productos);
          this.buscarProducto();
        }
      }
    );
  }

  actualizarProducto(producto: Producto): void {
    const dialogRef = this.dialog.open(EditProductComponent, { disableClose: true, data: producto });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Producto actualizado exitosamente', 'El producto ' + producto.nombre + ' ha sido actualizado con éxito.');
          this.buscarProducto();
        }
      }
    );
  }

  eliminarProducto(productoEliminar: Producto): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el producto ' + productoEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarProductoSubscription = this._productosService.eliminarProducto(productoEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionProducto = this.dataSource.data.findIndex((producto: Producto) => {
              return producto._id === productoEliminar._id;
            });
            let productosActualizados: Producto[] = this.dataSource.data;
            if (posicionProducto != -1) {
              productosActualizados.splice(posicionProducto, 1);
            }
            this.inicializarProductos(productosActualizados);
            this.buscarProducto();
            this._alertasService.alertaExitoSinConfirmacion('Producto eliminado exitosamente', 'El producto ' + productoEliminar.nombre + ' ha sido eliminado con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerProductosSubscription) this.obtenerProductosSubscription.unsubscribe();
    if (this.eliminarProductoSubscription) this.eliminarProductoSubscription.unsubscribe();
  }
}