import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { DiaDeLaSemana } from 'src/app/shared/enums/dia-de-la-semana.enum'
import { Subscription } from 'rxjs';
import { Paquete, PaquetePorTamano, AlternativaProductos, AlternativaProductosPorTamano } from 'src/app/shared/class-models/paquete.model';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as momento from 'moment';
import { FormGroup } from '@angular/forms';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { AgregarTamanoConfiguracionComponent } from 'src/app/modules/configuration/tamanos-configuracion/agregar-tamano-configuracion/agregar-tamano-configuracion.component';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { CANTIDAD_SECCIONES_MINIMA, CANTIDAD_SECCIONES_MAXIMA } from 'src/app/shared/constants/configuracion-paquetes.const';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-new-promo',
  templateUrl: './new-promo.component.html',
  styleUrls: ['./new-promo.component.scss']
})
export class NewPromoComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  titulo = 'Agregar paquete';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;

  /* VARIABLES DEL STEP DE INFORMACIÓN GENERAL */
  @ViewChild('informacionGeneralPaqueteForm') informacionGeneralPaqueteForm: FormGroup;
  DiaDeLaSemana = DiaDeLaSemana;
  diasDeLaSemana = [0, 1, 2, 3, 4, 5, 6];
  estadoDiasDeLaSemana: boolean[];
  todosLosDiasDeLaSemana: boolean;
  fechaInicioMinima: Date;
  eventoCambioImagenFotografia: any = '';

  /* VARIABLES DEL STEP DE TAMAÑOS */
  tamanos: Tamano[];
  estadoTamanos: boolean[];
  estadoPeticionObtenerTamanos: number;
  obtenerTamanosSubscription: Subscription;

  /* VARIABLES DEL STEP DE SECCIONES */
  cantidadSeccionesMinima = CANTIDAD_SECCIONES_MINIMA;
  cantidadSeccionesMaxima = CANTIDAD_SECCIONES_MAXIMA;

  /* VARIABLES DEL STEP DE ALTERNATIVAS DE PRODUCTOS */
  productos: Producto[];
  estadoAlternativasProducto: boolean[][];
  estadoPeticionObtenerProductosDetallados: number;
  obtenerProductosDetalladosSubscription: Subscription;

  /* VARIABLES DEL STEP DE PRECIOS */
  sugerenciasPreciosPaquetePorTamano: {precioMinimo: number | string, precioMaximo: number | string}[];

  /* VARIABLES PARA AGREGAR EL PRODUCTO */
  estadoPeticionAgregarPaquete: number;
  agregarPaqueteSubscription: Subscription;
  paquete: Paquete = new Paquete();

  constructor(
    private _paquetesService: PaquetesService,
    private _tamanosService: TamanosService,
    private _productosService: ProductosService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NewPromoComponent>,
    public dialog: MatDialog
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 2;
    this.estadoPeticionAgregarPaquete = RequestState.initial;
    this.estadoPeticionObtenerTamanos = RequestState.initial;
    this.estadoPeticionObtenerProductosDetallados = RequestState.initial;
    /* INICIALIZACIÓN DE VARIABLES DEL STEP DE INFORMACIÓN GENERAL */
    this.fechaInicioMinima = new Date();
    this.todosLosDiasDeLaSemana = false;
    this.cambioTodosLosDiasDeLaSemanaSeleccionados();
    /* INICIALIZACIÓN DE VARIABLES DEL STEP DE ALTERNATIVAS DE PRODUCTOS */
    this.estadoAlternativasProducto = [];
    /* INICIALIZACIÓN DE VARIABLES DEL STEP DE PRECIOS */
    this.sugerenciasPreciosPaquetePorTamano = [];
  }

  ngOnInit(): void {
    this.obtenerTamanos();
    this.obtenerProductosDetallados();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerTamanos(): void {
    this.estadoPeticionObtenerTamanos = RequestState.loading;
    this.obtenerTamanosSubscription = this._tamanosService.obtenerTamanos().subscribe(
      (tamanos: Tamano[]) => {
        this.estadoTamanos = tamanos.map(tamano => { return false; });
        this.tamanos = tamanos;
        this.estadoPeticionObtenerTamanos = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerTamanos = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas()
        this.modal.close();
      }
    );
  }

  obtenerProductosDetallados(): void {
    this.estadoPeticionObtenerProductosDetallados = RequestState.loading;
    this.obtenerProductosDetalladosSubscription = this._productosService.obtenerProductosDetallados().subscribe(
      (productos: Producto[]) => {
        this.productos = productos;
        this.estadoPeticionObtenerProductosDetallados = RequestState.success;
        /* INICIALIZACIÓN DE VARIABLES DEL STEP DE SECCIONES */
        this.agregarSeccion();
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProductosDetallados = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas()
        this.modal.close();
      }
    );
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  /* MÉTODOS DEL STEP DE INFORMACIÓN GENERAL */
  obtenerDiaDeLaSemana(diaDeLaSemana: number): string {
    switch (diaDeLaSemana) {
      case DiaDeLaSemana.Domingo: return 'Domingo';
      case DiaDeLaSemana.Lunes: return 'Lunes';
      case DiaDeLaSemana.Martes: return 'Martes';
      case DiaDeLaSemana.Miercoles: return 'Miércoles';
      case DiaDeLaSemana.Jueves: return 'Jueves';
      case DiaDeLaSemana.Viernes: return 'Viernes';
      case DiaDeLaSemana.Sabado: return 'Sabado';
    }
  }

  cambioTodosLosDiasDeLaSemanaSeleccionados(): void {
    if (this.todosLosDiasDeLaSemana) {
      this.estadoDiasDeLaSemana = Array(7).fill(true);
      this.paquete.diasDeLaSemana = this.diasDeLaSemana;
    } else {
      this.estadoDiasDeLaSemana = Array(7).fill(false);
      this.paquete.diasDeLaSemana = [];
    }
  }

  cambioDiaDeLaSemanaSeleccionado(posicionDiaDeLaSemana: number, diaDeLaSemana): void {
    if (this.estadoDiasDeLaSemana[posicionDiaDeLaSemana] == true) {
      this.paquete.diasDeLaSemana.push(diaDeLaSemana);
    } else {
      this.paquete.diasDeLaSemana = this.paquete.diasDeLaSemana.filter(diaDeLaSemanaFiltro => diaDeLaSemanaFiltro !== diaDeLaSemana);
    }
    this.verificarTodosLosDiasDeLaSemanaSeleccionados();
  }

  verificarTodosLosDiasDeLaSemanaSeleccionados(): void {
    let todosLosDiasDeLaSemanaSeleccionados: boolean = true;
    this.estadoDiasDeLaSemana.forEach(diaDeLaSemana => {
      if (diaDeLaSemana == false) todosLosDiasDeLaSemanaSeleccionados = false;
    })
    this.todosLosDiasDeLaSemana = todosLosDiasDeLaSemanaSeleccionados;
  }

  validarHorario(): void {
    if (this.paquete.horaInicio && this.paquete.horaFin) {
      let cmpHoraInicio = momento({ h: Number(this.paquete.horaInicio.split(':')[0]), m: Number(this.paquete.horaInicio.split(':')[1]) })
      let cmpHoraFin = momento({ h: Number(this.paquete.horaFin.split(':')[0]), m: Number(this.paquete.horaFin.split(':')[1]) });
      if (cmpHoraFin.isBefore(cmpHoraInicio)) {
        this.informacionGeneralPaqueteForm.controls['horaFin'].setErrors({ invalida: true });
      } else {
        this.informacionGeneralPaqueteForm.controls['horaFin'].setErrors(null);
      }
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(evento: ImageCroppedEvent): void {
    this.paquete.foto = evento.base64;
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
  //this.imagenFotoRecortada = '';
  //this.eventoCambioImagenFotografia = '';
  //}

  /* MÉTODOS DEL STEP DE TAMAÑOS */
  cambioEstadoTamano(posicionTamano: number, tamano: Tamano): void {
    if (this.estadoTamanos[posicionTamano]) {
      let paquetePorTamano = new PaquetePorTamano();
      paquetePorTamano.tamano = tamano;
      paquetePorTamano._idTamano = tamano._id;
      this.paquete.paquetePorTamano.push(paquetePorTamano);
      this.paquete.alternativasProductos.forEach((seccion, posicionSeccion) => {
        this.paquete.paquetePorTamano[this.paquete.paquetePorTamano.length - 1].alternativasProductosPorTamano.push([]);
        this.paquete.alternativasProductos[posicionSeccion].productos.forEach(producto => {
          let alternativaProductosPorTamano = new AlternativaProductosPorTamano();
          alternativaProductosPorTamano.producto = producto;
          alternativaProductosPorTamano._idProducto = producto._id;
          this.paquete.paquetePorTamano[this.paquete.paquetePorTamano.length - 1].alternativasProductosPorTamano[posicionSeccion].push(alternativaProductosPorTamano);
        });
      });
      /*this.estadoAlternativasProducto.push(
        this.productos.map(producto => { return false; })
      );*/
      this.sugerenciasPreciosPaquetePorTamano.push({precioMinimo: 0, precioMaximo: 0});
    }
    else {
      this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea eliminar el tamaño ' + tamano.nombre + '?', 'Toda la configuración definida en este tamaño será eliminada también.').then((result) => {
        if (result.value) {
          const posicionPaquetePorTamanoSeleccionado = this.paquete.paquetePorTamano.findIndex(paquetePorTamano => {
            return paquetePorTamano._idTamano == tamano._id;
          });
          if (posicionPaquetePorTamanoSeleccionado > -1) {
            this.paquete.paquetePorTamano.splice(posicionPaquetePorTamanoSeleccionado, 1);
            this.sugerenciasPreciosPaquetePorTamano.splice(posicionPaquetePorTamanoSeleccionado, 1);
          }
        } else {
          this.estadoTamanos[posicionTamano] = true;
        }
      })
    }
  }

  agregarTamano(): void {
    const modal = this.dialog.open(AgregarTamanoConfiguracionComponent, { disableClose: true });
    modal.afterClosed().subscribe(
      (tamano: Tamano) => {
        if (tamano != null) {
          this._alertasService.alertaExitoSinConfirmacion('Tamaño agregado exitosamente', 'El tamaño ' + tamano.nombre + ' ha sido agregado con éxito.');
          this.tamanos.push(tamano);
          this.estadoTamanos.push(false);
        }
      }
    );
  }

  /* MÉTODOS DEL STEP DE SECCIONES */
  agregarSeccion(): void {
    if (this.paquete.alternativasProductos.length < this.cantidadSeccionesMaxima) {
      this.paquete.alternativasProductos.push(new AlternativaProductos);
      this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
        paquetePorTamano.alternativasProductosPorTamano.push([]);
      });
      this.estadoAlternativasProducto.push(
        this.productos.map(producto => { return false; })
      );
    }
  }

  eliminarSeccion(posicion: number): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea eliminar la sección?', 'Todos los productos definidos en esta sección serán eliminados también.').then((result) => {
      if (result.value) {
        if (this.paquete.alternativasProductos.length > this.cantidadSeccionesMinima) {
          this.paquete.alternativasProductos.splice(posicion, 1);
          this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
            paquetePorTamano.alternativasProductosPorTamano.splice(posicion, 1);
          });
          this.estadoAlternativasProducto.splice(posicion, 1);
        }
      }
    })
  }

  /* MÉTODOS DEL STEP DE ALTERNATIVAS DE PRODUCTOS */
  cambioEstadoAlternativaProducto(posicionSeccion: number, posicionProducto: number): void {
    if (this.estadoAlternativasProducto[posicionSeccion][posicionProducto]) {
      (<Producto[]>this.paquete.alternativasProductos[posicionSeccion].productos).push(this.productos[posicionProducto]);
      this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
        let alternativaProductosPorTamano = new AlternativaProductosPorTamano();
        alternativaProductosPorTamano.producto = this.productos[posicionProducto];
        alternativaProductosPorTamano._idProducto = this.productos[posicionProducto]._id;
        paquetePorTamano.alternativasProductosPorTamano[posicionSeccion].push(alternativaProductosPorTamano);
      });
    }
    else {
      this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea eliminar el producto ' + this.productos[posicionProducto].nombre + ' de la sección ' + this.paquete.alternativasProductos[posicionSeccion].descripcion + '?', 'Los la configuración de tamaños definida para este producto en esa sección será eliminada también.').then((result) => {
        if (result.value) {
          const posicionAlternativaProductoSeleccionada = (<Producto[]>this.paquete.alternativasProductos[posicionSeccion].productos).indexOf(this.productos[posicionProducto], 0);
          if (posicionAlternativaProductoSeleccionada > -1) {
            (<Producto[]>this.paquete.alternativasProductos[posicionSeccion].productos).splice(posicionAlternativaProductoSeleccionada, 1);
          }
          this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
            const posicionAlternativaProductoPorTamanoSeleccionada = paquetePorTamano.alternativasProductosPorTamano[posicionSeccion].findIndex(alternativaProductoPorTamano => {
              return alternativaProductoPorTamano._idProducto == this.productos[posicionProducto]._id;
            })
            if (posicionAlternativaProductoPorTamanoSeleccionada > -1) {
              paquetePorTamano.alternativasProductosPorTamano[posicionSeccion].splice(posicionAlternativaProductoPorTamanoSeleccionada, 1)
            }
          });
        } else {
          this.estadoAlternativasProducto[posicionSeccion][posicionProducto] = true;
        }
      })
    }
  }

  alternativasProductosPorSeccionValidas(): boolean {
    let alternativasProductosPorSeccionValidas: boolean = true
    this.paquete.alternativasProductos.forEach(alternativaProductos => {
      if (alternativaProductos.productos.length == 0) alternativasProductosPorSeccionValidas = false;
    })
    return alternativasProductosPorSeccionValidas
  }

  /* MÉTODOS PARA EL STEP DE PRECIOS */
  generarSugerenciaPreciosPaquetePorTamano(): void {
    this.paquete.paquetePorTamano.forEach((paquetePorTamano, posicionPaquetePorTamano) => {
      this.sugerenciasPreciosPaquetePorTamano[posicionPaquetePorTamano].precioMinimo = this.calcularPrecioMinimoPaquetePorTamano(posicionPaquetePorTamano);
      this.sugerenciasPreciosPaquetePorTamano[posicionPaquetePorTamano].precioMaximo = this.calcularPrecioMaximoPaquetePorTamano(posicionPaquetePorTamano);
    });
  }

  calcularPrecioMinimoPaquetePorTamano(posicionPaquetePorTamano): number {
    let precioMinimoPaquetePorTamanoPorSeccion: number[] = [];
    this.paquete.paquetePorTamano[posicionPaquetePorTamano].alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
      let precioMinimoProductoPorSeccion: number = null;
      alternativaProductosPorTamano.forEach(alternativaProductoPorTamano => {
        let posicionProductoPorTamano = alternativaProductoPorTamano.producto.productoPorTamano.findIndex(productoPorTamano => {
          return alternativaProductoPorTamano._idTamano == productoPorTamano.tamano._id;
        });
        if (posicionProductoPorTamano > -1) {
          if (precioMinimoProductoPorSeccion == null || precioMinimoProductoPorSeccion > (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio)) {
            precioMinimoProductoPorSeccion = (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio);
          } 
        }
      });
      precioMinimoPaquetePorTamanoPorSeccion.push(precioMinimoProductoPorSeccion);
    })
    return precioMinimoPaquetePorTamanoPorSeccion.reduce((sumaPrecios, precioActual) => sumaPrecios + precioActual, 0);
  }

  calcularPrecioMaximoPaquetePorTamano(posicionPaquetePorTamano): number {
    let precioMinimoPaquetePorTamanoPorSeccion: number[] = [];
    this.paquete.paquetePorTamano[posicionPaquetePorTamano].alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
      let precioMinimoProductoPorSeccion: number = null;
      alternativaProductosPorTamano.forEach(alternativaProductoPorTamano => {
        let posicionProductoPorTamano = alternativaProductoPorTamano.producto.productoPorTamano.findIndex(productoPorTamano => {
          return alternativaProductoPorTamano._idTamano == productoPorTamano.tamano._id;
        });
        if (posicionProductoPorTamano > -1) {
          if (precioMinimoProductoPorSeccion == null || precioMinimoProductoPorSeccion < (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio)) {
            precioMinimoProductoPorSeccion = (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio);
          } 
        }
      });
      precioMinimoPaquetePorTamanoPorSeccion.push(precioMinimoProductoPorSeccion);
    })
    return precioMinimoPaquetePorTamanoPorSeccion.reduce((sumaPrecios, precioActual) => sumaPrecios + precioActual, 0);
  }

  transformarPrecio(posicionPaquetePorTamano: number, precio: string): void {
    if (precio != null && precio != undefined && !isNaN(Number(precio))) {
      this.paquete.paquetePorTamano[posicionPaquetePorTamano].precio = Number(precio).toFixed(2);
    } else {
      this.paquete.paquetePorTamano[posicionPaquetePorTamano].precio = Number('0').toFixed(2);
    }
  }

  /* MÉTODOS PARA AGREGAR EL PRODUCTO */
  agregarPaquete(): void {
    this.estadoPeticionAgregarPaquete = RequestState.loading;
    this.agregarPaqueteSubscription = this._paquetesService.agregarPaquete(this.prepararDatosPaquete()).subscribe(
      (paquete: Paquete) => {
        this.estadoPeticionAgregarPaquete = RequestState.success;
        this.modal.close(paquete);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarPaquete = RequestState.error;
      }
    );
  }

  prepararDatosPaquete(): Paquete {
    let paquete = new Paquete;
    paquete.nombre = this.paquete.nombre;
    paquete.descripcion = this.paquete.descripcion;
    paquete.esPermanente = this.paquete.esPermanente;
    if (paquete.esPermanente) {
      paquete.diasDeLaSemana = this.paquete.diasDeLaSemana.sort();
      paquete.fechaInicio = null;
      paquete.fechaFin = null;
    } else {
      paquete.diasDeLaSemana = null;
      paquete.fechaInicio = this.paquete.fechaInicio;
      paquete.fechaFin = this.paquete.fechaFin;
    }
    paquete.horarioValidez = this.paquete.horarioValidez;
    if (paquete.horarioValidez) {
      paquete.horaInicio = this.paquete.horaInicio;
      paquete.horaFin = this.paquete.horaFin;
    } else {
      paquete.horaInicio = null;
      paquete.horaFin = null;
    }
    paquete.foto = this.paquete.foto;
    this.paquete.alternativasProductos.forEach(alternativaProductos => {
      let copiaAlternativaProductos = new AlternativaProductos;
      copiaAlternativaProductos.descripcion = alternativaProductos.descripcion;
      copiaAlternativaProductos.productos = (<Producto[]>alternativaProductos.productos).map(producto => producto._id);
      paquete.alternativasProductos.push(copiaAlternativaProductos);
    })
    this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
      let copiaPaquetePorTamano = new PaquetePorTamano;
      copiaPaquetePorTamano._idTamano = paquetePorTamano._idTamano;
      copiaPaquetePorTamano.precio = parseFloat(paquetePorTamano.precio.toString());
      paquetePorTamano.alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
        let copiaAlternativaProductosPorTamano: AlternativaProductosPorTamano[] = [];
        alternativaProductosPorTamano.forEach(alternativaProductoPorTamano => {
          let copiaAlternativaProductoPorTamano = new AlternativaProductosPorTamano();
          copiaAlternativaProductoPorTamano._idProducto = alternativaProductoPorTamano._idProducto;
          copiaAlternativaProductoPorTamano._idTamano = alternativaProductoPorTamano._idTamano;
          copiaAlternativaProductosPorTamano.push(copiaAlternativaProductoPorTamano);
        })
        copiaPaquetePorTamano.alternativasProductosPorTamano.push(copiaAlternativaProductosPorTamano);
      })
      paquete.paquetePorTamano.push(copiaPaquetePorTamano);
    })
    return paquete;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerTamanosSubscription) this.obtenerTamanosSubscription.unsubscribe();
    if (this.obtenerProductosDetalladosSubscription) this.obtenerProductosDetalladosSubscription.unsubscribe();
    if (this.agregarPaqueteSubscription) this.agregarPaqueteSubscription.unsubscribe();
  }
}
