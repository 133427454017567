import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Comanda } from 'src/app/shared/class-models/comanda.model';
import { ComandasService } from 'src/app/core/servicios/comandas.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ElementosPorAreaService } from 'src/app/core/servicios/elementos-por-area.service';

@Component({
  selector: 'app-new-comanda',
  templateUrl: './new-comanda.component.html',
  styleUrls: []
})
export class NewComandaComponent implements OnInit, OnDestroy {
  titulo: string = 'Agregar comanda';
  ExpresionesRegulares = ExpresionesRegulares;
  comanda: Comanda = new Comanda();
  RequestState = RequestState;
  estadoPeticionAgregarComanda: number;
  agregarComandaSubscription: Subscription;
  estadoPeticionActualizarComandaElementoPorArea: number;
  actualizarComandaElementoPorAreaSubscription: Subscription;

  constructor(
    private _comandasService: ComandasService,
    private _elementosPorAreaService: ElementosPorAreaService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NewComandaComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { _idRestaurante: string, _idArea: string, elementoPorArea: ElementoPorArea },
    ) {
    this.estadoPeticionAgregarComanda = RequestState.initial;
  }

  ngOnInit(): void {}

  transformarNumeroDePersonas(numeroDePersonas: string): void {
    if (numeroDePersonas != null && numeroDePersonas != undefined && !isNaN(Number(numeroDePersonas))) {
      if (Number(numeroDePersonas) < 1) {
        this.comanda.numeroDePersonas = '1';
      } else {
        this.comanda.numeroDePersonas = Number(numeroDePersonas).toFixed(0);
      }
    } else {
      this.comanda.numeroDePersonas = null;
    }
  }

  async agregarComanda() {
    this.estadoPeticionAgregarComanda = RequestState.loading;
    this.agregarComandaSubscription = this._comandasService.agregarComanda(this.data._idRestaurante, this.data._idArea, this.data.elementoPorArea._id, this.prepararDatosComanda()).subscribe(
      (comanda: Comanda) => {
        this.estadoPeticionAgregarComanda = RequestState.success;
        this.actualizarComandaElementoPorArea(comanda);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarComanda = RequestState.error;
      }
    );
  }

  actualizarComandaElementoPorArea(comanda: Comanda): void {
    this.estadoPeticionActualizarComandaElementoPorArea = RequestState.loading;
    this.actualizarComandaElementoPorAreaSubscription = this._elementosPorAreaService.actualizarComandaElementoPorArea(this.data._idRestaurante, this.data._idArea, this.data.elementoPorArea._id, comanda._id).subscribe(
      (res: {titulo: string, detalles: string}) => {
        this.estadoPeticionActualizarComandaElementoPorArea = RequestState.success;
        this.actualizarElementoPorAreaOriginal(comanda);
        this.modal.close(comanda);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarComandaElementoPorArea = RequestState.error;
      }
    );
  }

  prepararDatosComanda(): Comanda {
    let comanda: Comanda = new Comanda();
    if (this.comanda.numeroDePersonas != null && this.comanda.numeroDePersonas != undefined && !isNaN(Number(this.comanda.numeroDePersonas))) {
      comanda.numeroDePersonas = parseInt(this.comanda.numeroDePersonas.toString());
    } else {
      comanda.numeroDePersonas = null;
    }
    comanda.observacion = this.comanda.observacion;
    return comanda;
  }

  actualizarElementoPorAreaOriginal(comanda: Comanda) {
    comanda.consumoTotal = 0;
    this.data.elementoPorArea._idComanda = comanda._id;
    this.data.elementoPorArea.comanda = comanda;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.agregarComandaSubscription) this.agregarComandaSubscription.unsubscribe();
    if(this.actualizarComandaElementoPorAreaSubscription) this.actualizarComandaElementoPorAreaSubscription.unsubscribe();
  }
}
