import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadCrumb} from '../../shared/models/bread-crumb';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {OrderService} from '../../core/services/order.service';
import {SizeService} from '../../core/services/size.service';
import {Orden} from '../../shared/models/orden';
import {Tamano} from '../../shared/models/tamano';

/* ÁREAS */
import { AreasService } from 'src/app/core/servicios/areas.service';
import { Area } from 'src/app/shared/class-models/area.model';

/* CLIENTES */
import { ClientesService } from 'src/app/core/servicios/clientes.service';
import { Cliente } from 'src/app/shared/class-models/cliente.model';

/* ELEMENTOS POR ÁREA */
import { ElementosPorAreaService } from 'src/app/core/servicios/elementos-por-area.service';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';

/* INGREDIENTES */
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';

/* PAQUETES */
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { Paquete } from 'src/app/shared/class-models/paquete.model';

/* PRODUCTOS */
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { Producto } from 'src/app/shared/class-models/producto.model';

/* PROVEEDORES */
import { ProveedoresService } from 'src/app/core/servicios/proveedores.service';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';

/* RESTAURANTES */
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';

/* USUARIOS */
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { Usuario } from 'src/app/shared/class-models/usuario.model';

/* USADO PARA CLIENTES Y PROVEEDORES */
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumb: BreadCrumb[];
  subscription: Subscription;
  tipoDeUsuario: number;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private _authService: AuthService,
              private _areasService: AreasService,
              private _clientesService: ClientesService,
              private _elementosPorArea: ElementosPorAreaService,
              private _ingredientesService: IngredientesService,
              private _paquetesService: PaquetesService,
              private _productosService: ProductosService,
              private _proveedoresService: ProveedoresService,
              private _restaurantesService: RestaurantesService,
              private _usuariosService: UsuariosService,
              private orderService: OrderService,
              private sizeService: SizeService) {
    this.tipoDeUsuario = this._authService.obtenerTipoUsuario();
    this.breadcrumb = [];
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map(event => this.buildBreadCrumb(this.activatedRoute.root))
    ).subscribe((res: any) => {
      this.breadcrumb = res;
    });
  }

  ngOnInit() {
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', posicion: number = 0,
                  breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    const path = route.routeConfig ? route.routeConfig.path : '';
    let label = '';
    let nextUrl = url;
    let breadcrumb: BreadCrumb = null;
    const newBreadcrumbs = breadcrumbs;
    if (path !== '') {
      label = route.routeConfig ? route.routeConfig.data.breadcrumb : '';
      nextUrl += '/' + route.snapshot.url[0].path;
      breadcrumb = {
        etiqueta: label,
        url: nextUrl,
        eslink: true,
        posicion
      };
    }
    if (breadcrumb !== null) {
      posicion = posicion + 1;
      if (route.snapshot.url[0].path !== 'mesa' && route.snapshot.url[0].path !== 'orden') {
        if (route.snapshot.url[0].path === 'restaurantes' && this.tipoDeUsuario > 1) {
          breadcrumb.eslink = false;
        }
        newBreadcrumbs.push(breadcrumb);
      }
      this.buildParamsBreadCrumb(nextUrl, route.snapshot.url[0].path, route.snapshot.params, posicion).subscribe(
        (res: BreadCrumb) => {
          if (res != null) {
            nextUrl = res.url;
            newBreadcrumbs.push(res);
            newBreadcrumbs.sort((obj1, obj2) => {
              if (obj1.posicion > obj2.posicion) {
                return 1;
              }
              if (obj1.posicion < obj2.posicion) {
                return -1;
              }
              return 0;
            });
          }
        }
      );
      nextUrl = this.getParamBreadCrumb(nextUrl, route.snapshot.url[0].path, route.snapshot.params);
    }
    if (route.firstChild) {
      posicion = posicion + 1;
      return this.buildBreadCrumb(route.firstChild, nextUrl, posicion, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  getParamBreadCrumb(url: string, path: string, params: any) {
    let newUrl = null;
    switch (path) {
      case 'areas':
        if (params.idArea != null) {
          newUrl = url + '/' + params.idArea;
        }
        break;
      case 'clientes':
        if (params.idCliente != null) {
          newUrl = url + '/' + params.idCliente;
        }
        break;
      case 'empleados':
        if (params.idEmpleado != null) {
          newUrl = url + '/' + params.idEmpleado;
        }
        break;
      case 'ingredientes':
        if (params.idIngrediente != null) {
          newUrl = url + '/' + params.idIngrediente;
        }
        break;
      case 'paquetes':
        if (params.idPaquete != null) {
          newUrl = url + '/' + params.idPaquete;
        }
        break;
      case 'productos':
        if (params.idProducto != null) {
          newUrl = url + '/' + params.idProducto;
        }
        break;
      case 'proveedores':
        if (params.idProveedor != null) {
          newUrl = url + '/' + params.idProveedor;
        }
        break;
      case 'restaurantes':
        if (params.idRestaurante != null) {
          newUrl = url + '/' + params.idRestaurante;
        }
        break;
      case 'mesa':
        if (params.idMesa != null) {
          newUrl = url + '/' + params.idMesa;
        }
        break;
      case 'orden':
        if (params.idComanda != null && params.tipo != null && params.idTipo != null && params.idTamano != null) {
          newUrl = url + '/' + params.idComanda + '/' + params.tipo + '/' + params.idTipo + '/' + params.idTamano;
        }
        break;
    }
    return newUrl;
  }

  buildParamsBreadCrumb(newUrl: string, path: string, params: any, posicion: number) {
    const breadcrumb = new BehaviorSubject<BreadCrumb>(null);
    switch (path) {
      case 'areas':
        if (params.idRestaurante != null && params.idArea != null) {
          this._areasService.obtenerArea(params.idRestaurante, params.idArea).subscribe(
            (area: Area) => {
              breadcrumb.next({
                etiqueta: area.nombre,
                url: newUrl + '/' + params.idArea,
                eslink: true,
                posicion
              });
            });
        }
        break;
      case 'clientes':
        if (params.idCliente != null) {
          this._clientesService.obtenerCliente(params.idCliente).subscribe(
            (cliente: Cliente) => {
              breadcrumb.next({
                etiqueta: this.obtenerRazonSocial(cliente),
                url: newUrl + '/' + params.idCliente,
                eslink: true,
                posicion
              });
            });
        }
        break;
      case 'empleados':
        if (params.idEmpleado != null) {
          this._usuariosService.obtenerUsuario(params.idEmpleado).subscribe(
            (empleado: Usuario) => {
              breadcrumb.next({
                etiqueta: empleado.nombres
                  + (empleado.primerApellido != null ? ' ' + empleado.primerApellido : '')
                  + (empleado.segundoApellido != null ? ' ' + empleado.segundoApellido : ''),
                url: newUrl + '/' + params.idEmpleado,
                eslink: true,
                posicion
              });
            });
        }
        break;
      case 'ingredientes':
        if (params.idIngrediente != null) {
          this._ingredientesService.obtenerIngrediente(params.idIngrediente).subscribe(
            (ingrediente: Ingrediente) => {
              breadcrumb.next({
                etiqueta: ingrediente.nombre,
                url: newUrl + '/' + params.idIngrediente,
                eslink: true,
                posicion
              });
            });
        }
        break;
      case 'paquetes':
        if (params.idPaquete != null) {
          this._paquetesService.obtenerPaquete(params.idPaquete).subscribe(
            (paquete: Paquete) => {
              breadcrumb.next({
                etiqueta: paquete.nombre,
                url: newUrl + '/' + params.idPaquete,
                eslink: true,
                posicion
              });
            });
        }
        break;
      case 'productos':
        if (params.idProducto != null) {
          this._productosService.obtenerProducto(params.idProducto).subscribe(
            (producto: Producto) => {
              breadcrumb.next({
                etiqueta: producto.nombre,
                url: newUrl + '/' + params.idProducto,
                eslink: true,
                posicion
              });
            });
        }
        break;
      case 'proveedores':
        if (params.idProveedor != null) {
          this._proveedoresService.obtenerProveedor(params.idProveedor).subscribe(
            (proveedor: Proveedor) => {
              breadcrumb.next({
                etiqueta: this.obtenerRazonSocial(proveedor),
                url: newUrl + '/' + params.idProveedor,
                eslink: true,
                posicion
              });
            });
        }
        break;
      case 'restaurantes':
        if (params.idRestaurante != null) {
          this._restaurantesService.obtenerRestaurante(params.idRestaurante).subscribe(
            (restaurante: Restaurante) => {
              breadcrumb.next({
                etiqueta: restaurante.nombre,
                url: newUrl + '/' + params.idRestaurante,
                eslink: this.tipoDeUsuario <= 2,
                posicion
              });
            });
        }
        break;
      case 'mesa':
        if (params.idMesa != null) {
          this._elementosPorArea.obtenerElementoPorArea(params.idRestaurante, params.idArea, params.idMesa).subscribe(
            (elementoPorArea: ElementoPorArea) => {
              breadcrumb.next({
                etiqueta: elementoPorArea.elemento.nombre + ' ' + elementoPorArea.nombre,
                eslink: true,
                url: newUrl + '/' + params.idMesa,
                posicion
              });
            });
        }
        break;
      case 'orden':
        if (params.idComanda != null && params.tipo != null && params.idTipo != null && params.idTamano != null) {
          this.orderService.obtenerOrdenesComandaEspecificas(
            params.idComanda,
            params.tipo,
            params.idTipo,
            params.idTamano
          ).subscribe(
            (orden: Orden[]) => {
              this.sizeService.obtenerTamano(params.idTamano).subscribe(
                (tamano: Tamano) => {
                  if (params.tipo === 'paquete') {
                    this._paquetesService.obtenerPaquete(params.idTipo).subscribe(
                      (paquete: Paquete) => {
                        breadcrumb.next({
                          etiqueta: paquete.nombre + ' (' + tamano.nombre + ')',
                          url: newUrl + '/' + params.idComanda + '/' + params.tipo + '/' + params.idTipo + '/' + params.idTamano,
                          eslink: true,
                          posicion
                        });
                      });
                  } else {
                    this._productosService.obtenerProducto(params.idTipo).subscribe(
                      (producto: Producto) => {
                        breadcrumb.next({
                          etiqueta: producto.nombre + ' (' + tamano.nombre + ')',
                          url: newUrl + '/' + params.idComanda + '/' + params.tipo + '/' + params.idTipo + '/' + params.idTamano,
                          eslink: true,
                          posicion
                        });
                      });
                  }
                });
            });
        }
        break;
    }
    return breadcrumb.asObservable();
  }

  obtenerRazonSocial(persona: Cliente | Proveedor) {
    switch(persona.tipoDePersona) {
      case TipoDePersona.Moral: return persona.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return persona.nombres + ' ' + persona.primerApellido + (persona.segundoApellido ? (' ' + persona.segundoApellido) : '');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
