import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';

@Injectable({
    providedIn: 'root'
})
export class ProductosDeCocinaService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseProductosEnCocina = '/productos-de-cocina';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerProductosDeCocina(_idRestaurante: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseProductosEnCocina}`, AppSettings.Options);
    }
    /* POST */
    agregarProductoOmitidoDeCocina(_idRestaurante: string, _idProducto: string): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseProductosEnCocina}`, JSON.stringify({_idProducto}), AppSettings.Options);
    }

    /* PUT */

    /* PATCH */
    actualizarEstadoProductoOmitidoDeCocina(_idRestaurante: string, _idProductoDeCocina: string, activo: boolean): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseProductosEnCocina}/${_idProductoDeCocina}`, JSON.stringify({activo}), AppSettings.Options);
    }
}