import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html'
})
export class InventoryComponent implements OnInit {

  links = [{name: 'Insumos', path: './insumos'}];
  activeLink: string;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.changeTab();
  }

  changeTab(): void {
    setTimeout(() => this.activeLink = this.activatedRoute.snapshot.children[0].data.breadcrumb, 500); // Mexicanada, perdón.
  }
}
