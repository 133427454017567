import {Routes} from '@angular/router';

import {PromosComponent} from './promos.component';
import {NewPromoComponent} from './pages/new-promo/new-promo.component';

export const PromosRoutes: Routes = [
  {
    path: '',
    component: PromosComponent
  },
  {
    path: 'nuevo',
    data: {
      breadcrumb: 'Nuevo'
    },
    component: NewPromoComponent
  }
];
