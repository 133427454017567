import { Component, OnInit } from '@angular/core';
import { Elemento } from 'src/app/shared/class-models/elemento.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { ElementosService } from 'src/app/core/servicios/elementos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef } from '@angular/material';
import { TipoArea } from 'src/app/shared/enums/tipo-area.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoDeElementoDeAreaNormal } from 'src/app/shared/enums/tipo-de-elemento.enum';

@Component({
  selector: 'app-agregar-elemento-configuracion',
  templateUrl: './agregar-elemento-configuracion.component.html',
  styleUrls: ['./agregar-elemento-configuracion.component.scss']
})
export class AgregarElementoConfiguracionComponent implements OnInit {
  titulo = 'Agregar tipo de mesa';
  elemento: Elemento = new Elemento();
  estadoPeticionAgregarElemento: number;
  RequestState = RequestState;
  agregarElementoSubscription: Subscription;
  
  constructor(
    private _elementosService: ElementosService,
    private _alertasService: AlertasService,
    public dialogRef: MatDialogRef<AgregarElementoConfiguracionComponent>
  ) {
    this.estadoPeticionAgregarElemento = RequestState.initial;
  }

  ngOnInit(): void {}

  transformarNumeroAsientos(numeroAsientos: string): void {
    if (numeroAsientos != null && numeroAsientos != undefined && !isNaN(Number(numeroAsientos))) {
      if (Number(numeroAsientos) < 1) {
        this.elemento.numeroAsientos = 1;
      } else {
        this.elemento.numeroAsientos = Number(numeroAsientos).toFixed(0);
      }
    } else {
      this.elemento.numeroAsientos = 1;
    }
  }

  agregarElemento(): void {
    this.estadoPeticionAgregarElemento = RequestState.loading;
    this.agregarElementoSubscription = this._elementosService.agregarElemento(this.prepararDatosElemento()).subscribe(
      (elemento: Elemento) => {
        this.estadoPeticionAgregarElemento = RequestState.success;
        this.dialogRef.close(elemento);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarElemento = RequestState.error;
      }
    );
  }

  prepararDatosElemento(): Elemento {
    let elemento: Elemento = new Elemento();
    elemento.numeroAsientos = parseInt(this.elemento.numeroAsientos.toString());
    elemento.tipoDeArea = TipoArea.Normal;
    elemento.tipo = TipoDeElementoDeAreaNormal.Mesa;
    return elemento;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.agregarElementoSubscription) this.agregarElementoSubscription.unsubscribe();
  }
}
