import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { TipoDeGastoGeneral } from 'src/app/shared/class-models/tipo-de-gasto-general.model';
import { Subscription } from 'rxjs';
import { TiposDeGastosGeneralesService } from 'src/app/core/servicios/tipos-de-gastos-generales.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RFC_GENERICO } from 'src/app/shared/constants/rfc-generico.constant';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-actualizar-tipo-de-gasto',
  templateUrl: './actualizar-tipo-de-gasto.component.html',
  styleUrls: ['./actualizar-tipo-de-gasto.component.scss']
})
export class ActualizarTipoDeGastoComponent implements OnInit, AfterViewChecked, OnDestroy {
  titulo: string = 'Actualizar tipo de gasto general';
  TipoDePersona = TipoDePersona;
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  tipoDeGastoGeneral: TipoDeGastoGeneral;
  estadoPeticionObtenerTipoDeGastoGeneral: number;
  obtenerTipoDeGastoGeneralSubscripcion: Subscription;
  estadoPeticionActualizarTipoDeGastoGeneral: number;
  actualizarTipoDeGastoGeneralSubscripcion: Subscription;

  constructor(
    private _tiposDeGastosGeneralesService: TiposDeGastosGeneralesService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<ActualizarTipoDeGastoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TipoDeGastoGeneral,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.estadoPeticionObtenerTipoDeGastoGeneral = RequestState.initial;
    this.estadoPeticionActualizarTipoDeGastoGeneral = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerTipoDeGastoGeneral();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  obtenerTipoDeGastoGeneral(): void {
    this.estadoPeticionObtenerTipoDeGastoGeneral = RequestState.loading;
    this.obtenerTipoDeGastoGeneralSubscripcion = this._tiposDeGastosGeneralesService.obtenerTipoDeGastoGeneral(this.data._id).subscribe(
      (tipoDeGastoGeneral: TipoDeGastoGeneral) => {
        this.tipoDeGastoGeneral = tipoDeGastoGeneral;
        this.estadoPeticionObtenerTipoDeGastoGeneral = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerTipoDeGastoGeneral = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerTipoDePersona(tipo: number): string {
    switch (tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  actualizacionTipoDePersona(): void {
    switch (this.tipoDeGastoGeneral.tipoDePersona) {
      case TipoDePersona.Moral:
      case TipoDePersona.Fisica:
        if (this.tipoDeGastoGeneral.rfc == RFC_GENERICO) this.tipoDeGastoGeneral.rfc = '';
        break;
      case TipoDePersona.Ninguna:
        this.tipoDeGastoGeneral.rfc = RFC_GENERICO;
        break;
    }
  }

  convertirRfcMayusculas(): void {
    if (this.tipoDeGastoGeneral.rfc) {
      this.tipoDeGastoGeneral.rfc = this.tipoDeGastoGeneral.rfc.toUpperCase();
    }
  }

  actualizarTipoDeGastoGeneral(): void {
    this.estadoPeticionActualizarTipoDeGastoGeneral = RequestState.loading;
    this.actualizarTipoDeGastoGeneralSubscripcion = this._tiposDeGastosGeneralesService.actualizarTipoDeGastoGeneral(this.data._id, this.prepararDatosTipoDeGastoGeneral()).subscribe(
      (tipoDeGastoGeneral: TipoDeGastoGeneral) => {
        this.estadoPeticionActualizarTipoDeGastoGeneral = RequestState.success;
        this.actualizarTipoDeGastoGeneralOriginal(tipoDeGastoGeneral);
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarTipoDeGastoGeneral = RequestState.error;
      }
    );
  }

  prepararDatosTipoDeGastoGeneral(): TipoDeGastoGeneral {
    let tipoDeGastoGeneral = new TipoDeGastoGeneral();
    tipoDeGastoGeneral.concepto = this.tipoDeGastoGeneral.concepto;
    tipoDeGastoGeneral.descripcion = this.tipoDeGastoGeneral.descripcion;
    tipoDeGastoGeneral.tieneReceptor = this.tipoDeGastoGeneral.tieneReceptor;
    if (tipoDeGastoGeneral.tieneReceptor) {
      tipoDeGastoGeneral.tipoDePersona = this.tipoDeGastoGeneral.tipoDePersona;
      tipoDeGastoGeneral.rfc = this.tipoDeGastoGeneral.rfc;
      tipoDeGastoGeneral.telefono = this.tipoDeGastoGeneral.telefono;
      tipoDeGastoGeneral.correo = this.tipoDeGastoGeneral.correo;
      switch (tipoDeGastoGeneral.tipoDePersona) {
        case TipoDePersona.Moral:
          tipoDeGastoGeneral.razonSocial = this.tipoDeGastoGeneral.razonSocial;
          tipoDeGastoGeneral.tieneRepresentante = this.tipoDeGastoGeneral.tieneRepresentante;
          if (tipoDeGastoGeneral.tieneRepresentante) {
            tipoDeGastoGeneral.nombresRepresentante = this.tipoDeGastoGeneral.nombresRepresentante;
            tipoDeGastoGeneral.primerApellidoRepresentante = this.tipoDeGastoGeneral.primerApellidoRepresentante;
            tipoDeGastoGeneral.segundoApellidoRepresentante = this.tipoDeGastoGeneral.segundoApellidoRepresentante;
          } else {
            tipoDeGastoGeneral.nombresRepresentante = null;
            tipoDeGastoGeneral.primerApellidoRepresentante = null;
            tipoDeGastoGeneral.segundoApellidoRepresentante = null;
          }
          break;
        case TipoDePersona.Fisica:
        case TipoDePersona.Ninguna:
          tipoDeGastoGeneral.nombres = this.tipoDeGastoGeneral.nombres;
          tipoDeGastoGeneral.primerApellido = this.tipoDeGastoGeneral.primerApellido;
          tipoDeGastoGeneral.segundoApellido = this.tipoDeGastoGeneral.segundoApellido;
          tipoDeGastoGeneral.tieneRepresentante = false;
          tipoDeGastoGeneral.nombresRepresentante = null;
          tipoDeGastoGeneral.primerApellidoRepresentante = null;
          tipoDeGastoGeneral.segundoApellidoRepresentante = null;
          break;
      }
    } else {
      tipoDeGastoGeneral.tipoDePersona = null;
      tipoDeGastoGeneral.rfc = null;
      tipoDeGastoGeneral.nombres = null;
      tipoDeGastoGeneral.primerApellido = null;
      tipoDeGastoGeneral.segundoApellido = null;
      tipoDeGastoGeneral.razonSocial = null;
      tipoDeGastoGeneral.tieneRepresentante = false;
      tipoDeGastoGeneral.nombresRepresentante = null;
      tipoDeGastoGeneral.primerApellidoRepresentante = null;
      tipoDeGastoGeneral.segundoApellidoRepresentante = null;
      tipoDeGastoGeneral.telefono = null;
      tipoDeGastoGeneral.correo = null;
    }
    return tipoDeGastoGeneral;
  }

  actualizarTipoDeGastoGeneralOriginal(tipoDeGastoGeneral: TipoDeGastoGeneral): void {
    this.data.concepto = tipoDeGastoGeneral.concepto;
    this.data.descripcion = tipoDeGastoGeneral.descripcion;
    this.data.tieneReceptor = tipoDeGastoGeneral.tieneReceptor;
    this.data.tipoDePersona = tipoDeGastoGeneral.tipoDePersona;
    this.data.rfc = tipoDeGastoGeneral.rfc;
    this.data.nombres = tipoDeGastoGeneral.nombres;
    this.data.primerApellido = tipoDeGastoGeneral.primerApellido;
    this.data.segundoApellido = tipoDeGastoGeneral.segundoApellido;
    this.data.razonSocial = tipoDeGastoGeneral.razonSocial;
    this.data.tieneRepresentante = tipoDeGastoGeneral.tieneRepresentante;
    this.data.nombresRepresentante = tipoDeGastoGeneral.nombresRepresentante;
    this.data.primerApellidoRepresentante = tipoDeGastoGeneral.primerApellidoRepresentante;
    this.data.segundoApellidoRepresentante = tipoDeGastoGeneral.segundoApellidoRepresentante;
    this.data.telefono = tipoDeGastoGeneral.telefono;
    this.data.correo = tipoDeGastoGeneral.correo;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.actualizarTipoDeGastoGeneralSubscripcion) this.actualizarTipoDeGastoGeneralSubscripcion.unsubscribe();
  }
}
