import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Categoria } from 'src/app/shared/class-models/categoria.model';
import { Subscription } from 'rxjs';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { CategoriasService } from 'src/app/core/servicios/categorias.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-editar-categoria-configuracion',
  templateUrl: './editar-categoria-configuracion.component.html',
  styleUrls: ['./editar-categoria-configuracion.component.scss']
})
export class EditarCategoriaConfiguracionComponent implements OnInit, OnDestroy {
  titulo = 'Actualizar categoría';
  RequestState = RequestState;
  categoria: Categoria;

  /* VARIABLES PARA OBTENER LA CATEGORIA */
  obtenerCategoriaSubscription: Subscription;
  estadoPeticionObtenerCategoria: number;

  /* VARIABLES PARA ACTUALIZAR LA CATEGORIA */
  ExpresionesRegulares = ExpresionesRegulares;
  actualizarCategoriaSubscription: Subscription;
  estadoPeticionActualizarCategoria: number;

  constructor(
    private _categoriasService: CategoriasService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Categoria,
    public dialogRef: MatDialogRef<EditarCategoriaConfiguracionComponent>
    ) {
    this.estadoPeticionObtenerCategoria = RequestState.initial;
    this.estadoPeticionActualizarCategoria = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCategorias();
  }

  obtenerCategorias() {
    this.estadoPeticionObtenerCategoria = RequestState.loading;
    this.obtenerCategoriaSubscription = this._categoriasService.obtenerCategoria(this.data._id).subscribe(
      (categoria: Categoria) => {
        this.categoria = categoria;
        this.estadoPeticionObtenerCategoria = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCategoria = RequestState.error;
        this.dialogRef.close();
      }
    );
  }

  actualizarCategoria() {
    this.estadoPeticionActualizarCategoria = RequestState.loading;
    this.actualizarCategoriaSubscription = this._categoriasService.actualizarCategoria(this.data._id, this.categoria).subscribe(
      (categoria: Categoria) => {
        this.estadoPeticionActualizarCategoria = RequestState.success;
        this.actualizarCategoriaOriginal(categoria)
        this.dialogRef.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarCategoria = RequestState.error;
      }
    );
  }
  
  actualizarCategoriaOriginal(categoria: Categoria): void {
    this.data.nombre = categoria.nombre;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerCategoriaSubscription) this.obtenerCategoriaSubscription.unsubscribe();
    if(this.actualizarCategoriaSubscription) this.actualizarCategoriaSubscription.unsubscribe();
  }
}
