import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {Insumo} from '../../../../shared/models/insumo';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../shared/enums/request-state.enum';
import {AddStockItemComponent} from './components/add-stock-item/add-stock-item.component';
import {EditStockItemComponent} from './components/edit-stock-item/edit-stock-item.component';
import {StockService} from 'src/app/core/services/stock.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-stock-item',
  templateUrl: './stock.component.html'
})
export class StockComponent implements OnInit, OnDestroy {
  ngOnInit(): void {}

  ngOnDestroy(): void {}
/*
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  columns = ['Nombre', 'Existencia', 'Opciones'];
  dataSource: MatTableDataSource<Insumo>;
  noResults: boolean;
  requestState: number;
  subscription: Subscription;
  idRestaurante: string;
  @ViewChild('dialogAddSuccess', {static: true}) private dialogAddSuccess: SwalComponent;
  @ViewChild('dialogAddError', {static: true}) private dialogAddError: SwalComponent;
  @ViewChild('dialogEditSuccess', {static: true}) private dialogEditSuccess: SwalComponent;
  @ViewChild('dialogEditError', {static: true}) private dialogEditError: SwalComponent;
  @ViewChild('dialogDeleteSuccess', {static: true}) private dialogDeleteSuccess: SwalComponent;
  @ViewChild('dialogDeleteError', {static: true}) private dialogDeleteError: SwalComponent;

  constructor(private route: ActivatedRoute,
              private stockService: StockService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.requestState = RequestState.loading;
    this.idRestaurante = this.route.parent.parent.parent.snapshot.paramMap.get('idRestaurante');
    this.subscription = this.stockService.obtenerExistencias(this.idRestaurante).subscribe(
      (data: any) => {
        if (data.length === 0) {
          this.noResults = true;
        }
        this.requestState = RequestState.success;
        this.dataSource = new MatTableDataSource<any>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      () => this.requestState = RequestState.error
    );
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    this.subscription.unsubscribe();
  }

  addInput(): void {
    const dialogRef = this.dialog.open(AddStockItemComponent, {disableClose: true, data: {idRestaurante: this.idRestaurante}});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          const data = this.dataSource.data;
          data.push(result.form);
          this.dataSource.data = data;
          this.dialogAddSuccess.show();
        }
      }
    );
  }

  editInput(id: string): void {
    const dialogRef = this.dialog.open(EditStockItemComponent, {disableClose: true, data: {id, idRestaurante: this.idRestaurante}});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          this.ngOnInit();
          this.dialogEditSuccess.show();
        }
      }
    );
  }

  deleteInput(id: string): void {
    this.stockService.eliminarExistencia(id).subscribe(
      (res) => {
        this.subscription = this.stockService.obtenerExistencias(this.idRestaurante).subscribe(
          (data: Insumo[]) => {
            if (data.length === 0) {
              this.noResults = true;
            }
            this.requestState = RequestState.success;
            this.dataSource = new MatTableDataSource<Insumo>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          () => this.requestState = RequestState.error
        );
        if (res.status = 'OK') {
          this.dialogDeleteSuccess.show();
        }
      }
    );
  }*/
}

