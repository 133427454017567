import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { CuentasService } from 'src/app/core/servicios/cuentas.service';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-resumen-pago',
  templateUrl: './resumen-pago.component.html',
  styleUrls: ['./resumen-pago.component.scss']
})
export class ResumenPagoComponent implements OnInit, OnDestroy {
  titulo: string = 'Pagar cuenta';
  RequestState = RequestState;
  estadoPeticionAgregarCuenta: number;
  agregarCuentaSubscription: Subscription;
  
  constructor(
    public dialogRef: MatDialogRef<ResumenPagoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      _idRestaurante: string,
      _idArea: string,
      _idElementoPorArea: string,
      _idComanda: string,
      cuenta: Cuenta
    },
    private _cuentasService: CuentasService,
    private _alertasService: AlertasService,
  ) {
    this.estadoPeticionAgregarCuenta = RequestState.initial;
  }

  ngOnInit(): void {}

  agregarCuenta() {
    this.estadoPeticionAgregarCuenta = RequestState.loading;
    this.agregarCuentaSubscription = this._cuentasService.agregarCuenta(this.data._idRestaurante, this.data._idArea, this.data._idElementoPorArea, this.data._idComanda, this.data.cuenta).subscribe(
      (cuenta: Cuenta) => {
        this.estadoPeticionAgregarCuenta = RequestState.success;
        this.dialogRef.close(cuenta);
      },
      (err: HttpErrorResponse) => {
        this.estadoPeticionAgregarCuenta = RequestState.error;
        this._alertasService.alertaErrorSinConfirmacion(err.error.titulo, err.error.detalles);
      }
    );
  }

  cerrar(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.agregarCuentaSubscription) this.agregarCuentaSubscription.unsubscribe();
  }
}
