import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CambioContrasena } from 'src/app/shared/class-models/cambio-contrasena.model';
import { FormGroup } from '@angular/forms';
import { PerfilService } from 'src/app/core/servicios/perfil.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LongitudMinimaDeContrasena, LongitudMaximaDeContrasena } from 'src/app/shared/constants/longitud-de-contrasena.const';

@Component({
  selector: 'app-cambiar-contrasena-configuracion',
  templateUrl: './cambiar-contrasena-configuracion.component.html',
  styleUrls: ['./cambiar-contrasena-configuracion.component.scss']
})
export class CambiarContrasenaConfiguracionComponent implements OnInit {
  @ViewChild('actualizarContrasenaForm') actualizarContrasenaForm: FormGroup;
  titulo = 'Actualizar contraseña';
  RequestState = RequestState;
  estadoPeticionCambiarContrasena: number;
  cambiarContrasenaSubscripcion: Subscription;
  cambioContrasena: CambioContrasena = new CambioContrasena();
  LongitudMinimaDeContrasena = LongitudMinimaDeContrasena;
  LongitudMaximaDeContrasena = LongitudMaximaDeContrasena;
  
  constructor(
    private modal: MatDialogRef<CambiarContrasenaConfiguracionComponent>,
    private _perfilService: PerfilService,
    private _alertasService: AlertasService
    ) {
      this.estadoPeticionCambiarContrasena = RequestState.initial;
    }

  ngOnInit(): void {}

  verificarConfirmacionContrasenaNueva() {
    if (this.cambioContrasena.contrasenaNueva.length > 0 && this.cambioContrasena.confirmacionContrasenaNueva.length > 0 && 
      this.cambioContrasena.contrasenaNueva != this.cambioContrasena.confirmacionContrasenaNueva) {
      this.actualizarContrasenaForm.controls['confirmacionContrasenaNueva'].setErrors({ incorrecta: true });
    }
  }

  actualizarContrasena(): void {
    this.estadoPeticionCambiarContrasena = RequestState.loading;
    this.cambiarContrasenaSubscripcion = this._perfilService.actualizarContrasenaPerfil(this.cambioContrasena.contrasenaActual, this.cambioContrasena.contrasenaNueva).subscribe(
      (res) => {
        this.estadoPeticionCambiarContrasena = RequestState.success;
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionCambiarContrasena = RequestState.error;
      }
    );
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.cambiarContrasenaSubscripcion) this.cambiarContrasenaSubscripcion.unsubscribe();
  }
}
