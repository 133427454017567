import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  transform(fecha: Date) {
    return moment(fecha).locale('es').format('DD/MM/YYYY');// - hh:mm
  }

}
