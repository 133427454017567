import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppSettings} from '../../../../../../configs/app-settings.config';
import { Area } from 'src/app/shared/class-models/area.model';

@Component({
  selector: 'app-area-card',
  templateUrl: './area-card.component.html'
})
export class AreaCardComponent implements OnInit {
  @Input() _idRestaurante: string;
  @Input() area: Area;
  @Input() verTitulo: boolean;
  @Input() verVerMas: boolean;
  @Input() verConfiguracion: boolean;
  @Input() verActivo: boolean;

  AppSettings = AppSettings;

  @Output() edit = new EventEmitter<Area>();
  @Output() delete = new EventEmitter<Area>();

  constructor() {}

  ngOnInit() {}

  mostrarOpcionesConfiguracion() {
    if (!document.getElementById(`configurar${this.area.id}`).classList.contains('rollIn')) {
      document.getElementById(`configurar${this.area.id}`).classList.add('rollIn');
      document.getElementById(`configurar${this.area.id}`).classList.remove('rollOut');
      document.getElementById(`editar${this.area.id}`).classList.add('fadeInUp-2x');
      document.getElementById(`editar${this.area.id}`).classList.remove('fadeOutDown-2x');
      document.getElementById(`eliminar${this.area.id}`).classList.add('fadeInUp');
      document.getElementById(`eliminar${this.area.id}`).classList.remove('fadeOutDown');
    } else {
      document.getElementById(`configurar${this.area.id}`).classList.add('rollOut');
      document.getElementById(`configurar${this.area.id}`).classList.remove('rollIn');
      document.getElementById(`editar${this.area.id}`).classList.add('fadeOutDown-2x');
      document.getElementById(`editar${this.area.id}`).classList.remove('fadeInUp-2x');
      document.getElementById(`eliminar${this.area.id}`).classList.add('fadeOutDown');
      document.getElementById(`eliminar${this.area.id}`).classList.remove('fadeInUp');
    }
  }

  deleteArea(): void {
    this.delete.emit(this.area);
  }

  editArea(): void {
    this.edit.emit(this.area);
  }
}
