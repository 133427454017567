export const ExpresionesRegulares = [
    {
        //nombre de ingredientes, productos y paquetes
        id: 0,
        expresion: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9.!#$%&’*+/=?^_`{|}~-]|[(]|[)]|["]|[ ]|[-]|[,])*$',
        mensaje: 'Este campo admite letras, digitos y algunos caracteres especiales.'
    },
    {
        //Cantidades monetarias
        id: 1,
        expresion: '^([0-9]*)[.]?([0-9]*)$',
        mensaje: 'Este campo admite dígitos y un punto decimal.'
    },
    {
        //nombre de usuario
        id: 2,
        expresion: '^([a-zA-Z0-6]|[-]|[_]|[.])*$',
        mensaje: 'Este campo admite letras, dígitos, guion bajo, guion alto y punto.'
    },
    {
        //nombre, apellido paterno y apellido materno
        id: 3,
        expresion: '^([A-ZÑÁÉÍÓÚa-zñáéíóú]|[.]|[ ])*$',
        mensaje: 'Este campo admite letras, puntos y espacios.'
    },
    {
        //Telefono
        id: 4,
        expresion: '^[(]([0-9]){3}[)]\\s{1}([0-9]){3}[-]([0-9]){4}$',
        mensaje: 'Este campo admite 10 digitos.'
    },
    {
        //Codigo postal
        id: 5,
        expresion: '^[(]([0-9]){3}[)]\\s{1}([0-9]){3}[-]([0-9]){4}$',
        mensaje: 'Este campo admite 10 digitos.'
    },
    {
        //Cantidades enteras
        id: 6,
        expresion: '^[0-9]*$',
        mensaje: 'Este campo admite dígitos.'
    }, 
    {
        //Colonia y calle
        id: 7,
        expresion: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9.!#$%&’*+/=?^_`{|}~-]|[(]|[)]|["]|[ ]|[-]|[,]|[°]|[:]|[\'])*$',
        mensaje: 'Este campo admite letras, digitos y algunos caracteres especiales.'
    },
    {
        //Numero exterior y numero interior
        id: 8,
        expresion: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9.!#$%&’*+/=?^_`{|}~-]|[(]|[)]|["]|[ ]|[-]|[,]|[°]|[:]|[\'])*$',
        mensaje: 'Este campo admite letras, digitos y algunos caracteres especiales.'
    },
    {
        //correo electronico
        id: 9,
        expresion: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9.!#$%&’*+/=?^_`{|}~-]|[:])+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$',
        mensaje: 'Este campo solo admite formatos de correo validos.'
    },
    {
        //rfc persona moral
        id: 10,
        expresion: '^([A-Za-z&]){3}([0-9]){6}([A-Za-z0-9]){3}$',
        mensaje: 'Este campo admite 3 letras, seguido de 6 digitos seguido de 3 letras o digitos.'
    },
    {
        //rfc persona física
        id: 11,
        expresion: '^([A-Za-z&]){4}([0-9]){6}([A-Za-z0-9]){3}$',
        mensaje: 'Este campo admite 4 letras, seguido de 6 digitos seguido de 3 letras o digitos.'
    },
    {
        //razon social y empresa
        id: 12,
        expresion: '^([a-zñáéíóúA-ZÑÁÉÍÓÚ0-9.!#$%&’*+/=?^_`{|}~-]|[(]|[)]|["]|[ ]|[-]|[,])*$',
        mensaje: 'Este campo admite letras, digitos y algunos caracteres especiales.'
    },
    {
        //rfc persona moral o física
        id: 13,
        expresion: '^([A-Za-z&]){3,4}([0-9]){6}([A-Za-z0-9]){3}$',
        mensaje: 'Este campo admite 3 o 4 letras, seguido de 6 digitos seguido de 3 letras o digitos.'
    },
]