import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { ActivatedRoute } from '@angular/router';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ProductosDeCocinaService } from 'src/app/core/servicios/productos-de-cocina.service';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductoOmitidoDeCocina } from 'src/app/shared/class-models/producto-omitido-de-cocina.model';

@Component({
  selector: 'app-kds-conf',
  templateUrl: './kds-conf.component.html',
  styleUrls: []
})
export class KdsConfComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  columnas = ['producto', 'vaACocina', 'opciones'];
  dataSource: MatTableDataSource<Producto>;
  RequestState = RequestState;
  estadoPeticionObtenerProductosDeCocina: number;
  obtenerProductosDeCocinaSubscription: Subscription;
  _idRestaurante: string;
  filtro: string;

  /* VARIABLES PARA AGREGAR PRODUCTOS EN COCINA */
  agregarProductosDeCocinaExito: string[];
  agregarProductosDeCocinaFallo: string[];
  estadoPeticionAgregarProductosDeCocina: number;
  agregarProductoOmitidoDeCocinaSubscription: Subscription;

  /* VARIABLES PARA ELIMINAR PRODUCTOS EN COCINA */
  eliminarProductosDeCocinaExito: string[];
  eliminarProductosDeCocinaFallo: string[];
  estadoPeticionEliminarProductosDeCocina: number;

  /* VARIABLE PARA AGREGAR/ELIMINAR PRODUCTOS EN COCINA */
  actualizarEstadoProductoOmitidoDeCocinaSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _productosDeCocinaService: ProductosDeCocinaService,
    private _alertasService: AlertasService
    ) {
    this.estadoPeticionObtenerProductosDeCocina = RequestState.initial;
    this.estadoPeticionAgregarProductosDeCocina = RequestState.initial;
    this.estadoPeticionEliminarProductosDeCocina = RequestState.initial;
    this._idRestaurante = this.activatedRoute.parent.parent.snapshot.paramMap.get('idRestaurante');
  }

  ngOnInit() {
    this.obtenerProductosDeCocina();
  }

  obtenerProductosDeCocina(): void {
    this.estadoPeticionObtenerProductosDeCocina = RequestState.loading;
    this.obtenerProductosDeCocinaSubscription = this._productosDeCocinaService.obtenerProductosDeCocina(this._idRestaurante).subscribe(
      (productos: Producto[]) => {
        this.inicializarProductosDeCocina(productos);
        this.estadoPeticionObtenerProductosDeCocina = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProductosDeCocina = RequestState.error;
      }
    );
  }

  inicializarProductosDeCocina(productos: Producto[]): void {
    this.dataSource = new MatTableDataSource<Producto>(productos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (producto: Producto, filtro: string) => {
      return producto.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarProductoEnCocina(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  productoVaACocina(producto: Producto): boolean {
    if (producto.productoOmitidoDeCocina == null) return true;
    else return !producto.productoOmitidoDeCocina.activo;
  }

  totalProductosVanACocina(): number {
    let total = 0;
    this.dataSource.data.forEach(producto => {
      if (this.productoVaACocina(producto)) total++;
    })
    return total;
  }

  totalProductosOmitidosDeCocina(): number {
    let total = 0;
    this.dataSource.data.forEach(producto => {
      if (!this.productoVaACocina(producto)) total++;
    })
    return total;
  }

  /* MÉTODOS PARA AGREGAR PRODUCTOS EN COCINA */
  async agregarProductoDeCocina(producto: Producto) {
    try {
      await this.actualizarEstadoProductoOmitidoDeCocina(producto, false);
      this._alertasService.alertaExitoConConfirmacion('Producto agregado a cocina con éxito', 'El producto ' + producto.nombre + ' fue agregado a cocina exitosamente');
    } catch (error) {
      this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
    }
  }

  async agregarProductosDeCocina() {
    this.estadoPeticionAgregarProductosDeCocina = RequestState.loading;
    this.agregarProductosDeCocinaExito = [];
    this.agregarProductosDeCocinaFallo = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      try {
        if (this.productoVaACocina(this.dataSource.data[i]) == false) {
          await this.actualizarEstadoProductoOmitidoDeCocina(this.dataSource.data[i], false);
          this.agregarProductosDeCocinaExito.push(this.dataSource.data[i].nombre);
        }
      } catch (error) {
        this.agregarProductosDeCocinaFallo.push(this.dataSource.data[i].nombre);
      }
    }
    if (this.agregarProductosDeCocinaExito.length == 0 && this.agregarProductosDeCocinaFallo.length == 0) {
      this._alertasService.alertaInfoSinConfirmacion('No existe ningun producto pendiente de agregar a cocina', '');
    } else {
      if (this.agregarProductosDeCocinaFallo.length == 0) {
        this._alertasService.alertaExitoConConfirmacion(this.agregarProductosDeCocinaExito.length + ' ' + (this.agregarProductosDeCocinaExito.length == 1 ? 'producto fue agregado' : 'productos fueron agregados') + ' a cocina con éxito', '')
      } else {
        this._alertasService.alertaErrorConConfirmacion(this.agregarProductosDeCocinaFallo.length + ' ' + (this.agregarProductosDeCocinaFallo.length == 1 ? 'producto fallo al ser agregado' : 'productos fallaron al ser agregados') + ' a cocina',
          'Aunque ' + this.agregarProductosDeCocinaExito.length + ' ' + (this.agregarProductosDeCocinaExito.length == 1 ? 'producto fue agregado' : 'productos fueron agregados') + ' con éxito')
      }
    }
    this.estadoPeticionAgregarProductosDeCocina = RequestState.success;
  }

  /* MÉTODOS PARA ELIMINAR PRODUCTOS EN COCINA */
  async eliminarProductoDeCocina(producto: Producto) {
    try {
      if(producto.productoOmitidoDeCocina == null) {
        await this.agregarProductoOmitidoDeCocina(producto);
      } else {
        await this.actualizarEstadoProductoOmitidoDeCocina(producto, true);
      }
      this._alertasService.alertaExitoConConfirmacion('Producto eliminado de cocina con éxito', 'El producto ' + producto.nombre + ' fue eliminado de cocina exitosamente');
    } catch (error) {
      this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
    }
  }

  async eliminarProductosDeCocina() {
    this.estadoPeticionEliminarProductosDeCocina = RequestState.loading;
    this.eliminarProductosDeCocinaExito = [];
    this.eliminarProductosDeCocinaFallo = [];
    for (let i = 0; i < this.dataSource.data.length; i++) {
      try {
        if (this.productoVaACocina(this.dataSource.data[i]) == true) {
          if(this.dataSource.data[i].productoOmitidoDeCocina == null) {
            await this.agregarProductoOmitidoDeCocina(this.dataSource.data[i]);
          } else {
            await this.actualizarEstadoProductoOmitidoDeCocina(this.dataSource.data[i], true);
          }
          this.eliminarProductosDeCocinaExito.push(this.dataSource.data[i].nombre);
        }
      } catch (error) {
        this.eliminarProductosDeCocinaFallo.push(this.dataSource.data[i].nombre);
      }
    }
    if (this.eliminarProductosDeCocinaExito.length == 0 && this.eliminarProductosDeCocinaFallo.length == 0) {
      this._alertasService.alertaInfoSinConfirmacion('No existe ningun producto pendiente de eliminar de cocina', '');
    } else {
      if (this.eliminarProductosDeCocinaFallo.length == 0) {
        this._alertasService.alertaExitoConConfirmacion(this.eliminarProductosDeCocinaExito.length + ' ' + (this.eliminarProductosDeCocinaExito.length == 1 ? 'producto fue eliminado' : 'productos fueron eliminados') + ' de cocina con éxito', '')
      } else {
        this._alertasService.alertaErrorConConfirmacion(this.eliminarProductosDeCocinaFallo.length + ' ' + (this.eliminarProductosDeCocinaFallo.length == 1 ? 'producto fallo al ser eliminado' : 'productos fallaron al ser eliminados') + ' de cocina',
          'Aunque ' + this.eliminarProductosDeCocinaExito.length + ' ' + (this.eliminarProductosDeCocinaExito.length == 1 ? 'producto fue eliminado' : 'productos fueron eliminados') + ' con éxito.')
      }
    }
    this.estadoPeticionEliminarProductosDeCocina = RequestState.success;
  }

  async agregarProductoOmitidoDeCocina(producto: Producto): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.agregarProductoOmitidoDeCocinaSubscription = this._productosDeCocinaService.agregarProductoOmitidoDeCocina(this._idRestaurante, producto._id).subscribe(
        (productoOmitidoDeCocina: ProductoOmitidoDeCocina) => {
          producto.productoOmitidoDeCocina = productoOmitidoDeCocina;
          resolve(true);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  /* MÉTODO PARA AGREGAR/ELIMINAR PRODUCTOS EN COCINA */
  async actualizarEstadoProductoOmitidoDeCocina(producto: Producto, activo: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.actualizarEstadoProductoOmitidoDeCocinaSubscription = this._productosDeCocinaService.actualizarEstadoProductoOmitidoDeCocina(this._idRestaurante, producto.productoOmitidoDeCocina._id, activo).subscribe(
        (productoOmitidoDeCocina: ProductoOmitidoDeCocina) => {
          producto.productoOmitidoDeCocina.activo = productoOmitidoDeCocina.activo;
          resolve(true);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  ngOnDestroy(): void {
    if (this.agregarProductoOmitidoDeCocinaSubscription) this.agregarProductoOmitidoDeCocinaSubscription.unsubscribe();
    if (this.actualizarEstadoProductoOmitidoDeCocinaSubscription) this.actualizarEstadoProductoOmitidoDeCocinaSubscription.unsubscribe();
    if (this.obtenerProductosDeCocinaSubscription) this.obtenerProductosDeCocinaSubscription.unsubscribe();
  }
}
