import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';
import { Subscription } from 'rxjs';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { PerfilService } from 'src/app/core/servicios/perfil.service';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatStepper } from '@angular/material';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { ElementosPorAreaService } from 'src/app/core/servicios/elementos-por-area.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AbrirCajaComponent } from './abrir-caja/abrir-caja.component';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { TipoPropina } from 'src/app/shared/enums/tipo-propina.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { Cupon } from 'src/app/shared/class-models/cupon.model';
import { CuponesService } from 'src/app/core/servicios/cupones.service';
import { TipoDescuento } from 'src/app/shared/enums/tipo-descuento.enum';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { NewClientComponent } from 'src/app/modules/clients/modals/new-client/new-client.component';
import { Cliente } from 'src/app/shared/class-models/cliente.model';
import { ClientesService } from 'src/app/core/servicios/clientes.service';
import { FormaDePago } from 'src/app/shared/enums/forma-de-pago.enum';
import { MetodoDePago } from 'src/app/shared/enums/metodo-de-pago.enum';
import { Moneda } from 'src/app/shared/enums/moneda.enum';
import { ComandasService } from 'src/app/core/servicios/comandas.service';import { ResumenPagoComponent } from './resumen-pago/resumen-pago.component';
import { TipoImpresion } from 'src/app/shared/enums/tipo-impresion.enum';
import { ImpresionService } from 'src/app/core/servicios/impresion.service';
;

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})
export class PagoComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  tipoDeUsuario: number;
  _idRestaurante: string;
  _idArea: string;
  _idElementoPorArea: string;
  _idComanda: string;
  ExpresionesRegulares = ExpresionesRegulares;
  TipoOrden = TipoOrden;
  cantidadPeticionesRequeridas: number;
  contadorPeticionesFinalizadas: number;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER EL ELEMENTO POR AREA */
  elementoPorArea: ElementoPorArea;
  estadoPeticionObtenerElementoPorArea: number;
  obtenerElementoPorAreaSubscription: Subscription;

  /* MÉTODOS PARA OBTENER LAS ORDENES SIN PAGAR DE LA COMANDA */
  ordenes: Orden[];
  ordenesAgrupadas: Orden[];
  ordenesPorOrdenesAgrupadas: Orden[][];
  estadoPeticionObtenerOrdenesComandaSinPagar: number;
  obtenerOrdenesComandaSinPagarSubscription: Subscription;

  /* VARIABLES PARA OBTENER LA CAJA */
  cajaUsuarioActual: CajaDeUsuario;
  estadoPeticionObtenerCajaPerfil: number;
  obtenerCajaPerfilSubscription: Subscription;

  /* VARIABLES PARA OBTENER EL RESTAURANTE */
  restaurante: Restaurante;
  estadoPeticionObtenerRestaurante: number;
  obtenerRestauranteSubscription: Subscription;

  /* VARIABLES PARA OBTENER LOS CUPONES VIGENTES */
  cupones: Cupon[];
  estadoPeticionObtenerCuponesVigentes: number;
  obtenerCuponesVigentesSubscription: Subscription;

  /* VARIABLES PARA OBTENER LOS CLIENTES */
  clientes: Cliente[];
  estadoPeticionObtenerClientes: number;
  obtenerClientesSubscription: Subscription;

  /* VARIABLES PARA ELIMINAR UNA COMANDA */
  estadoPeticionEliminarComanda: number;
  eliminarComandaSubscription: Subscription;
  estadoPeticionActualizarComandaElementoPorArea: number;
  actualizarComandaElementoPorAreaSubscription: Subscription;

  /* VARIABLES PARA EL STEP DE ORDENES */
  todasLasOrdenesSeleccionadas: boolean;
  estadoOrdenesAgrupadas: boolean[];
  cantidadOrdenesAgrupadas: number[];
  cantidadesPorOrdenesAgrupadas: number[][];

  /* VARIABLES PARA EL STEP DE CUPÓN */
  TipoCupon = TipoCupon;

  /* VARIABLES PARA EL STEP DE DESCUENTO */
  TipoDescuento = TipoDescuento;

  /* VARIABLES PARA EL STEP DE PROPINA */
  TipoPropina = TipoPropina;

  /* VARIABLES PARA EL STEP DE CLIENTE */

  /* VARIABLES PARA EL STEP DE PAGO */
  Moneda = Moneda;
  FormaDePago = FormaDePago;
  MetodoDePago = MetodoDePago;

  /* VARIABLES PARA AGREGAR UNA CUENTA */
  @ViewChild('stepper') stepper: MatStepper;
  cuenta: Cuenta = new Cuenta();

  constructor(
    private _authService: AuthService,
    private _elementosPorAreaService: ElementosPorAreaService,
    private _ordenesService: OrdenesService,
    private _perfilService: PerfilService,
    private _restaurantesService: RestaurantesService,
    private _cuponesService: CuponesService,
    private _clientesService: ClientesService,
    private _comandasService: ComandasService,
    private _alertasService: AlertasService,
    private _impresionService: ImpresionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.tipoDeUsuario = this._authService.obtenerTipoUsuario();
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this._idArea = this.activatedRoute.parent.snapshot.paramMap.get('idArea');
    this._idElementoPorArea = this.activatedRoute.parent.snapshot.paramMap.get('idMesa');
    this.cantidadPeticionesRequeridas = 6;
    this.contadorPeticionesFinalizadas = 0;
    this.estadoPeticionObtenerElementoPorArea = RequestState.initial;
  }

  ngOnInit() {
    this.obtenerElementoPorArea();
    this.obtenerCajaPerfil();
    this.obtenerRestaurante();
    this.obtenerCuponesVigentes();
    this.obtenerClientes();
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
    if (this.contadorPeticionesFinalizadas == this.cantidadPeticionesRequeridas) {
      if (!this.cajaUsuarioActual) {
        this.abrirCaja();
      } else {
        if (this.cajaUsuarioActual._idRestaurante != this._idRestaurante) {
          this._alertasService.alertaErrorSinConfirmacion('Caja abierta en otro restaurante', 'Actualmente tienes una caja abierta pero es de otro restaurante, favor de cerrarla en la sección de "Inicio" antes de abrir una en este restaurante');
          this.redirigirElementoPorArea()
        }
      }
    }
  }

  redirigirElementoPorArea(): void {
    this.router.navigate(['/restaurantes/' + this._idRestaurante + '/areas/' + this._idArea + '/mesa/' + this._idElementoPorArea]);
  }

  /* MÉTODOS PARA OBTENER EL ELEMENTO POR AREA */
  obtenerElementoPorArea(): void {
    this.estadoPeticionObtenerElementoPorArea = RequestState.loading;
    this.obtenerElementoPorAreaSubscription = this._elementosPorAreaService.obtenerElementoPorArea(this._idRestaurante, this._idArea, this._idElementoPorArea).subscribe(
      (elementoPorArea: ElementoPorArea) => {
        this.elementoPorArea = elementoPorArea;
        this._idComanda = this.elementoPorArea._idComanda;
        this.estadoPeticionObtenerElementoPorArea = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
        this.obtenerOrdenesComandaSinPagar();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerElementoPorArea = RequestState.error;
        this.redirigirElementoPorArea();
      }
    );
  }

  /* MÉTODOS PARA OBTENER ORDENES DE LA COMANDA SIN PAGAR */
  obtenerOrdenesComandaSinPagar(): void {
    this.estadoPeticionObtenerOrdenesComandaSinPagar = RequestState.loading;
    this.obtenerOrdenesComandaSinPagarSubscription = this._ordenesService.obtenerOrdenesComandaSinPagar(this._idRestaurante, this._idArea, this._idElementoPorArea, this._idComanda).subscribe(
      (ordenes: Orden[]) => {
        this.ordenes = ordenes;
        this.ordenarOrdenes();
        this.inicializarOrdenesAgrupadas();
        this.estadoPeticionObtenerOrdenesComandaSinPagar = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerOrdenesComandaSinPagar = RequestState.error;
        this.redirigirElementoPorArea();
      }
    );
  }

  ordenarOrdenes(): void {
    this.ordenes = this.ordenes.sort((orden1, orden2) => {
      let nombreOrden1 = orden1.tipo == TipoOrden.Producto ? orden1.datosProducto.producto.nombre : orden1.datosPaquete.paquete.nombre;
      let nombreOrden2 = orden2.tipo == TipoOrden.Producto ? orden2.datosProducto.producto.nombre : orden2.datosPaquete.paquete.nombre;
      if (nombreOrden1 > nombreOrden2) return 1;
      if (nombreOrden1 < nombreOrden2) return -1;
      return 0;
    })
  }

  inicializarOrdenesAgrupadas(): void {
    //console.log('*************** O R D E N E S *********************')
    //console.log(this.ordenes);
    this.ordenesAgrupadas = [];
    this.ordenesPorOrdenesAgrupadas = [];
    this.estadoOrdenesAgrupadas = [];
    this.cantidadOrdenesAgrupadas = [];
    this.cantidadesPorOrdenesAgrupadas = [];
    this.ordenes.forEach(orden => {
      const posicionOrdenAgrupada = this.obtenerPosicionOrdenAgrupada(orden);
      if (posicionOrdenAgrupada != -1) {
        (<number>this.ordenesAgrupadas[posicionOrdenAgrupada].cantidad) += <number>orden.cantidad;
        this.ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada].push(orden);
      } else {
        this.ordenesAgrupadas.push(this.crearOrdenAgrupada(orden, <number>orden.cantidad));
        this.ordenesPorOrdenesAgrupadas.push([]);
        this.ordenesPorOrdenesAgrupadas[this.ordenesPorOrdenesAgrupadas.length - 1].push(orden);
      }
    })
    this.inicializarEstadoOrdenesAgrupadas();
    this.inicializarCantidadesOrdenesAgrupadas();
    //console.log('*************** O R D E N E S   A G R U P A D A S *********************')
    //console.log(this.ordenesAgrupadas);
    //console.log('*************** O R D E N E S   P O R   O R D E N E S   A G R U P A D A S *********************')
    //console.log(this.ordenesPorOrdenesAgrupadas);
  }

  obtenerPosicionOrdenAgrupada(orden: Orden): number {
    return this.ordenesAgrupadas.findIndex(ordenAgrupada => {
      if (ordenAgrupada.tipo == orden.tipo && ordenAgrupada.precioUnitario == orden.precioUnitario &&
        ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano &&
        ordenAgrupada.precioPorTamano.precio == orden.precioPorTamano.precio
      ) {
        switch (ordenAgrupada.tipo) {
          case TipoOrden.Producto:
            return this.sonOrdenesTipoProductoIdenticas(orden, ordenAgrupada);
          case TipoOrden.Paquete:
            return this.sonOrdenesTipoPaqueteIdenticas(orden, ordenAgrupada);
        }
      } else {
        return false;
      }
    })
  }

  sonOrdenesTipoProductoIdenticas(orden1: Orden, orden2: Orden): boolean {
    let sonIdenticas: boolean = true;
    if (orden1.datosProducto._idProducto == orden2.datosProducto._idProducto &&
      orden1.datosProducto.agregarIngredientes.length == orden2.datosProducto.agregarIngredientes.length) {
      //Se recorre el array de agregarIngredientes
      for (let posicionAgregarIngrediente = 0; posicionAgregarIngrediente < orden1.datosProducto.agregarIngredientes.length; posicionAgregarIngrediente++) {
        if (orden1.datosProducto.agregarIngredientes[posicionAgregarIngrediente]._idIngrediente != orden2.datosProducto.agregarIngredientes[posicionAgregarIngrediente]._idIngrediente ||
          orden1.datosProducto.agregarIngredientes[posicionAgregarIngrediente].precio != orden2.datosProducto.agregarIngredientes[posicionAgregarIngrediente].precio) {
          sonIdenticas = false;
          break;
        }
      }
    } else {
      sonIdenticas = false;
    }
    return sonIdenticas;
  }

  sonOrdenesTipoPaqueteIdenticas(orden1: Orden, orden2: Orden): boolean {
    let sonIdenticas: boolean = true;
    if (orden1.datosPaquete._idPaquete == orden2.datosPaquete._idPaquete &&
      orden1.datosPaquete.alternativasProductosPorTamano.length == orden2.datosPaquete.alternativasProductosPorTamano.length) {
      //Se recorre el array de alternativasProductosPorTamano
      for (let posicionAlternativa = 0; posicionAlternativa < orden1.datosPaquete.alternativasProductosPorTamano.length; posicionAlternativa++) {
        if (orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idProducto == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idProducto &&
          orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idTamano == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idTamano &&
          orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length) {
          //Se recorre el array de agregarIngredientes
          for (let posicionAgregarIngrediente = 0; posicionAgregarIngrediente < orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length; posicionAgregarIngrediente++) {
            if (orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente]._idIngrediente != orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente]._idIngrediente ||
              orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente].precio != orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente].precio) {
              sonIdenticas = false;
              break;
            }
          }
        } else {
          sonIdenticas = false;
        }
      }
    } else {
      sonIdenticas = false;
    }
    return sonIdenticas;
  }

  crearOrdenAgrupada(orden: Orden, cantidad: number): Orden {
    let ordenAgrupada = new Orden();
    ordenAgrupada.tipo = orden.tipo;
    ordenAgrupada.cantidad = cantidad;
    ordenAgrupada.precioUnitario = orden.precioUnitario;
    ordenAgrupada.precioPorTamano = orden.precioPorTamano;
    switch (ordenAgrupada.tipo) {
      case TipoOrden.Producto:
        ordenAgrupada.datosProducto = orden.datosProducto;
        break;
      case TipoOrden.Paquete:
        ordenAgrupada.datosPaquete = orden.datosPaquete;
        break;
    }
    return ordenAgrupada;
  }

  /* MÉTODOS PARA OBTENER LA CAJA */
  obtenerCajaPerfil(): void {
    this.estadoPeticionObtenerCajaPerfil = RequestState.loading;
    this.obtenerCajaPerfilSubscription = this._perfilService.obtenerCajaPerfil().subscribe(
      (cajaUsuario: CajaDeUsuario) => {
        this.cajaUsuarioActual = cajaUsuario;
        this.estadoPeticionObtenerCajaPerfil = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas()
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCajaPerfil = RequestState.error;
        this.redirigirElementoPorArea();
      }
    );
  }

  abrirCaja() {
    const referenciaModal = this.dialog.open(AbrirCajaComponent, {
      data: this._idRestaurante
    });
    referenciaModal.afterClosed().subscribe(caja => {
      if (caja) {
        this.cajaUsuarioActual = caja;
        this._alertasService.alertaExitoSinConfirmacion('Caja abierta', 'La caja ha sido abierta con éxito');
      }
    })
  }

  /* MÉTODOS PARA OBTENER EL RESTAURANTE */
  obtenerRestaurante(): void {
    this.estadoPeticionObtenerRestaurante = RequestState.loading;
    this.obtenerRestauranteSubscription = this._restaurantesService.obtenerRestaurante(this._idRestaurante).subscribe(
      (restaurante: Restaurante) => {
        this.restaurante = restaurante;
        this.cuenta.porcentajeIva = restaurante.porcentajeIva;
        this.estadoPeticionObtenerRestaurante = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas()
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerRestaurante = RequestState.error;
        this.redirigirElementoPorArea();
      }
    );
  }

  /* MÉTODOS PARA OBTENER LOS CUPONES VIGENTES */
  obtenerCuponesVigentes(): void {
    this.cupones = [];
    this.estadoPeticionObtenerCuponesVigentes = RequestState.loading;
    this.obtenerCuponesVigentesSubscription = this._cuponesService.obtenerCuponesVigentes().subscribe(
      (cupones: Cupon[]) => {
        this.cupones = cupones;
        this.estadoPeticionObtenerCuponesVigentes = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas()
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCuponesVigentes = RequestState.error;
      }
    );
  }

  /* MÉTODOS PARA OBTENER LOS CLIENTES */
  obtenerClientes(): void {
    this.clientes = [];
    this.estadoPeticionObtenerClientes = RequestState.loading;
    this.obtenerClientesSubscription = this._clientesService.obtenerClientes().subscribe(
      (clientes: Cliente[]) => {
        this.clientes = clientes;
        this.estadoPeticionObtenerClientes = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas()
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerClientes = RequestState.error;
      }
    );
  }

  /* MÉTODOS PARA ELIMINAR UNA COMANDA */
  eliminarComanda() {
    this.estadoPeticionEliminarComanda = RequestState.loading;
    this.eliminarComandaSubscription = this._comandasService.eliminarComanda(this._idRestaurante, this._idArea, this._idElementoPorArea, this._idComanda).subscribe(
      async (resultado: {titulo: string, detalles: string}) => {
        this.estadoPeticionEliminarComanda = RequestState.success;
        await this.actualizarComandaElementoPorArea();
        this._alertasService.alertaExitoSinConfirmacion(resultado.titulo, resultado.detalles);
        this.router.navigate(['/restaurantes/' + this._idRestaurante + '/areas/' + this._idArea]);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionEliminarComanda = RequestState.error;
      }
    );
  }
  
  actualizarComandaElementoPorArea(): Promise<boolean> {
    return new Promise((resolve) => {
      this.estadoPeticionActualizarComandaElementoPorArea = RequestState.loading;
      this.actualizarComandaElementoPorAreaSubscription = this._elementosPorAreaService.actualizarComandaElementoPorArea(this._idRestaurante, this._idArea, this.elementoPorArea._id, null).subscribe(
        (res: {titulo: string, detalles: string}) => {
          resolve(true);
          this.estadoPeticionActualizarComandaElementoPorArea = RequestState.success;
        },
        (error: HttpErrorResponse) => {
          resolve(false);
          this.estadoPeticionActualizarComandaElementoPorArea = RequestState.error;
        }
      );
    })
  }

  /* MÉTODOS PARA EL STEP DE ORDENES */
  actualizarTodasLasOrdenesSeleccionadas(): void {
    if (this.todasLasOrdenesSeleccionadas) {
      this.seleccionarTodasLasOrdenes();
    } else {
      this.deseleccionarTodasLasOrdenes();
    }
  }

  seleccionarTodasLasOrdenes(): void {
    this.estadoOrdenesAgrupadas = this.ordenesAgrupadas.map(ordenAgrupada => true);
    this.cantidadOrdenesAgrupadas = this.cantidadesPorOrdenesAgrupadas.map(cantidadesPorOrdenAgrupada => cantidadesPorOrdenAgrupada[cantidadesPorOrdenAgrupada.length - 1]);
    (<Orden[]>this.cuenta.ordenes) = this.ordenesAgrupadas.map((ordenAgrupada, posicionOrdenAgrupada) => {
      return this.crearOrdenAgrupada(ordenAgrupada, this.cantidadOrdenesAgrupadas[posicionOrdenAgrupada])
    });
  }

  deseleccionarTodasLasOrdenes(): void {
    this.estadoOrdenesAgrupadas = this.ordenesAgrupadas.map(ordenAgrupada => false);
    this.cantidadOrdenesAgrupadas = this.cantidadesPorOrdenesAgrupadas.map(cantidadesPorOrdenAgrupada => cantidadesPorOrdenAgrupada[0]);
    (<Orden[]>this.cuenta.ordenes) = [];
  }

  obtenerTotalDeOrdenesSinPagar(): number {
    let totalDeOrdenesSinPagar = 0;
    for (let posicionEstadoOrdenesAgrupadas = 0; posicionEstadoOrdenesAgrupadas < this.estadoOrdenesAgrupadas.length; posicionEstadoOrdenesAgrupadas++) {
      if (!this.estadoOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas]) {
        totalDeOrdenesSinPagar += (<number>this.ordenesAgrupadas[posicionEstadoOrdenesAgrupadas].cantidad)
        //También puede ser con this.cantidadesPorOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas][this.cantidadesPorOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas].length - 1];
      } else {
        totalDeOrdenesSinPagar += (<number>this.ordenesAgrupadas[posicionEstadoOrdenesAgrupadas].cantidad) - this.cantidadOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas];
      }
    }
    return totalDeOrdenesSinPagar;
  }

  obtenerTotalOrdenesAPagar(): number {
    let totalDeOrdenesAPagar = 0;
    (<Orden[]>this.cuenta.ordenes).forEach(orden => {
      totalDeOrdenesAPagar += <number>orden.cantidad;
    })
    return totalDeOrdenesAPagar;
  }

  inicializarEstadoOrdenesAgrupadas(): void {
    this.todasLasOrdenesSeleccionadas = false;
    this.estadoOrdenesAgrupadas = this.ordenesAgrupadas.map(ordenAgrupada => { return false });
  }

  inicializarCantidadesOrdenesAgrupadas() {
    this.cantidadOrdenesAgrupadas = this.ordenesAgrupadas.map(ordenAgrupada => { return 1 });
    this.cantidadesPorOrdenesAgrupadas = this.ordenesAgrupadas.map(ordenAgrupada => { return [] });
    this.ordenesAgrupadas.forEach((ordenAgrupada, posicionOrdenAgrupada) => {
      for (let cantidad = 0; cantidad < ordenAgrupada.cantidad; cantidad++) {
        this.cantidadesPorOrdenesAgrupadas[posicionOrdenAgrupada].push(cantidad + 1);
      }
    })
  }

  obtenerPosicionOrdenCuenta(orden: Orden): number {
    return this.cuenta.ordenes.findIndex(ordenCuenta => {
      if (ordenCuenta.tipo == orden.tipo && ordenCuenta.precioUnitario == orden.precioUnitario &&
        ordenCuenta.precioPorTamano._idTamano == orden.precioPorTamano._idTamano &&
        ordenCuenta.precioPorTamano.precio == orden.precioPorTamano.precio
      ) {
        switch (ordenCuenta.tipo) {
          case TipoOrden.Producto:
            return this.sonOrdenesTipoProductoIdenticas(orden, ordenCuenta);
          case TipoOrden.Paquete:
            return this.sonOrdenesTipoPaqueteIdenticas(orden, ordenCuenta);
        }
      } else {
        return false;
      }
    })
  }

  cambioEstadoOrdenAgrupada(posicionOrdenAgrupada: number, ordenAgrupada: Orden): void {
    if (this.estadoOrdenesAgrupadas[posicionOrdenAgrupada]) {
      (<Orden[]>this.cuenta.ordenes).push(this.crearOrdenAgrupada(ordenAgrupada, 1));
      this.cantidadOrdenesAgrupadas[posicionOrdenAgrupada] = this.cantidadesPorOrdenesAgrupadas[posicionOrdenAgrupada][0];
    }
    else {
      const posicionOrdenCuenta = this.obtenerPosicionOrdenCuenta(ordenAgrupada);
      if (posicionOrdenCuenta != -1) {
        this.cuenta.ordenes.splice(posicionOrdenCuenta, 1);
      }
    }
    this.verificarTodasLasOrdenesSeleccionadas();
  }

  cambioCantidadOrdenAgrupada(posicionOrdenAgrupada: number, ordenAgrupada: Orden): void {
    const posicionOrdenCuenta = this.obtenerPosicionOrdenCuenta(ordenAgrupada);
    if (posicionOrdenCuenta != -1) {
      (<Orden>this.cuenta.ordenes[posicionOrdenCuenta]).cantidad = this.cantidadOrdenesAgrupadas[posicionOrdenAgrupada];
    }
    this.verificarTodasLasOrdenesSeleccionadas();
  }

  verificarTodasLasOrdenesSeleccionadas() {
    let todasLasOrdenesSeleccionadas = true;
    for (let posicionEstadoOrdenesAgrupadas = 0; posicionEstadoOrdenesAgrupadas < this.estadoOrdenesAgrupadas.length; posicionEstadoOrdenesAgrupadas++) {
      if (!this.estadoOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas]) {
        todasLasOrdenesSeleccionadas = false
        break;
      } else {
        if (this.cantidadOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas] != this.cantidadesPorOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas][this.cantidadesPorOrdenesAgrupadas[posicionEstadoOrdenesAgrupadas].length - 1]) {
          todasLasOrdenesSeleccionadas = false
          break;
        }
      }
    }
    this.todasLasOrdenesSeleccionadas = todasLasOrdenesSeleccionadas;
  }

  /* MÉTODO PARA EL STEP DE CUPÓN */
  cambioCupon() {
    this.cuenta.datosCupon._idCupon = this.cuenta.datosCupon.cupon._id;
    this.cuenta.datosCupon.nombre = this.cuenta.datosCupon.cupon.nombre;
    this.cuenta.datosCupon.tipo = this.cuenta.datosCupon.cupon.tipo;
    switch (this.cuenta.datosCupon.tipo) {
      case TipoCupon.Cantidad:
        this.cuenta.datosCupon.porcentaje = undefined;
        this.cuenta.datosCupon.valor = this.cuenta.datosCupon.cupon.descuentoCantidad;
        break;
      case TipoCupon.Porcentaje:
        this.cuenta.datosCupon.porcentaje = this.cuenta.datosCupon.cupon.descuentoPorcentaje;
        this.cuenta.datosCupon.valor = this.calcularCupon();
        break;
    }
  }

  /* MÉTODOS PARA EL STEP DE DESCUENTO */
  cambioTipoDatosDescuento() {
    switch (this.cuenta.datosDescuento.tipo) {
      case TipoDescuento.Cantidad:
        this.cuenta.datosDescuento.porcentaje = null;
        this.cuenta.datosDescuento.valor = '0.00';
        break;
      case TipoDescuento.Porcentaje:
        this.cuenta.datosDescuento.porcentaje = 0;
        this.cuenta.datosDescuento.valor = null;
        break;
    }
  }

  transformarValorDatosDescuento(valor: string): void {
    if (valor != null && valor != undefined && !isNaN(Number(valor))) {
      this.cuenta.datosDescuento.valor = Number(valor).toFixed(2);
    } else {
      this.cuenta.datosDescuento.valor = Number('0').toFixed(2);
    }
  }

  transformarPorcentajeDatosDescuento(porcentaje: string) {
    if (porcentaje != null && porcentaje != undefined && !isNaN(Number(porcentaje))) {
      if (Number(porcentaje) > 100) {
        this.cuenta.datosDescuento.porcentaje = 100
      } else {
        if (Number(porcentaje) < 0) {
          this.cuenta.datosDescuento.porcentaje = 0
        } else {
          this.cuenta.datosDescuento.porcentaje = Number(porcentaje).toFixed(0);
        }
      }
    } else {
      this.cuenta.datosDescuento.porcentaje = 0
    }
    this.cuenta.datosDescuento.valor = this.calcularDescuento();
  }

  /* MÉTODOS PARA EL STEP DE PROPINA */
  cambioTipoDatosPropina() {
    switch (this.cuenta.datosPropina.tipo) {
      case TipoPropina.Cantidad:
        this.cuenta.datosPropina.porcentaje = null;
        this.cuenta.datosPropina.valor = '0.00';
        break;
      case TipoPropina.Porcentaje:
        this.cuenta.datosPropina.porcentaje = 0;
        this.cuenta.datosPropina.valor = null;
        break;
    }
  }

  transformarValorDatosPropina(valor: string): void {
    if (valor != null && valor != undefined && !isNaN(Number(valor))) {
      this.cuenta.datosPropina.valor = Number(valor).toFixed(2);
    } else {
      this.cuenta.datosPropina.valor = Number('0').toFixed(2);
    }
  }

  transformarPorcentajeDatosPropina(porcentaje: string) {
    if (porcentaje != null && porcentaje != undefined && !isNaN(Number(porcentaje))) {
      if (Number(porcentaje) > 100) {
        this.cuenta.datosPropina.porcentaje = 100
      } else {
        if (Number(porcentaje) < 0) {
          this.cuenta.datosPropina.porcentaje = 0
        } else {
          this.cuenta.datosPropina.porcentaje = Number(porcentaje).toFixed(0);
        }
      }
    } else {
      this.cuenta.datosPropina.porcentaje = 0
    }
    this.cuenta.datosPropina.valor = this.calcularPropina();
  }

  /* MÉTODO PARA EL STEP DE CLIENTE */
  cambioCliente(): void {
    this.cuenta.datosCliente._idCliente = this.cuenta.datosCliente.cliente._id;
    this.cuenta.datosCliente.tipoDePersona = this.cuenta.datosCliente.cliente.tipoDePersona;
    this.cuenta.datosCliente.rfc = this.cuenta.datosCliente.cliente.rfc;
    switch (this.cuenta.datosCliente.tipoDePersona) {
      case TipoDePersona.Moral:
        this.cuenta.datosCliente.razonSocial = this.cuenta.datosCliente.cliente.razonSocial;
        this.cuenta.datosCliente.tieneRepresentante = this.cuenta.datosCliente.cliente.tieneRepresentante;
        if (this.cuenta.datosCliente.tieneRepresentante) {
          this.cuenta.datosCliente.nombresRepresentante = this.cuenta.datosCliente.cliente.nombresRepresentante;
          this.cuenta.datosCliente.primerApellidoRepresentante = this.cuenta.datosCliente.cliente.primerApellidoRepresentante;
          this.cuenta.datosCliente.segundoApellidoRepresentante = this.cuenta.datosCliente.cliente.segundoApellidoRepresentante;
        } else {
          this.cuenta.datosCliente.nombresRepresentante = undefined;
          this.cuenta.datosCliente.primerApellidoRepresentante = undefined;
          this.cuenta.datosCliente.segundoApellidoRepresentante = undefined;
        }
        this.cuenta.datosCliente.nombres = undefined;
        this.cuenta.datosCliente.primerApellido = undefined;
        this.cuenta.datosCliente.segundoApellido = undefined;
        break;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        this.cuenta.datosCliente.nombres = this.cuenta.datosCliente.cliente.nombres;
        this.cuenta.datosCliente.primerApellido = this.cuenta.datosCliente.cliente.primerApellido;
        this.cuenta.datosCliente.segundoApellido = this.cuenta.datosCliente.cliente.segundoApellido;
        this.cuenta.datosCliente.razonSocial = undefined;
        this.cuenta.datosCliente.tieneRepresentante = undefined;
        this.cuenta.datosCliente.nombresRepresentante = undefined;
        this.cuenta.datosCliente.primerApellidoRepresentante = undefined;
        this.cuenta.datosCliente.segundoApellidoRepresentante = undefined;
    }
  }

  obtenerRazonSocial(cliente: Cliente): string {
    switch (cliente.tipoDePersona) {
      case TipoDePersona.Moral: return cliente.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return cliente.nombres + ' ' + cliente.primerApellido + (cliente.segundoApellido ? (' ' + cliente.segundoApellido) : '');
    }
  }

  agregarCliente(): void {
    const dialogRef = this.dialog.open(NewClientComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (cliente: Cliente) => {
        if (cliente) {
          this._alertasService.alertaExitoSinConfirmacion('Cliente agregado exitosamente', 'El cliente ' + this.obtenerRazonSocial(cliente) + ' ha sido agregado con éxito');
          this.clientes.push(cliente);
          this.cuenta.datosCliente.noAplica = false;
          this.cuenta.datosCliente.cliente = cliente;
          this.cambioCliente();
        }
      }
    );
  }
  /* MÉTODOS PARA EL STEP DE PAGO */
  obtenerMoneda(tipoMoneda: string): string {
    switch (tipoMoneda) {
      case Moneda.PesoMexicano: return 'Peso mexicano';
      case Moneda.Euro: return 'Euro';
      case Moneda.DolarAmericano: return 'Dolar americano';
    }
  }

  obtenerFormaDePago(tipoFormaDePago: string): string {
    switch (tipoFormaDePago) {
      case FormaDePago.Efectivo: return 'Efectivo';
      case FormaDePago.ChequeNominativo: return 'Cheque nominativo';
      case FormaDePago.TransferenciaElectronicaDeFondos: return 'Transferencia electrónica de fondos';
      case FormaDePago.TarjetaDeCredito: return 'Tarjeta de crédito';
      case FormaDePago.MonederoElectronico: return 'Monedero electrónico';
      case FormaDePago.DineroElectronico: return 'Dinero electróico';
      case FormaDePago.ValesDeDespensa: return 'Vales de despensa';
      case FormaDePago.DacionDePago: return 'Dacion de pago';
      case FormaDePago.PagoPorSubrogacion: return 'Pago por subrogacion';
      case FormaDePago.PagoPorConsignacion: return 'Pago por consignacion';
      case FormaDePago.Condonacion: return 'Condonacion';
      case FormaDePago.Compensacion: return 'Compensacion';
      case FormaDePago.Novacion: return 'Novacion';
      case FormaDePago.Confusion: return 'Confusion';
      case FormaDePago.RemisionDeDeuda: return 'Remision de deuda';
      case FormaDePago.PrescripcionOCaducidad: return 'Prescripcion o caducidad';
      case FormaDePago.ASatisfaccionDelAcreedor: return 'A satisfaccion del acreedor';
      case FormaDePago.TarjetaDeDebito: return 'Tarjeta de debito';
      case FormaDePago.TarjetaDeServicios: return 'Tarjeta de servicios';
      case FormaDePago.AplicacionDeAnticipos: return 'Aplicacion de anticipos';
      case FormaDePago.PorDefinir: return 'Por definir';
    }
  }

  obtenerMetodoDePago(tipoMetodoDePago: string): string {
    switch (tipoMetodoDePago) {
      case MetodoDePago.PagoEnParcialidadesODiferido: return 'Pago en parcialidades o diferido';
      case MetodoDePago.PagoEnUnaSolaExhibicion: return 'Pago en una sola exhibición';
    }
  }

  /* MÉTODOS PARA EL STEP DE PAGO */
  transformarCantidadPago(cantidadPago: string) {
    if (cantidadPago != null && cantidadPago != undefined && !isNaN(Number(cantidadPago))) {
      this.cuenta.cantidadPago = Number(cantidadPago).toFixed(2);
    } else {
      this.cuenta.cantidadPago = Number('0').toFixed(2);
    }
  }

  /* MÉTODOS PARA LOS CÁLCULOS DE LA CUENTA */
  calcularTotalOrdenesCuenta(): number {
    return (<Orden[]>this.cuenta.ordenes)
      .map(orden => { return (<number>orden.cantidad) * orden.precioUnitario })
      .reduce((acomulador, totalOrden) => { return acomulador + totalOrden }, 0);
  }

  calcularIva(): number {
    const porcentajeIva = (this.restaurante.ivaActivo ? (this.cuenta.porcentajeIva) : 0)
    if (this.cuenta.preciosIncluyenIva) {
      return parseFloat(((this.calcularTotalOrdenesCuenta() * porcentajeIva) / (100 + porcentajeIva)).toFixed(2));
    } else {
      return parseFloat((this.calcularTotalOrdenesCuenta() * porcentajeIva * 0.01).toFixed(2));
    }
  }

  calcularSubtotal(): number {
    if (this.cuenta.preciosIncluyenIva) {
      return this.calcularTotalOrdenesCuenta() - this.calcularIva();
    } else {
      return this.calcularTotalOrdenesCuenta();
    }
  }

  calcularCupon(): number {
    let cupon: number;
    if (!this.cuenta.datosCupon.noAplica && this.cuenta.datosCupon.cupon) {
      switch (this.cuenta.datosCupon.tipo) {
        case TipoCupon.Cantidad:
          cupon = <number>this.cuenta.datosCupon.valor;
          break;
        case TipoPropina.Porcentaje:
          cupon = (this.calcularSubtotal() + this.calcularIva()) * (<number>this.cuenta.datosCupon.porcentaje) * 0.01;
          break;
      }
    } else {
      cupon = 0;
    }
    return cupon;
  }

  obtenerPorcentajeDescuento(): number {
    if (this.cuenta.datosDescuento.porcentaje != null && this.cuenta.datosDescuento.porcentaje != undefined && !isNaN(Number(this.cuenta.datosDescuento.porcentaje))) {
      if (Number(this.cuenta.datosDescuento.porcentaje) > 100) {
        return 100;
      } else {
        if (Number(this.cuenta.datosDescuento.porcentaje) < 0) {
          return 0;
        } else {
          return parseInt(Number(this.cuenta.datosDescuento.porcentaje).toFixed(0));
        }
      }
    } else {
      return 0;
    }
  }

  calcularDescuento(): number {
    let descuento: number;
    if (this.cuenta.datosDescuento.activo) {
      switch (this.cuenta.datosDescuento.tipo) {
        case TipoPropina.Cantidad:
          if (this.cuenta.datosDescuento.valor != null && this.cuenta.datosDescuento.valor != undefined && !isNaN(Number(this.cuenta.datosDescuento.valor))) {
            descuento = parseFloat(Number(this.cuenta.datosDescuento.valor).toFixed(2));
          } else {
            descuento = 0;
          }
          break;
        case TipoPropina.Porcentaje:
          descuento = (this.calcularSubtotal() + this.calcularIva()) * this.obtenerPorcentajeDescuento() * 0.01;
          break;
      }
    } else {
      descuento = 0;
    }
    return descuento;
  }

  obtenerPorcentajePropina(): number {
    if (this.cuenta.datosPropina.porcentaje != null && this.cuenta.datosPropina.porcentaje != undefined && !isNaN(Number(this.cuenta.datosPropina.porcentaje))) {
      if (Number(this.cuenta.datosPropina.porcentaje) > 100) {
        return 100;
      } else {
        if (Number(this.cuenta.datosPropina.porcentaje) < 0) {
          return 0;
        } else {
          return parseInt(Number(this.cuenta.datosPropina.porcentaje).toFixed(0));
        }
      }
    } else {
      return 0;
    }
  }

  calcularPropina(): number {
    let propina: number;
    if (this.cuenta.datosPropina.activo) {
      switch (this.cuenta.datosPropina.tipo) {
        case TipoPropina.Cantidad:
          if (this.cuenta.datosPropina.valor != null && this.cuenta.datosPropina.valor != undefined && !isNaN(Number(this.cuenta.datosPropina.valor))) {
            propina = parseFloat(Number(this.cuenta.datosPropina.valor).toFixed(2));
          } else {
            propina = 0;
          }
          break;
        case TipoPropina.Porcentaje:
          propina = (this.calcularSubtotal() + this.calcularIva()) * this.obtenerPorcentajePropina() * 0.01;
          break;
      }
    } else {
      propina = 0;
    }
    return propina;
  }

  calcularTotal(): number {
    return this.calcularSubtotal() + this.calcularIva() - this.calcularCupon() - this.calcularDescuento() + this.calcularPropina();
  }

  obtenerCantidadPago(): number {
    let cantidadPago: number;
    if (this.cuenta.cantidadPago != null && this.cuenta.cantidadPago != undefined && !isNaN(Number(this.cuenta.cantidadPago))) {
      cantidadPago = parseFloat(Number(this.cuenta.cantidadPago).toFixed(2));
    } else {
      cantidadPago = 0;
    }
    return cantidadPago;
  }

  /* MÉTODOS PARA AGRAGAR UNA CUENTA */
  agregarCuenta() {
    const referenciaModal = this.dialog.open(ResumenPagoComponent, {
      data: {
        _idRestaurante: this._idRestaurante,
        _idArea: this._idArea,
        _idElementoPorArea: this._idElementoPorArea,
        _idComanda: this._idComanda,
        cuenta: this.prepararDatosCuenta(),
      }
    });
    referenciaModal.afterClosed().subscribe((cuenta: Cuenta) => {
      if (cuenta) {
        this._alertasService.alertaExitoConConfirmacionImpresionTicket('¡Pago realizado exitosamente!', 'El cambio es de $' + cuenta.cantidadCambio.toFixed(2), 'Imprimir ticket').then((result) => {
          if (result.value) {
            this.imprimirCuenta(cuenta);
          }
        })
        this.cuenta = new Cuenta();
        this.filtrarOrdenesPagadas(<string[]>cuenta.ordenes);
        this.inicializarOrdenesAgrupadas();
        this.stepper.selectedIndex = 0;
      }
    })
  }

  prepararDatosCuenta(): Cuenta {
    let cuenta: Cuenta = new Cuenta();
    cuenta.cantidadProductosDiferentes = this.obtenerCantidadProductosDiferentes()
    cuenta.cantidadTotalProductos = this.obtenerCantidadTotalProductos()
    cuenta.ordenes = this.obtenerOrdenes();
    cuenta.datosPropina.activo = this.cuenta.datosPropina.activo;
    if (cuenta.datosPropina.activo) {
      cuenta.datosPropina.tipo = this.cuenta.datosPropina.tipo;
      switch (cuenta.datosPropina.tipo) {
        case TipoPropina.Cantidad: 
          cuenta.datosPropina.porcentaje = undefined;
          break;
        case TipoPropina.Porcentaje: 
          cuenta.datosPropina.porcentaje = parseInt(this.cuenta.datosPropina.porcentaje.toString());
          break;
      } 
      cuenta.datosPropina.valor = parseFloat(this.cuenta.datosPropina.valor.toString());
    } else {
      cuenta.datosPropina.tipo = undefined;
      cuenta.datosPropina.porcentaje = undefined;
      cuenta.datosPropina.valor = undefined;
    }
    cuenta.datosDescuento.activo = this.cuenta.datosDescuento.activo;
    if (cuenta.datosDescuento.activo) {
      cuenta.datosDescuento.tipo = this.cuenta.datosDescuento.tipo;
      switch (cuenta.datosDescuento.tipo) {
        case TipoDescuento.Cantidad: 
          cuenta.datosDescuento.porcentaje = undefined;
          break;
        case TipoDescuento.Porcentaje: 
          cuenta.datosDescuento.porcentaje = parseInt(this.cuenta.datosDescuento.porcentaje.toString());
          break;
      } 
      cuenta.datosDescuento.valor = parseFloat(this.cuenta.datosDescuento.valor.toString());
    } else {
      cuenta.datosDescuento.tipo = undefined;
      cuenta.datosDescuento.porcentaje = undefined;
      cuenta.datosDescuento.valor = undefined;
    }
    cuenta.datosCupon.noAplica = this.cuenta.datosCupon.noAplica;
    if (!cuenta.datosCupon.noAplica) {
      cuenta.datosCupon._idCupon = this.cuenta.datosCupon._idCupon;
      cuenta.datosCupon.nombre = this.cuenta.datosCupon.nombre;
      cuenta.datosCupon.tipo = this.cuenta.datosCupon.tipo;
      cuenta.datosCupon.porcentaje = this.cuenta.datosCupon.porcentaje;
      cuenta.datosCupon.valor = this.cuenta.datosCupon.valor;
    } else {
      cuenta.datosCupon._idCupon = undefined;
      cuenta.datosCupon.nombre = undefined;
      cuenta.datosCupon.tipo = undefined;
      cuenta.datosCupon.porcentaje = undefined;
      cuenta.datosCupon.valor = undefined;
    }
    cuenta.datosCliente.noAplica = this.cuenta.datosCliente.noAplica;
    if (!cuenta.datosCliente.noAplica) {
      cuenta.datosCliente._idCliente = this.cuenta.datosCliente._idCliente;
      cuenta.datosCliente.tipoDePersona = this.cuenta.datosCliente.tipoDePersona;
      cuenta.datosCliente.rfc = this.cuenta.datosCliente.rfc;
      cuenta.datosCliente.nombres = this.cuenta.datosCliente.nombres;
      cuenta.datosCliente.primerApellido = this.cuenta.datosCliente.primerApellido;
      cuenta.datosCliente.segundoApellido = this.cuenta.datosCliente.segundoApellido;
      cuenta.datosCliente.razonSocial = this.cuenta.datosCliente.razonSocial;
      cuenta.datosCliente.tieneRepresentante = this.cuenta.datosCliente.tieneRepresentante;
      cuenta.datosCliente.nombresRepresentante = this.cuenta.datosCliente.nombresRepresentante;
      cuenta.datosCliente.primerApellidoRepresentante = this.cuenta.datosCliente.primerApellidoRepresentante;
      cuenta.datosCliente.segundoApellidoRepresentante = this.cuenta.datosCliente.segundoApellidoRepresentante;
    } else {
      cuenta.datosCliente._idCliente = undefined;
      cuenta.datosCliente.tipoDePersona = undefined;
      cuenta.datosCliente.rfc = undefined;
      cuenta.datosCliente.nombres = undefined;
      cuenta.datosCliente.primerApellido = undefined;
      cuenta.datosCliente.segundoApellido = undefined;
      cuenta.datosCliente.razonSocial = undefined;
      cuenta.datosCliente.tieneRepresentante = undefined;
      cuenta.datosCliente.nombresRepresentante = undefined;
      cuenta.datosCliente.primerApellidoRepresentante = undefined;
      cuenta.datosCliente.segundoApellidoRepresentante = undefined;
    }
    cuenta.subtotal = this.calcularSubtotal();
    cuenta.preciosIncluyenIva = this.cuenta.preciosIncluyenIva;
    cuenta.porcentajeIva = this.cuenta.porcentajeIva;
    cuenta.iva = this.calcularIva();
    cuenta.total = this.calcularTotal();
    cuenta.formaDePago = this.cuenta.formaDePago;
    cuenta.metodoDePago = this.cuenta.metodoDePago;
    cuenta.moneda = this.cuenta.moneda;
    cuenta.cantidadPago = parseFloat(this.cuenta.cantidadPago.toString());
    cuenta.cantidadCambio = cuenta.cantidadPago - cuenta.total;
    cuenta.banco = undefined;
    cuenta.numeroDeTarjeta = undefined;
    cuenta.estadoDeFacturacion = this.cuenta.estadoDeFacturacion;
    cuenta._idCajaDeUsuario = this.cajaUsuarioActual._id;
    return cuenta;
  }

  obtenerCantidadProductosDiferentes(): number {
    return this.cuenta.ordenes.length;
  }

  obtenerCantidadTotalProductos(): number {
    return (<Orden[]>this.cuenta.ordenes)
      .map(orden => { return <number>orden.cantidad })
      .reduce((acomulador, cantidadOrden) => { return acomulador + cantidadOrden }, 0);
  }

  obtenerOrdenes(): string[] {
    let ordenes: string[] = [];
    (<Orden[]>this.cuenta.ordenes).forEach(orden => {
      const posicionOrdenAgrupada = this.obtenerPosicionOrdenAgrupada(orden);
      if (posicionOrdenAgrupada != -1) {
        for (let posicionOrdenPorOrdenAgrupada = this.ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada].length - 1; posicionOrdenPorOrdenAgrupada > (this.ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada].length - 1 - <number>orden.cantidad); posicionOrdenPorOrdenAgrupada--) {
          ordenes.push(this.ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada][posicionOrdenPorOrdenAgrupada]._id);
        }
      }
    });
    return ordenes;
  }

  filtrarOrdenesPagadas(ordenes: string[]): void {
    this.ordenes = this.ordenes.filter(orden => {
      return !ordenes.includes(orden._id);
    });
  }

  /* MÉTODOS PARA IMPRIMIR LA CUENTA */
  async imprimirCuenta(cuenta: Cuenta) {
    this._impresionService.actualizarTipoImpresion(TipoImpresion.Cuenta);
    await this._impresionService.actualizarCuenta(cuenta);
    await this._impresionService.actualizarOrdenesAgrupadasCuenta(cuenta);
    window.print();
  }

  ngOnDestroy(): void {
    if (this.obtenerElementoPorAreaSubscription) this.obtenerElementoPorAreaSubscription.unsubscribe();
    if (this.obtenerOrdenesComandaSinPagarSubscription) this.obtenerOrdenesComandaSinPagarSubscription.unsubscribe();
    if (this.obtenerCajaPerfilSubscription) this.obtenerCajaPerfilSubscription.unsubscribe();
    if (this.obtenerRestauranteSubscription) this.obtenerRestauranteSubscription.unsubscribe();
    if (this.obtenerCuponesVigentesSubscription) this.obtenerCuponesVigentesSubscription.unsubscribe();
    if (this.obtenerClientesSubscription) this.obtenerClientesSubscription.unsubscribe();
    if (this.eliminarComandaSubscription) this.eliminarComandaSubscription.unsubscribe();
    if (this.actualizarComandaElementoPorAreaSubscription) this.actualizarComandaElementoPorAreaSubscription.unsubscribe();
  }
}
