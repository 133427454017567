import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {Insumo} from '../../../../../../shared/models/insumo';
import {InventoryService} from '../../../../../../core/services/inventory.service';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {InventoryEditInputComponent} from '../../components/inventory-edit-input/inventory-edit-input.component';
import {InventoryAddInputComponent} from '../../components/inventory-add-input/inventory-add-input.component';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html'
})
export class InputsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  columns = ['Nombre', 'Unidad de Medida', 'Opciones'];
  dataSource: MatTableDataSource<Insumo>;
  noResults: boolean;
  requestState: number;
  stockRequestState: number;
  subscription: Subscription;
  @ViewChild('dialogAddSuccess', {static: true}) private dialogAddSuccess: SwalComponent;
  @ViewChild('dialogAddError', {static: true}) private dialogAddError: SwalComponent;
  @ViewChild('dialogEditSuccess', {static: true}) private dialogEditSuccess: SwalComponent;
  @ViewChild('dialogEditError', {static: true}) private dialogEditError: SwalComponent;
  @ViewChild('dialogDeleteSuccess', {static: true}) private dialogDeleteSuccess: SwalComponent;
  @ViewChild('dialogDeleteError', {static: true}) private dialogDeleteError: SwalComponent;

  constructor(private inventoryService: InventoryService,
              public dialog: MatDialog) {
    this.stockRequestState = RequestState.initial;
  }

  ngOnInit(): void {
    this.requestState = RequestState.loading;
    this.subscription = this.inventoryService.obtenerInsumos().subscribe(
      (data: Insumo[]) => {
        if (data.length === 0) {
          this.noResults = true;
        }
        this.dataSource = new MatTableDataSource<Insumo>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.requestState = RequestState.success;
      },
      () => this.requestState = RequestState.error
    );
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    this.subscription.unsubscribe();
  }

  addInput(): void {
    const dialogRef = this.dialog.open(InventoryAddInputComponent, {disableClose: true});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          const data = this.dataSource.data;
          data.push(result.form);
          this.dataSource.data = data;
          this.dialogAddSuccess.show();
        }
      }
    );
  }

  editInput(id: string): void {
    const dialogRef = this.dialog.open(InventoryEditInputComponent, {disableClose: true, data: {id}});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          this.ngOnInit();
          this.dialogEditSuccess.show();
        }
      }
    );
  }

  deleteInput(id: string): void {
    this.inventoryService.eliminarInsumo(id).subscribe(
      (res) => {
        this.subscription = this.inventoryService.obtenerInsumos().subscribe(
          (data: Insumo[]) => {
            if (data.length === 0) {
              this.noResults = true;
            }
            this.requestState = RequestState.success;
            this.dataSource = new MatTableDataSource<Insumo>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          () => this.requestState = RequestState.error
        );
        if (res.status = 'OK') {
          this.dialogDeleteSuccess.show();
        }
      }
    );
  }

  setStockStateRequest(requestState: number) {
    this.stockRequestState = requestState;
  }
}
