import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {AppSettings} from '../../configs/app-settings.config';
import {AuthService} from '../authentication/auth.service';
import {Proveedor} from '../../shared/models/proveedor';

@Injectable({
  providedIn: 'root'
})

export class ProviderService {

  public providerInfo: Proveedor;
  public proveedores: Proveedor[];

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  addProvider(provider: Proveedor): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}/proveedor.php`, {provider}, AppSettings.Options);
  }

  editProvider(provider: Proveedor, id: string): Observable<any> {
    provider.id = id;
    return this.httpClient.put(`${AppSettings.APIEndpoint}/proveedor.php`, {provider}, AppSettings.Options);
  }

  deleteProvider(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/proveedor.php?id=${id}`, AppSettings.Options);
  }

  getProvider(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/proveedor.php?id=${id}`, AppSettings.Options)
      .pipe(map((res: Proveedor[]) => {
        const fl = res.filter(proveedor => {
          return proveedor.id === id;
        });
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  getProviders(): Observable<any> {
    return this.httpClient
      .get(`${AppSettings.APIEndpoint}/proveedores/proveedores.php`, AppSettings.Options);
  }
}
