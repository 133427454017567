import {AppSettings} from '../../../../../../../../configs/app-settings.config';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Producto} from '../../../../../../../../shared/models/producto';
import {MatDialog} from '@angular/material';
import {ProductSizeComponent} from '../../dialogs/product-size/product-size.component';
import {Orden} from '../../../../../../../../shared/models/orden';
import { VistaProductoModalComponent } from 'src/app/modules/product/vista-producto-modal/vista-producto-modal.component';

@Component({
  selector: 'app-menu-product',
  templateUrl: './menu-product.component.html'
})
export class MenuProductComponent implements OnInit {

  @Input() product: Producto;
  @Output() orden = new EventEmitter<Orden>();

  appSettings = AppSettings;

  // control: number;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  add(): void {
    const dialogRef = this.dialog.open(ProductSizeComponent,
      {
        disableClose: true,
        data: {
          id: this.product.id,
          nombre: this.product.nombre,
          existencia: this.product.existencia
        }
      });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res.ok) {
          if (this.product.existencia != 'NA') {
            (this.product.existencia as number) -= res.orden.cantidad;
          }
          this.orden.emit(res.orden);
          /*this.control++;
          this.quantity.emit(this.control);*/
        }
      }
    );
  }

  detalles() {
    const dialogRef = this.dialog.open(VistaProductoModalComponent,
      {
        disableClose: true,
        data: {
          id: this.product.id,
          nombre: this.product.nombre
        }
      });
  }
}
