import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../configs/app-settings.config';
import { HttpClient } from '@angular/common/http';
import { Categoria } from 'src/app/shared/class-models/categoria.model';


@Injectable({
    providedIn: 'root'
})
export class CategoriasService {
    private rutaBaseCategorias = '/categorias';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerCategorias(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseCategorias}`, AppSettings.Options);
    }

    obtenerCategoria(_idCategoria: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseCategorias}/${_idCategoria}`, AppSettings.Options);
    }

    /* POST */
    agregarCategoria(categoria: Categoria) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseCategorias}`, JSON.stringify(categoria), AppSettings.Options);
    }

    agregarCategoriaPorDefecto(categoria: Categoria) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseCategorias}/por-defecto`, JSON.stringify(categoria), AppSettings.Options);
    }

    /* PUT */
    actualizarCategoria(_idCategoria: string, categoria: Categoria) {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseCategorias}/${_idCategoria}`, JSON.stringify(categoria), AppSettings.Options);
    }

    /* PATCH */
    eliminarCategoria(_idCategoria: string) {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseCategorias}/${_idCategoria}`, AppSettings.Options);
    }
}