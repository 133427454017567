import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('direccionForm') direccionForm: FormGroup;
  titulo: string = 'Actualizar usuario';
  RequestState = RequestState;
  usuario: Usuario;
  tipoUsuario: number;
  stepActual: number = 0;

  /* VARIABLES PARA OBTENER EL USUARIO */
  estadoPeticionObtenerUsuario: number;
  fotoActualUsuario: string;
  obtenerUsuarioSubscription: Subscription;
  ciudadesSubscripcion: Subscription;
  estadosSubscripcion: Subscription;
  ciudades: Ciudad[];
  estados: Estado[];
  codigoPostal: string = '';
  AppSettings = AppSettings;

  /* VARIABLES PARA ACTUALIZAR EL USURIO */
  ExpresionesRegulares = ExpresionesRegulares;
  TipoUsuario = TipoUsuario;
  estadoPeticionActualizarUsuario: number;
  actualizarUsuarioSubscription: Subscription;
  eventoCambioImagenFotografia: any = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: Usuario,
    private modal: MatDialogRef<EditUserComponent>,
    private _authService: AuthService,
    private _usuariosService: UsuariosService,
    private _alertasService: AlertasService,
    private _ubicacionService: UbicacionService) {
    this.estadoPeticionObtenerUsuario = RequestState.initial;
    this.estadoPeticionActualizarUsuario = RequestState.initial;
    this.tipoUsuario = this._authService.obtenerTipoUsuario();
  }

  ngOnInit(): void {
    this.obtenerUsuario();
  }

  obtenerUsuario(): void {
    this.estadoPeticionObtenerUsuario = RequestState.loading;
    this.obtenerUsuarioSubscription = this._usuariosService.obtenerUsuario(this.data._id).subscribe(
      (usuario: Usuario) => {
        this.fotoActualUsuario = usuario.foto;
        usuario.foto = '';
        this.codigoPostal = usuario.codigoPostal.codigo;
        this.usuario = usuario;
        this.obtenerEstados();
        this.obtenerCiudades(this.usuario.idEstado);
        this.estadoPeticionObtenerUsuario = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUsuario = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerEstados(): void {
    this.estados = [];
    this.estadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerCiudades(idEstado: number): void {
    this.ciudades = [];
    this.ciudadesSubscripcion = this._ubicacionService.obtenerCiudades(idEstado).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerTipoUsuario(tipo: number): string {
    switch(tipo) {
      case TipoUsuario.Vendedor: return 'Ventas de licencias';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador de licencias';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
      default: return '';
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(event: ImageCroppedEvent): void {
    this.usuario.foto = event.base64;
  }

  imagenCargada(): void { }

  cortadorListo(): void { }

  cargarImagenFallida(): void { }

  async actualizarUsuario() {
    this.estadoPeticionActualizarUsuario = RequestState.loading;
    const codigoPostal = await this.verificarCodigoPostal(this.usuario.idEstado, this.codigoPostal);
    if (!codigoPostal) {
      this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
      this.estadoPeticionActualizarUsuario = RequestState.initial;
      this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
    } else {
      this.usuario.idCodigoPostal = codigoPostal.id;
      this.actualizarUsuarioSubscription = this._usuariosService.actualizarUsuario(this.data._id, this.usuario).subscribe(
        (usuario: Usuario) => {
          this.estadoPeticionActualizarUsuario = RequestState.success;
          this.actualizarUsuarioOriginal(usuario);
          this.modal.close(true);
        },
        (error: HttpErrorResponse) => {
          this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          this.estadoPeticionActualizarUsuario = RequestState.error;
        }
      );
    }
  }

  retroceder() {
    this.stepActual--;
    this.stepper.previous();
  }

  avanzar() {
    this.stepActual++;
    this.stepper.next();
  }

  async validarCodigoPostal() {
    if (this.codigoPostal.length >= 5) {
      const codigoPostal = await this.verificarCodigoPostal(this.usuario.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      }
    }
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  actualizarUsuarioOriginal(usuario: Usuario): void {
    this.data.id = usuario.id;
    this.data.nombres = usuario.nombres;
    this.data.primerApellido = usuario.primerApellido;
    this.data.segundoApellido = usuario.segundoApellido;
    this.data.nombreUsuario = usuario.nombreUsuario;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.ciudadesSubscripcion) this.ciudadesSubscripcion.unsubscribe();
    if (this.estadosSubscripcion) this.estadosSubscripcion.unsubscribe();
    if (this.obtenerUsuarioSubscription) this.obtenerUsuarioSubscription.unsubscribe();
    if (this.actualizarUsuarioSubscription) this.actualizarUsuarioSubscription.unsubscribe();
  }
}
