import {Routes} from '@angular/router';
import {KdsComponent} from './kds.component';


export const KDSRoutes: Routes = [
  {
    path: '',
    component: KdsComponent
  }
];
