import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialog } from '@angular/material';
import { EditRestaurantComponent } from './pages/edit-restaurant/edit-restaurant.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html'
})
export class RestaurantComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  //PorcentajesIva = PorcentajesIva;
  estadoPeticionObtenerRestaurante: number;
  obtenerRestauranteSubscripcion: Subscription;
  estadoPeticionActualizarConfiguracionRestaurante: number;
  //actualizarConfiguracionRestauranteSubscription: Subscription;
  eliminarRestauranteSubscription: Subscription;
  restaurante: Restaurante;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _restaurantesService: RestaurantesService,
    private _alertasService: AlertasService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerRestaurante = RequestState.initial;
    this.estadoPeticionActualizarConfiguracionRestaurante = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerRestaurante();
  }

  /*licenceInfo(): void {
    const dialogRef = this.dialog.open(LicenceInfoComponent, {
      width: '500px',
      data: this.restaurante.licencia,
      disableClose: true
    });
  }

  newLicence(): void {
    const dialogRef = this.dialog.open(NewLicenceComponent, {
      width: '500px',
      data: this.restaurante,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          this.dialogEditLicenseSuccess.show();
          this.restaurante = result.res;
        }
      }
    );
  }*/

  obtenerRestaurante(): void {
    this.estadoPeticionObtenerRestaurante = RequestState.loading;
    this.obtenerRestauranteSubscripcion = this._restaurantesService.obtenerRestaurante(this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante')).subscribe(
      (restaurante: Restaurante) => {
        this.restaurante = restaurante;
        this.estadoPeticionObtenerRestaurante = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerRestaurante = RequestState.error;
        this.router.navigate(['/restaurantes']);
      }
    );
  }

  actualizarRestaurante(restaurante: Restaurante): void {
    const dialogRef = this.dialog.open(EditRestaurantComponent, { disableClose: true, data: restaurante });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Restaurante actualizado exitosamente', 'El restaurante ' + restaurante.nombre + ' ha sido actualizado con éxito.');
        }
      }
    );
  }

  eliminarRestaurante(restaurante: Restaurante): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el restaurante ' + restaurante.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarRestauranteSubscription = this._restaurantesService.eliminarRestaurante(restaurante._id).subscribe(
          (resultado: boolean) => {
            this._alertasService.alertaExitoSinConfirmacion('Restaurante eliminado exitosamente', 'El restaurante ' + restaurante.nombre + ' ha sido eliminado con éxito.');
            this.router.navigate(['/restaurantes']);
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  /*actualizarConfiguracionRestaurante() {
    this.estadoPeticionActualizarConfiguracionRestaurante = RequestState.loading;
    this.actualizarConfiguracionRestauranteSubscription = this._restaurantesService.actualizarConfiguracionRestaurante(this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante'), this.restaurante).subscribe(
      (restaurante: Restaurante) => {
        this._alertasService.alertaExitoSinConfirmacion('Configuración del restaurante actualizada exitosamente', 'La configuración del restaurante ' + restaurante.nombre + ' ha sido actualizado con éxito.');
        this.estadoPeticionActualizarConfiguracionRestaurante = RequestState.success;
        this.actualizarConfiguracionRestauranteOriginal(restaurante);
      },
      (error) => {
        this.estadoPeticionActualizarConfiguracionRestaurante = RequestState.error;
        this._alertasService.alertaErrorSinConfirmacion('Error al actualizar la configuración del restaurante', 'Ha ocurrido un error al actualizar la configuración del restaurante, favor de intentarlo mas tarde.');
      }
    );
  }*/

  /*actualizarConfiguracionRestauranteOriginal(restaurante: Restaurante): void {
    this.restaurante.descuentosActivos = restaurante.descuentosActivos;
    this.restaurante.cuponesActivos = restaurante.cuponesActivos;
    this.restaurante.ivaActivo = restaurante.ivaActivo;
    this.restaurante.porcentajeIva = restaurante.porcentajeIva;
  }*/

  ngOnDestroy(): void {
    if (this.obtenerRestauranteSubscripcion) this.obtenerRestauranteSubscripcion.unsubscribe();
    if (this.eliminarRestauranteSubscription) this.eliminarRestauranteSubscription.unsubscribe();
    //if (this.actualizarConfiguracionRestauranteSubscription) this.actualizarConfiguracionRestauranteSubscription.unsubscribe();
  }
}
