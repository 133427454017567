import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BillDetailsComponent } from '../dialogs/bill-details/bill-details.component';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { TipoDescuento } from 'src/app/shared/enums/tipo-descuento.enum';
import { TipoPropina } from 'src/app/shared/enums/tipo-propina.enum';
import { CuentasService } from 'src/app/core/servicios/cuentas.service';
import { ImpresionService } from 'src/app/core/servicios/impresion.service';
import { TipoImpresion } from 'src/app/shared/enums/tipo-impresion.enum';

@Component({
  selector: 'app-cuentas-de-caja',
  templateUrl: './cuentas-de-caja.component.html',
  styleUrls: ['./cuentas-de-caja.component.scss']
})
export class CuentasDeCajaComponent implements OnInit {
  @Input() cajaDeUsuario: CajaDeUsuario;
  @Input() _idRestaurante: string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Cuenta>;
  filtro: string = '';
  RequestState = RequestState;
  estadoPeticionObtenerCuentasCaja: number;
  obtenerCuentasCajaSubscription: Subscription;
  columnas = ['fechaRegistro', /*'formaDePago',*/ 'subtotal', 'iva', 'cupon', 'descuento', 'propina', 'total', 'opciones'];
  TipoCupon = TipoCupon;
  TipoDescuento = TipoDescuento;
  TipoPropina = TipoPropina;

  constructor(
    private _cuentasService: CuentasService,
    private _impresionService: ImpresionService,
    private _alertasService: AlertasService,
    public dialog: MatDialog) {
    this.estadoPeticionObtenerCuentasCaja = RequestState.initial;
  }

  ngOnInit(): void {
    if (this.cajaDeUsuario) {
      this.obtenerCuentasCaja();
    }
    else {
      this.inicializarCuentas([]);
      this.estadoPeticionObtenerCuentasCaja = RequestState.success;
    }
  }

  obtenerCuentasCaja(): void {
    this.estadoPeticionObtenerCuentasCaja = RequestState.loading;
    this.obtenerCuentasCajaSubscription = this._cuentasService.obtenerCuentasCaja(this._idRestaurante, this.cajaDeUsuario._id).subscribe(
      (cuentas: Cuenta[]) => {
        this.inicializarCuentas(cuentas);
        this.cajaDeUsuario.montoVentaTotal = this.calcularTotalCajaDeUsuario();
        this.estadoPeticionObtenerCuentasCaja = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCuentasCaja = RequestState.error;
      }
    );
  }

  inicializarCuentas(cuentas: Cuenta[]): void {
    this.dataSource = new MatTableDataSource<Cuenta>(cuentas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    /*this.dataSource.filterPredicate = (cliente: Cliente, filtro: string) => {
      return this.obtenerTipoDePersona(cliente.tipoDePersona).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerRazonSocial(cliente).trim().toLowerCase().indexOf(filtro) !== -1 ||
        cliente.rfc.trim().toLowerCase().indexOf(filtro) !== -1 ||
        cliente.correo.trim().toLowerCase().indexOf(filtro) !== -1 ||
        (cliente.telefono ? cliente.telefono.trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
        (cliente.telefono ? this.phonePipe.transform(cliente.telefono, 'phone').trim().toLowerCase().indexOf(filtro) !== -1 : false);
    }*/
  }

  buscarCuenta(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  calcularTotalCajaDeUsuario(): number {
    return this.dataSource.data
      .map(cuenta => cuenta.total)
      .reduce((acomulador, total) => {return acomulador + total}, 0);
  }

  verCuenta(cuenta: Cuenta): void {
    const dialogRef = this.dialog.open(BillDetailsComponent,
      {
        width: '80%',
        disableClose: true, 
        data: { _idRestaurante: this._idRestaurante, cuenta: cuenta } 
      });
  }

  async imprimirCuenta(cuenta: Cuenta) {
    this._impresionService.actualizarTipoImpresion(TipoImpresion.Cuenta);
    await this._impresionService.actualizarCuenta(cuenta);
    await this._impresionService.actualizarOrdenesAgrupadasCuenta(cuenta);
    window.print();
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerCuentasCajaSubscription) this.obtenerCuentasCajaSubscription.unsubscribe();
  }
}
