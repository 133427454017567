import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Ingrediente} from '../../shared/models/ingrediente';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppSettings} from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class IngredientService {

  constructor(private httpClient: HttpClient) {
  }

  obtenerIngrediente(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/ingrediente.php?id=` + id, AppSettings.Options)
      .pipe(map((res: Ingrediente[]) => {
        const fl = res.filter(ingrediente => ingrediente.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  obtenerIngredientes(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/ingredientes/ingredientes.php`, AppSettings.Options);
  }

  obtenerIngredientesExtra(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/ingredientes/ingredientes-extra.php`, AppSettings.Options);
  }

  nuevoIngrediente(ingrediente: Ingrediente): Observable<any> {
    if (ingrediente.esExtra) {
      ingrediente.precio = parseFloat(ingrediente.precio.toString());
    }
    const body = {
      ingredient: ingrediente
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/ingrediente.php`, JSON.stringify(body), AppSettings.Options);
  }

  actualizarIngrediente(id, ingrediente: Ingrediente): Observable<object> {
    ingrediente.id = id;
    if (ingrediente.esExtra) {
      ingrediente.precio = parseFloat(ingrediente.precio.toString());
    }
    const body = {
      ingredient: ingrediente
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/ingrediente.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarIngrediente(id: string) {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/ingrediente.php?id=${id}`, AppSettings.Options);
  }
}
