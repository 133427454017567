import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// Pipes.
import {CommonModule, DatePipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTooltipModule,
  MatCheckboxModule, MatStepperModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {ClientsComponent} from './clients.component';
// Modals.
import {NewClientComponent} from './modals/new-client/new-client.component';
// Routes.
import {ClientsRoutes} from './clients.routes';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';

@NgModule({
  declarations: [
    ClientsComponent,
    NewClientComponent
  ],
  imports: [
    RouterModule.forRoot(ClientsRoutes),
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatStepperModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    ClientsComponent,
    NewClientComponent
  ],
  providers: [
    DatePipe,
    PhonePipe
  ]
})
export class ClientsModule {
}
