import {Routes} from '@angular/router';

import {RestaurantsComponent} from './restaurants.component';
import {NewRestaurantComponent} from './pages/new-restaurant/new-restaurant.component';
import {AdminGuard} from '../../core/guards/admin.guard';

export const RestaurantsRoutes: Routes = [
  {
    path: '',
    component: RestaurantsComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'nueva',
    component: NewRestaurantComponent,
    canActivate: [AdminGuard]
  }
];
