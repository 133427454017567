import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../configs/app-settings.config';
import { HttpClient } from '@angular/common/http';
import { Elemento } from 'src/app/shared/class-models/elemento.model';

@Injectable({
    providedIn: 'root'
})
export class ElementosService {
    private rutaBaseElementos = '/elementos';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerElementosPorTipoDeArea(tipoDeArea: number): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseElementos}/tipos-de-area/${tipoDeArea}`, AppSettings.Options);
    }

    obtenerElementosDeArea(_idArea): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseElementos}/areas/${_idArea}`, AppSettings.Options);
    }

    obtenerElemento(_idElemento: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseElementos}/${_idElemento}`, AppSettings.Options);
    }

    /* POST */
    agregarElemento(elemento: Elemento) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseElementos}`, JSON.stringify(elemento), AppSettings.Options);
    }

    agregarElementoPorTipoDeAreaPorDefecto(elemento: Elemento, tipoDeArea: number) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseElementos}/tipos-de-area/${tipoDeArea}/por-defecto`, JSON.stringify(elemento), AppSettings.Options);
    }

    /* PUT */
    actualizarElemento(_idElemento: string, elemento: Elemento) {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseElementos}/${_idElemento}`, JSON.stringify(elemento), AppSettings.Options);
    }

    /* PATCH */
    eliminarElemento(_idElemento: string) {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseElementos}/${_idElemento}`, AppSettings.Options);
    }
}
