import { Usuario } from './usuario.model';


export class Licencia {
    _id?: string;
    id: number;
    fechaInicio: string | Date;
    fechaFin: string | Date;
    formaDePago: string;
    monto: number | string;
    meses: number;
    usuarios: number;
    restaurantes: number;
    esDemo: boolean;
    usadas?: number; //sucursales usadas para una licencia;
    _idAdministrador?: string;
    administrador: Usuario;
    _idLicenciaRenovada: string;
    licenciaRenovada: Licencia;
    _idUsuario: string;
    usuario: Usuario;
    activo?: boolean;
    fechaRegistro: Date;
    _idAccesoUsuario: string;

    constructor() { 
        this.esDemo = false;
        this._idAdministrador = '';
    }
}