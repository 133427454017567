import { Component, OnInit, Inject } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { FormaDePago } from 'src/app/shared/enums/forma-de-pago.enum';
import { Subscription } from 'rxjs';
import { GastoDeInsumo, RegistroDeInsumo } from 'src/app/shared/class-models/gasto-de-insumo.model';
import { GastosDeInsumosService } from 'src/app/core/servicios/gastos-de-insumos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { TipoDeInsumo } from 'src/app/shared/enums/tipo-de-insumo.enum';
import { Insumo } from 'src/app/shared/class-models/insumo.model';

@Component({
  selector: 'app-actualizacion-gasto-de-insumo',
  templateUrl: './actualizacion-gasto-de-insumo.component.html',
  styleUrls: ['./actualizacion-gasto-de-insumo.component.scss']
})
export class ActualizacionGastoDeInsumoComponent implements OnInit {
  /* VARIABLES GENERALES */
  titulo: string = 'Actualizar gasto de insumo';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  FormaDePago = FormaDePago;
  TipoDeInsumo = TipoDeInsumo;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  estadoPeticionObtenerGastoDeInsumo: number;
  obtenerGastoDeInsumoSubscripcion: Subscription;
  nombresDeInsumo: string[];
  razonSocialProveedor: string;

  /* VARIABLES PARA AGREGAR EL GASTO DE INSUMO */
  estadoPeticionActualizarGastoDeInsumo: number;
  actualizarGastoDeInsumoSubscripcion: Subscription;
  gastoDeInsumo: GastoDeInsumo;;

  constructor(
    private _gastosDeInsumosService: GastosDeInsumosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    public modal: MatDialogRef<ActualizacionGastoDeInsumoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { _idRestaurante: string, gastoDeInsumo: GastoDeInsumo },//Se recibe el ID del restaurante
  ) {
    this.estadoPeticionObtenerGastoDeInsumo = RequestState.initial;
    this.estadoPeticionActualizarGastoDeInsumo = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerGastoDeInsumo();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerGastoDeInsumo(): void {
    this.estadoPeticionObtenerGastoDeInsumo = RequestState.loading;
    this.obtenerGastoDeInsumoSubscripcion = this._gastosDeInsumosService.obtenerGastoDeInsumo(this.data._idRestaurante, this.data.gastoDeInsumo._id).subscribe(
      (gastoDeInsumo: GastoDeInsumo) => {
        this.razonSocialProveedor = this.obtenerRazonSocialProveedor(gastoDeInsumo.proveedor);
        this.nombresDeInsumo = gastoDeInsumo.insumos.map(registroDeInsumo => this.obtenerNombreDeInsumo(registroDeInsumo.insumo));
        gastoDeInsumo.insumos.forEach(registroDeInsumo => {
          registroDeInsumo.cantidadDeInsumo = parseFloat(registroDeInsumo.cantidadDeInsumo.toString()).toFixed(registroDeInsumo.insumo.unidadDeMedida.cantidadDeDecimales);
        })
        gastoDeInsumo.total = parseFloat(gastoDeInsumo.total.toString()).toFixed(2);
        this.gastoDeInsumo = gastoDeInsumo;
        this.estadoPeticionObtenerGastoDeInsumo = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerGastoDeInsumo = RequestState.error;
        this.modal.close();
      }
    );
  }
  /* MÉTODOS PARA PROVEEDORES */
  obtenerRazonSocialProveedor(proveedor: Proveedor): string {
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral: return proveedor.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return proveedor.nombres + ' ' + proveedor.primerApellido + (proveedor.segundoApellido ? (' ' + proveedor.segundoApellido) : '');
    }
  }

  /* MÉTODOS PARA INSUMOS */
  obtenerNombreDeInsumo(insumo: Insumo): string {
    switch (insumo.tipo) {
      case TipoDeInsumo.Ingrediente: return insumo.ingrediente.nombre;
      case TipoDeInsumo.Producto: return insumo.producto.nombre;
      case TipoDeInsumo.Otro: return insumo.nombre;
    }
  }

  transformarCantidadDeInsumo(posicionInsumo: number, cantidadDeInsumo: string, cantidadDeDecimales: number): void {
    if (cantidadDeInsumo != null && cantidadDeInsumo != undefined && !isNaN(Number(cantidadDeInsumo))) {
      this.gastoDeInsumo.insumos[posicionInsumo].cantidadDeInsumo = Number(cantidadDeInsumo).toFixed(cantidadDeDecimales);
    } else {
      this.gastoDeInsumo.insumos[posicionInsumo].cantidadDeInsumo = Number('0').toFixed(cantidadDeDecimales);
    }
  }

  /* MÉTODOS GENERALES */
  obtenerFormaDePago(tipoFormaDePago: string): string {
    switch (tipoFormaDePago) {
      case FormaDePago.Efectivo: return 'Efectivo';
      case FormaDePago.ChequeNominativo: return 'Cheque nominativo';
      case FormaDePago.TransferenciaElectronicaDeFondos: return 'Transferencia electrónica de fondos';
      case FormaDePago.TarjetaDeCredito: return 'Tarjeta de crédito';
      case FormaDePago.MonederoElectronico: return 'Monedero electrónico';
      case FormaDePago.DineroElectronico: return 'Dinero electróico';
      case FormaDePago.ValesDeDespensa: return 'Vales de despensa';
      case FormaDePago.DacionDePago: return 'Dacion de pago';
      case FormaDePago.PagoPorSubrogacion: return 'Pago por subrogacion';
      case FormaDePago.PagoPorConsignacion: return 'Pago por consignacion';
      case FormaDePago.Condonacion: return 'Condonacion';
      case FormaDePago.Compensacion: return 'Compensacion';
      case FormaDePago.Novacion: return 'Novacion';
      case FormaDePago.Confusion: return 'Confusion';
      case FormaDePago.RemisionDeDeuda: return 'Remision de deuda';
      case FormaDePago.PrescripcionOCaducidad: return 'Prescripcion o caducidad';
      case FormaDePago.ASatisfaccionDelAcreedor: return 'A satisfaccion del acreedor';
      case FormaDePago.TarjetaDeDebito: return 'Tarjeta de debito';
      case FormaDePago.TarjetaDeServicios: return 'Tarjeta de servicios';
      case FormaDePago.AplicacionDeAnticipos: return 'Aplicacion de anticipos';
      case FormaDePago.PorDefinir: return 'Por definir';
    }
  }

  transformarPrecio(total: string): void {
    if (total != null && total != undefined && !isNaN(Number(total))) {
      this.gastoDeInsumo.total = Number(total).toFixed(2);
    } else {
      this.gastoDeInsumo.total = Number('0').toFixed(2);
    }
  }

  /* MÉTODOS PARA ACTRUALIZAR EL GASTO DE INSUMO */
  actualizarGastoDeInsumo(): void {
    this.estadoPeticionActualizarGastoDeInsumo = RequestState.loading;
    this.actualizarGastoDeInsumoSubscripcion = this._gastosDeInsumosService.actualizarGastoDeInsumo(this.data._idRestaurante, this.data.gastoDeInsumo._id, this.prepararDatosGastoDeInsumo()).subscribe(
      (gastoDeInsumo: GastoDeInsumo) => {
        this.estadoPeticionActualizarGastoDeInsumo = RequestState.success;
        this.actualizarGastoDeInsumoOriginal(gastoDeInsumo);
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarGastoDeInsumo = RequestState.error;
      }
    );
  }

  prepararDatosGastoDeInsumo(): GastoDeInsumo {
    let gastoDeInsumo = new GastoDeInsumo();
    gastoDeInsumo._idProveedor = this.gastoDeInsumo._idProveedor;
    gastoDeInsumo.descripcion = this.gastoDeInsumo.descripcion;
    //gastoDeInsumo.total = 0;
    this.gastoDeInsumo.insumos.forEach(registroDeInsumo => {
      let copiaRegistroDeInsumo = new RegistroDeInsumo();
      copiaRegistroDeInsumo._idInsumo = registroDeInsumo._idInsumo;
      const tipoDeInsumo = registroDeInsumo.insumo.tipo;
      if (tipoDeInsumo == TipoDeInsumo.Producto) copiaRegistroDeInsumo._idTamano = registroDeInsumo._idTamano;
      else copiaRegistroDeInsumo._idTamano = null;
      copiaRegistroDeInsumo.cantidadDeInsumo = parseFloat(registroDeInsumo.cantidadDeInsumo.toString());
      copiaRegistroDeInsumo.total = parseFloat(this.gastoDeInsumo.total.toString());
      //copiaRegistroDeInsumo.total = parseFloat(registroDeInsumo.total.toString());
      //(<number>gastoDeInsumo.total) += parseFloat(registroDeInsumo.total.toString());
      gastoDeInsumo.insumos.push(copiaRegistroDeInsumo);
    })
    gastoDeInsumo.formaDePago = this.gastoDeInsumo.formaDePago;
    gastoDeInsumo.total = parseFloat(this.gastoDeInsumo.total.toString());
    return gastoDeInsumo;
  }

  actualizarGastoDeInsumoOriginal(gastoDeInsumo: GastoDeInsumo): void {
    this.data.gastoDeInsumo._idProveedor = gastoDeInsumo._idProveedor;
    this.data.gastoDeInsumo.proveedor = gastoDeInsumo.proveedor;
    this.data.gastoDeInsumo.descripcion = gastoDeInsumo.descripcion;
    this.data.gastoDeInsumo.insumos = gastoDeInsumo.insumos;
    this.data.gastoDeInsumo.formaDePago = gastoDeInsumo.formaDePago;
    this.data.gastoDeInsumo.total = gastoDeInsumo.total;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerGastoDeInsumoSubscripcion) this.obtenerGastoDeInsumoSubscripcion.unsubscribe();
    if (this.actualizarGastoDeInsumoSubscripcion) this.actualizarGastoDeInsumoSubscripcion.unsubscribe();
  }
}
