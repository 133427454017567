import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {HomeComponent} from './home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// Pages
import {AdminComponent} from './pages/admin/admin.component';
import {CashierComponent} from './pages/cashier/cashier.component';
import {WaiterComponent} from './pages/waiter/waiter.component';
import {
  MatButtonModule,
  MatCardModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatDividerModule
} from '@angular/material';
import {CloseCashRegisterComponent} from './dialogs/close-cash-register/close-cash-register.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {OpenCashRegisterComponent} from './dialogs/open-cash-register/open-cash-register.component';
import {ChartsModule} from 'ng2-charts';
import locale from '@angular/common/locales/es';
import {CashRegisterModule} from '../restaurant/pages/cash-register/cash-register.module';
import {RestaurantReportComponent} from './pages/admin/pages/restaurant-report/restaurant-report.component';
import {CashierReportComponent} from './pages/admin/pages/cashier-report/cashier-report.component';
import {WaiterReportComponent} from './pages/admin/pages/waiter-report/waiter-report.component';
import {LicenseSellerComponent} from './pages/license-seller/license-seller.component';
import {RootComponent} from './pages/root/root.component';
import {LicenceSellersReportComponent} from './pages/root/pages/licence-sellers-report/licence-sellers-report.component';
import {GeneralLicenceSalesReportComponent} from './pages/root/pages/general-licence-sales-report/general-licence-sales-report.component';
import {PipesModule} from '../../shared/pipes/pipes.module';
import { CajaActualComponent } from './pages/cashier/caja-actual/caja-actual.component';
import { HistorialCajasComponent } from './pages/cashier/historial-cajas/historial-cajas.component';
import { FechaConHoraPipe } from 'src/app/shared/pipes/fecha-con-hora.pipe';
import { CajaConsultadaComponent } from './pages/cashier/caja-consultada/caja-consultada.component';

registerLocaleData(locale, 'es');

@NgModule({
  declarations: [
    AdminComponent,
    HomeComponent,
    CashierComponent,
    WaiterComponent,
    CloseCashRegisterComponent,
    OpenCashRegisterComponent,
    RestaurantReportComponent,
    CashierReportComponent,
    WaiterReportComponent,
    LicenseSellerComponent,
    RootComponent,
    LicenceSellersReportComponent,
    GeneralLicenceSalesReportComponent,
    CajaActualComponent,
    HistorialCajasComponent,
    CajaConsultadaComponent
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en'},
    FechaConHoraPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatProgressBarModule,
    SweetAlert2Module,
    MatIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    ChartsModule,
    CashRegisterModule,
    MatTooltipModule,
    MatExpansionModule,
    PipesModule,
    MatCardModule,
    MatDividerModule
  ],
  exports: [
    AdminComponent,
    HomeComponent,
    CashierComponent,
    WaiterComponent
  ]
})
export class HomeModule {
}
