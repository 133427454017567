import { Area } from './area.model';
import { Comanda } from './comanda.model';
import { Elemento } from './elemento.model';
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';

export class ElementoPorArea {
    _id?: string;
    id?: number;
    nombre: string;
    descripcion: string;
    _idComanda: string;
    comanda: Comanda;
    _idElemento: string;
    elemento: Elemento;
    _idArea: string;
    area: Area;
    _idRestaurante: string;
    restaurante: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {}
}