import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ExistenciasService } from 'src/app/core/servicios/existencias.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Existencia } from 'src/app/shared/class-models/existencia.model';
import { ActualizarExistenciasPorTamanoComponent } from './actualizar-existencias-por-tamano/actualizar-existencias-por-tamano.component';

@Component({
  selector: 'app-administracion-existencias',
  templateUrl: './administracion-existencias.component.html',
  styleUrls: ['./administracion-existencias.component.scss']
})
export class AdministracionExistenciasComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Producto>;
  filtro: string = '';
  estadoPeticionObtenerExistenciasDeProductos: number;
  RequestState = RequestState;
  obtenerExistenciasDeProductosSubscripcion: Subscription;
  agregarExistenciaSubscripcion: Subscription;
  actualizarEstadoDeExistenciaSubscription: Subscription;
  columnas = ['producto', 'administracionDeExistenciasHabilitado', 'totalDeExistencias', 'opciones'];
  _idRestaurante: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _existenciasService: ExistenciasService,
    private _alertasService: AlertasService,
    public dialog: MatDialog) {
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this.estadoPeticionObtenerExistenciasDeProductos = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerExistenciasDeProductos();
  }

  obtenerExistenciasDeProductos(): void {
    this.estadoPeticionObtenerExistenciasDeProductos = RequestState.loading;
    this.obtenerExistenciasDeProductosSubscripcion = this._existenciasService.obtenerExistenciasDeProductos(this._idRestaurante).subscribe(
      (productos: Producto[]) => {
        this.inicializarProductos(productos);
        this.estadoPeticionObtenerExistenciasDeProductos = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerExistenciasDeProductos = RequestState.error;
      }
    );
  }

  inicializarProductos(productos: Producto[]): void {
    this.dataSource = new MatTableDataSource<Producto>(productos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (producto: Producto, filtro: string) => {
      return producto.nombre.trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.administracionDeExistenciasHabilitado(producto) ? producto.totalDeExistencias.toString().trim().toLowerCase().indexOf(filtro) !== -1 : false;
    }
  }

  buscarProducto(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  administracionDeExistenciasHabilitado(producto: Producto): boolean {
    if (producto.existencia != null && producto.existencia.activo == true) {
      return true;
    } else {
      return false;
    }
  }

  /* MÉTODOS PARA AGREGAR EXISTENCIA DE PRODUCTOS */
  async agregarExistenciaDeProducto(producto: Producto) {
    try {
      if (producto.existencia == null) {
        await this.agregarExistencia(producto);
      } else {
        await this.actualizarEstadoDeExistencia(producto, true);
      }
      this._alertasService.alertaExitoConConfirmacion('Administración de existencias habilitada', 'Se ha habilitado exitosamente la administración de existencias en el producto ' + producto.nombre);
    } catch (error) {
      this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
    }
  }

  async agregarExistencia(producto: Producto): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let existencia = new Existencia;
      existencia._idProducto = producto._id;
      this.agregarExistenciaSubscripcion = this._existenciasService.agregarExistencia(this._idRestaurante, existencia).subscribe(
        (existencia: Existencia) => {
          producto.existencia = existencia;
          producto.totalDeExistencias = 0;
          resolve(true);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  /* MÉTODOS PARA ELIMINAR EXISTENCIAS DE PRODUCTOS */
  async eliminarExistenciaDeProducto(producto: Producto) {
    try {
      await this.actualizarEstadoDeExistencia(producto, false);
      this._alertasService.alertaExitoConConfirmacion('Administración de existencias deshabilitada', 'Se ha deshabilitado exitosamente la administración de existencias en el producto ' + producto.nombre);
    } catch (error) {
      this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
    }
  }

  /* MÉTODO PARA AGREGAR/ELIMINAR EXISTENCIA DE PRODUCTOS */
  async actualizarEstadoDeExistencia(producto: Producto, activo: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.actualizarEstadoDeExistenciaSubscription = this._existenciasService.actualizarEstadoDeExistencia(this._idRestaurante, producto.existencia._id, activo).subscribe(
        (existencia: Existencia) => {
          producto.existencia.activo = existencia.activo;
          resolve(true);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  /* MÉTODO PARA ABRIR EL MODAL CORRESPONDIENTE A LA ADMINISTRACIÓN DE LAS EXISTENCIAS DE UN PRODUCTO */
  actualizarExistenciasDeProductoPorTamano(producto: Producto): void {
    const dialogRef = this.dialog.open(ActualizarExistenciasPorTamanoComponent, { disableClose: true, data: { _idRestaurante: this._idRestaurante, producto: producto } });
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerExistenciasDeProductosSubscripcion) this.obtenerExistenciasDeProductosSubscripcion.unsubscribe();
    if (this.agregarExistenciaSubscripcion) this.agregarExistenciaSubscripcion.unsubscribe();
    if (this.actualizarEstadoDeExistenciaSubscription) this.actualizarEstadoDeExistenciaSubscription.unsubscribe();
  }
}
