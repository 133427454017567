import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Paquete, PaquetePorTamano, AlternativaProductosPorTamano } from 'src/app/shared/class-models/paquete.model';
import { Subscription, Subject } from 'rxjs';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { ExistenciasPorTamanoService } from 'src/app/core/servicios/existencias-por-tamano.service';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { Orden, AlternativaProductoPorTamano, AgregarIngrediente } from 'src/app/shared/class-models/orden.model';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Existencia } from 'src/app/shared/class-models/existencia.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ControlInternoExistenciaDeProductoPorTamano } from 'src/app/shared/class-models/control-interno-existencia-de-producto-por-tamano.enum';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { takeUntil } from 'rxjs/operators';
import { ExistenciaPorTamano } from 'src/app/shared/class-models/existencia-por-tamano.model';

@Component({
  selector: 'app-nueva-orden-paquete-modal',
  templateUrl: './nueva-orden-paquete-modal.component.html',
  styleUrls: ['./nueva-orden-paquete-modal.component.scss']
})
export class NuevaOrdenPaqueteModalComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  titulo: string;
  RequestState = RequestState;
  ExpresionesRegulares = ExpresionesRegulares;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;
  ingredientesExtra: Ingrediente[];
  estadoPeticionObtenerIngredientesExtra: number;
  obtenerIngredientesExtraSubscripcion: Subscription;
  paquete: Paquete;
  estadoPeticionObtenerPaquete: number;
  obtenerPaqueteSubscripcion: Subscription;
  obtenerExistenciasDeProductoPorTamanoSubscripcion: Subscription

  /* VARIABLES DEL STEP DE TAMAÑO */
  paquetePorTamanoSeleccionado: PaquetePorTamano;

  /* VARIABLES DEL STEP DE PRODUCTOS */
  alternativasProductosPorTamanoSeleccionadas: AlternativaProductosPorTamano[];

  /* VARIABLES DEL STEP DE QUITAR INGREDIENTES */
  estadoIngredientes: boolean[][];

  /* VARIABLES DEL STEP DE INGREDIENTES EXTRA */
  estadoIngredientesExtra: boolean[][];

  /* VARIABLES DEL STEP DE NOTAS */
  readonly codigosDeSeparadores: number[] = [ENTER, COMMA];

  /* VARIABLES PARA AGREGAR UNA ORDEN */
  orden: Orden = new Orden();

  /* VARIABLES CON SOCKETS PARA ACTUALIZAR LOS CAMBIOS EN LAS EXISTENCIAS  */
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private _paquetesService: PaquetesService,
    private _existenciasPorTamanoService: ExistenciasPorTamanoService,
    private _ingredientesService: IngredientesService,
    private _ordenesService: OrdenesService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NuevaOrdenPaqueteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      _idRestaurante: string,
      paquete: Paquete,
      controlInternoExistenciasDeProductosPorTamano: ControlInternoExistenciaDeProductoPorTamano[]
    },
  ) {
    this.inicializarDatosOrden();
    this.titulo = 'Agregar orden del paquete ' + this.data.paquete.nombre;
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 3;
    this.estadoPeticionObtenerPaquete = RequestState.initial;
    this.estadoPeticionObtenerIngredientesExtra = RequestState.initial;
  }

  inicializarDatosOrden() {
    this.orden.tipo = TipoOrden.Paquete;
    this.orden.datosPaquete._idPaquete = this.data.paquete._id;
    this.orden.datosPaquete.paquete._id = this.data.paquete._id;
    this.orden.datosPaquete.paquete.id = this.data.paquete.id;
    this.orden.datosPaquete.paquete.nombre = this.data.paquete.nombre;
  }

  ngOnInit(): void {
    this.disminucionExistenciasOrdenes();
    this.obtenerPaquete();
  }

  /* MÉTODO CON SOCKETS PARA ACTUALIZAR LOS CAMBIOS EN LAS EXISTENCIAS */
  disminucionExistenciasOrdenes() {
    this._ordenesService.disminucionExistenciasOrdenes()
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (existenciaPorTamano: ExistenciaPorTamano) => {
          this.paquete.alternativasProductos.forEach((alternativaProductos, posicionAlternativProductos) => {
            const posicionProducto = alternativaProductos.productos.findIndex(producto => {
              return producto._id == existenciaPorTamano._idProducto;
            })
            if (
              this.data._idRestaurante == existenciaPorTamano._idRestaurante &&
              posicionProducto != -1 &&
              this.administracionDeExistenciasHabilitado(posicionAlternativProductos, existenciaPorTamano._idProducto)) {
              const posicionExistenciaPorTamano = this.obtenerPosicionExistenciaPorTamano(<Producto>alternativaProductos.productos[posicionProducto], existenciaPorTamano._idTamano);
              if (posicionExistenciaPorTamano != -1) {
                (<Producto>alternativaProductos.productos[posicionProducto]).existenciasPorTamano[posicionExistenciaPorTamano].cantidad = existenciaPorTamano.cantidad;
                //this.cambioPaquetePorTamanoSeleccionado();
              }
            }
          })
        }
      );
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  obtenerPaquete(): void {
    this.estadoPeticionObtenerPaquete = RequestState.loading;
    this.obtenerPaqueteSubscripcion = this._paquetesService.obtenerPaquete(this.data.paquete._id).subscribe(
      (paquete: Paquete) => {
        this.paquete = paquete;
        this.orden.datosPaquete.alternativasProductosPorTamano = this.paquete.alternativasProductos.map(seccion => new AlternativaProductoPorTamano());
        this.obtenerIngredientesExtra();
        this.obtenerExistenciasDeProductosPorTamano();
        this.estadoPeticionObtenerPaquete = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerPaquete = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerIngredientesExtra(): void {
    this.estadoPeticionObtenerIngredientesExtra = RequestState.loading;
    this.obtenerIngredientesExtraSubscripcion = this._ingredientesService.obtenerIngredientesExtra().subscribe(
      (ingredientesExtra: Ingrediente[]) => {
        this.ingredientesExtra = ingredientesExtra;
        this.inicializarVariablesDeControlDelFormulario();//Las variables de control se inicializan aqui debido a que se requieren los ingredientes extra para inicializar la variable estadoIngredientesExtra
        this.estadoPeticionObtenerIngredientesExtra = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngredientesExtra = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas();
      }
    );
  }

  inicializarVariablesDeControlDelFormulario(): void {
    this.alternativasProductosPorTamanoSeleccionadas = this.paquete.alternativasProductos.map(seccion => null);
    this.estadoIngredientes = this.paquete.alternativasProductos.map(seccion => []);
    this.estadoIngredientesExtra = this.paquete.alternativasProductos.map(seccion => []);
  }

  async obtenerExistenciasDeProductosPorTamano() {
    for (let posicionSeccion = 0; posicionSeccion < this.paquete.alternativasProductos.length; posicionSeccion++) {
      for (let posicionAlternativaProducto = 0; posicionAlternativaProducto < this.paquete.alternativasProductos[posicionSeccion].productos.length; posicionAlternativaProducto++) {
        try {
          this.paquete.alternativasProductos[posicionSeccion].productos[posicionAlternativaProducto] = await this.obtenerExistenciasDeProductoPorTamano((<Producto>this.paquete.alternativasProductos[posicionSeccion].productos[posicionAlternativaProducto])._id);
        } catch (error) {
          let existencia: Existencia = new Existencia();
          existencia._id = null;
          existencia.activo = false;
          (<Producto>this.paquete.alternativasProductos[posicionSeccion].productos[posicionAlternativaProducto]).existencia = existencia;
        }
      }
    }
    this.incrementarContadorPeticionesFinalizadas();
  }

  obtenerExistenciasDeProductoPorTamano(_idProducto: string): Promise<Producto> {
    return new Promise<any>((resolve, reject) => {
      this.obtenerExistenciasDeProductoPorTamanoSubscripcion = this._existenciasPorTamanoService.obtenerExistenciasDeProductoPorTamano(this.data._idRestaurante, _idProducto).subscribe(
        (producto: Producto) => {
          resolve(producto);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  /* MÉTODOS DEL STEP DE TAMAÑO */
  cambioPaquetePorTamanoSeleccionado(): void {
    this.inicializarVariablesDeControlDelFormulario();
    this.orden.precioPorTamano.precio = <number>this.paquetePorTamanoSeleccionado.precio;
    this.orden.precioPorTamano.tamano = this.paquetePorTamanoSeleccionado.tamano;
    this.orden.precioPorTamano._idTamano = this.paquetePorTamanoSeleccionado._idTamano;
  }

  /* VARIABLES DEL STEP DE PRODUCTOS */
  obtenerProductoPorSeccion(posicionSeccion: number, _idProducto: string): Producto {
    const posicionProducto = (<Producto[]>this.paquete.alternativasProductos[posicionSeccion].productos).findIndex(producto => producto._id == _idProducto);
    if (posicionProducto != -1) {
      return <Producto>this.paquete.alternativasProductos[posicionSeccion].productos[posicionProducto];
    } else {
      return null;
    }
  }

  cambioAlternativaProductoPorTamano(posicionAlternativaProductoPorTamano: number): void {
    /* Las dos primeras lineas se realizaron con el fin de poder utilizar la variable alternativasProductosPorTamanoSeleccionadas[posicionAlternativaProductoPorTamano].ingredientes
       en el control de los ingredientes de la sección "Quitar ingredientes", pues en primera instancia esta variable no cuenta con estos datos ya que no se obtienen en la petición. */
    const producto = this.obtenerProductoPorSeccion(posicionAlternativaProductoPorTamano, this.alternativasProductosPorTamanoSeleccionadas[posicionAlternativaProductoPorTamano]._idProducto);
    this.alternativasProductosPorTamanoSeleccionadas[posicionAlternativaProductoPorTamano].producto.ingredientes = producto.ingredientes;
    this.estadoIngredientes[posicionAlternativaProductoPorTamano] = (<Ingrediente[]>producto.ingredientes).map(ingrediente => false);
    this.estadoIngredientesExtra[posicionAlternativaProductoPorTamano] = this.ingredientesExtra.map(ingredienteExra => false);
    this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].producto = this.alternativasProductosPorTamanoSeleccionadas[posicionAlternativaProductoPorTamano].producto;
    this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano]._idProducto = this.alternativasProductosPorTamanoSeleccionadas[posicionAlternativaProductoPorTamano]._idProducto;
    this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].tamano = this.alternativasProductosPorTamanoSeleccionadas[posicionAlternativaProductoPorTamano].tamano;
    this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano]._idTamano = this.alternativasProductosPorTamanoSeleccionadas[posicionAlternativaProductoPorTamano]._idTamano;
    this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].notas = [];
    this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].quitarIngredientes = [];
    this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].agregarIngredientes = [];
  }

  administracionDeExistenciasHabilitado(posicionSeccion: number, _idProducto: string): boolean {
    const producto: Producto = this.obtenerProductoPorSeccion(posicionSeccion, _idProducto);
    this.paquetePorTamanoSeleccionado.alternativasProductosPorTamano
    this.paquete.alternativasProductos
    if (producto.existencia != null && producto.existencia.activo == true) {
      return true;
    } else {
      return false;
    }
  }

  obtenerPosicionExistenciaPorTamano(producto: Producto, _idTamano: string): number {
    return producto.existenciasPorTamano.findIndex(existenciaPorTamano => {
      return existenciaPorTamano._idTamano == _idTamano;
    });
  }

  obtenerExistenciasUtilizadasProductoPorTamano(_idProducto: string, _idTamano: string): number {
    const posicionControlInternoExistenciaDeProductoPorTamano = this.data.controlInternoExistenciasDeProductosPorTamano.findIndex(controlInternoExistenciaDeProductoPorTamano => {
      return controlInternoExistenciaDeProductoPorTamano._idProducto == _idProducto && controlInternoExistenciaDeProductoPorTamano._idTamano == _idTamano;
    });
    if (posicionControlInternoExistenciaDeProductoPorTamano != -1) {
      return this.data.controlInternoExistenciasDeProductosPorTamano[posicionControlInternoExistenciaDeProductoPorTamano].cantidad;
    } else {
      return 0;
    }
  }

  productoPorTamanoConExistencias(posicionSeccion: number, _idProducto: string, _idTamano: string): boolean {
    const producto: Producto = this.obtenerProductoPorSeccion(posicionSeccion, _idProducto);
    if (this.administracionDeExistenciasHabilitado(posicionSeccion, _idProducto)) {
      const posicionExistenciaPorTamano = this.obtenerPosicionExistenciaPorTamano(producto, _idTamano);
      if (posicionExistenciaPorTamano != -1) {
        return (producto.existenciasPorTamano[posicionExistenciaPorTamano].cantidad - this.obtenerExistenciasUtilizadasProductoPorTamano(_idProducto, _idTamano)) > 0;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  obtenerExistenciaProductoPorTamano(posicionSeccion: number, _idProducto: string, _idTamano: string): number {
    const producto: Producto = this.obtenerProductoPorSeccion(posicionSeccion, _idProducto);
    const posicionExistenciaPorTamano = this.obtenerPosicionExistenciaPorTamano(producto, _idTamano);
    if (posicionExistenciaPorTamano != -1) {
      return producto.existenciasPorTamano[posicionExistenciaPorTamano].cantidad - this.obtenerExistenciasUtilizadasProductoPorTamano(_idProducto, _idTamano);
    } else {
      return 0;
    }
  }

  /* MÉTODOS DEL STEP DE QUITAR INGREDIENTES */
  cambioEstadoIngrediente(posicionAlternativaProductoPorTamano: number, posicionIngrediente: number, ingrediente: Ingrediente): void {
    if (this.estadoIngredientes[posicionAlternativaProductoPorTamano][posicionIngrediente]) (<Ingrediente[]>this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].quitarIngredientes).push(ingrediente);
    else {
      const posicionIngredienteSeleccionado = (<Ingrediente[]>this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].quitarIngredientes).indexOf(ingrediente, 0);
      if (posicionIngredienteSeleccionado > -1) {
        this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].quitarIngredientes.splice(posicionIngredienteSeleccionado, 1);
      }
    }
  }

  /* MÉTODOS DEL STEP DE INGREDIENTES EXTRA */
  cambioEstadoIngredienteExtra(posicionAlternativaProductoPorTamano: number, posicionIngredienteExtra: number, ingredienteExtra: Ingrediente): void {
    if (this.estadoIngredientesExtra[posicionAlternativaProductoPorTamano][posicionIngredienteExtra]) {
      let agregarIngrediente: AgregarIngrediente = new AgregarIngrediente();
      agregarIngrediente.ingrediente = ingredienteExtra;
      agregarIngrediente._idIngrediente = ingredienteExtra._id;
      agregarIngrediente.precio = <number>ingredienteExtra.precio;
      this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].agregarIngredientes.push(agregarIngrediente);
    }
    else {
      const posicionIngredienteExtraSeleccionado = this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].agregarIngredientes.findIndex(agregarIngrediente => {
        return agregarIngrediente._idIngrediente == ingredienteExtra._id;
      });
      if (posicionIngredienteExtraSeleccionado > -1) {
        this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].agregarIngredientes.splice(posicionIngredienteExtraSeleccionado, 1);
      }
    }
  }

  /* MÉTODOS DEL STEP DE NOTAS */
  agregarNota(posicionAlternativaProductoPorTamano: number, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (value != null && value != '') {
      this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].notas.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  eliminarNota(posicionAlternativaProductoPorTamano: number, nota: string): void {
    const posicionNota = this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].notas.indexOf(nota);
    if (posicionNota != -1) {
      this.orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativaProductoPorTamano].notas.splice(posicionNota, 1);
    }
  }

  /* MÉTODOS PARA AGREGAR UNA ORDEN */
  agregarOrden(): void {
    this.modal.close(this.prepararDatosOrden());
  }

  prepararDatosOrden(): Orden {
    let orden: Orden = new Orden();
    orden.tipo = this.orden.tipo;
    orden.cantidad = parseInt(this.orden.cantidad.toString());
    orden.datosPaquete._idPaquete = this.orden.datosPaquete._idPaquete;
    orden.datosPaquete.paquete = this.orden.datosPaquete.paquete;
    this.orden.datosPaquete.alternativasProductosPorTamano.forEach(alternativaProductoPorTamano => {
      let copiaAlternativaProductoPorTamano = new AlternativaProductoPorTamano();
      copiaAlternativaProductoPorTamano._idProducto = alternativaProductoPorTamano._idProducto;
      copiaAlternativaProductoPorTamano.producto = alternativaProductoPorTamano.producto;
      copiaAlternativaProductoPorTamano._idTamano = alternativaProductoPorTamano._idTamano;
      copiaAlternativaProductoPorTamano.tamano = alternativaProductoPorTamano.tamano;
      copiaAlternativaProductoPorTamano.quitarIngredientes = (<Ingrediente[]>alternativaProductoPorTamano.quitarIngredientes).sort((ingrediente1, ingrediente2) => {
        if (ingrediente1._id > ingrediente2._id) return 1;
        if (ingrediente1._id < ingrediente2._id) return -1;
        return 0;
      });
      copiaAlternativaProductoPorTamano.agregarIngredientes = alternativaProductoPorTamano.agregarIngredientes.sort((agregarIngrediente1, agregarIngrediente2) => {
        if (agregarIngrediente1._idIngrediente > agregarIngrediente2._idIngrediente) return 1;
        if (agregarIngrediente1._idIngrediente < agregarIngrediente2._idIngrediente) return -1;
        return 0;
      });
      copiaAlternativaProductoPorTamano.notas = alternativaProductoPorTamano.notas.sort();
      orden.datosPaquete.alternativasProductosPorTamano.push(copiaAlternativaProductoPorTamano);
    })
    orden.precioPorTamano = this.orden.precioPorTamano;
    orden.precioUnitario = this.obtenerPrecioUnitarioOrden();
    return orden
  }

  obtenerPrecioUnitarioOrden(): number {
    let precioUnitario = this.orden.precioPorTamano.precio;
    this.orden.datosPaquete.alternativasProductosPorTamano.forEach(alternativaProductoPorTamano => {
      alternativaProductoPorTamano.agregarIngredientes.forEach(agregarIngrediente => {
        precioUnitario += agregarIngrediente.precio;
      })
    });
    return precioUnitario;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (this.obtenerIngredientesExtraSubscripcion) this.obtenerIngredientesExtraSubscripcion.unsubscribe();
    if (this.obtenerPaqueteSubscripcion) this.obtenerPaqueteSubscripcion.unsubscribe();
    if (this.obtenerExistenciasDeProductoPorTamanoSubscripcion) this.obtenerExistenciasDeProductoPorTamanoSubscripcion.unsubscribe();
  }
}
