import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Insumo } from 'src/app/shared/class-models/insumo.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { InsumosService } from 'src/app/core/servicios/insumos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoDeInsumo } from 'src/app/shared/enums/tipo-de-insumo.enum';
import { AltaInsumoComponent } from './alta-insumo/alta-insumo.component';
import { ActualizacionInsumoComponent } from './actualizacion-insumo/actualizacion-insumo.component';

@Component({
  selector: 'app-insumos',
  templateUrl: './insumos.component.html',
  styleUrls: ['./insumos.component.scss']
})
export class InsumosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Insumo>;
  filtro: string = '';
  estadoPeticionObtenerInsumos: number;
  RequestState = RequestState;
  obtenerInsumosSubscripcion: Subscription;
  eliminarInsumoSubscripcion: Subscription;
  columnas = ['nombre', 'tipo', 'unidadDeMedida', 'opciones'];

  constructor(
    private _insumosService: InsumosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog) {
    this.estadoPeticionObtenerInsumos = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerInsumos();
  }

  obtenerInsumos(): void {
    this.estadoPeticionObtenerInsumos = RequestState.loading;
    this.obtenerInsumosSubscripcion = this._insumosService.obtenerInsumos().subscribe(
      (insumos: Insumo[]) => {
        this.inicializarInsumos(insumos);
        this.estadoPeticionObtenerInsumos = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerInsumos = RequestState.error;
      }
    );
  }

  inicializarInsumos(insumos: Insumo[]): void {
    this.dataSource = new MatTableDataSource<Insumo>(insumos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (insumo: Insumo, filtro: string) => {
      return this.obtenerNombreDeInsumo(insumo).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerTipoDeInsumo(insumo.tipo).trim().toLowerCase().indexOf(filtro) !== -1 || 
        (insumo.unidadDeMedida.nombre + ' (' + insumo.unidadDeMedida.abreviacion + ')').trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarInsumo(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerNombreDeInsumo(insumo: Insumo): string {
    switch(insumo.tipo) {
      case TipoDeInsumo.Ingrediente: return insumo.ingrediente.nombre;
      case TipoDeInsumo.Producto: return insumo.producto.nombre;
      case TipoDeInsumo.Otro: return insumo.nombre;
    }
  }

  obtenerTipoDeInsumo(tipoDeInsumo: number): string {
    switch(tipoDeInsumo) {
      case TipoDeInsumo.Ingrediente: return 'Ingrediente';
      case TipoDeInsumo.Producto: return 'Producto';
      case TipoDeInsumo.Otro: return 'Otro';
    }
  }

  agregarInsumo(): void {
    const dialogRef = this.dialog.open(AltaInsumoComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (insumo: Insumo) => {
        if (insumo) {
          this._alertasService.alertaExitoSinConfirmacion('Insumo agregado exitosamente', 'El insumo ' + this.obtenerNombreDeInsumo(insumo) + ' ha sido agregado con éxito');
          this.dataSource.data.push(insumo);
          const insumos: Insumo[] = this.dataSource.data;
          this.inicializarInsumos(insumos);
          this.buscarInsumo();
        }
      }
    );
  }

  actualizarInsumo(insumo: Insumo): void {
    const dialogRef = this.dialog.open(ActualizacionInsumoComponent, { disableClose: true, data: insumo });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
          if (resultado == true) {
            this._alertasService.alertaExitoSinConfirmacion('Insumo actualizado exitosamente', 'El insumo ' + this.obtenerNombreDeInsumo(insumo) + ' ha sido actualizado con éxito.');
            this.buscarInsumo();
          }
      }
    );
  }

  eliminarInsumo(insumoEliminar: Insumo): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el insumo ' + this.obtenerNombreDeInsumo(insumoEliminar) + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarInsumoSubscripcion = this._insumosService.eliminarInsumo(insumoEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionInsumo = this.dataSource.data.findIndex((insumo: Insumo) => {
                return insumo._id === insumoEliminar._id;
              });
              let insumosActualizados: Insumo[] = this.dataSource.data;
              if (posicionInsumo != -1) {
                insumosActualizados.splice(posicionInsumo, 1);
              }
              this.inicializarInsumos(insumosActualizados);
              this.buscarInsumo();
              this._alertasService.alertaExitoSinConfirmacion('Insumo eliminado exitosamente', 'El insumo ' + this.obtenerNombreDeInsumo(insumoEliminar) + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerInsumosSubscripcion) this.obtenerInsumosSubscripcion.unsubscribe();
    if (this.eliminarInsumoSubscripcion) this.eliminarInsumoSubscripcion.unsubscribe();
  }
}
