import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Comanda } from 'src/app/shared/class-models/comanda.model';

@Injectable({
    providedIn: 'root'
})
export class ComandasService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseAreas = '/areas';
    private rutaBaseElementosPorArea = '/elementos-por-area';
    private rutaBaseComandas = '/comandas';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerComandas(_idRestaurante: string, _idArea: string, _idElementoPorArea: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}`, AppSettings.Options);
    }

    obtenerComanda(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}`, AppSettings.Options);
    }

    /* POST */
    agregarComanda(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, comanda: Comanda): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}`, JSON.stringify(comanda), AppSettings.Options);
    }

    /* PUT */
    actualizarComanda(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, comanda: Comanda): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}`, JSON.stringify(comanda), AppSettings.Options);
    }

    /* PATCH */
    eliminarComanda(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}`, AppSettings.Options);
    }
}