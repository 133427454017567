import {Component, Input, OnInit} from '@angular/core';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: []
})
export class CashierComponent implements OnInit {
  @Input() tipoDeUsuario: number;
  cajaDeUsuario: CajaDeUsuario = null;
  tabSeleccionada: number = 0;
  
  constructor() {}

  ngOnInit(): void {}

  verCajaDeUsuario(cajaDeUsuario: CajaDeUsuario): void {
    this.cajaDeUsuario = cajaDeUsuario;
    this.tabSeleccionada = 2;
  }
}
