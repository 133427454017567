import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppSettings} from 'src/app/configs/app-settings.config';

import {Cliente} from '../../shared/models/cliente';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  public clientes: Cliente[];
  private error = true; // Para intercalar entre errores y "peticiones" exitosas.

  constructor(private httpClient: HttpClient) {
  }

  addClient(cliente: Cliente): Observable<any> {
    return new Observable(
      observer => {
        setTimeout(
          () => {
            if (this.error) {
              observer.error();
            } else {
              observer.next();
            }
            this.error = !this.error;
          }, 1000
        );
      }
    );
  }

  editClient(cliente: Cliente, id: string): Observable<any> {
    cliente.id = id;
    const body = {
      client: cliente
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/cliente.php`, JSON.stringify(body), AppSettings.Options);
  }

  deleteClient(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/cliente.php?id=${id}`, AppSettings.Options);
  }

  getClient(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cliente.php?id=${id}`, AppSettings.Options)
      .pipe(map((res: Cliente[]) => {
        this.clientes = res;
        const fl = res.filter(cliente => cliente.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  getClients(criteria, term): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/clientes/clientes.php?criterio=${criteria}&term=${term}`, AppSettings.Options);
  }

  postClient(client: Cliente): Observable<any> {
    const body = {
      client
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/cliente.php`, JSON.stringify(body), AppSettings.Options);
  }
}
