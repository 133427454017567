import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BillService} from '../../core/services/bill.service';
import {Cuenta} from '../../shared/models/cuenta';
import {Orden} from '../../shared/models/orden';
import {AppSettings} from '../../configs/app-settings.config';
import { GrupoOrdenes } from 'src/app/shared/models/grupo-ordenes';

@Component({
  selector: 'app-bill-printer',
  templateUrl: './bill-printer.component.html',
  styleUrls: ['./bill-printer.component.scss']
})
export class BillPrinterComponent implements OnInit {
  cuenta: Cuenta;
  ordenes: Orden[];
  agregarIngredientes: any[];
  AppSettings = AppSettings;
  tipoImpresion: number;
  grupoOrdenes: GrupoOrdenes;
  ordenPrincipal: Orden[];
  ordenesAgrupadas: Orden[][];

  constructor(public billService: BillService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.billService.deteccionCambiosTipoImpresion.subscribe(tipoImpresion => {
      this.tipoImpresion = tipoImpresion;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambiosGrupoOrdenes.subscribe(grupoOrdenes => {
      this.grupoOrdenes = grupoOrdenes;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambiosOrdenPrincipal.subscribe(ordenPrincipal => {
      this.ordenPrincipal = ordenPrincipal;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambiosOrdenesAgrupadas.subscribe(ordenesAgrupadas => {
      this.ordenesAgrupadas = ordenesAgrupadas;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambios.subscribe(cuenta => {
      this.cuenta = cuenta;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambiosEnOrdenes.subscribe(ordenes => {
      this.ordenes = ordenes;
      this.agregarIngredientes = [];
      if (this.ordenes !== null) {
        this.ordenes.forEach((orden, index) => {
          this.agregarIngredientes.push([]);
          if (orden.datosProducto !== null) {
            if (orden.datosProducto.agregarIngredientes !== null && orden.datosProducto.agregarIngredientes !== undefined) {
              this.agregarIngredientes[index] = orden.datosProducto.agregarIngredientes;
            }
          } else {
            orden.datosPaquete.alternativasProductosPorTamano.forEach((alternativa) => {
              if (alternativa.agregarIngredientes !== null && alternativa.agregarIngredientes !== undefined) {
                this.agregarIngredientes[index] = this.agregarIngredientes[index].concat(alternativa.agregarIngredientes);
              }
            });
          }
        });
      }
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
  }
}
