import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-eliminar-orden-modal',
  templateUrl: './eliminar-orden-modal.component.html',
  styleUrls: ['./eliminar-orden-modal.component.scss']
})
export class EliminarOrdenModalComponent implements OnInit {
  titulo = 'Eliminar orden';
  cantidadEliminar: number = 1;
  opcionesCantidadEliminar: number[] = [];

  constructor(
    public referenciaModal: MatDialogRef<EliminarOrdenModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    for (let posicion = 1; posicion <= this.data; posicion++){
      this.opcionesCantidadEliminar.push(posicion);
    }
  }

  ngOnInit() {
  }

  eliminarOrden() {
    this.referenciaModal.close(this.cantidadEliminar)
  }

  cerrar(): void {
    this.referenciaModal.close()
  }
}
