import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdministracionInsumosRoutes } from './administracion-insumos-routing.module';
import { AdministracionInsumosComponent } from './administracion-insumos.component';
import { GastosDeInsumosComponent } from './gastos-de-insumos/gastos-de-insumos.component';
import { InsumosComponent } from './insumos/insumos.component';
import { UnidadesDeMedidaComponent } from './unidades-de-medida/unidades-de-medida.component';
import { AltaUnidadDeMedidaComponent } from './unidades-de-medida/alta-unidad-de-medida/alta-unidad-de-medida.component';
import { ActualizacionUnidadDeMedidaComponent } from './unidades-de-medida/actualizacion-unidad-de-medida/actualizacion-unidad-de-medida.component';
import { AltaInsumoComponent } from './insumos/alta-insumo/alta-insumo.component';
import { ActualizacionInsumoComponent } from './insumos/actualizacion-insumo/actualizacion-insumo.component';
import { AltaGastoDeInsumoComponent } from './gastos-de-insumos/alta-gasto-de-insumo/alta-gasto-de-insumo.component';
import { ActualizacionGastoDeInsumoComponent } from './gastos-de-insumos/actualizacion-gasto-de-insumo/actualizacion-gasto-de-insumo.component';
import { MatTabsModule, MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatSelectModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatTableModule, MatCheckboxModule, MatStepperModule, MatChipsModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';


@NgModule({
  declarations: [
    AdministracionInsumosComponent,
    GastosDeInsumosComponent,
    InsumosComponent,
    UnidadesDeMedidaComponent,
    AltaUnidadDeMedidaComponent,
    ActualizacionUnidadDeMedidaComponent,
    AltaInsumoComponent,
    ActualizacionInsumoComponent,
    AltaGastoDeInsumoComponent,
    ActualizacionGastoDeInsumoComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(AdministracionInsumosRoutes),
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatStepperModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule
  ],
  providers: [
    FechaPipe
  ],
})
export class AdministracionInsumosModule { }
