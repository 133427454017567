import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../configs/app-settings.config';
import { HttpClient } from '@angular/common/http';
import { Tamano } from 'src/app/shared/class-models/tamano.model';

@Injectable({
    providedIn: 'root'
})
export class TamanosService {
    private rutaBaseTamanos = '/tamanos';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerTamanos(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseTamanos}`, AppSettings.Options);
    }

    obtenerTamano(_idTamano: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseTamanos}/${_idTamano}`, AppSettings.Options);
    }

    /* POST */
    agregarTamano(tamano: Tamano) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseTamanos}`, JSON.stringify(tamano), AppSettings.Options);
    }

    agregarTamanoPorDefecto(tamano: Tamano) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseTamanos}/por-defecto`, JSON.stringify(tamano), AppSettings.Options);
    }

    /* PUT */
    actualizarTamano(_idTamano: string, tamano: Tamano) {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseTamanos}/${_idTamano}`, JSON.stringify(tamano), AppSettings.Options);
    }

    /* PATCH */
    eliminarTamano(_idTamano: string) {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseTamanos}/${_idTamano}`, AppSettings.Options);
    }
}
