import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { GastoDeInsumo } from 'src/app/shared/class-models/gasto-de-insumo.model';

@Injectable({
    providedIn: 'root'
})
export class GastosDeInsumosService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseGastosDeInsumos = '/gastos-de-insumos';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerGastosDeInsumos(_idRestaurante: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosDeInsumos}`, AppSettings.Options);
    }

    obtenerGastoDeInsumo(_idRestaurante: string, _idGastoDeInsumo: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosDeInsumos}/${_idGastoDeInsumo}`, AppSettings.Options);
    }

    /* POST */
    agregarGastoDeInsumo(_idRestaurante: string, gastoDeInsumo: GastoDeInsumo): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosDeInsumos}`, JSON.stringify(gastoDeInsumo), AppSettings.Options);
    }

    /* PUT */
    actualizarGastoDeInsumo(_idRestaurante: string, _idGastoDeInsumo: string, gastoDeInsumo: GastoDeInsumo): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosDeInsumos}/${_idGastoDeInsumo}`, JSON.stringify(gastoDeInsumo), AppSettings.Options);
    }

    /* PATCH */
    eliminarGastoDeInsumo(_idRestaurante: string, _idGastoDeInsumo: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosDeInsumos}/${_idGastoDeInsumo}`, AppSettings.Options);
    }
}