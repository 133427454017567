import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { Subscription } from 'rxjs';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-editar-tamano-configuracion',
  templateUrl: './editar-tamano-configuracion.component.html',
  styleUrls: ['./editar-tamano-configuracion.component.scss']
})
export class EditarTamanoConfiguracionComponent implements OnInit, OnDestroy {
  titulo = 'Actualizar tamaño';
  RequestState = RequestState;
  tamano: Tamano;

  /* VARIABLES PARA OBTENER EL TAMAÑO */
  obtenerTamanoSubscription: Subscription;
  estadoPeticionObtenerTamano: number;

  /* VARIABLES PARA ACTUALIZAR EL TAMAÑO */
  ExpresionesRegulares = ExpresionesRegulares;
  actualizarTamanoSubscription: Subscription;
  estadoPeticionActualizarTamano: number;

  constructor(
    private _tamanosService: TamanosService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Tamano,
    public dialogRef: MatDialogRef<EditarTamanoConfiguracionComponent>
    ) {
    this.estadoPeticionObtenerTamano = RequestState.initial;
    this.estadoPeticionActualizarTamano = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerTamano();
  }

  obtenerTamano() {
    this.estadoPeticionObtenerTamano = RequestState.loading;
    this.obtenerTamanoSubscription = this._tamanosService.obtenerTamano(this.data._id).subscribe(
      (tamano: Tamano) => {
        this.tamano = tamano;
        this.estadoPeticionObtenerTamano = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerTamano = RequestState.error;
        this.dialogRef.close();
      }
    );
  }

  actualizarTamano() {
    this.estadoPeticionActualizarTamano = RequestState.loading;
    this.actualizarTamanoSubscription = this._tamanosService.actualizarTamano(this.data._id, this.tamano).subscribe(
      (tamano: Tamano) => {
        this.estadoPeticionActualizarTamano = RequestState.success;
        this.actualizarTamanoOriginal(tamano)
        this.dialogRef.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarTamano = RequestState.error;
      }
    );
  }
  
  actualizarTamanoOriginal(tamano: Tamano): void {
    this.data.nombre = tamano.nombre;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerTamanoSubscription) this.obtenerTamanoSubscription.unsubscribe();
    if(this.actualizarTamanoSubscription) this.actualizarTamanoSubscription.unsubscribe();
  }
}
