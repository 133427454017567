import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {

  constructor(private httpClient: HttpClient) {
  }

  nuevaCaja(idSucursal: string, montoInicial: number): Observable<any> {
    const body = {
      idSucursal,
      montoInicial
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/caja.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarCaja(id: string, total: number): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/caja.php?id=${id}&total=${total}`, AppSettings.Options);
  }

  obtenerCajasAbiertas(idSucursal: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cajas/cajas.php?idSucursal=${idSucursal}`, AppSettings.Options);
  }

  obtenerCuentasDeUnaCaja(idCaja: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cajas/cuentas-de-una-caja.php?idCaja=${idCaja}`, AppSettings.Options);
  }

  obtenerCajaDeUnUsuario(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cajas/caja-de-un-usuario.php`, AppSettings.Options);
  }

  obtenerCajaDeUnUsuarioEnUnaSucursal(idSucursal: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/cajas/caja-de-un-usuario-en-una-sucursal.php?idSucursal=${idSucursal}`,
      AppSettings.Options);
  }
}
