import {MatButtonModule} from '@angular/material/button';
import {
  MatBadgeModule,
  MatCardModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
  MatProgressBarModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {AreasComponent} from './areas.component';
// Components.
import {AreaCardComponent} from './components/area-card/area-card.component';
// Pages.
import {NewAreaComponent} from './pages/new-area/new-area.component';
// Routes.
import {AreasRoutes} from './areas.routes';

@NgModule({
  declarations: [
    AreaCardComponent,
    AreasComponent,
    NewAreaComponent
  ],
  imports: [
    RouterModule.forRoot(AreasRoutes),
    CommonModule,
    FormsModule,
    ImageCropperModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    MatTooltipModule,
    MatProgressBarModule
  ],
  exports: [
    AreaCardComponent,
    AreasComponent,
    NewAreaComponent
  ]
})
export class AreasModule {
}
