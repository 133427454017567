import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class UbicacionService {
  private rutaBaseUbicacion = '/ubicacion';

  constructor(private httpClient: HttpClient) { }

  /* GET */
  obtenerEstados(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUbicacion}/estados`, AppSettings.Options);
  }

  obtenerCiudades(idEstado: number): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUbicacion}/estados/${idEstado}/ciudades`, AppSettings.Options);
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUbicacion}/estados/${idEstado}?codigoPostal=${codigoPostal}`,
      AppSettings.Options);
  }

  /* POST */

  /* PUT */

  /* PATCH */
}
