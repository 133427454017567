import { Component, OnInit, Inject } from '@angular/core';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { GruposDeOrdenesService } from 'src/app/core/servicios/grupos-de-ordenes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Orden, AgregarIngrediente, AlternativaProductoPorTamano } from 'src/app/shared/class-models/orden.model';
import { ControlInternoExistenciaDeProductoPorTamano } from 'src/app/shared/class-models/control-interno-existencia-de-producto-por-tamano.enum';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { EliminarOrdenModalComponent } from '../eliminar-orden-modal/eliminar-orden-modal.component';

@Component({
  selector: 'app-agregar-pedido-modal',
  templateUrl: './agregar-pedido-modal.component.html',
  styleUrls: ['./agregar-pedido-modal.component.scss']
})
export class AgregarPedidoModalComponent implements OnInit {
  titulo: string = 'Confirmar pedido';
  RequestState = RequestState;
  estadoPeticionAgregarPedido: number;
  ordenesAgrupadas: Orden[];
  TipoOrden = TipoOrden;

  constructor(
    private _ordenesService: OrdenesService,
    private _grupoDeOrdenesService: GruposDeOrdenesService,
    private _alertasService: AlertasService,
    public referenciaModal: MatDialogRef<AgregarPedidoModalComponent>,
    public modal: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      _idRestaurante: string,
      _idArea: string,
      _idElementoPorArea: string,
      _idComanda: string,
      ordenes: Orden[],
      controlInternoExistenciasDeProductosPorTamano: ControlInternoExistenciaDeProductoPorTamano[]
    }
  ) {
    this.estadoPeticionAgregarPedido = RequestState.initial;
    this.inicializarOrdenesAgrupadas();
  }

  inicializarOrdenesAgrupadas(): void {
    this.ordenesAgrupadas = [];
    this.data.ordenes.forEach(orden => {
      const posicionOrdenAgrupada = this.ordenesAgrupadas.findIndex(ordenAgrupada => {
        if (ordenAgrupada.tipo == orden.tipo && ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano) {
          switch (ordenAgrupada.tipo) {
            case TipoOrden.Producto:
              return ordenAgrupada.datosProducto._idProducto == orden.datosProducto._idProducto;
            case TipoOrden.Paquete:
              return ordenAgrupada.datosPaquete._idPaquete == orden.datosPaquete._idPaquete;
          }
        } else {
          return false;
        }
      })
      if (posicionOrdenAgrupada != -1) {
        (<number>this.ordenesAgrupadas[posicionOrdenAgrupada].cantidad) += <number>orden.cantidad;
      } else {
        let ordenAgrupada = new Orden();
        ordenAgrupada.tipo = orden.tipo;
        ordenAgrupada.cantidad = orden.cantidad;
        ordenAgrupada.precioPorTamano._idTamano = orden.precioPorTamano._idTamano;
        ordenAgrupada.precioPorTamano.tamano = orden.precioPorTamano.tamano;
        switch (ordenAgrupada.tipo) {
          case TipoOrden.Producto:
            ordenAgrupada.datosProducto._idProducto = orden.datosProducto._idProducto;
            ordenAgrupada.datosProducto.producto = orden.datosProducto.producto;
            break;
          case TipoOrden.Paquete:
            ordenAgrupada.datosPaquete._idPaquete = orden.datosPaquete._idPaquete;
            ordenAgrupada.datosPaquete.paquete = orden.datosPaquete.paquete;
            break;
        }
        this.ordenesAgrupadas.push(ordenAgrupada);
      }
    })
  }

  ngOnInit(): void {
  }

  obtenerOrdenesDeOrdenAgrupada(ordenAgrupada: Orden): Orden[] {
    return this.data.ordenes.filter(orden => {
      if (ordenAgrupada.tipo == orden.tipo && ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano) {
        switch (ordenAgrupada.tipo) {
          case TipoOrden.Producto:
            return ordenAgrupada.datosProducto._idProducto == orden.datosProducto._idProducto;
          case TipoOrden.Paquete:
            return ordenAgrupada.datosPaquete._idPaquete == orden.datosPaquete._idPaquete;
        }
      } else {
        return false;
      }
    })
  }

  async agregarPedido() {
    this.estadoPeticionAgregarPedido = RequestState.loading;
    try {
      let contadorDeOrdenesFallidas: number = 0;
      const grupoDeOrdenes = await this.agregarGrupoDeOrdenes();
      for (let posicionOrden = 0; posicionOrden < this.data.ordenes.length; posicionOrden++) {
        for (let cantidadOrdenes = 0; cantidadOrdenes < this.data.ordenes[posicionOrden].cantidad; cantidadOrdenes++) {
          const result: { error: boolean, titulo?: string, detalles?: string } = await this.agregarOrden(grupoDeOrdenes._id, this.prepararDatosOrden(this.data.ordenes[posicionOrden]));
          if (result.error) {
            contadorDeOrdenesFallidas++;
            switch (this.data.ordenes[posicionOrden].tipo) {
              case TipoOrden.Producto:
                this.disminuirExistenciasUtilizadasProductoPorTamano(this.data.ordenes[posicionOrden].datosProducto._idProducto, this.data.ordenes[posicionOrden].precioPorTamano._idTamano, 1);
                break;
              case TipoOrden.Paquete:
                this.data.ordenes[posicionOrden].datosPaquete.alternativasProductosPorTamano.forEach(alternativaProductoPorTamano => {
                  this.disminuirExistenciasUtilizadasProductoPorTamano(alternativaProductoPorTamano._idProducto, alternativaProductoPorTamano._idTamano, 1);
                })
                break;
            }
          }
        }
      }
      this._grupoDeOrdenesService
        .actualizarEstadoEnCocinaGrupoDeOrdenes(this.data._idRestaurante, this.data._idArea, this.data._idElementoPorArea, this.data._idComanda, grupoDeOrdenes._id)
        .subscribe(
          (resultado: any[]) => {
          }
        );
      this.estadoPeticionAgregarPedido = RequestState.success;
      this.referenciaModal.close({grupoDeOrdenes, contadorDeOrdenesFallidas})
    } catch (error) {
      this._alertasService.alertaErrorSinConfirmacion('No se ha podido levantar el pedido', 'Ha ocurrido un error al levantar el pedido, favor de intentarlo mas tarde');
      this.estadoPeticionAgregarPedido = RequestState.error;
    }
  }

  agregarGrupoDeOrdenes(): Promise<GrupoDeOrdenes> {
    return new Promise((resolve, reject) => {
      this._grupoDeOrdenesService.agregarGrupoDeOrdenes(this.data._idRestaurante, this.data._idArea, this.data._idElementoPorArea, this.data._idComanda).subscribe(
        (grupoDeOrdenes: GrupoDeOrdenes) => {
          resolve(grupoDeOrdenes);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  prepararDatosOrden(orden: Orden): Orden {
    let copiaOrden = new Orden();
    copiaOrden.tipo = orden.tipo;
    copiaOrden.cantidad = 1;
    switch (orden.tipo) {
      case TipoOrden.Producto:
        copiaOrden.datosPaquete.paquete = undefined;
        copiaOrden.datosProducto._idProducto = orden.datosProducto._idProducto;
        copiaOrden.datosProducto.producto = undefined;
        copiaOrden.datosProducto.quitarIngredientes = (<Ingrediente[]>orden.datosProducto.quitarIngredientes).map(ingrediente => ingrediente._id);
        copiaOrden.datosProducto.agregarIngredientes = orden.datosProducto.agregarIngredientes.map(agregarIngrediente => {
          let copiaAgregarIngrediente = new AgregarIngrediente();
          copiaAgregarIngrediente._idIngrediente = agregarIngrediente._idIngrediente;
          copiaAgregarIngrediente.ingrediente = undefined;
          copiaAgregarIngrediente.precio = agregarIngrediente.precio;
          return copiaAgregarIngrediente;
        });
        copiaOrden.datosProducto.notas = orden.datosProducto.notas;
        break;
      case TipoOrden.Paquete:
        copiaOrden.datosProducto.producto = undefined;
        copiaOrden.datosPaquete._idPaquete = orden.datosPaquete._idPaquete;
        copiaOrden.datosPaquete.paquete = undefined;
        orden.datosPaquete.alternativasProductosPorTamano.forEach(alternativaProductoPorTamano => {
          let copiaAlternativaProductoPorTamano = new AlternativaProductoPorTamano();
          copiaAlternativaProductoPorTamano._idTamano = alternativaProductoPorTamano._idTamano;
          copiaAlternativaProductoPorTamano.tamano = undefined;
          copiaAlternativaProductoPorTamano._idProducto = alternativaProductoPorTamano._idProducto;
          copiaAlternativaProductoPorTamano.producto = undefined;
          copiaAlternativaProductoPorTamano.quitarIngredientes = (<Ingrediente[]>alternativaProductoPorTamano.quitarIngredientes).map(ingrediente => ingrediente._id);
          copiaAlternativaProductoPorTamano.agregarIngredientes = alternativaProductoPorTamano.agregarIngredientes.map(agregarIngrediente => {
            let copiaAgregarIngrediente = new AgregarIngrediente();
            copiaAgregarIngrediente._idIngrediente = agregarIngrediente._idIngrediente;
            copiaAgregarIngrediente.ingrediente = undefined;
            copiaAgregarIngrediente.precio = agregarIngrediente.precio;
            return copiaAgregarIngrediente;
          });
          copiaAlternativaProductoPorTamano.notas = alternativaProductoPorTamano.notas;
          copiaOrden.datosPaquete.alternativasProductosPorTamano.push(copiaAlternativaProductoPorTamano);
        })
        break;
    }
    copiaOrden.precioPorTamano._idTamano = orden.precioPorTamano._idTamano;
    copiaOrden.precioPorTamano.tamano = undefined;
    copiaOrden.precioPorTamano.precio = orden.precioPorTamano.precio;
    copiaOrden.precioUnitario = orden.precioUnitario;
    return copiaOrden;
  }

  agregarOrden(_idGrupoDeOrdenes: string, orden: Orden): Promise<{ error: boolean, titulo?: string, detalles?: string }> {
    return new Promise((resolve, reject) => {
      this._ordenesService.agregarOrden(this.data._idRestaurante, this.data._idArea, this.data._idElementoPorArea, this.data._idComanda, _idGrupoDeOrdenes, orden).subscribe(
        (orden: GrupoDeOrdenes) => {
          resolve({ error: false });
        },
        (error: HttpErrorResponse) => {
          resolve({ error: true, titulo: error.error.titulo, detalles: error.error.detalles });
        }
      );
    })
  }

  eliminarOrden(ordenEliminar: Orden, posicionOrdenAgrupada: number) {
    if (ordenEliminar.cantidad == 1) {
      this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea eliminar esta orden?', '').then((result) => {
        if (result.value) {
          this.disminuirCantidadOrden(ordenEliminar, posicionOrdenAgrupada, 1);
        }
      })
    } else {
      const dialogRef = this.modal.open(EliminarOrdenModalComponent,
        {
          disableClose: true,
          data: ordenEliminar.cantidad,
          minWidth: '35%'
        });
      dialogRef.afterClosed().subscribe(
        (cantidad: number | null) => {
          if (cantidad != null) {
            this.disminuirCantidadOrden(ordenEliminar, posicionOrdenAgrupada, cantidad);
          }
        }
      );
    }
  }

  disminuirCantidadOrden(ordenEliminar: Orden, posicionOrdenAgrupada: number, cantidadDisminuir: number) {
    switch (ordenEliminar.tipo) {
      case TipoOrden.Producto:
        this.disminuirExistenciasUtilizadasProductoPorTamano(ordenEliminar.datosProducto._idProducto, ordenEliminar.precioPorTamano._idTamano, cantidadDisminuir);
        break;
      case TipoOrden.Paquete:
        ordenEliminar.datosPaquete.alternativasProductosPorTamano.forEach(alternativaProductoPorTamano => {
          this.disminuirExistenciasUtilizadasProductoPorTamano(alternativaProductoPorTamano._idProducto, alternativaProductoPorTamano._idTamano, cantidadDisminuir);
        })
        break;
    }
    (<number>ordenEliminar.cantidad) -= cantidadDisminuir;
    (<number>this.ordenesAgrupadas[posicionOrdenAgrupada].cantidad) -= cantidadDisminuir;
    if (ordenEliminar.cantidad == 0) {
      const posicionOrden = this.data.ordenes.findIndex(orden => orden == ordenEliminar);
      if (posicionOrden != -1) this.data.ordenes.splice(posicionOrden, 1);
      if (this.obtenerOrdenesDeOrdenAgrupada(this.ordenesAgrupadas[posicionOrdenAgrupada]).length == 0) this.ordenesAgrupadas.splice(posicionOrdenAgrupada, 1);
      if (this.data.ordenes.length == 0) this.cerrar();
    }
  }

  disminuirExistenciasUtilizadasProductoPorTamano(_idProducto: string, _idTamano: string, cantidad: number): void {
    const posicionControlInternoExistenciaDeProductoPorTamano = this.data.controlInternoExistenciasDeProductosPorTamano.findIndex(controlInternoExistenciaDeProductoPorTamano => {
      return controlInternoExistenciaDeProductoPorTamano._idProducto == _idProducto && controlInternoExistenciaDeProductoPorTamano._idTamano == _idTamano;
    });
    if (posicionControlInternoExistenciaDeProductoPorTamano != -1) {
      this.data.controlInternoExistenciasDeProductosPorTamano[posicionControlInternoExistenciaDeProductoPorTamano].cantidad -= cantidad;
    }
  }

  cerrar(): void {
    this.referenciaModal.close(null)
  }
}
