import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatStepper } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';


@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html'
})
export class NewEmployeeComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('infoGeneralForm') infoGeneralForm: FormGroup;
  @ViewChild('direccionForm') direccionForm: FormGroup;
  titulo = 'Agregar empleado';
  ExpresionesRegulares = ExpresionesRegulares;
  TipoUsuario = TipoUsuario;
  RequestState = RequestState;
  tipoUsuario: number;
  empleado: Usuario = new Usuario();
  estadoPeticionAgregarEmpleado: number;
  agregarEmpleadoSubscripcion: Subscription;
  restaurantesSubscripcion: Subscription;
  restaurantes: Restaurante[];
  estadosSubscripcion: Subscription;
  ciudadesSubscripcion: Subscription;
  estados: Estado[];
  ciudades: Ciudad[];
  eventoCambioImagenFotografia: any = '';
  codigoPostal: string = '';
  stepActual: number = 0;

  constructor(public modal: MatDialogRef<NewEmployeeComponent>,
    private _authService: AuthService,
    private _usuariosService: UsuariosService,
    private _ubicacionService: UbicacionService,
    private _restaurantesService: RestaurantesService,
    private _alertasService: AlertasService) {
    this.estadoPeticionAgregarEmpleado = RequestState.initial;
    this.tipoUsuario = this._authService.obtenerTipoUsuario();
  }

  ngOnInit(): void {
    this.obtenerRestaurantes();
    this.obtenerEstados();
  }

  obtenerRestaurantes(): void {
    this.restaurantesSubscripcion = this._restaurantesService.obtenerRestaurantes().subscribe(
      (restaurantes: Restaurante[]) => {
        this.restaurantes = restaurantes;
        switch (this.restaurantes.length) {
          case 0: //Se cierra el formulario debido a que no hay restaurantes en los cuales registrar empleados
            this._alertasService.alertaErrorSinConfirmacion('Actualmente no se tienen restaurantes registrados', 'Favor de agregar un restaurante para poder dar de alta sus empelados');
            this.modal.close();
            break;
          case 1: //Si solo hay un restaurante se inicializa el campo _idRestaurante con el unico restaurante disponible
            this.empleado._idRestaurante = this.restaurantes[0]._id;
            break;
        }
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.modal.close();
      }
    );
  }

  obtenerEstados(): void {
    this.estados = [];
    this.estadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerCiudades(idEstado: string): void {
    this.ciudades = [];
    this.ciudadesSubscripcion = this._ubicacionService.obtenerCiudades(Number(idEstado)).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerTipoUsuario(tipo: number): string {
    switch (tipo) {
      case TipoUsuario.Vendedor: return 'Vendedor';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
      default: return '';
    }
  }

  transformarNombreUsuario(nombreUsuario: string): void {
    if (nombreUsuario != null && nombreUsuario != undefined) {
      this.empleado.nombreUsuario = nombreUsuario.toLocaleLowerCase();
    } else {
      this.empleado.nombreUsuario = '';
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(event: ImageCroppedEvent): void {
    this.empleado.foto = event.base64;
  }

  imagenCargada(): void { }

  cortadorListo(): void { }

  cargarImagenFallida(): void { }

  async agregarEmpleado() {
    this.estadoPeticionAgregarEmpleado = RequestState.loading;
    const nombreUsuarioDisponible = await this.verificarNombreUsuario(this.empleado.nombreUsuario);
    if (nombreUsuarioDisponible == false) {
      this._alertasService.alertaErrorSinConfirmacion('Nombre de usuario no disponible', 'El nombre de usuario ingresado ya existe, favor de ingresar uno diferente.');
      this.estadoPeticionAgregarEmpleado = RequestState.initial;
      this.direccionForm.controls['nombreUsuario'].setErrors({ repetido: true });
    } else {
      const codigoPostal = await this.verificarCodigoPostal(this.empleado.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
        this.estadoPeticionAgregarEmpleado = RequestState.initial;
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      } else {
        this.empleado.idCodigoPostal = codigoPostal.id;
        this.agregarEmpleadoSubscripcion = this._usuariosService.agregarUsuario(this.empleado).subscribe(
          (empleado: Usuario) => {
            this.estadoPeticionAgregarEmpleado = RequestState.success;
            this.modal.close(empleado);
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            this.estadoPeticionAgregarEmpleado = RequestState.error;
          }
        );
      }
    }
  }
  
  async validarNombreUsuario() {
    if (this.empleado.nombreUsuario != '') {
      const nombreUsuarioDisponible = await this.verificarNombreUsuario(this.empleado.nombreUsuario);
      if (nombreUsuarioDisponible == false) {
        this.infoGeneralForm.controls['nombreUsuario'].setErrors({ repetido: true });
      }
    }
  }

  verificarNombreUsuario(nombreUsuario: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._usuariosService.verificarNombreUsuario(nombreUsuario).subscribe(
        (nombreUsuarioDisponible: boolean) => resolve(nombreUsuarioDisponible),
        (error) => resolve(false)
      );
    })
  }

  async validarCodigoPostal() {
    if (this.codigoPostal.length >= 5) {
      const codigoPostal = await this.verificarCodigoPostal(this.empleado.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      }
    }
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  retroceder() {
    this.stepActual--;
    this.stepper.previous();
  }

  avanzar() {
    this.stepActual++;
    this.stepper.next();
  }


  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy() {
    if (this.restaurantesSubscripcion) this.restaurantesSubscripcion.unsubscribe();
    if (this.ciudadesSubscripcion) this.ciudadesSubscripcion.unsubscribe();
    if (this.estadosSubscripcion) this.estadosSubscripcion.unsubscribe();
    if (this.agregarEmpleadoSubscripcion) this.agregarEmpleadoSubscripcion.unsubscribe();
  }
}
