import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Orden} from '../../../../../../../../shared/models/orden';
import {FormControl, FormGroup, ValidatorFn} from '@angular/forms';

@Component({
  selector: 'app-delete-order',
  templateUrl: './delete-order.component.html',
  styleUrls: []
})
export class DeleteOrderComponent implements OnInit {

  ordenesAgrupadas: Orden[];
  cantidades: number[][] = [];
  cantidadesOrdenesEliminadas: number[] = [];
  ordenesEliminadas: Orden[] = [];
  ordenesForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DeleteOrderComponent>) {
    this.ordenesForm = new FormGroup({
      todoSeleccionado: new FormControl(false)
    }, this.alMenosUnaOrdenSeleccionada());
    this.ordenesAgrupadas = [];
    this.data.ordenes.forEach((orden) => {
      const posicion = this.ordenesAgrupadas.findIndex(element => {
        if (this.data.tipo === 0) {
          if (element.datosProducto.agregarIngredientes.length !== orden.datosProducto.agregarIngredientes.length
            || element.datosProducto.quitarIngredientes.length !== orden.datosProducto.quitarIngredientes.length
            || element.datosProducto.notas.length !== orden.datosProducto.notas.length) {
            return false;
          }
          element.datosProducto.agregarIngredientes.forEach((ingrediente) => {
            if (!orden.datosProducto.agregarIngredientes.includes(ingrediente)) {
              return false;
            }
          });
          element.datosProducto.quitarIngredientes.forEach((ingrediente) => {
            if (!orden.datosProducto.quitarIngredientes.includes(ingrediente)) {
              return false;
            }
          });
          element.datosProducto.notas.forEach((nota) => {
            if (!orden.datosProducto.notas.includes(nota)) {
              return false;
            }
          });
          return true;
        } else {
          for (let posicionAlternativa = 0; posicionAlternativa < element.datosPaquete.alternativasProductosPorTamano.length; posicionAlternativa++) {
            if (element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length
              || element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.length !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.length
              || element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.length !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.length) {
              return false;
            }
            if (element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].producto.id !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].producto.id) {
              return false;
            }
            element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.forEach((ingrediente) => {
              if (!orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.includes(ingrediente)) {
                return false;
              }
            });
            element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.forEach((ingrediente) => {
              if (!orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.includes(ingrediente)) {
                return false;
              }
            });
            element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.forEach((nota) => {
              if (!orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.includes(nota)) {
                return false;
              }
            });
          }
          return true;
        }
      });
      if (posicion === -1) {
        this.ordenesAgrupadas.push({
          datosProducto: orden.datosProducto,
          datosPaquete: orden.datosPaquete,
          cantidad: 1,
          cancelada: null,
          fecha: null,
        });
      } else {
        this.ordenesAgrupadas[posicion].cantidad++;
      }
    });
    // Iniciar formulario de ordenes
    for (let i = 0; i < this.ordenesAgrupadas.length; i++) {
      this.ordenesForm.addControl('orden' + (i + 1), new FormControl(false));
      this.cantidades.push([]);
      this.ordenesForm.addControl('cantidadOrden' + (i + 1), new FormControl(1));
      if (this.ordenesAgrupadas[i].cantidad > 1) {
        for (let j = 0; j < this.ordenesAgrupadas[i].cantidad; j++) {
          this.cantidades[i].push(j + 1);
        }
      }
    }
  }

  ngOnInit() {
  }

  alMenosUnaOrdenSeleccionada(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }

  cambiarOrdenesEliminadas() {
    this.ordenesEliminadas = [];
    this.cantidadesOrdenesEliminadas = [];
    for (let i = 0; i < this.ordenesAgrupadas.length; i++) {
      if (this.ordenesForm.get('orden' + (i + 1)).value) {
        this.ordenesEliminadas.push(this.ordenesAgrupadas[i]);
        this.cantidadesOrdenesEliminadas.push(this.ordenesForm.get('cantidadOrden' + (i + 1)).value);
      }
    }
  }

  seleccionarTodo(e) {
    if (e.checked) {
      for (let i = 0; i < this.ordenesAgrupadas.length; i++) {
        this.ordenesForm.get('orden' + (i + 1)).setValue(true);
        this.ordenesForm.get('cantidadOrden' + (i + 1)).setValue(this.ordenesAgrupadas[i].cantidad);
      }
    } else {
      for (let i = 0; i < this.ordenesAgrupadas.length; i++) {
        this.ordenesForm.get('orden' + (i + 1)).setValue(false);
        this.ordenesForm.get('cantidadOrden' + (i + 1)).setValue(1);
      }
    }
    this.cambiarOrdenesEliminadas();
  }

  estaTodoSeleccionado() {
    let todoSeleccionado = true;
    for (let i = 0; i < this.ordenesAgrupadas.length; i++) {
      if (!this.ordenesForm.get('orden' + (i + 1)).value
        || (this.ordenesForm.get('cantidadOrden' + (i + 1)).value !== this.ordenesAgrupadas[i].cantidad)) {
        todoSeleccionado = false;
        break;
      }
    }
    return todoSeleccionado;
  }

  close(): void {
    this.dialogRef.close({ok: false});
  }

  eliminarOrdenes() {
    this.ordenesEliminadas.forEach((orden, posicion) => {
      orden.cantidad = this.cantidadesOrdenesEliminadas[posicion];
    });
    this.dialogRef.close({ok: true, ordenes: this.ordenesEliminadas});
  }
}
