import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { LicenciasService } from 'src/app/core/servicios/licencias.service';
import { Licencia } from 'src/app/shared/class-models/licencia.model';
import { RequestState } from '../../shared/enums/request-state.enum';
import { AddLicenseComponent } from './components/modals/add-license/add-license.component';
import { EditLicenseComponent } from './components/modals/edit-license/edit-license.component';
import { FolioPipe } from 'src/app/shared/pipes/folio.pipe';
import { FolioSinEspaciosPipe } from 'src/app/shared/pipes/folio-sin-espacios.pipe';
import { HttpErrorResponse } from '@angular/common/http';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html'

})
export class LicensesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Licencia>;
  filtro: string = '';
  estadoPeticionObtenerLicencias: number;
  RequestState = RequestState;
  obtenerLicenciasSubscripcion: Subscription;
  eliminarLicenciaSubscripcion: Subscription;
  columnas = ['dueno', 'serial', 'validez', 'costo', 'opciones'];
  fechaActual: Date;

  constructor(
    private _licenciasService: LicenciasService,
    private _alertasService: AlertasService,
    public modal: MatDialog,
    private folioPipe: FolioPipe,
    private folioSinEspaciosPipe: FolioSinEspaciosPipe,
    private fechaPipe: FechaPipe) {
    this.fechaActual = new Date();
  }

  ngOnInit() {
    this.obtenerLicencias();
  }

  obtenerLicencias() {
    this.estadoPeticionObtenerLicencias = RequestState.loading;
    this.obtenerLicenciasSubscripcion = this._licenciasService.obtenerLicencias().subscribe(
      (licencias: Licencia[]) => {
        this.inicializarTabla(licencias);
        this.estadoPeticionObtenerLicencias = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerLicencias = RequestState.error;
      }
    );
  }

  inicializarTabla(licencias: Licencia[]) {
    this.dataSource = new MatTableDataSource<Licencia>(licencias);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (licencia: Licencia, filtro: string) => {
      return licencia._id.trim().toLowerCase().indexOf(filtro) !== -1 ||
        (licencia.administrador.nombres + ' ' + licencia.administrador.primerApellido + (licencia.administrador.segundoApellido ? (' ' + licencia.administrador.primerApellido) : '')).trim().toLowerCase().indexOf(filtro) !== -1 ||
        ('$' + Number(licencia.monto).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.folioSinEspaciosPipe.transform(licencia._id, 'folio-sin-espacios').trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.folioPipe.transform(licencia._id, 'folio').trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerValidezLicencia(licencia).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerValidezLicencia(licencia).trim().replace(/\s/g, '').toLowerCase().indexOf(filtro) !== -1;
    }
  }

  obtenerValidezLicencia(licencia: Licencia): string {
    return this.fechaPipe.transform(licencia.fechaInicio as Date) + ' - ' + this.fechaPipe.transform(licencia.fechaFin as Date);
  }

  buscarLicencia() {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarLicencia(): void {
    const dialogRef = this.modal.open(AddLicenseComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (licencia: Licencia) => {
        if (licencia) {
          this._alertasService.alertaExitoSinConfirmacion('Licencia agregada', 'Licencia agregada con éxito a ' + licencia.administrador.nombres + ' ' + licencia.administrador.primerApellido + (licencia.administrador.segundoApellido ? (' ' + licencia.administrador.primerApellido) : '') + '. El serial de la licencia es ' + this.folioPipe.transform(licencia._id, 'folio') + '.');
          this.dataSource.data.push(licencia);
          const licencias: Licencia[] = this.dataSource.data;
          this.inicializarTabla(licencias);
          this.buscarLicencia();
        }
      }
    );
  }

  actualizarLicencia(licencia: Licencia): void {
    const dialogRef = this.modal.open(EditLicenseComponent, { disableClose: true, data: licencia });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Licencia renovada exitosamente', 'Licencia de ' + licencia.administrador.nombres + ' ' + licencia.administrador.primerApellido + (licencia.administrador.segundoApellido ? (' ' + licencia.administrador.primerApellido) : '') + ' ha sido actualizada con éxito. El serial de la licencia es ' + this.folioPipe.transform(licencia._id, 'folio') + '.');
          this.buscarLicencia();
        }
      }
    );
  }

  eliminarLicencia(licenciaEliminar: Licencia) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar la licencia  de ' + licenciaEliminar.administrador.nombres + ' ' + licenciaEliminar.administrador.primerApellido + (licenciaEliminar.administrador.segundoApellido ? (' ' + licenciaEliminar.administrador.primerApellido) : '') + '?', '')
      .then((result) => {
        if (result.value) {
          this.eliminarLicenciaSubscripcion = this._licenciasService.eliminarLicencia(licenciaEliminar._id).subscribe(
            (resultado) => {
              let posicionLicencia = this.dataSource.data.findIndex((licencia: Licencia) => {
                return licencia._id === licenciaEliminar._id;
              });
              let licenciasActualizadas: Licencia[] = this.dataSource.data;
              if (posicionLicencia != -1) {
                licenciasActualizadas.splice(posicionLicencia, 1);
              }
              this.inicializarTabla(licenciasActualizadas);
              this.buscarLicencia();
              this._alertasService.alertaExitoSinConfirmacion('Licencia eliminada exitosamente', 'La licencia den' + licenciaEliminar.administrador.nombres + ' ' + licenciaEliminar.administrador.primerApellido + (licenciaEliminar.administrador.segundoApellido ? (' ' + licenciaEliminar.administrador.primerApellido) : '') + ' ha sido eliminada con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerLicenciasSubscripcion) this.obtenerLicenciasSubscripcion.unsubscribe();
    if (this.eliminarLicenciaSubscripcion) this.eliminarLicenciaSubscripcion.unsubscribe();
  }
}
