import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestState } from '../../shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { EditIngredientComponent } from '../ingredient/pages/edit-ingredient/edit-ingredient.component';
import { MatDialog } from '@angular/material';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ingredient',
  templateUrl: './ingredient.component.html'
})
export class IngredientComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  obtenerIngredienteSubscription: Subscription;
  eliminarIngredienteSubscription: Subscription;
  estadoPeticionObtenerIngrediente: number;
  ingrediente: Ingrediente;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _ingredientesService: IngredientesService,
    private _alertasService: AlertasService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerIngrediente = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerIngrediente();
  }

  obtenerIngrediente() {
    this.estadoPeticionObtenerIngrediente = RequestState.loading;
    this.obtenerIngredienteSubscription = this._ingredientesService.obtenerIngrediente(this.activatedRoute.snapshot.paramMap.get('idIngrediente')).subscribe(
      (ingrediente: Ingrediente) => {
        this.ingrediente = ingrediente;
        this.estadoPeticionObtenerIngrediente = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngrediente = RequestState.error;
        this.router.navigate(['/ingredientes']);
      }
    );
  }

  actualizarIngrediente(ingrediente: Ingrediente) {
    const dialogRef = this.dialog.open(EditIngredientComponent, { disableClose: true, data: ingrediente });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Ingrediente actualizado exitosamente', 'El ingrediente ' + ingrediente.nombre + ' ha sido actualizado con éxito.');
        }
      }
    );
  }

  eliminarIngrediente(ingrediente: Ingrediente) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el ingrediente ' + ingrediente.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarIngredienteSubscription = this._ingredientesService.eliminarIngrediente(ingrediente._id).subscribe(
          (resultado: boolean) => {
            this._alertasService.alertaExitoSinConfirmacion('Ingrediente eliminado exitosamente', 'El ingrediente ' + ingrediente.nombre + ' ha sido eliminado con éxito.');
            this.router.navigate(['/ingredientes']);
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerIngredienteSubscription) this.obtenerIngredienteSubscription.unsubscribe();
    if (this.eliminarIngredienteSubscription) this.eliminarIngredienteSubscription.unsubscribe();
  }
}
