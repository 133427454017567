import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestState } from '../../shared/enums/request-state.enum';
import { Router } from '@angular/router';
import { PerfilService } from 'src/app/core/servicios/perfil.service';
import { RecuperacionContrasena } from 'src/app/shared/class-models/recuperacion-contrasena.model';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { LongitudMinimaDeContrasena, LongitudMaximaDeContrasena } from 'src/app/shared/constants/longitud-de-contrasena.const';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  @ViewChild('recuperarContrasenaForm') recuperarContrasenaForm: FormGroup;
  RequestState = RequestState;
  estadoPeticionEnviarCodigoRecuperacionContrasena: number;
  enviarCodigoRecuperacionContrasenaSubscription: Subscription;
  estadoPeticionActualizarContrasenaConCodigoDeRecuperacionPerfil: number;
  recuperarContrasenaSubscription: Subscription;
  recuperacionContrasena: RecuperacionContrasena = new RecuperacionContrasena();
  LongitudMinimaDeContrasena = LongitudMinimaDeContrasena;
  LongitudMaximaDeContrasena = LongitudMaximaDeContrasena;

  constructor(
    private _perfilService: PerfilService,
    private _alertasService: AlertasService,
    private router: Router) {
  }

  ngOnInit(): void {}

  transformarNombreUsuario(nombreUsuario: string): void {
    if (nombreUsuario != null && nombreUsuario != undefined) {
      this.recuperacionContrasena.nombreUsuario = nombreUsuario.toLocaleLowerCase();
    } else {
      this.recuperacionContrasena.nombreUsuario = '';
    }
  }

  verificarConfirmacionContrasenaNueva() {
    if (this.recuperacionContrasena.contrasenaNueva.length > 0 && this.recuperacionContrasena.confirmacionContrasenaNueva.length > 0 && 
      this.recuperacionContrasena.contrasenaNueva != this.recuperacionContrasena.confirmacionContrasenaNueva) {
      this.recuperarContrasenaForm.controls['confirmacionContrasenaNueva'].setErrors({ incorrecta: true });
    }
  }

  actualizarCodigoDeRecuperacionContrasenaPerfil() {
    this.estadoPeticionEnviarCodigoRecuperacionContrasena = RequestState.loading;
    this.enviarCodigoRecuperacionContrasenaSubscription = this._perfilService.actualizarCodigoDeRecuperacionContrasenaPerfil(this.recuperacionContrasena.nombreUsuario).subscribe(
      (res: any) => {
        this._alertasService.alertaExitoSinConfirmacion('Código de recuperación enviado con éxito', 'Se ha enviado un código de recuperación al correo que tiene registrado tu cuenta, favor de ingresarlo');
        this.estadoPeticionEnviarCodigoRecuperacionContrasena = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionEnviarCodigoRecuperacionContrasena = RequestState.error;
      }
    );

  }

  actualizarContrasenaConCodigoDeRecuperacionPerfil(): void {
    this.estadoPeticionActualizarContrasenaConCodigoDeRecuperacionPerfil = RequestState.loading;
    this.recuperarContrasenaSubscription = this._perfilService.actualizarContrasenaConCodigoDeRecuperacionPerfil(this.recuperacionContrasena).subscribe(
      (res: any) => {
        this.router.navigate(['/iniciar-sesion']);
        this._alertasService.alertaExitoSinConfirmacion('Contraseña restaurada con éxito', 'Favor de iniciar sesión con su nueva contraseña');
        this.estadoPeticionActualizarContrasenaConCodigoDeRecuperacionPerfil = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarContrasenaConCodigoDeRecuperacionPerfil = RequestState.error;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.enviarCodigoRecuperacionContrasenaSubscription) this.enviarCodigoRecuperacionContrasenaSubscription.unsubscribe();
    if (this.recuperarContrasenaSubscription) this.recuperarContrasenaSubscription.unsubscribe();
  }
}
