import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppSettings } from '../../configs/app-settings.config';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { WebSocketsService } from '../servicios/web-sockets.service';
const jwt = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private type$ = new BehaviorSubject<number>(6);
  private loggedIn$ = new BehaviorSubject<boolean>(false);
  private tokenDesencriptado: Usuario;
  constructor(private router: Router, private httpClient: HttpClient, private _webSocketsService: WebSocketsService) {
    if (this.obtenerToken()) {
      this.tokenDesencriptado = <Usuario>jwt.decodeToken(localStorage.getItem('token'));
      this.loggedIn$.next(true);
    }
  }

  get isLoggedIn() {
    return this.loggedIn$.asObservable();
  }

  iniciarSesion({ nombreUsuario, contrasena }): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}/autenticacion/sesion`, { nombreUsuario, contrasena }, AppSettings.Options)
      .pipe(map((token: string) => {
        if (token) {
          this.tokenDesencriptado = jwt.decodeToken(token);
          this.loggedIn$.next(true);
          localStorage.setItem('token', token);
        }
      }));
  }

  cerrarSesion(): void {
    this._webSocketsService.cerrarSesionWS().then(
      () => {
        localStorage.removeItem('token');
        this.router.navigate(['iniciar-sesion']);
      })
  }

  public obtenerToken(): string {
    return localStorage.getItem('token') ? localStorage.getItem('token') : null;
  }

  public obtenerTokenDesencriptado(): Usuario {
    return this.tokenDesencriptado;
  }

  public obtenerUsuario(): Usuario {
    return this.tokenDesencriptado;
  }

  public obtenerTipoUsuario(): number {
    return this.tokenDesencriptado.tipo;
  }
}
