import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { NewClientComponent } from './modals/new-client/new-client.component';
import { EditClientComponent } from '../client/modals/edit/edit-client.component';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { Cliente } from 'src/app/shared/class-models/cliente.model';
import { ClientesService } from 'src/app/core/servicios/clientes.service';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html'
})
export class ClientsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Cliente>;
  filtro: string = '';
  estadoPeticionObtenerClientes: number;
  RequestState = RequestState;
  obtenerClientesSubscripcion: Subscription;
  eliminarClienteSubscripcion: Subscription;
  columnas = ['tipoDePersona', 'razonSocial', 'rfc', 'correo', 'telefono', 'opciones'];


  constructor(
    private _clientesService: ClientesService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    private phonePipe: PhonePipe) {
    this.estadoPeticionObtenerClientes = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerClientes();
  }

  obtenerClientes(): void {
    this.estadoPeticionObtenerClientes = RequestState.loading;
    this.obtenerClientesSubscripcion = this._clientesService.obtenerClientes().subscribe(
      (clientes: Cliente[]) => {
        this.inicializarClientes(clientes);
        this.estadoPeticionObtenerClientes = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerClientes = RequestState.error;
      }
    );
  }

  inicializarClientes(clientes: Cliente[]): void {
    this.dataSource = new MatTableDataSource<Cliente>(clientes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (cliente: Cliente, filtro: string) => {
      return this.obtenerTipoDePersona(cliente.tipoDePersona).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerRazonSocial(cliente).trim().toLowerCase().indexOf(filtro) !== -1 ||
        cliente.rfc.trim().toLowerCase().indexOf(filtro) !== -1 ||
        cliente.correo.trim().toLowerCase().indexOf(filtro) !== -1 ||
        (cliente.telefono ? cliente.telefono.trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
        (cliente.telefono ? this.phonePipe.transform(cliente.telefono, 'phone').trim().toLowerCase().indexOf(filtro) !== -1 : false);
    }
  }

  buscarCliente(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerTipoDePersona(tipo: number): string {
    switch (tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  obtenerRazonSocial(cliente: Cliente): string {
    switch (cliente.tipoDePersona) {
      case TipoDePersona.Moral: return cliente.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return cliente.nombres + ' ' + cliente.primerApellido + (cliente.segundoApellido ? (' ' + cliente.segundoApellido) : '');
    }
  }

  agregarCliente(): void {
    const dialogRef = this.dialog.open(NewClientComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (cliente: Cliente) => {
        if (cliente) {
          this._alertasService.alertaExitoSinConfirmacion('Cliente agregado exitosamente', 'El cliente ' + this.obtenerRazonSocial(cliente) + ' ha sido agregado con éxito');
          this.dataSource.data.push(cliente);
          const clientes: Cliente[] = this.dataSource.data;
          this.inicializarClientes(clientes);
          this.buscarCliente();
        }
      }
    );
  }

  actualizarCliente(cliente: Cliente): void {
    const dialogRef = this.dialog.open(EditClientComponent, { disableClose: true, data: cliente });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Cliente actualizado exitosamente', 'El cliente ' + this.obtenerRazonSocial(cliente) + ' ha sido actualizado con éxito.');
          this.buscarCliente();
        }
      }
    );
  }

  eliminarCliente(clienteEliminar: Cliente): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar al cliente ' + this.obtenerRazonSocial(clienteEliminar) + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarClienteSubscripcion = this._clientesService.eliminarCliente(clienteEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionCliente = this.dataSource.data.findIndex((cliente: Cliente) => {
                return cliente._id === clienteEliminar._id;
              });
              let clientesActualizados: Cliente[] = this.dataSource.data;
              if (posicionCliente != -1) {
                clientesActualizados.splice(posicionCliente, 1);
              }
              this.inicializarClientes(clientesActualizados);
              this.buscarCliente();
              this._alertasService.alertaExitoSinConfirmacion('Cliente eliminado exitosamente', 'El cliente ' + this.obtenerRazonSocial(clienteEliminar) + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerClientesSubscripcion) this.obtenerClientesSubscripcion.unsubscribe();
    if (this.eliminarClienteSubscripcion) this.eliminarClienteSubscripcion.unsubscribe();
  }
}
