import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { CuentasService } from 'src/app/core/servicios/cuentas.service';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { TipoPropina } from 'src/app/shared/enums/tipo-propina.enum';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { TipoDescuento } from 'src/app/shared/enums/tipo-descuento.enum';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: []
})
export class BillDetailsComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  cuenta: Cuenta;
  estadoPeticionObtenerCuenta: number;
  obtenerCuentaSubscripcion: Subscription;
  ordenesPrincipales: Orden[];
  ordenesSinAgrupar: Orden[][];
  ordenesAgrupadas: Orden[][];
  estadoPeticionObtenerOrdenesCuenta: number;
  obtenerOrdenesCuentaSubscripcion: Subscription;
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;
  TipoCupon = TipoCupon;
  TipoDescuento = TipoDescuento;
  TipoPropina = TipoPropina;
  TipoOrden = TipoOrden;

  constructor(
    public modal: MatDialogRef<BillDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {_idRestaurante: string, cuenta: Cuenta},
    private _cuentasService: CuentasService,
    private _alertasService: AlertasService
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 2;
    this.estadoPeticionObtenerCuenta = RequestState.initial;
    this.estadoPeticionObtenerOrdenesCuenta = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCuenta();
    this.obtenerOrdenesCuenta();
  }
  
  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  obtenerCuenta(): void {
    this.estadoPeticionObtenerCuenta = RequestState.loading;
    this.obtenerCuentaSubscripcion = this._cuentasService.obtenerCuenta(this.data._idRestaurante, this.data.cuenta._id).subscribe(
      (cuenta: Cuenta) => {
        this.cuenta = cuenta;
        this.estadoPeticionObtenerCuenta = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCuenta = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerOrdenesCuenta(): void {
    this.estadoPeticionObtenerOrdenesCuenta = RequestState.loading;
    this.obtenerOrdenesCuentaSubscripcion = this._cuentasService.obtenerOrdenesCuenta(this.data._idRestaurante, this.data.cuenta._id).subscribe(
      (ordenes: Orden[]) => {
        this.inicializarOrdenesPrincipales(ordenes);
        this.estadoPeticionObtenerOrdenesCuenta = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerOrdenesCuenta = RequestState.error;
        this.modal.close();
      }
    );
  }

  inicializarOrdenesPrincipales(ordenes: Orden[]): void {
    this.ordenesPrincipales = [];
    this.ordenesSinAgrupar = [];
    this.ordenesAgrupadas = [];
    ordenes.forEach(orden => {
      const posicionOrdenPrincipal = this.ordenesPrincipales.findIndex(ordenPrincipal => {
        if (ordenPrincipal.tipo == orden.tipo && ordenPrincipal.precioPorTamano._idTamano == orden.precioPorTamano._idTamano) {
          switch (ordenPrincipal.tipo) {
            case TipoOrden.Producto:
              return ordenPrincipal.datosProducto._idProducto == orden.datosProducto._idProducto;
            case TipoOrden.Paquete:
              return ordenPrincipal.datosPaquete._idPaquete == orden.datosPaquete._idPaquete;
          }
        } else {
          return false;
        }
      })
      if (posicionOrdenPrincipal != -1) {
        (<number>this.ordenesPrincipales[posicionOrdenPrincipal].cantidad) += <number>orden.cantidad;
        this.ordenesSinAgrupar[posicionOrdenPrincipal].push(orden);
      } else {
        let ordenPrincipal = new Orden();
        ordenPrincipal.tipo = orden.tipo;
        ordenPrincipal.cantidad = orden.cantidad;
        ordenPrincipal.precioPorTamano = orden.precioPorTamano;
        switch (ordenPrincipal.tipo) {
          case TipoOrden.Producto:
            ordenPrincipal.datosProducto._idProducto = orden.datosProducto._idProducto;
            ordenPrincipal.datosProducto.producto = orden.datosProducto.producto;
            break;
          case TipoOrden.Paquete:
            ordenPrincipal.datosPaquete._idPaquete = orden.datosPaquete._idPaquete;
            ordenPrincipal.datosPaquete.paquete = orden.datosPaquete.paquete;
            break;
        }
        this.ordenesPrincipales.push(ordenPrincipal);
        this.ordenesSinAgrupar.push([]);
        this.ordenesSinAgrupar[this.ordenesSinAgrupar.length - 1].push(orden);
        this.ordenesAgrupadas.push([]);
      }
    })
    this.ordenesPrincipales.forEach((ordenPrincipal, posicion) => {
      this.inicializarOrdenesAgrupadas(posicion);
    })
  }

  inicializarOrdenesAgrupadas(posicionOrdenPrincipal: number): void {
    this.ordenesAgrupadas[posicionOrdenPrincipal] = [];
    this.ordenesSinAgrupar[posicionOrdenPrincipal].forEach(orden => {
      const posicionOrdenAgrupada = this.obtenerPosicionOrdenAgrupada(posicionOrdenPrincipal, orden);
      if (posicionOrdenAgrupada != -1) {
        (<number>this.ordenesAgrupadas[posicionOrdenPrincipal][posicionOrdenAgrupada].cantidad) += <number>orden.cantidad;
      } else {
        this.ordenesAgrupadas[posicionOrdenPrincipal].push(this.crearOrdenAgrupada(orden, <number>orden.cantidad));
      }
    })
  }

  obtenerPosicionOrdenAgrupada(posicionOrdenPrincipal: number, orden: Orden): number {
    return this.ordenesAgrupadas[posicionOrdenPrincipal].findIndex(ordenAgrupada => {
      if (ordenAgrupada.tipo == orden.tipo && ordenAgrupada.precioUnitario == orden.precioUnitario &&
        ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano &&
        ordenAgrupada.precioPorTamano.precio == orden.precioPorTamano.precio
      ) {
        switch (ordenAgrupada.tipo) {
          case TipoOrden.Producto:
            return this.sonOrdenesTipoProductoIdenticas(orden, ordenAgrupada);
          case TipoOrden.Paquete:
            return this.sonOrdenesTipoPaqueteIdenticas(orden, ordenAgrupada);
        }
      } else {
        return false;
      }
    })
  }

  sonOrdenesTipoProductoIdenticas(orden1: Orden, orden2: Orden): boolean {
    let sonIdenticas: boolean = true;
    if (orden1.datosProducto._idProducto == orden2.datosProducto._idProducto &&
      orden1.datosProducto.agregarIngredientes.length == orden2.datosProducto.agregarIngredientes.length) {
      //Se recorre el array de agregarIngredientes
      for (let posicionAgregarIngrediente = 0; posicionAgregarIngrediente < orden1.datosProducto.agregarIngredientes.length; posicionAgregarIngrediente++) {
        if (orden1.datosProducto.agregarIngredientes[posicionAgregarIngrediente]._idIngrediente != orden2.datosProducto.agregarIngredientes[posicionAgregarIngrediente]._idIngrediente ||
          orden1.datosProducto.agregarIngredientes[posicionAgregarIngrediente].precio != orden2.datosProducto.agregarIngredientes[posicionAgregarIngrediente].precio) {
          sonIdenticas = false;
          break;
        }
      }
    } else {
      sonIdenticas = false;
    }
    return sonIdenticas;
  }

  sonOrdenesTipoPaqueteIdenticas(orden1: Orden, orden2: Orden): boolean {
    let sonIdenticas: boolean = true;
    if (orden1.datosPaquete._idPaquete == orden2.datosPaquete._idPaquete &&
      orden1.datosPaquete.alternativasProductosPorTamano.length == orden2.datosPaquete.alternativasProductosPorTamano.length) {
      //Se recorre el array de alternativasProductosPorTamano
      for (let posicionAlternativa = 0; posicionAlternativa < orden1.datosPaquete.alternativasProductosPorTamano.length; posicionAlternativa++) {
        if (orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idProducto == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idProducto &&
          orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idTamano == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idTamano &&
          orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length) {
          //Se recorre el array de agregarIngredientes
          for (let posicionAgregarIngrediente = 0; posicionAgregarIngrediente < orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length; posicionAgregarIngrediente++) {
            if (orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente]._idIngrediente != orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente]._idIngrediente ||
              orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente].precio != orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente].precio) {
              sonIdenticas = false;
              break;
            }
          }
        } else {
          sonIdenticas = false;
        }
      }
    } else {
      sonIdenticas = false;
    }
    return sonIdenticas;
  }

  crearOrdenAgrupada(orden: Orden, cantidad: number): Orden {
    let ordenAgrupada = new Orden();
    ordenAgrupada.tipo = orden.tipo;
    ordenAgrupada.cantidad = cantidad;
    ordenAgrupada.precioUnitario = orden.precioUnitario;
    ordenAgrupada.precioPorTamano = orden.precioPorTamano;
    switch (ordenAgrupada.tipo) {
      case TipoOrden.Producto:
        ordenAgrupada.datosProducto = orden.datosProducto;
        break;
      case TipoOrden.Paquete:
        ordenAgrupada.datosPaquete = orden.datosPaquete;
        break;
    }
    return ordenAgrupada;
  }

  /*inicializarOrdenesAgrupadas(): void {
    this.ordenesAgrupadas = [];
    this.ordenes.forEach(orden => {
      const posicionOrdenAgrupada = this.ordenesAgrupadas.findIndex(ordenAgrupada => {
        if (
          ordenAgrupada.tipo == orden.tipo &&
          ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano &&
          ordenAgrupada.precioPorTamano.precio == orden.precioPorTamano.precio &&
          ordenAgrupada.precioUnitario == orden.precioUnitario
          ) {
          switch (ordenAgrupada.tipo) {
            case TipoOrden.Producto:
              return ordenAgrupada.datosProducto._idProducto == orden.datosProducto._idProducto;
            case TipoOrden.Paquete:
              return ordenAgrupada.datosPaquete._idPaquete == orden.datosPaquete._idPaquete;
          }
        } else {
          return false;
        }
      })
      if (posicionOrdenAgrupada != -1) {
        (<number>this.ordenesAgrupadas[posicionOrdenAgrupada].cantidad) += <number>orden.cantidad;
      } else {
        let ordenAgrupada = new Orden();
        ordenAgrupada.tipo = orden.tipo;
        ordenAgrupada.cantidad = orden.cantidad;
        ordenAgrupada.precioPorTamano = orden.precioPorTamano;
        ordenAgrupada.precioUnitario = orden.precioUnitario;
        switch (ordenAgrupada.tipo) {
          case TipoOrden.Producto:
            ordenAgrupada.datosProducto._idProducto = orden.datosProducto._idProducto;
            ordenAgrupada.datosProducto.producto = orden.datosProducto.producto;
            break;
          case TipoOrden.Paquete:
            ordenAgrupada.datosPaquete._idPaquete = orden.datosPaquete._idPaquete;
            ordenAgrupada.datosPaquete.paquete = orden.datosPaquete.paquete;
            break;
        }
        this.ordenesAgrupadas.push(ordenAgrupada);
      }
    })
  }

  obtenerOrdenesDeOrdenAgrupada(ordenAgrupada: Orden): Orden[] {
    return this.ordenes.filter(orden => {
      if (
        ordenAgrupada.tipo == orden.tipo &&
        ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano &&
        ordenAgrupada.precioPorTamano.precio == orden.precioPorTamano.precio &&
        ordenAgrupada.precioUnitario == orden.precioUnitario
        ) {
        switch (ordenAgrupada.tipo) {
          case TipoOrden.Producto:
            return ordenAgrupada.datosProducto._idProducto == orden.datosProducto._idProducto;
          case TipoOrden.Paquete:
            return ordenAgrupada.datosPaquete._idPaquete == orden.datosPaquete._idPaquete;
        }
      } else {
        return false;
      }
    })
  }*/

  obtenerNombreCompletoCliente(): string {
    if (this.cuenta.datosCliente.noAplica) return ''
    else {
      switch(this.cuenta.datosCliente.tipoDePersona) {
        case TipoDePersona.Moral: 
          return this.cuenta.datosCliente.razonSocial;
        case TipoDePersona.Fisica: 
        case TipoDePersona.Ninguna: 
          return this.cuenta.datosCliente.nombres + ' ' + this.cuenta.datosCliente.primerApellido + (this.cuenta.datosCliente.segundoApellido ? (' ' + this.cuenta.datosCliente.segundoApellido) : '');
      }
    }
  }

  cerrar() {
    this.modal.close()
  }

  ngOnDestroy(): void {
    if (this.obtenerCuentaSubscripcion) this.obtenerCuentaSubscripcion.unsubscribe();
    if (this.obtenerOrdenesCuentaSubscripcion) this.obtenerOrdenesCuentaSubscripcion.unsubscribe();
  }

  /*ordersSubscription: Subscription;
  dataSubscription: Subscription;
  cuenta: Cuenta;
  ordenes: Orden[];
  ordenPrincipal: Orden[];
  ordenesAgrupadas: Orden[][];
  requestState: number;

  constructor(public dialogRef: MatDialogRef<BillDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string,
              private billService: BillService) {
    this.requestState = RequestState.initial;
  }

  ngOnInit() {
    this.requestState = RequestState.loading;
    this.ordenPrincipal = [];
    this.ordenesAgrupadas = [];
    this.dataSubscription = this.billService.obtenerCuenta(this.data).subscribe(
      (cuenta: Cuenta) => {
        this.cuenta = cuenta;
        this.ordersSubscription = this.billService.obtenerOrdenesDeCuenta(this.data).subscribe(
          (ordenes: Orden[]) => {
            this.ordenes = ordenes;
            if (ordenes.length > 0) {
              this.ordenes.sort((obj1, obj2) => {
                const nombre1 = obj1.datosProducto != null ? obj1.datosProducto.producto.nombre : obj1.datosPaquete.paquete.nombre;
                const nombre2 = obj2.datosProducto != null ? obj2.datosProducto.producto.nombre : obj2.datosPaquete.paquete.nombre;
                if (nombre1 > nombre2) {
                  return 1;
                }
                if (nombre1 < nombre2) {
                  return -1;
                }
                return 0;
              });
              this.ordenes.forEach((orden) => {
                const posicion = this.ordenPrincipal.findIndex(element => {
                  if (element.precioPorTamano.tamano.id === orden.precioPorTamano.tamano.id) {
                    if (element.datosProducto !== null && orden.datosProducto !== null
                      && element.datosProducto.producto.id === orden.datosProducto.producto.id) {
                      return true;
                    }
                    if (element.datosPaquete !== null && orden.datosPaquete !== null
                      && element.datosPaquete.paquete.id === orden.datosPaquete.paquete.id) {
                      return true;
                    }
                  }
                });
                if (posicion === -1) {
                  this.ordenPrincipal.push({
                    datosProducto: orden.datosProducto,
                    datosPaquete: orden.datosPaquete,
                    cantidad: orden.cantidad,
                    precioPorTamano: orden.precioPorTamano,
                    cancelada: null,
                    fecha: null,
                  });
                  this.ordenesAgrupadas.push([]);
                  this.ordenesAgrupadas[this.ordenesAgrupadas.length - 1].push(orden);
                } else {
                  this.ordenPrincipal[posicion].cantidad += orden.cantidad;
                  this.ordenesAgrupadas[posicion].push(orden);
                }
              });
            }
            this.requestState = RequestState.success;
          },
          () => {
            this.requestState = RequestState.error;
          }
        );
      }, error => {
        this.requestState = RequestState.error;
      }
    );
  }

  obtenerTipoDePago() {
    switch (this.cuenta.formaDePago) {
      case '01':
        return '(Efectivo)';
      case '04':
        return '(Tarjeta de credito No.' + this.cuenta.numeroDeTarjeta + ')';
      case '28':
        return '(Tarjeta de debito No. ' + this.cuenta.numeroDeTarjeta + ')';
      default:
        return '';
    }
  }

  close(): void {
    this.dialogRef.close();
  }*/
}
