import { Component, OnDestroy, OnInit, ViewChild, Inject } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { TipoImagenRestaurante } from 'src/app/shared/enums/tipo-imagen-restaurante.enum';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { Licencia } from 'src/app/shared/class-models/licencia.model';
import { FormGroup } from '@angular/forms';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { LicenciasService } from 'src/app/core/servicios/licencias.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { PorcentajeIva } from 'src/app/shared/enums/porcentaje-iva.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';

@Component({
  selector: 'app-edit-restaurant',
  templateUrl: './edit-restaurant.component.html'
})
export class EditRestaurantComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  titulo = 'Actualizar restaurante';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;
  obtenerEstadosSubscripcion: Subscription;
  obtenerCiudadesSubscripcion: Subscription;
  obtenerLicenciasVigentesSubscripcion: Subscription;
  estadoPeticionObtenerRestaurante: number;
  obtenerRestauranteSubscripcion: Subscription;
  fotoActualRestaurante: string;
  //logoActualRestaurante: string;
  AppSettings = AppSettings;

  /* VARIABLES DEL STEP DE INFORMACIÓN GENERAL */
  TipoImagenRestaurante = TipoImagenRestaurante;
  eventoCambioImagenFotografia: any = '';
  eventoCambioImagenLogotipo: any = '';

  /* VARIABLES DEL STEP DE DIRECCIÓN */
  @ViewChild('direccionRestauranteForm') direccionRestauranteForm: FormGroup;
  estados: Estado[];
  ciudades: Ciudad[];
  codigoPostal: string = '';

  /* VARIABLES DEL STEP DE CONFIGURACIÓN */
  PorcentajeIva = PorcentajeIva;

  /* VARIABLES DEL STEP DE LICENCIA */
  licencias: Licencia[];

  /* VARIABLES PARA ACTUALIZAR EL RESTAURANTE */
  estadoPeticionActualizarRestaurante: number;
  actualizarRestauranteSubscription: Subscription;
  restaurante: Restaurante;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Restaurante,
    public modal: MatDialogRef<EditRestaurantComponent>,
    private _restaurantesService: RestaurantesService,
    private _ubicacionService: UbicacionService,
    private _licenciasService: LicenciasService,
    private _alertasService: AlertasService
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 2;
    this.estadoPeticionActualizarRestaurante = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerRestaurante();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerRestaurante(): void {
    this.estadoPeticionObtenerRestaurante = RequestState.loading;
    this.obtenerRestauranteSubscripcion = this._restaurantesService.obtenerRestaurante(this.data._id).subscribe(
      (restaurante: Restaurante) => {
        this.fotoActualRestaurante = restaurante.foto;
        restaurante.foto = '';
        //this.logoActualRestaurante = restaurante.logo;
        //restaurante.logo = '';
        this.codigoPostal = restaurante.codigoPostal.codigo;
        this.restaurante = restaurante;
        this.obtenerLicenciasVigentes();
        this.obtenerEstados();
        this.obtenerCiudades(Number(this.restaurante.idEstado));
        this.estadoPeticionObtenerRestaurante = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerRestaurante = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerLicenciasVigentes(): void {
    this.licencias = [];
    this.obtenerLicenciasVigentesSubscripcion = this._licenciasService.obtenerLicenciasVigentes().subscribe(
      (licencias: Licencia[]) => {
        this.licencias = licencias;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.incrementarContadorPeticionesFinalizadas();
        this.modal.close();
      }
    );
  }

  obtenerEstados(): void {
    this.estados = [];
    this.obtenerEstadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.incrementarContadorPeticionesFinalizadas();
        this.modal.close();
      }
    );
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  /* MÉTODOS DEL STEP DE INFORMACIÓN GENERAL */
  convertirRfcMayusculas(): void {
    if (this.restaurante.rfc) {
      this.restaurante.rfc = this.restaurante.rfc.toUpperCase();
    }
  }

  cambioArchivoEvento(tipoImagenRestaurante: number, event: any): void {
    switch (tipoImagenRestaurante) {
      case TipoImagenRestaurante.Fotografia: this.eventoCambioImagenFotografia = event;
        break;
      case TipoImagenRestaurante.Logotipo: this.eventoCambioImagenLogotipo = event;
        break;
    }
  }

  imagenRecortada(tipoImagenRestaurante: number, evento: ImageCroppedEvent): void {
    switch (tipoImagenRestaurante) {
      case TipoImagenRestaurante.Fotografia: this.restaurante.foto = evento.base64;
        break;
      case TipoImagenRestaurante.Logotipo: this.restaurante.logo = evento.base64;
        break;
    }
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
  //this.imagenFotoRecortada = '';
  //this.eventoCambioImagenFotografia = '';
  //}


  /* MÉTODOS DEL STEP DE DIRECCIÓN */
  obtenerCiudades(idEstado: number): void {
    this.ciudades = [];
    this.obtenerCiudadesSubscripcion = this._ubicacionService.obtenerCiudades(Number(idEstado)).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  /* MÉTODOS PARA ACTUALIZAR EL RESTAURANTE */
  async actualizarRestaurante() {
    this.estadoPeticionActualizarRestaurante = RequestState.loading;
    const codigoPostal = await this.verificarCodigoPostal(this.restaurante.idEstado, this.codigoPostal);
  if (!codigoPostal) {
    this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
      this.estadoPeticionActualizarRestaurante = RequestState.initial;
      this.direccionRestauranteForm.controls['codigoPostal1'].setErrors({ invalido: true });
    } else {
      this.restaurante.idCodigoPostal = codigoPostal.id;
      this.actualizarRestauranteSubscription = this._restaurantesService.actualizarRestaurante(this.data._id, this.restaurante).subscribe(
        (restaurante: Restaurante) => {
          this.estadoPeticionActualizarRestaurante = RequestState.success;
          this.actualizarRestauranteOriginal(restaurante);
          this.modal.close(true);
        },
        (error: HttpErrorResponse) => {
          this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          this.estadoPeticionActualizarRestaurante = RequestState.error;
        }
      );
    }
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  actualizarRestauranteOriginal(restaurante: Restaurante): void {
    this.data.nombre = restaurante.nombre;
    this.data.descripcion = restaurante.descripcion;
    this.data.rfc = restaurante.rfc;
    this.data.telefono = restaurante.telefono;
    this.data.foto = restaurante.foto;
    this.data.logo = restaurante.logo;
    this.data.idEstado = restaurante.idEstado;
    this.data.estado = restaurante.estado;
    this.data.idCiudad = restaurante.idCiudad;
    this.data.ciudad = restaurante.ciudad;
    this.data.calle = restaurante.calle;
    this.data.colonia = restaurante.colonia;
    this.data.numeroExterior = restaurante.numeroExterior;
    this.data.numeroInterior = restaurante.numeroInterior;
    this.data.idCodigoPostal = restaurante.idCodigoPostal;
    this.data.codigoPostal = restaurante.codigoPostal;
    this.data.propinasActivas = restaurante.propinasActivas;
    this.data.descuentosActivos = restaurante.descuentosActivos;
    this.data.cuponesActivos = restaurante.cuponesActivos;
    this.data.ivaActivo = restaurante.ivaActivo;
    this.data.porcentajeIva = restaurante.porcentajeIva;
    this.data._idLicencia = restaurante._idLicencia;
    this.data.licencia = restaurante.licencia;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerRestauranteSubscripcion) this.obtenerRestauranteSubscripcion.unsubscribe();
    if (this.obtenerLicenciasVigentesSubscripcion) this.obtenerLicenciasVigentesSubscripcion.unsubscribe();
    if (this.obtenerEstadosSubscripcion) this.obtenerEstadosSubscripcion.unsubscribe();
    if (this.obtenerCiudadesSubscripcion) this.obtenerCiudadesSubscripcion.unsubscribe();
    if (this.actualizarRestauranteSubscription) this.actualizarRestauranteSubscription.unsubscribe();
  }
}
