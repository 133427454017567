import {AppSettings} from '../../../../../../../../configs/app-settings.config';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Paquete} from '../../../../../../../../shared/models/paquete';
import {PromoSizeComponent} from '../../dialogs/promo-size/promo-size.component';
import {MatDialog} from '@angular/material';
import {Orden} from '../../../../../../../../shared/models/orden';
import { VistaPaqueteModalComponent } from 'src/app/modules/promo/vista-paquete-modal/vista-paquete-modal.component';

@Component({
  selector: 'app-menu-promo',
  templateUrl: './menu-promo.component.html'
})
export class MenuPromoComponent implements OnInit {

  @Input() promo: Paquete;
  @Input() idSucursal: String;
  @Output() orden = new EventEmitter<Orden>();

  appSettings = AppSettings;
  control: number;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.control = 0;
  }

  add(): void {
    const dialogRef = this.dialog.open(PromoSizeComponent,
      {
        disableClose: true,
        data: {
          id: this.promo.id,
          nombre: this.promo.nombre,
          idSucursal: this.idSucursal,
        }
      });
    dialogRef.afterClosed().subscribe(
      res => {
        if (res.ok) {
          this.orden.emit(res.orden);
        }
      }
    );
  }

  detalles() {
    const dialogRef = this.dialog.open(VistaPaqueteModalComponent,
      {
        disableClose: true,
        data: {
          id: this.promo.id,
          nombre: this.promo.nombre
        }
      });
  }
}
