import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Subscription } from 'rxjs';
import { ExistenciasPorTamanoService } from 'src/app/core/servicios/existencias-por-tamano.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { ExistenciaPorTamano } from 'src/app/shared/class-models/existencia-por-tamano.model';
import { TipoMovimientoExistencia } from 'src/app/shared/enums/tipo-movimiento-existencia.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { FormGroup } from '@angular/forms';
import { AppSettings } from 'src/app/configs/app-settings.config';

@Component({
  selector: 'app-actualizar-existencias-por-tamano',
  templateUrl: './actualizar-existencias-por-tamano.component.html',
  styleUrls: ['./actualizar-existencias-por-tamano.component.scss']
})
export class ActualizarExistenciasPorTamanoComponent implements OnInit, OnDestroy {
  @ViewChild('existenciaPorTamanoForm') existenciaPorTamanoForm: FormGroup;
  titulo = 'Actualizar existencias';
  RequestState = RequestState;
  existenciasPorTamano: ExistenciaPorTamano[];
  ExpresionesRegulares = ExpresionesRegulares;
  estadoPeticionObtenerExistenciasDeProductoPorTamano: number;
  obtenerExistenciasDeProductoPorTamanoSubscripcion: Subscription;
  TipoMovimientoExistencia = TipoMovimientoExistencia;
  existenciaPorTamanoActualizar: ExistenciaPorTamano;
  tipoMovimientoExistencia: number;
  cantidadMovimientoExistencia: number | string;
  descripcionMovimientoExistencia: string;
  estadoPeticionActualizarExistenciaDeProductoPorTamano: number;
  actualizarEstadoDeExistenciaPorTamanoSubscription: Subscription;
  agregarExistenciaPorTamanoSubscripcion: Subscription;
  AppSettings = AppSettings;

  constructor(
    private _existenciasPorTamanoService: ExistenciasPorTamanoService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<ActualizarExistenciasPorTamanoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { _idRestaurante: string, producto: Producto },
  ) {
    this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.initial;
    this.estadoPeticionActualizarExistenciaDeProductoPorTamano = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerExistenciasDeProductoPorTamano();
  }

  obtenerExistenciasDeProductoPorTamano(): void {
    this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.loading;
    this.obtenerExistenciasDeProductoPorTamanoSubscripcion = this._existenciasPorTamanoService.obtenerExistenciasDeProductoPorTamano(this.data._idRestaurante, this.data.producto._id).subscribe(
      (producto: Producto) => {
        this.prepararExistenciasPorTamano(producto);
        this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerExistenciasDeProductoPorTamano = RequestState.error;
        this.modal.close();
      }
    );
  }

  prepararExistenciasPorTamano(producto: Producto): void {
    this.existenciasPorTamano = [];
    producto.productoPorTamano.forEach(productoPorTamano => {
      let existenciaPorTamano: ExistenciaPorTamano = new ExistenciaPorTamano();
      existenciaPorTamano._idTamano = productoPorTamano._idTamano;
      existenciaPorTamano.tamano = productoPorTamano.tamano;
      existenciaPorTamano._idProducto = this.data.producto._id;
      let posicionExistenciaPorTamano = producto.existenciasPorTamano.findIndex(existenciaPorTamano => {
        return existenciaPorTamano._idTamano == productoPorTamano._idTamano;
      })
      if (posicionExistenciaPorTamano != -1) {
        existenciaPorTamano._id = producto.existenciasPorTamano[posicionExistenciaPorTamano]._id;
        existenciaPorTamano.cantidad = producto.existenciasPorTamano[posicionExistenciaPorTamano].cantidad;
      } else {
        existenciaPorTamano._id = null;
        existenciaPorTamano.cantidad = 0;
      }
      this.existenciasPorTamano.push(existenciaPorTamano);
    });
  }

  existenciaPorTamanoSeleccionada(existenciaPorTamano: ExistenciaPorTamano): void {
    this.tipoMovimientoExistencia = TipoMovimientoExistencia.Aumentar;
    this.cantidadMovimientoExistencia = 1;
    this.descripcionMovimientoExistencia = null;
    this.existenciaPorTamanoActualizar = existenciaPorTamano;
  }

  obtenerTipoMovimientoExistencia(tipoMovimientoExistencia: number): string {
    switch (tipoMovimientoExistencia) {
      case TipoMovimientoExistencia.Aumentar: return 'Aumentar';
      case TipoMovimientoExistencia.Disminuir: return 'Disminuir';
    }
  }

  transformarCantidadMovimientoExistencia(cantidadMovimientoExistencia: string): void {
    if (cantidadMovimientoExistencia != null && cantidadMovimientoExistencia != undefined && !isNaN(Number(cantidadMovimientoExistencia))) {
      if (Number(cantidadMovimientoExistencia) < 1) {
        this.cantidadMovimientoExistencia = '1';
      } else {
        this.cantidadMovimientoExistencia = Number(cantidadMovimientoExistencia).toFixed(0);
      }
    } else {
      this.cantidadMovimientoExistencia = '1';
    }
  }

  verificarCantidadMovimiento() {
    if (this.cantidadMovimientoExistencia != null && this.cantidadMovimientoExistencia != undefined && !isNaN(Number(this.cantidadMovimientoExistencia))) {
      if (
        Number(this.cantidadMovimientoExistencia) > 1 &&
        Number(this.cantidadMovimientoExistencia) > this.existenciaPorTamanoActualizar.cantidad &&
        this.tipoMovimientoExistencia == TipoMovimientoExistencia.Disminuir) {
        this.existenciaPorTamanoForm.controls['cantidadMovimientoExistencia1'].setErrors({ invalida: true });
      }
    }
  }

  /* MÉTODOS PARA AGREGAR EXISTENCIA POR TAMAÑO EN COCINA */
  async actualizarExistenciaDeProductoPorTamano() {
    this.estadoPeticionActualizarExistenciaDeProductoPorTamano = RequestState.loading;
    const existenciaPorTamano = this.prepararDatosExistenciaPorTamano();
    try {
      if (this.existenciaPorTamanoActualizar._id == null) {
        await this.agregarExistenciaPorTamano(existenciaPorTamano);
      } else {
        await this.actualizarEstadoDeExistenciaPorTamano(existenciaPorTamano);
      }
      this._alertasService.alertaExitoConConfirmacion('Existencia actualizada con éxito', 'Se ha actualizado exitosamente la existencia del tamaño ' + this.existenciaPorTamanoActualizar.tamano.nombre);
      this.existenciaPorTamanoActualizar = null;
      this.estadoPeticionActualizarExistenciaDeProductoPorTamano = RequestState.success;
    } catch (error) {
      this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      this.estadoPeticionActualizarExistenciaDeProductoPorTamano = RequestState.error;
    }
  }

  async agregarExistenciaPorTamano(existenciaPorTamano: ExistenciaPorTamano): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.agregarExistenciaPorTamanoSubscripcion = this._existenciasPorTamanoService.agregarExistenciaPorTamano(this.data._idRestaurante, this.data.producto.existencia._id, existenciaPorTamano, this.descripcionMovimientoExistencia).subscribe(
        (existenciaPorTamanoGuardada: ExistenciaPorTamano) => {
          this.data.producto.totalDeExistencias += existenciaPorTamano.cantidad;
          this.existenciaPorTamanoActualizar._id = existenciaPorTamanoGuardada._id;
          this.existenciaPorTamanoActualizar.cantidad = existenciaPorTamanoGuardada.cantidad;
          resolve(true);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  async actualizarEstadoDeExistenciaPorTamano(existenciaPorTamano: ExistenciaPorTamano): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.actualizarEstadoDeExistenciaPorTamanoSubscription = this._existenciasPorTamanoService.actualizarEstadoDeExistenciaPorTamano(this.data._idRestaurante, this.data.producto.existencia._id, existenciaPorTamano._id, existenciaPorTamano.cantidad, this.descripcionMovimientoExistencia).subscribe(
        (existenciaPorTamanoGuardada: ExistenciaPorTamano) => {
          this.data.producto.totalDeExistencias += existenciaPorTamano.cantidad;
          this.existenciaPorTamanoActualizar.cantidad = existenciaPorTamanoGuardada.cantidad;
          resolve(true);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    })
  }

  prepararDatosExistenciaPorTamano(): ExistenciaPorTamano {
    let existenciaPorTamano = new ExistenciaPorTamano();
    if (this.existenciaPorTamanoActualizar._id) existenciaPorTamano._id = this.existenciaPorTamanoActualizar._id;
    existenciaPorTamano._idProducto = this.existenciaPorTamanoActualizar._idProducto;
    existenciaPorTamano._idTamano = this.existenciaPorTamanoActualizar._idTamano;
    switch (this.tipoMovimientoExistencia) {
      case TipoMovimientoExistencia.Aumentar:
        existenciaPorTamano.cantidad = parseInt(this.cantidadMovimientoExistencia.toString());
        break;
      case TipoMovimientoExistencia.Disminuir:
        existenciaPorTamano.cantidad = parseInt(this.cantidadMovimientoExistencia.toString()) * (-1);
        break;
    }
    return existenciaPorTamano;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cerrar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerExistenciasDeProductoPorTamanoSubscripcion) this.obtenerExistenciasDeProductoPorTamanoSubscripcion.unsubscribe();
    if (this.actualizarEstadoDeExistenciaPorTamanoSubscription) this.actualizarEstadoDeExistenciaPorTamanoSubscription.unsubscribe();
    if (this.agregarExistenciaPorTamanoSubscripcion) this.agregarExistenciaPorTamanoSubscripcion.unsubscribe();
  }
}
