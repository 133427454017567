import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { RequestState } from '../../shared/enums/request-state.enum';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ProductoPorTamano, Producto } from 'src/app/shared/class-models/producto.model';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { EditProductComponent } from '../products/modals/edit-product/edit-product.component';
import { HttpErrorResponse } from '@angular/common/http';
import { VistaIngredienteModalComponent } from '../ingredient/vista-ingrediente-modal/vista-ingrediente-modal.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  estadoPeticionObtenerProducto: number;
  obtenerProductoSubscription: Subscription;
  eliminarProductoSubscription: Subscription;
  producto: Producto;

  /* PRECIOS POR TAMAÑO */
  @ViewChild('paginatorPreciosPorTamano', { read: MatPaginator, static: false }) paginatorPreciosPorTamano: MatPaginator;
  productoPorTamano: MatTableDataSource<ProductoPorTamano>;
  columnasPreciosPorTamano: string[] = ['tamano', 'precio'];

  /* INGREDIENTES */
  @ViewChild('paginatorIngredientes', { read: MatPaginator, static: false }) paginatorIngredientes: MatPaginator;
  ingredientes: MatTableDataSource<Ingrediente>;
  columnasIngredientes: string[] = ['nombre', 'opciones'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private _productosService: ProductosService,
    private _alertasService: AlertasService,
    private router: Router,
    public modal: MatDialog
  ) {
    this.estadoPeticionObtenerProducto = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProducto();
  }

  obtenerProducto(): void {
    this.estadoPeticionObtenerProducto = RequestState.loading;
    this.obtenerProductoSubscription = this._productosService.obtenerProducto(this.activatedRoute.snapshot.paramMap.get('idProducto')).subscribe(
      (producto: Producto) => {
        this.producto = producto;
        this.inicializarPreciosPorTamanoProducto();
        this.inicializarIngredientesProducto();
        this.estadoPeticionObtenerProducto = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProducto = RequestState.error;
        this.router.navigate(['/productos']);
      }
    );
  }

  inicializarPreciosPorTamanoProducto(): void {
    this.productoPorTamano = new MatTableDataSource<ProductoPorTamano>(this.producto.productoPorTamano);
    this.productoPorTamano.paginator = this.paginatorPreciosPorTamano;
  }

  inicializarIngredientesProducto(): void {
    this.ingredientes = new MatTableDataSource<Ingrediente>(<Ingrediente[]>this.producto.ingredientes);
    this.ingredientes.paginator = this.paginatorIngredientes;
  }

  verIngrediente(ingrediente: Ingrediente) {
    const dialogRef = this.modal.open(VistaIngredienteModalComponent,
      {
        disableClose: true,
        data: ingrediente
      });
  }

  actualizarProducto(producto: Producto): void {
    const dialogRef = this.modal.open(EditProductComponent, { disableClose: true, data: producto });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Producto actualizado exitosamente', 'El producto ' + producto.nombre + ' ha sido actualizado con éxito.');
          this.inicializarPreciosPorTamanoProducto();
          this.inicializarIngredientesProducto();
        }
      }
    );
  }

  eliminarProducto(producto: Producto): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el producto ' + producto.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarProductoSubscription = this._productosService.eliminarProducto(producto._id).subscribe(
          (resultado: boolean) => {
            this._alertasService.alertaExitoSinConfirmacion('Producto eliminado exitosamente', 'El producto ' + producto.nombre + ' ha sido eliminado con éxito.');
            this.router.navigate(['/productos']);
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerProductoSubscription) this.obtenerProductoSubscription.unsubscribe();
    if (this.eliminarProductoSubscription) this.eliminarProductoSubscription.unsubscribe();
  }
}
