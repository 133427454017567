
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';


export class CajaDeUsuario {
    _id?: string;
    id?: number;
    fechaApertura: Date;
    fechaCierre: Date;
    montoApertura: number | string;
    montoCierreEsperado: number | string;
    montoCierreReal: number | string;
    montoVentaEfectivo: number;
    montoVentaTarjeta: number;
    montoVentaTotal: number;
    _idRestaurante: string;
    restaurante?: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor(){
        this.montoApertura = '0.00';
    }
}