import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Orden} from '../../../../../../../../shared/models/orden';
import {OrderService} from '../../../../../../../../core/services/order.service';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../../../../../shared/enums/request-state.enum';
import {ElementoArea} from '../../../../../../../../shared/models/elementoArea';
import { GrupoOrdenes } from 'src/app/shared/models/grupo-ordenes';
import { BillService } from 'src/app/core/services/bill.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: []
})
export class SummaryComponent implements OnInit, OnDestroy {
  ordersSubscription: Subscription;
  gruposOrdenes: GrupoOrdenes[];
  ordenPrincipal: Orden[][];
  ordenesAgrupadas: Orden[][][];
  requestState: number;

  constructor(public dialogRef: MatDialogRef<SummaryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ElementoArea,
              private orderService: OrderService,
              private billService: BillService) {
  }

  ngOnInit() {
    this.requestState = RequestState.loading;
    this.ordenPrincipal = [];
    this.ordenesAgrupadas = [];
    this.ordersSubscription = this.orderService.obtenerOrdenesComanda(this.data.comanda.id).subscribe(
      (gruposOrdenes: GrupoOrdenes[]) => {
        this.gruposOrdenes = gruposOrdenes;
        this.gruposOrdenes.forEach((grupoOrdenes, posicionGrupoOrdenes) => {
          this.ordenPrincipal.push([]);
          this.ordenesAgrupadas.push([]);
          grupoOrdenes.ordenes.forEach((orden) => {
            const posicion = this.ordenPrincipal[posicionGrupoOrdenes].findIndex(element => {
              if (element.precioPorTamano.tamano.id === orden.precioPorTamano.tamano.id) {
                if (element.datosProducto !== null && orden.datosProducto !== null
                  && element.datosProducto.producto.id === orden.datosProducto.producto.id) {
                  return true;
                }
                if (element.datosPaquete !== null && orden.datosPaquete !== null
                  && element.datosPaquete.paquete.id === orden.datosPaquete.paquete.id) {
                  return true;
                }
              }
            });
            if (posicion === -1) {
              this.ordenPrincipal[posicionGrupoOrdenes].push({
                datosProducto: orden.datosProducto,
                datosPaquete: orden.datosPaquete,
                cantidad: 1,
                precioPorTamano: orden.precioPorTamano,
                cancelada: null,
                fecha: null,
              });
              this.ordenesAgrupadas[posicionGrupoOrdenes].push([]);
              this.ordenesAgrupadas[posicionGrupoOrdenes][this.ordenesAgrupadas[posicionGrupoOrdenes].length - 1].push(orden);
            } else {
              this.ordenPrincipal[posicionGrupoOrdenes][posicion].cantidad += 1;
              this.ordenesAgrupadas[posicionGrupoOrdenes][posicion].push(orden);
            }
          });
        });
        this.requestState = RequestState.success;
      }
    );
  }

  imprimirGrupoOrdenes(posicionGrupoOrdenes: number){
    this.billService.setTipoImpresion(1);
    this.billService.setGrupoOrdenes(this.gruposOrdenes[posicionGrupoOrdenes]);
    this.billService.setOrdenPrincipal(this.ordenPrincipal[posicionGrupoOrdenes]);
    this.billService.setOrdenesAgrupadas(this.ordenesAgrupadas[posicionGrupoOrdenes]);
    this.dialogRef.close(
      {
        imprimir: true,
      }
    );
  }

  close(): void {
    this.dialogRef.close(
      {
        imprimir: false,
      }
    );
  }

  ngOnDestroy(): void {
    this.ordersSubscription.unsubscribe();
  }
}
