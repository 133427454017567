import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppSettings} from '../../../../configs/app-settings.config';
import { Paquete } from 'src/app/shared/class-models/paquete.model';

@Component({
  selector: 'app-promo-card',
  templateUrl: './promo-card.component.html'
})
export class PromoCardComponent implements OnInit {
  @Input() paquete: Paquete;
  @Input() verTitulo: boolean;
  @Input() verVerMas: boolean;
  @Input() verConfiguracion: boolean;
  @Input() verActivo: boolean;
  @Output() edit = new EventEmitter<Paquete>();
  @Output() delete = new EventEmitter<Paquete>();
  AppSettings = AppSettings;

  constructor() {
  }

  ngOnInit() {
  }

  mostrarOpcionesConfiguracion() {
    if (!document.getElementById(`configurar${this.paquete.id}`).classList.contains('rollIn')) {
      document.getElementById(`configurar${this.paquete.id}`).classList.add('rollIn');
      document.getElementById(`configurar${this.paquete.id}`).classList.remove('rollOut');
      document.getElementById(`editar${this.paquete.id}`).classList.add('fadeInUp-2x');
      document.getElementById(`editar${this.paquete.id}`).classList.remove('fadeOutDown-2x');
      document.getElementById(`eliminar${this.paquete.id}`).classList.add('fadeInUp');
      document.getElementById(`eliminar${this.paquete.id}`).classList.remove('fadeOutDown');
    } else {
      document.getElementById(`configurar${this.paquete.id}`).classList.add('rollOut');
      document.getElementById(`configurar${this.paquete.id}`).classList.remove('rollIn');
      document.getElementById(`editar${this.paquete.id}`).classList.add('fadeOutDown-2x');
      document.getElementById(`editar${this.paquete.id}`).classList.remove('fadeInUp-2x');
      document.getElementById(`eliminar${this.paquete.id}`).classList.add('fadeOutDown');
      document.getElementById(`eliminar${this.paquete.id}`).classList.remove('fadeInUp');
    }
  }

  deletePromo(): void {
    this.delete.emit(this.paquete);
  }

  editPromo(): void {
    this.edit.emit(this.paquete);
  }
}
