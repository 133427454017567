import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { UnidadDeMedida } from 'src/app/shared/class-models/unidad-de-medida.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { UnidadesDeMedidaService } from 'src/app/core/servicios/unidad-de-medida.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AltaUnidadDeMedidaComponent } from './alta-unidad-de-medida/alta-unidad-de-medida.component';
import { ActualizacionUnidadDeMedidaComponent } from './actualizacion-unidad-de-medida/actualizacion-unidad-de-medida.component';

@Component({
  selector: 'app-unidades-de-medida',
  templateUrl: './unidades-de-medida.component.html',
  styleUrls: ['./unidades-de-medida.component.scss']
})
export class UnidadesDeMedidaComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<UnidadDeMedida>;
  filtro: string = '';
  estadoPeticionObtenerUnidadesDeMedida: number;
  RequestState = RequestState;
  obtenerUnidadesDeMedidaSubscripcion: Subscription;
  eliminarUnidadDeMedidaSubscripcion: Subscription;
  columnas = ['nombre', 'abreviacion', 'cantidadDeDecimales', 'opciones'];

  constructor(
    private _unidadesDeMedidaService: UnidadesDeMedidaService,
    private _alertasService: AlertasService,
    public dialog: MatDialog) {
    this.estadoPeticionObtenerUnidadesDeMedida = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerUnidadesDeMedida();
  }

  obtenerUnidadesDeMedida(): void {
    this.estadoPeticionObtenerUnidadesDeMedida = RequestState.loading;
    this.obtenerUnidadesDeMedidaSubscripcion = this._unidadesDeMedidaService.obtenerUnidadesDeMedida().subscribe(
      (unidadesDeMedida: UnidadDeMedida[]) => {
        this.inicializarUnidadesDeMedida(unidadesDeMedida);
        this.estadoPeticionObtenerUnidadesDeMedida = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUnidadesDeMedida = RequestState.error;
      }
    );
  }

  inicializarUnidadesDeMedida(unidadesDeMedida: UnidadDeMedida[]): void {
    this.dataSource = new MatTableDataSource<UnidadDeMedida>(unidadesDeMedida);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (unidadDeMedida: UnidadDeMedida, filtro: string) => {
      return unidadDeMedida.nombre.trim().toLowerCase().indexOf(filtro) !== -1 ||
        unidadDeMedida.abreviacion.trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarUnidadDeMedida(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarUnidadDeMedida(): void {
    const dialogRef = this.dialog.open(AltaUnidadDeMedidaComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (unidadDeMedida: UnidadDeMedida) => {
        if (unidadDeMedida) {
          this._alertasService.alertaExitoSinConfirmacion('Unidad de medida agregada exitosamente', 'La unidad de medida ' + unidadDeMedida.nombre + ' ha sido agregada con éxito');
          this.dataSource.data.push(unidadDeMedida);
          const unidadesDeMedida: UnidadDeMedida[] = this.dataSource.data;
          this.inicializarUnidadesDeMedida(unidadesDeMedida);
          this.buscarUnidadDeMedida();
        }
      }
    );
  }

  actualizarUnidadDeMedida(unidadDeMedida: UnidadDeMedida): void {
    const dialogRef = this.dialog.open(ActualizacionUnidadDeMedidaComponent, { disableClose: true, data: unidadDeMedida });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
          if (resultado == true) {
            this._alertasService.alertaExitoSinConfirmacion('Unidad de medida actualizada exitosamente', 'La unidad de medida ' + unidadDeMedida.nombre + ' ha sido actualizada con éxito.');
            this.buscarUnidadDeMedida();
          }
      }
    );
  }

  eliminarUnidadDeMedida(unidadDeMedidaEliminar: UnidadDeMedida): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar la unidad de medida ' + unidadDeMedidaEliminar.nombre + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarUnidadDeMedidaSubscripcion = this._unidadesDeMedidaService.eliminarUnidadDeMedida(unidadDeMedidaEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionUnidadDeMedida = this.dataSource.data.findIndex((unidadDeMedida: UnidadDeMedida) => {
                return unidadDeMedida._id === unidadDeMedidaEliminar._id;
              });
              let unidadesDeMedidaActualizados: UnidadDeMedida[] = this.dataSource.data;
              if (posicionUnidadDeMedida != -1) {
                unidadesDeMedidaActualizados.splice(posicionUnidadDeMedida, 1);
              }
              this.inicializarUnidadesDeMedida(unidadesDeMedidaActualizados);
              this.buscarUnidadDeMedida();
              this._alertasService.alertaExitoSinConfirmacion('Unidad de medida eliminada exitosamente', 'La unidad de medida ' + unidadDeMedidaEliminar.nombre + ' ha sido eliminada con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerUnidadesDeMedidaSubscripcion) this.obtenerUnidadesDeMedidaSubscripcion.unsubscribe();
    if (this.eliminarUnidadDeMedidaSubscripcion) this.eliminarUnidadDeMedidaSubscripcion.unsubscribe();
  }
}
