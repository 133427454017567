import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material';
import {InventoryService} from '../../../../../../core/services/inventory.service';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {Magnitude} from '../../magnitudes';

@Component({
  selector: 'app-inventory-add-input',
  templateUrl: './inventory-add-input.component.html'
})
export class InventoryAddInputComponent implements OnInit, OnDestroy {

  disableBtn: boolean;
  form: FormGroup;
  requestState: number;
  dataSubscription: Subscription;
  formSubscription: Subscription;
  timeout: number;
  title = 'Agregar Insumo';
  unidades = Magnitude;
  @ViewChild('dialogWarning', {static: true}) private dialogWarning: SwalComponent;

  constructor(public dialogRef: MatDialogRef<InventoryAddInputComponent>,
              private inventoryService: InventoryService) {
    this.form = new FormGroup(
      {
        id: new FormControl(''),
        activo: new FormControl(''),
        fechaRegistro: new FormControl(''),
        idUsuario: new FormControl(''),
        nombre: new FormControl('', Validators.required),
        descripcion: new FormControl(''),
        unidadMedida: new FormControl('', Validators.required)
      }
    );
  }

  ngOnInit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.initial;
    this.formSubscription = this.form.valueChanges.subscribe(
      () => this.disableBtn = !this.form.valid
    );
  }

  ngOnDestroy(): void {
    if (this.dataSubscription != null) {
      this.dataSubscription.unsubscribe();
    }
    this.formSubscription.unsubscribe();
  }

  add(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.dataSubscription = this.inventoryService.agregarInsumo(this.form.value).subscribe(
      res => {
        this.requestState = RequestState.success;
        this.form.setValue(res[0]);
        this.dialogRef.close({ok: true, form: this.form.getRawValue()});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogWarning.show().then(
      data => {
        if (data.value) {
          this.dialogRef.close({ok: false});
        }
      }
    );
  }
}
