import { Usuario } from './usuario.model';

export class Ingrediente {
    _id?: string;
    id?: number;
    nombre: string;
    descripcion?: string;
    foto?: string;
    precio?: number | string;
    esExtra: boolean;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.nombre = '';
        this.descripcion = '';
        this.foto = '';
        this.esExtra = false;
        this.precio = '0.00';
    }
  }
  