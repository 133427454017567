import {NgModule} from '@angular/core';
import {PhonePipe} from './phone.pipe';
import {FolioPipe} from './folio.pipe';
import { FolioSinEspaciosPipe } from './folio-sin-espacios.pipe';
import { FechaPipe } from './fecha.pipe';
import { FechaConHoraPipe } from './fecha-con-hora.pipe';

@NgModule({
  declarations: [
    PhonePipe,
    FolioPipe,
    FolioSinEspaciosPipe,
    FechaPipe,
    FechaConHoraPipe
  ],
  exports: [
    PhonePipe,
    FolioPipe,
    FolioSinEspaciosPipe,
    FechaPipe,
    FechaConHoraPipe
  ]
})
export class PipesModule {
}
