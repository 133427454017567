import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { CategoriasService } from 'src/app/core/servicios/categorias.service';
import { Categoria } from 'src/app/shared/class-models/categoria.model';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { HttpErrorResponse } from '@angular/common/http';
import { VistaProductoModalComponent } from 'src/app/modules/product/vista-producto-modal/vista-producto-modal.component';

@Component({
  selector: 'app-menu-productos',
  templateUrl: './menu-productos.component.html',
  styleUrls: ['./menu-productos.component.scss']
})
export class MenuProductosComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  AppSettings = AppSettings;
  @ViewChild('paginadorProductos') paginadorProductos: MatPaginator;
  obtenerProductosSubscripcion: Subscription;
  productos: MatTableDataSource<Producto>;
  productosObservable: Observable<any>;
  estadoPeticionObtenerProductos: number;
  filtroBusqueda: string = '';
  filtro_IdCategoria: string = '';
  categorias: Categoria[];
  obtenerCategoriasSubscripcion: Subscription;

  constructor(
    private _productosService: ProductosService,
    private _categoriasService: CategoriasService,
    private _alertasService: AlertasService,
    public modal: MatDialog
  ) {
    this.estadoPeticionObtenerProductos = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProductos();
    this.obtenerCategorias();
  }

  obtenerProductos() {
    this.estadoPeticionObtenerProductos = RequestState.loading;
    this.obtenerProductosSubscripcion = this._productosService.obtenerProductos().subscribe(
      (productos: Producto[]) => {
        this.inicializarProductos(productos);
        this.estadoPeticionObtenerProductos = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProductos = RequestState.error;
      }
    );
  }

  inicializarProductos(productos: Producto[]): void {
    this.productos = new MatTableDataSource<Producto>(productos);
    this.productos.paginator = this.paginadorProductos;
    this.productos.filterPredicate = (producto: Producto, filtro: string) => {
      return producto.nombre.trim().toLowerCase().indexOf(this.filtroBusqueda.trim().toLowerCase()) !== -1 &&
        (this.filtro_IdCategoria == '' || producto._idCategoria == this.filtro_IdCategoria);
    }
    this.productosObservable = this.productos.connect();
  }

  buscarProducto(): void {
    //Esta verificacion se hace con el fin de identificar que alguno de los dos filtros tenga valor para que se pueda llevar a cabo la búsqueda
    let filtro = this.filtroBusqueda != '' ? this.filtroBusqueda : this.filtro_IdCategoria;
    this.productos.filter = filtro.trim().toLowerCase();
  }

  obtenerCategorias() {
    this.categorias = [];
    this.obtenerCategoriasSubscripcion = this._categoriasService.obtenerCategorias().subscribe(
      (categorias: Categoria[]) => {
        this.categorias = categorias
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  verProducto(producto: Producto) {
    const dialogRef = this.modal.open(VistaProductoModalComponent,
      {
        disableClose: true,
        data: producto
      });
  }

  ngOnDestroy(): void {
    if (this.productos) this.productos.disconnect();
    if (this.obtenerProductosSubscripcion) this.obtenerProductosSubscripcion.unsubscribe();
    if (this.obtenerCategoriasSubscripcion) this.obtenerCategoriasSubscripcion.unsubscribe();
  }
}
