import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';
import { ElementosPorAreaService } from 'src/app/core/servicios/elementos-por-area.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { Elemento } from 'src/app/shared/class-models/elemento.model';
import { ElementosService } from 'src/app/core/servicios/elementos.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AgregarElementoConfiguracionComponent } from 'src/app/modules/configuration/elementos-configuracion/agregar-elemento-configuracion/agregar-elemento-configuracion.component';
import { TipoArea } from 'src/app/shared/enums/tipo-area.enum';

@Component({
  selector: 'app-new-table',
  templateUrl: './new-table.component.html',
  styleUrls: []
})
export class NewTableComponent implements OnInit, OnDestroy {
  titulo: string;
  ExpresionesRegulares = ExpresionesRegulares;
  elementoPorArea: ElementoPorArea = new ElementoPorArea();
  RequestState = RequestState;
  estadoPeticionAgregarElementoPorArea: number;
  agregarElementoPorAreaSubscription: Subscription;
  estadoPeticionObtenerElementos: number;
  obtenerElementosSubscription: Subscription;
  elementos: Elemento[];
  TipoArea = TipoArea;

  constructor(
    private _elementosService: ElementosService,
    private _elementosPorAreaService: ElementosPorAreaService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NewTableComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { _idRestaurante: string, _idArea: string, tipoDeArea: number },
    ) {
    switch(this.data.tipoDeArea) {
      case TipoArea.Normal: 
        this.titulo = 'Agregar mesa';
        break;
      case TipoArea.Barra: 
        this.titulo = 'Agregar espacio en la barra';
        break;
    }
    this.estadoPeticionObtenerElementos = RequestState.initial;
    this.estadoPeticionAgregarElementoPorArea = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerElementosDeArea();
  }

  obtenerElementosDeArea(): void {
    this.estadoPeticionObtenerElementos = RequestState.loading;
    this.obtenerElementosSubscription = this._elementosService.obtenerElementosDeArea(this.data._idArea).subscribe(
      (elementos: Elemento[]) => {
        this.elementos = elementos;
        this.estadoPeticionObtenerElementos = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerElementos = RequestState.error;
        this.modal.close();
      }
    );
  }

  agregarElemento() {
    const dialogRef = this.dialog.open(AgregarElementoConfiguracionComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (elemento: Elemento) => {
        if (elemento != null) {
          this._alertasService.alertaExitoSinConfirmacion('Tipo de mesa agregado exitosamente', 'El tipo de mesa con ' + elemento.numeroAsientos + ' ' + (elemento.numeroAsientos == 1  ? 'asiento' : 'asientos') + ' ha sido agregado con éxito.');
          this.elementos.push(elemento);
        }
      }
    );
  }

  obtenerTextoAgregandoElementoPorArea() {
    switch(this.data.tipoDeArea) {
      case TipoArea.Normal: return 'Agregando mesa';
      case TipoArea.Barra: return 'Agregando espacio en la barra';
      default: return '';
    }
  }

  obtenerTextoNombreElementoPorArea() {
    switch(this.data.tipoDeArea) {
      case TipoArea.Normal: return 'Número de mesa';
      case TipoArea.Barra: return 'Número de espacio en la barra';
      default: return '';
    }
  }

  obtenerTextoPlaceholderNombreElementoPorArea() {
    switch(this.data.tipoDeArea) {
      case TipoArea.Normal: return 'Ingresa el número de la mesa';
      case TipoArea.Barra: return 'Ingresa el número del espacio en la barra';
      default: return '';
    }
  }

  obtenerTextoErrorCampoRequeridoNombreElementoPorArea() {
    switch(this.data.tipoDeArea) {
      case TipoArea.Normal: return 'Por favor ingresa el número de la mesa';
      case TipoArea.Barra: return 'Por favor ingresa el número del espacio en la barra';
      default: return '';
    }
  }

  transformarNumeroDeMesa(numeroDeMesa: string): void {
    if (numeroDeMesa != null && numeroDeMesa != undefined && !isNaN(Number(numeroDeMesa))) {
      if (Number(numeroDeMesa) < 1) {
        this.elementoPorArea.nombre = '1';
      } else {
        this.elementoPorArea.nombre = Number(numeroDeMesa).toFixed(0);
      }
    } else {
      this.elementoPorArea.nombre = '1';
    }
  }

  agregarElementoPorArea(): void {
    if(this.data.tipoDeArea == TipoArea.Barra) this.elementoPorArea._idElemento = this.elementos[0]._id;
    this.estadoPeticionAgregarElementoPorArea = RequestState.loading;
    this.agregarElementoPorAreaSubscription = this._elementosPorAreaService.agregarElementoPorArea(this.data._idRestaurante, this.data._idArea, this.elementoPorArea).subscribe(
      (elementoPorArea: ElementoPorArea) => {
        this.estadoPeticionAgregarElementoPorArea = RequestState.success;
        this.modal.close(elementoPorArea);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarElementoPorArea = RequestState.error;
      }
    );
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerElementosSubscription) this.obtenerElementosSubscription.unsubscribe();
    if(this.agregarElementoPorAreaSubscription) this.agregarElementoPorAreaSubscription.unsubscribe();
  }
}
