import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administracion-insumos',
  templateUrl: './administracion-insumos.component.html',
  styleUrls: ['./administracion-insumos.component.scss']
})
export class AdministracionInsumosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
