import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { CuentasService } from './cuentas.service';
import { AlertasService } from './alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Orden, AgregarIngrediente, AlternativaProductoPorTamano } from 'src/app/shared/class-models/orden.model';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { OrdenesService } from './ordenes.service';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { GruposDeOrdenesService } from './grupos-de-ordenes.service';

@Injectable({
    providedIn: 'root'
})
export class ImpresionService {
    public tipoImpresion: BehaviorSubject<number>;

    /* VARIABLES PARA LA CUENTA */
    public cuenta: BehaviorSubject<Cuenta>;
    public ordenesAgrupadasCuenta: BehaviorSubject<Orden[]>;

    /* VARIABLES PARA EL GRUPO DE ORDENES */
    public grupoDeOrdenes: BehaviorSubject<GrupoDeOrdenes>;
    public ordenesAgrupadasGrupoDeOrdenes: BehaviorSubject<Orden[]>
    public ordenesPorOrdenesAgrupadasGrupoDeOrdenes: BehaviorSubject<Orden[][]>

    constructor(
        private _cuentasService: CuentasService,
        private _ordenesService: OrdenesService,
        private _gruposDeOrdenesService: GruposDeOrdenesService,
        private _alertasService: AlertasService
    ) {
        this.tipoImpresion = new BehaviorSubject<number>(null);
        this.cuenta = new BehaviorSubject<Cuenta>(null);
        this.ordenesAgrupadasCuenta = new BehaviorSubject<Orden[]>([]);
        this.grupoDeOrdenes = new BehaviorSubject<GrupoDeOrdenes>(null);
        this.ordenesAgrupadasGrupoDeOrdenes = new BehaviorSubject<Orden[]>([]);
        this.ordenesPorOrdenesAgrupadasGrupoDeOrdenes = new BehaviorSubject<Orden[][]>([]);
    }

    /* MÉTODOS PARA EL TIPO DE IMPRESIÓN */
    actualizarTipoImpresion(tipoImpresion: number): void {
        this.tipoImpresion.next(tipoImpresion);
    }

    /********************************************************************************************************/
    /********************************************* C U E N T A **********************************************/
    /********************************************************************************************************/
    /* MÉTODOS PARA ACTUALIZAR LA CUENTA */
    async actualizarCuenta(cuenta: Cuenta) {
        try {
            this.cuenta.next(await this.obtenerCuenta(cuenta.restaurante._id, cuenta._id));
        } catch (error) {
            this._alertasService.alertaErrorSinConfirmacion(error.titulo, error.detalles);
            this.cuenta.next(null);
        }
    }

    private obtenerCuenta(_idRestaurante, _idCuenta): Promise<Cuenta> {
        return new Promise((resolve, reject) => {
            this._cuentasService.obtenerCuenta(_idRestaurante, _idCuenta).subscribe(
                (cuenta: Cuenta) => {
                    resolve(cuenta);
                },
                (error: HttpErrorResponse) => {
                    reject({ titulo: error.error.titulo, detalles: error.error.detalles })
                }
            );
        })
    }

    /* MÉTODOS PARA ACTUALIZAR LAS ORDENES AGRUPADAS */
    async actualizarOrdenesAgrupadasCuenta(cuenta: Cuenta) {
        try {
            const ordenes = await this.obtenerOrdenesCuenta(cuenta.restaurante._id, cuenta._id);
            this.ordenesAgrupadasCuenta.next(this.agruparOrdenesCuenta(ordenes));
        } catch (error) {
            this._alertasService.alertaErrorSinConfirmacion(error.titulo, error.detalles);
            this.ordenesAgrupadasCuenta.next([]);
        }
    }

    private obtenerOrdenesCuenta(_idRestaurante, _idCuenta): Promise<Orden[]> {
        return new Promise((resolve, reject) => {
            this._cuentasService.obtenerOrdenesCuenta(_idRestaurante, _idCuenta).subscribe(
                (ordenes: Orden[]) => {
                    resolve(ordenes);
                },
                (error: HttpErrorResponse) => {
                    reject({ titulo: error.error.titulo, detalles: error.error.detalles })
                }
            );
        })
    }

    private agruparOrdenesCuenta(ordenes: Orden[]): Orden[] {
        let ordenesAgrupadas: Orden[] = [];
        ordenes.forEach(orden => {
            const posicionOrdenAgrupada = this.obtenerPosicionOrdenAgrupadaCuenta(ordenesAgrupadas, orden);
            if (posicionOrdenAgrupada != -1) {
                (<number>ordenesAgrupadas[posicionOrdenAgrupada].cantidad) += <number>orden.cantidad;
            } else {
                ordenesAgrupadas.push(this.crearOrdenAgrupadaCuenta(orden, <number>orden.cantidad));
            }
        })
        return ordenesAgrupadas;
    }

    private obtenerPosicionOrdenAgrupadaCuenta(ordenesAgrupadas: Orden[], orden: Orden): number {
        return ordenesAgrupadas.findIndex(ordenAgrupada => {
            if (ordenAgrupada.tipo == orden.tipo && ordenAgrupada.precioUnitario == orden.precioUnitario &&
                ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano &&
                ordenAgrupada.precioPorTamano.precio == orden.precioPorTamano.precio
            ) {
                switch (ordenAgrupada.tipo) {
                    case TipoOrden.Producto:
                        return this.sonOrdenesTipoProductoIdenticasCuenta(orden, ordenAgrupada);
                    case TipoOrden.Paquete:
                        return this.sonOrdenesTipoPaqueteIdenticasCuenta(orden, ordenAgrupada);
                }
            } else {
                return false;
            }
        })
    }

    private sonOrdenesTipoProductoIdenticasCuenta(orden1: Orden, orden2: Orden): boolean {
        let sonIdenticas: boolean = true;
        if (orden1.datosProducto._idProducto == orden2.datosProducto._idProducto &&
            orden1.datosProducto.agregarIngredientes.length == orden2.datosProducto.agregarIngredientes.length) {
            //Se recorre el array de agregarIngredientes
            for (let posicionAgregarIngrediente = 0; posicionAgregarIngrediente < orden1.datosProducto.agregarIngredientes.length; posicionAgregarIngrediente++) {
                if (orden1.datosProducto.agregarIngredientes[posicionAgregarIngrediente]._idIngrediente != orden2.datosProducto.agregarIngredientes[posicionAgregarIngrediente]._idIngrediente ||
                    orden1.datosProducto.agregarIngredientes[posicionAgregarIngrediente].precio != orden2.datosProducto.agregarIngredientes[posicionAgregarIngrediente].precio) {
                    sonIdenticas = false;
                    break;
                }
            }
        } else {
            sonIdenticas = false;
        }
        return sonIdenticas;
    }

    private sonOrdenesTipoPaqueteIdenticasCuenta(orden1: Orden, orden2: Orden): boolean {
        let sonIdenticas: boolean = true;
        if (orden1.datosPaquete._idPaquete == orden2.datosPaquete._idPaquete &&
            orden1.datosPaquete.alternativasProductosPorTamano.length == orden2.datosPaquete.alternativasProductosPorTamano.length) {
            //Se recorre el array de alternativasProductosPorTamano
            for (let posicionAlternativa = 0; posicionAlternativa < orden1.datosPaquete.alternativasProductosPorTamano.length; posicionAlternativa++) {
                if (orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idProducto == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idProducto &&
                    orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idTamano == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa]._idTamano &&
                    orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length == orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length) {
                    //Se recorre el array de agregarIngredientes
                    for (let posicionAgregarIngrediente = 0; posicionAgregarIngrediente < orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length; posicionAgregarIngrediente++) {
                        if (orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente]._idIngrediente != orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente]._idIngrediente ||
                            orden1.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente].precio != orden2.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes[posicionAgregarIngrediente].precio) {
                            sonIdenticas = false;
                            break;
                        }
                    }
                } else {
                    sonIdenticas = false;
                }
            }
        } else {
            sonIdenticas = false;
        }
        return sonIdenticas;
    }

    private crearOrdenAgrupadaCuenta(orden: Orden, cantidad: number): Orden {
        let ordenAgrupada = new Orden();
        ordenAgrupada.tipo = orden.tipo;
        ordenAgrupada.cantidad = cantidad;
        ordenAgrupada.precioUnitario = orden.precioUnitario;
        ordenAgrupada.precioPorTamano = orden.precioPorTamano;
        switch (ordenAgrupada.tipo) {
            case TipoOrden.Producto:
                ordenAgrupada.datosProducto = orden.datosProducto;
                break;
            case TipoOrden.Paquete:
                ordenAgrupada.datosPaquete = orden.datosPaquete;
                break;
        }
        return ordenAgrupada;
    }

    /********************************************************************************************************/
    /*********************************** G R U P O   D E   O R D E N E S ************************************/
    /********************************************************************************************************/
    /* MÉTODOS PARA ACTUALIZAR EL GRUPO DE ORDENES */
    async actualizarGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string) {
        try {
            this.grupoDeOrdenes.next(await this.obtenerGrupoDeOrdenes(_idRestaurante, _idArea, _idElementoPorArea, _idComanda, _idGrupoDeOrdenes));
        } catch (error) {
            this._alertasService.alertaErrorSinConfirmacion(error.titulo, error.detalles);
            this.grupoDeOrdenes.next(null);
        }
    }

    private obtenerGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string): Promise<GrupoDeOrdenes> {
        return new Promise((resolve, reject) => {
            this._gruposDeOrdenesService.obtenerGrupoDeOrdenes(_idRestaurante, _idArea, _idElementoPorArea, _idComanda, _idGrupoDeOrdenes).subscribe(
                (grupoDeOrdenes: GrupoDeOrdenes) => {
                    resolve(grupoDeOrdenes);
                },
                (error: HttpErrorResponse) => {
                    reject({ titulo: error.error.titulo, detalles: error.error.detalles })
                }
            );
        })
    }

    /* MÉTODOS PARA ACTUALIZAR LAS ORDENES AGRUPADAS Y ORDENES POR ORDENES AGRUPADAS */
    async actualizarOrdenesGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string) {
        try {
            const ordenes = await this.obtenerOrdenesGrupoDeOrdenes(_idRestaurante, _idArea, _idElementoPorArea, _idComanda, _idGrupoDeOrdenes);
            this.inicializarOrdenesAgrupadasGrupoDeOrdenes(ordenes);
        } catch (error) {
            this._alertasService.alertaErrorSinConfirmacion(error.titulo, error.detalles);
            this.ordenesAgrupadasGrupoDeOrdenes.next([]);
            this.ordenesPorOrdenesAgrupadasGrupoDeOrdenes.next([]);
        }
    }

    async obtenerOrdenesGrupoDeOrdenes(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, _idGrupoDeOrdenes: string): Promise<Orden[]> {
        return new Promise<Orden[]>((resolve, reject) => {
            this._ordenesService.obtenerOrdenes(_idRestaurante, _idArea, _idElementoPorArea, _idComanda, _idGrupoDeOrdenes).subscribe(
                (ordenes: Orden[]) => {
                    resolve(ordenes)
                },
                (error: HttpErrorResponse) => {
                    resolve(null)
                }
            );
        });
    }

    inicializarOrdenesAgrupadasGrupoDeOrdenes(ordenes: Orden[]): void {
        let ordenesAgrupadas: Orden[] = [];
        let ordenesSinAgruparoPorOrdenesAgrupadas: Orden[][] = [];
        let ordenesPorOrdenesAgrupadas: Orden[][] = [];
        ordenes.forEach(orden => {
            const posicionOrdenAgrupada = ordenesAgrupadas.findIndex(ordenAgrupada => {
                if (ordenAgrupada.tipo == orden.tipo && ordenAgrupada.precioPorTamano._idTamano == orden.precioPorTamano._idTamano) {
                    switch (ordenAgrupada.tipo) {
                        case TipoOrden.Producto:
                            return ordenAgrupada.datosProducto._idProducto == orden.datosProducto._idProducto;
                        case TipoOrden.Paquete:
                            return ordenAgrupada.datosPaquete._idPaquete == orden.datosPaquete._idPaquete;
                    }
                } else {
                    return false;
                }
            })
            if (posicionOrdenAgrupada != -1) {
                (<number>ordenesAgrupadas[posicionOrdenAgrupada].cantidad) += <number>orden.cantidad;
                ordenesSinAgruparoPorOrdenesAgrupadas[posicionOrdenAgrupada].push(orden);
            } else {
                let ordenAgrupada = new Orden();
                ordenAgrupada.tipo = orden.tipo;
                ordenAgrupada.cantidad = orden.cantidad;
                ordenAgrupada.precioPorTamano._idTamano = orden.precioPorTamano._idTamano;
                ordenAgrupada.precioPorTamano.tamano = orden.precioPorTamano.tamano;
                switch (ordenAgrupada.tipo) {
                    case TipoOrden.Producto:
                        ordenAgrupada.datosProducto._idProducto = orden.datosProducto._idProducto;
                        ordenAgrupada.datosProducto.producto = orden.datosProducto.producto;
                        break;
                    case TipoOrden.Paquete:
                        ordenAgrupada.datosPaquete._idPaquete = orden.datosPaquete._idPaquete;
                        ordenAgrupada.datosPaquete.paquete = orden.datosPaquete.paquete;
                        break;
                }
                ordenesAgrupadas.push(ordenAgrupada);
                ordenesSinAgruparoPorOrdenesAgrupadas.push([]);
                ordenesSinAgruparoPorOrdenesAgrupadas[ordenesSinAgruparoPorOrdenesAgrupadas.length - 1].push(orden);
            }
        })
        for (let posicionOrdenesAgrupadas = 0; posicionOrdenesAgrupadas < ordenesAgrupadas.length; posicionOrdenesAgrupadas++) {
            this.inicializarOrdenesPorOrdenAgrupada(posicionOrdenesAgrupadas, ordenesSinAgruparoPorOrdenesAgrupadas, ordenesPorOrdenesAgrupadas);
        }
        this.ordenesAgrupadasGrupoDeOrdenes.next(ordenesAgrupadas);
        this.ordenesPorOrdenesAgrupadasGrupoDeOrdenes.next(ordenesPorOrdenesAgrupadas);
    }

    inicializarOrdenesPorOrdenAgrupada(posicionOrdenAgrupada: number, ordenesSinAgruparoPorOrdenesAgrupadas: Orden[][], ordenesPorOrdenesAgrupadas: Orden[][]): void {
        ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada] = [];
        ordenesSinAgruparoPorOrdenesAgrupadas[posicionOrdenAgrupada].forEach(orden => {
            const posicionOrden = this.obtenerPosicionOrden(ordenesPorOrdenesAgrupadas, posicionOrdenAgrupada, orden);
            if (posicionOrden == -1) {
                ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada].push(orden);
            } else {
                (<number>ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada][posicionOrden].cantidad) += <number>orden.cantidad;
            }
        });
    }

    obtenerPosicionOrden(ordenesPorOrdenesAgrupadas: Orden[][], posicionOrdenAgrupada: number, nuevaOrden: Orden): number {
        return ordenesPorOrdenesAgrupadas[posicionOrdenAgrupada].findIndex(orden => {
            let esOrdenIdentica: boolean = true;
            if (orden.tipo == nuevaOrden.tipo && orden.precioPorTamano._idTamano == nuevaOrden.precioPorTamano._idTamano && orden.precioPorTamano.precio == nuevaOrden.precioPorTamano.precio) {
                switch (nuevaOrden.tipo) {
                    case TipoOrden.Producto:
                        if (
                            nuevaOrden.datosProducto._idProducto != orden.datosProducto._idProducto ||
                            !this.quitarIngredientesIdentico(<Ingrediente[]>orden.datosProducto.quitarIngredientes, <Ingrediente[]>nuevaOrden.datosProducto.quitarIngredientes) ||
                            !this.agregarIngredientesIdentico(orden.datosProducto.agregarIngredientes, nuevaOrden.datosProducto.agregarIngredientes) ||
                            !this.notasIdentico(orden.datosProducto.notas, nuevaOrden.datosProducto.notas)) {
                            esOrdenIdentica = false;
                        }
                        break;
                    case TipoOrden.Paquete:
                        if (nuevaOrden.datosPaquete._idPaquete != orden.datosPaquete._idPaquete ||
                            !this.alternativasProductosPorTamanoIdentico(orden.datosPaquete.alternativasProductosPorTamano, nuevaOrden.datosPaquete.alternativasProductosPorTamano)
                        ) {
                            esOrdenIdentica = false;
                        }
                        break;
                }
            } else {
                esOrdenIdentica = false;
            }
            return esOrdenIdentica;
        });
    }


    quitarIngredientesIdentico(quitarIngredientesOrden: Ingrediente[], quitarIngredientesNuevaOrden: Ingrediente[]): boolean {
        let esIdentico: boolean = true;
        if (quitarIngredientesOrden.length == quitarIngredientesNuevaOrden.length) {
            for (let posicionIngrediente = 0; posicionIngrediente < quitarIngredientesOrden.length; posicionIngrediente++) {
                if (quitarIngredientesOrden[posicionIngrediente]._id != quitarIngredientesNuevaOrden[posicionIngrediente]._id) {
                    esIdentico = false;
                    break;
                }
            }
        } else {
            esIdentico = false;
        }
        return esIdentico;
    }

    agregarIngredientesIdentico(agregarIngredientesOrden: AgregarIngrediente[], agregarIngredientesNuevaOrden: AgregarIngrediente[]): boolean {
        let esIdentico: boolean = true;
        if (agregarIngredientesOrden.length == agregarIngredientesNuevaOrden.length) {
            for (let posicionIngrediente = 0; posicionIngrediente < agregarIngredientesOrden.length; posicionIngrediente++) {
                if (agregarIngredientesOrden[posicionIngrediente]._idIngrediente != agregarIngredientesNuevaOrden[posicionIngrediente]._idIngrediente ||
                    agregarIngredientesOrden[posicionIngrediente].precio != agregarIngredientesNuevaOrden[posicionIngrediente].precio) {
                    esIdentico = false;
                    break;
                }
            }
        } else {
            esIdentico = false;
        }
        return esIdentico;
    }

    notasIdentico(notasOrden: string[], notasNuevaOrden: string[]): boolean {
        let esIdentico: boolean = true;
        if (notasOrden.length == notasNuevaOrden.length) {
            for (let posicionNota = 0; posicionNota < notasOrden.length; posicionNota++) {
                if (notasOrden[posicionNota] != notasNuevaOrden[posicionNota]) {
                    esIdentico = false;
                    break;
                }
            }
        } else {
            esIdentico = false;
        }
        return esIdentico;
    }

    alternativasProductosPorTamanoIdentico(alternativasProductosPorTamanoOrden: AlternativaProductoPorTamano[], alternativasProductosPorTamanoNuevaOrden: AlternativaProductoPorTamano[]): boolean {
        let esIdentico: boolean = true;
        if (alternativasProductosPorTamanoOrden.length == alternativasProductosPorTamanoNuevaOrden.length) {
            for (let posicionAlternativaProductoPorTamano = 0; posicionAlternativaProductoPorTamano < alternativasProductosPorTamanoOrden.length; posicionAlternativaProductoPorTamano++) {
                if (alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano]._idProducto != alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano]._idProducto ||
                    alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano]._idTamano != alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano]._idTamano ||
                    !this.quitarIngredientesIdentico(<Ingrediente[]>alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].quitarIngredientes, <Ingrediente[]>alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].quitarIngredientes) ||
                    !this.agregarIngredientesIdentico(alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].agregarIngredientes, alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].agregarIngredientes) ||
                    !this.notasIdentico(alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].notas, alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].notas)
                ) {
                    esIdentico = false;
                    break;
                }
            }
        } else {
            esIdentico = false;
        }
        return esIdentico;
    }



    /*public deteccionCambios: any;
    public deteccionCambiosEnOrdenes: any;
    public deteccionCambiosOrdenPrincipal: any;
    public deteccionCambiosOrdenesAgrupadas: any;
    private ordenesCuentaActual: Orden[];
    private ordenPrincipal: Orden[];
    private ordenesAgrupadas: Orden[][];
  
    constructor(private httpClient: HttpClient) {
      this.ordenesCuentaActual = null;
      this.deteccionCambiosEnOrdenes = new BehaviorSubject(this.ordenesCuentaActual);
      this.deteccionCambiosOrdenPrincipal = new BehaviorSubject(this.ordenPrincipal);
      this.deteccionCambiosOrdenesAgrupadas = new BehaviorSubject(this.ordenesAgrupadas);
    }
  
    setOrdenPrincipal(ordenPrincipal: Orden[]) {
      this.ordenPrincipal = ordenPrincipal;
      this.deteccionCambiosOrdenPrincipal.next(this.ordenPrincipal);
    }
  
    setOrdenesAgrupadas(ordenesAgrupadas: Orden[][]) {
      this.ordenesAgrupadas = ordenesAgrupadas;
      this.deteccionCambiosOrdenesAgrupadas.next(this.ordenesAgrupadas);
    }
  
    getOrdenesCuentaActual() {
      return this.ordenesCuentaActual;
    }
  
    obtenerOrdenesDeCuenta(idCuenta: string): Observable<object> {
      return this.httpClient.get(`${AppSettings.APIEndpoint}/cuentas/ordenes-de-cuenta.php?idCuenta=` + idCuenta, AppSettings.Options);
    }
  
    obtenerOrdenesDeCuentaParaTicket(idCuenta: string): Observable<object> {
      return this.httpClient.get(`${AppSettings.APIEndpoint}/cuentas/ordenes-de-cuenta-para-ticket.php?idCuenta=` + idCuenta, AppSettings.Options)
        .pipe(map((res: Orden[]) => {
          this.ordenesCuentaActual = res;
          this.deteccionCambiosEnOrdenes.next(this.ordenesCuentaActual);
          return res;
        }));
    }*/
}
