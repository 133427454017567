import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule, DatePipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTooltipModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ConfigurationComponent} from './configuration.component';
import {ConfigurationRoutes} from './configuration.routes';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {CurrencyPipe} from 'src/app/shared/pipes/currency.pipe';
import {LicensesComponent} from './components/licenses/licenses.component';

/* CONTRASEÑA */
import { CambiarContrasenaConfiguracionComponent } from './cambiar-contrasena-configuracion/cambiar-contrasena-configuracion.component';

/* TAMAÑOS */
import { TamanosConfiguracionComponent } from './tamanos-configuracion/tamanos-configuracion.component';
import { EditarTamanoConfiguracionComponent } from './tamanos-configuracion/editar-tamano-configuracion/editar-tamano-configuracion.component';
import { AgregarTamanoConfiguracionComponent } from './tamanos-configuracion/agregar-tamano-configuracion/agregar-tamano-configuracion.component';

/* CATEGORIAS */
import { CategoriasConfiguracionComponent } from './categorias-configuracion/categorias-configuracion.component';
import { AgregarCategoriaConfiguracionComponent } from './categorias-configuracion/agregar-categoria-configuracion/agregar-categoria-configuracion.component';
import { EditarCategoriaConfiguracionComponent } from './categorias-configuracion/editar-categoria-configuracion/editar-categoria-configuracion.component';

/* CUPONES */
import { CuponesConfiguracionComponent } from './cupones-configuracion/cupones-configuracion.component';
import { AgregarCuponConfiguracionComponent } from './cupones-configuracion/agregar-cupon-configuracion/agregar-cupon-configuracion.component';
import { EditarCuponConfiguracionComponent } from './cupones-configuracion/editar-cupon-configuracion/editar-cupon-configuracion.component';
import { FolioPipe } from 'src/app/shared/pipes/folio.pipe';
import { FolioSinEspaciosPipe } from 'src/app/shared/pipes/folio-sin-espacios.pipe';
import { ElementosConfiguracionComponent } from './elementos-configuracion/elementos-configuracion.component';
import { AgregarElementoConfiguracionComponent } from './elementos-configuracion/agregar-elemento-configuracion/agregar-elemento-configuracion.component';
import { EditarElementoConfiguracionComponent } from './elementos-configuracion/editar-elemento-configuracion/editar-elemento-configuracion.component';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';

@NgModule({
  declarations: [
    ConfigurationComponent,
    LicensesComponent,

    /* CONTRASEÑA */
    CambiarContrasenaConfiguracionComponent,

    /* TAMAÑOS */
    TamanosConfiguracionComponent,
    EditarTamanoConfiguracionComponent,
    AgregarTamanoConfiguracionComponent,

    /* CATEGORIAS */
    CategoriasConfiguracionComponent,
    AgregarCategoriaConfiguracionComponent,
    EditarCategoriaConfiguracionComponent,
    
    /* CUPONES */
    CuponesConfiguracionComponent,
    AgregarCuponConfiguracionComponent,
    EditarCuponConfiguracionComponent,
    ElementosConfiguracionComponent,
    AgregarElementoConfiguracionComponent,
    EditarElementoConfiguracionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(ConfigurationRoutes),
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatListModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    ConfigurationComponent
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    FolioPipe,
    FolioSinEspaciosPipe,
    FechaPipe
  ]
})
export class ConfigurationModule {
}
