import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../configs/app-settings.config';
import { HttpClient } from '@angular/common/http';
import { Cupon } from 'src/app/shared/class-models/cupon.model';

@Injectable({
    providedIn: 'root'
})
export class CuponesService {
    private rutaBaseCupones = '/cupones';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerCupones(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseCupones}`, AppSettings.Options);
    }

    obtenerCuponesVigentes(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseCupones}/vigentes`, AppSettings.Options);
    }

    obtenerCupon(_idCupon: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseCupones}/${_idCupon}`, AppSettings.Options);
    }

    /* POST */
    agregarCupon(cupon: Cupon) {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseCupones}`, JSON.stringify(cupon), AppSettings.Options);
    }

    /* PUT */
    actualizarCupon(_idCupon: string, cupon: Cupon) {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseCupones}/${_idCupon}`, JSON.stringify(cupon), AppSettings.Options);
    }

    /* PATCH */
    eliminarCupon(_idCupon: string) {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseCupones}/${_idCupon}`, AppSettings.Options);
    }
}
