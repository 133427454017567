import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ProveedoresService } from 'src/app/core/servicios/proveedores.service';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { EditProviderComponent } from '../provider/modals/edit/edit-provider.component';
import { NewProviderComponent } from './modals/new-provider/new-provider.component';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html'
})
export class ProvidersComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Proveedor>;
  filtro: string = '';
  estadoPeticionObtenerProveedores: number;
  RequestState = RequestState;
  obtenerProveedoresSubscripcion: Subscription;
  eliminarProveedorSubscripcion: Subscription;
  columnas = ['tipoDePersona', 'razonSocial', 'rfc', 'correo', 'telefono', 'opciones'];


  constructor(
    private _proveedoresService: ProveedoresService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    private phonePipe: PhonePipe) {
    this.estadoPeticionObtenerProveedores = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProveedores();
  }

  obtenerProveedores(): void {
    this.estadoPeticionObtenerProveedores = RequestState.loading;
    this.obtenerProveedoresSubscripcion = this._proveedoresService.obtenerProveedores().subscribe(
      (proveedores: Proveedor[]) => {
        this.inicializarProveedores(proveedores);
        this.estadoPeticionObtenerProveedores = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProveedores = RequestState.error;
      }
    );
  }

  inicializarProveedores(proveedores: Proveedor[]) {
    this.dataSource = new MatTableDataSource<Proveedor>(proveedores);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (proveedor: Proveedor, filtro: string) => {
      return this.obtenerTipoDePersona(proveedor.tipoDePersona).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerRazonSocial(proveedor).trim().toLowerCase().indexOf(filtro) !== -1 ||
        proveedor.rfc.trim().toLowerCase().indexOf(filtro) !== -1 ||
        proveedor.correo.trim().toLowerCase().indexOf(filtro) !== -1 ||
        (proveedor.telefono ? proveedor.telefono.trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
        (proveedor.telefono ? this.phonePipe.transform(proveedor.telefono, 'phone').trim().toLowerCase().indexOf(filtro) !== -1 : false);
    }
  }

  buscarProveedor() {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerTipoDePersona(tipo: number): string {
    switch (tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  obtenerRazonSocial(proveedor: Proveedor) {
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral: return proveedor.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return proveedor.nombres + ' ' + proveedor.primerApellido + (proveedor.segundoApellido ? (' ' + proveedor.segundoApellido) : '');
    }
  }
  agregarProveedor(): void {
    const dialogRef = this.dialog.open(NewProviderComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (proveedor: Proveedor) => {
        if (proveedor) {
          this._alertasService.alertaExitoSinConfirmacion('Proveedor agregado exitosamente', 'El proveedor ' + this.obtenerRazonSocial(proveedor) + ' ha sido agregado con éxito');
          this.dataSource.data.push(proveedor);
          const proveedores: Proveedor[] = this.dataSource.data;
          this.inicializarProveedores(proveedores);
          this.buscarProveedor();
        }
      }
    );
  }

  actualizarProveedor(proveedor: Proveedor): void {
    const dialogRef = this.dialog.open(EditProviderComponent, { disableClose: true, data: proveedor });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Proveedor actualizado exitosamente', 'El proveedor ' + this.obtenerRazonSocial(proveedor) + ' ha sido actualizado con éxito.');
          this.buscarProveedor();
        }
      }
    );
  }

  eliminarProveedor(proveedorEliminar: Proveedor) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar al proveedor ' + this.obtenerRazonSocial(proveedorEliminar) + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarProveedorSubscripcion = this._proveedoresService.eliminarProveedor(proveedorEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionProveedor = this.dataSource.data.findIndex((proveedor: Proveedor) => {
                return proveedor._id === proveedorEliminar._id;
              });
              let proveedoresActualizados: Proveedor[] = this.dataSource.data;
              if (posicionProveedor != -1) {
                proveedoresActualizados.splice(posicionProveedor, 1);
              }
              this.inicializarProveedores(proveedoresActualizados);
              this.buscarProveedor();
              this._alertasService.alertaExitoSinConfirmacion('Proveedor eliminado exitosamente', 'El proveedor ' + this.obtenerRazonSocial(proveedorEliminar) + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerProveedoresSubscripcion) this.obtenerProveedoresSubscripcion.unsubscribe();
    if (this.eliminarProveedorSubscripcion) this.eliminarProveedorSubscripcion.unsubscribe();
  }
}
