import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs/internal/Subscription';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { MatDialogRef } from '@angular/material';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { TipoImagenRestaurante } from 'src/app/shared/enums/tipo-imagen-restaurante.enum';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LicenciasService } from 'src/app/core/servicios/licencias.service';
import { Licencia } from 'src/app/shared/class-models/licencia.model';
import { PorcentajeIva } from 'src/app/shared/enums/porcentaje-iva.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';

@Component({
  selector: 'app-new-restaurant',
  templateUrl: './new-restaurant.component.html'
})
export class NewRestaurantComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  titulo = 'Agregar restaurante';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;
  obtenerEstadosSubscripcion: Subscription;
  obtenerCiudadesSubscripcion: Subscription;
  obtenerLicenciasVigentesSubscripcion: Subscription;

  /* VARIABLES DEL STEP DE INFORMACIÓN GENERAL */
  TipoImagenRestaurante = TipoImagenRestaurante;
  eventoCambioImagenFotografia: any = '';
  eventoCambioImagenLogotipo: any = '';

  /* VARIABLES DEL STEP DE DIRECCIÓN */
  @ViewChild('direccionRestauranteForm') direccionRestauranteForm: FormGroup;
  estados: Estado[];
  ciudades: Ciudad[];
  codigoPostal: string = '';

  /* VARIABLES DEL STEP DE CONFIGURACIÓN */
  PorcentajeIva = PorcentajeIva;
  
  /* VARIABLES DEL STEP DE LICENCIA */
  licencias: Licencia[];

  /* VARIABLES PARA AGREGAR EL RESTAURANTE */
  estadoPeticionAgregarRestaurante: number;
  agregarRestauranteSubscription: Subscription;
  restaurante: Restaurante = new Restaurante();

  constructor(public modal: MatDialogRef<NewRestaurantComponent>,
    private _restaurantesService: RestaurantesService,
    private _ubicacionService: UbicacionService,
    private _licenciasService: LicenciasService,
    private _alertasService: AlertasService
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 2;
    this.estadoPeticionAgregarRestaurante = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerLicenciasVigentes();
    this.obtenerEstados();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerLicenciasVigentes(): void {
    this.licencias = [];
    this.obtenerLicenciasVigentesSubscripcion = this._licenciasService.obtenerLicenciasVigentes().subscribe(
      (licencias: Licencia[]) => {
        this.licencias = licencias;
        switch(this.licencias.length) {
          case 0: //Se cierra el formulario debido a que no hay licencias par asignar al restaurante
            this._alertasService.alertaErrorSinConfirmacion('Actualmente no se tienen licencias registradas', 'Favor de adquirir una licencia para poder dar de alta sus restaurantes');
            this.modal.close();
            break;
          case 1: //Si solo hay una licencia se inicializa el campo _idLicencia con la unica licencia disponible
            this.restaurante._idLicencia = this.licencias[0]._id;
            break;
        }
        this.incrementarContadorPeticionesFinalizadas();
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.incrementarContadorPeticionesFinalizadas();
        this.modal.close();
      }
    );
  }

  obtenerEstados(): void {
    this.estados = [];
    this.obtenerEstadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
        this.incrementarContadorPeticionesFinalizadas();
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.incrementarContadorPeticionesFinalizadas();
        this.modal.close();
      }
    );
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  /* MÉTODOS DEL STEP DE INFORMACIÓN GENERAL */
  convertirRfcMayusculas(): void {
    if (this.restaurante.rfc) {
      this.restaurante.rfc = this.restaurante.rfc.toUpperCase();
    }
  }

  cambioArchivoEvento(tipoImagenRestaurante: number, event: any): void {
    switch (tipoImagenRestaurante) {
      case TipoImagenRestaurante.Fotografia: this.eventoCambioImagenFotografia = event;
        break;
      case TipoImagenRestaurante.Logotipo: this.eventoCambioImagenLogotipo = event;
        break;
    }
  }

  imagenRecortada(tipoImagenRestaurante: number, evento: ImageCroppedEvent): void {
    switch (tipoImagenRestaurante) {
      case TipoImagenRestaurante.Fotografia: this.restaurante.foto = evento.base64;
        break;
      case TipoImagenRestaurante.Logotipo: this.restaurante.logo = evento.base64;
        break;
    }
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
  //this.imagenFotoRecortada = '';
  //this.eventoCambioImagenFotografia = '';
  //}


  /* MÉTODOS DEL STEP DE DIRECCIÓN */
  obtenerCiudades(idEstado: string): void {
    this.ciudades = [];
    this.obtenerCiudadesSubscripcion = this._ubicacionService.obtenerCiudades(Number(idEstado)).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  /* MÉTODOS PARA AGREGAR EL RESTAURANTE */
  async agregarRestaurante() {
    this.estadoPeticionAgregarRestaurante = RequestState.loading;
    const codigoPostal = await this.verificarCodigoPostal(this.restaurante.idEstado, this.codigoPostal);
    if (!codigoPostal) {
      this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
      this.estadoPeticionAgregarRestaurante = RequestState.initial;
      this.direccionRestauranteForm.controls['codigoPostal1'].setErrors({ invalido: true });
    } else {
      this.restaurante.idCodigoPostal = codigoPostal.id;
      this.agregarRestauranteSubscription = this._restaurantesService.agregarRestaurante(this.restaurante).subscribe(
        (restaurante: Restaurante) => {
          this.estadoPeticionAgregarRestaurante = RequestState.success;
          this.modal.close(restaurante);
        },
        (error: HttpErrorResponse) => {
          this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          this.estadoPeticionAgregarRestaurante = RequestState.error;
        }
      );
    }
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerLicenciasVigentesSubscripcion) this.obtenerLicenciasVigentesSubscripcion.unsubscribe();
    if (this.obtenerEstadosSubscripcion) this.obtenerEstadosSubscripcion.unsubscribe();
    if (this.obtenerCiudadesSubscripcion) this.obtenerCiudadesSubscripcion.unsubscribe();
    if (this.agregarRestauranteSubscription) this.agregarRestauranteSubscription.unsubscribe();
  }
}
