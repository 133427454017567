import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { TipoDeBusqueda } from 'src/app/shared/enums/tipo-de-busqueda.enum';

@Injectable({
    providedIn: 'root'
})
export class CajasDeUsuarioService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseCajasDeUsuario = '/cajas';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerCajasDeUsuarioPorFecha(_idRestaurante: string, tipoDeBusqueda: number, anio: number, mes: number, dia?: number): Observable<any> {
        switch(tipoDeBusqueda) {
            case TipoDeBusqueda.Mensual: return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajasDeUsuario}/por-fecha?tipoDeBusqueda=${tipoDeBusqueda}&anio=${anio}&mes=${mes}`, AppSettings.Options);
            case TipoDeBusqueda.Diaria: return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajasDeUsuario}/por-fecha?tipoDeBusqueda=${tipoDeBusqueda}&anio=${anio}&mes=${mes}&dia=${dia}`, AppSettings.Options);
        }
    }

    obtenerCajasDeUsuarioAbiertas(_idRestaurante: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajasDeUsuario}/abiertas`, AppSettings.Options);
    }

    obtenerCajaDeUsuario(_idRestaurante: string, _idCajaDeUsuario: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajasDeUsuario}/${_idCajaDeUsuario}`, AppSettings.Options);
    }

    obtenerCuentasCajaDeUsuario(_idRestaurante: string, _idCajaDeUsuario: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajasDeUsuario}/${_idCajaDeUsuario}/cuentas`, AppSettings.Options);
    }

    /* POST */
    agregarCajaDeUsuario(_idRestaurante: string, cajaDeUsuario: CajaDeUsuario): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajasDeUsuario}`, JSON.stringify(cajaDeUsuario), AppSettings.Options);
    }

    /* PUT */

    /* PATCH */
    eliminarCajaDeUsuario(_idRestaurante: string, _idCajaDeUsuario: string, cajaDeUsuario: CajaDeUsuario): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajasDeUsuario}/${_idCajaDeUsuario}`, JSON.stringify(cajaDeUsuario), AppSettings.Options);
    }
}