import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Categoria } from 'src/app/shared/class-models/categoria.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CategoriasService } from 'src/app/core/servicios/categorias.service';
import { AgregarCategoriaConfiguracionComponent } from './agregar-categoria-configuracion/agregar-categoria-configuracion.component';
import { EditarCategoriaConfiguracionComponent } from './editar-categoria-configuracion/editar-categoria-configuracion.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-categorias-configuracion',
  templateUrl: './categorias-configuracion.component.html',
  styleUrls: ['./categorias-configuracion.component.scss']
})
export class CategoriasConfiguracionComponent implements OnInit, OnDestroy {
  @Output() peticionObtenerCategoriasFinalizada = new EventEmitter<boolean>();
  @Input() estadoPeticiones: number;
  @ViewChild('paginatorCategorias', { read: MatPaginator, static: false }) paginatorCategorias: MatPaginator;
  dataSource: MatTableDataSource<Categoria>;
  columnasTabla: string[] = ['nombre', 'opciones'];
  estadoPeticion: number;
  RequestState = RequestState;
  obtenerCategoriasSubscription: Subscription;
  eliminarCategoriaSubscription: Subscription;
  filtro: string = '';

  constructor(
    private _categoriasService: CategoriasService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticion = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCategorias();
  }

  obtenerCategorias() {
    this.estadoPeticion = RequestState.loading;
    this.obtenerCategoriasSubscription = this._categoriasService.obtenerCategorias().subscribe(
      (categorias: Categoria[]) => {
        this.inicializarCategorias(categorias);
        this.estadoPeticion = RequestState.success;
        this.peticionObtenerCategoriasFinalizada.emit(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticion = RequestState.error;
        this.peticionObtenerCategoriasFinalizada.emit(true);
      }
    );
  }

  inicializarCategorias(categorias: Categoria[]): void {
    this.dataSource = new MatTableDataSource<Categoria>(categorias);
    this.dataSource.paginator = this.paginatorCategorias;
    this.dataSource.filterPredicate = (categoria: Categoria, filtro: string) => {
      return categoria.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarCategoria(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarCategoria() {
    const dialogRef = this.dialog.open(AgregarCategoriaConfiguracionComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (categoria: Categoria) => {
        if (categoria != null) {
          this._alertasService.alertaExitoSinConfirmacion('Categoría agregado exitosamente', 'La categoría ' + categoria.nombre + ' ha sido agregada con éxito.');
          this.dataSource.data.push(categoria);
          const categorias: Categoria[] = this.dataSource.data;
          this.inicializarCategorias(categorias);
          this.buscarCategoria();
        }
      }
    );
  }

  actualizarCategoria(categoria: Categoria) {
    const dialogRef = this.dialog.open(EditarCategoriaConfiguracionComponent, { disableClose: true, data: categoria });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Categoría actualizada exitosamente', 'La categoría ' + categoria.nombre + ' ha sido actualizada con éxito.');
          this.buscarCategoria();
        }
      }
    );
  }

  eliminarCategoria(categoriaEliminar: Categoria) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar la categoría ' + categoriaEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarCategoriaSubscription = this._categoriasService.eliminarCategoria(categoriaEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionCategoria = this.dataSource.data.findIndex((categoria: Categoria) => {
              return categoria._id === categoriaEliminar._id;
            });
            let categoriasActualizadas: Categoria[] = this.dataSource.data;
            if (posicionCategoria != -1) {
              categoriasActualizadas.splice(posicionCategoria, 1);
            }
            this.inicializarCategorias(categoriasActualizadas);
            this.buscarCategoria();
            this._alertasService.alertaExitoSinConfirmacion('Categoría eliminada exitosamente', 'La categoría ' + categoriaEliminar.nombre + ' ha sido eliminada con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy() {
    if (this.obtenerCategoriasSubscription) this.obtenerCategoriasSubscription.unsubscribe();
    if (this.eliminarCategoriaSubscription) this.eliminarCategoriaSubscription.unsubscribe();
  }
}
