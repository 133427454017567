import { HttpHeaders } from '@angular/common/http';

const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

export const AppSettings = {
  // DEVELOP
  /*APIEndpoint: 'http://localhost:5530/api/v1',
  ImagesEndPoint: 'http://localhost:5530',
  SocketsUrl: 'http://localhost:5530',*/
  //// PRODUCCION
  APIEndpoint: 'https://login.payfoods.com:5530/api/v1',
  ImagesEndPoint: 'https://login.payfoods.com:5530',
  SocketsUrl: 'https://login.payfoods.com:5530',
  Options: {
    headers: httpHeaders
  }
};
