import { Component, OnInit, Inject } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { GastoGeneral } from 'src/app/shared/class-models/gasto-general.model';
import { Subscription } from 'rxjs';
import { FormaDePago } from 'src/app/shared/enums/forma-de-pago.enum';
import { GastosGeneralesService } from 'src/app/core/servicios/gastos-generales.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AltaGastoComponent } from '../alta-gasto/alta-gasto.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-actualizar-gasto',
  templateUrl: './actualizar-gasto.component.html',
  styleUrls: ['./actualizar-gasto.component.scss']
})
export class ActualizarGastoComponent implements OnInit {
  titulo: string = 'Actualizar gasto general';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  gastoGeneral: GastoGeneral;
  estadoPeticionObtenerGastoGeneral: number;
  obtenerGastosGeneralSubscripcion: Subscription;
  estadoPeticionActualizarGastoGeneral: number;
  actualizarGastoGeneralSubscripcion: Subscription;
  FormaDePago = FormaDePago;

  constructor(
    private _gastosGeneralesService: GastosGeneralesService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<AltaGastoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {_idRestaurante: string, gastoGeneral: GastoGeneral},
  ) {
    this.estadoPeticionObtenerGastoGeneral = RequestState.initial;
    this.estadoPeticionActualizarGastoGeneral = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerGastoGeneral();
  }

  obtenerGastoGeneral(): void {
    this.estadoPeticionObtenerGastoGeneral = RequestState.loading;
    this.obtenerGastosGeneralSubscripcion = this._gastosGeneralesService.obtenerGastoGeneral(this.data._idRestaurante, this.data.gastoGeneral._id).subscribe(
      (gastoGeneral: GastoGeneral) => {
        gastoGeneral.total = parseFloat(gastoGeneral.total.toString()).toFixed(2);
        this.gastoGeneral = gastoGeneral;
        this.estadoPeticionObtenerGastoGeneral = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerGastoGeneral = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerFormaDePago(tipoFormaDePago: string): string {
    switch(tipoFormaDePago) {
      case FormaDePago.Efectivo: return 'Efectivo';
      case FormaDePago.ChequeNominativo: return 'Cheque nominativo';
      case FormaDePago.TransferenciaElectronicaDeFondos: return 'Transferencia electrónica de fondos';
      case FormaDePago.TarjetaDeCredito: return 'Tarjeta de crédito';
      case FormaDePago.MonederoElectronico: return 'Monedero electrónico';
      case FormaDePago.DineroElectronico: return 'Dinero electróico';
      case FormaDePago.ValesDeDespensa: return 'Vales de despensa';
      case FormaDePago.DacionDePago: return 'Dacion de pago';
      case FormaDePago.PagoPorSubrogacion: return 'Pago por subrogacion';
      case FormaDePago.PagoPorConsignacion: return 'Pago por consignacion';
      case FormaDePago.Condonacion: return 'Condonacion';
      case FormaDePago.Compensacion: return 'Compensacion';
      case FormaDePago.Novacion: return 'Novacion';
      case FormaDePago.Confusion: return 'Confusion';
      case FormaDePago.RemisionDeDeuda: return 'Remision de deuda';
      case FormaDePago.PrescripcionOCaducidad: return 'Prescripcion o caducidad';
      case FormaDePago.ASatisfaccionDelAcreedor: return 'A satisfaccion del acreedor';
      case FormaDePago.TarjetaDeDebito: return 'Tarjeta de debito';
      case FormaDePago.TarjetaDeServicios: return 'Tarjeta de servicios';
      case FormaDePago.AplicacionDeAnticipos: return 'Aplicacion de anticipos';
      case FormaDePago.PorDefinir: return 'Por definir';
    }
  }

  transformarPrecio(total: string): void {
    if (total != null && total != undefined && !isNaN(Number(total))) {
      this.gastoGeneral.total = Number(total).toFixed(2);
    } else {
      this.gastoGeneral.total = Number('0').toFixed(2);
    }
  }

  actualizarGastoGeneral(): void {
    this.estadoPeticionActualizarGastoGeneral = RequestState.loading;
    this.actualizarGastoGeneralSubscripcion = this._gastosGeneralesService.actualizarGastoGeneral(this.data._idRestaurante, this.data.gastoGeneral._id, this.prepararDatosGastoGeneral()).subscribe(
      (gastoGeneral: GastoGeneral) => {
        this.estadoPeticionActualizarGastoGeneral = RequestState.success;
        this.actualizarGastoGeneralOriginal(gastoGeneral);
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarGastoGeneral = RequestState.error;
      }
    );
  }

  prepararDatosGastoGeneral(): GastoGeneral {
    let gastoGeneral = new GastoGeneral();
    gastoGeneral._idTipoDeGastoGeneral = this.gastoGeneral._idTipoDeGastoGeneral;
    gastoGeneral.descripcion = this.gastoGeneral.descripcion;
    gastoGeneral.formaDePago = this.gastoGeneral.formaDePago;
    gastoGeneral.total = parseFloat(this.gastoGeneral.total.toString());
    return gastoGeneral;
  }

  actualizarGastoGeneralOriginal(gastoGeneral: GastoGeneral): void {
    this.data.gastoGeneral._idTipoDeGastoGeneral = gastoGeneral._idTipoDeGastoGeneral;
    this.data.gastoGeneral.tipoDeGastoGeneral = gastoGeneral.tipoDeGastoGeneral;
    this.data.gastoGeneral.descripcion = gastoGeneral.descripcion;
    this.data.gastoGeneral.formaDePago = gastoGeneral.formaDePago;
    this.data.gastoGeneral.total = gastoGeneral.total;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerGastosGeneralSubscripcion) this.obtenerGastosGeneralSubscripcion.unsubscribe();
    if (this.actualizarGastoGeneralSubscripcion) this.actualizarGastoGeneralSubscripcion.unsubscribe();
  }
}
