import { Existencia } from './existencia.model';
import { Producto } from './producto.model';
import { Restaurante } from './restaurante.model';
import { Tamano } from './tamano.model';
import { Usuario } from './usuario.model';


export class ExistenciaPorTamano {
    _id?: string;
    id?: number;
    cantidad: number;
    _idExistencia: string;
    existencia: Existencia;
    _idProducto: string;
    producto?: Producto;
    _idTamano: string;
    tamano?: Tamano;
    _idRestaurante: string;
    restaurante?: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor(){}
}