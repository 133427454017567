import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementosPorAreaService } from 'src/app/core/servicios/elementos-por-area.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { NewTableComponent } from './pages/new-table/new-table.component';
import { EditTableComponent } from './pages/edit-table/edit-table.component';
import { NewComandaComponent } from './pages/new-comanda/new-comanda.component';
import { AreasService } from 'src/app/core/servicios/areas.service';
import { Area } from 'src/app/shared/class-models/area.model';
import { TipoArea } from 'src/app/shared/enums/tipo-area.enum';
import { Comanda } from 'src/app/shared/class-models/comanda.model';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html'
})
export class AreaComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<ElementoPorArea>;
  observable: Observable<any>;
  filtro: string = '';
  RequestState = RequestState;
  area: Area;
  estadoPeticionObtenerArea: number;
  obtenerAreaSubscription: Subscription;
  estadoPeticionObtenerElementosPorArea: number;
  obtenerElementosPorAreaSubscription: Subscription;
  eliminarElementoPorAreaSubscription: Subscription;
  _idRestaurante: string;
  _idArea: string;

  /* HABILITAR CONFIGURACIÓN */
  configuracionHabilitadaElementosPorArea: boolean;
  configuracionHabilitadaPago: boolean;

  constructor(
    private _authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private _areasService: AreasService,
    private _elementosPorArea: ElementosPorAreaService,
    private _alertasService: AlertasService,
    private _ordenesService: OrdenesService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this._idArea = this.activatedRoute.parent.snapshot.paramMap.get('idArea');
    this.estadoPeticionObtenerArea = RequestState.initial;
    this.estadoPeticionObtenerElementosPorArea = RequestState.initial;
    this.configuracionHabilitadaElementosPorArea = this.habilitarConfiguracionElementosPorArea();
    this.configuracionHabilitadaPago = this.habilitarConfiguracionPago();
  }

  habilitarConfiguracionElementosPorArea(): boolean {
    switch (this._authService.obtenerTipoUsuario()) {
      case TipoUsuario.Administrador:
      case TipoUsuario.Gerente:
      case TipoUsuario.Cajero:
      case TipoUsuario.Barman:
      case TipoUsuario.Mesero:
        return true;
      default:
        return false;
    }
  }

  habilitarConfiguracionPago(): boolean {
    switch (this._authService.obtenerTipoUsuario()) {
      case TipoUsuario.Administrador:
      case TipoUsuario.Gerente:
      case TipoUsuario.Cajero:
      case TipoUsuario.Barman:
        return true;
      default:
        return false;
    }
  }

  ngOnInit(): void {
    this.obtenerArea();
  }

  obtenerArea(): void {
    this.estadoPeticionObtenerArea = RequestState.loading;
    this.obtenerAreaSubscription = this._areasService.obtenerArea(this._idRestaurante, this._idArea).subscribe(
      (area: Area) => {
        this.area = area;
        this.estadoPeticionObtenerArea = RequestState.success;
        this.obtenerElementosPorArea();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerArea = RequestState.error;
        this.router.navigate(['/restaurantes/' + this._idRestaurante + '/areas']);
      }
    );
  }

  obtenerElementosPorArea(): void {
    this.estadoPeticionObtenerElementosPorArea = RequestState.loading;
    this.obtenerElementosPorAreaSubscription = this._elementosPorArea.obtenerElementosPorArea(this._idRestaurante, this._idArea).subscribe(
      async (elementosPorArea: ElementoPorArea[]) => {
        for (const elementoPorArea of elementosPorArea) {
          if (elementoPorArea.comanda) {
            elementoPorArea.comanda.consumoTotal = await this.obtenerTotalDeOrdenesComanda(elementoPorArea);
          }
        }
        this.inicializarElementosPorArea(elementosPorArea);
        this.estadoPeticionObtenerElementosPorArea = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerElementosPorArea = RequestState.error;
      }
    );
  }

  obtenerTotalDeOrdenesComanda(elementoPorArea: ElementoPorArea): Promise<number> {
    return new Promise((resolve) => {
      this._ordenesService.obtenerTotalDeOrdenesComanda(this._idRestaurante, this._idArea, elementoPorArea._id, elementoPorArea.comanda._id).subscribe(
        (totalDeOrdenes: number) => {
          resolve(totalDeOrdenes);
        }
      );
    })
  }

  inicializarElementosPorArea(elementosPorArea: ElementoPorArea[]): void {
    this.dataSource = new MatTableDataSource<ElementoPorArea>(elementosPorArea);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (elementoPorArea: ElementoPorArea, filtro: string) => {
      return elementoPorArea.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
    this.observable = this.dataSource.connect();
  }

  buscarElementoPorArea(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarElementoPorArea(): void {
    const dialogRef = this.dialog.open(NewTableComponent, { disableClose: true, data: { _idRestaurante: this._idRestaurante, _idArea: this._idArea, tipoDeArea: this.area.tipo } });
    dialogRef.afterClosed().subscribe(
      (elementoPorArea: ElementoPorArea) => {
        if (elementoPorArea != null) {
          switch (this.area.tipo) {
            case TipoArea.Normal:
              this._alertasService.alertaExitoSinConfirmacion('Mesa agregada exitosamente', 'La mesa ' + elementoPorArea.nombre + ' ha sido agregada con éxito.');
              break;
            case TipoArea.Barra:
              this._alertasService.alertaExitoSinConfirmacion('Espacio en la barra agregado exitosamente', 'El espacio en la barra ' + elementoPorArea.nombre + ' ha sido agregado con éxito.');
              break;
          }
          this.dataSource.data.push(elementoPorArea);
          const elementosPorArea: ElementoPorArea[] = this.dataSource.data;
          this.inicializarElementosPorArea(elementosPorArea);
          this.buscarElementoPorArea();
        }
      }
    );
  }

  actualizarElementoPorArea(elementoPorArea: ElementoPorArea): void {
    const dialogRef = this.dialog.open(EditTableComponent, { disableClose: true, data: { _idRestaurante: this._idRestaurante, _idArea: this._idArea, tipoDeArea: this.area.tipo, elementoPorArea: elementoPorArea } });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          switch (this.area.tipo) {
            case TipoArea.Normal:
              this._alertasService.alertaExitoSinConfirmacion('Mesa actualizada exitosamente', 'La mesa ' + elementoPorArea.nombre + ' ha sido actualizada con éxito.');
              break;
            case TipoArea.Barra:
              this._alertasService.alertaExitoSinConfirmacion('Espacio en la barra actualizado exitosamente', 'El espacio en la barra ' + elementoPorArea.nombre + ' ha sido actualizado con éxito.');
              break;
          }
          this.buscarElementoPorArea();
        }
      }
    );
  }

  eliminarElementoPorArea(elementoPorAreaEliminar: ElementoPorArea): void {
    let tituloAlertaEliminarElementoPorArea: string;
    switch (this.area.tipo) {
      case TipoArea.Normal:
        tituloAlertaEliminarElementoPorArea = '¿Seguro que deseas eliminar la mesa ' + elementoPorAreaEliminar.nombre + '?';
        break;
      case TipoArea.Barra:
        tituloAlertaEliminarElementoPorArea = '¿Seguro que deseas eliminar el espacio en la barra ' + elementoPorAreaEliminar.nombre + '?';
        break;
    }
    this._alertasService.alertaErrorConConfirmacion(tituloAlertaEliminarElementoPorArea, '').then((result) => {
      if (result.value) {
        this.eliminarElementoPorAreaSubscription = this._elementosPorArea.eliminarElementoPorArea(this._idRestaurante, this._idArea, elementoPorAreaEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionElementoPorArea = this.dataSource.data.findIndex((elementoPorArea: ElementoPorArea) => {
              return elementoPorArea._id === elementoPorAreaEliminar._id;
            });
            let elementosPorAreaActualizados: ElementoPorArea[] = this.dataSource.data;
            if (posicionElementoPorArea != -1) {
              elementosPorAreaActualizados.splice(posicionElementoPorArea, 1);
            }
            this.inicializarElementosPorArea(elementosPorAreaActualizados);
            this.buscarElementoPorArea();
            switch (this.area.tipo) {
              case TipoArea.Normal:
                this._alertasService.alertaExitoSinConfirmacion('Mesa eliminada exitosamente', 'La mesa ' + elementoPorAreaEliminar.nombre + ' ha sido eliminada con éxito.');
                break;
              case TipoArea.Barra:
                this._alertasService.alertaExitoSinConfirmacion('Espacio en la barra eliminado exitosamente', 'El espacio en la barra ' + elementoPorAreaEliminar.nombre + ' ha sido eliminado con éxito.');
                break;
            }
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  agregarComanda(elementoPorArea: ElementoPorArea): void {
    const dialogRef = this.dialog.open(NewComandaComponent, { disableClose: true, data: { _idRestaurante: this._idRestaurante, _idArea: this._idArea, elementoPorArea: elementoPorArea } });
    dialogRef.afterClosed().subscribe(
      (comanda: Comanda) => {
        if (comanda != null) {
          switch (this.area.tipo) {
            case TipoArea.Normal:
              this._alertasService.alertaExitoSinConfirmacion('Comanda abierta exitosamente', 'Se ha abierto una comanda en la mesa ' + elementoPorArea.nombre + ' con éxito.');
              break;
            case TipoArea.Barra:
              this._alertasService.alertaExitoSinConfirmacion('Comanda abierta exitosamente', 'Se ha abierto una comanda en el espacio en la barra ' + elementoPorArea.nombre + ' con éxito.');
              break;
          }
        }
      }
    );
  }

  obtenerTextoAgregarElementoPorArea(): string {
    switch (this.area.tipo) {
      case TipoArea.Normal: return 'Agregar mesa';
      case TipoArea.Barra: return 'Agregar espacio en la barra';
      default: return '';
    }
  }

  obtenerTextoSinElementosPorAreaRegistrados(): string {
    switch (this.area.tipo) {
      case TipoArea.Normal: return 'No hay mesas registradas';
      case TipoArea.Barra: return 'No hay espacios en la barra registrados';
      default: return '';
    }
  }

  obtenerTextoErrorObtenerElementosPorArea(): string {
    switch (this.area.tipo) {
      case TipoArea.Normal: return 'Ha ocurrido un error al obtener las mesas, favor de intentarlo mas tarde.';
      case TipoArea.Barra: return 'Ha ocurrido un error al obtener los espacios en la barra, favor de intentarlo mas tarde.';
      default: return '';
    }
  }

  obtenerTextoElementoPorAreaOcupado(): string {
    switch (this.area.tipo) {
      case TipoArea.Normal: return 'Ocupada';
      case TipoArea.Barra: return 'Ocupado';
      default: return '';
    }
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerAreaSubscription) this.obtenerAreaSubscription.unsubscribe();
    if (this.obtenerElementosPorAreaSubscription) this.obtenerElementosPorAreaSubscription.unsubscribe();
    if (this.eliminarElementoPorAreaSubscription) this.eliminarElementoPorAreaSubscription.unsubscribe();
  }
  /*
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    observable: Observable<any>;
    dataSource: MatTableDataSource<ElementoArea>;
    userSubscription: Subscription;
    subscription: Subscription;
    ordersAmountSubscription: Subscription[] = [];
    titulo = 'Área';
    elementosPorArea: ElementoArea[];
    habilitarConfiguracion: boolean;
    habilitarPago: boolean;
    requestState: number;
    @ViewChild('dialogAddSuccess', {static: false}) private dialogAddSuccess: SwalComponent;
    @ViewChild('dialogAddTableSuccess', {static: false}) private dialogAddTableSuccess: SwalComponent;
    @ViewChild('dialogDeleteTableSuccess', {static: false}) private dialogDeleteTableSuccess: SwalComponent;
    @ViewChild('dialogDeleteTableWarning', {static: true}) private dialogDeleteTableWarning: SwalComponent;
    @ViewChild('dialogEditTableSuccess', {static: true}) private dialogEditTableSuccess: SwalComponent;
  
    constructor(private route: ActivatedRoute,
                private changeDetectorRef: ChangeDetectorRef,
                private userService: UserService,
                private elementByAreaService: ElementsByAreaService,
                private orderService: OrderService,
                private areaService: AreaService,
                public dialog: MatDialog) {
    }
  
    ngOnInit(): void {
      this.requestState = RequestState.loading;
      this.userSubscription = this.userService.getUserInfo().subscribe(
        (usuario: Usuario) => {
          this.habilitarConfiguracion = usuario.tipo <= 5;
          this.habilitarPago = usuario.tipo <= 4;
          this.subscription = this.elementByAreaService.obtenerElementosArea(this.route.snapshot.paramMap.get('idArea')).subscribe(
            (data: ElementoArea[]) => {
              for (let i = 0; i < data.length; i++) {
                this.ordersAmountSubscription.push(new Subscription());
                if (data[i].comanda != null) {
                  this.ordersAmountSubscription[i] = this.orderService.obtenerTotalOrdenesComanda(data[i].comanda.id).subscribe(
                    (total: any) => {
                      data[i].comanda.consumoTotal = total;
                    }
                  );
                }
              }
              this.dataSource = new MatTableDataSource<ElementoArea>(data);
              this.changeDetectorRef.detectChanges();
              this.dataSource.paginator = this.paginator;
              this.observable = this.dataSource.connect();
              this.requestState = RequestState.success;
            }
          );
        });
    }
  
    ngOnDestroy(): void {
      if (this.dataSource) {
        this.dataSource.disconnect();
      }
      for (const subscription of this.ordersAmountSubscription) {
        subscription.unsubscribe();
      }
      if (this.subscription !== undefined) {
        this.subscription.unsubscribe();
      }
      if (this.userSubscription !== undefined) {
        this.userSubscription.unsubscribe();
      }
    }
  
    agregarComanda(id: string) {
      const dialogRef = this.dialog.open(NewComandaComponent, {disableClose: true, data: id});
      dialogRef.afterClosed().subscribe(
        result => {
          if (result.ok) {
            const data = this.dataSource.data;
            const index = data.findIndex(elementoArea => elementoArea.id === id);
            data[index].comanda = result.form;
            data[index].comanda.consumoTotal = 0;
            this.dataSource.data = data;
            this.dialogAddSuccess.show();
          }
        }
      );
    }
  
    agregarMesa() {
      const dialogRef = this.dialog.open(NewTableComponent,
        {disableClose: true, data: this.route.snapshot.paramMap.get('idArea')});
      dialogRef.afterClosed().subscribe(
        result => {
          if (result.ok) {
            const data = this.dataSource.data;
            data.push(result.form);
            this.dataSource.data = data;
            this.dialogAddTableSuccess.show();
          }
        }
      );
    }
  
    eliminarMesa(id: string) {
      this.dialogDeleteTableWarning.show().then(
        data => {
          if (data.value) {
            this.elementByAreaService.eliminarElementoArea(id).subscribe(
              () => {
                const posicion = this.dataSource.data.findIndex(element => {
                    return element.id === id;
                  }
                );
                if (posicion !== -1) {
                  const data = this.dataSource.data;
                  data.splice(posicion, 1);
                  this.dataSource.data = data;
                  this.dialogDeleteTableSuccess.show();
                }
              }
            );
          }
        }
      );
    }
  
    editarMesa(id: string) {
      const dialogRef = this.dialog.open(EditTableComponent,
        {disableClose: true, data: {id, idArea: this.route.snapshot.paramMap.get('idArea')}});
      dialogRef.afterClosed().subscribe(
        res => {
          if (res.ok) {
            const posicion = this.dataSource.data.findIndex(element => {
                return element.id === id;
              }
            );
            if (posicion !== -1) {
              const data = this.dataSource.data;
              data[posicion].nombre = res.form.nombre;
              data[posicion].coordenadaX = res.form.coordenadaX;
              data[posicion].coordenadaY = res.form.coordenadaY;
              data[posicion].fechaRegistro = res.form.fechaRegistro;
              data[posicion].activo = res.form.activo;
              data[posicion].elemento = res.form.elemento;
              data[posicion].gerente = res.form.gerente;
              this.dataSource.data = data;
              this.dialogEditTableSuccess.show();
            }
          }
        }
      );
    }*/
}
