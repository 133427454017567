import { Ingrediente } from './ingrediente.model';
import { Producto } from './producto.model';
import { Proveedor } from './proveedor.model';
import { UnidadDeMedida } from './unidad-de-medida.model';
import { Usuario } from './usuario.model';
import { TipoDeInsumo } from '../enums/tipo-de-insumo.enum';

export class Insumo {
    _id?: string;
    id?: number;
    tipo: number;
    descripcion?: string;
    nombre: string;
    _idIngrediente?: string;
    ingrediente: Ingrediente;
    _idProducto?: string;
    producto: Producto;
    _idUnidadDeMedida: string;
    unidadDeMedida: UnidadDeMedida;
    proveedores?: Proveedor[] | string[];
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.tipo = TipoDeInsumo.Otro;
        this.proveedores = [];
    }
}
