import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {AppSettings} from '../../configs/app-settings.config';
import {AuthService} from '../authentication/auth.service';
import {Usuario} from 'src/app/shared/models/usuario';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  public userInfo: Usuario;
  public empleados: Usuario[];
  private tempUsername: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  addUser(user: Usuario): Observable<any> {
    const body = {
      user
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/usuario.php`, JSON.stringify(body), AppSettings.Options);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const body = {contrasena: oldPassword, nuevaContrasena: newPassword};
    return this.httpClient.post(`${AppSettings.APIEndpoint}/usuario/cambiar-contrasena.php`, JSON.stringify(body), AppSettings.Options);
  }

  editUser(usuario: Usuario, id: string): Observable<any> {
    usuario.id = id;
    const body = {
      user: usuario
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/usuario.php`, JSON.stringify(body), AppSettings.Options);
  }

  deleteUser(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/usuario.php?id=${id}`, AppSettings.Options);
  }

  getAdmins(): Observable<Usuario[]> {
    return this.httpClient.get<Usuario[]>(`${AppSettings.APIEndpoint}/usuarios/usuarios-admin.php`,
      AppSettings.Options);
  }

  getUserInfo(): Observable<any> {
    if (this.authService.isLoggedIn) {
      return this.httpClient.post(`${AppSettings.APIEndpoint}/usuario/obtener-informacion.php`, AppSettings.Options)
        .pipe(map((res: Usuario) => this.userInfo = res));
    }
  }

  getUser(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/usuario.php?id=${id}`, AppSettings.Options)
      .pipe(map((res: Usuario[]) => {
        const fl = res.filter(usuario => {
          return usuario.id === id;
        });
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  getUsers(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/usuarios/usuarios.php`, AppSettings.Options);
  }

  /*
  Reseteo de contraseña
  */

  generateResetCode(username: string): Observable<any> {
    this.tempUsername = username;
    return this.httpClient.post(`${AppSettings.APIEndpoint}/cambio-de-contrasena/obtener-codigo.php`,
      {usuario: username}, AppSettings.Options);
  }

  ResetPassword(resetCode: string, password: string): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}/cambio-de-contrasena/cambiar-contrasena.php`,
      {usuario: this.tempUsername, codigo: resetCode, contrasena: password}, AppSettings.Options);
  }

  verificarRFC(rfc: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/usuario/verificacion-rfc.php?rfc=${rfc}`, AppSettings.Options);
  }

  verificarNombreUsuario(nombreUsuario: string): Observable<any> {
    return this.httpClient
      .get(`${AppSettings.APIEndpoint}/usuario/verificacion-nombre-usuario.php?nombreUsuario=${nombreUsuario}`, AppSettings.Options);
  }
}
