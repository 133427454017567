import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatStepper} from '@angular/material';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../../../../../core/services/order.service';
import {Orden} from '../../../../../../shared/models/orden';
import {ElementsByAreaService} from '../../../../../../core/services/elements-by-area.service';
import {ElementoArea} from '../../../../../../shared/models/elementoArea';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {Cuenta} from '../../../../../../shared/models/cuenta';
import {DeleteComandaComponent} from '../delete-comanda/delete-comanda.component';
import {CashRegisterService} from '../../../../../../core/services/cash-register.service';
import {RestaurantService} from '../../../../../../core/services/restaurant.service';
import {Restaurante} from '../../../../../../shared/models/restaurante';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {CouponService} from '../../../../../../core/services/coupon.service';
import {Cupon} from '../../../../../../shared/models/coupon';
import {Cliente} from '../../../../../../shared/models/cliente';
import {ClientService} from '../../../../../../core/services/client.service';
import {NewClientComponent} from '../../../../../clients/modals/new-client/new-client.component';
import {BillService} from '../../../../../../core/services/bill.service';
import { PaymentResultComponent } from './dialogs/payment-result/payment-result.component';
import { PerfilService } from 'src/app/core/servicios/perfil.service';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { AbrirCajaComponent } from '../pago/abrir-caja/abrir-caja.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: [],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class PaymentComponent implements OnInit, OnDestroy {
  cashRegisterSubscription: Subscription;
  cuenta: Cuenta;
  subscription: Subscription;
  ordersSubscription: Subscription;
  restaurantSubscription: Subscription;
  clientSubscription: Subscription;
  subscriptionCoupon: Subscription;
  restaurante: Restaurante;
  titulo = 'Pago de comanda';
  ordenes: Orden[];
  cantidades: number[][] = [];
  cantidadesCuenta: number[] = [];
  ordenesCuenta: Orden[] = [];
  ordersRequestState: number;
  billRequestState: number;
  restaurantRequestState: number;
  clientRequestState: number;
  elementoPorArea: ElementoArea;
  ordenesForm: FormGroup;
  payForm: FormGroup;
  tipForm: FormGroup;
  discountForm: FormGroup;
  couponForm: FormGroup;
  searchClientForm: FormGroup;
  clientes: Cliente[];
  sinCliente: boolean;
  criterios = ['RFC', 'Nombre'];
  cambio: number;
  cupones: Cupon[];
  sinCaja: boolean;
  idCaja: string;
  preciosIncluyenIVA: boolean = true;
  metodosDePago = [
    {
      clave: 'PPD',
      descripcion: 'Pago en parcialidades o diferido'
    },
    {
      clave: 'PUE',
      descripcion: 'Pago en una sola exhibicion'
    }
  ];
  formasDePago = [
    {
      clave: '01',
      descripcion: 'Efectivo'
    },
    {
      clave: '04',
      descripcion: 'Tarjeta de credito'
    },
    {
      clave: '28',
      descripcion: 'Tarjeta de debito'
    }
  ];
  monedas = [
    {
      clave: 'MXN',
      descripcion: 'Peso Mexicano'
    },
    {
      clave: 'EUR',
      descripcion: 'Euro'
    },
    {
      clave: 'USD',
      descripcion: 'Dolar Americano'
    }
  ];
  bancos = ['BBVA Bancomer', 'Banorte', 'Banamex', 'HSBC', 'Santander', 'Scotiabank'];
  porcentajes = ['5', '10', '15', '20', '25', '30'];
  propina: boolean;
  tipoDePropina: string;
  descuento: boolean;
  tipoDeDescuento: string;
  cajaUsuarioActual: CajaDeUsuario;
  @ViewChild('dialogAddComandaSuccess', {static: true}) private dialogAddComandaSuccess: SwalComponent;
  @ViewChild('dialogPaymentSuccess', {static: true}) private dialogPaymentSuccess: SwalComponent;
  @ViewChild('dialogAddClientSuccess', {static: true}) private dialogAddClientSuccess: SwalComponent;

  constructor(private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef,
              private restaurantService: RestaurantService,
              private orderService: OrderService,
              private elementByAreaService: ElementsByAreaService,
              private couponService: CouponService,
              private clientService: ClientService,
              private router: Router,
              public dialog: MatDialog,
              private cashRegisterService: CashRegisterService,
              private _perfilService: PerfilService,
              private activatedRoute: ActivatedRoute,
              private billService: BillService) {
    this.cambio = -1;
    this.reiniciarFormularios();
    this.reiniciarCuenta();
    this.cambioSinCliente();
    this.formaDePagoSeleccionada();
  }

  ngOnInit() {
    this.ordersRequestState = RequestState.loading;
    this.restaurantRequestState = RequestState.loading;
    this._perfilService.obtenerCajaPerfil().subscribe(
      (cajaUsuario: CajaDeUsuario)=>{
        this.cajaUsuarioActual = cajaUsuario;
        if(!this.cajaUsuarioActual) {
          const referenciaModal = this.dialog.open(AbrirCajaComponent, {
            data:this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante')
          });
          referenciaModal.afterClosed().subscribe(cajaUsuario=>{
            if(cajaUsuario){
              this.cajaUsuarioActual = cajaUsuario;
            }
          })
        }
      },
      ()=>{

      }
    );
    /*this.cashRegisterSubscription = this.cashRegisterService.obtenerCajaDeUnUsuarioEnUnaSucursal(this.route.parent.snapshot.paramMap.get('idRestaurante')).subscribe(
      (res: any) => {
        if (res.data != null) {
          this.sinCaja = false;
          this.idCaja = res.data.id;
          this.subscription = this.elementByAreaService.obtenerElementoArea(this.route.snapshot.paramMap.get('idMesa')).subscribe(
            (elementoArea: ElementoArea) => {
              this.elementoPorArea = elementoArea;
              this.ordersSubscription = this.obtenerOrdenes().subscribe((resOrdenes: number) => this.ordersRequestState = resOrdenes);
            }
          );
        } else {
          this.sinCaja = true;
          this.ordersRequestState = RequestState.success;
        }
      }
    );*/
    this.restaurantSubscription = this.restaurantService.obtenerRestaurante(this.route.parent.snapshot.paramMap.get('idRestaurante')).subscribe(
        (res: Restaurante) => {
          this.restaurante = res;
          if (this.restaurante.cuponesActivos) {
            this.subscriptionCoupon = this.couponService.obtenerCuponesValidos().subscribe(
              (coupons) => {
                this.cupones = coupons;
                this.restaurantRequestState = RequestState.success;
              },
              () => {
                this.restaurantRequestState = RequestState.error;
              }
            );
          } else {
            this.restaurantRequestState = RequestState.success;
          }
        },
        () => {
          this.restaurantRequestState = RequestState.error;
        }
      );
  }

  abrirCaja(){
    const referenciaModal = this.dialog.open(AbrirCajaComponent, {
      data:this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante')
    });
    referenciaModal.afterClosed().subscribe(caja=>{
      if(caja){
        this.cajaUsuarioActual = caja;
      }
    })
  }

  reiniciarFormularios() {
    this.ordenesForm = new FormGroup({
      todoSeleccionado: new FormControl(false)
    }, this.alMenosUnaOrdenSeleccionada());
    this.searchClientForm = new FormGroup({
      criterio: new FormControl(''),
      termino: new FormControl(
        '',
        [
          Validators.pattern('[.a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\'\\t\\n\\v\\f\\r ' +
            '\u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]*')
        ]
      ),
      posicionCliente: new FormControl('', [Validators.required])
    });
    this.payForm = new FormGroup({
      moneda: new FormControl('', [Validators.required]),
      metodoDePago: new FormControl('', [Validators.required]),
      formaDePago: new FormControl('', [Validators.required]),
      cantidad: new FormControl('0.00', [Validators.required, Validators.pattern('^[0-9]+[.][0-9][0-9]$')]),
      numeroDeTarjeta: new FormControl('', [Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.maxLength(18),
        Validators.minLength(13)]),
      banco: new FormControl('', [Validators.required])
    });
    this.tipForm = new FormGroup({
      cantidad: new FormControl('0.00', [Validators.required, Validators.pattern('^[0-9]+[.][0-9][0-9]$')]),
      porcentaje: new FormControl('0', [Validators.required]),
    });
    this.couponForm = new FormGroup({
      posicionCupon: new FormControl(''),
    });
    this.discountForm = new FormGroup({
      cantidad: new FormControl('0.00', [Validators.required, Validators.pattern('^[0-9]+[.][0-9][0-9]$')]),
      porcentaje: new FormControl('0', [Validators.required]),
    });
  }

  reiniciarCuenta() {
    this.payForm.reset();
    this.payForm.get('moneda').setValue('MXN');
    this.payForm.get('metodoDePago').setValue('PPD');
    this.payForm.get('formaDePago').setValue('01');
    this.payForm.get('cantidad').setValue('0.00');
    this.descuento = false;
    this.discountForm.reset();
    this.descuentoSeleccionado();
    this.propina = false;
    this.tipForm.reset();
    this.propinaSeleccionada();
    this.searchClientForm.reset();
    this.searchClientForm.get('criterio').setValue('Nombre');
    this.searchClientForm.get('posicionCliente').setValue('-1');
    this.clientes = null;
    this.sinCliente = true;
    this.clientRequestState = RequestState.initial;
    this.ordenesForm.reset();
    this.ordenes = [];
    this.cantidades = [];
    this.cantidadesCuenta = [];
    this.ordenesCuenta = [];
  }

  obtenerOrdenes(): Observable<number> {
    return new Observable(
      observer => {
        this.orderService.obtenerOrdenesCuenta(this.elementoPorArea.comanda.id).subscribe(
          (ordenes: Orden[]) => {
            this.ordenes = ordenes;
            if (ordenes.length > 0) {
              this.ordenes.sort((obj1, obj2) => {
                const nombre1 = obj1.datosProducto != null ? obj1.datosProducto.producto.nombre : obj1.datosPaquete.paquete.nombre;
                const nombre2 = obj2.datosProducto != null ? obj2.datosProducto.producto.nombre : obj2.datosPaquete.paquete.nombre;
                if (nombre1 > nombre2) {
                  return 1;
                }
                if (nombre1 < nombre2) {
                  return -1;
                }
                return 0;
              });
            }
            for (let i = 0; i < this.ordenes.length; i++) {
              this.ordenesForm.addControl('orden' + (i + 1), new FormControl(false));
              this.cantidades.push([]);
              this.ordenesForm.addControl('cantidadOrden' + (i + 1), new FormControl(1));
              if (this.ordenes[i].cantidad > 1) {
                for (let j = 0; j < this.ordenes[i].cantidad; j++) {
                  this.cantidades[i].push(j + 1);
                }
              }
            }
            observer.next(RequestState.success);
          },
          () => {
            observer.next(RequestState.error);
          }
        );
      }
    );
  }

  alMenosUnaOrdenSeleccionada(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }

  totalOrdenes(tipoOrdenes: string) {
    let total = 0;
    if (tipoOrdenes !== 'cuenta') {
      for (const orden of this.ordenes) {
        total += orden.cantidad;
      }
    } else {
      for (const cantidad of this.cantidadesCuenta) {
        total += cantidad;
      }
    }
    return total;
  }

  calcularPago(tipo: string) {
    let total = 0;
    let cupon = 0;
    let subtotal = 0;
    let propina = 0;
    let descuento = 0;
    let IVA = 0;
    if (!(tipo === 'Propina' && this.tipoDePropina === 'Cantidad')) {
      for (let i = 0; i < this.ordenesCuenta.length; i++) {
        subtotal += this.ordenesCuenta[i].precioUnitario * this.cantidadesCuenta[i];
      }
    }
    IVA += Math.floor(subtotal * (this.restaurante.ivaActivo ? this.restaurante.IVA : 0)) * 0.01;//- cupon - descuento
    if (this.preciosIncluyenIVA) {
      subtotal = Number((subtotal - IVA).toFixed(2))
    }
    if (this.couponForm.get('posicionCupon').value !== '' && (tipo === 'Cupon' || tipo === 'IVA' || tipo === 'Total')) {
      switch (this.cupones[this.couponForm.get('posicionCupon').value - 1].tipo) {
        case 'cantidad':
          cupon += this.cupones[this.couponForm.get('posicionCupon').value - 1].descuentoCantidad;
          break;
        case 'porcentaje':
          cupon += Math.floor((subtotal + IVA) * this.cupones[this.couponForm.get('posicionCupon').value - 1].descuentoPorcentaje) * 0.01;
          break;
      }
    }
    if (tipo === 'Propina' || tipo === 'Total') {
      if (this.propina) {
        switch (this.tipoDePropina) {
          case 'Cantidad':
            propina += parseFloat(
              (this.tipForm.get('cantidad').value !== '' && this.tipForm.get('cantidad').value !== null)
                ? this.tipForm.get('cantidad').value : 0);
            break;
          case 'Porcentaje':
            propina += Math.floor((subtotal + IVA) * parseFloat(
              (this.tipForm.get('porcentaje').value !== '' && this.tipForm.get('porcentaje').value !== null)
                ? this.tipForm.get('porcentaje').value : 0)) * 0.01;
            break;
        }
      }
    }
    if (tipo === 'Descuento' || tipo === 'IVA' || tipo === 'Total') {
      if (this.descuento) {
        switch (this.tipoDeDescuento) {
          case 'Cantidad':
            descuento += parseFloat(
              (this.discountForm.get('cantidad').value !== '' && this.discountForm.get('cantidad').value !== null)
                ? this.discountForm.get('cantidad').value : 0);
            break;
          case 'Porcentaje':
            descuento += Math.floor((subtotal + IVA) * parseFloat(
              (this.discountForm.get('porcentaje').value !== '' && this.discountForm.get('porcentaje').value !== null)
                ? this.discountForm.get('porcentaje').value : 0)) * 0.01;
            break;
        }
      }
    }
    switch (tipo) {
      case 'Subtotal':
        total = subtotal;
        break;
      case 'IVA':
        total = IVA;
        break;
      case 'Propina':
        total = propina;
        break;
      case 'Cupon':
        total = cupon;
        break;
      case 'Descuento':
        total = descuento;
        break;
      case 'Total':
        total = Number((subtotal + IVA + propina - cupon - descuento).toFixed(2));
        break;
    }
    if (total < 0) {
      total = 0;
    }
    return total;
  }

  cambiarOrdenesCuenta() {
    this.ordenesCuenta = [];
    this.cantidadesCuenta = [];
    for (let i = 0; i < this.ordenes.length; i++) {
      if (this.ordenesForm.get('orden' + (i + 1)).value) {
        this.ordenesCuenta.push(this.ordenes[i]);
        this.cantidadesCuenta.push(this.ordenesForm.get('cantidadOrden' + (i + 1)).value);
      }
    }
  }

  seleccionarTodo(e) {
    if (e.checked) {
      for (let i = 0; i < this.ordenes.length; i++) {
        this.ordenesForm.get('orden' + (i + 1)).setValue(true);
        this.ordenesForm.get('cantidadOrden' + (i + 1)).setValue(this.ordenes[i].cantidad);
      }
    } else {
      for (let i = 0; i < this.ordenes.length; i++) {
        this.ordenesForm.get('orden' + (i + 1)).setValue(false);
        this.ordenesForm.get('cantidadOrden' + (i + 1)).setValue(1);
      }
    }
    this.cambiarOrdenesCuenta();
  }

  estaTodoSeleccionado() {
    let todoSeleccionado = true;
    for (let i = 0; i < this.ordenes.length; i++) {
      if (!this.ordenesForm.get('orden' + (i + 1)).value
        || (this.ordenesForm.get('cantidadOrden' + (i + 1)).value !== this.ordenes[i].cantidad)) {
        todoSeleccionado = false;
        break;
      }
    }
    return todoSeleccionado;
  }

  cambioSinCliente(): void {
    if (this.sinCliente) {
      this.searchClientForm.get('posicionCliente').setValue('-1');
      this.payForm.get('metodoDePago').setValue('NA');
    } else {
      this.searchClientForm.get('posicionCliente').setValue('');
      this.payForm.get('metodoDePago').setValue('');
    }
  }

  addClient(): void {
    const dialogRef = this.dialog.open(NewClientComponent, {disableClose: true});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          this.dialogAddClientSuccess.show();
        }
      }
    );
  }

  obtenerClientes(): void {
    this.clientRequestState = RequestState.loading;
    this.clientSubscription = this.clientService.getClients(this.searchClientForm.get('criterio').value,
      this.searchClientForm.get('termino').value).subscribe(
      (data: Cliente[]) => {
        this.clientes = data;
        this.clientes.sort((obj1, obj2) => {
          if (obj1.nombres > obj2.nombres) {
            return 1;
          }
          if (obj1.nombres < obj2.nombres) {
            return -1;
          }
          return 0;
        });
        this.searchClientForm.get('posicionCliente').setValue('');
        this.clientRequestState = RequestState.success;
      },
      () => {
        this.clientRequestState = RequestState.error;
      });
  }

  formaDePagoSeleccionada() {
    this.payForm.get('numeroDeTarjeta').reset();
    this.payForm.get('banco').reset();
    this.payForm.get('cantidad').reset();
    switch (this.payForm.get('formaDePago').value) {
      case '01':
        this.payForm.get('numeroDeTarjeta').setValue('0000000000000');
        this.payForm.get('banco').setValue(' ');
        this.payForm.get('cantidad').setValue('0.00');
        break;
      case '04':
      case '28':
        this.payForm.get('numeroDeTarjeta').setValue('');
        this.payForm.get('banco').setValue('');
        this.payForm.get('cantidad').setValue('0.00');
        break;
      default:
        this.payForm.get('cantidad').setValue('0.00');
        this.payForm.get('numeroDeTarjeta').setValue('0000000000000');
        this.payForm.get('banco').setValue(' ');
        break;
    }
  }

  propinaSeleccionada() {
    if (this.propina) {
      this.tipoDePropina = '';
      this.tipForm.get('cantidad').setValue('0.00');
      this.tipForm.get('porcentaje').setValue('');
    } else {
      this.tipForm.get('cantidad').setValue('0.00');
      this.tipForm.get('porcentaje').setValue('0');
    }
  }

  tipoDePropinaSeleccionada() {
    this.tipForm.get('porcentaje').reset();
    this.tipForm.get('cantidad').reset();
    if (this.tipoDePropina === 'Cantidad') {
      this.tipForm.get('cantidad').setValue('0.00');
      this.tipForm.get('porcentaje').setValue('0');
    } else {
      this.tipForm.get('cantidad').setValue('0.00');
      this.tipForm.get('porcentaje').setValue('');
    }
  }

  descuentoSeleccionado() {
    if (this.descuento) {
      this.tipoDeDescuento = '';
      this.discountForm.get('cantidad').setValue('0.00');
      this.discountForm.get('porcentaje').setValue('');
    } else {
      this.discountForm.get('cantidad').setValue('0.00');
      this.discountForm.get('porcentaje').setValue('0');
    }
  }

  tipoDeDescuentoSeleccionado() {
    this.discountForm.get('porcentaje').reset();
    this.discountForm.get('cantidad').reset();
    if (this.tipoDeDescuento === 'Cantidad') {
      this.discountForm.get('cantidad').setValue('0.00');
      this.discountForm.get('porcentaje').setValue('0');
    } else {
      this.discountForm.get('cantidad').setValue('0.00');
      this.discountForm.get('porcentaje').setValue('');
    }
  }

  makePayment(stepper: MatStepper): void {
    const cuenta: Cuenta = {
      moneda: this.payForm.get('moneda').value,
      metodoDePago: this.payForm.get('metodoDePago').value,
      formaDePago: this.payForm.get('formaDePago').value,
      cantidad: null,
      numeroDeTarjeta: null,
      banco: null,
      subtotal: this.calcularPago('Subtotal'),
      porcentajeIva: this.restaurante.ivaActivo ? Number(this.restaurante.IVA) : 0,
      preciosIncluyenIVA: this.preciosIncluyenIVA,
      iva: this.restaurante.ivaActivo ? this.calcularPago('IVA') : 0,
      total: this.calcularPago('Total'),
      ordenes: this.ordenesCuenta,
      cajero: null,
      caja: {
        id: this.idCaja,
        fechaApertura: null,
        montoInicial: null,
        cajero: null,
        total: null
      },
      propina: null,
      cupon: null,
      descuento: null,
      cliente: null,
      comanda: {
        id: this.elementoPorArea.comanda.id,
        fechaApertura: null,
        fechaCierre: null,
        consumoTotal: null
      }
    };
    switch (this.payForm.get('formaDePago').value) {
      case '01':
        cuenta.cantidad = parseFloat(this.payForm.get('cantidad').value);
        this.cambio = cuenta.cantidad - cuenta.total;
        break;
      case '03':
      case '04':
      case '28':
        cuenta.numeroDeTarjeta = this.payForm.get('numeroDeTarjeta').value;
        cuenta.banco = this.payForm.get('banco').value;
        this.cambio = -1;
        break;
      default:
        this.cambio = -1;
    }
    if (this.propina) {
      switch (this.tipoDePropina) {
        case 'Cantidad':
          cuenta.propina = {
            tipo: 'Cantidad',
            porcentaje: null,
            valor: Number(this.tipForm.get('cantidad').value)
          };
          break;
        case 'Porcentaje':
          cuenta.propina = {
            tipo: 'Porcentaje',
            porcentaje: Number(this.tipForm.get('porcentaje').value),
            valor: this.calcularPago('Propina')
          };
          break;
      }
    }
    if (this.couponForm.get('posicionCupon').value !== '') {
      switch (this.cupones[this.couponForm.get('posicionCupon').value - 1].tipo) {
        case 'cantidad':
          cuenta.cupon = {
            id: this.cupones[this.couponForm.get('posicionCupon').value - 1].id,
            tipo: 'Cantidad',
            porcentaje: null,
            valor: Number(this.cupones[this.couponForm.get('posicionCupon').value - 1].descuentoCantidad)
          };
          break;
        case 'porcentaje':
          cuenta.cupon = {
            id: this.cupones[this.couponForm.get('posicionCupon').value - 1].id,
            tipo: 'Porcentaje',
            porcentaje: Number(this.cupones[this.couponForm.get('posicionCupon').value - 1].descuentoPorcentaje),
            valor: this.calcularPago('Cupon')
          };
          break;
      }
    }
    if (this.descuento) {
      switch (this.tipoDeDescuento) {
        case 'Cantidad':
          cuenta.descuento = {
            tipo: 'Cantidad',
            porcentaje: null,
            valor: Number(this.discountForm.get('cantidad').value)
          };
          break;
        case 'Porcentaje':
          cuenta.descuento = {
            tipo: 'Porcentaje',
            porcentaje: Number(this.discountForm.get('porcentaje').value),
            valor: this.calcularPago('Descuento')
          };
          break;
      }
    }
    if (!this.sinCliente) {
      cuenta.cliente = this.clientes[this.searchClientForm.get('posicionCliente').value - 1];
    }
    const idOrdenes: string[] = [];
    for (let i = 0; i < this.ordenesCuenta.length; i++) {
      for (let j = 0; j < this.cantidadesCuenta[i]; j++) {
        idOrdenes.push(this.ordenesCuenta[i].idOrdenes[j]);
      }
    }
    const dialogRef = this.dialog.open(PaymentResultComponent, {disableClose: true, data: {cuenta, idOrdenes}});
    dialogRef.afterClosed().subscribe(
      res => {
        if (res.ok) {
          this.cuenta = res.cuenta;
          this.dialogPaymentSuccess.show();
          this.ordersRequestState = RequestState.loading;
          this.reiniciarFormularios();
          this.reiniciarCuenta();
          this.cambioSinCliente();
          this.formaDePagoSeleccionada();
          stepper.reset();
          this.ordersSubscription = this.obtenerOrdenes().subscribe( (resOrdenes: number) => this.ordersRequestState = resOrdenes );
        }
      }
    );
  }

  imprimirTicket() {
    this.billRequestState = RequestState.loading;
    this.billService.setTipoImpresion(0);
    this.ordersSubscription = this.billService.obtenerCuenta(this.cuenta.id).subscribe(
      (cuenta: Cuenta) => {
        this.ordersSubscription = this.billService.obtenerOrdenesDeCuentaParaTicket(this.cuenta.id).subscribe(
          (ordenes: Orden[]) => {
            if (ordenes.length > 0) {
              ordenes.sort((obj1, obj2) => {
                const nombre1 = obj1.datosProducto != null ? obj1.datosProducto.producto.nombre : obj1.datosPaquete.paquete.nombre;
                const nombre2 = obj2.datosProducto != null ? obj2.datosProducto.producto.nombre : obj2.datosPaquete.paquete.nombre;
                if (nombre1 > nombre2) {
                  return 1;
                }
                if (nombre1 < nombre2) {
                  return -1;
                }
                return 0;
              });
            }
            this.billRequestState = RequestState.success;
            window.print();
          },
          () => {
            this.billRequestState = RequestState.error;
          }
        );
      }, error => {
        this.billRequestState = RequestState.error;
      }
    );

    /*this.billService.setCuentaActual(this.cuenta);
    this.ordersSubscription = this.billService.obtenerOrdenesDeCuentaParaTicket(this.cuenta.id).subscribe(
      (ordenes: Orden[]) => {
        if (ordenes.length > 0) {
          ordenes.sort((obj1, obj2) => {
            const nombre1 = obj1.datosProducto != null ? obj1.datosProducto.producto.nombre : obj1.datosPaquete.paquete.nombre;
            const nombre2 = obj2.datosProducto != null ? obj2.datosProducto.producto.nombre : obj2.datosPaquete.paquete.nombre;
            if (nombre1 > nombre2) {
              return 1;
            }
            if (nombre1 < nombre2) {
              return -1;
            }
            return 0;
          });
        }
        this.billRequestState = RequestState.success;
        window.print();
      },
      () => {
        this.billRequestState = RequestState.error;
      }
    );*/
  }

  cerrarComanda() {
    const dialogRef = this.dialog.open(DeleteComandaComponent, {disableClose: true, data: this.elementoPorArea.comanda.id});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          this.dialogAddComandaSuccess.show().then(() => {
            const url = '/restaurantes/' + this.route.parent.snapshot.paramMap.get('idRestaurante')
              + '/areas/' + this.route.parent.snapshot.paramMap.get('idArea');
            this.router.navigateByUrl(url);
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.cashRegisterSubscription.unsubscribe();
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
    if (this.ordersSubscription !== undefined) {
      this.ordersSubscription.unsubscribe();
    }
    if (this.clientSubscription !== undefined) {
      this.clientSubscription.unsubscribe();
    }
    this.restaurantSubscription.unsubscribe();
  }
}
