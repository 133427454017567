import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { RequestState } from '../../shared/enums/request-state.enum';
import { NewIngredientComponent } from './pages/new-ingredient/new-ingredient.component';
import { EditIngredientComponent } from '../ingredient/pages/edit-ingredient/edit-ingredient.component';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html'
})
export class IngredientsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Ingrediente>;
  filtro: string = '';
  estadoPeticionObtenerIngredientes: number;
  RequestState = RequestState;
  obtenerIngredientesSubscription: Subscription;
  observable: Observable<any>;
  eliminarIngredienteSubscription: Subscription;

  constructor(
    private _ingredientesService: IngredientesService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerIngredientes = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerIngredientes();
  }

  obtenerIngredientes(): void {
    this.estadoPeticionObtenerIngredientes = RequestState.loading;
    this.obtenerIngredientesSubscription = this._ingredientesService.obtenerIngredientes().subscribe(
      (ingredientes: Ingrediente[]) => {
        this.inicializarIngredientes(ingredientes);
        this.estadoPeticionObtenerIngredientes = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngredientes = RequestState.error;
      }
    );
  }

  inicializarIngredientes(ingredientes: Ingrediente[]): void {
    this.dataSource = new MatTableDataSource<Ingrediente>(ingredientes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (ingrediente: Ingrediente, filtro: string) => {
      return ingrediente.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
    this.observable = this.dataSource.connect();
  }

  buscarIngrediente(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarIngrediente(): void {
    const dialogRef = this.dialog.open(NewIngredientComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (ingrediente: Ingrediente) => {
        if (ingrediente) {
          this._alertasService.alertaExitoSinConfirmacion('Ingrediente agregado exitosamente', 'El ingrediente ' + ingrediente.nombre + ' ha sido agregado con éxito.');
          this.dataSource.data.push(ingrediente);
          const ingredientes: Ingrediente[] = this.dataSource.data;
          this.inicializarIngredientes(ingredientes);
          this.buscarIngrediente();
        }
      }
    );
  }

  actualizarIngrediente(ingrediente: Ingrediente): void {
    const dialogRef = this.dialog.open(EditIngredientComponent, { disableClose: true, data: ingrediente });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Ingrediente actualizado exitosamente', 'El ingrediente ' + ingrediente.nombre + ' ha sido actualizado con éxito.');
          this.buscarIngrediente();
        }
      }
    );
  }

  eliminarIngrediente(ingredienteEliminar: Ingrediente): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el ingrediente ' + ingredienteEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarIngredienteSubscription = this._ingredientesService.eliminarIngrediente(ingredienteEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionIngrediente = this.dataSource.data.findIndex((ingrediente: Ingrediente) => {
              return ingrediente._id === ingredienteEliminar._id;
            });
            let ingredientesActualizados: Ingrediente[] = this.dataSource.data;
            if (posicionIngrediente != -1) {
              ingredientesActualizados.splice(posicionIngrediente, 1);
            }
            this.inicializarIngredientes(ingredientesActualizados);
            this.buscarIngrediente();
            this._alertasService.alertaExitoSinConfirmacion('Ingrediente eliminado exitosamente', 'El ingrediente ' + ingredienteEliminar.nombre + ' ha sido eliminado con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerIngredientesSubscription) this.obtenerIngredientesSubscription.unsubscribe();
    if (this.eliminarIngredienteSubscription) this.eliminarIngredienteSubscription.unsubscribe();
  }
}
