import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Cliente } from 'src/app/shared/class-models/cliente.model';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  private rutaBaseClientes = '/clientes';

  constructor(private httpClient: HttpClient) { }

  /* GET */
  obtenerCliente(_idCliente: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseClientes}/${_idCliente}`, AppSettings.Options);
  }

  obtenerClientes(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseClientes}`, AppSettings.Options);
  }

  /* POST */
  agregarCliente(cliente: Cliente): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseClientes}`, JSON.stringify(cliente), AppSettings.Options);
  }

  /* PUT */
  actualizarCliente(_idCliente: string, cliente: Cliente): Observable<any> {
    return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseClientes}/${_idCliente}`, JSON.stringify(cliente), AppSettings.Options);
  }

  /* PATCH */
  eliminarCliente(_idCliente: string): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseClientes}/${_idCliente}`, AppSettings.Options);
  }
}
