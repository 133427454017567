import { Component, OnInit, Input } from '@angular/core';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { Cliente } from 'src/app/shared/class-models/cliente.model';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { TipoDescuento } from 'src/app/shared/enums/tipo-descuento.enum';
import { TipoPropina } from 'src/app/shared/enums/tipo-propina.enum';

@Component({
  selector: 'app-impresion-cuenta',
  templateUrl: './impresion-cuenta.component.html',
  styleUrls: ['./impresion-cuenta.component.scss']
})
export class ImpresionCuentaComponent implements OnInit {
  @Input() cuenta: Cuenta;
  @Input() ordenesAgrupadas: Orden[];
  AppSettings = AppSettings;
  TipoOrden = TipoOrden;
  TipoCupon = TipoCupon;
  TipoDescuento = TipoDescuento;
  TipoPropina = TipoPropina;

  constructor() { }

  ngOnInit() {
  }

  obtenerNombreCompletoUsuario(): string {
    return this.cuenta.usuario.nombres + ' ' + this.cuenta.usuario.primerApellido  + (this.cuenta.usuario.segundoApellido != null ? (' ' + this.cuenta.usuario.segundoApellido) : '')
  }

  obtenerNombreCompletoCliente(): string {
    if (this.cuenta.datosCliente.noAplica) return ''
    else {
      switch(this.cuenta.datosCliente.tipoDePersona) {
        case TipoDePersona.Moral: 
          return this.cuenta.datosCliente.razonSocial;
        case TipoDePersona.Fisica: 
        case TipoDePersona.Ninguna: 
          return this.cuenta.datosCliente.nombres + ' ' + this.cuenta.datosCliente.primerApellido + (this.cuenta.datosCliente.segundoApellido ? (' ' + this.cuenta.datosCliente.segundoApellido) : '');
      }
    }
  }

  obtenerNombreOrden(orden: Orden): string {
    return orden.tipo == TipoOrden.Producto ? orden.datosProducto.producto.nombre : ('Paquete ' + orden.datosPaquete.paquete.nombre);
  }
}
