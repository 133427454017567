import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppSettings} from '../../../../configs/app-settings.config';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';

@Component({
  selector: 'app-ingredient-card',
  templateUrl: './ingredient-card.component.html'
})
export class IngredientCardComponent implements OnInit {
  @Input() ingrediente: Ingrediente;
  @Input() verTitulo: boolean;
  @Input() verVerMas: boolean;
  @Input() verConfiguracion: boolean;
  @Input() verActivo: boolean;
  @Output() delete = new EventEmitter<Ingrediente>();
  @Output() edit = new EventEmitter<Ingrediente>();
  AppSettings = AppSettings;


  constructor() {
  }

  ngOnInit() {}

  mostrarOpcionesConfiguracion() {
    if (!document.getElementById(`configurar${this.ingrediente.id}`).classList.contains('rollIn')) {
      document.getElementById(`configurar${this.ingrediente.id}`).classList.add('rollIn');
      document.getElementById(`configurar${this.ingrediente.id}`).classList.remove('rollOut');
      document.getElementById(`editar${this.ingrediente.id}`).classList.add('fadeInUp-2x');
      document.getElementById(`editar${this.ingrediente.id}`).classList.remove('fadeOutDown-2x');
      document.getElementById(`eliminar${this.ingrediente.id}`).classList.add('fadeInUp');
      document.getElementById(`eliminar${this.ingrediente.id}`).classList.remove('fadeOutDown');
    } else {
      document.getElementById(`configurar${this.ingrediente.id}`).classList.add('rollOut');
      document.getElementById(`configurar${this.ingrediente.id}`).classList.remove('rollIn');
      document.getElementById(`editar${this.ingrediente.id}`).classList.add('fadeOutDown-2x');
      document.getElementById(`editar${this.ingrediente.id}`).classList.remove('fadeInUp-2x');
      document.getElementById(`eliminar${this.ingrediente.id}`).classList.add('fadeOutDown');
      document.getElementById(`eliminar${this.ingrediente.id}`).classList.remove('fadeInUp');
    }
  }

  deleteIngredient() {
    this.delete.emit(this.ingrediente);
  }

  editIngredient() {
    this.edit.emit(this.ingrediente);
  }
}
