import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Cupon } from 'src/app/shared/class-models/cupon.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { CuponesService } from 'src/app/core/servicios/cupones.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef } from '@angular/material';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-agregar-cupon-configuracion',
  templateUrl: './agregar-cupon-configuracion.component.html',
  styleUrls: ['./agregar-cupon-configuracion.component.scss']
})
export class AgregarCuponConfiguracionComponent implements OnInit, OnDestroy {
  titulo = 'Agregar cupón';
  ExpresionesRegulares = ExpresionesRegulares;
  TipoCupon = TipoCupon;
  cupon: Cupon = new Cupon();
  estadoPeticionAgregarCupon: number;
  RequestState = RequestState;
  agregarCuponSubscription: Subscription;
  fechaInicioMinima: Date;

  constructor(
    private _cuponesService: CuponesService,
    private _alertasService: AlertasService,
    public dialogRef: MatDialogRef<AgregarCuponConfiguracionComponent>
  ) {
    this.fechaInicioMinima = new Date();
    this.estadoPeticionAgregarCupon = RequestState.initial;
  }

  ngOnInit(): void { }

  transformarDescuentoCantidad(cantidad: string) {
    if (cantidad != null && cantidad != undefined && !isNaN(Number(cantidad))) {
      this.cupon.descuentoCantidad = Number(cantidad).toFixed(2);
    } else {
      this.cupon.descuentoCantidad = Number('0').toFixed(2);
    }
  }

  transformarDescuentoPorcentaje(porcentaje: string) {
    if (porcentaje != null && porcentaje != undefined && !isNaN(Number(porcentaje))) {
      if (Number(porcentaje) > 100) {
        this.cupon.descuentoPorcentaje = 100
      } else {
        if (Number(porcentaje) < 0) {
          this.cupon.descuentoPorcentaje = 0
        } else {
          this.cupon.descuentoPorcentaje = Number(porcentaje).toFixed(0);
        }
      }
    } else {
      this.cupon.descuentoPorcentaje = 0
    }
  }

  agregarCupon() {
    this.estadoPeticionAgregarCupon = RequestState.loading;
    this.agregarCuponSubscription = this._cuponesService.agregarCupon(this.prepararDatosCupon()).subscribe(
      (cupon: Cupon) => {
        this.estadoPeticionAgregarCupon = RequestState.success;
        this.dialogRef.close(cupon);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarCupon = RequestState.error;
      }
    );
  }

  prepararDatosCupon(): Cupon {
    let cupon = new Cupon();
    cupon.nombre = this.cupon.nombre;
    cupon.fechaInicio = this.cupon.fechaInicio;
    cupon.fechaFin = this.cupon.fechaFin;
    cupon.tipo = this.cupon.tipo;
    switch (cupon.tipo) {
      case TipoCupon.Cantidad:
        cupon.descuentoCantidad = Number(this.cupon.descuentoCantidad);
        cupon.descuentoPorcentaje = null;
        break;
      case TipoCupon.Porcentaje:
        cupon.descuentoPorcentaje = Number(this.cupon.descuentoPorcentaje);
        cupon.descuentoCantidad = null;
        break;
    }
    return cupon;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.agregarCuponSubscription) this.agregarCuponSubscription.unsubscribe();
  }
}
