import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Insumo } from 'src/app/shared/class-models/insumo.model';

@Injectable({
  providedIn: 'root'
})
export class InsumosService {
  private rutaBaseInsumos = '/insumos';

  constructor(private httpClient: HttpClient) { }

  /* GET */
  obtenerInsumo(_idInsumo: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseInsumos}/${_idInsumo}`, AppSettings.Options);
  }

  obtenerInsumos(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseInsumos}`, AppSettings.Options);
  }

  /* POST */
  agregarInsumo(insumo: Insumo): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseInsumos}`, JSON.stringify(insumo), AppSettings.Options);
  }

  /* PUT */
  actualizarInsumo(_idInsumo: string, insumo: Insumo): Observable<any> {
    return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseInsumos}/${_idInsumo}`, JSON.stringify(insumo), AppSettings.Options);
  }

  /* PATCH */
  eliminarInsumo(_idInsumo: string): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseInsumos}/${_idInsumo}`, AppSettings.Options);
  }
}
