import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'fechaConHora'
})
export class FechaConHoraPipe implements PipeTransform {

  transform(fecha: Date) {
    return moment(fecha).locale('es').format('DD/MM/YYYY - hh:mm');
  }

}
