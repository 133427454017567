import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { ProveedoresService } from 'src/app/core/servicios/proveedores.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { EditProviderComponent } from './modals/edit/edit-provider.component';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-proveedor',
  templateUrl: './provider.component.html'
})
export class ProviderComponent implements OnInit, OnDestroy {
  TipoDePersona = TipoDePersona;
  RequestState = RequestState;
  proveedor: Proveedor;
  estadoPeticionObtenerProveedor: number;
  obtenerProveedorSubscripcion: Subscription;
  eliminarProveedorSubscripcion: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _proveedoresService: ProveedoresService,
    private _alertasService: AlertasService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerProveedor = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProveedor();
  }

  obtenerProveedor() {
    this.estadoPeticionObtenerProveedor = RequestState.loading;
    this.obtenerProveedorSubscripcion = this._proveedoresService.obtenerProveedor(this.activatedRoute.snapshot.paramMap.get('idProveedor')).subscribe(
      (proveedor: Proveedor) => {
        this.proveedor = proveedor;
        this.estadoPeticionObtenerProveedor = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProveedor = RequestState.error;
        this.router.navigate(['/proveedores']);
      }
    );
  }

  obtenerRazonSocial(proveedor: Proveedor) {
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral: return proveedor.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return proveedor.nombres + ' ' + proveedor.primerApellido + (proveedor.segundoApellido ? (' ' + proveedor.segundoApellido) : '');
    }
  }

  actualizarProveedor() {
    const dialogRef = this.dialog.open(EditProviderComponent, { disableClose: true, data: this.proveedor });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Proveedor agregado exitosamente', 'El proveedor ' + this.obtenerRazonSocial(this.proveedor) + ' ha sido agregado con éxito');
        }
      }
    );
  }

  eliminarProveedor() {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar al proveedor ' + this.obtenerRazonSocial(this.proveedor) + '?', '')
      .then((result) => {
        if (result.value) {
          this.eliminarProveedorSubscripcion = this._proveedoresService.eliminarProveedor(this.proveedor._id).subscribe(
            (resultado: boolean) => {
              this._alertasService.alertaExitoSinConfirmacion('Proveedor eliminado exitosamente', 'El proveedor ' + this.obtenerRazonSocial(this.proveedor) + ' ha sido eliminado con éxito.');
              this.router.navigate(['/proveedores']);
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.obtenerProveedorSubscripcion) this.obtenerProveedorSubscripcion.unsubscribe();
    if (this.eliminarProveedorSubscripcion) this.eliminarProveedorSubscripcion.unsubscribe();
  }
}
