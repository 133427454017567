import {Component, OnDestroy, OnInit} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material';
import {RequestState} from '../../../../shared/enums/request-state.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-new-ingredient',
  templateUrl: './new-ingredient.component.html'
})
export class NewIngredientComponent implements OnInit, OnDestroy {
  titulo = 'Agregar ingrediente';
  ExpresionesRegulares = ExpresionesRegulares;
  ingrediente: Ingrediente = new Ingrediente();
  estadoPeticionAgregarIngrediente: number;
  RequestState = RequestState;
  agregarIngredienteSubscription: Subscription;
  eventoCambioImagenFotografia: any = '';

  constructor(
    private _ingredientesService: IngredientesService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NewIngredientComponent>
    ) {
    this.estadoPeticionAgregarIngrediente = RequestState.initial;
  }

  ngOnInit(): void {}

  transformarPrecio(precio: string): void {
    if (precio != null && precio != undefined && !isNaN(Number(precio))) {
      this.ingrediente.precio = Number(precio).toFixed(2);
    } else {
      this.ingrediente.precio = Number('0').toFixed(2);
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(evento: ImageCroppedEvent): void {
    this.ingrediente.foto = evento.base64;
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
    //this.imagenFotoRecortada = '';
    //this.eventoCambioImagenFotografia = '';
  //}

  agregarIngrediente(): void {
    this.estadoPeticionAgregarIngrediente = RequestState.loading;
    this.agregarIngredienteSubscription = this._ingredientesService.agregarIngrediente(this.prepararDatosIngrediente()).subscribe(
      (ingrediente: Ingrediente) => {
        this.estadoPeticionAgregarIngrediente = RequestState.success;
        this.modal.close(ingrediente);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarIngrediente = RequestState.error;
      }
    );
  }

  prepararDatosIngrediente(): Ingrediente {
    let ingrediente = new Ingrediente;
    ingrediente.nombre = this.ingrediente.nombre;
    ingrediente.descripcion = this.ingrediente.descripcion;
    ingrediente.esExtra = this.ingrediente.esExtra;
    if (ingrediente.esExtra) {
      ingrediente.precio = parseFloat(this.ingrediente.precio.toString());
    } else {
      ingrediente.precio = null
    }
    ingrediente.foto = this.ingrediente.foto;
    return ingrediente;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.agregarIngredienteSubscription) this.agregarIngredienteSubscription.unsubscribe();
  }
}
