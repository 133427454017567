import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'folio'
})
export class FolioPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    // Si la entrada tiene una longitud de 24 y se conforma de letras y números.
    if (value.length === 24 && /^\w+$/.test(value)) {
      return value.substring(0, 4)
        + ' - ' + value.substring(4, 8)
        + ' - ' + value.substring(8, 12)
        + ' - ' + value.substring(12, 16)
        + ' - ' + value.substring(16, 20)
        + ' - ' + value.substring(20, 24);
    } else {
      return 'Error';
    }
  }
}
