export const Magnitude =
  {
    masa: [
      {nombre: 'kilogramo', unidad: 'kg'},
      {nombre: 'gramo', unidad: 'g'},
      {nombre: 'miligramo', unidad: 'mg'},
      {nombre: 'onza', unidad: 'oz'},
      {nombre: 'libra', unidad: 'lb'}
    ],
    volumen: [
      {nombre: 'litro', unidad: 'l'},
      {nombre: 'galón', unidad: 'gl'}
    ]
  };
