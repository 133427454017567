import {Component, Input, OnInit} from '@angular/core';
import {Color, Label} from 'ng2-charts';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {formatCurrency} from '@angular/common';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {Subscription} from 'rxjs';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { TipoDeReporte } from 'src/app/shared/enums/tipo-de-reporte.enum';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { ReportesService } from 'src/app/core/servicios/reportes.service';

@Component({
  selector: 'app-waiter-report',
  templateUrl: './waiter-report.component.html',
  styleUrls: ['./waiter-report.component.scss']
})
export class WaiterReportComponent implements OnInit {
  @Input() tipoUsuario: number;
  @Input() _idRestaurante: string;
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            min: 0,
            // Include a dollar sign in the ticks
            callback(value, index, values) {
              return formatCurrency(parseFloat(value.toString()),
                'en',
                '$',
                'MXN',
                '.2-2');
            }
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return formatCurrency(parseFloat(value.toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
        },
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartColors: Color[] = [
    { // green
      backgroundColor: 'rgba(7,111,0,0.3)',
      borderColor: 'rgba(7,111,0,1)',
      pointBackgroundColor: 'rgba(7,111,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(7,111,0,0.7)'
    },
  ];
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    {data: [], label: 'Total de ventas neto', borderWidth: 2}
  ];

  form: FormGroup;
  requestState: number;
  restaurantsRequestState: number;
  restaurantsSubscription: Subscription;
  reportSubscription: Subscription;
  restaurants: Restaurante[];
  dias: number[];
  total: number;
  subtotal: number;
  iva: number;
  propinas: number;
  cupones: number;
  descuentos: number;
  TipoUsuario = TipoUsuario;
  TipoDeReporte = TipoDeReporte;

  constructor(private _restaurantesService: RestaurantesService, private _reportesService: ReportesService) {
    this.requestState = RequestState.initial;
  }

  ngOnInit() {
    this.form = new FormGroup(
      {
        reporte: new FormControl(TipoDeReporte.Anual, [Validators.required]),
        anio: new FormControl(2020, [Validators.required]),
        mes: new FormControl(1, [Validators.required]),
        dia: new FormControl(1, [Validators.required]),
        _idRestaurante: new FormControl(this.tipoUsuario === TipoUsuario.Administrador ? '0' : this._idRestaurante, [
            Validators.required
          ]
        )
      });
    this.calcularDias();
    this.requestState = RequestState.loading;
    if (this.tipoUsuario === TipoUsuario.Administrador) {
      this.restaurantsRequestState = RequestState.loading;
      this.restaurantsSubscription = this._restaurantesService.obtenerRestaurantes().subscribe(
        (restaurantes: Restaurante[]) => {
          this.restaurants = restaurantes;
          this.restaurantsRequestState = RequestState.success;
          this.generarReporte();
        }, error => {
          this.restaurantsRequestState = RequestState.error;
        }
      );
    } else {
      this.restaurantsRequestState = RequestState.success;
      this.generarReporte();
    }
  }

  calcularDias() {
    if (this.form.get('reporte').value === TipoDeReporte.Diario) {
      this.dias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
      const maximoNumeroDeDias = new Date(this.form.get('anio').value, this.form.get('mes').value, 0).getDate();
      for (let i = 29; i <= maximoNumeroDeDias; i++) {
        this.dias.push(i);
      }
      this.form.get('dia').setValue(1);
    }
  }

  generarReporte() {
    this.requestState = RequestState.loading;
    let _idRestaurante: string;
    if (this.form.get('_idRestaurante').value === '0') {
      _idRestaurante = null;
    } else {
      _idRestaurante = this.form.get('_idRestaurante').value
    }
    const reporte: number = this.form.get('reporte').value;
    const anio: number = this.form.get('anio').value;
    const mes: number = this.form.get('mes').value;
    const dia: number = this.form.get('dia').value;
    this.reportSubscription = this._reportesService.obtenerVentasMeseros(_idRestaurante, reporte, anio, mes, dia).subscribe(
      (result: {ventas: any[], cuentas: any}) => {
        this.barChartLabels = [];
        this.barChartData[0].data = [];
        result.ventas.forEach((sale) => {
          this.barChartLabels.push(sale.mesero.nombres + ' ' + sale.mesero.primerApellido);
          this.barChartData[0].data.push(sale.total);
        });
        if (result.cuentas!= null) {
          this.total = result.cuentas.total;
          this.subtotal = result.cuentas.subtotal;
          this.iva = result.cuentas.iva;
          this.propinas = result.cuentas.propinas;
          this.cupones = result.cuentas.cupones;
          this.descuentos = result.cuentas.descuentos;
        }
        else {
          this.total = 0;
          this.subtotal = 0;
          this.iva = 0;
          this.propinas = 0;
          this.cupones = 0
          this.descuentos = 0;
        }
        this.requestState = RequestState.success;
      }, error => {
        this.requestState = RequestState.error;
      }
    );
  }
}
