import {Routes} from '@angular/router';

import {IngredientsComponent} from './ingredients.component';
import {NewIngredientComponent} from './pages/new-ingredient/new-ingredient.component';

export const IngredientsRoutes: Routes = [
  {
    path: '',
    component: IngredientsComponent
  }/*,
  {
    path: 'nuevo',
    data: {
      breadcrumb: 'Nuevo'
    },
    component: NewIngredientComponent
  }*/
];
