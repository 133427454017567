import { Component, OnInit, Inject } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { TipoDeInsumo } from 'src/app/shared/enums/tipo-de-insumo.enum';
import { UnidadDeMedida } from 'src/app/shared/class-models/unidad-de-medida.model';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { UnidadesDeMedidaService } from 'src/app/core/servicios/unidad-de-medida.service';
import { ProveedoresService } from 'src/app/core/servicios/proveedores.service';
import { InsumosService } from 'src/app/core/servicios/insumos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Insumo } from 'src/app/shared/class-models/insumo.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AltaUnidadDeMedidaComponent } from '../../unidades-de-medida/alta-unidad-de-medida/alta-unidad-de-medida.component';
import { NewProviderComponent } from 'src/app/modules/providers/modals/new-provider/new-provider.component';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';

@Component({
  selector: 'app-actualizacion-insumo',
  templateUrl: './actualizacion-insumo.component.html',
  styleUrls: ['./actualizacion-insumo.component.scss']
})
export class ActualizacionInsumoComponent implements OnInit {
  /* VARIABLES GENERALES */
  titulo: string = 'Actualizar insumo';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  estadoPeticionObtenerInsumo: number;
  obtenerInsumoSubscription: Subscription;
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;

  /* VARIABLES PARA EL STEP DE INFORMACIÓN GENERAL */
  TipoDeInsumo = TipoDeInsumo;
  unidadesDeMedida: UnidadDeMedida[];
  estadoPeticionObtenerUnidadesDeMedida: number;
  obtenerUnidadesDeMedidaSubscripcion: Subscription;

  /* VARIABLES PARA EL STEP DE PROVEEDORES */
  proveedores: Proveedor[];
  estadoProveedores: boolean[];
  estadoPeticionObtenerProveedores: number;
  obtenerProveedoresSubscripcion: Subscription;

  /* VARIABLES PARA ACTUALIZAR EL INSUMO */
  estadoPeticionActualizarInsumo: number;
  agregarInsumoSubscripcion: Subscription;
  insumo: Insumo;

  constructor(
    private _unidadesDeMedidaService: UnidadesDeMedidaService,
    private _proveedoresService: ProveedoresService,
    private _insumosService: InsumosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    public modal: MatDialogRef<ActualizacionInsumoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Insumo,
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 3;
    this.estadoProveedores = [];
    this.estadoPeticionObtenerInsumo = RequestState.initial;
    this.estadoPeticionObtenerProveedores = RequestState.initial;
    this.estadoPeticionObtenerUnidadesDeMedida = RequestState.initial;
    this.estadoPeticionActualizarInsumo = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerInsumo();
    this.obtenerUnidadesDeMedida();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerInsumo(): void {
    this.estadoPeticionObtenerInsumo = RequestState.loading;
    this.obtenerInsumoSubscription = this._insumosService.obtenerInsumo(this.data._id).subscribe(
      (insumo: Insumo) => {
        this.insumo = insumo;
        this.estadoPeticionObtenerInsumo = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
        this.obtenerProveedores();
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerInsumo = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerUnidadesDeMedida(): void {
    this.unidadesDeMedida = [];
    this.estadoPeticionObtenerUnidadesDeMedida = RequestState.loading;
    this.obtenerUnidadesDeMedidaSubscripcion = this._unidadesDeMedidaService.obtenerUnidadesDeMedida().subscribe(
      (unidadesDeMedida: UnidadDeMedida[]) => {
        this.unidadesDeMedida = unidadesDeMedida;
        this.estadoPeticionObtenerUnidadesDeMedida = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUnidadesDeMedida = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerProveedores(): void {
    this.proveedores = [];
    this.estadoPeticionObtenerProveedores = RequestState.loading;
    this.obtenerProveedoresSubscripcion = this._proveedoresService.obtenerProveedores().subscribe(
      (proveedores: Proveedor[]) => {
        this.proveedores = proveedores;
        this.inicializarProveedoresInsumo();
        this.estadoPeticionObtenerProveedores = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProveedores = RequestState.error;
        this.modal.close();
      }
    );
  }

  inicializarProveedoresInsumo(): void {
    if (this.insumo.proveedores != null) {
      this.proveedores.forEach(proveedor => {
        let posicionProveedor = (<Proveedor[]>this.insumo.proveedores).findIndex(proveedorInsumo => {
          return proveedorInsumo._id == proveedor._id;
        })
        if (posicionProveedor != -1) {
          (<Proveedor[]>this.insumo.proveedores)[posicionProveedor] = proveedor;
          this.estadoProveedores.push(true);
        } else {
          this.estadoProveedores.push(false);
        }
      })
    } else {
      this.insumo.proveedores = [];
      this.estadoProveedores = this.proveedores.map(proveedor => { return false; });
    }
  }


  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  /* MÉTODOS DEL STEP DE INFORMACIÓN GENERAL */
  obtenerTipoDeInsumo(tipoDeInsumo: number): string {
    switch (tipoDeInsumo) {
      case TipoDeInsumo.Ingrediente: return 'Ingrediente';
      case TipoDeInsumo.Producto: return 'Producto';
      case TipoDeInsumo.Otro: return 'Otro';
    }
  }

  agregarUnidadDeMedida(): void {
    const dialogRef = this.dialog.open(AltaUnidadDeMedidaComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (unidadDeMedida: UnidadDeMedida) => {
        if (unidadDeMedida != null) {
          this._alertasService.alertaExitoSinConfirmacion('Unidad de medida agregada exitosamente', 'La unidad de medida ' + unidadDeMedida.nombre + ' ha sido agregada con éxito');
          this.unidadesDeMedida.push(unidadDeMedida);
        }
      }
    );
  }

  /* MÉTODOS DEL STEP DE PROVEEDORES */
  cambioEstadoProveedor(posicionProveedor: number, proveedor: Proveedor): void {
    if (this.estadoProveedores[posicionProveedor]) (<Proveedor[]>this.insumo.proveedores).push(proveedor);
    else {
      const posicionProveedorSeleccionado = (<Proveedor[]>this.insumo.proveedores).indexOf(proveedor, 0);
      if (posicionProveedorSeleccionado > -1) {
        this.insumo.proveedores.splice(posicionProveedorSeleccionado, 1);
      }
    }
  }

  agregarProveedor(): void {
    const dialogRef = this.dialog.open(NewProviderComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (proveedor: Proveedor) => {
        if (proveedor != null) {
          this._alertasService.alertaExitoSinConfirmacion('Proveedor agregado exitosamente', 'El proveedor ' + this.obtenerRazonSocialProveedor(proveedor) + ' ha sido agregado con éxito');
          this.proveedores.push(proveedor);
          this.estadoProveedores.push(false);
        }
      }
    );
  }

  obtenerRazonSocialProveedor(proveedor: Proveedor): string {
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral: return proveedor.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return proveedor.nombres + ' ' + proveedor.primerApellido + (proveedor.segundoApellido ? (' ' + proveedor.segundoApellido) : '');
    }
  }

  /* MÉTODOS PARA ACTUALIZAR EL INSUMO */
  actualizarInsumo(): void {
    this.estadoPeticionActualizarInsumo = RequestState.loading;
    this.agregarInsumoSubscripcion = this._insumosService.actualizarInsumo(this.data._id, this.prepararDatosInsumo()).subscribe(
      (insumo: Insumo) => {
        this.estadoPeticionActualizarInsumo = RequestState.success;
        this.actualizarInsumoOriginal(insumo);
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarInsumo = RequestState.error;
      }
    );
  }

  prepararDatosInsumo(): Insumo {
    let insumo = new Insumo();
    insumo.tipo = this.insumo.tipo;
    switch (insumo.tipo) {
      case TipoDeInsumo.Ingrediente:
        insumo._idIngrediente = this.insumo._idIngrediente;
        insumo._idProducto = null;
        insumo.nombre = null;
        insumo.descripcion = null;
        break;
      case TipoDeInsumo.Producto:
        insumo._idProducto = this.insumo._idProducto;
        insumo._idIngrediente = null;
        insumo.nombre = null;
        insumo.descripcion = null;
        break;
      case TipoDeInsumo.Otro:
        insumo.nombre = this.insumo.nombre;
        insumo.descripcion = this.insumo.descripcion;
        insumo._idIngrediente = null;
        insumo._idProducto = null;
        break;
    }
    insumo._idUnidadDeMedida = this.insumo._idUnidadDeMedida;
    (<Proveedor[]>this.insumo.proveedores).forEach((proveedor) => {
      (<string[]>insumo.proveedores).push(proveedor._id);
    })
    return insumo;
  }

  actualizarInsumoOriginal(insumo: Insumo): void {
    this.data.tipo = insumo.tipo;
    this.data.nombre = insumo.nombre;
    this.data.descripcion = insumo.descripcion;
    this.data._idIngrediente = insumo._idIngrediente;
    this.data.ingrediente = insumo.ingrediente;
    this.data._idProducto = insumo._idProducto;
    this.data.producto = insumo.producto;
    this.data._idUnidadDeMedida = insumo._idUnidadDeMedida;
    this.data.unidadDeMedida = insumo.unidadDeMedida;
    this.data.proveedores = insumo.proveedores;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerInsumoSubscription) this.obtenerInsumoSubscription.unsubscribe();
    if (this.obtenerUnidadesDeMedidaSubscripcion) this.obtenerUnidadesDeMedidaSubscripcion.unsubscribe();
    if (this.obtenerProveedoresSubscripcion) this.obtenerProveedoresSubscripcion.unsubscribe();
    if (this.agregarInsumoSubscripcion) this.agregarInsumoSubscripcion.unsubscribe();
  }
}
