import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: []
})
export class NotesComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
