import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { RequestState } from '../../shared/enums/request-state.enum';
import { NewEmployeeComponent } from './modals/new-employee/new-employee.component';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { EditEmployeeComponent } from '../employee/modals/edit-employee/edit-employee.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';


@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html'
})
export class EmployeesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<Usuario>;
  filtro: string = '';
  estadoPeticionObtenerEmpleados: number;
  RequestState = RequestState;
  obtenerEmpleadosSubscripcion: Subscription;
  eliminarEmpleadoSubscripcion: Subscription;
  columnas = ['restaurante', 'tipo', 'nombreUsuario', 'nombreCompleto', 'opciones'];

  constructor(
    private _usuariosService: UsuariosService,
    private _alertasService: AlertasService,
    public modal: MatDialog) {
    this.estadoPeticionObtenerEmpleados = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerEmpleados();
  }

  obtenerEmpleados(): void {
    this.estadoPeticionObtenerEmpleados = RequestState.loading;
    this.obtenerEmpleadosSubscripcion = this._usuariosService.obtenerUsuarios().subscribe(
      (empleados: Usuario[]) => {
        this.inicializarEmpleados(empleados);
        this.estadoPeticionObtenerEmpleados = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerEmpleados = RequestState.error;
      }
    );
  }

  inicializarEmpleados(empleados: Usuario[]): void {
    this.dataSource = new MatTableDataSource<Usuario>(empleados);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (empleado: Usuario, filtro: string) => {
      return this.obtenerTipoUsuario(empleado.tipo).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerNombreCompleto(empleado).trim().toLowerCase().indexOf(filtro) !== -1 ||
        empleado.nombreUsuario.trim().toLowerCase().indexOf(filtro) !== -1 ||
        empleado.restaurante ? empleado.restaurante.nombre.trim().toLowerCase().indexOf(filtro) !== -1 : false;
    }
  }

  buscarEmpleado(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerTipoUsuario(tipo: number): string {
    switch (tipo) {
      case TipoUsuario.Vendedor: return 'Ventas';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
      default: return '';
    }
  }

  obtenerNombreCompleto(empleado: Usuario): string {
    return empleado.nombres + ' ' + empleado.primerApellido + (empleado.segundoApellido ? (' ' + empleado.segundoApellido) : '');
  }

  agregarEmpleado(): void {
    const referenciaModal = this.modal.open(NewEmployeeComponent, { disableClose: true });
    referenciaModal.afterClosed().subscribe(
      (empleado: Usuario) => {
        if (empleado) {
          this._alertasService.alertaExitoSinConfirmacion('Empleado agregado exitosamente', 'El empleado ' + this.obtenerNombreCompleto(empleado) + ' ha sido agregado con éxito');
          this.dataSource.data.push(empleado);
          const empleados: Usuario[] = this.dataSource.data;
          this.inicializarEmpleados(empleados);
          this.buscarEmpleado();
        }
      }
    );
  }

  actualizarEmpleado(empleado: Usuario): void {
    const referenciaModal = this.modal.open(EditEmployeeComponent, { disableClose: true, data: empleado });
    referenciaModal.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Empleado actualizado exitosamente', 'El empleado ' + this.obtenerNombreCompleto(empleado) + ' ha sido actualizado con éxito');
          this.buscarEmpleado();
        }
      }
    );
  }

  eliminarUsuario(empleadoEliminar: Usuario): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar al empleado ' + this.obtenerNombreCompleto(empleadoEliminar) + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarEmpleadoSubscripcion = this._usuariosService.eliminarUsuario(empleadoEliminar._id).subscribe(
            () => {
              let posicionEmpleado = this.dataSource.data.findIndex((empleado: Usuario) => {
                return empleado._id === empleadoEliminar._id;
              });
              let empleadosActualizados: Usuario[] = this.dataSource.data;
              if (posicionEmpleado != -1) {
                empleadosActualizados.splice(posicionEmpleado, 1);
              }
              this.inicializarEmpleados(empleadosActualizados);
              this.buscarEmpleado();
              this._alertasService.alertaExitoSinConfirmacion('Empleado eliminado exitosamente', 'El empleado ' + this.obtenerNombreCompleto(empleadoEliminar) + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      });
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerEmpleadosSubscripcion) this.obtenerEmpleadosSubscripcion.unsubscribe();
    if (this.eliminarEmpleadoSubscripcion) this.eliminarEmpleadoSubscripcion.unsubscribe();
  }
}
