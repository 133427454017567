import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';

@Injectable({
    providedIn: 'root'
})
export class ReportesService {

    constructor(private httpClient: HttpClient) { }

    /* TODOS SON MÉTODOS GET */

    /* ROOT */
    obtenerVentasLicencias(reporte: number, anio: number, mes: number): Observable<object> {
        return this.httpClient.get(
            `${AppSettings.APIEndpoint}/reportes/ventas-licencias?reporte=${reporte}&anio=${anio}&mes=${mes}`, AppSettings.Options);
    }

    obtenerVentasLicenciasPorVendedor(reporte: number, anio: number, mes: number, dia: number): Observable<object> {
        return this.httpClient.get(
            `${AppSettings.APIEndpoint}/reportes/ventas-licencias-por-vendedor?reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`, AppSettings.Options);
    }

    /* VENDEDOR */
    obtenerVentasLicenciasPersonal(reporte: number, anio: number, mes: number): Observable<any> {
        return this.httpClient.get(
            `${AppSettings.APIEndpoint}/reportes/ventas-licencias-personal?reporte=${reporte}&anio=${anio}&mes=${mes}`, AppSettings.Options);
    }

    /* ADMINISTRADOR Y GERENTE */
    obtenerVentas(idRestaurante: string, reporte: number, anio: number, mes: number): Observable<object> {
        if (idRestaurante == null) {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/ventas?reporte=${reporte}&anio=${anio}&mes=${mes}`,
                AppSettings.Options);
        } else {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/ventas?idRestaurante=${idRestaurante}&reporte=${reporte}&anio=${anio}&mes=${mes}`,
                AppSettings.Options);
        }
    }

    obtenerGastosGenerales(idRestaurante: string, reporte: number, anio: number, mes: number): Observable<object> {
        if (idRestaurante == null) {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/gastos-generales?reporte=${reporte}&anio=${anio}&mes=${mes}`,
                AppSettings.Options);
        } else {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/gastos-generales?idRestaurante=${idRestaurante}&reporte=${reporte}&anio=${anio}&mes=${mes}`,
                AppSettings.Options);
        }
    }

    obtenerGastosDeInsumos(idRestaurante: string, reporte: number, anio: number, mes: number): Observable<object> {
        if (idRestaurante == null) {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/gastos-de-insumos?reporte=${reporte}&anio=${anio}&mes=${mes}`,
                AppSettings.Options);
        } else {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/gastos-de-insumos?idRestaurante=${idRestaurante}&reporte=${reporte}&anio=${anio}&mes=${mes}`,
                AppSettings.Options);
        }
    }

    obtenerVentasCajeros(idRestaurante: string, reporte: number, anio: number, mes: number, dia: number): Observable<object> {
        if (idRestaurante == null) {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/ventas-cajeros?reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`,
                AppSettings.Options);
        } else {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/ventas-cajeros?idRestaurante=${idRestaurante}&reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`,
                AppSettings.Options);
        }
    }

    obtenerVentasMeseros(idRestaurante: string, reporte: number, anio: number, mes: number, dia: number): Observable<object> {
        if (idRestaurante == null) { 
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/ventas-meseros?reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`,
                AppSettings.Options);
        } else {
            return this.httpClient.get(
                `${AppSettings.APIEndpoint}/reportes/ventas-meseros?idRestaurante=${idRestaurante}&reporte=${reporte}&anio=${anio}&mes=${mes}&dia=${dia}`,
                AppSettings.Options);
        }
    }
}
