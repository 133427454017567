import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import UsuarioSocket from 'src/app/shared/class-models/usuario-socket.model';

@Injectable({
  providedIn: 'root'
})
export class WebSocketsService {
  public estatusSocket = false;
  public usuario: UsuarioSocket;

  constructor(private socket: Socket) {
    this.cargarAlmacenamientoLocal();
    this.verificarEstatus();
  }

  verificarEstatus() {
    this.socket.on('connect', () => {
      this.estatusSocket = true;
    })
    this.socket.on('disconnect', () => {
      this.estatusSocket = false;
    })
  }

  emitir(evento: string, payload?: any, callback?: Function) {
    this.socket.emit(evento, payload, callback);
  }

  escuchar(evento: string) {
    return this.socket.fromEvent(evento);
  }

  iniciarSesionWS(usuario: UsuarioSocket) {
    return new Promise((resolve, reject) => {
      this.emitir('actualizando-usuario', usuario, resp => {
        this.usuario = usuario;
        this.guardarAlmacenamientoLocal();
        resolve();
      });
    });
  }

  cerrarSesionWS() {
    return new Promise((resolve, reject) => {
      this.emitir('cerrar-sesion', this.usuario, resp => {
        localStorage.removeItem('usr-swws');
        resolve();
      });
    });
  }

  guardarAlmacenamientoLocal() {
    localStorage.setItem('usr-swws', JSON.stringify(this.usuario));
  }

  cargarAlmacenamientoLocal() {
    if (localStorage.getItem('usr-swws')) {
      this.usuario = JSON.parse(localStorage.getItem('usr-swws'));
      this.iniciarSesionWS(this.usuario);
    }
  }

  obtenerUsuarioSocket() {
    return this.usuario;
  }
}
