import { Area } from './area.model';
import { ElementoPorArea } from './elemento-por-area.model';
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';

export class Comanda {
    _id?: string;
    id?: number;
    fechaApertura: Date;
    fechaCierre: Date;
    numeroDePersonas: number | string;
    observacion: string;
    consumoTotal: number;
    _idElementoPorArea: string;
    elementoPorArea: ElementoPorArea;
    _idArea: string;
    area: Area;
    _idRestaurante: string;
    restaurante: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.consumoTotal = 0;
    }
}