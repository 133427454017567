import { Component, OnInit, Inject } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { GastoDeInsumo, RegistroDeInsumo } from 'src/app/shared/class-models/gasto-de-insumo.model';
import { Subscription } from 'rxjs';
import { Insumo } from 'src/app/shared/class-models/insumo.model';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { FormaDePago } from 'src/app/shared/enums/forma-de-pago.enum';
import { GastosDeInsumosService } from 'src/app/core/servicios/gastos-de-insumos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ProveedoresService } from 'src/app/core/servicios/proveedores.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoDeInsumo } from 'src/app/shared/enums/tipo-de-insumo.enum';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { UnidadDeMedida } from 'src/app/shared/class-models/unidad-de-medida.model';

@Component({
  selector: 'app-alta-gasto-de-insumo',
  templateUrl: './alta-gasto-de-insumo.component.html',
  styleUrls: ['./alta-gasto-de-insumo.component.scss']
})
export class AltaGastoDeInsumoComponent implements OnInit {
  /* VARIABLES GENERALES */
  titulo: string = 'Agregar gasto de insumo';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  FormaDePago = FormaDePago;

  /* VARIABLES PARA PROVEEDORES */
  proveedores: Proveedor[];
  estadoPeticionObtenerProveedores: number;
  obtenerProveedoresSubscripcion: Subscription;

  /* VARIABLES PARA INSUMOS */
  insumos: Insumo[];
  estadoPeticionObtenerInsumos: number;
  obtenerInsumosSubscripcion: Subscription;
  TipoDeInsumo = TipoDeInsumo;

  /* VARIABLES PARA AGREGAR EL GASTO DE INSUMO */
  estadoPeticionAgregarGastoDeInsumo: number;
  agregarGastoDeInsumoSubscripcion: Subscription;
  gastoDeInsumo: GastoDeInsumo = new GastoDeInsumo();

  constructor(
    private _proveedoresService: ProveedoresService,
    private _gastosDeInsumosService: GastosDeInsumosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    public modal: MatDialogRef<AltaGastoDeInsumoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,//Se recibe el ID del restaurante
  ) {
    this.inicializarRegistroDeInsumos();
    this.estadoPeticionObtenerProveedores = RequestState.initial;
    this.estadoPeticionObtenerInsumos = RequestState.initial;
    this.estadoPeticionAgregarGastoDeInsumo = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProveedores();
  }

  /* MÉTODOS PARA PROVEEDORES */
  obtenerProveedores(): void {
    this.proveedores = [];
    this.estadoPeticionObtenerProveedores = RequestState.loading;
    this.obtenerProveedoresSubscripcion = this._proveedoresService.obtenerProveedores().subscribe(
      (proveedores: Proveedor[]) => {
        this.proveedores = proveedores;
        this.estadoPeticionObtenerProveedores = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProveedores = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerRazonSocialProveedor(proveedor: Proveedor): string {
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral: return proveedor.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return proveedor.nombres + ' ' + proveedor.primerApellido + (proveedor.segundoApellido ? (' ' + proveedor.segundoApellido) : '');
    }
  }

  /* MÉTODOS PARA INSUMOS */
  obtenerInsumosProveedor(): void {
    this.inicializarRegistroDeInsumos();
    this.insumos = [];
    this.estadoPeticionObtenerInsumos = RequestState.loading;
    this.obtenerInsumosSubscripcion = this._proveedoresService.obtenerInsumosProveedor(this.gastoDeInsumo._idProveedor).subscribe(
      (insumos: Insumo[]) => {
        this.insumos = insumos;
        this.estadoPeticionObtenerInsumos = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerInsumos = RequestState.error;
      }
    );
  }

  inicializarRegistroDeInsumos(): void {
    this.gastoDeInsumo.insumos = Array(1).fill(new RegistroDeInsumo());
  }

  obtenerNombreDeInsumo(insumo: Insumo): string {
    switch (insumo.tipo) {
      case TipoDeInsumo.Ingrediente: return insumo.ingrediente.nombre;
      case TipoDeInsumo.Producto: return insumo.producto.nombre;
      case TipoDeInsumo.Otro: return insumo.nombre;
    }
  }

  obtenerPosicionInsumo(_idInsumo: string): number {
    return this.insumos.findIndex(insumo => {
      return insumo._id === _idInsumo;
    })
  }

  obtenerUnidadDeMedidaDeInsumo(_idInsumo: string): UnidadDeMedida {
    let posicionInsumo = this.obtenerPosicionInsumo(_idInsumo);
    if (posicionInsumo > -1) {
      return this.insumos[posicionInsumo].unidadDeMedida;
    } else {
      return null;
    }
  }

  obtenerTipoDeInsumo(_idInsumo: string): number {
    let posicionInsumo = this.obtenerPosicionInsumo(_idInsumo);
    if (posicionInsumo > -1) {
      return this.insumos[posicionInsumo].tipo;
    } else {
      return -1;
    }
  }

  transformarCantidadDeInsumo(posicionInsumo: number, cantidadDeInsumo: string, cantidadDeDecimales: number): void {
    if (cantidadDeInsumo != null && cantidadDeInsumo != undefined && !isNaN(Number(cantidadDeInsumo))) {
      this.gastoDeInsumo.insumos[posicionInsumo].cantidadDeInsumo = Number(cantidadDeInsumo).toFixed(cantidadDeDecimales);
    } else {
      this.gastoDeInsumo.insumos[posicionInsumo].cantidadDeInsumo = Number('0').toFixed(cantidadDeDecimales);
    }
  }

  transformarPrecioInsumo(posicionInsumo: number, total: string): void {
    if (total != null && total != undefined && !isNaN(Number(total))) {
      this.gastoDeInsumo.insumos[posicionInsumo].total = Number(total).toFixed(2);
    } else {
      this.gastoDeInsumo.insumos[posicionInsumo].total = Number('0').toFixed(2);
    }
  }

  /* MÉTODOS GENERALES */
  obtenerFormaDePago(tipoFormaDePago: string): string {
    switch (tipoFormaDePago) {
      case FormaDePago.Efectivo: return 'Efectivo';
      case FormaDePago.ChequeNominativo: return 'Cheque nominativo';
      case FormaDePago.TransferenciaElectronicaDeFondos: return 'Transferencia electrónica de fondos';
      case FormaDePago.TarjetaDeCredito: return 'Tarjeta de crédito';
      case FormaDePago.MonederoElectronico: return 'Monedero electrónico';
      case FormaDePago.DineroElectronico: return 'Dinero electróico';
      case FormaDePago.ValesDeDespensa: return 'Vales de despensa';
      case FormaDePago.DacionDePago: return 'Dacion de pago';
      case FormaDePago.PagoPorSubrogacion: return 'Pago por subrogacion';
      case FormaDePago.PagoPorConsignacion: return 'Pago por consignacion';
      case FormaDePago.Condonacion: return 'Condonacion';
      case FormaDePago.Compensacion: return 'Compensacion';
      case FormaDePago.Novacion: return 'Novacion';
      case FormaDePago.Confusion: return 'Confusion';
      case FormaDePago.RemisionDeDeuda: return 'Remision de deuda';
      case FormaDePago.PrescripcionOCaducidad: return 'Prescripcion o caducidad';
      case FormaDePago.ASatisfaccionDelAcreedor: return 'A satisfaccion del acreedor';
      case FormaDePago.TarjetaDeDebito: return 'Tarjeta de debito';
      case FormaDePago.TarjetaDeServicios: return 'Tarjeta de servicios';
      case FormaDePago.AplicacionDeAnticipos: return 'Aplicacion de anticipos';
      case FormaDePago.PorDefinir: return 'Por definir';
    }
  }

  transformarPrecio(total: string): void {
    if (total != null && total != undefined && !isNaN(Number(total))) {
      this.gastoDeInsumo.total = Number(total).toFixed(2);
    } else {
      this.gastoDeInsumo.total = Number('0').toFixed(2);
    }
  }

  /* MÉTODOS PARA AGREGAR EL GASTO DE INSUMO */
  agregarGastoDeInsumo(): void {
    this.estadoPeticionAgregarGastoDeInsumo = RequestState.loading;
    this.agregarGastoDeInsumoSubscripcion = this._gastosDeInsumosService.agregarGastoDeInsumo(this.data, this.prepararDatosGastoDeInsumo()).subscribe(
      (gastoDeInsumo: GastoDeInsumo) => {
        this.estadoPeticionAgregarGastoDeInsumo = RequestState.success;
        this.modal.close(gastoDeInsumo);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarGastoDeInsumo = RequestState.error;
      }
    );
  }

  prepararDatosGastoDeInsumo(): GastoDeInsumo {
    let gastoDeInsumo = new GastoDeInsumo();
    gastoDeInsumo._idProveedor = this.gastoDeInsumo._idProveedor;
    gastoDeInsumo.descripcion = this.gastoDeInsumo.descripcion;
    //gastoDeInsumo.total = 0;
    this.gastoDeInsumo.insumos.forEach(registroDeInsumo => {
      let copiaRegistroDeInsumo = new RegistroDeInsumo();
      copiaRegistroDeInsumo._idInsumo = registroDeInsumo._idInsumo;
      const tipoDeInsumo = this.obtenerTipoDeInsumo(registroDeInsumo._idInsumo);
      if (tipoDeInsumo == TipoDeInsumo.Producto) copiaRegistroDeInsumo._idTamano = registroDeInsumo._idTamano;
      else copiaRegistroDeInsumo._idTamano = null;
      copiaRegistroDeInsumo.cantidadDeInsumo = parseFloat(registroDeInsumo.cantidadDeInsumo.toString());
      copiaRegistroDeInsumo.total = parseFloat(this.gastoDeInsumo.total.toString());
      //copiaRegistroDeInsumo.total = parseFloat(registroDeInsumo.total.toString());
      //(<number>gastoDeInsumo.total) += parseFloat(registroDeInsumo.total.toString());
      gastoDeInsumo.insumos.push(copiaRegistroDeInsumo);
    })
    gastoDeInsumo.formaDePago = this.gastoDeInsumo.formaDePago;
    gastoDeInsumo.total = parseFloat(this.gastoDeInsumo.total.toString());
    return gastoDeInsumo;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerInsumosSubscripcion) this.obtenerInsumosSubscripcion.unsubscribe();
    if (this.agregarGastoDeInsumoSubscripcion) this.agregarGastoDeInsumoSubscripcion.unsubscribe();
  }
}
