import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {Producto} from 'src/app/shared/models/producto';
import {ProductService} from 'src/app/core/services/product.service';
import {StockService} from 'src/app/core/services/stock.service';

@Component({
  selector: 'app-edit-stock-item',
  templateUrl: './edit-stock-item.component.html'
})
export class EditStockItemComponent implements OnInit, OnDestroy {

  disableBtn: boolean;
  form: FormGroup;
  requestState: number;
  requestInputsState: RequestState;
  requestProvidersState: RequestState;
  dataSubscription: Subscription;
  formSubscription: Subscription;
  timeout: number;
  title = 'Editar Existencia Producto';
  productos: any;
  productoseleccionado: Producto;
  @ViewChild('dialogWarning', {static: true}) private dialogWarning: SwalComponent;

  constructor(public dialogRef: MatDialogRef<EditStockItemComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productService: ProductService,
              private stockService: StockService) {
    this.form = new FormGroup(
      {
        id: new FormControl(''),
        activo: new FormControl(''),
        nombre: new FormControl(''),
        fechaRegistro: new FormControl(''),
        idUsuario: new FormControl(''),
        idProducto: new FormControl('', Validators.required),
        cantidad: new FormControl('', Validators.required)
      }
    );
  }

  ngOnInit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.initial;
    this.formSubscription = this.form.valueChanges.subscribe(
      () => this.disableBtn = !this.form.valid
    );
    this.formSubscription = this.productService.obtenerProductos().subscribe(
      (res) => {
        this.productos = res;
        this.requestInputsState = RequestState.loading;
        this.stockService.getExistencia(this.data.id).subscribe(
          (res) => {
            this.form.patchValue(res[0]);
            this.requestInputsState = RequestState.success;
            this.productoseleccionado = this.productos.find(element => {
              return (element.id === this.form.get('idProducto').value);
            });
          }
        );
      }
    );
  }

  ngOnDestroy(): void {
    if (this.dataSubscription != null) {
      this.dataSubscription.unsubscribe();
    }
    this.formSubscription.unsubscribe();
  }

  edit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.dataSubscription = this.stockService.editarExistencia(this.form.value, this.data.idRestaurante).subscribe(
      res => {
        this.requestState = RequestState.success;
        this.form.setValue(res[0]);
        this.dialogRef.close({ok: true, form: this.form.getRawValue()});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogWarning.show().then(
      data => {
        if (data.value) {
          this.dialogRef.close({ok: false});
        }
      }
    );
  }
}
