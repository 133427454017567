import {NgModule} from '@angular/core';
import {PromosModule} from '../promos/promos.module';
import {
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTabsModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatBadgeModule,
  MatProgressBarModule,
  MatTooltipModule
} from '@angular/material';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';

import {PromoComponent} from './promo.component';
// Pages.
import {EditPromoComponent} from './pages/edit-promo/edit-promo.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { VistaPaqueteModalComponent } from './vista-paquete-modal/vista-paquete-modal.component';

@NgModule({
  declarations: [
    PromoComponent,
    EditPromoComponent,
    VistaPaqueteModalComponent
  ],
  imports: [
    ImageCropperModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatChipsModule,
    MatDividerModule,
    PromosModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    PromoComponent,
    EditPromoComponent
  ]
})
export class PromoModule {
}
