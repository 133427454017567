import { Component, OnInit } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { UnidadDeMedida } from 'src/app/shared/class-models/unidad-de-medida.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { UnidadesDeMedidaService } from 'src/app/core/servicios/unidad-de-medida.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { CantidadDeDecimalesParaUnidadesDeMedida } from 'src/app/shared/enums/cantidad-de-decimales-para-unidades-de-medida.enum';

@Component({
  selector: 'app-alta-unidad-de-medida',
  templateUrl: './alta-unidad-de-medida.component.html',
  styleUrls: ['./alta-unidad-de-medida.component.scss']
})
export class AltaUnidadDeMedidaComponent implements OnInit {
  titulo = 'Agregar unidad de medida';
  ExpresionesRegulares = ExpresionesRegulares;
  unidadDeMedida: UnidadDeMedida = new UnidadDeMedida();
  estadoPeticionAgregarUnidadDeMedida: number;
  RequestState = RequestState;
  agregarUnidadDeMedidaSubscription: Subscription;
  CantidadDeDecimalesParaUnidadesDeMedida = CantidadDeDecimalesParaUnidadesDeMedida;

  constructor(
    private _unidadesDeMedidaService: UnidadesDeMedidaService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<AltaUnidadDeMedidaComponent>
    ) {
    this.estadoPeticionAgregarUnidadDeMedida = RequestState.initial;
  }

  ngOnInit(): void {}

  agregarUnidadDeMedida(): void {
    this.estadoPeticionAgregarUnidadDeMedida = RequestState.loading;
    this.agregarUnidadDeMedidaSubscription = this._unidadesDeMedidaService.agregarUnidadDeMedida(this.unidadDeMedida).subscribe(
      (area: UnidadDeMedida) => {
        this.estadoPeticionAgregarUnidadDeMedida = RequestState.success;
        this.modal.close(area);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarUnidadDeMedida = RequestState.error;
      }
    );
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.agregarUnidadDeMedidaSubscription) this.agregarUnidadDeMedidaSubscription.unsubscribe();
  }
}
