import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { TipoDeGastoGeneral } from 'src/app/shared/class-models/tipo-de-gasto-general.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { TiposDeGastosGeneralesService } from 'src/app/core/servicios/tipos-de-gastos-generales.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { AltaTipoDeGastoComponent } from './alta-tipo-de-gasto/alta-tipo-de-gasto.component';
import { ActualizarTipoDeGastoComponent } from './actualizar-tipo-de-gasto/actualizar-tipo-de-gasto.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tipos-de-gastos',
  templateUrl: './tipos-de-gastos.component.html',
  styleUrls: ['./tipos-de-gastos.component.scss']
})
export class TiposDeGastosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<TipoDeGastoGeneral>;
  filtro: string = '';
  estadoPeticionObtenerTiposDeGastosGenerales: number;
  RequestState = RequestState;
  obtenerTiposDeGastosGeneralesSubscripcion: Subscription;
  eliminarTipoDeGastoGeneralSubscripcion: Subscription;
  columnas = ['concepto', 'tipoDePersona', 'razonSocial', 'rfc', 'correo', 'telefono', 'opciones'];

  constructor(
    private _tiposDeGastosGeneralesService: TiposDeGastosGeneralesService,
    private _alertasService: AlertasService,
    public dialog: MatDialog,
    private phonePipe: PhonePipe) {
    this.estadoPeticionObtenerTiposDeGastosGenerales = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerTiposDeGastosGenerales();
  }

  obtenerTiposDeGastosGenerales(): void {
    this.estadoPeticionObtenerTiposDeGastosGenerales = RequestState.loading;
    this.obtenerTiposDeGastosGeneralesSubscripcion = this._tiposDeGastosGeneralesService.obtenerTiposDeGastosGenerales().subscribe(
      (tiposDeGastosGenerales: TipoDeGastoGeneral[]) => {
        this.inicializarTiposDeGastosGenerales(tiposDeGastosGenerales);
        this.estadoPeticionObtenerTiposDeGastosGenerales = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerTiposDeGastosGenerales = RequestState.error;
      }
    );
  }

  inicializarTiposDeGastosGenerales(tiposDeGastosGenerales: TipoDeGastoGeneral[]): void {
    this.dataSource = new MatTableDataSource<TipoDeGastoGeneral>(tiposDeGastosGenerales);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (tipoDeGastoGeneral: TipoDeGastoGeneral, filtro: string) => {
      return tipoDeGastoGeneral.concepto.trim().toLowerCase().indexOf(filtro) !== -1 ||
        (tipoDeGastoGeneral.tieneReceptor ? 
          (this.obtenerTipoDePersona(tipoDeGastoGeneral.tipoDePersona).trim().toLowerCase().indexOf(filtro) !== -1 ||
          this.obtenerRazonSocial(tipoDeGastoGeneral).trim().toLowerCase().indexOf(filtro) !== -1 ||
          tipoDeGastoGeneral.rfc.trim().toLowerCase().indexOf(filtro) !== -1 ||
          (tipoDeGastoGeneral.correo ? tipoDeGastoGeneral.correo.trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
          (tipoDeGastoGeneral.telefono ? tipoDeGastoGeneral.telefono.trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
          (tipoDeGastoGeneral.telefono ? this.phonePipe.transform(tipoDeGastoGeneral.telefono, 'phone').trim().toLowerCase().indexOf(filtro) !== -1 : false))
          : false);
    }
  }

  buscarTipoDeGastoGeneral(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerTipoDePersona(tipo: number): string {
    switch(tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  obtenerRazonSocial(tipoDeGastoGeneral: TipoDeGastoGeneral): string {
    switch(tipoDeGastoGeneral.tipoDePersona) {
      case TipoDePersona.Moral: return tipoDeGastoGeneral.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return tipoDeGastoGeneral.nombres + ' ' + tipoDeGastoGeneral.primerApellido + (tipoDeGastoGeneral.segundoApellido ? (' ' + tipoDeGastoGeneral.segundoApellido) : '');
    }
  }

  agregarTipoDeGastoGeneral(): void {
    const dialogRef = this.dialog.open(AltaTipoDeGastoComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (tipoDeGastoGeneral: TipoDeGastoGeneral) => {
        if (tipoDeGastoGeneral) {
          this._alertasService.alertaExitoSinConfirmacion('Tipo de gasto general agregado exitosamente', 'El tipo de gasto general ' + tipoDeGastoGeneral.concepto + ' ha sido agregado con éxito');
          this.dataSource.data.push(tipoDeGastoGeneral);
          const tiposDeGastosGenerales: TipoDeGastoGeneral[] = this.dataSource.data;
          this.inicializarTiposDeGastosGenerales(tiposDeGastosGenerales);
          this.buscarTipoDeGastoGeneral();
        }
      }
    );
  }

  actualizarTipoDeGastoGeneral(tipoDeGastoGeneral: TipoDeGastoGeneral): void {
    const dialogRef = this.dialog.open(ActualizarTipoDeGastoComponent, { disableClose: true, data: tipoDeGastoGeneral });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
          if (resultado == true) {
            this._alertasService.alertaExitoSinConfirmacion('Tipo de gasto general actualizado exitosamente', 'El tipo de gasto general ' + tipoDeGastoGeneral.concepto + ' ha sido actualizado con éxito.');
            this.buscarTipoDeGastoGeneral();
          }
      }
    );
  }

  eliminarTipoDeGastoGeneral(tipoDeGastoGeneralEliminar: TipoDeGastoGeneral): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el tipo de gasto general ' + tipoDeGastoGeneralEliminar.concepto + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarTipoDeGastoGeneralSubscripcion = this._tiposDeGastosGeneralesService.eliminarTipoDeGastoGeneral(tipoDeGastoGeneralEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionTipoDeGastoGeneral = this.dataSource.data.findIndex((tipoDeGastoGeneral: TipoDeGastoGeneral) => {
                return tipoDeGastoGeneral._id === tipoDeGastoGeneralEliminar._id;
              });
              let tiposDeGastosGeneralesActualizados: TipoDeGastoGeneral[] = this.dataSource.data;
              if (posicionTipoDeGastoGeneral != -1) {
                tiposDeGastosGeneralesActualizados.splice(posicionTipoDeGastoGeneral, 1);
              }
              this.inicializarTiposDeGastosGenerales(tiposDeGastosGeneralesActualizados);
              this.buscarTipoDeGastoGeneral();
              this._alertasService.alertaExitoSinConfirmacion('Tipo de gasto general eliminado exitosamente', 'El tipo de gasto general ' + tipoDeGastoGeneralEliminar.concepto + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerTiposDeGastosGeneralesSubscripcion) this.obtenerTiposDeGastosGeneralesSubscripcion.unsubscribe();
    if (this.eliminarTipoDeGastoGeneralSubscripcion) this.eliminarTipoDeGastoGeneralSubscripcion.unsubscribe();
  }
}
