import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Area } from 'src/app/shared/class-models/area.model';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { TipoArea } from 'src/app/shared/enums/tipo-area.enum';
import { AreasService } from 'src/app/core/servicios/areas.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-area',
  templateUrl: './edit-area.component.html'
})
export class EditAreaComponent implements OnInit, OnDestroy {
  titulo = 'Actualizar área';
  RequestState = RequestState;
  area: Area;

  /* VARIABLES PARA OBTENER EL ÁREA */
  obtenerAreaSubscription: Subscription;
  estadoPeticionObtenerArea: number;
  AppSettings = AppSettings;
  fotoActualArea: string;

  /* VARIABLES PARA ACTUALIZAR EL ÁREA */
  ExpresionesRegulares = ExpresionesRegulares;
  TipoArea = TipoArea;
  actualizarAreaSubscription: Subscription;
  estadoPeticionActualizarArea: number;
  eventoCambioImagenFotografia: any = '';

  constructor(
    private _areasService: AreasService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<EditAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {_idRestaurante: string, area: Area},
  ) {
    this.estadoPeticionObtenerArea = RequestState.initial;
    this.estadoPeticionActualizarArea = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerArea();
  }

  obtenerArea(): void {
    this.estadoPeticionObtenerArea = RequestState.loading;
    this.obtenerAreaSubscription = this._areasService.obtenerArea(this.data._idRestaurante, this.data.area._id).subscribe(
      (area: Area) => {
        this.fotoActualArea = area.foto;
        area.foto = '';
        this.area = area;
        this.estadoPeticionObtenerArea = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerArea = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerTipoArea(tipoArea: number): string {
    switch (tipoArea) {
      case TipoArea.Normal: return 'Normal';
      case TipoArea.Barra: return 'Barra';
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(evento: ImageCroppedEvent): void {
    this.area.foto = evento.base64;
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
  //this.imagenFotoRecortada = '';
  //this.eventoCambioImagenFotografia = '';
  //}

  actualizarArea(): void {
    this.estadoPeticionActualizarArea = RequestState.loading;
    this.actualizarAreaSubscription = this._areasService.actualizarArea(this.data._idRestaurante, this.data.area._id, this.area).subscribe(
      (area: Area) => {
        this.estadoPeticionActualizarArea = RequestState.success;
        this.actualizarAreaOriginal(area);
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarArea = RequestState.error;
      }
    );
  }

  actualizarAreaOriginal(area: Area): void {
    this.data.area.nombre = area.nombre;
    this.data.area.descripcion = area.descripcion;
    this.data.area.tipo = area.tipo;
    this.data.area.foto = area.foto;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerAreaSubscription) this.obtenerAreaSubscription.unsubscribe();
    if(this.actualizarAreaSubscription) this.actualizarAreaSubscription.unsubscribe();
  }
}
