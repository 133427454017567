import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {HttpClient} from '@angular/common/http';
import {Comanda} from '../../shared/models/comanda';

@Injectable({
  providedIn: 'root'
})
export class ComandaService {

  constructor(private httpClient: HttpClient) {
  }

  nuevaComanda(comanda: Comanda): Observable<any> {
    const body = {
      comanda
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/comanda.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarComanda(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/comanda.php?id=${id}`, AppSettings.Options);
  }
}
