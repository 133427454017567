import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatStepper } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ProveedoresService } from 'src/app/core/servicios/proveedores.service';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { RFC_GENERICO } from 'src/app/shared/constants/rfc-generico.constant';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';


@Component({
  selector: 'app-new-provider',
  templateUrl: './new-provider.component.html'
})
export class NewProviderComponent implements OnInit, OnDestroy {
  titulo: string = 'Agregar proveedor';
  @ViewChild('direccionForm') direccionForm: FormGroup;
  @ViewChild('stepper') stepper: MatStepper;
  stepActual: number = 0;
  TipoDePersona = TipoDePersona;
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  proveedor: Proveedor = new Proveedor();
  estadoPeticionAgregarProveedor: number;
  agregarProveedorSubscripcion: Subscription;
  estadosSubscripcion: Subscription;
  ciudadesSubscripcion: Subscription;
  estados: Estado[];
  ciudades: Ciudad[];
  codigoPostal: string = '';

  constructor(public modal: MatDialogRef<NewProviderComponent>,
    private _proveedoresService: ProveedoresService,
    private _ubicacionService: UbicacionService,
    private _alertasService: AlertasService) {
    this.estadoPeticionAgregarProveedor = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerEstados();
  }

  obtenerEstados() {
    this.estados = [];
    this.estadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerCiudades(idEstado: string): void {
    this.ciudades = [];
    this.ciudadesSubscripcion = this._ubicacionService.obtenerCiudades(Number(idEstado)).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerTipoDePersona(tipo: number): string {
    switch (tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  actualizacionTipoDePersona() {
    switch (this.proveedor.tipoDePersona) {
      case TipoDePersona.Moral:
      case TipoDePersona.Fisica:
        if (this.proveedor.rfc == RFC_GENERICO) this.proveedor.rfc = '';
        break;
      case TipoDePersona.Ninguna:
        this.proveedor.rfc = RFC_GENERICO;
        break;
    }
  }

  convertirRfcMayusculas(): void {
    if (this.proveedor.rfc) {
      this.proveedor.rfc = this.proveedor.rfc.toUpperCase();
    }
  }

  async agregarProveedor() {
    this.estadoPeticionAgregarProveedor = RequestState.loading;
    const codigoPostal = await this.verificarCodigoPostal(this.proveedor.idEstado, this.codigoPostal);
    if (!codigoPostal) {
      this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
      this.estadoPeticionAgregarProveedor = RequestState.initial;
      this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
    } else {
      this.proveedor.idCodigoPostal = codigoPostal.id;
      this.agregarProveedorSubscripcion = this._proveedoresService.agregarProveedor(this.prepararDatosProveedor()).subscribe(
        (proveedor: Proveedor) => {
          this.estadoPeticionAgregarProveedor = RequestState.success;
          this.modal.close(proveedor);
        },
        (error: HttpErrorResponse) => {
          this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          this.estadoPeticionAgregarProveedor = RequestState.error;
        }
      );
    }
  }

  async validarCodigoPostal() {
    if (this.codigoPostal.length >= 5) {
      const codigoPostal = await this.verificarCodigoPostal(this.proveedor.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      }
    }
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  prepararDatosProveedor(): Proveedor {
    let proveedor = new Proveedor();
    proveedor.tipoDePersona = this.proveedor.tipoDePersona;
    proveedor.rfc = this.proveedor.rfc;
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral:
        proveedor.razonSocial = this.proveedor.razonSocial;
        proveedor.tieneRepresentante = this.proveedor.tieneRepresentante;
        if (proveedor.tieneRepresentante) {
          proveedor.nombresRepresentante = this.proveedor.nombresRepresentante;
          proveedor.primerApellidoRepresentante = this.proveedor.primerApellidoRepresentante;
          proveedor.segundoApellidoRepresentante = this.proveedor.segundoApellidoRepresentante;
        }
        break;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        proveedor.nombres = this.proveedor.nombres;
        proveedor.primerApellido = this.proveedor.primerApellido;
        proveedor.segundoApellido = this.proveedor.segundoApellido;
        proveedor.tieneRepresentante = false;
        break;
    }
    proveedor.idEstado = this.proveedor.idEstado;
    proveedor.idCiudad = this.proveedor.idCiudad;
    proveedor.calle = this.proveedor.calle;
    proveedor.colonia = this.proveedor.colonia;
    proveedor.numeroExterior = this.proveedor.numeroExterior;
    proveedor.numeroInterior = this.proveedor.numeroInterior;
    proveedor.idCodigoPostal = this.proveedor.idCodigoPostal;
    proveedor.telefono = this.proveedor.telefono;
    proveedor.correo = this.proveedor.correo;
    return proveedor;
  }

  retroceder() {
    this.stepActual--;
    this.stepper.previous();
  }

  avanzar() {
    this.stepActual++;
    this.stepper.next();
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.estadosSubscripcion) this.estadosSubscripcion.unsubscribe();
    if (this.ciudadesSubscripcion) this.ciudadesSubscripcion.unsubscribe();
    if (this.agregarProveedorSubscripcion) this.agregarProveedorSubscripcion.unsubscribe();
  }
}
