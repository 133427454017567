import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from '../../../../configs/app-settings.config';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { PerfilService } from 'src/app/core/servicios/perfil.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertasService } from 'src/app/core/servicios/alertas.service';

@Component({
  selector: 'app-waiter',
  templateUrl: './waiter.component.html',
  styleUrls: []
})
export class WaiterComponent implements OnInit {
  AppSettings = AppSettings;
  @Input() usuario: Usuario;
  perfil: Usuario;
  constructor(private _perfilService: PerfilService,
    private _alertasService: AlertasService) {
  }

  ngOnInit() {
    this.obtenerPerfilUsuario();
  }

  obtenerPerfilUsuario() {
    this._perfilService.obtenerPerfil().subscribe(
      (usuario: Usuario) => {
        this.perfil = usuario;
      },
      (err: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(err.error.titulo, err.error.detalles);
      }
    );
  }

}
