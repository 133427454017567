import {CommonModule} from '@angular/common';
import {
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTabsModule
} from '@angular/material';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {CashRegisterComponent} from './cash-register.component';
// Routes.
import {CashRegisterRoutes} from './cash-register.routes';
import {BillDetailsComponent} from './dialogs/bill-details/bill-details.component';
import {PipesModule} from '../../../../shared/pipes/pipes.module';
import { CuentasDeCajaComponent } from './cuentas-de-caja/cuentas-de-caja.component';

@NgModule({
  declarations: [
    CashRegisterComponent,
    BillDetailsComponent,
    CuentasDeCajaComponent
  ],
  imports: [
    RouterModule.forRoot(CashRegisterRoutes),
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatExpansionModule,
    MatChipsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    CashRegisterComponent
  ]
})
export class CashRegisterModule {
}
