import {Routes} from '@angular/router';

import {InventoryComponent} from './inventory.component';
import {InputsComponent} from './pages/inputs/inputs.component';

export const InventoryRoutes: Routes = [
  {
    path: '', component: InventoryComponent, children: [
      {
        path: 'insumos', component: InputsComponent, data: {
          breadcrumb: 'Insumos'
        },
      },
      {path: '**', redirectTo: 'insumos'}
    ]
  },
];
