import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { ClientesService } from 'src/app/core/servicios/clientes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { EditClientComponent } from './modals/edit/edit-client.component';
import { Cliente } from 'src/app/shared/class-models/cliente.model';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html'
})
export class ClientComponent implements OnInit, OnDestroy {
  TipoDePersona = TipoDePersona;
  RequestState = RequestState;
  cliente: Cliente;
  estadoPeticionObtenerCliente: number;
  obtenerClienteSubscripcion: Subscription;
  eliminarClienteSubscripcion: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _clientesService: ClientesService,
    private _alertasService: AlertasService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerCliente = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCliente();
  }

  obtenerCliente(): void {
    this.estadoPeticionObtenerCliente = RequestState.loading;
    this.obtenerClienteSubscripcion = this._clientesService.obtenerCliente(this.activatedRoute.snapshot.paramMap.get('idCliente')).subscribe(
      (cliente: Cliente) => {
        this.cliente = cliente;
        this.estadoPeticionObtenerCliente = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCliente = RequestState.error;
        this.router.navigate(['/clientes']);
      }
    );
  }

  obtenerRazonSocial(cliente: Cliente): string {
    switch (cliente.tipoDePersona) {
      case TipoDePersona.Moral: return cliente.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return cliente.nombres + ' ' + cliente.primerApellido + (cliente.segundoApellido ? (' ' + cliente.segundoApellido) : '');
    }
  }

  actualizarCliente(): void {
    const dialogRef = this.dialog.open(EditClientComponent, { disableClose: true, data: this.cliente });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Cliente actualizado exitosamente', 'El cliente ' + this.obtenerRazonSocial(this.cliente) + ' ha sido actualizado con éxito');
        }
      }
    );
  }

  eliminarCliente(): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar al cliente ' + this.obtenerRazonSocial(this.cliente) + '?', '')
      .then((result) => {
        if (result.value) {
          this.eliminarClienteSubscripcion = this._clientesService.eliminarCliente(this.cliente._id).subscribe(
            (resultado: boolean) => {
              this._alertasService.alertaExitoSinConfirmacion('Cliente eliminado exitosamente', 'El cliente ' + this.obtenerRazonSocial(this.cliente) + ' ha sido eliminado con éxito.');
              this.router.navigate(['/clientes']);
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.obtenerClienteSubscripcion) this.obtenerClienteSubscripcion.unsubscribe();
    if (this.eliminarClienteSubscripcion) this.eliminarClienteSubscripcion.unsubscribe();
  }
}
