import {NgModule} from '@angular/core';

import {ProductComponent} from './product.component';
import {
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDividerModule,
  MatIconModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatTabsModule
} from '@angular/material';
import {BrowserModule} from '@angular/platform-browser';
import {ProductsModule} from '../products/products.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
// Pages.
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { VistaProductoModalComponent } from './vista-producto-modal/vista-producto-modal.component';

@NgModule({
  declarations: [
    ProductComponent,
    VistaProductoModalComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    BrowserModule,
    ProductsModule,
    RouterModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    ProductComponent
  ]
})
export class ProductModule {
}
