import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatStepperModule,
  MatTooltipModule
} from '@angular/material';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MenuModule} from './pages/menu/menu.module';

import {AreaComponent} from './area.component';
// Components.
import {TableCardComponent} from './components/table-card/table-card.component';
import {OrderCardComponent} from './components/order-card/order-card.component';
// Pages.
import {EditAreaComponent} from './pages/edit-area/edit-area.component';
import {EditTableComponent} from './pages/edit-table/edit-table.component';
import {TableComponent} from './pages/table/table.component';
// Routes.
import {AreaRoutes} from './area.routes';
import {SummaryComponent} from './pages/table/dialogs/summary/summary.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClientsModule} from '../../../clients/clients.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {BrowserModule} from '@angular/platform-browser';
import {OrderComponent} from './pages/order/order.component';
import {AddIngredientComponent} from './pages/order/dialogs/add-ingredient/add-ingredient.component';
import {RemoveIngredientComponent} from './pages/order/dialogs/remove-ingredient/remove-ingredient.component';
import {NotesComponent} from './pages/order/dialogs/notes/notes.component';
import {ProductsModule} from '../../../products/products.module';
import {PromosModule} from '../../../promos/promos.module';
import {ImageCropperModule} from 'ngx-image-cropper';
import {PaymentResultComponent} from './pages/payment/dialogs/payment-result/payment-result.component';
import {NewComandaComponent} from './pages/new-comanda/new-comanda.component';
import {DeleteComandaComponent} from './pages/delete-comanda/delete-comanda.component';
import {NewTableComponent} from './pages/new-table/new-table.component';
import { DirectivesModule } from 'src/app/core/directives/directives.module';
import { FechaConHoraPipe } from 'src/app/shared/pipes/fecha-con-hora.pipe';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AbrirCajaComponent } from './pages/pago/abrir-caja/abrir-caja.component';
import { PagoComponent } from './pages/pago/pago.component';
import { ResumenPagoComponent } from './pages/pago/resumen-pago/resumen-pago.component';
import { ResumenGrupoDeOrdenesComponent } from './pages/table/resumen-grupo-de-ordenes/resumen-grupo-de-ordenes.component';

@NgModule({
  declarations: [
    AreaComponent,
    EditAreaComponent,
    EditTableComponent,
    TableCardComponent,
    TableComponent,
    OrderCardComponent,
    SummaryComponent,
    PaymentComponent,
    OrderComponent,
    AddIngredientComponent,
    RemoveIngredientComponent,
    NotesComponent,
    PaymentResultComponent,
    NewComandaComponent,
    DeleteComandaComponent,
    NewTableComponent,
    AbrirCajaComponent,
    PagoComponent,
    ResumenPagoComponent,
    ResumenGrupoDeOrdenesComponent
  ],
  imports: [
    RouterModule.forRoot(AreaRoutes),
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatChipsModule,
    MatDividerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatInputModule,
    MatRadioModule,
    MenuModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    MatStepperModule,
    ClientsModule,
    SweetAlert2Module,
    ProductsModule,
    PromosModule,
    ImageCropperModule,
    MatTooltipModule,
    DirectivesModule,
    PipesModule
  ],
  exports: [
    AreaComponent,
    EditAreaComponent,
    EditTableComponent,
    TableCardComponent,
    TableComponent,
    OrderCardComponent
  ]
})
export class AreaModule {
}
