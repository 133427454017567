import { Component, OnInit, ViewChild, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { FormGroup } from '@angular/forms';
import { DiaDeLaSemana } from 'src/app/shared/enums/dia-de-la-semana.enum';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { CANTIDAD_SECCIONES_MINIMA, CANTIDAD_SECCIONES_MAXIMA } from 'src/app/shared/constants/configuracion-paquetes.const';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Paquete, PaquetePorTamano, AlternativaProductos, AlternativaProductosPorTamano } from 'src/app/shared/class-models/paquete.model';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import * as momento from 'moment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AgregarTamanoConfiguracionComponent } from 'src/app/modules/configuration/tamanos-configuracion/agregar-tamano-configuracion/agregar-tamano-configuracion.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-promo',
  templateUrl: './edit-promo.component.html',
  styleUrls: ['./edit-promo.component.scss']
})
export class EditPromoComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  titulo = 'Actualizar paquete';
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;

  /* VARIABLES PARA OBTENER LOS DATOS DEL FORMULARIO */
  estadoPeticionObtenerPaquete: number;
  obtenerPaqueteSubscription: Subscription;
  contadorPeticionesFinalizadas: number;
  cantidadPeticionesRequeridas: number;
  fotoActualPaquete: string;
  AppSettings = AppSettings;

  /* VARIABLES DEL STEP DE INFORMACIÓN GENERAL */
  @ViewChild('informacionGeneralPaqueteForm') informacionGeneralPaqueteForm: FormGroup;
  DiaDeLaSemana = DiaDeLaSemana;
  diasDeLaSemana = [0, 1, 2, 3, 4, 5, 6];
  estadoDiasDeLaSemana: boolean[];
  todosLosDiasDeLaSemana: boolean;
  fechaInicioMinima: Date;
  eventoCambioImagenFotografia: any = '';

  /* VARIABLES DEL STEP DE TAMAÑOS */
  tamanos: Tamano[];
  estadoTamanos: boolean[];
  estadoPeticionObtenerTamanos: number;
  obtenerTamanosSubscription: Subscription;

  /* VARIABLES DEL STEP DE SECCIONES */
  cantidadSeccionesMinima = CANTIDAD_SECCIONES_MINIMA;
  cantidadSeccionesMaxima = CANTIDAD_SECCIONES_MAXIMA;

  /* VARIABLES DEL STEP DE ALTERNATIVAS DE PRODUCTOS */
  productos: Producto[];
  estadoAlternativasProducto: boolean[][];
  estadoPeticionObtenerProductosDetallados: number;
  obtenerProductosDetalladosSubscription: Subscription;

  /* VARIABLES DEL STEP DE PRECIOS */
  sugerenciasPreciosPaquetePorTamano: { precioMinimo: number | string, precioMaximo: number | string }[];

  /* VARIABLES PARA AGREGAR EL PRODUCTO */
  estadoPeticionActualizarPaquete: number;
  actualizarPaqueteSubscription: Subscription;
  paquete: Paquete;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Paquete,
    private _paquetesService: PaquetesService,
    private _tamanosService: TamanosService,
    private _productosService: ProductosService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<EditPromoComponent>,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.cantidadPeticionesRequeridas = 2;
    this.estadoPeticionActualizarPaquete = RequestState.initial;
    this.estadoPeticionObtenerPaquete = RequestState.initial;
    this.estadoPeticionObtenerTamanos = RequestState.initial;
    this.estadoPeticionObtenerProductosDetallados = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerPaquete();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerPaquete(): void {
    this.estadoPeticionObtenerPaquete = RequestState.loading;
    this.obtenerPaqueteSubscription = this._paquetesService.obtenerPaquete(this.data._id).subscribe(
      (paquete: Paquete) => {
        if(momento(new Date(paquete.fechaInicio)).isAfter(new Date(Date.now()))) {
          this.fechaInicioMinima = new Date(Date.now());
        } else {
          this.fechaInicioMinima = new Date(paquete.fechaInicio);
        }
        this.fotoActualPaquete = paquete.foto;
        paquete.foto = '';
        this.paquete = paquete;
        this.inicializarDiasDeLaSemanaSeleccionados();
        this.obtenerTamanos();
        this.obtenerProductosDetallados();
        this.estadoPeticionObtenerPaquete = RequestState.success;
      }, 
      (err) => {
        this.estadoPeticionObtenerPaquete = RequestState.error;
        this.modal.close(null);
      }
    );
  }

  inicializarDiasDeLaSemanaSeleccionados(): void {
    this.estadoDiasDeLaSemana = [];
    if(this.paquete.esPermanente) {
      let todosLosDiasDeLaSemanaSeleccionados: boolean = true;
      this.diasDeLaSemana.forEach(diaDeLaSemana => {
        if (this.paquete.diasDeLaSemana.includes(diaDeLaSemana)) {
          this.estadoDiasDeLaSemana.push(true);
        } else {
          todosLosDiasDeLaSemanaSeleccionados = false;
          this.estadoDiasDeLaSemana.push(false);
        }
        this.todosLosDiasDeLaSemana = todosLosDiasDeLaSemanaSeleccionados;
      });
    } else {
      this.todosLosDiasDeLaSemana = false;
      this.cambioTodosLosDiasDeLaSemanaSeleccionados();
    } 
  }

  obtenerTamanos(): void {
    this.estadoPeticionObtenerTamanos = RequestState.loading;
    this.obtenerTamanosSubscription = this._tamanosService.obtenerTamanos().subscribe(
      (tamanos: Tamano[]) => {
        this.tamanos = tamanos;
        this.inicializarEstadoTamanos();
        this.estadoPeticionObtenerTamanos = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerTamanos = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas()
        this.modal.close();
      }
    );
  }

  inicializarEstadoTamanos(): void {
    this.estadoTamanos = [];
    this.sugerenciasPreciosPaquetePorTamano = [];
    this.tamanos.forEach(tamano => {
      let posicionPaquetePorTamano = this.paquete.paquetePorTamano.findIndex(paquetePorTamano => {
        return paquetePorTamano.tamano.id == tamano.id;
      });
      if (posicionPaquetePorTamano > -1) {
        this.estadoTamanos.push(true);
        this.paquete.paquetePorTamano[posicionPaquetePorTamano]._idTamano = tamano._id;
        this.paquete.paquetePorTamano[posicionPaquetePorTamano].tamano = tamano;
        this.paquete.paquetePorTamano[posicionPaquetePorTamano].precio = parseFloat(this.paquete.paquetePorTamano[posicionPaquetePorTamano].precio.toString()).toFixed(2);
        this.sugerenciasPreciosPaquetePorTamano.push({ precioMinimo: 0, precioMaximo: 0 });
      } else {
        this.estadoTamanos.push(false);
      }
    });
  }

  obtenerProductosDetallados(): void {
    this.estadoPeticionObtenerProductosDetallados = RequestState.loading;
    this.obtenerProductosDetalladosSubscription = this._productosService.obtenerProductosDetallados().subscribe(
      (productos: Producto[]) => {
        this.productos = productos;
        this.estadoPeticionObtenerProductosDetallados = RequestState.success;
        this.inicializarEstadoAlternativasProducto();
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProductosDetallados = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas()
        this.modal.close();
      }
    );
  }

  inicializarEstadoAlternativasProducto(): void {
    this.estadoAlternativasProducto = [];
    this.paquete.alternativasProductos.forEach((seccion, posicionSeccion) => {
      this.estadoAlternativasProducto.push([]);
      this.productos.forEach(producto => {
        let posicionAlternativaProducto = (<Producto[]>seccion.productos).findIndex(alternativaProducto => {
          return alternativaProducto.id == producto.id;
        })
        if (posicionAlternativaProducto > -1) {
          this.estadoAlternativasProducto[posicionSeccion].push(true);
          (<Producto[]>seccion.productos)[posicionAlternativaProducto] = producto;
          this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
            paquetePorTamano.alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
              let posicionAlternativaProductosPorTamano = alternativaProductosPorTamano.findIndex(alternativaProductoPorTamano => {
                return alternativaProductoPorTamano._idProducto == producto._id;
              })
              if (posicionAlternativaProductosPorTamano > -1) {
                alternativaProductosPorTamano[posicionAlternativaProductosPorTamano].producto = producto;
              }
            })
          });
        } else {
          this.estadoAlternativasProducto[posicionSeccion].push(false);
        }
      });
    });
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
  }

  /* MÉTODOS DEL STEP DE INFORMACIÓN GENERAL */
  obtenerDiaDeLaSemana(diaDeLaSemana: number): string {
    switch (diaDeLaSemana) {
      case DiaDeLaSemana.Domingo: return 'Domingo';
      case DiaDeLaSemana.Lunes: return 'Lunes';
      case DiaDeLaSemana.Martes: return 'Martes';
      case DiaDeLaSemana.Miercoles: return 'Miércoles';
      case DiaDeLaSemana.Jueves: return 'Jueves';
      case DiaDeLaSemana.Viernes: return 'Viernes';
      case DiaDeLaSemana.Sabado: return 'Sabado';
    }
  }

  cambioTodosLosDiasDeLaSemanaSeleccionados(): void {
    if (this.todosLosDiasDeLaSemana) {
      this.estadoDiasDeLaSemana = Array(7).fill(true);
      this.paquete.diasDeLaSemana = this.diasDeLaSemana;
    } else {
      this.estadoDiasDeLaSemana = Array(7).fill(false);
      this.paquete.diasDeLaSemana = [];
    }
  }

  cambioDiaDeLaSemanaSeleccionado(posicionDiaDeLaSemana: number, diaDeLaSemana): void {
    if (this.estadoDiasDeLaSemana[posicionDiaDeLaSemana] == true) {
      this.paquete.diasDeLaSemana.push(diaDeLaSemana);
    } else {
      this.paquete.diasDeLaSemana = this.paquete.diasDeLaSemana.filter(diaDeLaSemanaFiltro => diaDeLaSemanaFiltro !== diaDeLaSemana);
    }
    this.verificarTodosLosDiasDeLaSemanaSeleccionados();
  }

  verificarTodosLosDiasDeLaSemanaSeleccionados(): void {
    let todosLosDiasDeLaSemanaSeleccionados: boolean = true;
    this.estadoDiasDeLaSemana.forEach(diaDeLaSemana => {
      if (diaDeLaSemana == false) todosLosDiasDeLaSemanaSeleccionados = false;
    })
    this.todosLosDiasDeLaSemana = todosLosDiasDeLaSemanaSeleccionados;
  }

  validarHorario(): void {
    if (this.paquete.horaInicio && this.paquete.horaFin) {
      let cmpHoraInicio = momento({ h: Number(this.paquete.horaInicio.split(':')[0]), m: Number(this.paquete.horaInicio.split(':')[1]) })
      let cmpHoraFin = momento({ h: Number(this.paquete.horaFin.split(':')[0]), m: Number(this.paquete.horaFin.split(':')[1]) });
      if (cmpHoraFin.isBefore(cmpHoraInicio)) {
        this.informacionGeneralPaqueteForm.controls['horaFin'].setErrors({ invalida: true });
      } else {
        this.informacionGeneralPaqueteForm.controls['horaFin'].setErrors(null);
      }
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(evento: ImageCroppedEvent): void {
    this.paquete.foto = evento.base64;
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
  //this.imagenFotoRecortada = '';
  //this.eventoCambioImagenFotografia = '';
  //}

  /* MÉTODOS DEL STEP DE TAMAÑOS */
  cambioEstadoTamano(posicionTamano: number, tamano: Tamano): void {
    if (this.estadoTamanos[posicionTamano]) {
      let paquetePorTamano = new PaquetePorTamano();
      paquetePorTamano.tamano = tamano;
      paquetePorTamano._idTamano = tamano._id;
      this.paquete.paquetePorTamano.push(paquetePorTamano);
      this.paquete.alternativasProductos.forEach((seccion, posicionSeccion) => {
        this.paquete.paquetePorTamano[this.paquete.paquetePorTamano.length - 1].alternativasProductosPorTamano.push([]);
        this.paquete.alternativasProductos[posicionSeccion].productos.forEach(producto => {
          let alternativaProductosPorTamano = new AlternativaProductosPorTamano();
          alternativaProductosPorTamano.producto = producto;
          alternativaProductosPorTamano._idProducto = producto._id;
          this.paquete.paquetePorTamano[this.paquete.paquetePorTamano.length - 1].alternativasProductosPorTamano[posicionSeccion].push(alternativaProductosPorTamano);
        });
        /********************************************************************************************************************************/
        /********************************************************************************************************************************/
        /********************************************************************************************************************************/
      });
      /*this.estadoAlternativasProducto.push(
        this.productos.map(producto => { return false; })
      );*/
      this.sugerenciasPreciosPaquetePorTamano.push({ precioMinimo: 0, precioMaximo: 0 });
    }
    else {
      this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea eliminar el tamaño ' + tamano.nombre + '?', 'Toda la configuración definida en este tamaño será eliminada también.').then((result) => {
        if (result.value) {
          const posicionPaquetePorTamanoSeleccionado = this.paquete.paquetePorTamano.findIndex(paquetePorTamano => {
            return paquetePorTamano._idTamano == tamano._id;
          });
          if (posicionPaquetePorTamanoSeleccionado > -1) {
            this.paquete.paquetePorTamano.splice(posicionPaquetePorTamanoSeleccionado, 1);
            this.sugerenciasPreciosPaquetePorTamano.splice(posicionPaquetePorTamanoSeleccionado, 1);
          }
        } else {
          this.estadoTamanos[posicionTamano] = true;
        }
      })
    }
  }

  agregarTamano(): void {
    const modal = this.dialog.open(AgregarTamanoConfiguracionComponent, { disableClose: true });
    modal.afterClosed().subscribe(
      (tamano: Tamano) => {
        if (tamano != null) {
          this._alertasService.alertaExitoSinConfirmacion('Tamaño agregado exitosamente', 'El tamaño ' + tamano.nombre + ' ha sido agregado con éxito.');
          this.tamanos.push(tamano);
          this.estadoTamanos.push(false);
        }
      }
    );
  }

  /* MÉTODOS DEL STEP DE SECCIONES */
  agregarSeccion(): void {
    if (this.paquete.alternativasProductos.length < this.cantidadSeccionesMaxima) {
      this.paquete.alternativasProductos.push(new AlternativaProductos);
      this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
        paquetePorTamano.alternativasProductosPorTamano.push([]);
      });
      this.estadoAlternativasProducto.push(
        this.productos.map(producto => { return false; })
      );
    }
  }

  eliminarSeccion(posicion: number): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea eliminar la sección?', 'Todos los productos definidos en esta sección serán eliminados también.').then((result) => {
      if (result.value) {
        if (this.paquete.alternativasProductos.length > this.cantidadSeccionesMinima) {
          this.paquete.alternativasProductos.splice(posicion, 1);
          this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
            paquetePorTamano.alternativasProductosPorTamano.splice(posicion, 1);
          });
          this.estadoAlternativasProducto.splice(posicion, 1);
        }
      }
    })
  }

  /* MÉTODOS DEL STEP DE ALTERNATIVAS DE PRODUCTOS */
  cambioEstadoAlternativaProducto(posicionSeccion: number, posicionProducto: number): void {
    if (this.estadoAlternativasProducto[posicionSeccion][posicionProducto]) {
      (<Producto[]>this.paquete.alternativasProductos[posicionSeccion].productos).push(this.productos[posicionProducto]);
      this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
        let alternativaProductosPorTamano = new AlternativaProductosPorTamano();
        alternativaProductosPorTamano.producto = this.productos[posicionProducto];
        alternativaProductosPorTamano._idProducto = this.productos[posicionProducto]._id;
        paquetePorTamano.alternativasProductosPorTamano[posicionSeccion].push(alternativaProductosPorTamano);
      });
    }
    else {
      this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea eliminar el producto ' + this.productos[posicionProducto].nombre + ' de la sección ' + this.paquete.alternativasProductos[posicionSeccion].descripcion + '?', 'Los la configuración de tamaños definida para este producto en esa sección será eliminada también.').then((result) => {
        if (result.value) {
          const posicionAlternativaProductoSeleccionada = (<Producto[]>this.paquete.alternativasProductos[posicionSeccion].productos).indexOf(this.productos[posicionProducto], 0);
          if (posicionAlternativaProductoSeleccionada > -1) {
            (<Producto[]>this.paquete.alternativasProductos[posicionSeccion].productos).splice(posicionAlternativaProductoSeleccionada, 1);
          }
          this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
            const posicionAlternativaProductoPorTamanoSeleccionada = paquetePorTamano.alternativasProductosPorTamano[posicionSeccion].findIndex(alternativaProductoPorTamano => {
              return alternativaProductoPorTamano._idProducto == this.productos[posicionProducto]._id;
            })
            if (posicionAlternativaProductoPorTamanoSeleccionada > -1) {
              paquetePorTamano.alternativasProductosPorTamano[posicionSeccion].splice(posicionAlternativaProductoPorTamanoSeleccionada, 1)
            }
          });
        } else {
          this.estadoAlternativasProducto[posicionSeccion][posicionProducto] = true;
        }
      })
    }
  }

  alternativasProductosPorSeccionValidas(): boolean {
    let alternativasProductosPorSeccionValidas: boolean = true
    this.paquete.alternativasProductos.forEach(alternativaProductos => {
      if (alternativaProductos.productos.length == 0) alternativasProductosPorSeccionValidas = false;
    })
    return alternativasProductosPorSeccionValidas
  }

  /* MÉTODOS PARA EL STEP DE PRECIOS */
  generarSugerenciaPreciosPaquetePorTamano(): void {
    this.paquete.paquetePorTamano.forEach((paquetePorTamano, posicionPaquetePorTamano) => {
      this.sugerenciasPreciosPaquetePorTamano[posicionPaquetePorTamano].precioMinimo = this.calcularPrecioMinimoPaquetePorTamano(posicionPaquetePorTamano);
      this.sugerenciasPreciosPaquetePorTamano[posicionPaquetePorTamano].precioMaximo = this.calcularPrecioMaximoPaquetePorTamano(posicionPaquetePorTamano);
    });
  }

  calcularPrecioMinimoPaquetePorTamano(posicionPaquetePorTamano): number {
    let precioMinimoPaquetePorTamanoPorSeccion: number[] = [];
    this.paquete.paquetePorTamano[posicionPaquetePorTamano].alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
      let precioMinimoProductoPorSeccion: number = null;
      alternativaProductosPorTamano.forEach(alternativaProductoPorTamano => {
        let posicionProductoPorTamano = alternativaProductoPorTamano.producto.productoPorTamano.findIndex(productoPorTamano => {
          return alternativaProductoPorTamano._idTamano == productoPorTamano.tamano._id;
        });
        if (posicionProductoPorTamano > -1) {
          if (precioMinimoProductoPorSeccion == null || precioMinimoProductoPorSeccion > (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio)) {
            precioMinimoProductoPorSeccion = (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio);
          }
        }
      });
      precioMinimoPaquetePorTamanoPorSeccion.push(precioMinimoProductoPorSeccion);
    })
    return precioMinimoPaquetePorTamanoPorSeccion.reduce((sumaPrecios, precioActual) => sumaPrecios + precioActual, 0);
  }

  calcularPrecioMaximoPaquetePorTamano(posicionPaquetePorTamano): number {
    let precioMinimoPaquetePorTamanoPorSeccion: number[] = [];
    this.paquete.paquetePorTamano[posicionPaquetePorTamano].alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
      let precioMinimoProductoPorSeccion: number = null;
      alternativaProductosPorTamano.forEach(alternativaProductoPorTamano => {
        let posicionProductoPorTamano = alternativaProductoPorTamano.producto.productoPorTamano.findIndex(productoPorTamano => {
          return alternativaProductoPorTamano._idTamano == productoPorTamano.tamano._id;
        });
        if (posicionProductoPorTamano > -1) {
          if (precioMinimoProductoPorSeccion == null || precioMinimoProductoPorSeccion < (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio)) {
            precioMinimoProductoPorSeccion = (<number>alternativaProductoPorTamano.producto.productoPorTamano[posicionProductoPorTamano].precio);
          }
        }
      });
      precioMinimoPaquetePorTamanoPorSeccion.push(precioMinimoProductoPorSeccion);
    })
    return precioMinimoPaquetePorTamanoPorSeccion.reduce((sumaPrecios, precioActual) => sumaPrecios + precioActual, 0);
  }

  transformarPrecio(posicionPaquetePorTamano: number, precio: string): void {
    if (precio != null && precio != undefined && !isNaN(Number(precio))) {
      this.paquete.paquetePorTamano[posicionPaquetePorTamano].precio = Number(precio).toFixed(2);
    } else {
      this.paquete.paquetePorTamano[posicionPaquetePorTamano].precio = Number('0').toFixed(2);
    }
  }

  /* MÉTODOS PARA AGREGAR EL PRODUCTO */
  actualizarPaquete(): void {
    this.estadoPeticionActualizarPaquete = RequestState.loading;
    this.actualizarPaqueteSubscription = this._paquetesService.actualizarPaquete(this.data._id, this.prepararDatosPaquete()).subscribe(
      (paquete: Paquete) => {
        this.estadoPeticionActualizarPaquete = RequestState.success;
        this.actualizarPaqueteOriginal(paquete);
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarPaquete = RequestState.error;
      }
    );
  }

  prepararDatosPaquete(): Paquete {
    let paquete = new Paquete;
    paquete.nombre = this.paquete.nombre;
    paquete.descripcion = this.paquete.descripcion;
    paquete.esPermanente = this.paquete.esPermanente;
    if (paquete.esPermanente) {
      paquete.diasDeLaSemana = this.paquete.diasDeLaSemana.sort();
      paquete.fechaInicio = null;
      paquete.fechaFin = null;
    } else {
      paquete.diasDeLaSemana = null;
      paquete.fechaInicio = this.paquete.fechaInicio;
      paquete.fechaFin = this.paquete.fechaFin;
    }
    paquete.horarioValidez = this.paquete.horarioValidez;
    if (paquete.horarioValidez) {
      paquete.horaInicio = this.paquete.horaInicio;
      paquete.horaFin = this.paquete.horaFin;
    } else {
      paquete.horaInicio = null;
      paquete.horaFin = null;
    }
    paquete.foto = this.paquete.foto;
    this.paquete.alternativasProductos.forEach(alternativaProductos => {
      let copiaAlternativaProductos = new AlternativaProductos;
      copiaAlternativaProductos.descripcion = alternativaProductos.descripcion;
      copiaAlternativaProductos.productos = (<Producto[]>alternativaProductos.productos).map(producto => producto._id);
      paquete.alternativasProductos.push(copiaAlternativaProductos);
    })
    this.paquete.paquetePorTamano.forEach(paquetePorTamano => {
      let copiaPaquetePorTamano = new PaquetePorTamano;
      copiaPaquetePorTamano._idTamano = paquetePorTamano._idTamano;
      copiaPaquetePorTamano.precio = parseFloat(paquetePorTamano.precio.toString());
      paquetePorTamano.alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
        let copiaAlternativaProductosPorTamano: AlternativaProductosPorTamano[] = [];
        alternativaProductosPorTamano.forEach(alternativaProductoPorTamano => {
          let copiaAlternativaProductoPorTamano = new AlternativaProductosPorTamano();
          copiaAlternativaProductoPorTamano._idProducto = alternativaProductoPorTamano._idProducto;
          copiaAlternativaProductoPorTamano._idTamano = alternativaProductoPorTamano._idTamano;
          copiaAlternativaProductosPorTamano.push(copiaAlternativaProductoPorTamano);
        })
        copiaPaquetePorTamano.alternativasProductosPorTamano.push(copiaAlternativaProductosPorTamano);
      })
      paquete.paquetePorTamano.push(copiaPaquetePorTamano);
    })
    return paquete;
  }

  actualizarPaqueteOriginal(paquete: Paquete): void {
    this.data.nombre = paquete.nombre;
    this.data.descripcion = paquete.descripcion;
    this.data.esPermanente = paquete.esPermanente;
    this.data.diasDeLaSemana = paquete.diasDeLaSemana;
    this.data.fechaInicio = paquete.fechaInicio;
    this.data.fechaFin = paquete.fechaFin;
    this.data.horarioValidez = paquete.horarioValidez;
    this.data.horaInicio = paquete.horaInicio;
    this.data.horaFin = paquete.horaFin;
    this.data.foto = paquete.foto;
    this.data.alternativasProductos = paquete.alternativasProductos;
    this.data.paquetePorTamano = paquete.paquetePorTamano;
  }

  /* MÉTODOS PARA CERRAR EL MODAL */
  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerPaqueteSubscription) this.obtenerPaqueteSubscription.unsubscribe();
    if (this.obtenerTamanosSubscription) this.obtenerTamanosSubscription.unsubscribe();
    if (this.obtenerProductosDetalladosSubscription) this.obtenerProductosDetalladosSubscription.unsubscribe();
    if (this.actualizarPaqueteSubscription) this.actualizarPaqueteSubscription.unsubscribe();
  }
}
