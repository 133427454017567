import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private httpClient: HttpClient) {
  }

  obtenerEstados(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/directorio/estados.php`, AppSettings.Options);
  }

  obtenerCiudades(idEstado: number): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/directorio/ciudades.php?idEstado=` + idEstado, AppSettings.Options);
  }

  verificarCodigoPostal(idEstado: string, codigoPostal: string): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/directorio/verificar-codigo-postal.php?idEstado=` + idEstado + `&codigoPostal=` + codigoPostal,
      AppSettings.Options);
  }
}
