import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppSettings} from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private httpClient: HttpClient) {

  }

  obtenerExistencias(idSucursal: String): Observable<any> {
    return this.httpClient.get<any>(`${AppSettings.APIEndpoint}/existencias.php?idSucursal=${idSucursal}`, AppSettings.Options);
  }

  getExistencia(idExistencia: string): Observable<any> {
    return this.httpClient.get<any>(`${AppSettings.APIEndpoint}/existencia.php?id=` + idExistencia, AppSettings.Options);
  }

  agregarExistencia(existencia: any, idSucursal: string): Observable<any> {
    existencia.restaurante = {
      id: idSucursal,
      nombre: null,
      estado: null,
      ciudad: null,
      IVA: null,
      rfc: null,
    };
    const body = {
      stock: existencia
    };
    return this.httpClient.post<any>(`${AppSettings.APIEndpoint}/existencia.php`, JSON.stringify(body), AppSettings.Options);
  }

  editarExistencia(existencia: any, idSucursal: string): Observable<any> {
    existencia.restaurante = {
      id: idSucursal,
      nombre: null,
      estado: null,
      ciudad: null,
      IVA: null,
      rfc: null,
    };
    const body = {
      stock: existencia
    };
    return this.httpClient.put<any>(`${AppSettings.APIEndpoint}/existencia.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarExistencia(idExistencia: string) {
    return this.httpClient.delete<any>(`${AppSettings.APIEndpoint}/existencia.php?id=` + idExistencia, AppSettings.Options);
  }
}
