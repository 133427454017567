import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { RequestState } from '../../shared/enums/request-state.enum';
import { Paquete, AlternativaProductosPorTamano } from 'src/app/shared/class-models/paquete.model';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { EditPromoComponent } from './pages/edit-promo/edit-promo.component';
import { DiaDeLaSemana } from 'src/app/shared/enums/dia-de-la-semana.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { VistaProductoModalComponent } from '../product/vista-producto-modal/vista-producto-modal.component';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html'
})
export class PromoComponent implements OnInit {
  RequestState = RequestState;
  estadoPeticionObtenerPaquete: number;
  obtenerPaqueteSubscription: Subscription;
  eliminarPaqueteSubscription: Subscription;
  paquete: Paquete;

  /* PAQUETE POR TAMAÑO */
  columnasPaquetePorTamano: string[] = ['producto', 'tamano', 'opciones'];
  tablasPaquetePorTamano: MatTableDataSource<AlternativaProductosPorTamano>[][] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private _paquetesService: PaquetesService,
    private _alertasService: AlertasService,
    private router: Router,
    public modal: MatDialog
  ) {
    this.estadoPeticionObtenerPaquete = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerPaquete();
  }

  obtenerPaquete(): void {
    this.estadoPeticionObtenerPaquete = RequestState.loading;
    this.obtenerPaqueteSubscription = this._paquetesService.obtenerPaquete(this.activatedRoute.snapshot.paramMap.get('idPaquete')).subscribe(
      (paquete: Paquete) => {
        this.paquete = paquete;
        this.inicializarPaquetePorTamano();
        this.estadoPeticionObtenerPaquete = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerPaquete = RequestState.error;
        this.router.navigate(['/paquetes']);
      }
    );
  }

  inicializarPaquetePorTamano(): void {
    this.tablasPaquetePorTamano = [];
    this.paquete.paquetePorTamano.forEach((paquetePorTamano, posicion) => {
      this.tablasPaquetePorTamano.push([]);
      this.paquete.paquetePorTamano[posicion].alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
        this.tablasPaquetePorTamano[posicion].push(new MatTableDataSource<AlternativaProductosPorTamano>(alternativaProductosPorTamano));
      })
    });
  }

  obtenerDiasDeLaSemana(): string {
    let diasDeLaSemana: string = '';//Todos los 
    for (let posicionDiaDeLaSemana = 0; posicionDiaDeLaSemana < this.paquete.diasDeLaSemana.length; posicionDiaDeLaSemana++) {
      diasDeLaSemana += this.obtenerDiaDeLaSemana(this.paquete.diasDeLaSemana[posicionDiaDeLaSemana]);
      if (posicionDiaDeLaSemana <= this.paquete.diasDeLaSemana.length - 3) {
        diasDeLaSemana += ', ';
      } else {
        if (posicionDiaDeLaSemana === this.paquete.diasDeLaSemana.length - 2) {
          diasDeLaSemana += ' y ';
        } /*else {
          diasDeLaSemana += '.';
        }*/
      }
    }
    return diasDeLaSemana;
  }


  obtenerDiaDeLaSemana(diaDeLaSemana: number): string {
    switch (diaDeLaSemana) {
      case DiaDeLaSemana.Domingo: return 'Domingo';
      case DiaDeLaSemana.Lunes: return 'Lunes';
      case DiaDeLaSemana.Martes: return 'Martes';
      case DiaDeLaSemana.Miercoles: return 'Miércoles';
      case DiaDeLaSemana.Jueves: return 'Jueves';
      case DiaDeLaSemana.Viernes: return 'Viernes';
      case DiaDeLaSemana.Sabado: return 'Sabado';
    }
  }

  verProducto(producto: Producto) {
    const dialogRef = this.modal.open(VistaProductoModalComponent,
      {
        disableClose: true,
        data: producto
      });
  }

  actualizarPaquete(paquete: Paquete): void {
    const dialogRef = this.modal.open(EditPromoComponent, { disableClose: true, data: paquete });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Paquete actualizado exitosamente', 'El paquete ' + paquete.nombre + ' ha sido actualizado con éxito.');
          this.inicializarPaquetePorTamano();
        }
      }
    );
  }

  eliminarPaquete(paquete: Paquete): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el paquete ' + paquete.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarPaqueteSubscription = this._paquetesService.eliminarPaquete(paquete._id).subscribe(
          (resultado: boolean) => {
            this._alertasService.alertaExitoSinConfirmacion('Paquete eliminado exitosamente', 'El paquete ' + paquete.nombre + ' ha sido eliminado con éxito.');
            this.router.navigate(['/paquetes']);
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.obtenerPaqueteSubscription) this.obtenerPaqueteSubscription.unsubscribe();
    if (this.eliminarPaqueteSubscription) this.eliminarPaqueteSubscription.unsubscribe();
  }
}
