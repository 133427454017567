import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Licencia} from '../../../../../shared/models/licencia';

@Component({
  selector: 'app-licence-info',
  templateUrl: './licence-info.component.html',
  styleUrls: []
})
export class LicenceInfoComponent implements OnInit {

  formasDePago = [
    {
      clave: '01',
      descripcion: 'Efectivo'
    },
    {
      clave: '02',
      descripcion: 'Cheque nominativo'
    },
    {
      clave: '03',
      descripcion: 'Transferencia electronica de fondos'
    },
    {
      clave: '04',
      descripcion: 'Tarjeta de credito'
    },
    {
      clave: '05',
      descripcion: 'Monedero electronico'
    },
    {
      clave: '06',
      descripcion: 'Dinero electronico'
    },
    {
      clave: '08',
      descripcion: 'Vales de despensa'
    },
    {
      clave: '12',
      descripcion: 'Dacion de pago'
    },
    {
      clave: '13',
      descripcion: 'Pago por subrogacion'
    },
    {
      clave: '14',
      descripcion: 'Pago por consignacion'
    },
    {
      clave: '15',
      descripcion: 'Condonacion'
    },
    {
      clave: '17',
      descripcion: 'Compensacion'
    },
    {
      clave: '23',
      descripcion: 'Novacion'
    },
    {
      clave: '24',
      descripcion: 'Confusion'
    },
    {
      clave: '25',
      descripcion: 'Remision de deuda'
    },
    {
      clave: '26',
      descripcion: 'Prescripcion o caducidad'
    },
    {
      clave: '27',
      descripcion: 'A satisfaccion del acreedor'
    },
    {
      clave: '28',
      descripcion: 'Tarjeta de debito'
    },
    {
      clave: '29',
      descripcion: 'Tarjeta de servicios'
    },
    {
      clave: '30',
      descripcion: 'Aplicacion de anticipos'
    },
    {
      clave: '99',
      descripcion: 'Por definir'
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<LicenceInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Licencia,
    public dialog: MatDialog) {
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  obtenerTipoPago() {
    const posicion = this.formasDePago.findIndex((formaDePago) => {
      return formaDePago.clave === this.data.formaDePago;
    });
    if (posicion !== -1) {
      return this.formasDePago[posicion].descripcion;
    } else {
      return 'Desconocida';
    }
  }

}
