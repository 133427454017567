import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {AppSettings} from '../../configs/app-settings.config';
import {Usuario} from 'src/app/shared/models/usuario';
import {Licencia} from 'src/app/shared/models/licencia';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  public licenseInfo: Licencia;
  public licenses: Licencia[];

  constructor(private httpClient: HttpClient) {
  }

  public getLicense(id: string) {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/licencia.php?idLicencia=` + id, AppSettings.Options);
  }

  public getLicenses(): Observable<Licencia[]> {
    return this.httpClient.get<Licencia[]>(
      `${AppSettings.APIEndpoint}/licencias.php`, AppSettings.Options);
  }

  public getOwnLicenses(): Observable<Licencia[]> {
    return this.httpClient.get<Licencia[]>(`${AppSettings.APIEndpoint}/mis-licencias.php`, AppSettings.Options);
  }

  public getUsersAdmin(): Observable<Usuario[]> {
    return this.httpClient.get<Usuario[]>(`${AppSettings.APIEndpoint}/usuarios/usuarios-admin.php?criterio=&term=`, AppSettings.Options);
  }

  public addLicense(licencia: Licencia): Observable<Licencia> {
    licencia.monto = parseFloat(licencia.monto.toString());
    const body = {
      license: licencia
    };
    return this.httpClient.post<Licencia>(`${AppSettings.APIEndpoint}/licencia.php`, JSON.stringify(body), AppSettings.Options);
  }

  public editLicense(licencia: Licencia, id: string): Observable<Licencia> {
    licencia.id = id;
    licencia.monto = parseFloat(licencia.monto.toString());
    const body = {
      license: licencia
    };
    return this.httpClient.put<Licencia>(`${AppSettings.APIEndpoint}/licencia.php`, JSON.stringify(body), AppSettings.Options);
  }

  public deleteLicense(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/licencia.php?idLicencia=` + id, AppSettings.Options);
  }
}
