import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Producto } from 'src/app/shared/class-models/producto.model';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  private rutaBaseProductos = '/productos';

  constructor(private httpClient: HttpClient) {
  }

  /* GET */
  obtenerProducto(_idProducto: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseProductos}/${_idProducto}`, AppSettings.Options);
  }

  obtenerProductos(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseProductos}`, AppSettings.Options);
  }

  obtenerProductosDetallados(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseProductos}/detallados`, AppSettings.Options);
  }

  obtenerProductosSinInsumoRegistrado(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseProductos}/sin-insumo-registrado`, AppSettings.Options);
  }

  /* POST */
  agregarProducto(producto: Producto): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseProductos}`, JSON.stringify(producto), AppSettings.Options);
  }

  /* PUT */
  actualizarProducto(_idProducto: string, producto: Producto): Observable<any> {
    return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseProductos}/${_idProducto}`, JSON.stringify(producto), AppSettings.Options);
  }

  /* PATCH */
  eliminarProducto(_idProducto: string): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseProductos}/${_idProducto}`, AppSettings.Options);
  }


  /*obtenerProductosDeCocina(idSucursal: string): Observable<object> {
  return this.httpClient.get(`${AppSettings.APIEndpoint}/productos/cocina.php?id=` + idSucursal, AppSettings.Options);
}

agregarProductoACocina(idSucursal: string, idProducto: string): Observable<object> {
  const body = {
    idSucursal,
    idProducto
  };
  return this.httpClient.post(`${AppSettings.APIEndpoint}/producto-cocina.php`, JSON.stringify(body), AppSettings.Options);
}

eliminarProductoDeCocina(idSucursal: string, idProducto: string): Observable<object> {
  return this.httpClient.delete(
    `${AppSettings.APIEndpoint}/producto-cocina.php?idSucursal=${idSucursal}&idProducto=${idProducto}`,
    AppSettings.Options);
}

obtenerProductosSucursal(idSucursal: string): Observable<object> {
  return this.httpClient.get(`${AppSettings.APIEndpoint}/productos/obtener-sucursal.php?id=` + idSucursal, AppSettings.Options);
}*/
}
