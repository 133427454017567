import {Component, OnInit} from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  usuario: Usuario;
  TipoUsuario = TipoUsuario;

  constructor(private _authService: AuthService) {
  }

  ngOnInit(): void {
    this.usuario = this._authService.obtenerUsuario();
  }
}
