import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatChipInputEvent, MatDialogRef} from '@angular/material';
import {Paquete} from '../../../../../../../../shared/models/paquete';
import {RequestState} from '../../../../../../../../shared/enums/request-state.enum';
import {Subscription} from 'rxjs';
import {PromoService} from '../../../../../../../../core/services/promo.service';
import {Producto} from '../../../../../../../../shared/models/producto';
import {Ingrediente} from '../../../../../../../../shared/models/ingrediente';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {IngredientService} from '../../../../../../../../core/services/ingredient.service';
import {ProductService} from '../../../../../../../../core/services/product.service';
import {Orden} from '../../../../../../../../shared/models/orden';

@Component({
  selector: 'app-promo-size',
  templateUrl: './promo-size.component.html',
  styleUrls: []
})
export class PromoSizeComponent implements OnInit, OnDestroy {

  disableBtn: boolean;
  paquetePorTamanoSeleccionado: any;
  productosSeleccionados: Producto[] = [];
  subscription: Subscription;
  productSubscription: Subscription[] = [];
  requestState: number;
  paquete: Paquete;
  productos: Producto[] = [];
  productsRequestState: number;
  contadorProductos: number;
  ingredientesSeleccionados: Ingrediente[][] = [];
  extraIngredientesSubscription: Subscription;
  ingredientesExtra: Ingrediente[];
  extraIngredientesRequestState: number;
  ingredientesExtraSeleccionados: Ingrediente[][] = [];
  orden: Orden;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  notas: any[][] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<PromoSizeComponent>,
              private promoService: PromoService,
              private productService: ProductService,
              private ingredientService: IngredientService) {
    this.paquetePorTamanoSeleccionado = null;
    this.contadorProductos = 0;
  }

  ngOnInit(): void {
    this.requestState = RequestState.loading;
    this.subscription = this.promoService.obtenerPaqueteSucursal(this.data.id, this.data.idSucursal).subscribe(
      (res: any) => {
        this.paquete = res;
        if (this.paquete.paquetePorTamano.length > 0) {
          this.paquetePorTamanoSeleccionado = this.paquete.paquetePorTamano[0];
        }
        this.paquete.alternativasProductos.forEach(() => {
          this.productosSeleccionados.push(null);
          this.productos.push(null);
          this.ingredientesExtraSeleccionados.push([]);
          this.ingredientesSeleccionados.push([]);
          this.notas.push([]);
        });
        this.requestState = RequestState.success;
        this.extraIngredientesSubscription = this.ingredientService.obtenerIngredientesExtra().subscribe(
          (data: Ingrediente[]) => {
            this.ingredientesExtra = data;
            this.extraIngredientesRequestState = RequestState.success;
          },
          error => {
            console.error(error);
            this.extraIngredientesRequestState = RequestState.error;
          }
        );
      });
  }

  obtenerProductos() {
    this.productsRequestState = RequestState.loading;
    this.contadorProductos = 0;
    this.productosSeleccionados.forEach((producto, posicion) => {
      this.productSubscription[posicion] = this.productService.obtenerProducto(producto.id).subscribe(
        (data: Producto) => {
          this.productos[posicion] = data;
          this.productsRequestState = RequestState.success;
          this.contadorProductos++;
        },
        error => {
          console.error(error);
          this.productsRequestState = RequestState.error;
        }
      );
    });
  }

  setIngredient(id: any, i: number): void {
    const posicion = this.ingredientesSeleccionados[i].findIndex((ingrediente) => {
      return ingrediente.id === id;
    });
    if (posicion === -1) {
      this.ingredientesSeleccionados[i].push(this.productos[i].ingredientes.find((ingrediente) => {
          return ingrediente.id === id;
        }
      ));
    } else {
      this.ingredientesSeleccionados[i].splice(posicion, 1);
    }
  }

  setExtraIngredient(id: any, i: number): void {
    const posicion = this.ingredientesExtraSeleccionados[i].findIndex((ingrediente) => {
      return ingrediente.id === id;
    });
    if (posicion === -1) {
      this.ingredientesExtraSeleccionados[i].push(this.ingredientesExtra.find((ingrediente) => {
          return ingrediente.id === id;
        }
      ));
    } else {
      this.ingredientesExtraSeleccionados[i].splice(posicion, 1);
    }
  }

  agregarNota(event: MatChipInputEvent, i: number): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.notas[i].push({observacion: value.trim()});
    }
    if (input) {
      input.value = '';
    }
  }

  eliminarNota(nota: any, i: number): void {
    const posicion = this.notas[i].indexOf(nota);
    if (posicion >= 0) {
      this.notas[i].splice(posicion, 1);
    }
  }

  deshabilitarBoton() {
    if (this.paquetePorTamanoSeleccionado == null) {
      return true;
    }
    for (const producto of this.productosSeleccionados) {
      if (producto == null) {
        return true;
      }
    }
    return false;
  }

  nuevaOrden() {
    const alternativas = [];
    this.paquete.alternativasProductos.forEach((alternativa, posicion) => {
      alternativas.push({
        producto: {
          id: this.productosSeleccionados[posicion].id,
          nombre: this.productosSeleccionados[posicion].nombre
        },
        tamano: this.paquetePorTamanoSeleccionado.alternativasProductosPorTamano[posicion].find((elemento) => {
          if (elemento.producto.id === this.productosSeleccionados[posicion].id) {
            return elemento;
          }
        }).tamano,
        agregarIngredientes: this.ingredientesExtraSeleccionados[posicion],
        quitarIngredientes: this.ingredientesSeleccionados[posicion],
        notas: this.notas[posicion]
      });
    });
    this.orden = {
      cantidad: 1,
      cancelada: false,
      fecha: null, // new Date().toISOString()
      datosPaquete: {
        paquete: {
          id: this.paquete.id,
          nombre: this.paquete.nombre,
          clasificacion: null
        },
        alternativasProductosPorTamano: alternativas,
      },
      precioPorTamano: {
        tamano: this.paquetePorTamanoSeleccionado.tamano,
        precio: this.paquetePorTamanoSeleccionado.precio
      }
    };
    this.dialogRef.close({ok: true, orden: this.orden});
  }

  close(): void {
    this.dialogRef.close({ok: false});
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
