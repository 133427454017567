import {Component, OnInit} from '@angular/core';
import {ChartOptions, ChartType} from 'chart.js';
import {formatCurrency} from '@angular/common';
import {Label} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import { TipoDeReporte } from 'src/app/shared/enums/tipo-de-reporte.enum';
import { ReportesService } from 'src/app/core/servicios/reportes.service';

@Component({
  selector: 'app-licence-sellers-report',
  templateUrl: './licence-sellers-report.component.html',
  styleUrls: []
})
export class LicenceSellersReportComponent implements OnInit {
  /* ------------------------------- START OF PIE CHART VARIABLES ------------------------------- */
  colors = [
    { // green
      backgroundColor: 'rgba(7,111,0,0.3)',
      borderColor: 'rgba(7,111,07,1)'
    },
    { // red
      backgroundColor: 'rgba(185,0,32,0.3)',
      borderColor: 'rgba(185,0,32,1)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.3)',
      borderColor: 'rgba(77,83,96,1)'
    },
    { // green
      backgroundColor: 'rgba(127,208,67,0.3)',
      borderColor: 'rgba(127,208,67)'
    },
    { // red
      backgroundColor: 'rgba(255,89,114,0.3)',
      borderColor: 'rgba(255,89,114,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.3)',
      borderColor: 'rgba(148,159,177,1)'
    }
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          return formatCurrency(parseFloat(value.toString()),
            'en',
            '$',
            'MXN',
            '.2-2');
        },
      },
    },
    tooltips: {
      callbacks: {
        label(tooltipItems, data) {
          /*const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;*/
          return data.labels[tooltipItems.index] + ': '
            + formatCurrency(parseFloat(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toString()),
              'en',
              '$',
              'MXN',
              '.2-2');
        }
      }
    }
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [],
      borderColor: []
    }
  ];
  totalDeVentas: number;
  form: FormGroup;
  requestState: number;
  reportSubscription: Subscription;
  dias: number[];
  TipoDeReporte = TipoDeReporte;

  constructor(private _reportesService: ReportesService) {
    this.requestState = RequestState.initial;
    this.totalDeVentas = 0;
    this.form = new FormGroup(
      {
        reporte: new FormControl(TipoDeReporte.Anual, [Validators.required]),
        anio: new FormControl(2020, [Validators.required]),
        mes: new FormControl(1, [Validators.required]),
        dia: new FormControl(1, [Validators.required])
      });
  }

  ngOnInit() {
    this.generarReporte();
  }

  calcularDias() {
    if (this.form.get('reporte').value == TipoDeReporte.Diario) {
      this.dias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
      const maximoNumeroDeDias = new Date(this.form.get('anio').value, this.form.get('mes').value, 0).getDate();
      for (let i = 29; i <= maximoNumeroDeDias; i++) {
        this.dias.push(i);
      }
      this.form.get('dia').setValue(1);
    }
  }

  generarReporte() {
    this.requestState = RequestState.loading;
    const reporte: number = this.form.get('reporte').value;
    const anio: number = this.form.get('anio').value;
    const mes: number = this.form.get('mes').value;
    const dia: number = this.form.get('dia').value;
    this.reportSubscription = this._reportesService.obtenerVentasLicenciasPorVendedor(reporte, anio, mes, dia).subscribe(
      (sales: any[]) => {
        
        this.inicializarGrafico();
        let posicionColor = 0;
        this.totalDeVentas = 0;
        sales.forEach((sale) => {
          // Pie chart generation
          this.pieChartColors[0].backgroundColor.push(this.colors[posicionColor].backgroundColor);
          this.pieChartColors[0].borderColor.push(this.colors[posicionColor].borderColor);
          posicionColor++;
          if (posicionColor === this.colors.length) {
            posicionColor = 0;
          }
          this.pieChartLabels.push(sale.vendedor.nombres + ' ' + sale.vendedor.primerApellido);
          this.pieChartData.push(sale.total);
          this.totalDeVentas += sale.total;
        });
        this.requestState = RequestState.success;
      }, error => {
        this.requestState = RequestState.error;
      }
    );
  }

  inicializarGrafico() {
    // Pie chart initialization
    this.pieChartLabels = [];
    this.pieChartData = [];
    this.pieChartColors = [
      {
        backgroundColor: [],
        borderColor: []
      }
    ];
  }

}
