import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { TipoDeGastoGeneral } from 'src/app/shared/class-models/tipo-de-gasto-general.model';

@Injectable({
    providedIn: 'root'
})
export class TiposDeGastosGeneralesService {
    private rutaBaseTiposDeGastosGenerales = '/tipos-de-gastos-generales';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerTiposDeGastosGenerales(): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseTiposDeGastosGenerales}`, AppSettings.Options);
    }

    obtenerTipoDeGastoGeneral(_idTipoDeGastoGeneral: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseTiposDeGastosGenerales}/${_idTipoDeGastoGeneral}`, AppSettings.Options);
    }

    /* POST */
    agregarTipoDeGastoGeneral(tipoDeGasto: TipoDeGastoGeneral): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseTiposDeGastosGenerales}`, JSON.stringify(tipoDeGasto), AppSettings.Options);
    }

    /* PUT */
    actualizarTipoDeGastoGeneral(_idTipoDeGastoGeneral: string, tipoDeGasto: TipoDeGastoGeneral): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseTiposDeGastosGenerales}/${_idTipoDeGastoGeneral}`, JSON.stringify(tipoDeGasto), AppSettings.Options);
    }

    /* PATCH */
    eliminarTipoDeGastoGeneral(_idTipoDeGastoGeneral: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseTiposDeGastosGenerales}/${_idTipoDeGastoGeneral}`, AppSettings.Options);
    }
}