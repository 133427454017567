import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {MenuComponent} from './menu.component';
import {MenuProductComponent} from './components/menu-products/menu-product.component';
import {MenuPromoComponent} from './components/menu-promos/menu-promo.component';
// Pages.
import {CommonModule} from '@angular/common';
import {ProductSizeComponent} from './dialogs/product-size/product-size.component';
import {PromoSizeComponent} from './dialogs/promo-size/promo-size.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {DeleteOrderComponent} from './dialogs/delete-order/delete-order.component';
import {ProductsModule} from '../../../../../products/products.module';
import {PromosModule} from '../../../../../promos/promos.module';
import { TarjetaOrdenProductoComponent } from './tarjeta-orden-producto/tarjeta-orden-producto.component';
import { TarjetaOrdenPaqueteComponent } from './tarjeta-orden-paquete/tarjeta-orden-paquete.component';
import { NuevaOrdenProductoModalComponent } from './modales/nueva-orden-producto-modal/nueva-orden-producto-modal.component';
import { NuevaOrdenPaqueteModalComponent } from './modales/nueva-orden-paquete-modal/nueva-orden-paquete-modal.component';
import { AgregarPedidoModalComponent } from './modales/agregar-pedido-modal/agregar-pedido-modal.component';
import { EliminarOrdenModalComponent } from './modales/eliminar-orden-modal/eliminar-orden-modal.component';

// Components.

@NgModule({
  declarations: [
    MenuComponent,
    MenuProductComponent,
    MenuPromoComponent,
    ProductSizeComponent,
    PromoSizeComponent,
    DeleteOrderComponent,
    TarjetaOrdenProductoComponent,
    TarjetaOrdenPaqueteComponent,
    NuevaOrdenProductoModalComponent,
    NuevaOrdenPaqueteModalComponent,
    AgregarPedidoModalComponent,
    EliminarOrdenModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatChipsModule,
    MatStepperModule,
    MatCheckboxModule,
    ScrollingModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatBadgeModule,
    MatGridListModule,
    MatRadioModule,
    MatExpansionModule,
    SweetAlert2Module,
    ProductsModule,
    PromosModule,
    ProductsModule
  ],
  exports: [
    MenuComponent
  ]
})
export class MenuModule {
}
