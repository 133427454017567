import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { GastoDeInsumo } from 'src/app/shared/class-models/gasto-de-insumo.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GastosDeInsumosService } from 'src/app/core/servicios/gastos-de-insumos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';
import { HttpErrorResponse } from '@angular/common/http';
import { Proveedor } from 'src/app/shared/class-models/proveedor.model';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { Insumo } from 'src/app/shared/class-models/insumo.model';
import { TipoDeInsumo } from 'src/app/shared/enums/tipo-de-insumo.enum';
import { FormaDePago } from 'src/app/shared/enums/forma-de-pago.enum';
import { AltaGastoDeInsumoComponent } from './alta-gasto-de-insumo/alta-gasto-de-insumo.component';
import { ActualizacionGastoDeInsumoComponent } from './actualizacion-gasto-de-insumo/actualizacion-gasto-de-insumo.component';

@Component({
  selector: 'app-gastos-de-insumos',
  templateUrl: './gastos-de-insumos.component.html',
  styleUrls: ['./gastos-de-insumos.component.scss']
})
export class GastosDeInsumosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<GastoDeInsumo>;
  filtro: string = '';
  estadoPeticionObtenerGastosDeInsumos: number;
  RequestState = RequestState;
  obtenerGastosDeInsumosSubscripcion: Subscription;
  eliminarGastoDeInsumoSubscripcion: Subscription;
  columnas = ['fechaRegistro', 'insumo', 'proveedor', 'formaDePago', 'total', 'opciones'];
  _idRestaurante: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _gastosDeInsumosService: GastosDeInsumosService,
    private _alertasService: AlertasService,
    private fechaPipe: FechaPipe,
    public dialog: MatDialog) {
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this.estadoPeticionObtenerGastosDeInsumos = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerGastosGeneral();
  }

  obtenerGastosGeneral(): void {
    this.estadoPeticionObtenerGastosDeInsumos = RequestState.loading;
    this.obtenerGastosDeInsumosSubscripcion = this._gastosDeInsumosService.obtenerGastosDeInsumos(this._idRestaurante).subscribe(
      (gastosDeInsumos: GastoDeInsumo[]) => {
        this.inicializarGastosDeInsumos(gastosDeInsumos);
        this.estadoPeticionObtenerGastosDeInsumos = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerGastosDeInsumos = RequestState.error;
      }
    );
  }

  inicializarGastosDeInsumos(gastosDeInsumos: GastoDeInsumo[]): void {
    this.dataSource = new MatTableDataSource<GastoDeInsumo>(gastosDeInsumos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (gastoDeInsumo: GastoDeInsumo, filtro: string) => {
      return this.obtenerNombreDeInsumo(gastoDeInsumo.insumos[0].insumo).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerRazonSocialProveedor(gastoDeInsumo.proveedor).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerFormaDePago(gastoDeInsumo.formaDePago).trim().toLowerCase().indexOf(filtro) !== -1 ||
        ('$' + Number(gastoDeInsumo.total).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.fechaPipe.transform(gastoDeInsumo.fechaRegistro).trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarGastoDeInsumo(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerNombreDeInsumo(insumo: Insumo): string {
    switch(insumo.tipo) {
      case TipoDeInsumo.Ingrediente: return insumo.ingrediente.nombre;
      case TipoDeInsumo.Producto: return insumo.producto.nombre;
      case TipoDeInsumo.Otro: return insumo.nombre;
    }
  }

  obtenerRazonSocialProveedor(proveedor: Proveedor): string {
    switch (proveedor.tipoDePersona) {
      case TipoDePersona.Moral: return proveedor.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return proveedor.nombres + ' ' + proveedor.primerApellido + (proveedor.segundoApellido ? (' ' + proveedor.segundoApellido) : '');
    }
  }

  obtenerFormaDePago(tipoFormaDePago: string): string {
    switch (tipoFormaDePago) {
      case FormaDePago.Efectivo: return 'Efectivo';
      case FormaDePago.ChequeNominativo: return 'Cheque nominativo';
      case FormaDePago.TransferenciaElectronicaDeFondos: return 'Transferencia electrónica de fondos';
      case FormaDePago.TarjetaDeCredito: return 'Tarjeta de crédito';
      case FormaDePago.MonederoElectronico: return 'Monedero electrónico';
      case FormaDePago.DineroElectronico: return 'Dinero electróico';
      case FormaDePago.ValesDeDespensa: return 'Vales de despensa';
      case FormaDePago.DacionDePago: return 'Dacion de pago';
      case FormaDePago.PagoPorSubrogacion: return 'Pago por subrogacion';
      case FormaDePago.PagoPorConsignacion: return 'Pago por consignacion';
      case FormaDePago.Condonacion: return 'Condonacion';
      case FormaDePago.Compensacion: return 'Compensacion';
      case FormaDePago.Novacion: return 'Novacion';
      case FormaDePago.Confusion: return 'Confusion';
      case FormaDePago.RemisionDeDeuda: return 'Remision de deuda';
      case FormaDePago.PrescripcionOCaducidad: return 'Prescripcion o caducidad';
      case FormaDePago.ASatisfaccionDelAcreedor: return 'A satisfaccion del acreedor';
      case FormaDePago.TarjetaDeDebito: return 'Tarjeta de debito';
      case FormaDePago.TarjetaDeServicios: return 'Tarjeta de servicios';
      case FormaDePago.AplicacionDeAnticipos: return 'Aplicacion de anticipos';
      case FormaDePago.PorDefinir: return 'Por definir';
    }
  }

  agregarGastoDeInsumo(): void {
    const dialogRef = this.dialog.open(AltaGastoDeInsumoComponent, { disableClose: true, data: this._idRestaurante });
    dialogRef.afterClosed().subscribe(
      (gastoDeInsumo: GastoDeInsumo) => {
        if (gastoDeInsumo) {
          this._alertasService.alertaExitoSinConfirmacion('Gasto de insumo agregado exitosamente', 'El gasto de insumo de ' + this.obtenerRazonSocialProveedor(gastoDeInsumo.proveedor) + ' ha sido agregado con éxito');
          this.dataSource.data.push(gastoDeInsumo);
          const gastosDeInsumos: GastoDeInsumo[] = this.dataSource.data;
          this.inicializarGastosDeInsumos(gastosDeInsumos);
          this.buscarGastoDeInsumo();
        }
      }
    );
  }

  actualizarGastoDeInsumo(gastoDeInsumo: GastoDeInsumo): void {
    const dialogRef = this.dialog.open(ActualizacionGastoDeInsumoComponent, { disableClose: true, data: { _idRestaurante: this._idRestaurante, gastoDeInsumo: gastoDeInsumo } });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Gasto de insumo actualizado exitosamente', 'El gasto de insumo de ' + this.obtenerRazonSocialProveedor(gastoDeInsumo.proveedor) + ' ha sido actualizado con éxito.');
          this.buscarGastoDeInsumo();
        }
      }
    );
  }

  eliminarGastoDeInsumo(gastoDeInsumoEliminar: GastoDeInsumo) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el gasto de insumo de ' + this.obtenerRazonSocialProveedor(gastoDeInsumoEliminar.proveedor) + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarGastoDeInsumoSubscripcion = this._gastosDeInsumosService.eliminarGastoDeInsumo(this._idRestaurante, gastoDeInsumoEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionGasto = this.dataSource.data.findIndex((gastoDeInsumo: GastoDeInsumo) => {
                return gastoDeInsumo._id === gastoDeInsumoEliminar._id;
              });
              let gastosDeInsumosActualizados: GastoDeInsumo[] = this.dataSource.data;
              if (posicionGasto != -1) {
                gastosDeInsumosActualizados.splice(posicionGasto, 1);
              }
              this.inicializarGastosDeInsumos(gastosDeInsumosActualizados);
              this.buscarGastoDeInsumo();
              this._alertasService.alertaExitoSinConfirmacion('Gasto de insumo eliminado exitosamente', 'El gasto de insumo de ' + this.obtenerRazonSocialProveedor(gastoDeInsumoEliminar.proveedor) + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerGastosDeInsumosSubscripcion) this.obtenerGastosDeInsumosSubscripcion.unsubscribe();
    if (this.eliminarGastoDeInsumoSubscripcion) this.eliminarGastoDeInsumoSubscripcion.unsubscribe();
  }
}
