import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { ExistenciaPorTamano } from 'src/app/shared/class-models/existencia-por-tamano.model';

@Injectable({
    providedIn: 'root'
})
export class ExistenciasPorTamanoService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseExistenciasDeProductos = '/existencias-de-productos';
    private rutaBaseExistencias = '/existencias';
    private rutaBaseExistenciasDeProductosPorTamano = '/existencias-de-producto-por-tamano';
    private rutaBaseExistenciasPorTamano = '/existencias-por-tamano';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerExistenciasDeProductoPorTamano(_idRestaurante: string, _idProducto: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistenciasDeProductos}/${_idProducto}${this.rutaBaseExistenciasDeProductosPorTamano}`, AppSettings.Options);
    }

    obtenerExistenciaDeProductoPorTamano(_idRestaurante: string, _idProducto: string, _idTamano: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistenciasDeProductos}/${_idProducto}${this.rutaBaseExistenciasDeProductosPorTamano}/${_idTamano}`, AppSettings.Options);
    }

    /* POST */
    agregarExistenciaPorTamano(_idRestaurante: string, _idExistencia: string, existenciaPorTamano: ExistenciaPorTamano, descripcion: string): Observable<any> {
        const body: any = {
            existenciaPorTamano: existenciaPorTamano,
            descripcion: descripcion
        }
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistencias}/${_idExistencia}${this.rutaBaseExistenciasPorTamano}`, JSON.stringify(body), AppSettings.Options);
    }

    /* PUT */

    /* PATCH */
    actualizarEstadoDeExistenciaPorTamano(_idRestaurante: string, _idExistencia: string, _idExistenciaPorTamano: string, cantidad: number, descripcion: string): Observable<any> {
        const body: any = {
            existenciaPorTamano: {cantidad: cantidad},
            descripcion: descripcion
        }
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseExistencias}/${_idExistencia}${this.rutaBaseExistenciasPorTamano}/${_idExistenciaPorTamano}/cantidad`, JSON.stringify(body), AppSettings.Options);
    }
}