import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-edit-ingredient',
  templateUrl: './edit-ingredient.component.html'
})
export class EditIngredientComponent implements OnInit, OnDestroy {
  titulo = 'Actualizar ingrediente';
  RequestState = RequestState;
  ingrediente: Ingrediente;

  /* VARIABLES PARA OBTENER EL INGREDIENTE */
  obtenerIngredienteSubscription: Subscription;
  estadoPeticionObtenerIngrediente: number;
  AppSettings = AppSettings;
  fotoActualIngrediente: string;

  /* VARIABLES PARA ACTUALIZAR EL INGREDIENTE */
  ExpresionesRegulares = ExpresionesRegulares;
  actualizarIngredienteSubscription: Subscription;
  estadoPeticionActualizarIngrediente: number;
  eventoCambioImagenFotografia: any = '';

  constructor(
    private _ingredientesService: IngredientesService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Ingrediente,
    public modal: MatDialogRef<EditIngredientComponent>
    ) {
    this.estadoPeticionObtenerIngrediente = RequestState.initial;
    this.estadoPeticionActualizarIngrediente = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerIngrediente();
  }

  obtenerIngrediente(): void {
    this.estadoPeticionObtenerIngrediente = RequestState.loading;
    this.obtenerIngredienteSubscription = this._ingredientesService.obtenerIngrediente(this.data._id).subscribe(
      (ingrediente: Ingrediente) => {
        this.fotoActualIngrediente = ingrediente.foto;
        ingrediente.foto = '';
        if (ingrediente.esExtra == true) ingrediente.precio = parseFloat(ingrediente.precio.toString()).toFixed(2);
        else ingrediente.precio = '0.00';
        this.ingrediente = ingrediente;
        this.estadoPeticionObtenerIngrediente = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngrediente = RequestState.error;
        this.modal.close();
      }
    );
  }

  transformarPrecio(precio: string): void {
    if (precio != null && precio != undefined && !isNaN(Number(precio))) {
      this.ingrediente.precio = Number(precio).toFixed(2);
    } else {
      this.ingrediente.precio = Number('0').toFixed(2);
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(evento: ImageCroppedEvent): void {
    this.ingrediente.foto = evento.base64;
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
    //this.imagenFotoRecortada = '';
    //this.eventoCambioImagenFotografia = '';
  //}

  actualizarIngrediente() {
    this.estadoPeticionActualizarIngrediente = RequestState.loading;
    this.actualizarIngredienteSubscription = this._ingredientesService.actualizarIngrediente(this.data._id, this.prepararDatosIngrediente()).subscribe(
      (ingrediente: Ingrediente) => {
        this.estadoPeticionActualizarIngrediente = RequestState.success;
        this.actualizarIngredienteOriginal(ingrediente)
        this.modal.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarIngrediente = RequestState.error;
      }
    );
  }

  prepararDatosIngrediente(): Ingrediente {
    let ingrediente = new Ingrediente;
    ingrediente.nombre = this.ingrediente.nombre;
    ingrediente.descripcion = this.ingrediente.descripcion;
    ingrediente.esExtra = this.ingrediente.esExtra;
    if (ingrediente.esExtra) {
      ingrediente.precio = parseFloat(this.ingrediente.precio.toString());
    } else {
      ingrediente.precio = null
    }
    ingrediente.foto = this.ingrediente.foto;
    return ingrediente;
  }
  
  actualizarIngredienteOriginal(ingrediente: Ingrediente): void {
    this.data.nombre = ingrediente.nombre;
    this.data.descripcion = ingrediente.descripcion;
    this.data.esExtra = ingrediente.esExtra;
    this.data.precio = ingrediente.precio;
    this.data.foto = ingrediente.foto;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerIngredienteSubscription) this.obtenerIngredienteSubscription.unsubscribe();
    if(this.actualizarIngredienteSubscription) this.actualizarIngredienteSubscription.unsubscribe();
  }
}
