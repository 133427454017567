import { Component, Inject, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { ClientesService } from 'src/app/core/servicios/clientes.service';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { Cliente } from 'src/app/shared/class-models/cliente.model';
import { RFC_GENERICO } from 'src/app/shared/constants/rfc-generico.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-edit',
  templateUrl: './edit-client.component.html'
})
export class EditClientComponent implements OnInit, OnDestroy, AfterViewChecked {
  titulo: string = 'Actualizar cliente';
  @ViewChild('direccionForm') direccionForm: FormGroup;
  @ViewChild('stepper') stepper: MatStepper;
  stepActual: number = 0;
  TipoDePersona = TipoDePersona;
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  cliente: Cliente;
  estadoPeticionObtenerCliente: number;
  obtenerClienteSubscripcion: Subscription;
  estadoPeticionActualizarCliente: number;
  actualizarClienteSubscripcion: Subscription;
  ciudadesSubscripcion: Subscription;
  estadosSubscripcion: Subscription;
  ciudades: Ciudad[];
  estados: Estado[];
  codigoPostal: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: Cliente,
    private modal: MatDialogRef<EditClientComponent>,
    private _ubicacionService: UbicacionService,
    private _alertasService: AlertasService,
    private _clientesService: ClientesService,
    private changeDetectorRef: ChangeDetectorRef) {
      this.estadoPeticionObtenerCliente = RequestState.initial;
      this.estadoPeticionActualizarCliente = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCliente();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  obtenerCliente(): void {
    this.estadoPeticionObtenerCliente = RequestState.loading;
    this.obtenerClienteSubscripcion = this._clientesService.obtenerCliente(this.data._id).subscribe(
      (cliente: Cliente) => {
        this.cliente = cliente;
        this.codigoPostal = cliente.codigoPostal.codigo;
        this.obtenerEstados();
        this.obtenerCiudades(this.cliente.idEstado);
        this.estadoPeticionObtenerCliente = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCliente = RequestState.error;
        this.modal.close();
      }
    );
  }

  obtenerEstados(): void {
    this.estados = [];
    this.estadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerCiudades(idEstado: number): void {
    this.ciudades = [];
    this.ciudadesSubscripcion = this._ubicacionService.obtenerCiudades(idEstado).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerTipoDePersona(tipo: number): string {
    switch(tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  actualizacionTipoDePersona(): void {
    switch(this.cliente.tipoDePersona) {
      case TipoDePersona.Moral:
      case TipoDePersona.Fisica: 
        if (this.cliente.rfc == RFC_GENERICO) this.cliente.rfc = '';
        break;
      case TipoDePersona.Ninguna: 
        this.cliente.rfc = RFC_GENERICO;
        break;
    }
  }

  convertirRfcMayusculas(): void {
    if (this.cliente.rfc) {
      this.cliente.rfc = this.cliente.rfc.toUpperCase();
    }
  }

  async actualizarCliente() {
    this.estadoPeticionActualizarCliente = RequestState.loading;
    const codigoPostal = await this.verificarCodigoPostal(this.cliente.idEstado, this.codigoPostal);
    if (!codigoPostal) {
      this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
      this.estadoPeticionActualizarCliente = RequestState.initial;
      this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
    } else {
      this.cliente.idCodigoPostal = codigoPostal.id;
      this.actualizarClienteSubscripcion = this._clientesService.actualizarCliente(this.data._id, this.prepararDatosCliente()).subscribe(
        (cliente: Cliente) => {
          this.estadoPeticionActualizarCliente = RequestState.success;
          this.actualizarClienteOriginal(cliente);
          this.modal.close(true);
        },
        (error: HttpErrorResponse) => {
          this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          this.estadoPeticionActualizarCliente = RequestState.error;
        }
      );
    }        
  }

  async validarCodigoPostal() {
    if (this.codigoPostal.length >= 5) {
      const codigoPostal = await this.verificarCodigoPostal(this.cliente.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      }
    }

  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  prepararDatosCliente(): Cliente {
    let cliente = new Cliente();
    cliente.tipoDePersona = this.cliente.tipoDePersona;
    cliente.rfc = this.cliente.rfc;
    switch(cliente.tipoDePersona) {
      case TipoDePersona.Moral:
        cliente.razonSocial = this.cliente.razonSocial;
        cliente.tieneRepresentante = this.cliente.tieneRepresentante;
        if (cliente.tieneRepresentante) {
          cliente.nombresRepresentante = this.cliente.nombresRepresentante;
          cliente.primerApellidoRepresentante = this.cliente.primerApellidoRepresentante;
          cliente.segundoApellidoRepresentante = this.cliente.segundoApellidoRepresentante;
        }
        break;
      case TipoDePersona.Fisica: 
      case TipoDePersona.Ninguna:
        cliente.nombres = this.cliente.nombres;
        cliente.primerApellido = this.cliente.primerApellido;
        cliente.segundoApellido = this.cliente.segundoApellido;
        cliente.tieneRepresentante = false;
        break;
    }
    cliente.idEstado = this.cliente.idEstado;
    cliente.idCiudad = this.cliente.idCiudad;
    cliente.calle = this.cliente.calle;
    cliente.colonia = this.cliente.colonia;
    cliente.numeroExterior = this.cliente.numeroExterior;
    cliente.numeroInterior = this.cliente.numeroInterior;
    cliente.idCodigoPostal = this.cliente.idCodigoPostal;
    cliente.telefono = this.cliente.telefono;
    cliente.correo = this.cliente.correo;
    return cliente;
  }

  actualizarClienteOriginal(cliente: Cliente): void {
    this.data.tipoDePersona = cliente.tipoDePersona;
    this.data.rfc = cliente.rfc;
    this.data.nombres = cliente.nombres;
    this.data.primerApellido = cliente.primerApellido;
    this.data.segundoApellido = cliente.segundoApellido;
    this.data.razonSocial = cliente.razonSocial;
    this.data.tieneRepresentante = cliente.tieneRepresentante;
    this.data.nombresRepresentante  = cliente.nombresRepresentante ;
    this.data.primerApellidoRepresentante  = cliente.primerApellidoRepresentante ;
    this.data.segundoApellidoRepresentante  = cliente.segundoApellidoRepresentante ;
    this.data.estado = cliente.estado;
    this.data.idEstado = cliente.idEstado;
    this.data.ciudad = cliente.ciudad;
    this.data.idCiudad = cliente.idCiudad;
    this.data.calle = cliente.calle;
    this.data.colonia = cliente.colonia;
    this.data.numeroExterior = cliente.numeroExterior;
    this.data.numeroInterior = cliente.numeroInterior;
    this.data.idCodigoPostal = cliente.idCodigoPostal;
    this.data.codigoPostal = cliente.codigoPostal;
    this.data.telefono = cliente.telefono;
    this.data.correo = cliente.correo;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  retroceder() {
    this.stepActual--;
    this.stepper.previous();
  }

  avanzar() {
    this.stepActual++;
    this.stepper.next();
  }

  ngOnDestroy(): void {
    if (this.obtenerClienteSubscripcion) this.obtenerClienteSubscripcion.unsubscribe();
    if (this.ciudadesSubscripcion) this.ciudadesSubscripcion.unsubscribe();
    if (this.estadosSubscripcion) this.estadosSubscripcion.unsubscribe();
    if (this.actualizarClienteSubscripcion) this.actualizarClienteSubscripcion.unsubscribe();
  }
}
