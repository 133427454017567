import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { MatPaginator, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProductoPorTamano, Producto } from 'src/app/shared/class-models/producto.model';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';

@Component({
  selector: 'app-vista-producto-modal',
  templateUrl: './vista-producto-modal.component.html',
  styleUrls: ['./vista-producto-modal.component.scss']
})
export class VistaProductoModalComponent implements OnInit {
  RequestState = RequestState;
  estadoPeticionObtenerProducto: number;
  obtenerProductoSubscription: Subscription;
  producto: Producto;

  /* PRECIOS POR TAMAÑO */
  @ViewChild('paginatorPreciosPorTamano', {read: MatPaginator, static: false}) paginatorPreciosPorTamano: MatPaginator;
  preciosPorTamano: MatTableDataSource<ProductoPorTamano>;
  columnasPreciosPorTamano: string[] = ['tamano', 'precio'];

  /* INGREDIENTES */
  @ViewChild('paginatorIngredientes', {read: MatPaginator, static: false}) paginatorIngredientes: MatPaginator;
  ingredientes: MatTableDataSource<Ingrediente>;
  columnasIngredientes: string[] = ['nombre'];

  constructor(
    private _productosService: ProductosService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Producto,
    public referenciaModal: MatDialogRef<VistaProductoModalComponent>,
  ) {
    this.estadoPeticionObtenerProducto = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerProducto();
  }

  obtenerProducto() {
    this.estadoPeticionObtenerProducto = RequestState.loading;
    this.obtenerProductoSubscription = this._productosService.obtenerProducto(this.data._id).subscribe(
      (producto: Producto) => {
        this.producto = producto;
        this.inicializarPreciosPorTamanoProducto();
        this.inicializarIngredientesProducto();
        this.estadoPeticionObtenerProducto = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerProducto = RequestState.error;
        this.cerrar();
      }
    );
  }

  inicializarPreciosPorTamanoProducto(): void {
    this.preciosPorTamano = new MatTableDataSource<ProductoPorTamano>(this.producto.productoPorTamano);
    this.preciosPorTamano.paginator = this.paginatorPreciosPorTamano;
  }

  inicializarIngredientesProducto(): void {
    this.ingredientes = new MatTableDataSource<Ingrediente>(<Ingrediente[]>this.producto.ingredientes);
    this.ingredientes.paginator = this.paginatorIngredientes;
  }

  cerrar() {
    this.referenciaModal.close()
  }

  ngOnDestroy(): void {
    if (this.obtenerProductoSubscription) this.obtenerProductoSubscription.unsubscribe();
    if (this.ingredientes) this.ingredientes.disconnect();
    if (this.preciosPorTamano) this.preciosPorTamano.disconnect();
  }
}
