import { Component, OnDestroy, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ExistenciasService } from 'src/app/core/servicios/existencias.service';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription, Observable, Subject } from 'rxjs';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Categoria } from 'src/app/shared/class-models/categoria.model';
import { CategoriasService } from 'src/app/core/servicios/categorias.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { Paquete } from 'src/app/shared/class-models/paquete.model';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { Orden, AgregarIngrediente, AlternativaProductoPorTamano } from 'src/app/shared/class-models/orden.model';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { GruposDeOrdenesService } from 'src/app/core/servicios/grupos-de-ordenes.service';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { ControlInternoExistenciaDeProductoPorTamano } from 'src/app/shared/class-models/control-interno-existencia-de-producto-por-tamano.enum';
import { AgregarPedidoModalComponent } from './modales/agregar-pedido-modal/agregar-pedido-modal.component';
import { takeUntil } from 'rxjs/operators';
import { ExistenciaPorTamano } from 'src/app/shared/class-models/existencia-por-tamano.model';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { TipoImpresion } from 'src/app/shared/enums/tipo-impresion.enum';
import { ImpresionService } from 'src/app/core/servicios/impresion.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  @Input() estadoPeticiones: number;
  @Input() _idRestaurante: string;
  @Input() _idArea: string;
  @Input() _idElementoPorArea: string;
  @Input() _idComanda: string;
  @Output() peticionesMenuFinalizadas = new EventEmitter<boolean>();
  @Output() obtenerTotalSinPagarDeOrdenesComanda = new EventEmitter<boolean>();
  RequestState = RequestState;
  AppSettings = AppSettings;
  contadorPeticionesRequeridas: number;
  contadorPeticionesFinalizadas: number;
  TipoOrden = TipoOrden;

  /* VARIABLES PARA OBTENER CATEGORÍAS */
  categorias: Categoria[];
  estadoPeticionObtenerCategorias: number;
  obtenerCategoriasSubscripcion: Subscription;
  filtro_IdCategoria: string = '';

  /* VARIABLES PARA OBTENER PRODUCTOS CON EXISTENCIAS */
  @ViewChild('paginadorProductos') paginadorProductos: MatPaginator;
  productos: MatTableDataSource<Producto>;
  productosObservable: Observable<any>;
  estadoPeticionObtenerExistenciasDeProductos: number;
  obtenerExistenciasDeProductosSubscripcion: Subscription;
  filtroBusquedaProducto: string = '';

  /* VARIABLES PARA OBTENER PAQUETES VIGENTES */
  @ViewChild('paginadorPaquetes') paginadorPaquetes: MatPaginator;
  paquetes: MatTableDataSource<Paquete>;
  paquetesObservable: Observable<any>;
  estadoPeticionObtenerPaquetesVigentes: number;
  obtenerPaquetesVigentesSubscripcion: Subscription;
  filtroBusquedaPaquete: string = '';

  /* VARIABLES PARA EL CONTROL DE AGREGAR ORDENES */
  ordenes: Orden[] = [];
  controlInternoExistenciasDeProductosPorTamano: ControlInternoExistenciaDeProductoPorTamano[] = [];

  /* VARIABLES CON SOCKETS PARA ACTUALIZAR LOS CAMBIOS EN LAS EXISTENCIAS  */
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private _categoriasService: CategoriasService,
    private _existenciasService: ExistenciasService,
    private _paquetesService: PaquetesService,
    private _ordenesService: OrdenesService,
    private _impresionService: ImpresionService,
    private _grupoDeOrdenesService: GruposDeOrdenesService,
    private _alertasService: AlertasService,
    public modal: MatDialog
  ) {
    this.contadorPeticionesFinalizadas = 0;
    this.contadorPeticionesRequeridas = 3;
    this.estadoPeticionObtenerCategorias = RequestState.initial;
    this.estadoPeticionObtenerExistenciasDeProductos = RequestState.initial;
    this.estadoPeticionObtenerPaquetesVigentes = RequestState.initial;
  }

  ngOnInit(): void {
    this.disminucionExistenciasOrdenes()
    this.obtenerCategorias();
    this.obtenerExistenciasDeProductos();
    this.obtenerPaquetesVigentes();
  }

  /* MÉTODO CON SOCKETS PARA ACTUALIZAR LOS CAMBIOS EN LAS EXISTENCIAS */
  disminucionExistenciasOrdenes() {
    this._ordenesService.disminucionExistenciasOrdenes()
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (existenciaPorTamano: ExistenciaPorTamano) => {
          this.disminuirExistenciasProducto(existenciaPorTamano._idProducto, 1);
        }
      );
  }

  disminuirExistenciasProducto(_idProducto: string, cantidad: number): void {
    const posicionProducto = this.productos.data.findIndex(producto => {
      return producto._id == _idProducto;
    })
    if (
      posicionProducto != -1 &&
      this.administracionDeExistenciasHabilitado(this.productos.data[posicionProducto])
    ) {
      this.productos.data[posicionProducto].totalDeExistencias -= cantidad;
      /*if (this.administracionDeExistenciasHabilitado(this.productos.data[posicionProducto])) {
        if (this.productos.data[posicionProducto].totalDeExistencias - this.obtenerExistenciasUtilizadas(this.productos.data[posicionProducto]) > 0) {
          this.productos.data[posicionProducto].totalDeExistencias--;
        }
      }*/
    }
  }

  administracionDeExistenciasHabilitado(producto: Producto): boolean {
    if (producto.existencia != null && producto.existencia.activo == true) {
      return true;
    } else {
      return false;
    }
  }

  obtenerExistenciasUtilizadas(producto: Producto): number {
    return this.controlInternoExistenciasDeProductosPorTamano
      .filter(controlInternoExistenciaDeProductoPorTamano => controlInternoExistenciaDeProductoPorTamano._idProducto == producto._id)
      .map(controlInternoExistenciaDeProductoPorTamano => controlInternoExistenciaDeProductoPorTamano.cantidad)
      .reduce((acomulador, cantidad) => { return acomulador + cantidad }, 0);
  }


  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
    if (this.contadorPeticionesFinalizadas == this.contadorPeticionesRequeridas) this.peticionesMenuFinalizadas.emit(true);
  }

  /* MÉTODOS PARA OBTENER CATEGORÍAS */
  obtenerCategorias() {
    this.categorias = [];
    this.estadoPeticionObtenerCategorias = RequestState.loading;
    this.obtenerCategoriasSubscripcion = this._categoriasService.obtenerCategorias().subscribe(
      (categorias: Categoria[]) => {
        this.categorias = categorias;
        this.estadoPeticionObtenerCategorias = RequestState.loading;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCategorias = RequestState.loading;
        this.incrementarContadorPeticionesFinalizadas();
      }
    );
  }

  /* MÉTODOS PARA OBTENER PRODUCTOS CON EXISTENCIAS */
  obtenerExistenciasDeProductos(): void {
    this.estadoPeticionObtenerExistenciasDeProductos = RequestState.loading;
    this.obtenerExistenciasDeProductosSubscripcion = this._existenciasService.obtenerExistenciasDeProductos(this._idRestaurante).subscribe(
      (productos: Producto[]) => {
        this.inicializarProductos(productos);
        this.estadoPeticionObtenerExistenciasDeProductos = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerExistenciasDeProductos = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas();
      }
    );
  }

  inicializarProductos(productos: Producto[]): void {
    this.productos = new MatTableDataSource<Producto>(productos);
    this.productos.paginator = this.paginadorProductos;
    this.productos.filterPredicate = (producto: Producto, filtro: string) => {
      return producto.nombre.trim().toLowerCase().indexOf(this.filtroBusquedaProducto.trim().toLowerCase()) !== -1 &&
        (this.filtro_IdCategoria == '' || producto._idCategoria == this.filtro_IdCategoria);
    }
    this.productosObservable = this.productos.connect();
  }

  buscarProducto(): void {
    //Esta verificacion se hace con el fin de identificar que alguno de los dos filtros tenga valor para que se pueda llevar a cabo la búsqueda
    let filtro = this.filtroBusquedaProducto != '' ? this.filtroBusquedaProducto : this.filtro_IdCategoria;
    this.productos.filter = filtro.trim().toLowerCase();
  }

  /* MÉTODOS PARA OBTENER PAQUETES VIGENTES */
  obtenerPaquetesVigentes(): void {
    this.estadoPeticionObtenerPaquetesVigentes = RequestState.loading;
    this.obtenerPaquetesVigentesSubscripcion = this._paquetesService.obtenerPaquetesVigentes().subscribe(
      (paquetes: Paquete[]) => {
        this.inicializarPaquetes(paquetes);
        this.estadoPeticionObtenerExistenciasDeProductos = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerExistenciasDeProductos = RequestState.error;
        this.incrementarContadorPeticionesFinalizadas();
      }
    );
  }

  inicializarPaquetes(paquetes: Paquete[]): void {
    this.paquetes = new MatTableDataSource<Paquete>(paquetes);
    this.paquetes.paginator = this.paginadorProductos;
    this.paquetes.filterPredicate = (paquete: Paquete, filtro: string) => {
      return paquete.nombre.trim().toLowerCase().indexOf(filtro.trim().toLowerCase()) !== -1;
    }
    this.paquetesObservable = this.paquetes.connect();
  }

  buscarPaquete(): void {
    this.paquetes.filter = this.filtroBusquedaPaquete.trim().toLowerCase();
  }

  /* MÉTODOS PARA EL CONTROL DE AGREGAR ORDENES */
  quitarIngredientesIdentico(quitarIngredientesOrden: Ingrediente[], quitarIngredientesNuevaOrden: Ingrediente[]): boolean {
    let esIdentico: boolean = true;
    if (quitarIngredientesOrden.length == quitarIngredientesNuevaOrden.length) {
      for (let posicionIngrediente = 0; posicionIngrediente < quitarIngredientesOrden.length; posicionIngrediente++) {
        if (quitarIngredientesOrden[posicionIngrediente]._id != quitarIngredientesNuevaOrden[posicionIngrediente]._id) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  agregarIngredientesIdentico(agregarIngredientesOrden: AgregarIngrediente[], agregarIngredientesNuevaOrden: AgregarIngrediente[]): boolean {
    let esIdentico: boolean = true;
    if (agregarIngredientesOrden.length == agregarIngredientesNuevaOrden.length) {
      for (let posicionIngrediente = 0; posicionIngrediente < agregarIngredientesOrden.length; posicionIngrediente++) {
        if (agregarIngredientesOrden[posicionIngrediente]._idIngrediente != agregarIngredientesNuevaOrden[posicionIngrediente]._idIngrediente ||
          agregarIngredientesOrden[posicionIngrediente].precio != agregarIngredientesNuevaOrden[posicionIngrediente].precio) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  notasIdentico(notasOrden: string[], notasNuevaOrden: string[]): boolean {
    let esIdentico: boolean = true;
    if (notasOrden.length == notasNuevaOrden.length) {
      for (let posicionNota = 0; posicionNota < notasOrden.length; posicionNota++) {
        if (notasOrden[posicionNota] != notasNuevaOrden[posicionNota]) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  alternativasProductosPorTamanoIdentico(alternativasProductosPorTamanoOrden: AlternativaProductoPorTamano[], alternativasProductosPorTamanoNuevaOrden: AlternativaProductoPorTamano[]): boolean {
    let esIdentico: boolean = true;
    if (alternativasProductosPorTamanoOrden.length == alternativasProductosPorTamanoNuevaOrden.length) {
      for (let posicionAlternativaProductoPorTamano = 0; posicionAlternativaProductoPorTamano < alternativasProductosPorTamanoOrden.length; posicionAlternativaProductoPorTamano++) {
        if (alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano]._idProducto != alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano]._idProducto ||
          alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano]._idTamano != alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano]._idTamano ||
          !this.quitarIngredientesIdentico(<Ingrediente[]>alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].quitarIngredientes, <Ingrediente[]>alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].quitarIngredientes) ||
          !this.agregarIngredientesIdentico(alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].agregarIngredientes, alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].agregarIngredientes) ||
          !this.notasIdentico(alternativasProductosPorTamanoOrden[posicionAlternativaProductoPorTamano].notas, alternativasProductosPorTamanoNuevaOrden[posicionAlternativaProductoPorTamano].notas)
        ) {
          esIdentico = false;
          break;
        }
      }
    } else {
      esIdentico = false;
    }
    return esIdentico;
  }

  obtenerPosicionOrden(nuevaOrden: Orden): number {
    return this.ordenes.findIndex(orden => {
      let esOrdenIdentica: boolean = true;
      if (orden.tipo == nuevaOrden.tipo && orden.precioPorTamano._idTamano == nuevaOrden.precioPorTamano._idTamano && orden.precioPorTamano.precio == nuevaOrden.precioPorTamano.precio) {
        switch (nuevaOrden.tipo) {
          case TipoOrden.Producto:
            if (
              nuevaOrden.datosProducto._idProducto != orden.datosProducto._idProducto ||
              !this.quitarIngredientesIdentico(<Ingrediente[]>orden.datosProducto.quitarIngredientes, <Ingrediente[]>nuevaOrden.datosProducto.quitarIngredientes) ||
              !this.agregarIngredientesIdentico(orden.datosProducto.agregarIngredientes, nuevaOrden.datosProducto.agregarIngredientes) ||
              !this.notasIdentico(orden.datosProducto.notas, nuevaOrden.datosProducto.notas)) {
              esOrdenIdentica = false;
            }
            break;
          case TipoOrden.Paquete:
            if (nuevaOrden.datosPaquete._idPaquete != orden.datosPaquete._idPaquete ||
              !this.alternativasProductosPorTamanoIdentico(orden.datosPaquete.alternativasProductosPorTamano, nuevaOrden.datosPaquete.alternativasProductosPorTamano)
            ) {
              esOrdenIdentica = false;
            }
            break;
        }
      } else {
        esOrdenIdentica = false;
      }
      return esOrdenIdentica;
    });
  }

  agregarOrdenDePedido(nuevaOrden: Orden): void {
    const posicionOrden = this.obtenerPosicionOrden(nuevaOrden);
    if (posicionOrden == -1) {
      this.ordenes.push(nuevaOrden);
      this.ordenes = this.ordenes.sort((orden1, orden2) => {
        let _idOrden1 = orden1.tipo == TipoOrden.Producto ? orden1.datosProducto._idProducto : orden1.datosPaquete._idPaquete;
        let _idOrden2 = orden2.tipo == TipoOrden.Producto ? orden2.datosProducto._idProducto : orden2.datosPaquete._idPaquete;
        if (_idOrden1 > _idOrden2) return 1;
        if (_idOrden1 < _idOrden2) return -1;
        return 0;
      })
    } else {
      (<number>this.ordenes[posicionOrden].cantidad) += <number>nuevaOrden.cantidad;
    }
    this.agregarOrdenControlInternoExistenciasDeProductosPorTamano(nuevaOrden);
  }

  /* VARIABLES PARA AGREGAR ORDENES */
  agregarOrdenControlInternoExistenciasDeProductosPorTamano(orden: Orden): void {
    let posicionControlInternoExistenciaDeProductoPorTamano: number;
    switch (orden.tipo) {
      case TipoOrden.Producto:
        posicionControlInternoExistenciaDeProductoPorTamano = this.obtenerPosicionControlInternoExistenciaDeProductoPorTamano(orden.datosProducto._idProducto, orden.precioPorTamano._idTamano);
        if (posicionControlInternoExistenciaDeProductoPorTamano != -1) {
          this.controlInternoExistenciasDeProductosPorTamano[posicionControlInternoExistenciaDeProductoPorTamano].cantidad += <number>orden.cantidad;
        } else {
          this.agregarControlInternoExistenciaDeProductoPorTamano(orden.datosProducto._idProducto, orden.precioPorTamano._idTamano, <number>orden.cantidad);
        }
        break;
      case TipoOrden.Paquete:
        orden.datosPaquete.alternativasProductosPorTamano.forEach(alternativaProductoPorTamano => {
          posicionControlInternoExistenciaDeProductoPorTamano = this.obtenerPosicionControlInternoExistenciaDeProductoPorTamano(alternativaProductoPorTamano._idProducto, alternativaProductoPorTamano._idTamano);
          if (posicionControlInternoExistenciaDeProductoPorTamano != -1) {
            this.controlInternoExistenciasDeProductosPorTamano[posicionControlInternoExistenciaDeProductoPorTamano].cantidad += <number>orden.cantidad;
          } else {
            this.agregarControlInternoExistenciaDeProductoPorTamano(alternativaProductoPorTamano._idProducto, alternativaProductoPorTamano._idTamano, <number>orden.cantidad);
          }
        })
        break;
    }
  }

  obtenerPosicionControlInternoExistenciaDeProductoPorTamano(_idProducto: string, _idTamano: string): number {
    return this.controlInternoExistenciasDeProductosPorTamano.findIndex(controlInternoExistenciaDeProductoPorTamano => {
      return controlInternoExistenciaDeProductoPorTamano._idProducto == _idProducto && controlInternoExistenciaDeProductoPorTamano._idTamano == _idTamano;
    })
  }

  agregarControlInternoExistenciaDeProductoPorTamano(_idProducto: string, _idTamano: string, cantidad: number): void {
    let controlInternoExistenciaDeProductoPorTamano = new ControlInternoExistenciaDeProductoPorTamano();
    controlInternoExistenciaDeProductoPorTamano._idProducto = _idProducto;
    controlInternoExistenciaDeProductoPorTamano._idTamano = _idTamano;
    controlInternoExistenciaDeProductoPorTamano.cantidad = cantidad;
    this.controlInternoExistenciasDeProductosPorTamano.push(controlInternoExistenciaDeProductoPorTamano);
  }

  obtenerCantidadDeOrdenesDePedido(): number {
    let cantidadDeOrdenes: number = 0;
    this.ordenes.forEach(orden => {
      cantidadDeOrdenes += <number>orden.cantidad;
    });
    return cantidadDeOrdenes;
  }

  /* MÉTODOS PARA AGREGAR PEDIDO */
  agregarPedido(): void {
    const dialogRef = this.modal.open(AgregarPedidoModalComponent,
      {
        disableClose: true,
        minWidth: '60%',
        data: {
          _idRestaurante: this._idRestaurante,
          _idArea: this._idArea,
          _idElementoPorArea: this._idElementoPorArea,
          _idComanda: this._idComanda,
          ordenes: this.ordenes,
          controlInternoExistenciasDeProductosPorTamano: this.controlInternoExistenciasDeProductosPorTamano
        }
      });
    dialogRef.afterClosed().subscribe(
      (resultado: {grupoDeOrdenes: GrupoDeOrdenes, contadorDeOrdenesFallidas: number}) => {
        if (resultado != null) {
          if (resultado.contadorDeOrdenesFallidas == 0) {
            this._alertasService.alertaExitoConConfirmacionImpresionTicket('¡Pedido levantado exitosamente!', 'Se ha levantado el pedido con éxito', 'Imprimir pedido').then((result) => {
              if (result.value) {
                this.imprimirGrupoDeOrdenes(resultado.grupoDeOrdenes);
              }
            })
          } else {
            this._alertasService.alertaAdvertenciaConConfirmacionImpresionTicket('Pedido incompleto', resultado.contadorDeOrdenesFallidas + '' + (resultado.contadorDeOrdenesFallidas == 1 ? ' orden ' : ' ordenes ') + ' fallo al levantar el pedido', 'Imprimir pedido').then((result) => {
              if (result.value) {
                this.imprimirGrupoDeOrdenes(resultado.grupoDeOrdenes);
              }
            })
          }
          this.obtenerTotalSinPagarDeOrdenesComanda.emit(true);
          this.ordenes = [];
          this.controlInternoExistenciasDeProductosPorTamano.forEach(controlInternoPorTamano => {
            this.disminuirExistenciasProducto(controlInternoPorTamano._idProducto, controlInternoPorTamano.cantidad);
          });
          this.controlInternoExistenciasDeProductosPorTamano = [];
        }
      }
    );
  }

  /* MÉTODOS PARA IMPRIMIR EL GRUPO DE ORDENES */
  async imprimirGrupoDeOrdenes(grupoDeOrdenes: GrupoDeOrdenes) {
    this._impresionService.actualizarTipoImpresion(TipoImpresion.GrupoDeOrdenes);
    await this._impresionService.actualizarGrupoDeOrdenes(this._idRestaurante, this._idArea, this._idElementoPorArea, this._idComanda, grupoDeOrdenes._id);
    await this._impresionService.actualizarOrdenesGrupoDeOrdenes(this._idRestaurante, this._idArea, this._idElementoPorArea, this._idComanda, grupoDeOrdenes._id);
    window.print();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (this.obtenerCategoriasSubscripcion) this.obtenerCategoriasSubscripcion.unsubscribe();
    if (this.obtenerExistenciasDeProductosSubscripcion) this.obtenerExistenciasDeProductosSubscripcion.unsubscribe();
    if (this.obtenerPaquetesVigentesSubscripcion) this.obtenerPaquetesVigentesSubscripcion.unsubscribe();
  }
  /*@Input() borrarDatos: number;
  @Output() approvedOrder = new EventEmitter<Orden[]>();
  @Output() editedElement = new EventEmitter<{ id, type }>();
  @Output() itemOrdered = new EventEmitter<{ id, type }>();

  title = 'Menu';
  productsObservable: Observable<any>;
  products: MatTableDataSource<Producto>;
  filtroProducto: string = '';
  noHayProductos: boolean;
  idCategoriaFiltro: string = '';
  productsSubscription: Subscription;
  promosObservable: Observable<any>;
  filtroPromo: string = '';
  noHayPromos: boolean;
  promos: MatTableDataSource<Paquete>;
  promosSubscription: Subscription;
  @ViewChild(MatPaginator, {static: false}) orderPaginator: MatPaginator;
  order: MatTableDataSource<Orden>;
  completeOrder: Orden[] = [];
  cols: string[] = ['Concepto', 'Tamano', 'Cantidad', 'Opciones'];
  idRestaurante: string;
  requestState: number;
  @ViewChild('dialogDeleteWarning', {static: true}) private dialogDeleteWarning: SwalComponent;*/

  /*constructor(private changeDetectorRef: ChangeDetectorRef,
              private productService: ProductService,
              private promoService: PromoService,
              private route: ActivatedRoute,
              public dialog: MatDialog) {
    this.requestState = RequestState.initial;
    this.order = new MatTableDataSource<any>([]);
    this.noHayPromos = false;
    this.noHayProductos = false;
  }*/

  /*ngOnChanges(changes: SimpleChanges): void {
    this.order.data = [];
    this.completeOrder = [];
  }

  ngOnDestroy(): void {
    if (this.products) {
      this.products.disconnect();
    }
    if (this.promos) {
      this.promos.disconnect();
    }
    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
    if (this.promosSubscription) {
      this.promosSubscription.unsubscribe();
    }
  }*/

  /*deleteElement(data: any, index: number): void {
    data.splice(index, 1);
    this.order = new MatTableDataSource<Orden>(data);
  }*/

  // Id del elemento a editar.
  /*editElement(id: string, type: number): void {
    this.editedElement.emit({id, type});
  }*/

  // Aumenta la cantidad de un determinado producto en la tabla.
  /*newElement(item: Paquete | Producto, orden: Orden, isPromo: boolean): void {
    const data = this.order.data;
    let index: number;
    if (isPromo) {
      index = data.findIndex(element => {
        if (element.datosPaquete !== undefined) {
          return element.datosPaquete.paquete.id === item.id.toString()
            && element.precioPorTamano.tamano.id === orden.precioPorTamano.tamano.id;
        }
      });
    } else {
      index = data.findIndex(element => {
        if (element.datosProducto !== undefined) {
          return element.datosProducto.producto.id === item.id.toString()
            && element.precioPorTamano.tamano.id === orden.precioPorTamano.tamano.id;
        }
      });
    }
    if (index === -1) {
      let newOrder: Orden;
      if (isPromo) {
        newOrder = {
          precioPorTamano: orden.precioPorTamano,
          fecha: null,
          cantidad: 1,
          cancelada: false,
        };
        newOrder.datosPaquete = {
          paquete: item as Paquete,
          alternativasProductosPorTamano: null
        };
        this.productsObservable.forEach((product) => {
          product.forEach(x => {
            orden.datosPaquete.alternativasProductosPorTamano.forEach(prod => {
              if (prod.producto.id == x.id) {
                if (x.existencia != 'NA') {
                  x.existencia--;
                }
              }
            });
          });
        });
      } else {
        newOrder = {
          precioPorTamano: orden.precioPorTamano,
          fecha: null,
          cantidad: orden.cantidad,
          cancelada: false,
        };
        newOrder.datosProducto = {
          producto: item as Producto,
          quitarIngredientes: null,
          agregarIngredientes: null,
          notas: null
        };
      }
      data.push(newOrder);
      this.order.data = data;
    } else {
      if (isPromo) {
        data[index].cantidad++;
        this.productsObservable.forEach((product) => {
          product.forEach(x => {
            orden.datosPaquete.alternativasProductosPorTamano.forEach(prod => {
              if (prod.producto.id == x.id) {
                if (x.existencia != 'NA') {
                  x.existencia--;
                }
              }
            });
          });
        });
      } else {
        data[index].cantidad += orden.cantidad;
      }
    }
    if (isPromo) {
      this.completeOrder.push(orden);
    } else {
      for (let i = 0; i < orden.cantidad; i++) {
        this.completeOrder.push(orden);
      }
    }
    if (isPromo) {
      this.itemOrdered.emit({id: item.id, type: 1});
    } else {
      this.itemOrdered.emit({id: item.id, type: 0});
    }
  }*/

  /*deleteElement(i: number, tipo: number): void {
    if (this.order.data[i].cantidad === 1) {
      this.dialogDeleteWarning.show().then(
        res => {
          if (res.value) {
            const completeOrderIndex = this.completeOrder.findIndex(element => {
              if (this.order.data[i].precioPorTamano.tamano.id === element.precioPorTamano.tamano.id) {
                if (tipo === 0 && element.datosProducto !== undefined) {
                  return this.order.data[i].datosProducto.producto.id === element.datosProducto.producto.id;
                }
                if (tipo === 1 && element.datosPaquete !== undefined) {
                  return this.order.data[i].datosPaquete.paquete.id === element.datosPaquete.paquete.id;
                }
              }
            });
            if (this.order.data[i].datosPaquete) {
              this.productsObservable.forEach((product) => {
                product.forEach(x => {
                  this.completeOrder.forEach(order => {
                    order.datosPaquete.alternativasProductosPorTamano.forEach(prod => {
                      if (prod.producto.id == x.id) {
                        if (x.existencia != 'NA') {
                          x.existencia++;
                        }
                      }
                    });
                  });
                });
              });
            }
            if (this.order.data[i].datosProducto) {
              this.productsObservable.forEach((product) => {
                product.forEach(x => {
                  this.completeOrder.forEach(order => {
                    if (order.datosProducto.producto.id === x.id) {
                      if (x.existencia != 'NA') {
                        x.existencia++;
                      }
                    }
                  });
                });
              });
            }
            this.completeOrder.splice(completeOrderIndex, 1);
            const data = this.order.data;
            data.splice(i, 1);
            this.order = new MatTableDataSource<Orden>(data);
          }
        }
      );
    } else {
      const dialogRef = this.dialog.open(DeleteOrderComponent,
        {
          disableClose: true,
          data: {
            tipo,
            tamano: this.order.data[i].precioPorTamano.tamano,
            producto: this.order.data[i].datosProducto !== undefined ? this.order.data[i].datosProducto.producto : null,
            paquete: this.order.data[i].datosPaquete !== undefined ? this.order.data[i].datosPaquete.paquete : null,
            ordenes: this.completeOrder.filter((element) => {
              if (this.order.data[i].precioPorTamano.tamano.id === element.precioPorTamano.tamano.id) {
                if (tipo === 0 && element.datosProducto !== undefined) {
                  return this.order.data[i].datosProducto.producto.id === element.datosProducto.producto.id;
                }
                if (tipo === 1 && element.datosPaquete !== undefined) {
                  return this.order.data[i].datosPaquete.paquete.id === element.datosPaquete.paquete.id;
                }
              }
            })
          }
        });
      dialogRef.afterClosed().subscribe(
        res => {
          if (res.ok) {
            res.ordenes.forEach((orden) => {
              for (let j = 0; j < orden.cantidad; j++) {
                const completeOrderIndex = this.completeOrder.findIndex(element => {
                  if (this.order.data[i].precioPorTamano.tamano.id === element.precioPorTamano.tamano.id) {
                    if (tipo === 0) {
                      if (element.datosProducto.agregarIngredientes.length !== orden.datosProducto.agregarIngredientes.length
                        || element.datosProducto.quitarIngredientes.length !== orden.datosProducto.quitarIngredientes.length
                        || element.datosProducto.notas.length !== orden.datosProducto.notas.length) {
                        return false;
                      }
                      element.datosProducto.agregarIngredientes.forEach((ingrediente) => {
                        if (!orden.datosProducto.agregarIngredientes.includes(ingrediente)) {
                          return false;
                        }
                      });
                      element.datosProducto.quitarIngredientes.forEach((ingrediente) => {
                        if (!orden.datosProducto.quitarIngredientes.includes(ingrediente)) {
                          return false;
                        }
                      });
                      element.datosProducto.notas.forEach((nota) => {
                        if (!orden.datosProducto.notas.includes(nota)) {
                          return false;
                        }
                      });
                      return true;
                    } else {
                      for (let posicionAlternativa = 0; posicionAlternativa < element.datosPaquete.alternativasProductosPorTamano.length; posicionAlternativa++) {
                        if (element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.length
                          || element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.length !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.length
                          || element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.length !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.length) {
                          return false;
                        }
                        if (element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].producto.id !== orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].producto.id) {
                          return false;
                        }
                        element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.forEach((ingrediente) => {
                          if (!orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].agregarIngredientes.includes(ingrediente)) {
                            return false;
                          }
                        });
                        element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.forEach((ingrediente) => {
                          if (!orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].quitarIngredientes.includes(ingrediente)) {
                            return false;
                          }
                        });
                        element.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.forEach((nota) => {
                          if (!orden.datosPaquete.alternativasProductosPorTamano[posicionAlternativa].notas.includes(nota)) {
                            return false;
                          }
                        });
                      }
                      return true;
                    }
                  }
                });
                /*if (this.order.data[i].datosPaquete) {
                  this.productsObservable.forEach((product) => {
                    product.forEach(x => {
                      this.order.data[i].datosPaquete.alternativasProductosPorTamano.forEach(prod => {
                        if (prod.producto.id == x.id) {
                          if (x.existencia != 'NA') {
                            x.existencia++;
                          }
                        }
                      });
                    });
                  });
                }
                if (this.order.data[i].datosProducto) {
                  this.productsObservable.forEach((product) => {
                    product.forEach(x => {
                      if (this.order.data[i].datosProducto.producto.id === x.id) {
                        if (x.existencia != 'NA') {
                          x.existencia++;
                        }
                      }
                    });
                  });
                }
                this.completeOrder.splice(completeOrderIndex, 1);
              }
              const data = this.order.data;
              if (data[i].cantidad - orden.cantidad === 0) {
                data.splice(i, 1);
              } else {
                data[i].cantidad -= orden.cantidad;
              }
              this.order = new MatTableDataSource<Orden>(data);
            });
          }
        }
      );
    }
  }*/

  /*deleteProduct(data: any, id: string): void {
    const index = data.findIndex(element => {
      if (element.datosProducto !== undefined) {
        return element.datosProducto.producto.id === id;
      }
    });
    this.deleteElement(data, index);
  }

  deletePromo(data: any, id: string): void {
    const index = data.findIndex(element => {
      if (element.datosPaquete !== undefined) {
        return element.datosPaquete.paquete.id === id;
      }
    });
    this.deleteElement(data, index);
  }*/

  /*mandarACocina() {
    this.approvedOrder.emit(this.completeOrder);
  }

  instanceOfPaquete(object: any): object is Paquete {
    return 'alternativasProductos' in object;
  }

  submit(): void {
    this.approvedOrder.emit(this.order.data);
  }*/
}
