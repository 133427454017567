import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KdsComponent} from './kds.component';
import {KDSOrderComponent} from './components/order/k-d-s-order.component';
import {MatBadgeModule, MatButtonModule, MatCardModule, MatIconModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [
    KdsComponent,
    KDSOrderComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    MatCardModule,
    MatIconModule,
    MatBadgeModule
  ],
  exports: [
    KdsComponent,
    KDSOrderComponent
  ]
})
export class KdsModule {
}
