import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatStepper } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ClientesService } from 'src/app/core/servicios/clientes.service';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { Cliente } from 'src/app/shared/class-models/cliente.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { RFC_GENERICO } from 'src/app/shared/constants/rfc-generico.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';


@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit, OnDestroy {
  titulo: string = 'Agregar cliente';
  @ViewChild('direccionForm') direccionForm: FormGroup;
  @ViewChild('stepper') stepper: MatStepper;
  stepActual: number = 0;
  TipoDePersona = TipoDePersona;
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  cliente: Cliente = new Cliente();
  estadoPeticionAgregarCliente: number;
  agregarClienteSubscripcion: Subscription;
  estadosSubscripcion: Subscription;
  ciudadesSubscripcion: Subscription;
  estados: Estado[];
  ciudades: Ciudad[];
  codigoPostal: string = '';

  constructor(public modal: MatDialogRef<NewClientComponent>,
    private _clientesService: ClientesService,
    private _ubicacionService: UbicacionService,
    private _alertasService: AlertasService) {
    this.estadoPeticionAgregarCliente = RequestState.initial;

  }

  ngOnInit(): void {
    this.obtenerEstados();
  }

  obtenerEstados(): void {
    this.estados = [];
    this.estadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerCiudades(idEstado: string): void {
    this.ciudades = [];
    this.ciudadesSubscripcion = this._ubicacionService.obtenerCiudades(Number(idEstado)).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerTipoDePersona(tipo: number): string {
    switch (tipo) {
      case TipoDePersona.Moral: return 'Moral';
      case TipoDePersona.Fisica: return 'Física';
      case TipoDePersona.Ninguna: return 'Ninguna';
      default: return '';
    }
  }

  actualizacionTipoDePersona() {
    switch (this.cliente.tipoDePersona) {
      case TipoDePersona.Moral:
      case TipoDePersona.Fisica:
        if (this.cliente.rfc == RFC_GENERICO) this.cliente.rfc = '';
        break;
      case TipoDePersona.Ninguna:
        this.cliente.rfc = RFC_GENERICO;
        break;
    }
  }

  convertirRfcMayusculas(): void {
    if (this.cliente.rfc) {
      this.cliente.rfc = this.cliente.rfc.toUpperCase();
    }
  }

  async agregarCliente() {
    this.estadoPeticionAgregarCliente = RequestState.loading;
    const codigoPostal = await this.verificarCodigoPostal(this.cliente.idEstado, this.codigoPostal);
    if (!codigoPostal) {
      this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
      this.estadoPeticionAgregarCliente = RequestState.initial;
      this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
    } else {
      this.cliente.idCodigoPostal = codigoPostal.id;
      this.agregarClienteSubscripcion = this._clientesService.agregarCliente(this.prepararDatosCliente()).subscribe(
        (cliente: Cliente) => {
          this.estadoPeticionAgregarCliente = RequestState.success;
          this.modal.close(cliente);
        },
        (error: HttpErrorResponse) => {
          this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          this.estadoPeticionAgregarCliente = RequestState.error;
        }
      );
    }
  }

  async validarCodigoPostal() {
    if (this.codigoPostal.length >= 5) {
      const codigoPostal = await this.verificarCodigoPostal(this.cliente.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      }
    }

  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  prepararDatosCliente(): Cliente {
    let cliente = new Cliente();
    cliente.tipoDePersona = this.cliente.tipoDePersona;
    cliente.rfc = this.cliente.rfc;
    switch (cliente.tipoDePersona) {
      case TipoDePersona.Moral:
        cliente.razonSocial = this.cliente.razonSocial;
        cliente.tieneRepresentante = this.cliente.tieneRepresentante;
        if (cliente.tieneRepresentante) {
          cliente.nombresRepresentante = this.cliente.nombresRepresentante;
          cliente.primerApellidoRepresentante = this.cliente.primerApellidoRepresentante;
          cliente.segundoApellidoRepresentante = this.cliente.segundoApellidoRepresentante;
        }
        break;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        cliente.nombres = this.cliente.nombres;
        cliente.primerApellido = this.cliente.primerApellido;
        cliente.segundoApellido = this.cliente.segundoApellido;
        cliente.tieneRepresentante = false;
        break;
    }
    cliente.idEstado = this.cliente.idEstado;
    cliente.idCiudad = this.cliente.idCiudad;
    cliente.calle = this.cliente.calle;
    cliente.colonia = this.cliente.colonia;
    cliente.numeroExterior = this.cliente.numeroExterior;
    cliente.numeroInterior = this.cliente.numeroInterior;
    cliente.idCodigoPostal = this.cliente.idCodigoPostal;
    cliente.telefono = this.cliente.telefono;
    cliente.correo = this.cliente.correo;
    return cliente;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  retroceder() {
    this.stepActual--;
    this.stepper.previous();
  }

  avanzar() {
    this.stepActual++;
    this.stepper.next();
  }

  ngOnDestroy(): void {
    if (this.estadosSubscripcion) this.estadosSubscripcion.unsubscribe();
    if (this.ciudadesSubscripcion) this.ciudadesSubscripcion.unsubscribe();
    if (this.agregarClienteSubscripcion) this.agregarClienteSubscripcion.unsubscribe();
  }
}
