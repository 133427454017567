import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './kiosk.component.html'
})
export class KioskComponent implements OnInit {
  titulo = 'Menú';
  
  constructor() {}

  ngOnInit(): void {}
}
