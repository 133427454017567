import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Restaurante} from '../../shared/models/restaurante';
import {AppSettings} from '../../configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  public restaurante: Restaurante;

  constructor(private httpClient: HttpClient) {
  }

  obtenerRestaurante(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/sucursal.php?id=` + id, AppSettings.Options)
      .pipe(map((res: Restaurante[]) => {
        const fl = res.filter(restaurante => restaurante.id === id);
        this.restaurante = (fl.length > 0) ? fl[0] : null;
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  obtenerRestaurantes(): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/sucursales/sucursales.php`, AppSettings.Options);
  }

  nuevoRestaurante(restaurante: Restaurante): Observable<any> {
    const body = {
      restaurante
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/sucursal.php`, JSON.stringify(body), AppSettings.Options);
  }

  actualizarRestaurante(id: string, restaurante: Restaurante): Observable<any> {
    restaurante.id = id;
    const body = {
      restaurante,
      config: false
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/sucursal.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarRestaurante(id: string) {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/sucursal.php?id=${id}`, AppSettings.Options);
  }

  actualizarConfiguracion(restaurante: Restaurante): Observable<any> {
    const body = {
      restaurante,
      config: true
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/sucursal.php`, JSON.stringify(body), AppSettings.Options);
  }
}
