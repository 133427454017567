import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { ActivatedRoute } from '@angular/router';
import { CajasDeUsuarioService } from 'src/app/core/servicios/cajas-de-usuario.services';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Usuario } from 'src/app/shared/class-models/usuario.model';

@Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html'
})
export class CashRegisterComponent implements OnInit, OnDestroy {
  cajasDeUsuario: CajaDeUsuario[]
  posicionCajaDeUsuario: number;
  RequestState = RequestState;
  estadoPeticionObtenerCajasDeUsuarioAbiertas: number;
  obtenerCajasDeUsuarioAbiertasSubscripcion: Subscription;
  _idRestaurante: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _cajasDeUsuarioService: CajasDeUsuarioService,
    private _alertasService: AlertasService,
  ) {
    this.posicionCajaDeUsuario = 0;
  }

  ngOnInit(): void {
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this.obtenerCajasDeUsuarioAbiertas();
  }

  /* MÉTODOS PARA OBTENER LOS DATOS DEL FORMULARIO */
  obtenerCajasDeUsuarioAbiertas(): void {
    this.cajasDeUsuario = [];
    this.estadoPeticionObtenerCajasDeUsuarioAbiertas = RequestState.loading;
    this.obtenerCajasDeUsuarioAbiertasSubscripcion = this._cajasDeUsuarioService.obtenerCajasDeUsuarioAbiertas(this._idRestaurante).subscribe(
      (cajasDeUsuario: CajaDeUsuario[]) => {
        this.cajasDeUsuario = cajasDeUsuario;
        this.estadoPeticionObtenerCajasDeUsuarioAbiertas = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCajasDeUsuarioAbiertas = RequestState.error;
      }
    );
  }

  obtenerNombreCompletoUsuario(usuario: Usuario): string {
    return usuario.nombres + ' ' + usuario.primerApellido + (usuario.segundoApellido ? (' ' + usuario.segundoApellido) : '');
  }

  cambioCaja(posicion: number){
    this.posicionCajaDeUsuario = posicion;
  }

  ngOnDestroy(): void {
    if (this.obtenerCajasDeUsuarioAbiertasSubscripcion) this.obtenerCajasDeUsuarioAbiertasSubscripcion.unsubscribe()
  }
}
