import { Component, OnInit, Inject } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-vista-ingrediente-modal',
  templateUrl: './vista-ingrediente-modal.component.html',
  styleUrls: ['./vista-ingrediente-modal.component.scss']
})
export class VistaIngredienteModalComponent implements OnInit {
  RequestState = RequestState;
  estadoPeticionObtenerIngrediente: number;
  obtenerIngredienteSubscription: Subscription;
  ingrediente: Ingrediente;

  constructor(
    private _ingredientesService: IngredientesService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Ingrediente,
    public referenciaModal: MatDialogRef<VistaIngredienteModalComponent>,
  ) {
    this.estadoPeticionObtenerIngrediente = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerIngrediente();
  }

  obtenerIngrediente() {
    this.estadoPeticionObtenerIngrediente = RequestState.loading;
    this.obtenerIngredienteSubscription = this._ingredientesService.obtenerIngrediente(this.data._id).subscribe(
      (ingrediente: Ingrediente) => {
        this.ingrediente = ingrediente;
        this.estadoPeticionObtenerIngrediente = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerIngrediente = RequestState.error;
        this.cerrar();
      }
    );
  }

  cerrar() {
    this.referenciaModal.close()
  }

  ngOnDestroy(): void {
    if (this.obtenerIngredienteSubscription) this.obtenerIngredienteSubscription.unsubscribe();
  }
}
