import { Component, OnInit, Inject } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlternativaProductosPorTamano, Paquete } from 'src/app/shared/class-models/paquete.model';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DiaDeLaSemana } from 'src/app/shared/enums/dia-de-la-semana.enum';

@Component({
  selector: 'app-vista-paquete-modal',
  templateUrl: './vista-paquete-modal.component.html',
  styleUrls: ['./vista-paquete-modal.component.scss']
})
export class VistaPaqueteModalComponent implements OnInit {
  RequestState = RequestState;
  estadoPeticionObtenerPaquete: number;
  obtenerPaqueteSubscription: Subscription;
  paquete: Paquete;

  /* PAQUETE POR TAMAÑO */
  columnasPaquetePorTamano: string[] = ['producto', 'tamano'];
  tablasPaquetePorTamano: MatTableDataSource<AlternativaProductosPorTamano>[][] = [];

  constructor(
    private _paquetesService: PaquetesService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Paquete,
    public referenciaModal: MatDialogRef<VistaPaqueteModalComponent>
  ) {
    this.estadoPeticionObtenerPaquete = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerPaquete();
  }

  obtenerPaquete(): void {
    this.estadoPeticionObtenerPaquete = RequestState.loading;
    this.obtenerPaqueteSubscription = this._paquetesService.obtenerPaquete(this.data._id).subscribe(
      (paquete: Paquete) => {
        this.paquete = paquete;
        this.inicializarPaquetePorTamano();
        this.estadoPeticionObtenerPaquete = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerPaquete = RequestState.error;
        this.cerrar();
      }
    );
  }

  inicializarPaquetePorTamano(): void {
    this.tablasPaquetePorTamano = [];
    this.paquete.paquetePorTamano.forEach((paquetePorTamano, posicion) => {
      this.tablasPaquetePorTamano.push([]);
      this.paquete.paquetePorTamano[posicion].alternativasProductosPorTamano.forEach(alternativaProductosPorTamano => {
        this.tablasPaquetePorTamano[posicion].push(new MatTableDataSource<AlternativaProductosPorTamano>(alternativaProductosPorTamano));
      }) 
    });
  }

  obtenerDiasDeLaSemana(): string {
    let diasDeLaSemana: string = '';//Todos los 
    for (let posicionDiaDeLaSemana = 0; posicionDiaDeLaSemana < this.paquete.diasDeLaSemana.length; posicionDiaDeLaSemana++) {
      diasDeLaSemana += this.obtenerDiaDeLaSemana(this.paquete.diasDeLaSemana[posicionDiaDeLaSemana]);
      if (posicionDiaDeLaSemana <= this.paquete.diasDeLaSemana.length - 3) {
        diasDeLaSemana += ', ';
      } else {
        if (posicionDiaDeLaSemana === this.paquete.diasDeLaSemana.length - 2) {
          diasDeLaSemana += ' y ';
        } /*else {
          diasDeLaSemana += '.';
        }*/
      }
    }
    return diasDeLaSemana;
  }

  obtenerDiaDeLaSemana(diaDeLaSemana: number): string {
    switch (diaDeLaSemana) {
      case DiaDeLaSemana.Domingo: return 'Domingo';
      case DiaDeLaSemana.Lunes: return 'Lunes';
      case DiaDeLaSemana.Martes: return 'Martes';
      case DiaDeLaSemana.Miercoles: return 'Miércoles';
      case DiaDeLaSemana.Jueves: return 'Jueves';
      case DiaDeLaSemana.Viernes: return 'Viernes';
      case DiaDeLaSemana.Sabado: return 'Sabado';
    }
  }

  cerrar(): void {
    this.referenciaModal.close()
  }

  ngOnDestroy(): void {
    if (this.obtenerPaqueteSubscription) this.obtenerPaqueteSubscription.unsubscribe();
  }
}
