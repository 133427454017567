import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { GastoGeneral } from 'src/app/shared/class-models/gasto-general.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GastosGeneralesService } from 'src/app/core/servicios/gastos-generales.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { TipoDePersona } from 'src/app/shared/enums/tipo-de-personas.enum';
import { TipoDeGastoGeneral } from 'src/app/shared/class-models/tipo-de-gasto-general.model';
import { AltaGastoComponent } from './alta-gasto/alta-gasto.component';
import { ActualizarGastoComponent } from './actualizar-gasto/actualizar-gasto.component';
import { FormaDePago } from 'src/app/shared/enums/forma-de-pago.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';

@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.scss']
})
export class GastosComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<GastoGeneral>;
  filtro: string = '';
  estadoPeticionObtenerGastosGenerales: number;
  RequestState = RequestState;
  obtenerGastosGeneralesSubscripcion: Subscription;
  eliminarGastoGeneralSubscripcion: Subscription;
  columnas = ['fechaRegistro', 'concepto', 'razonSocial', 'formaDePago', 'total', 'opciones'];
  _idRestaurante: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _gastosGeneralesService: GastosGeneralesService,
    private _alertasService: AlertasService,
    private fechaPipe: FechaPipe,
    public dialog: MatDialog) {
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this.estadoPeticionObtenerGastosGenerales = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerGastosGeneral();
  }

  obtenerGastosGeneral(): void {
    this.estadoPeticionObtenerGastosGenerales = RequestState.loading;
    this.obtenerGastosGeneralesSubscripcion = this._gastosGeneralesService.obtenerGastosGenerales(this._idRestaurante).subscribe(
      (gastosGenerales: GastoGeneral[]) => {
        this.inicializarGastosGenerales(gastosGenerales);
        this.estadoPeticionObtenerGastosGenerales = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerGastosGenerales = RequestState.error;
      }
    );
  }

  inicializarGastosGenerales(gastosGenerales: GastoGeneral[]): void {
    this.dataSource = new MatTableDataSource<GastoGeneral>(gastosGenerales);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (gastoGeneral: GastoGeneral, filtro: string) => {
      return gastoGeneral.tipoDeGastoGeneral.concepto.trim().toLowerCase().indexOf(filtro) !== -1 ||
        (gastoGeneral.tipoDeGastoGeneral.tieneReceptor ? this.obtenerRazonSocial(gastoGeneral.tipoDeGastoGeneral).trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
        this.obtenerFormaDePago(gastoGeneral.formaDePago).trim().toLowerCase().indexOf(filtro) !== -1 ||
        ('$' + Number(gastoGeneral.total).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.fechaPipe.transform(gastoGeneral.fechaRegistro).trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarGastoGeneral(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerRazonSocial(tipoDeGastoGeneral: TipoDeGastoGeneral): string {
    switch (tipoDeGastoGeneral.tipoDePersona) {
      case TipoDePersona.Moral: return tipoDeGastoGeneral.razonSocial;
      case TipoDePersona.Fisica:
      case TipoDePersona.Ninguna:
        return tipoDeGastoGeneral.nombres + ' ' + tipoDeGastoGeneral.primerApellido + (tipoDeGastoGeneral.segundoApellido ? (' ' + tipoDeGastoGeneral.segundoApellido) : '');
    }
  }

  obtenerFormaDePago(tipoFormaDePago: string): string {
    switch (tipoFormaDePago) {
      case FormaDePago.Efectivo: return 'Efectivo';
      case FormaDePago.ChequeNominativo: return 'Cheque nominativo';
      case FormaDePago.TransferenciaElectronicaDeFondos: return 'Transferencia electrónica de fondos';
      case FormaDePago.TarjetaDeCredito: return 'Tarjeta de crédito';
      case FormaDePago.MonederoElectronico: return 'Monedero electrónico';
      case FormaDePago.DineroElectronico: return 'Dinero electróico';
      case FormaDePago.ValesDeDespensa: return 'Vales de despensa';
      case FormaDePago.DacionDePago: return 'Dacion de pago';
      case FormaDePago.PagoPorSubrogacion: return 'Pago por subrogacion';
      case FormaDePago.PagoPorConsignacion: return 'Pago por consignacion';
      case FormaDePago.Condonacion: return 'Condonacion';
      case FormaDePago.Compensacion: return 'Compensacion';
      case FormaDePago.Novacion: return 'Novacion';
      case FormaDePago.Confusion: return 'Confusion';
      case FormaDePago.RemisionDeDeuda: return 'Remision de deuda';
      case FormaDePago.PrescripcionOCaducidad: return 'Prescripcion o caducidad';
      case FormaDePago.ASatisfaccionDelAcreedor: return 'A satisfaccion del acreedor';
      case FormaDePago.TarjetaDeDebito: return 'Tarjeta de debito';
      case FormaDePago.TarjetaDeServicios: return 'Tarjeta de servicios';
      case FormaDePago.AplicacionDeAnticipos: return 'Aplicacion de anticipos';
      case FormaDePago.PorDefinir: return 'Por definir';
    }
  }

  agregarGastoGeneral(): void {
    const dialogRef = this.dialog.open(AltaGastoComponent, { disableClose: true, data: this._idRestaurante });
    dialogRef.afterClosed().subscribe(
      (gastoGeneral: GastoGeneral) => {
        if (gastoGeneral) {
          this._alertasService.alertaExitoSinConfirmacion('Gasto general agregado exitosamente', 'El gasto general de ' + gastoGeneral.tipoDeGastoGeneral.concepto + ' ha sido agregado con éxito');
          this.dataSource.data.push(gastoGeneral);
          const gastosGenerales: GastoGeneral[] = this.dataSource.data;
          this.inicializarGastosGenerales(gastosGenerales);
          this.buscarGastoGeneral();
        }
      }
    );
  }

  actualizarGastoGeneral(gastoGeneral: GastoGeneral): void {
    const dialogRef = this.dialog.open(ActualizarGastoComponent, { disableClose: true, data: { _idRestaurante: this._idRestaurante, gastoGeneral: gastoGeneral } });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Gasto general actualizado exitosamente', 'El gasto general de ' + gastoGeneral.tipoDeGastoGeneral.concepto + ' ha sido actualizado con éxito.');
          this.buscarGastoGeneral();
        }
      }
    );
  }

  eliminarGastoGeneral(gastoGeneralEliminar: GastoGeneral) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el gasto general de ' + gastoGeneralEliminar.tipoDeGastoGeneral.concepto + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarGastoGeneralSubscripcion = this._gastosGeneralesService.eliminarGastoGeneral(this._idRestaurante, gastoGeneralEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionGasto = this.dataSource.data.findIndex((gastoGeneral: GastoGeneral) => {
                return gastoGeneral._id === gastoGeneralEliminar._id;
              });
              let gastosGeneralesActualizados: GastoGeneral[] = this.dataSource.data;
              if (posicionGasto != -1) {
                gastosGeneralesActualizados.splice(posicionGasto, 1);
              }
              this.inicializarGastosGenerales(gastosGeneralesActualizados);
              this.buscarGastoGeneral();
              this._alertasService.alertaExitoSinConfirmacion('Gasto general eliminado exitosamente', 'El gasto general de ' + gastoGeneralEliminar.tipoDeGastoGeneral.concepto + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerGastosGeneralesSubscripcion) this.obtenerGastosGeneralesSubscripcion.unsubscribe();
    if (this.eliminarGastoGeneralSubscripcion) this.eliminarGastoGeneralSubscripcion.unsubscribe();
  }
}
