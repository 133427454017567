import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {InventoryAddInputComponent} from '../inventory-add-input/inventory-add-input.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {InventoryService} from '../../../../../../core/services/inventory.service';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {Insumo} from 'src/app/shared/models/insumo';
import {Proveedor} from 'src/app/shared/models/proveedor';
import {ProviderService} from 'src/app/core/services/provider.service';

@Component({
  selector: 'app-inventory-add-order',
  templateUrl: './inventory-add-order.component.html'
})
export class InventoryAddOrderComponent implements OnInit, OnDestroy {

  disableBtn: boolean;
  form: FormGroup;
  requestState: number;
  requestInputsState: RequestState;
  requestProvidersState: RequestState;
  dataSubscription: Subscription;
  formSubscription: Subscription;
  timeout: number;
  title = 'Agregar Orden Insumo';
  insumos: Insumo[];
  insumoSeleccionado: Insumo;
  proveedores: Proveedor[];
  @ViewChild('dialogWarning', {static: true}) private dialogWarning: SwalComponent;

  constructor(public dialogRef: MatDialogRef<InventoryAddInputComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private inventoryService: InventoryService,
              private providerService: ProviderService) {
    this.form = new FormGroup(
      {
        id: new FormControl(''),
        nombre: new FormControl(''),
        activo: new FormControl(''),
        nombreProveedor: new FormControl(''),
        idInsumo: new FormControl('', Validators.required),
        fecha: new FormControl(''),
        idUsuario: new FormControl(''),
        idProveedor: new FormControl('', Validators.required),
        precio: new FormControl('0.00', [Validators.required, Validators.pattern('^[0-9]+[.][0-9][0-9]$')]),
        cantidad: new FormControl(0, Validators.required)
      }
    );
  }

  ngOnInit(): void {
    this.disableBtn = true;
    this.requestState = RequestState.initial;
    this.formSubscription = this.form.valueChanges.subscribe(
      () => {
        this.disableBtn = !this.form.valid;
        this.insumoSeleccionado = this.insumos.find(element => {
          return (element.id === this.form.get('idInsumo').value);
        });
      }
    );
    this.requestInputsState = RequestState.loading;
    this.inventoryService.obtenerInsumos().subscribe(
      (res) => {
        this.insumos = res;
        this.requestInputsState = RequestState.success;
      }
    );
    this.requestProvidersState = RequestState.loading;
    this.providerService.getProviders().subscribe(
      (res) => {
        this.proveedores = res;
        ;
        this.requestProvidersState = RequestState.success;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.dataSubscription != null) {
      this.dataSubscription.unsubscribe();
    }
    this.formSubscription.unsubscribe();
  }

  add(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.dataSubscription = this.inventoryService.agregarOrden(this.form.value, this.data.idRestaurante).subscribe(
      res => {
        this.requestState = RequestState.success;
        this.form.patchValue(res[0]);
        this.dialogRef.close({ok: true, form: this.form.getRawValue()});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogWarning.show().then(
      data => {
        if (data.value) {
          this.dialogRef.close({ok: false});
        }
      }
    );
  }
}
