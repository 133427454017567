import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {HttpClient} from '@angular/common/http';
import {Cupon} from 'src/app/shared/models/coupon';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private httpClient: HttpClient) {
  }

  public getCoupons() {
    return this.httpClient.get<Cupon[]>(`${AppSettings.APIEndpoint}/cupones.php`, AppSettings.Options);
  }

  public obtenerCuponesValidos() {
    return this.httpClient.get<Cupon[]>(`${AppSettings.APIEndpoint}/cupones-validos.php`, AppSettings.Options);
  }

  public getCoupon(id: string): Observable<Cupon> {
    return this.httpClient.get<Cupon>(`${AppSettings.APIEndpoint}/cupon.php?id=${id}`, AppSettings.Options);
  }

  public agregarCupon(cupon: Cupon): Observable<any> {
    const body = {
      cupon
    };
    return this.httpClient.post<Cupon>(`${AppSettings.APIEndpoint}/cupon.php`, JSON.stringify(body), AppSettings.Options);
  }

  public borrarCupon(id: string): Observable<any> {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/cupon.php?id=${id}`, AppSettings.Options);
  }

  public editarCupon(cupon: Cupon) {
    const body = {
      cupon
    };
    return this.httpClient.put<Cupon>(`${AppSettings.APIEndpoint}/cupon.php`, JSON.stringify(body), AppSettings.Options);
  }
}
