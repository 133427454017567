import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { MatDialog } from '@angular/material';
import { VistaProductoModalComponent } from 'src/app/modules/product/vista-producto-modal/vista-producto-modal.component';
import { NuevaOrdenProductoModalComponent } from '../modales/nueva-orden-producto-modal/nueva-orden-producto-modal.component';
import { ControlInternoExistenciaDeProductoPorTamano } from 'src/app/shared/class-models/control-interno-existencia-de-producto-por-tamano.enum';

@Component({
  selector: 'app-tarjeta-orden-producto',
  templateUrl: './tarjeta-orden-producto.component.html',
  styleUrls: ['./tarjeta-orden-producto.component.scss']
})
export class TarjetaOrdenProductoComponent implements OnInit {
  @Input() producto: Producto;
  @Input() _idRestaurante: string;
  @Output() orden = new EventEmitter<Orden>();
  @Input() controlInternoExistenciasDeProductosPorTamano: ControlInternoExistenciaDeProductoPorTamano[];
  AppSettings = AppSettings;

  constructor(public modal: MatDialog) {}

  ngOnInit(): void {}

  administracionDeExistenciasHabilitado(): boolean {
    if (this.producto.existencia != null && this.producto.existencia.activo == true) {
      return true;
    } else {
      return false;
    }
  }

  obtenerExistenciasRestantes(): number {
    return this.producto.totalDeExistencias - this.obtenerExistenciasUtilizadas();
  }

  obtenerExistenciasUtilizadas(): number {
    return this.controlInternoExistenciasDeProductosPorTamano
      .filter(controlInternoExistenciaDeProductoPorTamano => controlInternoExistenciaDeProductoPorTamano._idProducto == this.producto._id)
      .map(controlInternoExistenciaDeProductoPorTamano => controlInternoExistenciaDeProductoPorTamano.cantidad)
      .reduce((acomulador, cantidad) => { return acomulador + cantidad}, 0);
  }

  verProducto() {
    const dialogRef = this.modal.open(VistaProductoModalComponent,
      {
        disableClose: true,
        data: this.producto
      });
  }

  agregarOrden(): void {
    const dialogRef = this.modal.open(NuevaOrdenProductoModalComponent,
      {
        disableClose: true,
        data: {
          _idRestaurante: this._idRestaurante,
          producto: this.producto,
          controlInternoExistenciasDeProductoPorTamano: this.controlInternoExistenciasDeProductosPorTamano.filter(controlInternoExistenciaDeProductoPorTamano => controlInternoExistenciaDeProductoPorTamano._idProducto == this.producto._id)
        }
      });
    dialogRef.afterClosed().subscribe(
      (orden: Orden) => {
        if (orden) {
          this.orden.emit(orden);
        }
      }
    );
  }
}
