import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule, MatCardModule, MatInputModule, MatPaginatorModule, MatProgressBarModule} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {IngredientsComponent} from './ingredients.component';
// Components.
import {IngredientCardComponent} from './components/ingredient-card/ingredient-card.component';
// Pages.
import {NewIngredientComponent} from './pages/new-ingredient/new-ingredient.component';
// Routes.
import {IngredientsRoutes} from './ingredients.routes';

import {DirectivesModule} from 'src/app/core/directives/directives.module';

@NgModule({
  declarations: [
    IngredientsComponent,
    NewIngredientComponent,
    IngredientCardComponent
  ],
  imports: [
    RouterModule.forRoot(IngredientsRoutes),
    CommonModule,
    FormsModule,
    ImageCropperModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    SweetAlert2Module,
    DirectivesModule
  ],
  exports: [
    IngredientsComponent,
    NewIngredientComponent,
    IngredientCardComponent
  ]
})
export class IngredientsModule {
}
