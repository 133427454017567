import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Cupon } from 'src/app/shared/class-models/cupon.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs/internal/Subscription';
import { CuponesService } from 'src/app/core/servicios/cupones.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { AgregarCuponConfiguracionComponent } from './agregar-cupon-configuracion/agregar-cupon-configuracion.component';
import { EditarCuponConfiguracionComponent } from './editar-cupon-configuracion/editar-cupon-configuracion.component';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { FechaPipe } from 'src/app/shared/pipes/fecha.pipe';

@Component({
  selector: 'app-cupones-configuracion',
  templateUrl: './cupones-configuracion.component.html',
  styleUrls: ['./cupones-configuracion.component.scss']
})
export class CuponesConfiguracionComponent implements OnInit, OnDestroy {
  @Output() peticionObtenerCuponesFinalizada = new EventEmitter<boolean>();
  @Input() estadoPeticiones: number;
  @ViewChild('paginatorCupones', { read: MatPaginator, static: false }) paginatorCupones: MatPaginator;
  dataSource: MatTableDataSource<Cupon>;
  columnasTabla: string[] = ['nombre', 'descuento', 'validez', 'opciones'];
  TipoCupon = TipoCupon;
  estadoPeticion: number;
  RequestState = RequestState;
  obtenerCuponesSubscription: Subscription;
  eliminarCuponSubscription: Subscription;
  filtro: string = '';

  constructor(
    private _cuponesService: CuponesService,
    private _alertasService: AlertasService,
    private fechaPipe: FechaPipe,
    public dialog: MatDialog
  ) {
    this.estadoPeticion = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCupones();
  }

  obtenerCupones() {
    this.estadoPeticion = RequestState.loading;
    this.obtenerCuponesSubscription = this._cuponesService.obtenerCupones().subscribe(
      (cupones: Cupon[]) => {
        this.inicializarCupones(cupones);
        this.estadoPeticion = RequestState.success;
        this.peticionObtenerCuponesFinalizada.emit(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticion = RequestState.error;
        this.peticionObtenerCuponesFinalizada.emit(true);
      }
    );
  }

  inicializarCupones(cupones: Cupon[]): void {
    this.dataSource = new MatTableDataSource<Cupon>(cupones);
    this.dataSource.paginator = this.paginatorCupones;
    this.dataSource.filterPredicate = (cupon: Cupon, filtro: string) => {
      return cupon.nombre.trim().toLowerCase().indexOf(filtro) !== -1 ||
        (cupon.tipo == TipoCupon.Cantidad ? ('$' + Number(cupon.descuentoCantidad).toFixed(2)).trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
        (cupon.tipo == TipoCupon.Porcentaje ? (String(cupon.descuentoPorcentaje) + '%').trim().toLowerCase().indexOf(filtro) !== -1 : false) ||
        this.obtenerValidezCupon(cupon).trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerValidezCupon(cupon).trim().replace(/\s/g, '').toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarCupon(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerValidezCupon(cupon: Cupon): string {
    if (cupon.fechaInicio == cupon.fechaFin) {
      return this.fechaPipe.transform(cupon.fechaInicio as Date);
    } else {
      return this.fechaPipe.transform(cupon.fechaInicio as Date) + ' - ' + this.fechaPipe.transform(cupon.fechaFin as Date);
    }
  }

  agregarCupon() {
    const dialogRef = this.dialog.open(AgregarCuponConfiguracionComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (cupon: Cupon) => {
        if (cupon != null) {
          this._alertasService.alertaExitoSinConfirmacion('Cupón agregado exitosamente', 'El cupón ' + cupon.nombre + ' ha sido agregado con éxito.');
          this.dataSource.data.push(cupon);
          const cupones: Cupon[] = this.dataSource.data;
          this.inicializarCupones(cupones);
          this.buscarCupon();
        }
      }
    );
  }

  actualizarCupon(cupon: Cupon) {
    const dialogRef = this.dialog.open(EditarCuponConfiguracionComponent, { disableClose: true, data: cupon });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Cupón actualizado exitosamente', 'El cupón ' + cupon.nombre + ' ha sido actualizado con éxito.');
          this.buscarCupon();
        }
      }
    );
  }

  eliminarCupon(cuponEliminar: Cupon) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el cupón ' + cuponEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarCuponSubscription = this._cuponesService.eliminarCupon(cuponEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionCupon = this.dataSource.data.findIndex((cupon: Cupon) => {
              return cupon._id === cuponEliminar._id;
            });
            let cuponesActualizados: Cupon[] = this.dataSource.data;
            if (posicionCupon != -1) {
              cuponesActualizados.splice(posicionCupon, 1);
            }
            this.inicializarCupones(cuponesActualizados);
            this.buscarCupon();
            this._alertasService.alertaExitoSinConfirmacion('Cupón eliminado exitosamente', 'El cupón ' + cuponEliminar.nombre + ' ha sido eliminado con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy() {
    if (this.obtenerCuponesSubscription) this.obtenerCuponesSubscription.unsubscribe();
    if (this.eliminarCuponSubscription) this.eliminarCuponSubscription.unsubscribe();
  }
}
