import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ComandaService} from '../../../../../../core/services/comanda.service';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';

@Component({
  selector: 'app-delete-comanda',
  templateUrl: './delete-comanda.component.html',
  styleUrls: []
})
export class DeleteComandaComponent implements OnInit, OnDestroy {

  titulo = 'Cerrar comanda';
  disableBtn: boolean;
  requestState: number;
  timeout: number;
  subscription: Subscription;
  idComanda: string;

  constructor(private route: ActivatedRoute,
              private comandaService: ComandaService,
              public dialogRef: MatDialogRef<DeleteComandaComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) {
    this.disableBtn = true;
    this.idComanda = this.data;
  }

  ngOnInit(): void {
    this.requestState = RequestState.initial;
    this.disableBtn = false;
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  cerrarComanda(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.subscription = this.comandaService.eliminarComanda(this.idComanda).subscribe(
      (res: boolean) => {
        this.requestState = RequestState.success;
        this.dialogRef.close({ok: true});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogRef.close({ok: false});
  }
}
