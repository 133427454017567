import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html'
})
export class EditEmployeeComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('direccionForm') direccionForm: FormGroup;
  titulo = 'Actualizar empleado';
  RequestState = RequestState;
  empleado: Usuario;
  tipoUsuario: number;
  stepActual: number = 0;

  /* VARIABLES PARA OBTENER EL EMPLEADO */
  estadoPeticionObtenerEmpleado: number;
  fotoActualEmpelado: string;
  obtenerEmpleadoSubscription: Subscription;
  restaurantesSubscripcion: Subscription;
  restaurantes: Restaurante[];
  ciudadesSubscripcion: Subscription;
  estadosSubscripcion: Subscription;
  ciudades: Ciudad[];
  estados: Estado[];
  codigoPostal: string = '';
  AppSettings = AppSettings;

  /* VARIABLES PARA ACTUALIZAR EL EMPLEADO */
  @ViewChild('empleadoForm') empleadoForm: FormGroup;
  ExpresionesRegulares = ExpresionesRegulares;
  TipoUsuario = TipoUsuario;
  estadoPeticionActualizarEmpleado: number;
  actualizarEmpleadoSubscription: Subscription;
  eventoCambioImagenFotografia: any = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: Usuario,
    private modal: MatDialogRef<EditEmployeeComponent>,
    private _authService: AuthService,
    private _usuariosService: UsuariosService,
    private _ubicacionService: UbicacionService,
    private _restaurantesService: RestaurantesService,
    private _alertasService: AlertasService) {
    this.estadoPeticionObtenerEmpleado = RequestState.initial;
    this.estadoPeticionActualizarEmpleado = RequestState.initial;
    this.tipoUsuario = this._authService.obtenerTipoUsuario();
  }

  ngOnInit(): void {
    this.obtenerEmpleado();

  }

  obtenerEmpleado(): void {
    this.estadoPeticionObtenerEmpleado = RequestState.loading;
    this.obtenerEmpleadoSubscription = this._usuariosService.obtenerUsuario(this.data._id).subscribe(
      (empleado: Usuario) => {
        this.fotoActualEmpelado = empleado.foto;
        empleado.foto = '';
        this.codigoPostal = empleado.codigoPostal.codigo;
        this.empleado = empleado;
        this.obtenerRestaurantes();
        this.obtenerEstados();
        this.obtenerCiudades(this.empleado.idEstado);
        this.estadoPeticionObtenerEmpleado = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.modal.close();
      }
    );
  }

  obtenerRestaurantes(): void {
    this.restaurantesSubscripcion = this._restaurantesService.obtenerRestaurantes().subscribe(
      (restaurantes: Restaurante[]) => {
        this.restaurantes = restaurantes;
        if (this.restaurantes.length == 0) {
          this._alertasService.alertaErrorSinConfirmacion('Actualmente no se tienen restaurantes registrados', 'Favor de agregar un restaurante para poder dar de alta sus empelados');
          this.modal.close();
        }
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.modal.close();
      }
    );
  }

  obtenerEstados(): void {
    this.estados = [];
    this.estadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerCiudades(idEstado: number): void {
    this.ciudades = [];
    this.ciudadesSubscripcion = this._ubicacionService.obtenerCiudades(idEstado).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerTipoUsuario(tipo: number): string {
    switch (tipo) {
      case TipoUsuario.Vendedor: return 'Vendedor';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
      default: return '';
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(event: ImageCroppedEvent): void {
    this.empleado.foto = event.base64;
  }

  imagenCargada(): void { }

  cortadorListo(): void { }

  cargarImagenFallida(): void { }

  async actualizarEmpleado() {
    this.estadoPeticionActualizarEmpleado = RequestState.loading;
    const codigoPostal = await this.verificarCodigoPostal(this.empleado.idEstado, this.codigoPostal);
    if (!codigoPostal) {
      this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
      this.estadoPeticionActualizarEmpleado = RequestState.initial;
      this.empleadoForm.controls['codigoPostal1'].setErrors({ invalido: true });
    } else {
      this.empleado.idCodigoPostal = codigoPostal.id;
      this._usuariosService.actualizarUsuario(this.data._id, this.empleado).subscribe(
        (empleado: Usuario) => {
          this.estadoPeticionActualizarEmpleado = RequestState.success;
          this.actualizarEmpleadoOriginal(empleado);
          this.modal.close(true);
        },
        (error: HttpErrorResponse) => {
          this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          this.estadoPeticionActualizarEmpleado = RequestState.error;
        }
      );
    }
  }

  async validarCodigoPostal() {
    if (this.codigoPostal.length >= 5) {
      const codigoPostal = await this.verificarCodigoPostal(this.empleado.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      }
    }
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  actualizarEmpleadoOriginal(empleado: Usuario) {
    this.data.tipo = empleado.tipo;
    this.data._idRestaurante = empleado._idRestaurante;
    this.data.restaurante = empleado.restaurante;
    this.data.nombreUsuario = empleado.nombreUsuario;
    this.data.nombres = empleado.nombres;
    this.data.primerApellido = empleado.primerApellido;
    this.data.segundoApellido = empleado.segundoApellido;
    this.data.idEstado = empleado.idEstado;
    this.data.estado = empleado.estado;
    this.data.idCiudad = empleado.idCiudad;
    this.data.ciudad = empleado.ciudad;
    this.data.calle = empleado.calle;
    this.data.colonia = empleado.colonia;
    this.data.numeroExterior = empleado.numeroExterior;
    this.data.numeroInterior = empleado.numeroInterior;
    this.data.idCodigoPostal = empleado.idCodigoPostal;
    this.data.codigoPostal = empleado.codigoPostal;
    this.data.telefono = empleado.telefono;
    this.data.correo = empleado.correo;
    this.data.foto = empleado.foto;
  }

  retroceder() {
    this.stepActual--;
    this.stepper.previous();
  }

  avanzar() {
    this.stepActual++;
    this.stepper.next();
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close(false);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.ciudadesSubscripcion) this.ciudadesSubscripcion.unsubscribe();
    if (this.estadosSubscripcion) this.estadosSubscripcion.unsubscribe();
    if (this.restaurantesSubscripcion) this.restaurantesSubscripcion.unsubscribe();
    if (this.obtenerEmpleadoSubscription) this.obtenerEmpleadoSubscription.unsubscribe();
    if (this.actualizarEmpleadoSubscription) this.actualizarEmpleadoSubscription.unsubscribe();
  }
}
