import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';

@Injectable({
  providedIn: 'root'
})
export class RestaurantesService {
  private rutaBaseRestaurantes = '/restaurantes';

  constructor(private httpClient: HttpClient) { }

  /* GET */
  obtenerRestaurantes(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}`, AppSettings.Options);
  }

  obtenerRestaurante(_idRestaurante: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}`, AppSettings.Options);
  }

  /* POST */
  agregarRestaurante(restaurante: Restaurante): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}`, JSON.stringify(restaurante), AppSettings.Options);
  }

  /* PUT */
  actualizarRestaurante(_idRestaurante: string, restaurante: Restaurante): Observable<any> {
    return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}`, JSON.stringify(restaurante), AppSettings.Options);
  }

  /* PATCH */
  /*actualizarConfiguracionRestaurante(idRestaurante: string, restaurante: Restaurante): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}/sucursal.php?idRestaurante=${idRestaurante}`, JSON.stringify(restaurante), AppSettings.Options);
  }*/
  atualizarContadorDeGrupoDeOrdenesRestaurante(_idRestaurante: string, contadorDeGrupoDeOrdenes: number): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}/contador-de-grupo-de-ordenes`, JSON.stringify({contadorDeGrupoDeOrdenes}), AppSettings.Options);
  }

  eliminarRestaurante(_idRestaurante: string): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}`, AppSettings.Options);
  }
}