import {Routes} from '@angular/router';

import {ClientRoutes} from './client/client.routes';
import {ClientsRoutes} from './clients/clients.routes';
import {EmployeeRoutes} from './employee/employee.routes';
import {EmployeesRoutes} from './employees/employees.routes';
import {HomeRoutes} from './home/home.routes';
import {IngredientRoutes} from './ingredient/ingredient.routes';
import {IngredientsRoutes} from './ingredients/ingredients.routes';
import {KDSRoutes} from './kds/kds.routes';
import {KioskRoutes} from './kiosk/kioskRoutes';
import {PromoRoutes} from './promo/promo.routes';
import {PromosRoutes} from './promos/promos.routes';
import {ProductRoutes} from './product/product.routes';
import {ProductsRoutes} from './products/products.routes';
import {RestaurantRoutes} from './restaurant/restaurant.routes';
import {RestaurantsRoutes} from './restaurants/restaurants.routes';
import {ConfigurationRoutes} from './configuration/configuration.routes';
import {AtLeastManagerGuard} from '../core/guards/at-least-manager';
import {AtLeastWaiterGuard} from '../core/guards/at-least-waiter';
import {ProviderRoutes} from './provider/provider.routes';
import {ProvidersRoutes} from './providers/providers.routes';
import {UsersRoutes} from './users/users.routes';
import {CookGuard} from '../core/guards/cook.guard';
import {AtLeastBarGuard} from '../core/guards/at-least-bar';
import {LicensesRoutes} from './licenses/licenses.routes';
import {AtLeastSalesGuard} from '../core/guards/at-least-sales.guard';

export const ModulesRoutes: Routes = [
  {
    path: 'inicio',
    data: {
      breadcrumb: 'Inicio'
    },
    children: HomeRoutes
  },
  {
    path: 'clientes',
    data: {
      breadcrumb: 'Clientes'
    },
    children: ClientsRoutes,
    canActivate: [AtLeastBarGuard]
  },
  {
    path: 'clientes/:idCliente',
    data: {
      breadcrumb: 'Clientes'
    },
    children: ClientRoutes,
    canActivate: [AtLeastBarGuard]
  },
  {
    path: 'cocina',
    data: {
      breadcrumb: 'Cocina'
    },
    children: KDSRoutes,
    canActivate: [CookGuard]
  },
  {
    path: 'empleados',
    data: {
      breadcrumb: 'Empleados'
    },
    children: EmployeesRoutes,
    canActivate: [AtLeastManagerGuard],
  },
  {
    path: 'empleados/:idEmpleado',
    data: {
      breadcrumb: 'Empleados'
    },
    children: EmployeeRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'proveedores',
    data: {
      breadcrumb: 'Proveedores'
    },
    children: ProvidersRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'proveedores/:idProveedor',
    data: {
      breadcrumb: 'Proveedores'
    },
    children: ProviderRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'ingredientes',
    data: {
      breadcrumb: 'Ingredientes'
    },
    children: IngredientsRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'ingredientes/:idIngrediente',
    data: {
      breadcrumb: 'Ingredientes'
    },
    children: IngredientRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'menu',
    data: {
      breadcrumb: 'Menu'
    },
    children: KioskRoutes
  },
  {
    path: 'productos',
    data: {
      breadcrumb: 'Productos'
    },
    children: ProductsRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'productos/:idProducto',
    data: {
      breadcrumb: 'Productos'
    },
    children: ProductRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'paquetes',
    data: {
      breadcrumb: 'Paquetes'
    },
    children: PromosRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'paquetes/:idPaquete',
    data: {
      breadcrumb: 'Paquetes'
    },
    children: PromoRoutes,
    canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'restaurantes',
    data: {
      breadcrumb: 'Restaurantes'
    },
    children: RestaurantsRoutes,
    canActivate: [AtLeastWaiterGuard]
  },
  {
    path: 'restaurantes/:idRestaurante',
    data: {
      breadcrumb: 'Restaurantes'
    },
    children: RestaurantRoutes,
    canActivate: [AtLeastWaiterGuard]
  },
  {
    path: 'usuarios',
    data: {
      breadcrumb: 'Usuarios'
    },
    children: UsersRoutes,
    canActivate: [AtLeastSalesGuard]
  },
  {
    path: 'configuracion',
    data: {
      breadcrumb: 'Configuración'
    },
    children: ConfigurationRoutes
  },
  {
    path: 'licencias',
    data: {
      breadcrumb: 'Licencias'
    },
    children: LicensesRoutes,
    // canActivate: [AtLeastManagerGuard]
  },
  {
    path: 'licencias/:idLicencia',
    data: {
      breadcrumb: 'Licencias'
    },
    children: LicensesRoutes,
    // canActivate: [AtLeastManagerGuard]
  }
];
