import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {Subscription} from 'rxjs';
import {InventoryService} from '../../../../../../core/services/inventory.service';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {InsumoOrdenado} from '../../../../../../shared/models/insumo-ordenado';
import {InventoryAddOrderComponent} from '../../components/inventory-add-order/inventory-add-order.component';
import {InventoryEditOrderComponent} from '../../components/inventory-edit-order/inventory-edit-order.component';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html'
})
export class StockComponent implements OnInit, OnDestroy {

  @Output() requestStateValue = new EventEmitter<number>();
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  columns = ['ID', 'Nombre', 'Precio', 'Cantidad', 'Fecha', 'Activo', 'Opciones'];
  dataSource: MatTableDataSource<InsumoOrdenado>;
  noResults: boolean;
  requestState: number;
  subscription: Subscription;
  idRestaurante: string;
  @ViewChild('dialogAddSuccess', {static: true}) private dialogAddSuccess: SwalComponent;
  @ViewChild('dialogAddError', {static: true}) private dialogAddError: SwalComponent;
  @ViewChild('dialogEditSuccess', {static: true}) private dialogEditSuccess: SwalComponent;
  @ViewChild('dialogEditError', {static: true}) private dialogEditError: SwalComponent;
  @ViewChild('dialogCancelSuccess', {static: true}) private dialogCancelSuccess: SwalComponent;
  @ViewChild('dialogCancelError', {static: true}) private dialogCancelError: SwalComponent;

  constructor(private route: ActivatedRoute,
              private inventoryService: InventoryService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.requestState = RequestState.loading;
    this.requestStateValue.emit(RequestState.loading);
    this.idRestaurante = this.route.parent.parent.parent.parent.snapshot.paramMap.get('idRestaurante');
    this.subscription = this.inventoryService.obtenerInventario(this.idRestaurante).subscribe(
      (data: InsumoOrdenado[]) => {
        if (data.length === 0) {
          this.noResults = true;
        }
        this.dataSource = new MatTableDataSource<InsumoOrdenado>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.requestState = RequestState.success;
        this.requestStateValue.emit(RequestState.success);
      },
      () => {
        this.requestState = RequestState.error;
        this.requestStateValue.emit(RequestState.error);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    this.subscription.unsubscribe();
  }

  addOrder(): void {
    const dialogRef = this.dialog.open(InventoryAddOrderComponent, {disableClose: true, data: {idRestaurante: this.idRestaurante}});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          const data = this.dataSource.data;
          data.push(result.form);
          this.dataSource.data = data;
          this.dialogAddSuccess.show();
        }
      }
    );
  }

  editOrder(id: string): void {
    const dialogRef = this.dialog.open(InventoryEditOrderComponent, {disableClose: true, data: {id}});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          this.dialogEditSuccess.show();
          this.ngOnInit();
        }
      }
    );
  }

  cancelOrder(id: string): void {
    this.inventoryService.eliminarOrden(id).subscribe(
      (res) => {
        if (res.status === 'OK') {
          this.dataSource.data.forEach(element => {
            if (element.id === id) {
              element.activo = false;
            }
          });
          this.dialogCancelSuccess.show();
        }
      }
    );
  }
}
