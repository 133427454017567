import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddStockItemComponent} from './components/add-stock-item/add-stock-item.component';
import {EditStockItemComponent} from './components/edit-stock-item/edit-stock-item.component';
import {StockComponent} from './stock.component';
import {MatTabsModule} from '@angular/material/tabs';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatStepperModule,
  MatTableModule
} from '@angular/material';
import {StockRoutes} from './stock.routes';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';


@NgModule({
  declarations: [StockComponent, AddStockItemComponent, EditStockItemComponent],
  imports: [
    RouterModule.forRoot(StockRoutes),
    CommonModule,
    MatCardModule,
    MatStepperModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    ReactiveFormsModule,
    SweetAlert2Module
  ],
  exports: [
    StockComponent,
    AddStockItemComponent,
    EditStockItemComponent
  ]
})
export class StockModule {
}
