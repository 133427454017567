import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: []
})
export class RootComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}

