import { Component, OnDestroy, OnInit } from '@angular/core';;
import { RequestState } from '../../../../../../shared/enums/request-state.enum';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementosPorAreaService } from 'src/app/core/servicios/elementos-por-area.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialog } from '@angular/material';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { ResumenGrupoDeOrdenesComponent } from './resumen-grupo-de-ordenes/resumen-grupo-de-ordenes.component';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { TipoImpresion } from 'src/app/shared/enums/tipo-impresion.enum';
import { ImpresionService } from 'src/app/core/servicios/impresion.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnInit, OnDestroy {
  /* VARIABLES GENERALES */
  RequestState = RequestState;
  _idRestaurante: string;
  _idArea: string;
  _idElementoPorArea: string;
  _idComanda: string;
  contadorPeticionesRequeridas: number;
  contadorPeticionesFinalizadas: number;
  estadoPeticiones: number;

  /* VARIABLES PARA OBTENER EL ELEMENTO POR AREA */
  elementoPorArea: ElementoPorArea;
  estadoPeticionObtenerElementoPorArea: number;
  obtenerElementoPorAreaSubscription: Subscription;

  /* VARIABLES PARA OBTENER EL TOTAL DE ORDENES SIN PAGAR */
  estadoPeticionObtenerTotalDeOrdenesSinPagar: number;
  obtenerTotalDeOrdenesSinPagarSubscription: Subscription;
  totalSinPagarDeOrdenes: number;

  /* VARIABLES DE HABILITAR CONFIGURACIÓN */
  configuracionHabilitadaPago: boolean;

  constructor(
    private _authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private _elementosPorAreaService: ElementosPorAreaService,
    private _ordenesService: OrdenesService,
    private _impresionService: ImpresionService,
    private _alertasService: AlertasService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this._idArea = this.activatedRoute.parent.snapshot.paramMap.get('idArea');
    this._idElementoPorArea = this.activatedRoute.parent.snapshot.paramMap.get('idMesa');
    this.contadorPeticionesFinalizadas = 0;
    this.contadorPeticionesRequeridas = 2;
    this.estadoPeticiones = RequestState.initial;
    this.estadoPeticionObtenerElementoPorArea = RequestState.initial;
    this.estadoPeticionObtenerTotalDeOrdenesSinPagar = RequestState.initial;
    this.configuracionHabilitadaPago = this.habilitarConfiguracionPago();
  }

  /* MÉTODOS DE HABILITAR CONFIGURACIÓN */
  habilitarConfiguracionPago(): boolean {
    switch (this._authService.obtenerTipoUsuario()) {
      case TipoUsuario.Administrador:
      case TipoUsuario.Gerente:
      case TipoUsuario.Cajero:
      case TipoUsuario.Barman:
        return true;
      default:
        return false;
    }
  }

  ngOnInit(): void {
    this.obtenerElementoPorArea();
  }

  incrementarContadorPeticionesFinalizadas(): void {
    this.contadorPeticionesFinalizadas++;
    if (this.contadorPeticionesFinalizadas == this.contadorPeticionesRequeridas) this.estadoPeticiones = RequestState.success;
  }

  /* MÉTODOS PARA OBTENER EL ELEMENTO POR AREA */
  obtenerElementoPorArea(): void {
    this.estadoPeticionObtenerElementoPorArea = RequestState.loading;
    this.obtenerElementoPorAreaSubscription = this._elementosPorAreaService.obtenerElementoPorArea(this._idRestaurante, this._idArea, this._idElementoPorArea).subscribe(
      (elementoPorArea: ElementoPorArea) => {
        this.elementoPorArea = elementoPorArea;
        this._idComanda = this.elementoPorArea._idComanda;
        this.estadoPeticionObtenerElementoPorArea = RequestState.success;
        this.incrementarContadorPeticionesFinalizadas();
        this.obtenerTotalSinPagarDeOrdenesComanda();
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerElementoPorArea = RequestState.error;
        this.router.navigate(['/restaurantes/' + this._idRestaurante + '/areas']);
      }
    );
  }

  /* MÉTODOS PARA OBTENER EL TOTAL DE ORDENES SIN PAGAR */
  obtenerTotalSinPagarDeOrdenesComanda(): void {
    this.estadoPeticionObtenerTotalDeOrdenesSinPagar = RequestState.loading;
    this.obtenerElementoPorAreaSubscription = this._ordenesService.obtenerTotalSinPagarDeOrdenesComanda(this._idRestaurante, this._idArea, this._idElementoPorArea, this._idComanda).subscribe(
      (totalSinPagarDeOrdenes: number) => {
        this.totalSinPagarDeOrdenes = totalSinPagarDeOrdenes;
        this.estadoPeticionObtenerTotalDeOrdenesSinPagar = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerTotalDeOrdenesSinPagar = RequestState.error;
        this.totalSinPagarDeOrdenes = null;
      }
    );
  }

  /* MÉTODOS PARA EL RESUMEN DE GRUPO DE ORDENES */
  verGruposDeOrdenes(): void {
    const dialogRef = this.dialog.open(ResumenGrupoDeOrdenesComponent,
      {
        width: '80%',
        disableClose: true,
        data: {
          _idRestaurante: this._idRestaurante,
          _idArea: this._idArea,
          _idElementoPorArea: this._idElementoPorArea,
          comanda: this.elementoPorArea.comanda,
        }
      });
    dialogRef.afterClosed().subscribe(
      async (grupoDeOrdenes: GrupoDeOrdenes) => {
        if (grupoDeOrdenes) {
          this._impresionService.actualizarTipoImpresion(TipoImpresion.GrupoDeOrdenes);
          await this._impresionService.actualizarGrupoDeOrdenes(this._idRestaurante, this._idArea, this._idElementoPorArea, this._idComanda, grupoDeOrdenes._id);
          await this._impresionService.actualizarOrdenesGrupoDeOrdenes(this._idRestaurante, this._idArea, this._idElementoPorArea, this._idComanda, grupoDeOrdenes._id);
          window.print();
        }
      }
    );
  }


  ngOnDestroy() {
    if (this.obtenerElementoPorAreaSubscription) this.obtenerElementoPorAreaSubscription.unsubscribe();
    if (this.obtenerTotalDeOrdenesSinPagarSubscription) this.obtenerTotalDeOrdenesSinPagarSubscription.unsubscribe();
  }

  /*@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  borrarDatosMenu: number;
  userSubscription: Subscription;
  elementByAreaSubscription: Subscription;
  ordersAmountSubscription: Subscription;
  newOrder: Subscription;
  ordersGroupSubscription: Subscription;
  titulo = 'Mesa';
  elementoArea: ElementoArea;
  consumoTotal: number;
  requestState: number;
  habilitarPago: boolean;
  newOrderRequestState: number;
  ordersGroupRequest: number;
  totalRequestState: number;
  idRestaurante: string;
  ordenesFallidas: Array<any>;
  ordenesRealizadas: Array<any>;
  @ViewChild('dialogAddSuccess', {static: false}) private dialogAddSuccess: SwalComponent;
  @ViewChild('dialogAddFailed', {static: false}) private dialogAddFailed: SwalComponent;

  constructor(private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef,
              private orderService: OrderService,
              private userService: UserService,
              private elementByAreaService: ElementsByAreaService,
              public dialog: MatDialog,
              private ordersGroupService: OrdersGroupService) {
    this.borrarDatosMenu = 1;
    this.ordenesFallidas = new Array;
    this.ordenesRealizadas = new Array;
  }

  mandarACocina(ordenes: Orden[]): void {
    this.ordersGroupRequest = RequestState.loading;
    this.ordersGroupSubscription = this.ordersGroupService.nuevoGrupoDeOrdenes(this.idRestaurante, this.elementoArea.comanda.id).subscribe(
      (ordersGroupRes: any) => {
        this.ordersGroupRequest = RequestState.success;
        let contador = 0;
        let flag = true;
        this.newOrderRequestState = RequestState.loading;
        ordenes.forEach((orden) => {
          orden.idGrupoOrdenes = ordersGroupRes.result.id;
          this.newOrder = this.orderService.nuevaOrden(orden, this.elementoArea.comanda.id, this.idRestaurante).subscribe(
            (res) => {
              contador++;
              this.ordenesRealizadas.push(orden);
              if (contador === ordenes.length) {
                this.newOrderRequestState = RequestState.success;
                if (this.ordenesFallidas.length === 0) {
                  this.dialogAddSuccess.show().then((resDialog) => {
                    this.borrarDatosMenu++;
                    this.totalRequestState = RequestState.loading;
                    this.ordersAmountSubscription = this.orderService.obtenerTotalOrdenesSinPagarComanda(this.elementoArea.comanda.id).subscribe(
                      (total: any) => {
                        this.consumoTotal = total;
                        this.totalRequestState = RequestState.success;
                        this.ngOnInit();
                      }
                    );
                  });
                } else {
                  this.dialogAddFailed.show().then((resDialog) => {
                    this.borrarDatosMenu++;
                    this.totalRequestState = RequestState.loading;
                    this.ordersAmountSubscription = this.orderService.obtenerTotalOrdenesSinPagarComanda(this.elementoArea.comanda.id).subscribe(
                      (total: any) => {
                        this.consumoTotal = total;
                        this.totalRequestState = RequestState.success;
                        this.ngOnInit();
                      }
                    );
                  });
                }
              }
            },
            (error) => {
              contador++;
              this.ordenesFallidas.push(orden);
              if (contador === ordenes.length) {
                this.newOrderRequestState = RequestState.success;
                if (this.ordenesFallidas.length === 0) {
                  this.dialogAddSuccess.show().then((resDialog) => {
                    this.borrarDatosMenu++;
                    this.totalRequestState = RequestState.loading;
                    this.ordersAmountSubscription = this.orderService.obtenerTotalOrdenesSinPagarComanda(this.elementoArea.comanda.id).subscribe(
                      (total: any) => {
                        this.consumoTotal = total;
                        this.totalRequestState = RequestState.success;
                        this.ngOnInit();
                      }
                    );
                  });
                } else {
                  this.dialogAddFailed.show().then((resDialog) => {
                    this.borrarDatosMenu++;
                    this.totalRequestState = RequestState.loading;
                    this.ordersAmountSubscription = this.orderService.obtenerTotalOrdenesSinPagarComanda(this.elementoArea.comanda.id).subscribe(
                      (total: any) => {
                        this.consumoTotal = total;
                        this.totalRequestState = RequestState.success;
                        this.ngOnInit();
                      }
                    );
                  });
                }
              }
            }
          );
        });
      },
      (error) => {
        this.ordersGroupRequest = RequestState.error;
      });
    //this.ordersAmountSubscription = this.orderService.obtenerTotalOrdenesComanda(this.elementoArea.comanda.id).subscribe(
    //  (total: any) => {
    //    this.consumoTotal = total;
    //    this.requestState = RequestState.success;
    //  }
    //);
  }


    resumenOrdenes(): void {
    let dialogRef = this.dialog.open(SummaryComponent, {
      width: '80%',
      data: this.elementoArea,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.imprimir){
        window.print();
      }
    });
  }
*/
}
