import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrdersGroupService {

  constructor(private httpClient: HttpClient) {
  }

  nuevoGrupoDeOrdenes(idSucursal: string, idComanda: string): Observable<any> {
    const body = {
      idSucursal,
      idComanda
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/grupo-ordenes.php`, JSON.stringify(body), AppSettings.Options);
  }

  terminarGrupoDeOrdenes(idGrupoOrdenes: string): Observable<any> {
    const body = {
      idGrupoOrdenes
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/grupo-ordenes.php`, JSON.stringify(body), AppSettings.Options);
  }
}
