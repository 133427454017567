import { Tamano } from './tamano.model';
import { Ingrediente } from './ingrediente.model';
import { Categoria } from './categoria.model';
import { Usuario } from './usuario.model';
import { ProductoOmitidoDeCocina } from './producto-omitido-de-cocina.model';
import { Existencia } from './existencia.model';
import { ExistenciaPorTamano } from './existencia-por-tamano.model';

export class ProductoPorTamano {
  precio: number | string;
  tamano: Tamano;
  _idTamano: string;

  constructor() {
    this.precio = '0.00';
  }
}

export class Producto {
  _id?: string;
  id?: number;
  nombre: string;
  descripcion?: string;
  foto?: string;
  _idCategoria?: string;
  categoria: Categoria;
  productoPorTamano?: ProductoPorTamano[];
  ingredientes?: Ingrediente[] | string[];
  productoOmitidoDeCocina: ProductoOmitidoDeCocina;
  _idUsuario: string;
  usuario: Usuario;
  fechaRegistro: Date;
  activo?: boolean;
  _idAccesoUsuario: string;
  //Administración de existencias
  existencia: Existencia;
  existenciasPorTamano: ExistenciaPorTamano[];
  totalDeExistencias: number;

  constructor() {
    this.ingredientes = [];
    this.productoPorTamano = [];
  }
}
