import { Producto } from './producto.model';
import { Tamano } from './tamano.model';
import { Usuario } from './usuario.model';

export class AlternativaProductosPorTamano {
    producto: Producto;
    _idProducto: string;
    tamano: Tamano;
    _idTamano: string;

    constructor() { }
}

export class PaquetePorTamano {
    precio: number | string;
    tamano: Tamano;
    _idTamano: string;
    alternativasProductosPorTamano: AlternativaProductosPorTamano[][];

    constructor() {
        this.precio = '0.00';
        this.alternativasProductosPorTamano = [];
    }
}

export class AlternativaProductos {
    descripcion: string;
    productos: Producto[] | string[];

    constructor() {
        this.productos = [];
    }
}

export class Paquete {
    _id?: string;
    id?: number;
    nombre: string;
    descripcion?: string;
    foto?: string;
    esPermanente: boolean;
    fechaInicio?: string | Date;
    fechaFin?: string | Date;
    horarioValidez: boolean;
    horaInicio?: string;
    horaFin?: string;
    diasDeLaSemana?: number[];
    paquetePorTamano?: PaquetePorTamano[];
    alternativasProductos?: AlternativaProductos[];
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.diasDeLaSemana = [];
        this.paquetePorTamano = [];
        this.alternativasProductos = [];
        this.esPermanente = false;
        this.horarioValidez = false;
    }
}