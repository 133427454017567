import {Routes} from '@angular/router';
import {RestaurantComponent} from './restaurant.component';
import {AreaRoutes} from './pages/area/area.routes';
import {AreasRoutes} from './pages/areas/areas.routes';
import {CashRegisterRoutes} from './pages/cash-register/cash-register.routes';
import {AtLeastManagerGuard} from '../../core/guards/at-least-manager';
import {KdsConfComponent} from './pages/kds-conf/kds-conf.component';
import { AdministracionGastosRoutes } from './pages/administracion-gastos/administracion-gastos.routes';
import { AdministracionInsumosRoutes } from './pages/administracion-insumos/administracion-insumos-routing.module';
import { AdministracionExistenciasRoutes } from './pages/administracion-existencias/administracion-existencias.routes';

export const RestaurantRoutes: Routes = [
  {
    path: '', children: [
      {
        path: '',
        data: {
          breadcrumb: 'Información'
        },
        component: RestaurantComponent,
        canActivate: [AtLeastManagerGuard]
      },
      {
        path: 'areas',
        data: {
          breadcrumb: 'Areas'
        },
        children: AreasRoutes
      },
      {
        path: 'areas/:idArea',
        data: {
          breadcrumb: 'Areas'
        },
        children: AreaRoutes
      },
      {
        path: 'cajas',
        data: {
          breadcrumb: 'Cajas'
        },
        children: CashRegisterRoutes,
        canActivate: [AtLeastManagerGuard]
      },
      /*{
        path: 'inventario',
        data: {
          breadcrumb: 'Inventario'
        },
        children: InventoryRoutes,
        canActivate: [AtLeastManagerGuard]
      },*/
      /*{
        path: 'insumos',
        data: {
          breadcrumb: 'Insumos'
        },
        children: InventoryRoutes,
        canActivate: [AtLeastManagerGuard]
      },*/
      {
        path: 'gastos-de-insumos',
        data: {
          breadcrumb: 'Gastos de insumos'
        },
        children: AdministracionInsumosRoutes,
        canActivate: [AtLeastManagerGuard]
      },
      {
        path: 'gastos-generales',
        data: {
          breadcrumb: 'Gastos generales'
        },
        children: AdministracionGastosRoutes,
        canActivate: [AtLeastManagerGuard]
      },
      {
        path: 'existencias',
        data: {
          breadcrumb: 'Existencias'
        },
        children: AdministracionExistenciasRoutes,
        canActivate: [AtLeastManagerGuard]
      },
      {
        path: 'productos-de-cocina',
        data: {
          breadcrumb: 'Productos de cocina'
        },
        component: KdsConfComponent,
        canActivate: [AtLeastManagerGuard]
      }
    ]
  }
];
