import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from '../authentication/auth.service';

@Injectable()
export class CookGuard implements CanActivate {
  constructor(private _authService: AuthService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this._authService.obtenerTipoUsuario() !== 6) {
      this.router.navigate(['inicio']);
      return false;
    } else {
      return true;
    }
  }
}
