import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { WebSocketsService } from './web-sockets.service';

@Injectable({
  providedIn: 'root'
})
export class CocinaService {

  constructor(private httpClient: HttpClient, private _webSocketService: WebSocketsService) { }

  /* GET */
  public obtenerOrdenesGrupoDeOrdenes(_idRestaurante: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/restaurantes/${_idRestaurante}/cocina/grupo-de-ordenes`, AppSettings.Options);
  }
  
  /* PATCH */
  public actualizarEstadoEnCocinaGrupoDeOrdenes(_idRestaurante: string, _idGrupoDeOrdenes: string, estadoEnCocina: number): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}/restaurantes/${_idRestaurante}/cocina/grupo-de-ordenes/${_idGrupoDeOrdenes}`, { estadoEnCocina }, AppSettings.Options);
  }


  /* SOCKETS */
  public obtenerNuevaOrdenEnCocina(){
    return this._webSocketService.escuchar('nueva-orden-cocina');
  }

}
