import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';


export class Area {
    _id?: string;
    id?: number;
    nombre: string;
    descripcion?: string;
    tipo: number;
    foto?: string;
    _idRestaurante: string;
    restaurante?: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor(){}
}