import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ClientModule } from './modules/client/client.module';
import { ClientsModule } from './modules/clients/clients.module';
import { EmployeeModule } from './modules/employee/employee.module';
import { EmployeesModule } from './modules/employees/employees.module';
import { HomeModule } from './modules/home/home.module';
import { IngredientModule } from './modules/ingredient/ingredient.module';
import { IngredientsModule } from './modules/ingredients/ingredients.module';
import { KdsModule } from './modules/kds/kds.module';
import { MenuModule } from './modules/restaurant/pages/area/pages/menu/menu.module';
import { ProductModule } from './modules/product/product.module';
import { ProductsModule } from './modules/products/products.module';
import { ProviderModule } from './modules/provider/provider.module';
import { ProvidersModule } from './modules/providers/providers.module';
import { PromoModule } from './modules/promo/promo.module';
import { PromosModule } from './modules/promos/promos.module';
import { RestaurantModule } from './modules/restaurant/restaurant.module';
import { RestaurantsModule } from './modules/restaurants/restaurants.module';

import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorIntl,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatStepperModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LoginComponent } from './components/login/login.component';
import { NavComponent } from './components/navigation/nav.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/authentication/auth.service';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { IngredientService } from './core/services/ingredient.service';
import { ProductService } from './core/services/product.service';
import { PromoService } from './core/services/promo.service';
import { RestaurantService } from './core/services/restaurant.service';
import { LocationService } from './core/services/location.service';
import { BillService } from './core/services/bill.service';
import { ComandaService } from './core/services/comanda.service';
import { ConfigurationModule } from './modules/configuration/configuration.module';
import { KioskModule } from './modules/kiosk/kiosk.module';
import { ManagerGuard } from './core/guards/manager.guard';
import { RootGuard } from './core/guards/root.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { CashierGuard } from './core/guards/cashier.guard';
import { BarGuard } from './core/guards/bar.guard';
import { WaiterGuard } from './core/guards/waiter.guard';
import { CookGuard } from './core/guards/cook.guard';
import { AtLeastManagerGuard } from './core/guards/at-least-manager';
import { AtLeastBarGuard } from './core/guards/at-least-bar';
import { AtLeastWaiterGuard } from './core/guards/at-least-waiter';
import { ReportsService } from './core/services/reports.service';
import { UsersModule } from './modules/users/users.module';
import { LicensesModule } from './modules/licenses/licenses.module';
import { BillPrinterComponent } from './modules/bill-printer/bill-printer.component';
import { PipesModule } from './shared/pipes/pipes.module';
import { AtLeastSalesGuard } from './core/guards/at-least-sales.guard';
import { OrdersGroupService } from './core/services/orders-group.service';
import { CustomMatPaginatorIntl } from './shared/injectables/custom-mat-paginator.init';
import { IngredientesService } from './core/servicios/ingredientes.service';

/* CONFIGURACION DEL IDIOMA DE LAS FECHAS */
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es-MX';
import { UsuarioNoAutenticadoGuard } from './core/guards/usuario-no-autenticado.guard';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { AppSettings } from './configs/app-settings.config';
import { ImpresionComponent } from './modules/impresion/impresion.component';
import { ImpresionCuentaComponent } from './modules/impresion/impresion-cuenta/impresion-cuenta.component';
import { ImpresionGrupoDeOrdenesComponent } from './modules/impresion/impresion-grupo-de-ordenes/impresion-grupo-de-ordenes.component';
registerLocaleData(localeES, 'es-MX')
const configuracionSockets: SocketIoConfig = {
  url: AppSettings.SocketsUrl,
  options: {}
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    BreadcrumbComponent,
    PasswordResetComponent,
    BillPrinterComponent,
    ImpresionComponent,
    ImpresionCuentaComponent,
    ImpresionGrupoDeOrdenesComponent
  ],
  imports: [
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      cancelButtonText: 'Cancelar',
      cancelButtonClass: 'btn btn-outline-danger ml-1',
      confirmButtonClass: 'btn btn-primary',
      confirmButtonText: 'Aceptar',
      customClass: 'modal-content'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ClientModule,
    ClientsModule,
    ConfigurationModule,
    EmployeeModule,
    EmployeesModule,
    HomeModule,
    IngredientModule,
    IngredientsModule,
    KdsModule,
    KioskModule,
    MenuModule,
    ProductModule,
    ProductsModule,
    ProviderModule,
    ProvidersModule,
    PromoModule,
    PromosModule,
    PipesModule,
    RestaurantModule,
    RestaurantsModule,
    UsersModule,
    LicensesModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatMomentDateModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    SocketIoModule.forRoot(configuracionSockets)
  ],
  providers: [
    AuthService,
    AuthGuard,
    UsuarioNoAutenticadoGuard,
    RootGuard,
    AdminGuard,
    ManagerGuard,
    AtLeastManagerGuard,
    CashierGuard,
    BarGuard,
    AtLeastBarGuard,
    WaiterGuard,
    AtLeastWaiterGuard,
    AtLeastSalesGuard,
    CookGuard,
    IngredientService,
    IngredientesService,
    ProductService,
    PromoService,
    RestaurantService,
    LocationService,
    BillService,
    ComandaService,
    ReportsService,
    OrdersGroupService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    //{provide: LOCALE_ID, useValue: 'es-MX'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
