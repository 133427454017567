import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersComponent} from './users.component';
import {
  MatBadgeModule,
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatStepperModule,
  MatTableModule,
  MatProgressBarModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {RouterModule} from '@angular/router';
import {UsersRoutes} from './users.routes';
import {NewUserComponent} from './modals/new-user/new-user.component';
import {EditUserComponent} from './modals/edit-user/edit-user.component';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
  declarations: [
    UsersComponent,
    NewUserComponent,
    EditUserComponent
  ],
  imports: [
    RouterModule.forRoot(UsersRoutes),
    CommonModule,
    FormsModule,
    ImageCropperModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatTableModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    MatProgressBarModule,
  ],
  exports: [
    UsersComponent
  ]
})
export class UsersModule {
}
