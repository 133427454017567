import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './components/login/login.component';
import {ModulesRoutes} from './modules/modules.routes';
import {NavComponent} from './components/navigation/nav.component';
import {AuthGuard} from './core/guards/auth.guard';
import {PasswordResetComponent} from './components/password-reset/password-reset.component';
import { UsuarioNoAutenticadoGuard } from './core/guards/usuario-no-autenticado.guard';

const routes: Routes = [
  {path: '', component: NavComponent, children: ModulesRoutes, canActivate: [AuthGuard]},
  {path: 'iniciar-sesion', component: LoginComponent, canActivate: [UsuarioNoAutenticadoGuard]},
  {path: 'cambio-de-contrasena', component: PasswordResetComponent, canActivate: [UsuarioNoAutenticadoGuard]},
  {path: '**', redirectTo: 'inicio', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
