import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Paquete } from 'src/app/shared/class-models/paquete.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { NewPromoComponent } from './pages/new-promo/new-promo.component';
import { EditPromoComponent } from '../promo/pages/edit-promo/edit-promo.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html'
})
export class PromosComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Paquete>;
  filtro: string = '';
  RequestState = RequestState;
  estadoPeticionObtenerPaquete: number;
  obtenerPaquetesSubscription: Subscription;
  observable: Observable<any>;
  eliminarPaqueteSubscription: Subscription;

  constructor(
    private _paquetesService: PaquetesService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerPaquete = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerPaquetes();
  }

  obtenerPaquetes(): void {
    this.estadoPeticionObtenerPaquete = RequestState.loading;
    this.obtenerPaquetesSubscription = this._paquetesService.obtenerPaquetes().subscribe(
      (paquetes: Paquete[]) => {
        this.inicializarPaquetes(paquetes);
        this.estadoPeticionObtenerPaquete = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerPaquete = RequestState.error;
      }
    );
  }

  inicializarPaquetes(paquetes: Paquete[]): void {
    this.dataSource = new MatTableDataSource<Paquete>(paquetes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (paquete: Paquete, filtro: string) => {
      return paquete.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
    this.observable = this.dataSource.connect();
  }

  buscarPaquete(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarPaquete(): void {
    const dialogRef = this.dialog.open(NewPromoComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (paquete: Paquete) => {
        if (paquete != null) {
          this._alertasService.alertaExitoSinConfirmacion('Paquete agregado exitosamente', 'El paquete ' + paquete.nombre + ' ha sido agregado con éxito.');
          this.dataSource.data.push(paquete);
          const paquetes: Paquete[] = this.dataSource.data;
          this.inicializarPaquetes(paquetes);
          this.buscarPaquete();
        }
      }
    );
  }

  actualizarPaquete(paquete: Paquete): void {
    const dialogRef = this.dialog.open(EditPromoComponent, { disableClose: true, data: paquete });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Paquete actualizado exitosamente', 'El paquete ' + paquete.nombre + ' ha sido actualizado con éxito.');
          this.buscarPaquete();
        }
      }
    );
  }

  eliminarPaquete(paqueteEliminar: Paquete): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el paquete ' + paqueteEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarPaqueteSubscription = this._paquetesService.eliminarPaquete(paqueteEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionPaquete = this.dataSource.data.findIndex((paquete: Paquete) => {
              return paquete._id === paqueteEliminar._id;
            });
            let paquetesActualizados: Paquete[] = this.dataSource.data;
            if (posicionPaquete != -1) {
              paquetesActualizados.splice(posicionPaquete, 1);
            }
            this.inicializarPaquetes(paquetesActualizados);
            this.buscarPaquete();
            this._alertasService.alertaExitoSinConfirmacion('Paquete eliminado exitosamente', 'El paquete ' + paqueteEliminar.nombre + ' ha sido eliminado con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerPaquetesSubscription) this.obtenerPaquetesSubscription.unsubscribe();
    if (this.eliminarPaqueteSubscription) this.eliminarPaqueteSubscription.unsubscribe();
  }
}
