import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Area } from 'src/app/shared/class-models/area.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription, Observable } from 'rxjs';
import { AreasService } from 'src/app/core/servicios/areas.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { ActivatedRoute } from '@angular/router';
import { NewAreaComponent } from './pages/new-area/new-area.component';
import { EditAreaComponent } from '../area/pages/edit-area/edit-area.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html'
})
export class AreasComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Area>;
  filtro: string = '';
  estadoPeticionObtenerAreas: number;
  RequestState = RequestState;
  obtenerAreasSubscription: Subscription;
  observable: Observable<any>;
  eliminarAreaSubscription: Subscription;
  _idRestaurante: string;

  /* HABILITAR CONFIGURACIÓN DE AREAS */
  configuracionHabilitadaAreas: boolean;

  constructor(
    private _authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private _areasService: AreasService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this._idRestaurante = this.activatedRoute.parent.snapshot.paramMap.get('idRestaurante');
    this.estadoPeticionObtenerAreas = RequestState.initial;
    this.configuracionHabilitadaAreas = this.habilitarConfiguracionAreas();
  }

  habilitarConfiguracionAreas(): boolean {
    switch(this._authService.obtenerTipoUsuario()) {
      case TipoUsuario.Administrador:
      case TipoUsuario.Gerente:
        return true;
      default: 
        return false;
    }
  }

  ngOnInit(): void {
    this.obtenerAreas();
  }

  obtenerAreas(): void {
    this.estadoPeticionObtenerAreas = RequestState.loading;
    this.obtenerAreasSubscription = this._areasService.obtenerAreas(this._idRestaurante).subscribe(
      (areas: Area[]) => {
        this.inicializarAreas(areas);
        this.estadoPeticionObtenerAreas = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerAreas = RequestState.error;
      }
    );
  }

  inicializarAreas(areas: Area[]): void {
    this.dataSource = new MatTableDataSource<Area>(areas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (area: Area, filtro: string) => {
      return area.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
    this.observable = this.dataSource.connect();
  }

  buscarArea(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarArea(): void {
    const dialogRef = this.dialog.open(NewAreaComponent, { disableClose: true, data: this._idRestaurante });
    dialogRef.afterClosed().subscribe(
      (area: Area) => {
        if (area != null) {
          this._alertasService.alertaExitoSinConfirmacion('Área agregada exitosamente', 'El área ' + area.nombre + ' ha sido agregada con éxito.');
          this.dataSource.data.push(area);
          const areas: Area[] = this.dataSource.data;
          this.inicializarAreas(areas);
          this.buscarArea();
        }
      }
    );
  }

  actualizarArea(area: Area): void {
    const dialogRef = this.dialog.open(EditAreaComponent, { disableClose: true, data: { _idRestaurante: this._idRestaurante, area: area } });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Área actualizada exitosamente', 'El área ' + area.nombre + ' ha sido actualizada con éxito.');
          this.buscarArea();
        }
      }
    );
  }

  eliminarArea(areaEliminar: Area): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el área ' + areaEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarAreaSubscription = this._areasService.eliminarArea(this._idRestaurante, areaEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionArea = this.dataSource.data.findIndex((area: Area) => {
              return area._id === areaEliminar._id;
            });
            let areasActualizados: Area[] = this.dataSource.data;
            if (posicionArea != -1) {
              areasActualizados.splice(posicionArea, 1);
            }
            this.inicializarAreas(areasActualizados);
            this.buscarArea();
            this._alertasService.alertaExitoSinConfirmacion('Área eliminada exitosamente', 'El área ' + areaEliminar.nombre + ' ha sido eliminada con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerAreasSubscription) this.obtenerAreasSubscription.unsubscribe();
    if (this.eliminarAreaSubscription) this.eliminarAreaSubscription.unsubscribe();
  }
}
