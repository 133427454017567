import {CommonModule} from '@angular/common';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatCheckboxModule
} from '@angular/material';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {InventoryComponent} from './inventory.component';
// Modals.
import {InventoryAddInputComponent} from './components/inventory-add-input/inventory-add-input.component';
import {InventoryEditInputComponent} from './components/inventory-edit-input/inventory-edit-input.component';
import {InventoryEditOrderComponent} from './components/inventory-edit-order/inventory-edit-order.component';
import {InventoryAddOrderComponent} from './components/inventory-add-order/inventory-add-order.component';
// Pages.
import {StockComponent} from './pages/stock/stock.component';
import {InputsComponent} from './pages/inputs/inputs.component';
// Routes.
import {InventoryRoutes} from './inventory.routes';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';

@NgModule({
  declarations: [
    InventoryComponent,
    StockComponent,
    InputsComponent,
    InventoryAddInputComponent,
    InventoryEditInputComponent,
    InventoryEditOrderComponent,
    InventoryAddOrderComponent
  ],
  imports: [
    RouterModule.forRoot(InventoryRoutes),
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    InventoryComponent,
    StockComponent,
    InputsComponent,
    InventoryAddInputComponent,
    InventoryEditInputComponent,
    InventoryEditOrderComponent,
    InventoryAddOrderComponent
  ],
  providers: [
    PhonePipe
  ]
})
export class InventoryModule {
}
