import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {Orden} from '../../shared/models/orden';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient) {
  }

  /*obtenerOrden(id: string): Observable<any> {
    return this.httpClient.get('')
      .pipe(map((res: any[]) => {
        const fl = res.filter(orden => orden.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }*/

  obtenerOrdenesComanda(idComanda: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/ordenes/ordenes-comanda.php?idComanda=` + idComanda, AppSettings.Options);
  }

  obtenerOrdenesComandaEspecificas(idComanda: string, tipo: string, idTipo: string, idTamano: string): Observable<object> {
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/ordenes/ordenes-comanda-especificas.php?idComanda=` + idComanda + `&tipo=` + tipo + `&idTipo=` + idTipo + `&idTamano=` + idTamano,
      AppSettings.Options);
  }

  obtenerTotalOrdenesComanda(idComanda: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/ordenes/total-ordenes-comanda.php?idComanda=` + idComanda, AppSettings.Options);
  }

  obtenerTotalOrdenesSinPagarComanda(idComanda: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/ordenes/total-ordenes-sin-pagar-comanda.php?idComanda=` + idComanda, AppSettings.Options);
  }

  obtenerOrdenesCuenta(idComanda: string): Observable<object> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/ordenes/ordenes-cuenta.php?idComanda=` + idComanda, AppSettings.Options);
  }

  nuevaOrden(orden: Orden, idComanda: string, idSucursal: string): Observable<any> {
    const body = {
      idComanda,
      datosProducto: null,
      datosPaquete: null,
      idGrupoOrdenes: orden.idGrupoOrdenes,
      precioPorTamano: {
        precio: orden.precioPorTamano.precio,
        idTamano: orden.precioPorTamano.tamano.id
      }
    };
    if (orden.datosProducto != null) {
      const agregarIngredientes = [];
      const quitarIngredientes = [];
      const notas = [];
      if (orden.datosProducto.agregarIngredientes != null) {
        orden.datosProducto.agregarIngredientes.forEach((ingrediente) => {
          agregarIngredientes.push({
            idIngrediente: ingrediente.id,
            precio: ingrediente.precio
          });
        });
      }
      if (orden.datosProducto.quitarIngredientes != null) {
        orden.datosProducto.quitarIngredientes.forEach((ingrediente) => {
          quitarIngredientes.push(ingrediente.id);
        });
      }
      if (orden.datosProducto.notas != null) {
        orden.datosProducto.notas.forEach((nota) => {
          notas.push(nota.observacion);
        });
      }
      body.datosProducto = {
        idProducto: orden.datosProducto.producto.id,
        agregarIngredientes: agregarIngredientes.length > 0 ? agregarIngredientes : null,
        quitarIngredientes: quitarIngredientes.length > 0 ? quitarIngredientes : null,
        notas: notas.length > 0 ? notas : null
      };
    } else {
      const alternativasProductosPorTamano = [];
      orden.datosPaquete.alternativasProductosPorTamano.forEach((alternativa) => {
        const agregarIngredientes = [];
        const quitarIngredientes = [];
        const notas = [];
        if (alternativa.agregarIngredientes != null) {
          alternativa.agregarIngredientes.forEach((ingrediente) => {
            agregarIngredientes.push({
              idIngrediente: ingrediente.id,
              precio: ingrediente.precio
            });
          });
        }
        if (alternativa.quitarIngredientes != null) {
          alternativa.quitarIngredientes.forEach((ingrediente) => {
            quitarIngredientes.push(ingrediente.id);
          });
        }
        if (alternativa.notas != null) {
          alternativa.notas.forEach((nota) => {
            notas.push(nota.observacion);
          });
        }
        alternativasProductosPorTamano.push({
          idProducto: alternativa.producto.id,
          idTamano: alternativa.tamano.id,
          agregarIngredientes: agregarIngredientes.length > 0 ? agregarIngredientes : null,
          quitarIngredientes: quitarIngredientes.length > 0 ? quitarIngredientes : null,
          notas: notas.length > 0 ? notas : null
        });
      });
      body.datosPaquete = {
        idPaquete: orden.datosPaquete.paquete.id,
        alternativasProductosPorTamano
      };
    }
    return this.httpClient.post(`${AppSettings.APIEndpoint}/orden.php?idSucursal=` + idSucursal, JSON.stringify(body), AppSettings.Options);
  }

  /*
  Cocina
   */

  obtenerOrdenesCocina(idSucursal: string, idOrdenes: string[]): Observable<any> {
    let cadenaDeIdOrdenes = '';
    idOrdenes.forEach((idOrden) => {
      cadenaDeIdOrdenes += '&idOrdenes[]=' + idOrden;
    });
    return this.httpClient.get(
      `${AppSettings.APIEndpoint}/ordenes/ordenes-cocina.php?idSucursal=` + idSucursal + cadenaDeIdOrdenes,
      AppSettings.Options);
  }

  terminarOrden(idOrden: string): Observable<any> {
    const body = {
      idOrden
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/orden.php`, JSON.stringify(body), AppSettings.Options);
  }

  actualizarOrden(id: string) {
    // Pendiente
  }

  eliminarOrden(id: string) {
    // Pendiente
  }
}
