import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {RequestState} from '../../../../shared/enums/request-state.enum';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { CajasDeUsuarioService } from 'src/app/core/servicios/cajas-de-usuario.services';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';

@Component({
  selector: 'app-close-cash-register',
  templateUrl: './close-cash-register.component.html',
  styleUrls: []
})
export class CloseCashRegisterComponent implements OnInit, OnDestroy {
  titulo: string = 'Cerrar caja';
  RequestState = RequestState;
  estadoPeticionCerrarCaja: number;
  cerrarCajaSubscription: Subscription;
  ExpresionesRegulares = ExpresionesRegulares;
  
  constructor(
    public modal: MatDialogRef<CloseCashRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public cajaDeUsuario: CajaDeUsuario,
    private _cajasService: CajasDeUsuarioService,
    private _alertasService: AlertasService,
  ) {
    this.estadoPeticionCerrarCaja = RequestState.initial;
    this.cajaDeUsuario.montoCierreEsperado = (this.cajaDeUsuario.montoVentaTotal + parseFloat(this.cajaDeUsuario.montoApertura.toString())).toFixed(2);
    this.cajaDeUsuario.montoCierreReal = '0.00';
  }

  ngOnInit(): void {}

  transformarMontoCierreReal(montoCierreReal: string): void {
    if (montoCierreReal != null && montoCierreReal != undefined && !isNaN(Number(montoCierreReal))) {
      this.cajaDeUsuario.montoCierreReal = Number(montoCierreReal).toFixed(2);
    } else {
      this.cajaDeUsuario.montoCierreReal = Number('0').toFixed(2);
    }
  }


  eliminarCajaDeUsuario() {
    this.estadoPeticionCerrarCaja = RequestState.loading;
    this.cerrarCajaSubscription = this._cajasService.eliminarCajaDeUsuario(this.cajaDeUsuario._idRestaurante, this.cajaDeUsuario._id, this.prepararDatosCajaDeUsuario()).subscribe(
      (cajaDeUsuario: CajaDeUsuario) => {
        this.estadoPeticionCerrarCaja = RequestState.success;
        this.modal.close(true);
      },
      (err: HttpErrorResponse) => {
        this.estadoPeticionCerrarCaja = RequestState.error;
        this._alertasService.alertaErrorSinConfirmacion(err.error.titulo, err.error.detalles);
      }
    );
  }

  prepararDatosCajaDeUsuario(): CajaDeUsuario {
    let cajaDeUsuario: CajaDeUsuario = new CajaDeUsuario();
    cajaDeUsuario.montoVentaEfectivo = parseFloat(this.cajaDeUsuario.montoCierreEsperado.toString());
    cajaDeUsuario.montoVentaTarjeta = 0; 
    cajaDeUsuario.montoVentaTotal = parseFloat(this.cajaDeUsuario.montoCierreEsperado.toString());
    cajaDeUsuario.montoCierreEsperado = parseFloat(this.cajaDeUsuario.montoCierreEsperado.toString());
    cajaDeUsuario.montoCierreReal = parseFloat(this.cajaDeUsuario.montoCierreReal.toString());
    return cajaDeUsuario;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.cerrarCajaSubscription) this.cerrarCajaSubscription.unsubscribe();
  }

  /*disableBtn: boolean;
  requestState: number;
  dataSubscription: Subscription;
  timeout: number;
  title = 'Cerrar caja';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private cashRegister: CashRegisterService,
              public dialogRef: MatDialogRef<CloseCashRegisterComponent>) {
  }

  ngOnInit() {
    this.closeCashRegister();
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  closeCashRegister(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.dataSubscription = this.cashRegister.eliminarCaja(this.data.id, this.data.total).subscribe(
      res => {
        this.requestState = RequestState.success;
        this.dialogRef.close({ok: true});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogRef.close({ok: false});
  }*/
}
