import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {EmployeesComponent} from './employees.component';
// Modals.
import {NewEmployeeComponent} from './modals/new-employee/new-employee.component';
// Routes.
import {EmployeesRoutes} from './employees.routes';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
  declarations: [
    EmployeesComponent,
    NewEmployeeComponent
  ],
  imports: [
    RouterModule.forRoot(EmployeesRoutes),
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    ImageCropperModule
  ],
  exports: [
    EmployeesComponent,
    NewEmployeeComponent
  ]
})
export class EmployeesModule {
}
