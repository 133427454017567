import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ElementoArea} from '../../shared/models/elementoArea';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppSettings} from '../../configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class ElementsByAreaService {

  constructor(private httpClient: HttpClient) {
  }

  obtenerElementosArea(idArea: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/elementos-area/elementos-area.php?idArea=` + idArea, AppSettings.Options);
  }

  obtenerElementoArea(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/elemento-area.php?id=` + id, AppSettings.Options)
      .pipe(map((res: ElementoArea[]) => {
        const fl = res.filter(elementoArea => elementoArea.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  nuevoElementoArea(elementoArea: ElementoArea): Observable<any> {
    const body = {
      elementoArea
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/elemento-area.php`, JSON.stringify(body), AppSettings.Options);
  }

  actualizarElementoArea(id, elementoArea: ElementoArea): Observable<object> {
    elementoArea.id = id;
    const body = {
      elementoArea
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/elemento-area.php`, JSON.stringify(body), AppSettings.Options);
  }

  eliminarElementoArea(id: string) {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/elemento-area.php?id=${id}`, AppSettings.Options);
  }
}
