/*export class ControlInternoExistenciaDeProductoPorTamano {
    _idTamano: string;
    cantidad: number;

    constructor() {
        this.cantidad = 0;
    }
}

export class ControlInternoExistenciasDeProducto {
    _idProducto: string;
    tieneControlExistencias: boolean;
    cantidad: number;
    exitenciasPorTamanos: ControlInternoExistenciaDeProductoPorTamano[];

    constructor() {
        this.cantidad = 0;
        this.exitenciasPorTamanos = [];
    }
}*/
export class ControlInternoExistenciaDeProductoPorTamano {
    _idProducto: string;
    _idTamano: string;
    cantidad: number;

    constructor() {
        this.cantidad = 0;
    }
}