import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';

@Injectable({
    providedIn: 'root'
})
export class CuentasService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseAreas = '/areas';
    private rutaBaseElementosPorArea = '/elementos-por-area';
    private rutaBaseComandas = '/comandas';
    private rutaBaseCajas = '/cajas';
    private rutaBaseCuentas = '/cuentas';
    private rutaBaseOrdenes = '/ordenes';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerCuentasCaja(_idRestaurante: string, _idCaja: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCajas}/${_idCaja}${this.rutaBaseCuentas}`, AppSettings.Options);
    }

    obtenerCuenta(_idRestaurante: string, _idCuenta: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCuentas}/${_idCuenta}`, AppSettings.Options);
    }

    obtenerOrdenesCuenta(_idRestaurante: string, _idCuenta: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseCuentas}/${_idCuenta}${this.rutaBaseOrdenes}`, AppSettings.Options);
    }


    /* POST */
    agregarCuenta(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string, cuenta: Cuenta): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}${this.rutaBaseComandas}/${_idComanda}${this.rutaBaseCuentas}`, JSON.stringify(cuenta), AppSettings.Options);
    }

    /* PUT */

    /* PATCH */

}