import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OrderService } from '../../core/services/order.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Subscription } from 'rxjs';
import { UserService } from '../../core/services/user.service';
import { Usuario } from '../../shared/models/usuario';
import { GrupoOrdenes } from '../../shared/models/grupo-ordenes';
import { OrdersGroupService } from '../../core/services/orders-group.service';
import { OrdenesService } from 'src/app/core/servicios/ordenes.service';
import { CocinaService } from 'src/app/core/servicios/cocina.service';
import { ActivatedRoute } from '@angular/router';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IngredientesService } from 'src/app/core/servicios/ingredientes.service';
import { Ingrediente } from 'src/app/shared/class-models/ingrediente.model';
import { ProductosService } from 'src/app/core/servicios/productos.service';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-kds',
  templateUrl: './kds.component.html'
})
export class KdsComponent implements OnInit, OnDestroy {
  _idRestaurante: string;
  ordersGroups: GrupoOrdenes[];
  gruposDeOrdenes: GrupoDeOrdenes[] = [];
  subscription: Subscription;
  userSubscription: Subscription;
  activo: boolean;
  userInfo: Usuario;
  onDestroy$ = new Subject<boolean>();

  constructor(private _alertasService: AlertasService,
    private ordersGroupService: OrdersGroupService,
    private _cocinaService: CocinaService,
    private _serAutenticacion: AuthService) {
    this.activo = true;
    this.ordersGroups = [];
    this._idRestaurante = this._serAutenticacion.obtenerUsuario()._idRestaurante;
  }

  ngOnInit(): void {
    this.obtenerOrdenes();
    this.obtenerNuevaOrden();
  }

  obtenerOrdenes() {
    this._cocinaService.obtenerOrdenesGrupoDeOrdenes(this._idRestaurante).subscribe(
      (grupoDeOrdenes: GrupoDeOrdenes[]) => {
        this.gruposDeOrdenes = grupoDeOrdenes;
      },
      (err: HttpErrorResponse) => {
      }
    );
  }

  obtenerNuevaOrden(){
    this._cocinaService.obtenerNuevaOrdenEnCocina()
    .pipe(
      takeUntil(this.onDestroy$)
    )
    .subscribe(
      (nuevoGrupoDeOrden: GrupoDeOrdenes)=>{
        this.gruposDeOrdenes.push(nuevoGrupoDeOrden);
        this._alertasService.alertaExitoSinConfirmacion('Nueva orden','Se ha agregado una nueva orden');
      }
    )
  }

  ngOnDestroy(): void {
    this.activo = false;
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
    if (this.userSubscription !== undefined) {
      this.userSubscription.unsubscribe();
    }
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
  terminarOrden(evento: { _id: string, estadoEnCocina: number }) {
    this._cocinaService.actualizarEstadoEnCocinaGrupoDeOrdenes(this._idRestaurante, evento._id, evento.estadoEnCocina).subscribe(
      (resp: any) => {
        this._alertasService.alertaExitoSinConfirmacion(resp.titulo, resp.detalles);
        const indice: number = this.gruposDeOrdenes.findIndex(grupo => {
          return grupo._id == evento._id
        });
        if (indice !== -1) {
          this.gruposDeOrdenes.splice(indice, 1);
        }
      },
      (err: HttpErrorResponse) => {
        this._alertasService.alertaErrorConConfirmacion(err.error.titulo, err.error.detalles);
      }
    );
  }
}
