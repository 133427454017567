import {NgModule} from '@angular/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {IngredientComponent} from './ingredient.component';
// Pages.
import {EditIngredientComponent} from './pages/edit-ingredient/edit-ingredient.component';
import {IngredientsModule} from '../ingredients/ingredients.module';
import {
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule
} from '@angular/material';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {IngredientRoutes} from './ingredient.routes';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { VistaIngredienteModalComponent } from './vista-ingrediente-modal/vista-ingrediente-modal.component';

@NgModule({
  declarations: [
    IngredientComponent,
    EditIngredientComponent,
    VistaIngredienteModalComponent
  ],
  imports: [
    RouterModule.forRoot(IngredientRoutes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IngredientsModule,
    ImageCropperModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    BrowserModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    SweetAlert2Module,
    PipesModule
  ],
  exports: [
    IngredientComponent,
    EditIngredientComponent
  ]
})
export class IngredientModule {
}
