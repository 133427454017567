import {Routes} from '@angular/router';
import {IngredientComponent} from './ingredient.component';

export const IngredientRoutes: Routes = [
  {
    path: '',
    component: IngredientComponent
  }/*,
  {
    path: 'editar',
    data: {
      breadcrumb: 'Editar'
    },
    component: EditIngredientComponent
  },
  {
    path: 'eliminar',
    data: {
      breadcrumb: 'Eliminar'
    },
    component: DeleteIngredientComponent
  }*/
];
