import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ImpresionService } from 'src/app/core/servicios/impresion.service';
import { TipoImpresion } from 'src/app/shared/enums/tipo-impresion.enum';
import { Cuenta } from 'src/app/shared/class-models/cuenta.model';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';

@Component({
  selector: 'app-impresion',
  templateUrl: './impresion.component.html',
  styleUrls: ['./impresion.component.scss']
})
export class ImpresionComponent implements OnInit {
  TipoImpresion = TipoImpresion;
  tipoImpresion: number;

  /* VARIABLES PARA LA CUENTA */
  cuenta: Cuenta;
  ordenesAgrupadasCuenta: Orden[];

  /* VARIABLES PARA EL GRUPO DE ORDENES */
  grupoDeOrdenes: GrupoDeOrdenes;
  ordenesAgrupadasGrupoDeOrdenes: Orden[];
  ordenesPorOrdenesAgrupadasGrupoDeOrdenes: Orden[][];

  /*cuenta: Cuenta;
  ordenes: Orden[];
  agregarIngredientes: any[];
  AppSettings = AppSettings;
  tipoImpresion: number;
  ordenPrincipal: Orden[];
  ordenesAgrupadas: Orden[][];*/

  constructor(public _impresionService: ImpresionService,
              private detectorCambios: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._impresionService.tipoImpresion.subscribe(tipoImpresion => {
      this.tipoImpresion = tipoImpresion;
      this.detectorCambios.detectChanges();
      this.detectorCambios.markForCheck();
    })
    
    /* MÉTODOS PARA LA CUENTA */
    this._impresionService.cuenta.subscribe(cuenta => {
      this.cuenta = cuenta;
      this.detectorCambios.detectChanges();
      this.detectorCambios.markForCheck();
    })
    this._impresionService.ordenesAgrupadasCuenta.subscribe(ordenesAgrupadasCuenta => {
      this.ordenesAgrupadasCuenta = ordenesAgrupadasCuenta;
      this.detectorCambios.detectChanges();
      this.detectorCambios.markForCheck();
    })

    /* MÉTODOS PARA EL GRUPO DE ORDENES */
    this._impresionService.grupoDeOrdenes.subscribe(grupoDeOrdenes => {
      this.grupoDeOrdenes = grupoDeOrdenes;
      this.detectorCambios.detectChanges();
      this.detectorCambios.markForCheck();
    })
    this._impresionService.ordenesAgrupadasGrupoDeOrdenes.subscribe(ordenesAgrupadasGrupoDeOrdenes => {
      this.ordenesAgrupadasGrupoDeOrdenes = ordenesAgrupadasGrupoDeOrdenes;
      this.detectorCambios.detectChanges();
      this.detectorCambios.markForCheck();
    })
    this._impresionService.ordenesPorOrdenesAgrupadasGrupoDeOrdenes.subscribe(ordenesPorOrdenesAgrupadasGrupoDeOrdenes => {
      this.ordenesPorOrdenesAgrupadasGrupoDeOrdenes = ordenesPorOrdenesAgrupadasGrupoDeOrdenes;
      this.detectorCambios.detectChanges();
      this.detectorCambios.markForCheck();
    })

    /*
    this.billService.deteccionCambiosOrdenPrincipal.subscribe(ordenPrincipal => {
      this.ordenPrincipal = ordenPrincipal;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambiosOrdenesAgrupadas.subscribe(ordenesAgrupadas => {
      this.ordenesAgrupadas = ordenesAgrupadas;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambios.subscribe(cuenta => {
      this.cuenta = cuenta;
      this.cd.detectChanges();
      this.cd.markForCheck();
    });
    this.billService.deteccionCambiosEnOrdenes.subscribe(ordenes => {
      this.ordenes = ordenes;
      this.agregarIngredientes = [];
      if (this.ordenes !== null) {
        this.ordenes.forEach((orden, index) => {
          this.agregarIngredientes.push([]);
          if (orden.datosProducto !== null) {
            if (orden.datosProducto.agregarIngredientes !== null && orden.datosProducto.agregarIngredientes !== undefined) {
              this.agregarIngredientes[index] = orden.datosProducto.agregarIngredientes;
            }
          } else {
            orden.datosPaquete.alternativasProductosPorTamano.forEach((alternativa) => {
              if (alternativa.agregarIngredientes !== null && alternativa.agregarIngredientes !== undefined) {
                this.agregarIngredientes[index] = this.agregarIngredientes[index].concat(alternativa.agregarIngredientes);
              }
            });
          }
        });
      }
      this.cd.detectChanges();
      this.cd.markForCheck();
    });*/
  }
}
