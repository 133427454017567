import { TipoDeGastoGeneral } from './tipo-de-gasto-general.model';
import { FormaDePago } from '../enums/forma-de-pago.enum';
import { Restaurante } from './restaurante.model';
import { Usuario } from './usuario.model';

export class GastoGeneral {
    _id?: string;
    id?: number;
    _idTipoDeGastoGeneral: string;
    tipoDeGastoGeneral: TipoDeGastoGeneral;
    descripcion?: string;
    concepto: string;
    razonSocial: string;
    rfc: string;
    formaDePago: string;
    /*subtotal: number;
    porcentajeIva: number;
    iva: number;
    ieps: number;*/
    total: number | string;
    restaurante?: Restaurante;
    _idRestaurante?: string;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.formaDePago = FormaDePago.Efectivo;
        this.total = '0.00';
    }
}