import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../core/authentication/auth.service';
import { UserService } from '../../core/services/user.service';
import { Link } from '../../shared/models/link';
import { BillService } from '../../core/services/bill.service';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnInit {

  menus: Link[];
  usuario: Usuario;
  notification: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  isTablet$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private userService: UserService,
    private billService: BillService) {
    this.notification = false;
  }

  ngOnInit(): void {
    this.usuario = this.authService.obtenerUsuario();
    this.asignarMenu();
    /*this.userService.getUserInfo().subscribe(
      data => {
        this.userInfo = data;
        if (data.remainingdays < 310) {
          this.notification = true;
        }
        this.asignarNavegacion();
      }
    );*/
  }

  obtenerTipoDeUsuario() {
    switch (this.usuario.tipo) {
      case TipoUsuario.Vendedor: return 'Ventas';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
    }
  }

  cerrarSesion(): void {
    this.authService.cerrarSesion();
  }

  asignarMenu() {
    switch (this.usuario.tipo) {
      case TipoUsuario.Vendedor:
        this.menus = [
          {
            name: 'Inicio',
            icon: 'home',
            route: '/inicio'
          },
          {
            name: 'Usuarios',
            icon: 'account_box',
            route: '/usuarios'
          },
          {
            name: 'Licencias',
            icon: 'how_to_reg',
            route: '/licencias'
          },
          {
            name: 'Configuración',
            icon: 'settings',
            route: '/configuracion'
          },
        ];
        break;
      case TipoUsuario.Root:
        this.menus = [
          {
            name: 'Inicio',
            icon: 'home',
            route: '/inicio'
          },
          {
            name: 'Usuarios',
            icon: 'account_box',
            route: '/usuarios'
          },
          {
            name: 'Licencias',
            icon: 'how_to_reg',
            route: '/licencias'
          },
          {
            name: 'Configuración',
            icon: 'settings',
            route: '/configuracion'
          }
        ];
        break;
      case TipoUsuario.Administrador:
        this.menus = [
          {
            name: 'Inicio',
            icon: 'home',
            route: '/inicio'
          },
          {
            name: 'Clientes',
            icon: 'people',
            route: '/clientes'
          },
          {
            name: 'Empleados',
            icon: 'work',
            route: '/empleados'
          },
          {
            name: 'Proveedores',
            icon: 'room',
            route: '/proveedores'
          },
          {
            name: 'Ingredientes',
            icon: 'list',
            route: '/ingredientes'
          },
          {
            name: 'Productos',
            icon: 'fastfood',
            route: '/productos'
          },
          {
            name: 'Paquetes',
            icon: 'attach_money',
            route: '/paquetes'
          },
          {
            name: 'Menu',
            icon: 'restaurant_menu',
            route: '/menu'
          },
          {
            name: 'Restaurantes',
            icon: 'store',
            route: '/restaurantes'
          },
          {
            name: 'Configuración',
            icon: 'settings',
            route: '/configuracion'
          }
        ];
        break;
      case TipoUsuario.Gerente:
        this.menus = [
          {
            name: 'Inicio',
            icon: 'home',
            route: '/inicio'
          },
          {
            name: 'Clientes',
            icon: 'people',
            route: '/clientes'
          },
          {
            name: 'Empleados',
            icon: 'work',
            route: '/empleados'
          },
          {
            name: 'Proveedores',
            icon: 'room',
            route: '/proveedores'
          },
          {
            name: 'Ingredientes',
            icon: 'list',
            route: '/ingredientes'
          },
          {
            name: 'Productos',
            icon: 'fastfood',
            route: '/productos'
          },
          {
            name: 'Paquetes',
            icon: 'attach_money',
            route: '/paquetes'
          },
          {
            name: 'Menu',
            icon: 'restaurant_menu',
            route: '/menu'
          },
          {
            name: 'Restaurante',
            icon: 'store',
            route: '/restaurantes/' + this.usuario._idRestaurante
          },
          {
            name: 'Configuración',
            icon: 'settings',
            route: '/configuracion'
          }
        ];
        break;
      case TipoUsuario.Cajero:
      case TipoUsuario.Barman:
        this.menus = [
          {
            name: 'Inicio',
            icon: 'home',
            route: '/inicio'
          },
          {
            name: 'Clientes',
            icon: 'people',
            route: '/clientes'
          },
          {
            name: 'Menu',
            icon: 'restaurant_menu',
            route: '/menu'
          },
          {
            name: 'Areas',
            icon: 'store',
            route: '/restaurantes/' + this.usuario._idRestaurante + '/areas'
          },
          {
            name: 'Configuración',
            icon: 'settings',
            route: '/configuracion'
          }
        ];
        break;
      case TipoUsuario.Mesero:
        this.menus = [
          {
            name: 'Inicio',
            icon: 'home',
            route: '/inicio'
          },
          {
            name: 'Menu',
            icon: 'restaurant_menu',
            route: '/menu'
          },
          {
            name: 'Areas',
            icon: 'store',
            route: '/restaurantes/' + this.usuario._idRestaurante + '/areas'
          },
          {
            name: 'Configuración',
            icon: 'settings',
            route: '/configuracion'
          }
        ];
        break;
      case TipoUsuario.Cocinero:
        this.menus = [
          {
            name: 'Inicio',
            icon: 'home',
            route: '/inicio'
          },
          {
            name: 'Menu',
            icon: 'restaurant_menu',
            route: '/menu'
          },
          {
            name: 'Cocina',
            icon: 'kitchen',
            route: '/cocina'
          },
          {
            name: 'Configuración',
            icon: 'settings',
            route: '/configuracion'
          }
        ];
        break;
    }
  }
}
