import {CommonModule} from '@angular/common';
import {
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSortModule,
  MatStepperModule
} from '@angular/material';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

import {EmployeeComponent} from './employee.component';

import {EditEmployeeComponent} from './modals/edit-employee/edit-employee.component';

import {PipesModule} from '../../shared/pipes/pipes.module';
import {ImageCropperModule} from 'ngx-image-cropper';

@NgModule({
  declarations: [
    EmployeeComponent,
    EditEmployeeComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    ReactiveFormsModule,
    RouterModule,
    SweetAlert2Module,
    PipesModule,
    ImageCropperModule,
    FormsModule
  ],
  exports: [
    EmployeeComponent,
    EditEmployeeComponent
  ]
})
export class EmployeeModule {
}
