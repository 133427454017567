import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { EditEmployeeComponent } from './modals/edit-employee/edit-employee.component';
import { AppSettings } from '../../configs/app-settings.config';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html'
})
export class EmployeeComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  empleado: Usuario;
  estadoPeticionObtenerEmpleado: number;
  obtenerEmpleadoSubscription: Subscription;
  eliminarEmpleadoSubscripcion: Subscription;
  AppSettings = AppSettings;

  constructor(
    private _usuariosService: UsuariosService,
    private _alertasService: AlertasService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) {
    this.estadoPeticionObtenerEmpleado = RequestState.initial
  }

  ngOnInit(): void {
    this.obtenerEmpleado();

  }

  obtenerEmpleado() {
    this.estadoPeticionObtenerEmpleado = RequestState.loading;
    this.obtenerEmpleadoSubscription = this._usuariosService.obtenerUsuario(this.activatedRoute.snapshot.paramMap.get('idEmpleado')).subscribe(
      (empleado: Usuario) => {
        this.empleado = empleado;
        this.estadoPeticionObtenerEmpleado = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerEmpleado = RequestState.error;
        this.router.navigate(['/empleados']);
      }
    );
  }

  obtenerTipoUsuario(tipo: number): string {
    switch (tipo) {
      case TipoUsuario.Vendedor: return 'Vendedor';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
      default: return '';
    }
  }

  obtenerNombreCompleto(empleado: Usuario): string {
    return empleado.nombres + ' ' + empleado.primerApellido + (empleado.segundoApellido ? (' ' + empleado.segundoApellido) : '');
  }


  actualizarEmpleado() {
    const referenciaModal = this.dialog.open(EditEmployeeComponent, { disableClose: true, data: this.empleado });
    referenciaModal.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Empleado actualizado exitosamente', 'El empleado ' + this.obtenerNombreCompleto(this.empleado) + ' ha sido actualizado con éxito');
        }
      }
    );
  }

  eliminarEmpleado() {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar al empleado ' + this.obtenerNombreCompleto(this.empleado) + '?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.eliminarEmpleadoSubscripcion = this._usuariosService.eliminarUsuario(this.empleado._id).subscribe(
            (resultado: boolean) => {
              this._alertasService.alertaExitoSinConfirmacion('Empleado eliminado exitosamente', 'El empleado ' + this.obtenerNombreCompleto(this.empleado) + ' ha sido eliminado con éxito.');
              this.router.navigate(['/empleados']);
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      });
  }

  ngOnDestroy(): void {
    if (this.obtenerEmpleadoSubscription) this.obtenerEmpleadoSubscription.unsubscribe();
    if (this.eliminarEmpleadoSubscripcion) this.eliminarEmpleadoSubscripcion.unsubscribe();
  }

}
