import { Usuario } from './usuario.model';

export class Tamano {
    _id?: string;
    id?: number;
    nombre: string;
    porDefecto: boolean;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.porDefecto = false;
    }
}
