import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatStepper } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { UbicacionService } from 'src/app/core/servicios/ubicacion.service';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from '../../../../shared/enums/request-state.enum';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { Estado } from 'src/app/shared/class-models/estado.model';
import { Ciudad } from 'src/app/shared/class-models/ciudad.model';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CodigoPostal } from 'src/app/shared/class-models/codigo-postal.model';
import { Categoria } from 'src/app/shared/class-models/categoria.model';
import { CategoriasService } from 'src/app/core/servicios/categorias.service';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { ElementosService } from 'src/app/core/servicios/elementos.service';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { Elemento } from 'src/app/shared/class-models/elemento.model';
import { TipoArea } from 'src/app/shared/enums/tipo-area.enum';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html'
})
export class NewUserComponent implements OnInit, OnDestroy {
  titulo: string = 'Agregar usuario';
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('direccionForm') direccionForm: FormGroup;
  TipoUsuario = TipoUsuario;
  ExpresionesRegulares = ExpresionesRegulares;
  RequestState = RequestState;
  usuario: Usuario = new Usuario();
  estadoPeticionAgregarUsuario: number;
  agregarUsuarioSubscripcion: Subscription;
  estadosSubscripcion: Subscription;
  ciudadesSubscripcion: Subscription;
  estados: Estado[];
  ciudades: Ciudad[];
  eventoCambioImagenFotografia: any = '';
  tipoUsuario: number;
  codigoPostal: string = '';
  stepActual: number = 0;

  constructor(
    private _authService: AuthService,
    public modal: MatDialogRef<NewUserComponent>,
    private _ubicacionService: UbicacionService,
    private _alertasService: AlertasService,
    private _usuariosService: UsuariosService,
    private _categoriasService: CategoriasService,
    private _tamanosService: TamanosService,
    private _elementosService: ElementosService
  ) {
    this.estadoPeticionAgregarUsuario = RequestState.initial;
    this.tipoUsuario = this._authService.obtenerTipoUsuario();
  }

  ngOnInit(): void {
    this.obtenerEstados();
  }

  obtenerEstados(): void {
    this.estados = [];
    this.estadosSubscripcion = this._ubicacionService.obtenerEstados().subscribe(
      (estados: Estado[]) => {
        this.estados = estados;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerCiudades(idEstado: string): void {
    this.ciudades = [];
    this.ciudadesSubscripcion = this._ubicacionService.obtenerCiudades(Number(idEstado)).subscribe(
      (ciudades: Ciudad[]) => {
        this.ciudades = ciudades;
      }, (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
      }
    );
  }

  obtenerTipoUsuario(tipo: number): string {
    switch (tipo) {
      case TipoUsuario.Vendedor: return 'Ventas de licencias';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador de licencias';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
      default: return '';
    }
  }

  transformarNombreUsuario(nombreUsuario: string): void {
    if (nombreUsuario != null && nombreUsuario != undefined) {
      this.usuario.nombreUsuario = nombreUsuario.toLocaleLowerCase();
    } else {
      this.usuario.nombreUsuario = '';
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(event: ImageCroppedEvent): void {
    this.usuario.foto = event.base64;
  }

  imagenCargada(): void { }

  cortadorListo(): void { }

  cargarImagenFallida(): void { }

  async agregarUsuario() {
    this.estadoPeticionAgregarUsuario = RequestState.loading;
    const nombreUsuarioDisponible = await this.verificarNombreUsuario(this.usuario.nombreUsuario);
    if (nombreUsuarioDisponible == false) {
      this._alertasService.alertaErrorSinConfirmacion('Nombre de usuario no disponible', 'El nombre de usuario ingresado ya existe, favor de ingresar uno diferente.');
      this.estadoPeticionAgregarUsuario = RequestState.initial;
      this.direccionForm.controls['nombreUsuario'].setErrors({ repetido: true });
    } else {
      const codigoPostal = await this.verificarCodigoPostal(this.usuario.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this._alertasService.alertaErrorSinConfirmacion('Código postal inválido', 'El código postal ingresado no corresponde con el estado ingresado.');
        this.estadoPeticionAgregarUsuario = RequestState.initial;
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      } else {
        this.usuario.idCodigoPostal = codigoPostal.id;
        this.agregarUsuarioSubscripcion = this._usuariosService.agregarUsuario(this.usuario).subscribe(
          async (usuario: Usuario) => {
            if (usuario.tipo == TipoUsuario.Administrador) {
              const categoria = await this.agregarCategoriaPorDefecto(usuario._id);
              const tamano = await this.agregarTamanoPorDefecto(usuario._id);
              const elementoAreaNormal = await this.agregarElementoPorTipoDeAreaPorDefecto(usuario._id, TipoArea.Normal);
              const elementoAreaBarra = await this.agregarElementoPorTipoDeAreaPorDefecto(usuario._id, TipoArea.Barra);
              if (categoria == null || tamano == null || elementoAreaNormal == null || elementoAreaBarra == null) this._alertasService.alertaErrorSinConfirmacion('Error al crear la configuración del usuario', 'El usuario fue creado pero ocurrió un error al crear su configuración básica, favor de reportarlo');
            }
            this.estadoPeticionAgregarUsuario = RequestState.success;
            this.modal.close(usuario);
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            this.estadoPeticionAgregarUsuario = RequestState.error;
          }
        );
      }
    }
  }

  retroceder() {
    this.stepActual--;
    this.stepper.previous();
  }

  avanzar() {
    this.stepActual++;
    this.stepper.next();
  }

  async validarCodigoPostal() {
    if (this.codigoPostal.length >= 5) {
      const codigoPostal = await this.verificarCodigoPostal(this.usuario.idEstado, this.codigoPostal);
      if (!codigoPostal) {
        this.direccionForm.controls['codigoPostal1'].setErrors({ invalido: true });
      }
    }
  }

  verificarNombreUsuario(nombreUsuario: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._usuariosService.verificarNombreUsuario(nombreUsuario).subscribe(
        (nombreUsuarioDisponible: boolean) => resolve(nombreUsuarioDisponible),
        (error) => resolve(false)
      );
    })
  }

  verificarCodigoPostal(idEstado: number, codigoPostal: string): Promise<CodigoPostal> {
    return new Promise((resolve, reject) => {
      this._ubicacionService.verificarCodigoPostal(idEstado, codigoPostal).subscribe(
        (codigoPostal: CodigoPostal) => resolve(codigoPostal),
        (error) => resolve(null)
      );
    })
  }

  agregarCategoriaPorDefecto(_idAccesoUsuario: string): Promise<Categoria> {
    return new Promise((resolve, reject) => {
      let categoria = new Categoria();
      categoria._idAccesoUsuario = _idAccesoUsuario;
      this._categoriasService.agregarCategoriaPorDefecto(categoria).subscribe(
        (categoria: Categoria) => resolve(categoria),
        (error) => resolve(null)
      );
    })
  }

  agregarTamanoPorDefecto(_idAccesoUsuario: string): Promise<Tamano> {
    return new Promise((resolve, reject) => {
      let tamano = new Tamano();
      tamano._idAccesoUsuario = _idAccesoUsuario;
      this._tamanosService.agregarTamanoPorDefecto(tamano).subscribe(
        (tamano: Tamano) => resolve(tamano),
        (error) => resolve(null)
      );
    })
  }

  agregarElementoPorTipoDeAreaPorDefecto(_idAccesoUsuario: string, tipoDeArea: number): Promise<Elemento> {
    return new Promise((resolve, reject) => {
      let elemento = new Elemento();
      elemento._idAccesoUsuario = _idAccesoUsuario;
      this._elementosService.agregarElementoPorTipoDeAreaPorDefecto(elemento, tipoDeArea).subscribe(
        (elemento: Elemento) => resolve(elemento),
        (error) => resolve(null)
      );
    })
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '')
      .then((resultado) => {
        if (resultado.value) {
          this.modal.close();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.estadosSubscripcion) this.estadosSubscripcion.unsubscribe();
    if (this.ciudadesSubscripcion) this.ciudadesSubscripcion.unsubscribe();
    if (this.agregarUsuarioSubscripcion) this.agregarUsuarioSubscripcion.unsubscribe();
  }
}
