import {Component, OnDestroy, OnInit, Inject} from '@angular/core';
import {RequestState} from '../../../../../../shared/enums/request-state.enum';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {Subscription} from 'rxjs';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { TipoArea } from 'src/app/shared/enums/tipo-area.enum';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { AreasService } from 'src/app/core/servicios/areas.service';
import { Area } from 'src/app/shared/class-models/area.model';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-new-area',
  templateUrl: './new-area.component.html',
  styles: []
})
export class NewAreaComponent implements OnInit, OnDestroy {
  titulo = 'Agregar área';
  ExpresionesRegulares = ExpresionesRegulares;
  area: Area = new Area();
  TipoArea = TipoArea;
  estadoPeticionAgregarArea: number;
  RequestState = RequestState;
  agregarAreaSubscription: Subscription;
  eventoCambioImagenFotografia: any = '';

  constructor(
    private _areasService: AreasService,
    private _alertasService: AlertasService,
    public modal: MatDialogRef<NewAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,//Se recibe el ID del restaurante
    ) {
    this.estadoPeticionAgregarArea = RequestState.initial;
  }

  ngOnInit(): void {}

  obtenerTipoArea(tipoArea: number): string {
    switch(tipoArea) {
      case TipoArea.Normal: return 'Normal';
      case TipoArea.Barra: return 'Barra';
    }
  }

  cambioArchivoEvento(event: any): void {
    this.eventoCambioImagenFotografia = event;
  }

  imagenRecortada(evento: ImageCroppedEvent): void {
    this.area.foto = evento.base64;
  }

  imagenCargada(): void {
    //this.cargandoImagen = false;
  }

  cortadorListo(): void {
    //this.cargandoImagen = false;
  }

  cargarImagenFallida(): void {
    //this.cargandoImagen = false;
  }

  //eliminarImagen(): void {
    //this.imagenFotoRecortada = '';
    //this.eventoCambioImagenFotografia = '';
  //}

  agregarArea(): void {
    this.estadoPeticionAgregarArea = RequestState.loading;
    this.agregarAreaSubscription = this._areasService.agregarArea(this.data, this.area).subscribe(
      (area: Area) => {
        this.estadoPeticionAgregarArea = RequestState.success;
        this.modal.close(area);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarArea = RequestState.error;
      }
    );
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.agregarAreaSubscription) this.agregarAreaSubscription.unsubscribe();
  }
}
