import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { GastoGeneral } from 'src/app/shared/class-models/gasto-general.model';

@Injectable({
    providedIn: 'root'
})
export class GastosGeneralesService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseGastosGenerales = '/gastos-generales';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerGastosGenerales(_idRestaurante: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosGenerales}`, AppSettings.Options);
    }

    obtenerGastoGeneral(_idRestaurante: string, _idGastoGeneral: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosGenerales}/${_idGastoGeneral}`, AppSettings.Options);
    }

    /* POST */
    agregarGastoGeneral(_idRestaurante: string, gastoGeneral: GastoGeneral): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosGenerales}`, JSON.stringify(gastoGeneral), AppSettings.Options);
    }

    /* PUT */
    actualizarGastoGeneral(_idRestaurante: string, _idGastoGeneral: string, gastoGeneral: GastoGeneral): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosGenerales}/${_idGastoGeneral}`, JSON.stringify(gastoGeneral), AppSettings.Options);
    }

    /* PATCH */
    eliminarGastoGeneral(_idRestaurante: string, _idGastoGeneral: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseGastosGenerales}/${_idGastoGeneral}`, AppSettings.Options);
    }
}