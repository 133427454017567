export class RecuperacionContrasena {
    nombreUsuario: string;
    codigoDeRecuperacion: string;
    contrasenaNueva: string;
    confirmacionContrasenaNueva: string;

    constructor() {
        this.nombreUsuario = '';
        this.codigoDeRecuperacion = '';
        this.contrasenaNueva = '';
        this.confirmacionContrasenaNueva = '';
    }
}