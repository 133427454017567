import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatTableDataSource} from '@angular/material';
import {Observable, Subscription} from 'rxjs';
import {Orden} from '../../../../../../shared/models/orden';
import {Paquete} from '../../../../../../shared/models/paquete';
import {Producto} from '../../../../../../shared/models/producto';
import {Tamano} from '../../../../../../shared/models/tamano';
import {ActivatedRoute} from '@angular/router';
import {OrderService} from '../../../../../../core/services/order.service';
import {SizeService} from '../../../../../../core/services/size.service';
import {PromoService} from '../../../../../../core/services/promo.service';
import {ProductService} from '../../../../../../core/services/product.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: []
})
export class OrderComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  observable: Observable<any>;
  dataSource: MatTableDataSource<Orden>;
  subscription: Subscription;
  title = 'Orden';
  loading: boolean;
  paquete: Paquete;
  producto: Producto;
  tamano: Tamano;
  ordenes: Orden[];

  constructor(private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef,
              private orderService: OrderService,
              private sizeService: SizeService,
              private promoService: PromoService,
              private productService: ProductService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.loading = true;
    this.subscription = this.orderService.obtenerOrdenesComandaEspecificas(
      this.route.snapshot.paramMap.get('idComanda'),
      this.route.snapshot.paramMap.get('tipo'),
      this.route.snapshot.paramMap.get('idTipo'),
      this.route.snapshot.paramMap.get('idTamano')
    ).subscribe(
      (data: Orden[]) => {
        this.ordenes = data;
        this.sizeService.obtenerTamano(this.route.snapshot.paramMap.get('idTamano')).subscribe(
          (tamano: Tamano) => {
            this.tamano = tamano;
            if (this.route.snapshot.paramMap.get('tipo') === 'paquete') {
              this.promoService.obtenerPaquete(this.route.snapshot.paramMap.get('idTipo')).subscribe(
                (paquete: Paquete) => {
                  this.paquete = paquete;
                  this.loading = false;
                });
            } else {
              this.productService.obtenerProducto(this.route.snapshot.paramMap.get('idTipo')).subscribe(
                (producto: Producto) => {
                  this.producto = producto;
                  this.loading = false;
                });
            }
          });
      }
    );
  }

  calculCostoExtra(posicion: number) {
    let acomulador = 0;
    if (this.ordenes[posicion].datosProducto != null) {
      if (this.ordenes[posicion].datosProducto.agregarIngredientes != null) {
        for (const ingrediente of this.ordenes[posicion].datosProducto.agregarIngredientes) {
          acomulador += ingrediente.precio;
        }
      }
    } else {
      for (const producto of this.ordenes[posicion].datosPaquete.alternativasProductosPorTamano) {
        if (producto.agregarIngredientes != null) {
          for (const ingrediente of producto.agregarIngredientes) {
            acomulador += ingrediente.precio;
          }
        }
      }
    }
    return acomulador;
  }

  /*agregarIngrediente(): void {
    this.dialog.open(AddIngredientComponent, {
      data: {
        idProducto: null,
        idPaquete: null,
        ingredientes: []
      },
      disableClose: true
    });
  }

  cancelarExtra(posicionIngrediente: number, posicionProducto: number): void {
    if (posicionProducto == null) {
      this.orden.datosProducto.agregarIngredientes.splice(posicionIngrediente, 1);
      if (this.orden.datosProducto.agregarIngredientes.length === 0) {
        this.orden.datosProducto.agregarIngredientes = null;
      }
    } else {
      this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].agregarIngredientes.splice(posicionIngrediente, 1);
      if (this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].agregarIngredientes.length === 0) {
        this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].agregarIngredientes = null;
      }
    }
  }

  eliminarIngrediente(): void {
    this.dialog.open(RemoveIngrdientComponent, {
      data: {
        idProducto: null,
        idPaquete: null,
        ingredientes: []
      },
      disableClose: true
    });
  }

  cancelarEliminacion(posicionIngrediente: number, posicionProducto: number): void {
    if (posicionProducto == null) {
      this.orden.datosProducto.quitarIngredientes.splice(posicionIngrediente, 1);
      if (this.orden.datosProducto.quitarIngredientes.length === 0) {
        this.orden.datosProducto.quitarIngredientes = null;
      }
    } else {
      this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].quitarIngredientes.splice(posicionIngrediente, 1);
      if (this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].quitarIngredientes.length === 0) {
        this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].quitarIngredientes = null;
      }
    }
  }

  agregarNota(): void {
    this.dialog.open(NotesComponent, {
      data: {
        idProducto: null,
        idPaquete: null
      },
      disableClose: true
    });
  }

  cancelarNota(posicionIngrediente: number, posicionProducto: number): void {
    if (posicionProducto == null) {
      this.orden.datosProducto.notas.splice(posicionIngrediente, 1);
      if (this.orden.datosProducto.notas.length === 0) {
        this.orden.datosProducto.notas = null;
      }
    } else {
      this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].notas.splice(posicionIngrediente, 1);
      if (this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].notas.length === 0) {
        this.orden.datosPaquete.alternativasProductosPorTamano[posicionProducto].notas = null;
      }
    }
  }*/

}
