import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { UsuariosService } from 'src/app/core/servicios/usuarios.service';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { RequestState } from '../../shared/enums/request-state.enum';
import { EditUserComponent } from './modals/edit-user/edit-user.component';
import { NewUserComponent } from './modals/new-user/new-user.component';
import { TipoUsuario } from 'src/app/shared/enums/tipo-usuario.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginador: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  TipoUsuario = TipoUsuario;
  dataSource: MatTableDataSource<Usuario>;
  filtro: string = '';
  estadoPeticionObtenerUsuarios: number;
  RequestState = RequestState;
  obtenerUsuariosSubscripcion: Subscription;
  eliminarUsuarioSubscripcion: Subscription;
  columnas = ['id', 'tipo', 'nombreUsuario', 'nombreCompleto', 'opciones'];

  constructor(
    private dialog: MatDialog,
    private _alertasService: AlertasService,
    private _usuariosService: UsuariosService) {
    this.estadoPeticionObtenerUsuarios = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerUsuariosAdministradores();
  }

  obtenerUsuariosAdministradores(): void {
    this.estadoPeticionObtenerUsuarios = RequestState.loading;
    this.obtenerUsuariosSubscripcion = this._usuariosService.obtenerUsuarios().subscribe(
      (usuarios: Usuario[]) => {
        this.inicializarUsuarios(usuarios);
        this.estadoPeticionObtenerUsuarios = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerUsuarios = RequestState.error;
      }
    );
  }

  inicializarUsuarios(usuarios: Usuario[]): void {
    this.dataSource = new MatTableDataSource<Usuario>(usuarios);
    this.dataSource.paginator = this.paginador;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (usuario: Usuario, filtro: string) => {
      return usuario.id.toString().trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerTipoUsuario(usuario.tipo).trim().toLowerCase().indexOf(filtro) !== -1 ||
        usuario.nombreUsuario.trim().toLowerCase().indexOf(filtro) !== -1 ||
        this.obtenerNombreCompletoUsuario(usuario).trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarUsuario(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  obtenerTipoUsuario(tipo: number): string {
    switch (tipo) {
      case TipoUsuario.Vendedor: return 'Ventas de licencias';
      case TipoUsuario.Root: return 'Root';
      case TipoUsuario.Administrador: return 'Administrador de licencias';
      case TipoUsuario.Gerente: return 'Gerente';
      case TipoUsuario.Cajero: return 'Cajero';
      case TipoUsuario.Barman: return 'Barman';
      case TipoUsuario.Mesero: return 'Mesero';
      case TipoUsuario.Cocinero: return 'Cocinero';
      default: return '';
    }
  }

  obtenerNombreCompletoUsuario(usuario: Usuario): string {
    return usuario.nombres + ' ' + usuario.primerApellido + (usuario.segundoApellido ? (' ' + usuario.segundoApellido) : '');
  }

  agregarUsuario(): void {
    const dialogRef = this.dialog.open(NewUserComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (usuario: Usuario) => {
        if (usuario) {
          this._alertasService.alertaExitoSinConfirmacion('Usuario agregado exitosamente', 'El usuario ' + this.obtenerNombreCompletoUsuario(usuario) + ' ha sido agregado con éxito');
          this.dataSource.data.push(usuario);
          const usuarios: Usuario[] = this.dataSource.data;
          this.inicializarUsuarios(usuarios);
          this.buscarUsuario();
        }
      }
    );
  }

  actualizarUsuario(usuario: Usuario): void {
    const dialogRef = this.dialog.open(EditUserComponent, { disableClose: true, data: usuario });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Usuario actualizado exitosamente', 'El usuario ' + this.obtenerNombreCompletoUsuario(usuario) + ' ha sido actualizado con éxito.');
          this.buscarUsuario();
        }
      }
    );
  }

  eliminarUsuario(usuarioEliminar: Usuario): void {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar al usuario ' + this.obtenerNombreCompletoUsuario(usuarioEliminar) + '?', '')
      .then((result) => {
        if (result.value) {
          this.eliminarUsuarioSubscripcion = this._usuariosService.eliminarUsuario(usuarioEliminar._id).subscribe(
            (resultado: boolean) => {
              let posicionUsuario = this.dataSource.data.findIndex((usuario: Usuario) => {
                return usuario._id === usuarioEliminar._id;
              });
              let usuariosActualizados: Usuario[] = this.dataSource.data;
              if (posicionUsuario != -1) {
                usuariosActualizados.splice(posicionUsuario, 1);
              }
              this.inicializarUsuarios(usuariosActualizados);
              this.buscarUsuario();
              this._alertasService.alertaExitoSinConfirmacion('Usuario eliminado exitosamente', 'El usuario ' + this.obtenerNombreCompletoUsuario(usuarioEliminar) + ' ha sido eliminado con éxito.');
            },
            (error: HttpErrorResponse) => {
              this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
            }
          );
        }
      })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerUsuariosSubscripcion) this.obtenerUsuariosSubscripcion.unsubscribe();
    if (this.eliminarUsuarioSubscripcion) this.eliminarUsuarioSubscripcion.unsubscribe();
  }
}
