import { Ciudad } from './ciudad.model';
import { CodigoPostal } from './codigo-postal.model';
import { Estado } from './estado.model';
import { Licencia } from './licencia.model';
import { PorcentajeIva } from '../enums/porcentaje-iva.enum';
import { Usuario } from './usuario.model';

export class Restaurante {
    _id?: string;
    id?: number;
    nombre: string;
    descripcion?: string;
    rfc: string;
    foto?: string;
    logo?: string;
    estado: Estado ;
    idEstado: number;
    ciudad: Ciudad ;
    idCiudad: number;
    calle?: string;
    colonia?: string;
    numeroExterior?: string;
    numeroInterior?: string;
    codigoPostal: CodigoPostal;
    idCodigoPostal?: number;
    telefono?: string;
    propinasActivas?: boolean;
    descuentosActivos?: boolean;
    cuponesActivos?: boolean;
    ivaActivo?: boolean;
    porcentajeIva: number;
    _idLicencia: string;
    licencia?: Licencia;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo?: boolean;
    _idAccesoUsuario: string;

    constructor() {
        this.propinasActivas = true;
        this.descuentosActivos = true;
        this.cuponesActivos = true;
        this.ivaActivo = true;
        this.porcentajeIva = PorcentajeIva.Dieciseis;
    }
}

