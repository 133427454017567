import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { CajasDeUsuarioService } from 'src/app/core/servicios/cajas-de-usuario.services';
import { CajaDeUsuario } from 'src/app/shared/class-models/caja-de-usuario.model';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { RequestState } from 'src/app/shared/enums/request-state.enum';

@Component({
  selector: 'app-abrir-caja',
  templateUrl: './abrir-caja.component.html',
  styleUrls: ['./abrir-caja.component.scss']
})
export class AbrirCajaComponent implements OnInit {
  titulo: string = 'Abrir caja';
  RequestState = RequestState;
  estadoPeticionAgregarCajaDeUsuario: number;
  cajaDeUsuario: CajaDeUsuario = new CajaDeUsuario();
  ExpresionesRegulares = ExpresionesRegulares;

  constructor(public modal: MatDialogRef<AbrirCajaComponent>,
    @Inject(MAT_DIALOG_DATA) public _idRestaurante: string,
    private activatedRoute: ActivatedRoute,
    private _alertasService: AlertasService,
    private _cajasService: CajasDeUsuarioService) {
    this.estadoPeticionAgregarCajaDeUsuario = RequestState.initial;
  }

  ngOnInit() {
  }

  transformarMontoApertura(montoApertura: string): void {
    if (montoApertura != null && montoApertura != undefined && !isNaN(Number(montoApertura))) {
      this.cajaDeUsuario.montoApertura = Number(montoApertura).toFixed(2);
    } else {
      this.cajaDeUsuario.montoApertura = Number('0').toFixed(2);
    }
  }

  agregarCajaDeUsuario() {
    this.estadoPeticionAgregarCajaDeUsuario = RequestState.loading;
    this._cajasService.agregarCajaDeUsuario(this._idRestaurante, this.prepararDatosCajaDeUsuario()).subscribe(
      (cajaDeUsuario: CajaDeUsuario) => {
        this.estadoPeticionAgregarCajaDeUsuario = RequestState.success;
        this.modal.close(cajaDeUsuario);
      },
      (err: HttpErrorResponse) => {
        this.estadoPeticionAgregarCajaDeUsuario = RequestState.error;
        this._alertasService.alertaErrorSinConfirmacion(err.error.titulo, err.error.detalles);
      }
    );
  }

  prepararDatosCajaDeUsuario(): CajaDeUsuario {
    let cajaDeUsuario = new CajaDeUsuario();
    cajaDeUsuario.montoApertura = parseFloat(this.cajaDeUsuario.montoApertura.toString());
    return cajaDeUsuario
  }

  cerrar(){
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.modal.close(null);
      }
    })
  }
}
