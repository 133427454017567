import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatChipInputEvent, MatDialogRef} from '@angular/material';
import {Producto} from '../../../../../../../../shared/models/producto';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../../../../../shared/enums/request-state.enum';
import {ProductService} from '../../../../../../../../core/services/product.service';
import {Ingrediente} from '../../../../../../../../shared/models/ingrediente';
import {IngredientService} from '../../../../../../../../core/services/ingredient.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Orden} from '../../../../../../../../shared/models/orden';

@Component({
  selector: 'app-product-size',
  templateUrl: './product-size.component.html',
  styleUrls: []
})
export class ProductSizeComponent implements OnInit, OnDestroy {

  orden: Orden;
  producto: Producto;
  disableBtn: boolean;
  productoPorTamanoSeleccionado: any;
  subscription: Subscription;
  requestState: number;
  ingredientesSeleccionados: Ingrediente[] = [];
  ingredientesExtraSeleccionados: Ingrediente[] = [];
  extraIngredientesSubscription: Subscription;
  extraIngredientesRequestState: number;
  ingredientesExtra: Ingrediente[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  notas: any[] = [];
  cantidad: string | number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ProductSizeComponent>,
              private productService: ProductService,
              private ingredientService: IngredientService) {
    this.productoPorTamanoSeleccionado = null;
    this.cantidad = 1;
  }

  ngOnInit(): void {
    this.requestState = RequestState.loading;
    this.subscription = this.productService.obtenerProducto(this.data.id).subscribe(
      (res: any) => {
        this.producto = res;
        if (this.producto.productoPorTamano.length > 0) {
          this.productoPorTamanoSeleccionado = this.producto.productoPorTamano[0];
        }
        this.requestState = RequestState.success;
      });
    this.extraIngredientesSubscription = this.ingredientService.obtenerIngredientesExtra().subscribe(
      (data: Ingrediente[]) => {
        this.ingredientesExtra = data;
        this.extraIngredientesRequestState = RequestState.success;
      },
      error => {
        console.error(error);
        this.extraIngredientesRequestState = RequestState.error;
      }
    );
  }

  setIngredient(id: string): void {
    const posicion = this.ingredientesSeleccionados.findIndex((ingrediente) => {
      return ingrediente.id === id;
    });
    if (posicion === -1) {
      this.ingredientesSeleccionados.push(this.producto.ingredientes.find((ingrediente) => {
          return ingrediente.id === id;
        }
      ));
    } else {
      this.ingredientesSeleccionados.splice(posicion, 1);
    }
  }

  setExtraIngredient(id: any): void {
    const posicion = this.ingredientesExtraSeleccionados.findIndex((ingrediente) => {
      return ingrediente.id === id;
    });
    if (posicion === -1) {
      this.ingredientesExtraSeleccionados.push(this.ingredientesExtra.find((ingrediente) => {
          return ingrediente.id === id;
        }
      ));
    } else {
      this.ingredientesExtraSeleccionados.splice(posicion, 1);
    }
  }

  agregarNota(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.notas.push({observacion: value.trim()});
    }
    if (input) {
      input.value = '';
    }
  }

  eliminarNota(nota: any): void {
    const posicion = this.notas.indexOf(nota);
    if (posicion >= 0) {
      this.notas.splice(posicion, 1);
    }
  }

  deshabilitarBoton() {
    if (this.productoPorTamanoSeleccionado == null) return true;
    else return false;
  }

  transformarCantidad(cantidad: string) {
    if (cantidad != null && cantidad != undefined && !isNaN(Number(cantidad))) {
      if (Number(cantidad) <= 0) {
        this.cantidad = 1;
      } else {
        if (this.data.existencia != 'NA') {
          if (Number(cantidad) >= (this.data.existencia as number)) {
            this.cantidad = (this.data.existencia as number);
          }
        } else {
          this.cantidad = Number(cantidad).toFixed(0);
        }
      }
    } else {
      this.cantidad = 1;
    }
  }

  aumentarCantidad() {
    if (this.data.existencia != 'NA') {
      if (this.cantidad >= (this.data.existencia as number)) {
        this.cantidad = (this.data.existencia as number);
      }
      else {
        this.cantidad = Number(this.cantidad) + 1;
      }
    } else {
      this.cantidad = Number(this.cantidad) + 1;
    }
  }

  desactivarAumentoCantidad() {
    if (this.data.existencia != 'NA') {
      if (this.cantidad >= (this.data.existencia as number)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  disminuirCantidad() {
    if (Number(this.cantidad) > 1) {
      this.cantidad = Number(this.cantidad) - 1;
    } else {
      this.cantidad = 1;
    }
  }

  desactivarDisminucionCantidad() {
    if (this.cantidad == null || this.cantidad == undefined || isNaN(Number(this.cantidad)) || Number(this.cantidad) <= 1) {
      return true;
    } else {
      return false;
    }

  }

  nuevaOrden() {
    this.orden = {
      cantidad: Number(this.cantidad),
      cancelada: false,
      fecha: null, // new Date().toISOString()
      datosProducto: {
        producto: {
          id: this.producto.id,
          nombre: this.producto.nombre,
          clasificacion: null
        },
        agregarIngredientes: this.ingredientesExtraSeleccionados,
        quitarIngredientes: this.ingredientesSeleccionados,
        notas: this.notas
      },
      precioPorTamano: this.productoPorTamanoSeleccionado
    };
    this.dialogRef.close({ok: true, orden: this.orden});
  }

  close(): void {
    this.dialogRef.close({ok: false});
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
