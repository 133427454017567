import {AppSettings} from '../../../../configs/app-settings.config';
import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import { Producto } from 'src/app/shared/class-models/producto.model';
import { VistaProductoModalComponent } from 'src/app/modules/product/vista-producto-modal/vista-producto-modal.component';

@Component({
  selector: 'app-menu-product',
  templateUrl: './kiosk-product.component.html'
})
export class KioskProductComponent implements OnInit {
  @Input() producto: Producto;
  appSettings = AppSettings;

  constructor(public modal: MatDialog) {
  }

  ngOnInit() {
  }

  detalles() {
    const dialogRef = this.modal.open(VistaProductoModalComponent,
      {
        disableClose: true,
        data: this.producto
      });
  }

}
