import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { Paquete } from 'src/app/shared/class-models/paquete.model';
import { PaquetesService } from 'src/app/core/servicios/paquetes.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { VistaPaqueteModalComponent } from 'src/app/modules/promo/vista-paquete-modal/vista-paquete-modal.component';

@Component({
  selector: 'app-menu-paquetes',
  templateUrl: './menu-paquetes.component.html',
  styleUrls: ['./menu-paquetes.component.scss']
})
export class MenuPaquetesComponent implements OnInit {
  RequestState = RequestState;
  AppSettings = AppSettings;
  @ViewChild('paginadorPaquetes') paginadorPaquetes: MatPaginator;
  obtenerPaquetesSubscripcion: Subscription;
  paquetes: MatTableDataSource<Paquete>;
  paquetesObservable: Observable<any>;
  estadoPeticionObtenerPaquetes: number;
  filtroBusqueda: string = '';

  constructor(
    private _paquetesService: PaquetesService,
    private _alertasService: AlertasService,
    public modal: MatDialog
  ) {
    this.estadoPeticionObtenerPaquetes = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerPaquetes();
  }

  obtenerPaquetes() {
    this.estadoPeticionObtenerPaquetes = RequestState.loading;
    this.obtenerPaquetesSubscripcion = this._paquetesService.obtenerPaquetes().subscribe(
      (paquetes: Paquete[]) => {
        this.inicializarPaquetes(paquetes);
        this.estadoPeticionObtenerPaquetes = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerPaquetes = RequestState.error;
      }
    );
  }

  inicializarPaquetes(paquetes: Paquete[]): void {
    this.paquetes = new MatTableDataSource<Paquete>(paquetes);
    this.paquetes.paginator = this.paginadorPaquetes;
    this.paquetes.filterPredicate = (paquete: Paquete, filtro: string) => {
      return paquete.nombre.trim().toLowerCase().indexOf(this.filtroBusqueda.trim().toLowerCase()) !== -1;
    }
    this.paquetesObservable = this.paquetes.connect();
  }

  buscarPaquete(): void {
    this.paquetes.filter = this.filtroBusqueda;
  }

  verPaquete(paquete: Paquete) {
    const dialogRef = this.modal.open(VistaPaqueteModalComponent,
      {
        disableClose: true,
        data: paquete
      });
  }

  ngOnDestroy(): void {
    if (this.paquetes) this.paquetes.disconnect();
    if (this.obtenerPaquetesSubscripcion) this.obtenerPaquetesSubscripcion.unsubscribe();
  }
}
