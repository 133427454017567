import { Ciudad } from './ciudad.model';
import { CodigoPostal } from './codigo-postal.model';
import { Estado } from './estado.model';
import { Restaurante } from './restaurante.model';

export class Usuario {
    _id?: string;
    id?: number;
    tipo: number;
    nombreUsuario: string;
    nombres: string;
    primerApellido: string;
    segundoApellido?: string;
    estado?: Estado;
    idEstado?: number;
    ciudad?: Ciudad;
    idCiudad?: number;
    calle: string;
    colonia: string;
    numeroExterior: string;
    numeroInterior?: string;
    codigoPostal: CodigoPostal;
    idCodigoPostal?: number;
    foto?: string;
    telefono?: string;
    correo?: string;
    _idRestaurante: string;
    restaurante: Restaurante;
    _idUsuario: string;
    usuario: Usuario;
    fechaRegistro: Date;
    activo: boolean;
    _idAccesoUsuario: string;

    constructor() { }
}