import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/authentication/auth.service';
import { InicioDeSesion } from 'src/app/shared/class-models/inicio-de-sesion.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { WebSocketsService } from 'src/app/core/servicios/web-sockets.service';
import UsuarioSocket from 'src/app/shared/class-models/usuario-socket.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  estadoPeticionIniciarSesion: number;
  iniciarSesionSubscription: Subscription;
  inicioDeSesion: InicioDeSesion = new InicioDeSesion();
  isLoggedIn: boolean;

  constructor(
    private _authService: AuthService,
    private _alertasService: AlertasService,
    private _webSocketsService: WebSocketsService,
    private router: Router) {
    this.estadoPeticionIniciarSesion = RequestState.initial;
  }

  ngOnInit(): void { }

  transformarNombreUsuario(nombreUsuario: string): void {
    if (nombreUsuario != null && nombreUsuario != undefined) {
      this.inicioDeSesion.nombreUsuario = nombreUsuario.toLocaleLowerCase();
    } else {
      this.inicioDeSesion.nombreUsuario = '';
    }
  }

  iniciarSesion() {
    this.estadoPeticionIniciarSesion = RequestState.loading;
    this.iniciarSesionSubscription = this._authService.iniciarSesion(this.inicioDeSesion).subscribe(
      (res: any) => {
        const datosUsuario = this._authService.obtenerUsuario();
        this.router.navigate(['inicio']);
        this._webSocketsService.iniciarSesionWS(UsuarioSocket.prototype.nuevoUsuario(datosUsuario._id, true, datosUsuario.tipo, datosUsuario._idRestaurante, datosUsuario._idAccesoUsuario))
          .then(() => {
            this._alertasService.alertaExitoSinConfirmacion('Bienvenido ' + this.obtenerNombreCompletoUsuario(this._authService.obtenerUsuario()), 'Has iniciado sesión satisfactoriamente.');
            this.router.navigate(['/inicio']);
            this.estadoPeticionIniciarSesion = RequestState.success;
          })
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionIniciarSesion = RequestState.error;
      }
    );
  }

  obtenerNombreCompletoUsuario(usuario: Usuario): string {
    return usuario.nombres + ' ' + usuario.primerApellido + (usuario.segundoApellido ? (' ' + usuario.segundoApellido) : '');
  }

  ngOnDestroy(): void {
    if (this.iniciarSesionSubscription) this.iniciarSesionSubscription.unsubscribe();
  }
}
