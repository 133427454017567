import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/shared/class-models/usuario.model';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/app/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private rutaBaseUsuarios = '/usuarios';

  constructor(private httpClient: HttpClient) { }

  /* GET */
  obtenerUsuario(_idUsuario: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}/${_idUsuario}`, AppSettings.Options)
  }

  obtenerUsuarios(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}`, AppSettings.Options);
  }

  obtenerCajasUsuario(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}/cajas`, AppSettings.Options);
  }

  obtenerUsuariosAdministradores(): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}/administradores`, AppSettings.Options);
  }

  verificarNombreUsuario(nombreUsuario: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}/nombre-usuario?nombreUsuario=${nombreUsuario}`, AppSettings.Options);
  }

  /* POST */
  agregarUsuario(usuario: Usuario): Observable<any> {
    return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}`, JSON.stringify(usuario), AppSettings.Options);
  }

  /* PUT */
  actualizarUsuario(_idUsuario: string, usuario: Usuario): Observable<any> {
    return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}/${_idUsuario}`, JSON.stringify(usuario), AppSettings.Options);
  }

  /* PATCH */
  eliminarUsuario(_idUsuario: string): Observable<any> {
    return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseUsuarios}/${_idUsuario}`, AppSettings.Options);
  }
}
