import { Component, OnInit, Inject } from '@angular/core';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Cupon } from 'src/app/shared/class-models/cupon.model';
import { Subscription } from 'rxjs';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { CuponesService } from 'src/app/core/servicios/cupones.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TipoCupon } from 'src/app/shared/enums/tipo-cupon.enum';
import { HttpErrorResponse } from '@angular/common/http';
import * as momento from 'moment';

@Component({
  selector: 'app-editar-cupon-configuracion',
  templateUrl: './editar-cupon-configuracion.component.html',
  styleUrls: ['./editar-cupon-configuracion.component.scss']
})
export class EditarCuponConfiguracionComponent implements OnInit {
  titulo = 'Actualizar cupón';
  RequestState = RequestState;
  cupon: Cupon;

  /* VARIABLES PARA OBTENER EL CUPON */
  obtenerCuponSubscription: Subscription;
  estadoPeticionObtenerCupon: number;

  /* VARIABLES PARA ACTUALIZAR EL CUPON */
  ExpresionesRegulares = ExpresionesRegulares;
  TipoCupon = TipoCupon;
  actualizarCuponSubscription: Subscription;
  estadoPeticionActualizarCupon: number;
  fechaInicioMinima: Date;

  constructor(
    private _cuponesService: CuponesService,
    private _alertasService: AlertasService,
    @Inject(MAT_DIALOG_DATA) public data: Cupon,
    public dialogRef: MatDialogRef<EditarCuponConfiguracionComponent>
    ) {
    this.estadoPeticionObtenerCupon = RequestState.initial;
    this.estadoPeticionActualizarCupon = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerCupon();
  }

  obtenerCupon() {
    this.estadoPeticionObtenerCupon = RequestState.loading;
    this.obtenerCuponSubscription = this._cuponesService.obtenerCupon(this.data._id).subscribe(
      (cupon: Cupon) => {
        if(momento(new Date(cupon.fechaInicio)).isAfter(new Date(Date.now()))) {
          this.fechaInicioMinima = new Date(Date.now());
        } else {
          this.fechaInicioMinima = new Date(cupon.fechaInicio);
        }
        this.cupon = this.inicializarDatosCupon(cupon);
        this.estadoPeticionObtenerCupon = RequestState.success;
      }, 
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerCupon = RequestState.error;
        this.dialogRef.close();
      }
    );
  }

  inicializarDatosCupon(cupon: Cupon): Cupon {
    switch (cupon.tipo) {
      case TipoCupon.Cantidad:
        cupon.descuentoCantidad = parseFloat(cupon.descuentoCantidad.toString()).toFixed(2);
        cupon.descuentoPorcentaje = 0;
        break;
      case TipoCupon.Porcentaje:
        cupon.descuentoCantidad = '0.00';
        break;
    }
    return cupon;
  }

  transformarDescuentoCantidad(cantidad: string) {
    if (cantidad != null && cantidad != undefined && !isNaN(Number(cantidad))) {
      this.cupon.descuentoCantidad = Number(cantidad).toFixed(2);
    } else {
      this.cupon.descuentoCantidad = Number('0').toFixed(2);
    }
  }

  transformarDescuentoPorcentaje(porcentaje: string) {
    if (porcentaje != null && porcentaje != undefined && !isNaN(Number(porcentaje))) {
      if (Number(porcentaje) > 100) {
        this.cupon.descuentoPorcentaje = 100
      } else {
        if (Number(porcentaje) < 0) {
          this.cupon.descuentoPorcentaje = 0
        } else {
          this.cupon.descuentoPorcentaje = Number(porcentaje).toFixed(0);
        }
      }
    } else {
      this.cupon.descuentoPorcentaje = 0
    }
  }

  actualizarCupon() {
    this.estadoPeticionActualizarCupon = RequestState.loading;
    this.actualizarCuponSubscription = this._cuponesService.actualizarCupon(this.data._id, this.prepararDatosCupon()).subscribe(
      (cupon: Cupon) => {
        this.estadoPeticionActualizarCupon = RequestState.success;
        this.actualizarCuponOriginal(cupon)
        this.dialogRef.close(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionActualizarCupon = RequestState.error;
      }
    );
  }

  prepararDatosCupon(): Cupon {
    let cupon = new Cupon();
    cupon.nombre = this.cupon.nombre;
    cupon.fechaInicio = this.cupon.fechaInicio;
    cupon.fechaFin = this.cupon.fechaFin;
    cupon.tipo = this.cupon.tipo;
    switch (cupon.tipo) {
      case TipoCupon.Cantidad:
        cupon.descuentoCantidad = Number(this.cupon.descuentoCantidad);
        cupon.descuentoPorcentaje = null;
        break;
      case TipoCupon.Porcentaje:
        cupon.descuentoPorcentaje = Number(this.cupon.descuentoPorcentaje);
        cupon.descuentoCantidad = null;
        break;
    }
    return cupon;
  }
  
  actualizarCuponOriginal(cupon: Cupon): void {
    this.data.nombre = cupon.nombre;
    this.data.tipo = cupon.tipo;
    this.data.fechaInicio = cupon.fechaInicio;
    this.data.fechaFin = cupon.fechaFin;
    this.data.descuentoCantidad = cupon.descuentoCantidad;
    this.data.descuentoPorcentaje = cupon.descuentoPorcentaje;
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(false);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.obtenerCuponSubscription) this.obtenerCuponSubscription.unsubscribe();
    if(this.actualizarCuponSubscription) this.actualizarCuponSubscription.unsubscribe();
  }
}
