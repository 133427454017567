import {Injectable} from '@angular/core';
import {Tamano} from '../../shared/models/tamano';
import {Observable} from 'rxjs';
import {AppSettings} from '../../configs/app-settings.config';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SizeService {

  constructor(private httpClient: HttpClient) {
  }

  public getSizes(): Observable<Tamano[]> {
    return this.httpClient.get<Tamano[]>(`${AppSettings.APIEndpoint}/tamanos.php`, AppSettings.Options);
  }

  obtenerTamano(id: string): Observable<any> {
    return this.httpClient.get(`${AppSettings.APIEndpoint}/tamano.php?id=` + id, AppSettings.Options)
      .pipe(map((res: Tamano[]) => {
        const fl = res.filter(tamano => tamano.id === id);
        return (fl.length > 0) ? fl[0] : null;
      }));
  }

  agregarTamano(nombre: string) {
    const body = {
      nombre: nombre
    };
    return this.httpClient.post(`${AppSettings.APIEndpoint}/tamano.php`, JSON.stringify(body), AppSettings.Options);
  }

  actualizarTamano(id: string, nombre: string) {
    const body = {
      id: id,
      nombre: nombre
    };
    return this.httpClient.put(`${AppSettings.APIEndpoint}/tamano.php`, JSON.stringify(body), AppSettings.Options);
  }

  borrarTamano(id: string) {
    return this.httpClient.delete(`${AppSettings.APIEndpoint}/tamano.php?id=` + id, AppSettings.Options);
  }
}
