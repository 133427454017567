import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { Area } from 'src/app/shared/class-models/area.model';

@Injectable({
    providedIn: 'root'
})
export class AreasService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseAreas = '/areas';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerAreas(_idRestaurante: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}`, AppSettings.Options);
    }

    obtenerArea(_idRestaurante: string, _idArea: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}`, AppSettings.Options);
    }

    /* POST */
    agregarArea(_idRestaurante: string, area: Area): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}`, JSON.stringify(area), AppSettings.Options);
    }

    /* PUT */
    actualizarArea(_idRestaurante: string, _idArea: string, area: Area): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}`, JSON.stringify(area), AppSettings.Options);
    }

    /* PATCH */
    eliminarArea(_idRestaurante: string, _idArea: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}`, AppSettings.Options);
    }
}