import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExpresionesRegulares } from 'src/app/shared/constants/expresiones-regulares';
import { Categoria } from 'src/app/shared/class-models/categoria.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs/internal/Subscription';
import { CategoriasService } from 'src/app/core/servicios/categorias.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { MatDialogRef } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-agregar-categoria-configuracion',
  templateUrl: './agregar-categoria-configuracion.component.html',
  styleUrls: ['./agregar-categoria-configuracion.component.scss']
})
export class AgregarCategoriaConfiguracionComponent implements OnInit, OnDestroy {
  titulo = 'Agregar categoría';
  ExpresionesRegulares = ExpresionesRegulares;
  categoria: Categoria = new Categoria();
  estadoPeticionAgregarCategoria: number;
  RequestState = RequestState;
  agregarCategoriaSubscription: Subscription;

  /*
    VALIDACIÓN DEL NOMBRE DE LA CATEGORIA
    this.nombreTamano = new FormControl('', [Validators.pattern('^((?!default)(?!Default).)*$'), Validators.required]);
  */

  constructor(
    private _categoriasService: CategoriasService,
    private _alertasService: AlertasService,
    public dialogRef: MatDialogRef<AgregarCategoriaConfiguracionComponent>
  ) {
    this.estadoPeticionAgregarCategoria = RequestState.initial;
  }

  ngOnInit(): void {}

  agregarCategoria() {
    this.estadoPeticionAgregarCategoria = RequestState.loading;
    this.agregarCategoriaSubscription = this._categoriasService.agregarCategoria(this.categoria).subscribe(
      (categoria: Categoria) => {
        this.estadoPeticionAgregarCategoria = RequestState.success;
        this.dialogRef.close(categoria);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionAgregarCategoria = RequestState.error;
      }
    );
  }

  cerrar(): void {
    this._alertasService.alertaAdvertenciaConConfirmacion('¿Seguro que desea cancelar?', '').then((result) => {
      if (result.value) {
        this.dialogRef.close(null);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.agregarCategoriaSubscription) this.agregarCategoriaSubscription.unsubscribe();
  }
}
