import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Restaurante } from 'src/app/shared/class-models/restaurante.model';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription, Observable } from 'rxjs';
import { RestaurantesService } from 'src/app/core/servicios/restaurantes.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { NewRestaurantComponent } from './pages/new-restaurant/new-restaurant.component';
import { EditRestaurantComponent } from '../restaurant/pages/edit-restaurant/edit-restaurant.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html'
})
export class RestaurantsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<Restaurante>;
  filtro: string = '';
  estadoPeticionObtenerRestaurantes: number;
  RequestState = RequestState;
  obtenerRestaurantesSubscription: Subscription;
  observable: Observable<any>;
  eliminarRestauranteSubscription: Subscription;

  constructor(
    private _restaurantesService: RestaurantesService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticionObtenerRestaurantes = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerRestaurantes();
  }

  obtenerRestaurantes(): void {
    this.estadoPeticionObtenerRestaurantes = RequestState.loading;
    this.obtenerRestaurantesSubscription = this._restaurantesService.obtenerRestaurantes().subscribe(
      (restaurantes: Restaurante[]) => {
        this.inicializarRestaurantes(restaurantes);
        this.estadoPeticionObtenerRestaurantes = RequestState.success;
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticionObtenerRestaurantes = RequestState.error;
      }
    );
  }

  inicializarRestaurantes(restaurantes: Restaurante[]): void {
    this.dataSource = new MatTableDataSource<Restaurante>(restaurantes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (restaurante: Restaurante, filtro: string) => {
      return restaurante.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
    this.observable = this.dataSource.connect();
  }

  buscarRestaurante(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarRestaurante(): void {
    const dialogRef = this.dialog.open(NewRestaurantComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (restaurante: Restaurante) => {
        if (restaurante != null) {
          this._alertasService.alertaExitoSinConfirmacion('Restaurante agregado exitosamente', 'El restaurante ' + restaurante.nombre + ' ha sido agregado con éxito.');
          this.dataSource.data.push(restaurante);
          const restaurantes: Restaurante[] = this.dataSource.data;
          this.inicializarRestaurantes(restaurantes);
          this.buscarRestaurante();
        }
      }
    );
  }

  actualizarRestaurante(restaurante: Restaurante) {
    const dialogRef = this.dialog.open(EditRestaurantComponent, { disableClose: true, data: restaurante });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Restaurante actualizado exitosamente', 'El restaurante ' + restaurante.nombre + ' ha sido actualizado con éxito.');
          this.buscarRestaurante();
        }
      }
    );
  }

  eliminarRestaurante(restauranteEliminar: Restaurante) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el restaurante ' + restauranteEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarRestauranteSubscription = this._restaurantesService.eliminarRestaurante(restauranteEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionRestaurante = this.dataSource.data.findIndex((restaurante: Restaurante) => {
              return restaurante._id === restauranteEliminar._id;
            });
            let restaurantesActualizados: Restaurante[] = this.dataSource.data;
            if (posicionRestaurante != -1) {
              restaurantesActualizados.splice(posicionRestaurante, 1);
            }
            this.inicializarRestaurantes(restaurantesActualizados);
            this.buscarRestaurante();
            this._alertasService.alertaExitoSinConfirmacion('Restaurante eliminado exitosamente', 'El restaurante ' + restauranteEliminar.nombre + ' ha sido eliminado con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy(): void {
    if (this.dataSource) this.dataSource.disconnect();
    if (this.obtenerRestaurantesSubscription) this.obtenerRestaurantesSubscription.unsubscribe();
    if (this.eliminarRestauranteSubscription) this.eliminarRestauranteSubscription.unsubscribe();
  }

  /*@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  observable: Observable<any>;
  dataSource: MatTableDataSource<Restaurante>;
  titulo = 'Restaurantes';
  restaurantes: Restaurante[];
  subscription: Subscription;
  busqueda: FormGroup;
  requestState: number;
  noResults: boolean = false;
  @ViewChild('dialogAddSuccess', {static: true}) private dialogAddSuccess: SwalComponent;
  @ViewChild('dialogEditSuccess', {static: true}) private dialogEditSuccess: SwalComponent;
  @ViewChild('dialogDeleteError', {static: true}) private dialogDeleteError: SwalComponent;
  @ViewChild('dialogDeleteSuccess', {static: true}) private dialogDeleteSuccess: SwalComponent;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private restaurantService: RestaurantService,
              public dialog: MatDialog) {
    this.busqueda = new FormGroup({
      buscar: new FormControl(
        '',
        [
          Validators.pattern('[.a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\'\\t\\n\\v\\f\\r ' +
            '\u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u2028\u2029\u3000]*')
        ]
      )
    });
  }

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.requestState = RequestState.loading;
    this.subscription = this.restaurantService.obtenerRestaurantes().subscribe(
      (data: Restaurante[]) => {
        this.restaurantes = data;
        this.orderRestaurants();
        this.dataSource = new MatTableDataSource<Restaurante>(data);
        this.changeDetectorRef.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.observable = this.dataSource.connect();
        this.requestState = RequestState.success;
      }
    );
  }

  orderRestaurants(): void {
    this.restaurantes.sort((restaurante1: Restaurante, restaurante2: Restaurante) => {
      if(restaurante1.nombre < restaurante2.nombre) { return -1; }
      if(restaurante1.nombre > restaurante2.nombre) { return 1; }
      return 0;
    });
  }

  filterRestaurants(): void{
    this.requestState = RequestState.loading;
    let termino = this.busqueda.value.buscar;
    this.noResults = false;
    let data: Restaurante[];
    data = this.restaurantes.filter(function(value, index, array): boolean {
      return value.nombre.trim().toLowerCase().indexOf(termino.trim().toLowerCase()) !== -1;
    });
    if (data.length === 0 && termino != '') {
      this.noResults = true;
    }
    this.dataSource = new MatTableDataSource<Restaurante>(data);
    this.dataSource.paginator = this.paginator;
    this.observable = this.dataSource.connect();
    this.requestState = RequestState.success;
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
    this.subscription.unsubscribe();
  }

  addRestaurant(): void {
    const dialogRef = this.dialog.open(NewRestaurantComponent, {disableClose: true});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result.ok) {
          this.restaurantes.push(result.form);
          this.dialogAddSuccess.show();
          this.orderRestaurants();
          this.filterRestaurants();
        }
      }
    );
  }

  deleteRestaurant(id: string): void {
    this.restaurantService.eliminarRestaurante(id).subscribe(
      () => {
        let index = this.restaurantes.findIndex(element => {
          return element.id === id;
        });
        if(index != -1) {
          this.restaurantes.splice(index, 1);
          this.dialogDeleteSuccess.show();
          this.filterRestaurants();
        }
      },
      () => {
        this.dialogDeleteError.show();
      }
    );
  }

  editRestaurant(id: string): void {
    const dialogRef = this.dialog.open(EditRestaurantComponent, {disableClose: true, data: {id}});
    dialogRef.afterClosed().subscribe(
      res => {
        if (res.ok) {
          this.restaurantes.forEach(element => {
            if (element.id === res.id) {
              element.nombre = res.form.nombre;
              element.foto = res.form.foto;
              this.dialogEditSuccess.show();
              this.filterRestaurants();
            }
          });
        }
      }
    );
  }*/
}
