import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTabsModule, MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatSelectModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatTableModule, MatCheckboxModule, MatTooltipModule, MatRadioModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AdministracionExistenciasComponent } from './administracion-existencias.component';
import { AdministracionExistenciasRoutes } from './administracion-existencias.routes';
import { ActualizarExistenciasPorTamanoComponent } from './actualizar-existencias-por-tamano/actualizar-existencias-por-tamano.component';


@NgModule({
  declarations: [
      AdministracionExistenciasComponent,
      ActualizarExistenciasPorTamanoComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(AdministracionExistenciasRoutes),
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
  ],
  exports: []
})
export class AdministracionExistenciasModule { }
