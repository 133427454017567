import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/configs/app-settings.config';
import { ElementoPorArea } from 'src/app/shared/class-models/elemento-por-area.model';

@Injectable({
    providedIn: 'root'
})
export class ElementosPorAreaService {
    private rutaBaseRestaurantes = '/restaurantes';
    private rutaBaseAreas = '/areas';
    private rutaBaseElementosPorArea = '/elementos-por-area';

    constructor(private httpClient: HttpClient) {
    }

    /* GET */
    obtenerElementosPorArea(_idRestaurante: string, _idArea: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}`, AppSettings.Options);
    }

    obtenerElementoPorArea(_idRestaurante: string, _idArea: string, _idElementoPorArea: string): Observable<any> {
        return this.httpClient.get(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}`, AppSettings.Options);
    }

    /* POST */
    agregarElementoPorArea(_idRestaurante: string, _idArea: string, elementoPorArea: ElementoPorArea): Observable<any> {
        return this.httpClient.post(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}`, JSON.stringify(elementoPorArea), AppSettings.Options);
    }

    /* PUT */
    actualizarElementoPorArea(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, elementoPorArea: ElementoPorArea): Observable<any> {
        return this.httpClient.put(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}`, JSON.stringify(elementoPorArea), AppSettings.Options);
    }

    /* PATCH */
    actualizarComandaElementoPorArea(_idRestaurante: string, _idArea: string, _idElementoPorArea: string, _idComanda: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}/comanda`, JSON.stringify({_idComanda}), AppSettings.Options);
    }

    eliminarElementoPorArea(_idRestaurante: string, _idArea: string, _idElementoPorArea: string): Observable<any> {
        return this.httpClient.patch(`${AppSettings.APIEndpoint}${this.rutaBaseRestaurantes}/${_idRestaurante}${this.rutaBaseAreas}/${_idArea}${this.rutaBaseElementosPorArea}/${_idElementoPorArea}`, AppSettings.Options);
    }
}