import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {BillService} from '../../../../../../../../core/services/bill.service';
import {Subscription} from 'rxjs';
import {RequestState} from '../../../../../../../../shared/enums/request-state.enum';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: []
})
export class PaymentResultComponent implements OnInit, OnDestroy {

  disableBtn: boolean;
  requestState: number;
  timeout: number;
  subscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<PaymentResultComponent>,
              private billService: BillService) {
    this.disableBtn = true;
  }

  ngOnInit(): void {
    this.requestState = RequestState.initial;
    this.disableBtn = false;
  }

  pagar(): void {
    this.disableBtn = true;
    this.requestState = RequestState.loading;
    this.subscription = this.billService.nuevaCuenta(this.data.cuenta, this.data.idOrdenes).subscribe(
      (res: any) => {
        this.requestState = RequestState.success;
        this.dialogRef.close({ok: true, cuenta: res.result});
      },
      () => {
        this.requestState = RequestState.error;
        this.timeout = 5;
        const interval = setInterval(
          () => {
            this.timeout--;
            if (this.timeout === 0) {
              this.requestState = RequestState.initial;
              this.disableBtn = false;
              clearInterval(interval);
            }
          },
          1000
        );
      }
    );
  }

  close(): void {
    this.dialogRef.close({ok: false});
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
