import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Licencia} from '../../../../../shared/models/licencia';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {LicenseService} from 'src/app/core/services/license.service';
import {RestaurantService} from 'src/app/core/services/restaurant.service';
import {Restaurante} from 'src/app/shared/models/restaurante';

@Component({
  selector: 'app-new-licence',
  templateUrl: './new-licence.component.html',
  styleUrls: []
})
export class NewLicenceComponent implements OnInit {

  form: FormGroup;
  title = 'Nueva licencia';
  licencias: Licencia[];
  dataSubscription: Subscription;

  constructor(public licenseService: LicenseService,
              private restaurantService: RestaurantService,
              public dialogRef: MatDialogRef<NewLicenceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Restaurante) {
    this.form = new FormGroup({
        serial: new FormControl(
          ''
        )
      }
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  activate(): void {
    this.data.licencia = this.form.get('serial').value;
    this.restaurantService.actualizarRestaurante(this.data.id, this.data).subscribe(
      (res) => {

        this.dialogRef.close({ok: true, res: res[0]});
      },
      () => {
        this.dialogRef.close({ok: false});
      }
    );
  }

  ngOnInit() {
    this.dataSubscription = this.licenseService.getOwnLicenses().subscribe(
      (licencias: Licencia[]) => this.licencias = licencias
    );
  }

}
