import { Component, OnInit, Input } from '@angular/core';
import { GrupoDeOrdenes } from 'src/app/shared/class-models/grupo-de-ordenes.model';
import { TipoOrden } from 'src/app/shared/enums/tipo-orden.enum';
import { Orden } from 'src/app/shared/class-models/orden.model';
import { Usuario } from 'src/app/shared/class-models/usuario.model';

@Component({
  selector: 'app-impresion-grupo-de-ordenes',
  templateUrl: './impresion-grupo-de-ordenes.component.html',
  styleUrls: ['./impresion-grupo-de-ordenes.component.scss']
})
export class ImpresionGrupoDeOrdenesComponent implements OnInit {
  @Input() grupoDeOrdenes: GrupoDeOrdenes;
  @Input() ordenesAgrupadas: Orden[];
  @Input() ordenesPorOrdenesAgrupadas: Orden[][];
  TipoOrden = TipoOrden;

  constructor() { }

  ngOnInit() {
  }

  obtenerNombreCompletoUsuario(usuario: Usuario): string {
    return usuario.nombres + ' ' + usuario.primerApellido + (usuario.segundoApellido ? (' ' + usuario.segundoApellido) : '');
  }

}
