import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Tamano } from 'src/app/shared/class-models/tamano.model';
import { MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { RequestState } from 'src/app/shared/enums/request-state.enum';
import { Subscription } from 'rxjs';
import { TamanosService } from 'src/app/core/servicios/tamanos.service';
import { AlertasService } from 'src/app/core/servicios/alertas.service';
import { AgregarTamanoConfiguracionComponent } from './agregar-tamano-configuracion/agregar-tamano-configuracion.component';
import { EditarTamanoConfiguracionComponent } from './editar-tamano-configuracion/editar-tamano-configuracion.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tamanos-configuracion',
  templateUrl: './tamanos-configuracion.component.html',
  styleUrls: ['./tamanos-configuracion.component.scss']
})
export class TamanosConfiguracionComponent implements OnInit, OnDestroy {
  @Output() peticionObtenerTamanosFinalizada = new EventEmitter<boolean>();
  @Input() estadoPeticiones: number;
  @ViewChild('paginatorTamanos', { read: MatPaginator, static: false }) paginatorTamanos: MatPaginator;
  dataSource: MatTableDataSource<Tamano>;
  columnasTabla: string[] = ['nombre', 'opciones'];
  estadoPeticion: number;
  RequestState = RequestState;
  obtenerTamanosSubscription: Subscription;
  eliminarTamanoSubscription: Subscription;
  filtro: string = '';

  constructor(
    private _tamanosService: TamanosService,
    private _alertasService: AlertasService,
    public dialog: MatDialog
  ) {
    this.estadoPeticion = RequestState.initial;
  }

  ngOnInit(): void {
    this.obtenerTamanos();
  }

  obtenerTamanos() {
    this.estadoPeticion = RequestState.loading;
    this.obtenerTamanosSubscription = this._tamanosService.obtenerTamanos().subscribe(
      (tamanos: Tamano[]) => {
        this.inicializarTamanos(tamanos);
        this.estadoPeticion = RequestState.success;
        this.peticionObtenerTamanosFinalizada.emit(true);
      },
      (error: HttpErrorResponse) => {
        this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
        this.estadoPeticion = RequestState.error;
        this.peticionObtenerTamanosFinalizada.emit(true);
      }
    );
  }

  inicializarTamanos(tamanos: Tamano[]): void {
    this.dataSource = new MatTableDataSource<Tamano>(tamanos);
    this.dataSource.paginator = this.paginatorTamanos;
    this.dataSource.filterPredicate = (tamano: Tamano, filtro: string) => {
      return tamano.nombre.trim().toLowerCase().indexOf(filtro) !== -1;
    }
  }

  buscarTamano(): void {
    this.dataSource.filter = this.filtro.trim().toLowerCase();
  }

  agregarTamano() {
    const dialogRef = this.dialog.open(AgregarTamanoConfiguracionComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(
      (tamano: Tamano) => {
        if (tamano != null) {
          this._alertasService.alertaExitoSinConfirmacion('Tamaño agregado exitosamente', 'El tamaño ' + tamano.nombre + ' ha sido agregado con éxito.');
          this.dataSource.data.push(tamano);
          const tamanos: Tamano[] = this.dataSource.data;
          this.inicializarTamanos(tamanos);
          this.buscarTamano();
        }
      }
    );
  }

  actualizarTamano(tamano: Tamano) {
    const dialogRef = this.dialog.open(EditarTamanoConfiguracionComponent, { disableClose: true, data: tamano });
    dialogRef.afterClosed().subscribe(
      (resultado: boolean | null) => {
        if (resultado == true) {
          this._alertasService.alertaExitoSinConfirmacion('Tamaño actualizado exitosamente', 'El tamaño ' + tamano.nombre + ' ha sido actualizado con éxito.');
          this.buscarTamano();
        }
      }
    );
  }

  eliminarTamano(tamanoEliminar: Tamano) {
    this._alertasService.alertaErrorConConfirmacion('¿Seguro que deseas eliminar el tamaño ' + tamanoEliminar.nombre + '?', '').then((result) => {
      if (result.value) {
        this.eliminarTamanoSubscription = this._tamanosService.eliminarTamano(tamanoEliminar._id).subscribe(
          (resultado: boolean) => {
            let posicionTamano = this.dataSource.data.findIndex((tamano: Tamano) => {
              return tamano._id === tamanoEliminar._id;
            });
            let tamanosActualizados: Tamano[] = this.dataSource.data;
            if (posicionTamano != -1) {
              tamanosActualizados.splice(posicionTamano, 1);
            }
            this.inicializarTamanos(tamanosActualizados);
            this.buscarTamano();
            this._alertasService.alertaExitoSinConfirmacion('Tamaño eliminado exitosamente', 'El tamaño ' + tamanoEliminar.nombre + ' ha sido eliminado con éxito.');
          },
          (error: HttpErrorResponse) => {
            this._alertasService.alertaErrorSinConfirmacion(error.error.titulo, error.error.detalles);
          }
        );
      }
    })
  }

  ngOnDestroy() {
    if (this.obtenerTamanosSubscription) this.obtenerTamanosSubscription.unsubscribe();
    if (this.eliminarTamanoSubscription) this.eliminarTamanoSubscription.unsubscribe();
  }
}
